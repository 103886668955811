import React from 'react';
import { Paper, Media, Grid, Cell } from 'react-md';
import ThemedButton from '../Buttons/ThemedButton';
import useMediaLoader from '../../hooks/useMediaLoader';

const headerInfo = { overflow: 'hidden', width: '100%' };
const detailsInfo = { marginTop: '100px' };

const GettingStartedPaper = (props) => {
    const { status, cardMediaUrl, headerTitle, buttonText, buttonClick, learnMoreLink, subTitle, buttons } = props;

    const {
        imageHasLoaded,
        imageSrc,
    } = useMediaLoader({ id: cardMediaUrl, suppressNotification: true });

    const hasImage = imageHasLoaded && imageSrc;

    return (
        <Paper zDepth={0} key={status} style={headerInfo}>
            <Grid>
                {
                    imageHasLoaded && imageSrc &&
                        <Cell desktopSize={5} tabletSize={8} phoneSize={4}>
                            {  buildMedia(imageSrc) }
                        </Cell>
                }
                <Cell desktopSize={hasImage ? 7 : 12} tabletSize={8} phoneSize={4} style={detailsInfo}>
                    <div className="cp-zero-state-tagline">{ headerTitle }</div>
                    { subTitle && <div className="cp-sub-tag-line">{ subTitle }</div> }
                    { learnMoreLink && setLearnMoreLinks(buttonClick) }
                    { buttons && setCustomButtons(buttons) }
                    { buttonText && functionsetButtonText(buttonText, buttonClick) }
                </Cell>
            </Grid>
        </Paper>
    );
}

const buildMedia = (imageSrc) => {
    return (
        <Media aspectRatio="1-1">
            <img src={imageSrc} alt="org" />
        </Media>
    );
}

const setLearnMoreLinks = (buttonClick) => {
    return(
        <Grid>
            <Cell key={0} size={5} tabletSize={4} desktopSize={6} className="btnAreaCenterHalfMargin">
                <ThemedButton flat primary swapTheming iconChildren="note" onClick={buttonClick}>Learn More</ThemedButton>
            </Cell>
            <Cell key={1} size={5} tabletSize={4} desktopSize={6} className="btnAreaCenterHalfMargin">
                <ThemedButton flat primary swapTheming iconChildren="movie" onClick={buttonClick}>Watch How-to</ThemedButton>
            </Cell>
        </Grid>
    );
}

const setCustomButtons = (buttons) => {
    return (
        <Grid>
            {
                buttons.map((button) => {
                    return (
                        <Cell key={button.buttonText} size={4} tabletSize={4} desktopSize={6} className="btnAreaCenterHalfMargin">
                            <ThemedButton raised swapTheming onClick={button.buttonClick}>{button.buttonText}</ThemedButton>
                        </Cell>
                    );
                })
            }
        </Grid>
    );
}

const functionsetButtonText = (buttonText, buttonClick) => {
    return (
        <div className="btnAreaCenter">
            <ThemedButton primary raised swapTheming onClick={buttonClick}>{buttonText}</ThemedButton>
        </div>
    );
}

export default GettingStartedPaper;
