export const NAV_DEFAULT = '/';
export const NAV_ORG_CLIENTS = '/organizationclients';
export const NAV_CLIENT_INVITES = '/clientinvites';
// export const NAV_COMMUNICATION = '/communication';
export const NAV_MY_PROFILE = '/myprofile';
export const NAV_SETTINGS = '/settings';
export const NAV_MEDIATION = '/mediation';
export const NAV_ORG = '/organization';
export const NAV_ORG_DETAILS = '/organizationdetails';
export const NAV_ORG_MESSAGE = '/organizationmessage';
export const NAV_ORG_BRANDING = '/organizationbranding';
export const NAV_ORG_APP_THEME = '/organizationapptheme';
export const NAV_SYSTEM_MSG = '/systemmessage';
export const NAV_VIEW_ORGS = '/vieworganizations';
export const NAV_VIEW_PROS = '/viewprofessionals';
export const NAV_PRO_PROFILE = '/professionalprofile';
export const NAV_SUPER_ADMIN = '/superadmin';
export const NAV_DEFAULT_BRANDING = '/systembranding';
export const NAV_ORG_TYPES = '/organizationtypes';
export const NAV_ORG_ENTRY_CATEGORIES = '/organizationentrycategories';
export const NAV_MEDIATION_ALLOCATION = '/mediationallocation';
// export const NAV_CLIENT_ASSIGN = '/clientassignment';
export const NAV_INTERNAL_COMMUNICATIONS = '/internalcommunications';
export const NAV_VIEW_COPARENTER_USERS = '/coparenterusers';
export const NAV_SEND_IN_APP_REVIEW = '/appreview';
export const NAV_MSGING = '/messaging';
export const NAV_VIDEO = '/video';
export const NAV_CASE_SEARCH = '/casesearch';
export const NAV_CLIENT_DETAILS = '/clientdetails';
export const NAV_CLIENT_PROFILE = '/clientprofile';
export const NAV_CLIENT_AGREEMENTS = '/clientagreements';
export const NAV_CLIENT_MESSAGES = '/clientMessages';
export const NAV_CLIENT_CHECKINS = '/clientCheckIns';
export const NAV_CLIENT_SENTIMENT = '/clientsentiment';
export const NAV_CLIENT_DOCUMENTS = '/clientdocuments';
export const NAV_CLIENT_COMMUNICATION = '/clientcommunications';
export const NAV_CLIENT_CHILDREN = '/clientchildren';
export const NAV_CLIENT_CALENDAR = '/clientcalendar';
export const NAV_CLIENT_REQUESTS = '/clientrequests';
export const NAV_CLIENT_REPOSITORY = '/clientrepository';
export const NAV_CLIENT_CONNECTIONS = '/clientconnections';
export const NAV_CLIENT_EXPENSES = '/clientexpenses';
export const NAV_CLIENT_MEDIATIONS = '/clientmediations';
export const NAV_SUBMIT_IDEAS = '/ideas';
export const NAV_ORG_GROUPS = '/organizationgroups';