import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import moment from 'moment';
import { CircularProgress, Tab, Tabs, TabsContainer } from 'react-md';
import ProTable from '../../components/ProTable';
import { ALL, FILTER_TEXT, FILTER_DATE } from '../../constants'
import { selectDataItemsFromComponent, selectCurrentUser } from '../../selectors/global'
import { setDataItemsToComponent } from '../../actions/global.js';
import { callCloudRunFunction } from '../../utils/firestore';

const COMPONENT_NAME = 'CoParentDashboard';

class CoParentDashboard extends PureComponent {
    constructor(props) {
        super(props);
        this.props.onSetDataItems([]);
        this.state = {
            isLoading: true,
            focusedTab: null,
            chatTabs: [],
        };
    }
    
    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { client, onSetDataItems, currentUser } = this.props

        callCloudRunFunction(currentUser, 'getClientCoParentChatMessages', { clientId: client.id, isUsingConnections: true }).then((result) => {
            const { messages, chats } = result;

            this.setState({
                focusedTab: chats[0].chatId,
                chatTabs: chats,
                isLoading: false,
            });
            onSetDataItems(messages);

        }).catch((err) => {
            console.log(err);
        });
    }

    createEmptyCard = () => {
        const { client } = this.props;
        return (<div align="center"><h3>No CoParent messages have been found for {client.friendlyName}</h3></div>);
    }

    renderTable(status) {
        const { dataItems } = this.props;

        const columns = [
            { label: 'Created On', value: 'timestamp', isPrimaryColumn: true, initialAsc: true, type: FILTER_DATE, fn: (item) => moment(item).format('llll')},
            { label: 'coParent Name', value: 'authorName', type: FILTER_TEXT, fn: (item) => item },
            { label: 'Message', value: 'body', type: FILTER_TEXT, isGrow: true, fn: (item) => item},
        ];

        const messagesByChat = dataItems.filter(x => x.chatId === status);

        return (
            <ProTable status={ALL} items={messagesByChat} columns={columns} key={ALL} componentName={COMPONENT_NAME} emptyCard={() => this.createEmptyCard(ALL)}/>
        );
    }

    render() {
        const { isLoading, chatTabs } = this.state;

        return isLoading
            ? <CircularProgress id="coParent-expanded-loading"/>
            : (
                <TabsContainer panelClassName="md-grid" colored onTabChange={(index, tabId) => this.setState({focusedTab: tabId})}>
                    <Tabs tabId="simple-tab">
                        { chatTabs.map(x => (<Tab key={`client-cp-messages-${x.chatId}`} id={x.chatId} label={x.title}>{ this.renderTable(x.chatId) }</Tab>)) }
                    </Tabs>
                </TabsContainer>
            );
    }
}

export function mapDispatchToProps(dispatch) {
    return { onSetDataItems: dataItems => dispatch(setDataItemsToComponent(dataItems, COMPONENT_NAME)) };
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser(),
    dataItems: selectDataItemsFromComponent(COMPONENT_NAME),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoParentDashboard);