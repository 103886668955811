import React, { PureComponent } from 'react'
import { DialogContainer } from 'react-md';

class InformationDialog extends PureComponent {
    
    onHideInformationDialog = () => {
        const { onHide } = this.props;
        
        onHide();
    }

    render() {
        const { title, text, visible, dismissable } = this.props;
        
        const actions = [{
            id: 'dialog-exit',
            primary: true,
            children: 'Ok',
            onClick: this.onHideInformationDialog,
        }];

        return (
            <DialogContainer
                title={title}
                id="information-dialog-container"
                aria-describedby= "information-dialog-container"
                visible={visible}
                onHide={this.onHideInformationDialog}
                focusOnMount={false}
                closeOnEsc={dismissable || false}
                onShow={this.startTimeout}
                autosizeContent={false}
                modal={!(dismissable || false)}
                actions={actions}
            >
                <p className="md-text-center">{ text || '' }</p>
            </DialogContainer>
        )
    }
}

export default InformationDialog;
