import React, { useEffect } from 'react';
import SliderFormField from '../../components/FormFields/SliderFormField';
import useFormValidation from '../../hooks/useFormValidation';
import { validate } from './UpdateAllocationValidate';
import { Cell, Grid, Button } from 'react-md';
import Bounce from 'react-reveal/Bounce';
import { round } from 'lodash';
import ThemedButton from '../../components/Buttons/ThemedButton';

function UpdateAllocationForm(props) {
    const { onSubmit, initialValues } = props;

    const { professionals = [] } = initialValues;
    const MAX_WEIGHT = 100;

    const allocationWeights = {};
    let totalWeight = 0;

    professionals.forEach((pro) => {
        const proWeighting =  round((pro.mediationWeight || 0) * MAX_WEIGHT);
        allocationWeights[pro.id] = proWeighting;
        totalWeight = totalWeight + proWeighting;
    });

    const INITIAL_STATE = {
        totalWeight: totalWeight,
        allocationWeights: allocationWeights,
    };

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        changeValues,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, updateAllocations, validate);

    useEffect(() => {
        const newTotalWeight = calculateTotalWeight(values['allocationWeights']);

        if (values['totalWeight'] !== newTotalWeight) {
            handleChange('totalWeight', newTotalWeight);
        }
    }, [values]);

    function updateAllocations(values) {
        const allocation = parseSubmissionToAllocation(values);
        onSubmit(allocation);
    }

    const parseSubmissionToAllocation = (values) => {
        const allocations = Object.entries(values['allocationWeights']).map(([id, value]) => {
            return {
                id: id,
                mediationWeight: value / MAX_WEIGHT,
            };
        });

        // Filter out allocation weightings that have changed
        const changedAllocations = allocations.filter((allocation) => {
            const professional = professionals.find(pro => pro.id === allocation.id);
            return professional && (professional.mediationWeight || 0) !== allocation.mediationWeight;
        });

        return changedAllocations;
    }

    const calculateTotalWeight = (weights) => {
        const totalValue = Object.entries(weights).reduce((prev, curr) => {
            const [ key, value ] = curr;
            return prev + value;
        }, 0);

        return totalValue;
    }

    const handleSliderChange = (id, value) => {
        const mergedWeights = Object.assign(values['allocationWeights'], {[id]: value});
        handleChange('allocationWeights', mergedWeights);
    }

    const handleRevertForm = () => {
        changeValues(INITIAL_STATE);
    }

    const handleZeroForm = () => {
        const zeroedWights = {};

        professionals.forEach((pro) => {
            zeroedWights[pro.id] = 0;
        });
        handleChange('allocationWeights', zeroedWights);
    }

    const handleResetSlider = (id) => {
        const professional = professionals.find(pro => pro.id === id);

        if (professional) {
            handleSliderChange(id, 0);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={6} tabletSize={2} phoneSize={4}>
                    <p>Total Weight allocated:</p>
                    <h2 className={!!errors['totalWeight'] ? 'weight-error' : 'weight-exact'}>{`${values['totalWeight']} out of ${MAX_WEIGHT}`}</h2>
                    <Bounce when={!!errors['totalWeight']}><p className="error-text">{errors['totalWeight'] || '...'}</p></Bounce>
                </Cell>
                <Cell desktopOffset={1} desktopSize={1} tabletOffset={1} tabletSize={1} phoneSize={4}>
                    <ThemedButton className='full-width-field' flat primary onClick={handleZeroForm}>Set All To Zero</ThemedButton>
                </Cell>
                <Cell desktopOffset={1} desktopSize={1} tabletOffset={1} tabletSize={1} phoneSize={4}>
                    <ThemedButton className='full-width-field' flat primary onClick={handleRevertForm}>Revert Changes</ThemedButton>
                </Cell>
                <Cell desktopOffset={1} desktopSize={1} tabletOffset={1} tabletSize={1} phoneSize={4}>
                    <ThemedButton className='full-width-field' disabled={isSubmitting} type="submit" flat primary swapTheming>Save</ThemedButton>
                </Cell>
                {
                    professionals.map((pro) => {
                        return (
                            <Cell size={12}>
                                <SliderFormField
                                    name={pro.id}
                                    values={values.allocationWeights}
                                    errors={errors}
                                    label={<><b>{`${pro.displayName} ${!pro.isActive ? '(Inactive)' : ''}`}</b><p>{pro.email}</p></>}
                                    leftIcon={<ThemedButton icon primary disabled={!pro.isActive} onClick={() => handleResetSlider(pro.id)}>undo</ThemedButton>}
                                    min={0}
                                    disabled={!pro.isActive}
                                    max={MAX_WEIGHT}
                                    step={5}
                                    discreteTicks={5}
                                    onChange={handleSliderChange}
                                    onBlur={handleBlur}
                                />
                            </Cell>
                        );
                    })
                }
            </Grid>
        </form>
    );
}

export default UpdateAllocationForm;