import React, { Component } from 'react';
import { Card, CardTitle, CardText } from 'react-md';
import AgreementActions from './AgreementActions';
import { AGREEMENT_STYLES } from '../../constants/lookupInfo';

class AgreementInteractionCard extends Component {
    displayResponses() {
        const { messageAttributes } = this.props;
        const messageResponse = messageAttributes.response.split('|');
        const agreementValue = messageAttributes.agreementValue;

        return (
            messageResponse.map((item, index) => {
                const textColor = item.toLowerCase().includes(` ${agreementValue}`) ? 'chat-card-green-text' : 'chat-card-red-text';
                return (
                    <div className={`responseContainer agreementResponseContainer ${textColor}`} key={`response${index}`}>
                        {item}
                    </div>
                );
            })
        );
    }

    render() {
        const {
            messageAttributes,
            /*isAnyDisagree,*/ isAllUserResponded, isAllAgree,
            onDeleteInteraction, onOverrideToAgree, onReset, onModify,
        } = this.props;

        const waitingCoParentResponseMessage = <p className="text-center">Waiting for coParent to Respond</p>;
        const wrapperTemplate = isAllAgree === null ? AGREEMENT_STYLES.blue : isAllAgree === true ? AGREEMENT_STYLES.green : AGREEMENT_STYLES.red;

        return (
            <Card className="chatCardWrapper">
                <CardTitle className={`${wrapperTemplate} chatCardTitle`} title={messageAttributes.title} />
                <CardText className="chatCardText">
                    <p className="agreementStatement">{ messageAttributes.message }</p>
                    { messageAttributes.responses && messageAttributes.responses.length > 0 && <div className="row agreementResponseLine">{this.displayResponses()}</div> }
                    { !isAllUserResponded && <div className="chatCardWaitingResponseText">{waitingCoParentResponseMessage}</div> }
                    { !isAllAgree && <AgreementActions onCancelAction={onDeleteInteraction} onAgreeOverride={onOverrideToAgree} onReset={onReset} onModify={onModify} /> }
                </CardText>
            </Card>
        );
    }
}

export default AgreementInteractionCard;
