import { emailValidate, phoneValidate } from "../../components/RequestFields/renderToolboxField";

function validate(values) {
    const clientKeys = [ 'client1', 'client2' ];
    let errors = {};

    clientKeys.forEach((key) => {
        const clientData = values[key];

        if (clientData) {
            const clientKeyErrors = {};

            if (!clientData.firstName.trim()) {
                clientKeyErrors.firstName = "First name is required";
            }
        
            if (!clientData.lastName.trim()) {
                clientKeyErrors.lastName = "Last name is required";
            }
        
            if (!clientData.sms.trim()) {
                clientKeyErrors.sms = "Phone number is required";
            } else if (phoneValidate(clientData.sms.trim())) {
                clientKeyErrors.sms = phoneValidate(clientData.sms.trim());
            }
        
            if (!clientData.email.trim()) {
                clientKeyErrors.email = "Email is required";
            } else if (emailValidate(clientData.email.trim())) {
                clientKeyErrors.email = emailValidate(clientData.email.trim());
            }

            // If client group does not have errors, do not set error object
            if (Object.keys(clientKeyErrors).length !== 0) {
                errors[key] = clientKeyErrors;
            }
            
        }
    });

    if (values.isUsingGroups && !values.orgGroup.trim()) {
        errors.orgGroup = 'Group is required'
    }

    return errors;
}

export {
    validate,
};