import * as firebase from 'firebase';
import { showToastNotification } from '../components/Notifications/ToastNotification';
import { NOTIFICATION_ERROR } from '../constants/notifications';
import { getMessageBodyFromFile } from '../utils/strings';
import { callCloudRunFunction } from '../utils/firestore';

const uuidv4 = require('uuid/v4');
// Uploads an array of files and create Upload Documents
// Returns an array of Upload IDs
const uploadFiles = async (files, baseBucketPath, currentUser, notes, sharedWith) => {
    var uploadIds = [];

    if (!files || !baseBucketPath || !currentUser) {
        return uploadIds;
    }

    for (let file of Array.from(files)) {
        uploadIds.push(await uploadFile(file, baseBucketPath, currentUser, notes, sharedWith));
    }

    uploadIds = uploadIds.filter(x => x !== null);

    return uploadIds;
}

// Uploads a file and creates an Upload Document
// Returns an Upload id
const uploadFile = (file, baseBucketPath, currentUser, notes, sharedWith) => {
    const storage = firebase.storage();
    const bucketPath = `${baseBucketPath}/${uuidv4()}`;

    const uploadMap = {
        bucketPath: bucketPath,
        createdBy: currentUser.uid,
        notes: notes || '',
        sharedWith: sharedWith || [],
        title: file.name,
    };

    var uploadTask = storage.ref().child(bucketPath).put(file);

    return uploadTask.then((uploadTaskSnapshot) => {
        return uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            uploadMap.url = url;
            return callCloudRunFunction(currentUser, 'createUpload', uploadMap)
        });
    }).then((uploadDoc) => {
        return uploadDoc.uid;
    }).catch((err) => {
        console.log(err);
        showToastNotification(`Failed to upload file: ${file.name}`, NOTIFICATION_ERROR);
        return null;
    });
}

// Puts the file into storage and returns download URL
const putFile = (bucketPath, file) => {
    if (!file || !bucketPath) {
        return Promise.reject(false);
    }

    const storage = firebase.storage();

    var uploadTask = storage.ref().child(bucketPath).put(file);

    return uploadTask.then((uploadTaskSnapshot) => {
        return uploadTaskSnapshot.ref.getDownloadURL();
    }).catch((err) => {
        console.log(err);
        return Promise.reject(false);
    });
}

// Deletes the file at the given path
const deleteFile = (bucketPath) => {
    if (!bucketPath) {
        return Promise.reject(false);
    }

    const storage = firebase.storage();

    return storage.ref().child(bucketPath).delete();
}

const getDataFromUploadId = (uploadId) => {
    const db = firebase.firestore();
    const storage = firebase.storage();

    return db.collection('Uploads').doc(uploadId).get().then((doc) => {
        if (doc.exists) {
            const uploadData = doc.data();
            uploadData.id = doc.ref.id;
            uploadData.createdOn = uploadData.createdOn ? uploadData.createdOn.toDate() : null;
            uploadData.updatedOn = uploadData.updatedOn ? uploadData.updatedOn.toDate() : null;
    
            return uploadData;
        } else {
            throw 'Document does not exist';
        }

    }).then(async (uploadData) => {
        return [ uploadData, await storage.ref().child(uploadData.bucketPath).getMetadata() ];

    }).then(([ uploadData, metaData ]) => {
        return [ uploadData, getMessageBodyFromFile({ type: metaData.contentType }) ];
    });
}

export {
    uploadFiles,
    uploadFile,
    putFile,
    deleteFile,
    getDataFromUploadId,
}