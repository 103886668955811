import React, { useEffect, useState } from 'react';
import useNotifications from '../../hooks/useNotifications';
import ThemedBadge from '../../components/ThemedBadge';
import TableIconButton from '../../components/Buttons/TableIconButton';
import usePageFramework from '../../hooks/usePageFramework';
import { selectCurrentUser } from '../../selectors/global';
import { callCloudRunFunction } from '../../utils/firestore';

function ClientMessageButton(props) {
    const { id, clientId, label, onClick } = props;
    const {
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());

    const [ directChatId, setDirectChatId ] = useState(null);

    useEffect(() => {
        if (!clientId) {
            return;
        }

        const uids = [{ uid: clientId }, currentUser];
        callCloudRunFunction(currentUser, 'isDirectChatCreated', { uids: uids }).then((result) => {
            if (result.exists) {
                setDirectChatId(result.chatId);
            }
        });
    }, []);

    const {
        hasMatchingNotification,
    } = useNotifications();

    return (
        <ThemedBadge
            badgeContent={hasMatchingNotification(directChatId) ? 1 : 0}
        >
            <TableIconButton
                id={id}
                label={label}
                onClick={onClick}
            />
        </ThemedBadge>
    );
}

export default ClientMessageButton;