function validate(labelsData) {
    return (values) => {
        let errors = {};

        labelsData.forEach((labelItem) => {
            const maxLength = labelItem.constraint || 20;
            const labelValue = values[labelItem.key];

            if (labelValue && labelValue.length > maxLength) {
                errors[labelItem.key] = `Maximum of ${maxLength} characters`;
            }
        });

        return errors;
    }
}

export {
    validate,
};