import messages from './messages';
import * as firebase from 'firebase';
import { normalizePhone } from './normalizePhone';

const minLength = (min) => (value) => value && value.length < min ? `${messages.mustBe.defaultMessage} ${min} ${messages.charOrMore.defaultMessage}` : undefined;
const length = (len) => (value) => value && value.length !== len ? `${messages.mustBe.defaultMessage} ${len} ${messages.chars.defaultMessage}` : undefined;
const maxLength = (max) => (value) => value && value.length > max ? `${messages.mustBe.defaultMessage} ${max} ${messages.charOrLess.defaultMessage}` : undefined;
const maxYearLength = (max) => (value) => value && value.length > max ? `${messages.yearMustBe.defaultMessage} ${max - 6} ${messages.chars.defaultMessage}` : undefined;
export const organization = (value) => value ? undefined :messages.required.defaultMessage;
export const required = (value) => value ? undefined : messages.required.defaultMessage;
export const emailValidate = (value) => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? messages.invalidEmail.defaultMessage : undefined;
export const phoneValidate = (value) => value && !/^(\(\d{3}\)[-\. ]?|\d{3}?[-\. ]?)\d{3}?[-\. ]?\d{4}$/i.test(value) ? messages.invalidPhone.defaultMessage: undefined;
export const validPassword = (value) => value && !/^(?=.*[A-Za-z!`~@#$%^&*+.=?/)(}{[><])(?=.*\d)[A-Za-z!`~@#$%^&*+.=?/)(}{[><\d]{9,150}$/i.test(value) ? messages.invalidPassword.defaultMessage : undefined;
export const postalCode = (value) => value && !/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value) ? "Invalid ZIP/Postal Code" : undefined ;
export const zipCode = (value) => value && !/^\b\d{5}(-\d{4})?\b$/.test(value) ? "Invalid ZIP/Postal Code" : undefined ;
export const number = (value) => value && isNaN(Number(value)) ? messages.invalidNumber.defaultMessage: undefined;
export const minLength8 = minLength(8);
export const minLength9 = minLength(9);
export const length6 = length(6);
export const maxLength160 = maxLength(160);
export const maxLength20 = maxLength(20);
export const maxYearLength10 = maxYearLength(10);
export const maxLength50 = maxLength(50);
export const maxLength64 = maxLength(64);
export const maxLength512 = maxLength(512);
export const maxLength150 = maxLength(150);
export const maxLength400 = maxLength(400);
export const maxLength60 = maxLength(60);
export const maxLength2000 = maxLength(2000);

export const minLength3OrEmpty = (value) => value ? minLength(3)(value) : undefined;

export const asyncValidateUid = (value, collectionName) => {
    const db = firebase.firestore();
    return value === undefined ?  Promise.resolve(false) : db.collection(collectionName).where('uid', '==', value.toString()).get().then((uidSnapshot) => {
        return uidSnapshot.empty ? Promise.resolve(messages.uidDoesNotExist.defaultMessage) : Promise.resolve(false);
    }).catch((err) => {
        console.log(err);
        return Promise.reject(err);
    });
}

export const asyncValidateEmail = (value, collectionName) => {
    const db = firebase.firestore();
    return value === undefined ?  Promise.resolve(false) : db.collection(collectionName).where('email', '==', value.toString().toLowerCase()).get().then((emailSnapshot) => {
        return emailSnapshot.empty ? Promise.resolve(false) : Promise.resolve(messages.existingEmail.defaultMessage);
    }).catch((err) => {
        console.log(err);
        return Promise.reject(err);
    });
};

export const asyncValidatePhone = (value, collectionName) => {
    const db = firebase.firestore();
    return value === undefined ? Promise.resolve(false) : db.collection(collectionName).where('sms', '==', normalizePhone(value)).get().then((phoneSnapshot) => {
        return phoneSnapshot.empty ? Promise.resolve(false) : Promise.resolve(messages.existingPhone.defaultMessage);
    }).catch((err) => {
        console.log(err);
        return Promise.reject(err);
    });
}