import * as firebase from 'firebase';

const generateFetchOptions = (userToken, body) => {
    const fetchOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'authorization': `Bearer ${userToken}`},
        body: JSON.stringify(body)
    };

    return fetchOptions;
}

const callCloudRunFunction = (currentUser, method, body) => {
    // const userToken = currentUser.tokenId;

    // console.log('callCloudRunFunction ', method);

    return firebase.auth().currentUser.getIdToken(true).then((tokenId) => {
        return generateFetchOptions(tokenId, body);
    }).then((fetchOptions) => {
        return fetch(`/cloudrun/${method}`, fetchOptions);
    }).then((response) => {
        if (response.ok) {
            return response.json();
        }
        throw 'Error';
    }).then((body) => {
        // console.log(body);
        return Promise.resolve(body);
    }).catch((err) => {
        console.log(err);
        return Promise.reject(err);
    });
}

const arrayParserFunction = (data) => {
    return !data
        ? []
        : data.map((x) => JSON.parse(x));
}

export {
    callCloudRunFunction,
    arrayParserFunction,
};