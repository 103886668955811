import * as firebase from 'firebase';
import { asyncValidationChanges } from '../../utils/formFields';

function validate(values) {
    const errors = {};

    if (!values.name.trim()) {
        errors.name = "A label is required";
    }

    return errors;
}

async function asyncValidate(values, previousValues, existingErrors, previousAsyncErrors) {
    const asyncErrors = {};

    const asyncValidateGroupFunction = async () => {
        return await asyncValidate(values.name, values.uid,  'Groups', values.organizationId);
    };

    const asyncValidate = (value, excludeId, collectionName, organizationId) => {
        const db = firebase.firestore();

        return value === undefined ? Promise.resolve(false) : db.collection('ProOrganizations').doc(organizationId).collection(collectionName).where('name', '==', value.toString()).get().then((snapshot) => {
            return snapshot.docs.filter(x => x.id !== excludeId).length === 0
                ? Promise.resolve(false)
                : Promise.resolve('A group of this name already exists')
        }).catch((err) => {
            console.log(err);
            return Promise.reject(err);
        });
    }

    const nameError = await asyncValidationChanges('name', values, previousValues, existingErrors, previousAsyncErrors, asyncValidateGroupFunction);
    if (nameError) {
        asyncErrors.name = nameError;
    }

    return asyncErrors;
}

export {
    validate,
    asyncValidate,
};