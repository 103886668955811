import React, { useRef } from "react";

function useFormValidation(initialState, submitFn = () => {}, validate = () => { return {}; }, asyncValidate = () => { return {}; } ) {
    const [ values, setValues ] = React.useState(initialState);
    const [ errors, setErrors ] = React.useState({});
    const [ isSubmitting, setSubmitting ] = React.useState(false);
    const previousRef = useRef({});

    React.useEffect(() => {
        asyncValidation(errors);
    }, [values]);

    React.useEffect(() => {
        if (!isSubmitting) return;

        const noErrors = Object.keys(errors).length === 0;
        
        if (noErrors) {
            // console.log("no Errors!", values);
            submitFn(values);
        }

        setSubmitting(false);
    }, [isSubmitting]);
    
    function handleChange(name, value) {
        changeValues({[name]: value});
    }
    
    // changeValues default merge the new vales into current values.
    // Use strict if changed values are to replace all values.
    function changeValues(changedValues, strict = false) {
        let newValues = Object.assign({}, values, changedValues);
        
        if (strict) {
            newValues = Object.assign({}, changedValues);
        }

        const validationErrors = validate(newValues);
        setValues(newValues);
        setErrors(validationErrors);
    }

    function removeValue(name) {
        let newValues = Object.assign({}, values);
        
        delete newValues[name];
        const validationErrors = validate(newValues);
        setValues(newValues);
        setErrors(validationErrors);
    }

    function handleBlur() {
        const validationErrors = validate(values);
        setErrors(validationErrors);
        asyncValidation(validationErrors);
    }

    function handleSubmit(event) {
        event.preventDefault();
        const validationErrors = validate(values);
        setErrors(validationErrors);
        asyncValidationWithSubmit(validationErrors);
    }

    async function asyncValidation(currentErrors) {
        const previous = previousRef.current;
        const previousValues = previous.values || {};
        const previousAsyncErrors = previous.asyncErrors || {};

        const asyncErrors = await asyncValidate(values, previousValues, currentErrors, previousAsyncErrors);

        const validationErrors = Object.assign({}, asyncErrors, currentErrors);
        setErrors(validationErrors);

        previousRef.current = { values, asyncErrors };
    }

    async function asyncValidationWithSubmit(currentErrors) {
        await asyncValidation(currentErrors);
        setSubmitting(true);
    }

    return {
        handleSubmit,
        handleChange,
        handleBlur,
        changeValues,
        removeValue,
        values,
        errors,
        isSubmitting
    };
}

export default useFormValidation;