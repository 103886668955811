import React, { useState, useEffect } from 'react'
import useLoadingPageFramework from "../../hooks/useLoadingPageFramework";
import moment from 'moment';
import { CardTitle, CardText, Media, Card, CircularProgress } from 'react-md';
import { timestampConvertToDate } from '../../utils/dateTime';
import { callCloudRunFunction } from '../../utils/firestore';
import usePageFramework from '../../hooks/usePageFramework';
import { selectCurrentUser } from '../../selectors/global';
import Fade from 'react-reveal/Fade';
import useCustomAssets from '../../hooks/useCustomAssets';
import useMediaLoader from '../../hooks/useMediaLoader';

function SystemMessageCard() {
    const {
        selector,
    } = usePageFramework();

    const {
        getAsset,
    } = useCustomAssets('InformationCards');

    const {
        imageHasLoaded,
        imageSrc,
    } = useMediaLoader({ id: getAsset('systemMessageCard'), suppressNotification: true });

    const currentUser = selector(selectCurrentUser());
    const [ systemMessage, setSystemMessage ] = useState({});

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        callCloudRunFunction(currentUser, 'getOrganizationMessage', { organizationId: 'system' }).then((data) => {
            data.createdOn = timestampConvertToDate(data.createdOn);

            setSystemMessage(data);
            finishedLoading();
        }).catch((err) => {
            console.log(err);
            errorLoading();
        });
    }, []);

    function buildPageContent() {
        return (
            <>
                <CardTitle title={'A message from coParenter'} subtitle={`Posted on: ${moment(systemMessage.createdOn).format("MMM Do YYYY h:mm:ss a")}`}/>
                <CardText>
                    <p>{systemMessage.message}</p>
                </CardText>
            </>
        );
    }

    return (
        <Card className="home-card">
            {
                !imageHasLoaded
                    ? <CircularProgress centered/>
                    : <Fade>
                        <Media aspectRatio="4-1">
                            <img src={imageSrc} alt="org"/>
                        </Media>
                    </Fade>
            }
            { renderPageContent() }
        </Card>
    );
}

export default SystemMessageCard