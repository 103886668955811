import React from 'react';
import { buildScheduleIcon } from '../ScheduleIcons';
import { SCHEDULE_CATEGORY_BIRTHDAY, PARENTING_SCHEDULE_CATEGORIES, ADDITIONAL_CATEGORIES } from '../../constants/schedule';

const buildParentingScheduleIcons = (items, childUid) => items.filter((item) => filterScheduleItem(item, childUid, PARENTING_SCHEDULE_CATEGORIES)).map((item) => buildScheduleIcon(item));
const buildAdditionalScheduleIcons = (items, childUid) => items.filter((item) => filterScheduleItem(item, childUid, ADDITIONAL_CATEGORIES)).map((item) => buildScheduleIcon(item));
const buildBirthdayIcon = () => buildScheduleIcon({ category: SCHEDULE_CATEGORY_BIRTHDAY });

const compileTileContent = (contents) => {
    const filteredContents = contents.filter((content) => content !== null);
    if (filteredContents.length > 2) {
        return [filteredContents[0], <p>{ `+${filteredContents.length - 1} more` }</p>];
    }
    return filteredContents;
}

const CalendarTileContent = (props) => {
    const { dateItems, child, hasBirthday } = props;
    const tileIcons = [];

    if (dateItems && dateItems.items) {
        buildParentingScheduleIcons(dateItems.items, child.uid).forEach((x) => tileIcons.push(x));
        buildAdditionalScheduleIcons(dateItems.items, child.uid).forEach((x) => tileIcons.push(x));
    }

    if (child && hasBirthday) {
        tileIcons.push(buildBirthdayIcon());
    }

    return (
        <ul className='flex-container-cal'>
            { compileTileContent(tileIcons) }
        </ul>
    );
}

// Filter function for matching schedule items
const filterScheduleItem = (scheduleItem, childUid, category) => scheduleItem.children && scheduleItem.children.includes(childUid) && category.includes(scheduleItem.category) ? true : false;

export {
    CalendarTileContent,
    filterScheduleItem,
};