import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Grid } from "react-md";
import { FILTER_NONE, FILTER_TEXT, FILTER_NUMBER, FILTER_DATE, FILTER_BOOLEAN, FILTER_ARRAY_CONTROLS, FILTER_ARRAY_TEXT } from "../constants";
import { BOOLEAN_FILTER_TYPES, DATE_FILTER_TYPES } from "../constants/lookupInfo";
import TextFormField from '../components/FormFields/TextFormField';
import SelectionFormField from '../components/FormFields/SelectionFormField';
import { getDefaultFilterValue } from '../utils/sortFilter';
import CheckboxFormField from '../components/FormFields/CheckboxFormField';

function useFilter(columns, initialFilterState) {
    const EMPTY_FILTER = {};

    columns.forEach((column) => {
        EMPTY_FILTER[column.value] = getDefaultFilterValue(column.type);
    });

    const INITIAL_STATE = Object.assign(EMPTY_FILTER, initialFilterState || {});

    const [filter, setFilter] = useState(INITIAL_STATE);

    useEffect(() => {
        setFilter(INITIAL_STATE);
    }, [initialFilterState])


    function resetFilterToDefault() {
        changeFilterValues(INITIAL_STATE);
    }

    function handleChange(name, value) {
        changeFilterValues({[name]: value});
    }

    function changeFilterValues(changedFilters) {
        const newValues = Object.assign({}, filter, changedFilters);
        setFilter(newValues);
    }

    const filterItems = (items) => {
        const filterFunctions = Object.entries(filter).map(([columnValue, value]) => {
            const column = columns.find(x => x.value === columnValue);

            if (value === getDefaultFilterValue(column.type)) {
                return x => (x);
            }

            return filterByProperty(column.type, value.toLowerCase(), columnValue);
        });

        return filterItemsRecursive(filterFunctions, items);
    }

    function filterItemsRecursive(filterFunctions, items) {
        if (filterFunctions.length === 0 || items.length === 0) return items;

        
        return filterItemsRecursive(filterFunctions.slice(1), items.filter(filterFunctions[0]));
    }

    const filterByProperty = (type, lowerText, property) => {
        switch(type) {
            case FILTER_DATE:
                return (x) => {
                    const dateFilterObj = DATE_FILTER_TYPES.find(filter => filter.id.toLowerCase() === lowerText);
                    const dateFilterDays = dateFilterObj.days;
                    const dateFilterName = dateFilterObj.id.toLowerCase();

                    if (dateFilterDays < 0) {
                        return x;
                    } else if (!x[property]){
                        return false;
                    } else if (dateFilterName === 'Yesterday'.toLowerCase()) {
                        const startDate = moment().subtract(dateFilterDays, 'days').startOf('day');
                        const endDate = moment().subtract(0, 'days').startOf('day');
                        return moment(x[property]).isBetween(startDate, endDate, 'minute', '[]');
                    } else {
                        const date = moment().subtract(dateFilterDays, 'days').startOf('day');
                        return moment(x[property]).isAfter(date);
                    }
                };
            case FILTER_NUMBER:
                return x => Number(lowerText) === Number(x[property]);
            case FILTER_BOOLEAN:
                return (x) => {
                    const booleanFilterValue = BOOLEAN_FILTER_TYPES.find(filter => filter.id.toLowerCase() === lowerText).value;
                    return booleanFilterValue === null || Boolean(x[property]) === booleanFilterValue; 
                };
            case FILTER_TEXT:
                return (x) => {
                    return x[property] !== null && x[property] !== undefined ? x[property].toLowerCase().includes(lowerText) : false;
                };
            case FILTER_ARRAY_TEXT:
                return (x) => {
                    return x[property] !== null && x[property]  !== undefined ? x[property].join(',').toLowerCase().includes(lowerText) : false;
                }
            case FILTER_ARRAY_CONTROLS:
                return (x) => {
                    const values = lowerText.split(',');
                    return x[property] !== null && x[property] !== undefined ? values.map((value) => x[property].join(',').toLowerCase().includes(value)).reduce((acc, cur) => acc && cur, true) :  false;
                }
            default:
                return (x) => x;
            }
    }

    const renderFilterField = (column) => {
        switch (column.type) {
            case FILTER_TEXT:
            case FILTER_ARRAY_TEXT:
            case FILTER_NUMBER:
                return (
                    <TextFormField
                        key={column.value}
                        name={column.value}
                        label={column.label}
                        values={filter}
                        errors={{}}
                        onChange={handleChange}
                    />
                );
            case FILTER_DATE:
                return (
                    <SelectionFormField
                        key={column.value}
                        name={column.value}
                        label={column.label}
                        menuItems={DATE_FILTER_TYPES}
                        itemLabel="id"
                        itemValue="id"
                        values={filter}
                        errors={{}}
                        onChange={handleChange}
                    />
                );
            case FILTER_BOOLEAN:
                return (
                    <SelectionFormField
                        key={column.value}
                        name={column.value}
                        label={column.label}
                        menuItems={BOOLEAN_FILTER_TYPES}
                        itemLabel="id"
                        itemValue="id"
                        values={filter}
                        errors={{}}
                        onChange={handleChange}
                    />
                );
            case FILTER_ARRAY_CONTROLS:
                    return (
                        <CheckboxFormField
                            key={column.value}
                            name={column.value}
                            label={column.label}
                            values={filter}
                            errors={{}}
                            controls={column.controls}
                            showSelectAllButton={false}
                            onChange={handleChange}
                        />
                    );
            default:
                break;
        }
    }

    const renderFilterBar = () => {
        const displayFilters = columns.filter(x => x.type !== FILTER_NONE);

        return (
            <Grid>
                { displayFilters.map((column) => renderFilterField(column)) }
            </Grid>
        );
    }

    return {
        resetFilterToDefault,
        filterItems,
        renderFilterBar,
    };
}

export default useFilter;