import React, { PureComponent } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Button, DialogContainer, Toolbar } from 'react-md';
import FileForm from './FormFile';

export default class FileUploadDialog extends PureComponent {

    parseFiles = (values) => {
        const fileValues = {};
        const findKeys = ['files'];
        findKeys.forEach(x => fileValues[x] = '');

        values.map((value, key) => {
            const name = findKeys.find(x => key === x);
            return fileValues[name] = value;
        });

        return fileValues;
    };

    handleSubmit = (e) => {
        const fileData = this.parseFiles(e);
        this.props.addFiles(fileData);
    };

    render() {
        const { visible, onHide } = this.props;
        const groups = (<FileForm onSubmit={this.handleSubmit} />);

        return (
            <DialogContainer
                id="add-file-dialog"
                aria-labelledby="add-file-dialog-title"
                visible={visible}
                onHide={onHide}
                fullPage
            >
                <CSSTransitionGroup
                    id="add-file-form"
                    className="md-text-container md-toolbar--relative"
                    transitionName="md-cross-fade"
                    transitionEnterTimeout={300}
                    transitionLeave={false}
                >
                    <Toolbar
                        nav={<Button icon onClick={onHide}>close</Button>}
                        title="Add Image(s) or PDF(s)" titleId="add-file-title" fixed colored
                    />
                    {groups}
                </CSSTransitionGroup>
            </DialogContainer>
        );
    }
}