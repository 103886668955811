import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectClientProfile, selectOrganizationId, selectOrganizationName, selectUserOrganizationRoles, selectCurrentUser } from '../../selectors/global';
import { setToolbarTitle, setUniqueChannelName } from '../../actions/global';
import { NAV_MSGING } from '../../constants/navigation';
import { callCloudRunFunction } from '../../utils/firestore';
import { CLOSED } from '../../constants';

class ClientCommunication extends Component {
    async componentDidMount() {
        const { clientProfile, currentUser } = this.props;
        currentUser.isProfessional = true;
        currentUser.isPrimary = false;
        clientProfile.isProfessional = false;
        clientProfile.isPrimary = true;

        const uids = { uids: [clientProfile, currentUser] };
        let chatData = null;
        
        this.props.onSetToolbarTitle(`Client Communication - ${clientProfile.name}`);
        callCloudRunFunction(currentUser, 'isDirectChatCreated', uids).then((result) => {
            if (result.exists) {
                this.props.onSetUniqueChannelName({ id: result.chatId, category: result.chatType });
                this.props.history.replace(NAV_MSGING);
            } else {
                callCloudRunFunction(currentUser, 'createDirectChat', uids).then((results) => {
                    chatData = results;
                    const json = { body: 'Direct Communication created', chatId: chatData.chatId, isWithProfanityCheck: false, uid: currentUser.uid };
                    return callCloudRunFunction(currentUser, 'addChatMessage', json);
                }).then((results) => {
                    console.log(results);
                    this.props.onSetUniqueChannelName({ id: chatData.chatId, category: chatData.chatType });
                    this.props.history.replace(NAV_MSGING);
                });
            }
        });
    }

    componentDidUpdate(prevProps) {
        const { organizationName: curOrganizationName } = this.props;
        const { organizationName: prevOrganizationName } = prevProps;

        if (curOrganizationName !== false && curOrganizationName !== prevOrganizationName) {
            this.props.onSetToolbarTitle(`Mediation - ${curOrganizationName}`);
        }
    }

    render() {
        return (<></>);
    }
}

export function mapDispatchToProps(dispatch) {
    return {
        onSetToolbarTitle: title => dispatch(setToolbarTitle(title)),
        onSetUniqueChannelName: channelName => dispatch(setUniqueChannelName(channelName)),
    }
}

const mapStateToProps = createStructuredSelector({
    userOrgRoles: selectUserOrganizationRoles(),
    organizationId: selectOrganizationId(),
    organizationName: selectOrganizationName(),
    clientProfile: selectClientProfile(),
    currentUser: selectCurrentUser(),

});

export default connect(mapStateToProps, mapDispatchToProps)(ClientCommunication);