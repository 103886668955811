import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import { Card, Button, Cell, Grid, CardText } from 'react-md';
import { NAV_DEFAULT } from '../../constants/navigation';
import { setToolbarButtons } from '../../actions/global';
import { selectClientProfile, selectCurrentUser, selectOrganizationId, selectIsSuperAdmin, selectCurrentUserGroups } from '../../selectors/global';
import usePageFramework from '../../hooks/usePageFramework';
import UpdateClientProfileForm from './UpdateClientProfileForm';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_ERROR, NOTIFICATION_INFO, NOTIFICATION_SUCCESS } from '../../constants/notifications';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { putFile, deleteFile } from '../../utils/fileUpload';
import { callCloudRunFunction } from '../../utils/firestore';
import { getMatchingGroups } from '../../utils/orgGroups';
import { useCustomPageFrameworkLabels } from '../../hooks/useCustomLabels';
import { getClientLabelVariableData } from '../../utils/customBranding';

function ClientProfile () {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const organizationId = selector(selectOrganizationId());
    const currentUser = selector(selectCurrentUser());
    const userIsSuperAdmin = selector(selectIsSuperAdmin());
    const userOrgGroups = selector(selectCurrentUserGroups());
    const clientProfile = selector(selectClientProfile());
    const [ isUsingGroups, setIsUsingGroups ] = useState(null);
    const [ selectableOrgGroups, setSelectableOrgGroups ] = useState([]);

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('ClientProfile', getClientLabelVariableData(clientProfile));

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (!clientProfile) {
            return;
        }

        const toolBarButtons = [{ icon: 'arrow_back', onClick: () => history.goBack() }];
    
        dispatch(setToolbarButtons(toolBarButtons));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Profile',
            action: 'visit page',
        };

        var orgGroupStream = null;
        const db = firebase.firestore();

        orgGroupStream = db.collection('ProOrganizations').doc(organizationId).collection('Groups').onSnapshot((snapshot) => {
            const groups = snapshot.docs.map(doc => {
                const group = doc.data();

                group.createdOn = group.createdOn ? group.createdOn.toDate() : null;
                group.updatedOn = group.updatedOn ? group.updatedOn.toDate() : null;
                group.id = doc.id;
                return group;
            });
            
            const availableGroups = userIsSuperAdmin ? groups : getMatchingGroups(userOrgGroups, groups);
            setSelectableOrgGroups(availableGroups);
            setIsUsingGroups(groups.length ? true : false);
            finishedLoading();
        });

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        
        return function cleanup() {
            dispatch(setToolbarButtons([]));

            if (orgGroupStream) {
                orgGroupStream();
            }
        }
    }, []);

    if (!clientProfile) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    function updateClientProfile(clientData) {
        if (!clientData.uid) {
            showToastNotification('Client Profile has failed to be updated', NOTIFICATION_ERROR);
            return;
        }

        const { updatedProfilePicture } = clientData;
        delete clientData['updatedProfilePicture'];

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Profile',
            action: 'update client profile',
            metadata: {...clientData}
        };

        showToastNotification(`Updating ${clientData.firstName} ${clientData.lastName}'s profile`, NOTIFICATION_INFO);

        const promises = [];

        // If a new profile picture is uploaded
        if (updatedProfilePicture) {
            const basePath = `Users/${clientProfile.uid}/`;
            promises.push(putFile(`${basePath}${updatedProfilePicture.name}`, updatedProfilePicture).then((url) => {
                clientData.photoUrl = url;
                log.metadata.photoUrl = url;
            }));

            // Delete current profile picture if exists
            if (clientProfile.photoUrl) {
                try {
                    const uri = decodeURIComponent(clientProfile.photoUrl);
                    const baseName = uri.split(clientProfile.uid)[1];
                    const fileName = baseName.substring(1, baseName.indexOf('?'));

                    deleteFile(`${basePath}${fileName}`);
                } catch (err) {
                    console.log(err);
                }
            }
        }

        Promise.all(promises).then(() => {
            return callCloudRunFunction(currentUser, 'updateUserDocument', clientData);
        }).then(() => {
            showToastNotification('Sucessfully updated the profile', NOTIFICATION_SUCCESS);
            callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Profile failed to be updated', NOTIFICATION_ERROR);    
        });
    }

    function buildPageContent() {
        return (
            <Grid>
                <Cell size={12}>
                    <Card>
                        <CardText>
                            <UpdateClientProfileForm
                                onSubmit={updateClientProfile}
                                initialValues={clientProfile}
                                isUsingGroups={isUsingGroups}
                                selectableOrgGroups={selectableOrgGroups}
                            />
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default ClientProfile;