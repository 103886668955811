import React from 'react';
import { Button, Grid, Cell } from 'react-md';

const AgreementActions = ({onCancelAction, onAgreeOverride, onReset, onModify}) => {
    return (
        <Grid>
            <Cell desktopSize={3} tabletSize={4} phoneSize={4}>
                <Button raised secondary id="agreement-action-cancel" className="btn-fill-width" onClick={onCancelAction}>Cancel Agreement</Button>    
            </Cell>
            <Cell desktopSize={3} tabletSize={4} phoneSize={4}>
                <Button raised secondary id="agreement-action-override-agree" className="btn-fill-width" onClick={onAgreeOverride}>Override to Agree</Button>
            </Cell>
            <Cell desktopSize={3} tabletSize={4} phoneSize={4}>
                <Button raised secondary id="agreement-action-reset" className="btn-fill-width" onClick={onReset}>Reset Agreement</Button>
            </Cell>
            <Cell desktopSize={3} tabletSize={4} phoneSize={4}>
                <Button raised secondary id="agreement-action-modify" className="btn-fill-width" onClick={onModify}>Modify Agreement</Button>
            </Cell>
        </Grid>
    );
}

export default AgreementActions;