import React from 'react';
import { Grid, Cell } from 'react-md';
import useFormValidation from '../../../hooks/useFormValidation';
import SelectionFormField from '../../FormFields/SelectionFormField';
import { SCHEDULE_CATEGORIES } from '../../../constants/lookupInfo';
import { validate } from './validate';

function ScheduleTypeSelectionForm(props) {
    const { onSubmit, onHide, formId, initialValues } = props;

    const INITIAL_STATE = {
        scheduleType: SCHEDULE_CATEGORIES[0].id,
    };

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, scheduleTypeSubmit, validate);

    function scheduleTypeSubmit(values) {
        const scheduleType = parseSubmissionToScheduleType(values);
        onSubmit(scheduleType);
    }

    function parseSubmissionToScheduleType(values) {
        const scheduleType = {
            scheduleType: values.scheduleType,
        };

        return scheduleType;
    }

    return (
        <form id={formId} name={formId} onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell size={12}>
                    <SelectionFormField
                        name="scheduleType"
                        label="Schedule Type"
                        values={values}
                        errors={errors}
                        menuItems={SCHEDULE_CATEGORIES}
                        itemLabel="label"
                        itemValue="id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
            </Grid>
        </form>
    );
}

export default ScheduleTypeSelectionForm;