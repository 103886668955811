import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase';
import { selectOrganizationId, selectCurrentUser } from '../../selectors/global';
import { Grid, Cell } from 'react-md';
import { setToolbarButtons } from '../../actions/global';
import { FILTER_TEXT, ALL } from '../../constants';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants/notifications';
import useFilterSorterPanel from '../../hooks/useFilterSorterPanel';
import { NAV_DEFAULT } from '../../constants/navigation';
import useDialogFormBuilder from '../../hooks/useDialogFormBuilder';
import CreateEntryCategoryForm from './CreateEntryCategoryForm';
import UpdateEntryCategoryForm from './UpdateEntryCategoryForm';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import EntryCategoryCard from './EntryCategoryCard';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import usePageFramework from '../../hooks/usePageFramework';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';
import DeleteConfirmationForm from '../../components/Forms/DeleteConfirmationForm';
import { callCloudRunFunction } from '../../utils/firestore';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import ThemedButton from '../../components/Buttons/ThemedButton';
import useCustomAssets from '../../hooks/useCustomAssets';

function OrganizationEntryCategories() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());

    const [ items, setItems ] = useState([]);
    const [ focusedCategoryId, setFocusedCategoryId ] = useState(null);
    const foundCategory = items && items.find(category => category.uid === focusedCategoryId || category.id === focusedCategoryId);
    
    const labelVariableData = {
        name: foundCategory && foundCategory.category || '',
    };

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel
    } = useCustomPageFrameworkLabels('RepositoryCategories', labelVariableData);

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];

        dispatch(setToolbarButtons(toolBarButtons));

        var dataStream = null;

        if (organizationId === false) {
            return;
        }

        const db = firebase.firestore();

        // Loading entry categories
        dataStream = db.collection('ProOrganizations').doc(organizationId).collection('EntryCategories').onSnapshot((snapshot) => {
            const categories = snapshot.docs.map(doc => {
                const category = doc.data();
                category.createdOn = category.createdOn ? category.createdOn.toDate() : null;
                category.updatedOn = category.updatedOn ? category.updatedOn.toDate() : null;
                category.id = doc.id;
                return category;
            });

            setItems(categories);
            finishedLoading();
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Secure Repository Categories',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);

        return () => {
            dispatch(setToolbarButtons([]));
            
            if (dataStream) {
                dataStream(null);
            }
        } 
    }, []);

    const columns = [
        { label: getDataLabel('categoryData'), value: 'category', type: FILTER_TEXT },
        { label: getDataLabel('descriptionData'), value: 'description', type: FILTER_TEXT },
    ];

    const {
        getFilteredAndSortedItems,
        renderFilterSortPanel,
    } = useFilterSorterPanel(getLabel('filterSorterHeader'), columns, items);

    const createCategoryDialog = {
        FormComponent: CreateEntryCategoryForm,
        onSubmit: createEntryCategory,
        dialogData: { title: getLabel('createTitleBar') },
    };

    const {
        showDialog: showCreateDialog,
        renderDialogForm: renderCreateDialogForm
    } = useDialogFormBuilder(createCategoryDialog);

    const updateCategoryDialog = {
        FormComponent: UpdateEntryCategoryForm,
        onSubmit: updateEntryCategory,
        dialogData: { title: getLabel('updateTitleBar') },
        formData: { initialValues: foundCategory },
    };

    const {
        showDialog: showUpdateDialog,
        renderDialogForm: renderUpdateDialogForm
    } = useDialogFormBuilder(updateCategoryDialog);
    
    const deleteCategoryPopup = {
        FormComponent: DeleteConfirmationForm,
        onSubmit: deleteEntryCategory,
        popupData: { title: getLabel('deleteConfirmationText'), formId: 'delete-category-form' },
        formData: { formId: 'delete-category-form', initialValues: foundCategory },
    };

    const {
        showPopup: showDeletePopup,
        renderPopupForm: renderDeletePopupForm,
    } = usePopupFormBuilder(deleteCategoryPopup);

    if (organizationId === false) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    function createEntryCategory(categoryData) {
        const categoryMetadata = {
            organizationId: organizationId,
        };

        const categoryDocument = Object.assign({}, categoryData, categoryMetadata);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Secure Repository Categories',
            action: 'create category',
            metadata: {...categoryDocument},
        };
        
        showToastNotification(`Creating category ${categoryDocument.category}`, NOTIFICATION_INFO);
        
        callCloudRunFunction(currentUser, 'createEntryCategory', categoryDocument).then((result) => {
            showToastNotification('Sucessfully created a new entry category', NOTIFICATION_SUCCESS);
            log.metadata.id = result.uid;
            log.categoryId = result.uid;
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('An error has occurred', NOTIFICATION_ERROR);
        });
    }

    function setUpdateEntryCategory(id) {
        setFocusedCategoryId(id);
        showUpdateDialog();
    }

    function updateEntryCategory(categoryData) {
        const categoryToUpdate = items && items.find(entry => entry.uid === categoryData.id);

        if (!categoryToUpdate) {
            showToastNotification(`No such entry category to update`, NOTIFICATION_ERROR);
            return;
        }

        const categoryMetadata = {
            organizationId: organizationId,
        };

        const categoryDocument = Object.assign({}, categoryData, categoryMetadata);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Secure Repository Categories',
            action: 'update category',
            categoryId: categoryDocument.id,
            metadata: {...categoryDocument}
        };

        showToastNotification(`Updating category ${categoryDocument.category}`, NOTIFICATION_INFO);
        
        callCloudRunFunction(currentUser, 'updateEntryCategory', categoryDocument).then(() => {
            showToastNotification('Sucessfully updated a entry category', NOTIFICATION_SUCCESS);
        }).then(() => {
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('An error has occurred', NOTIFICATION_ERROR);
        });
        setFocusedCategoryId(null);
    }

    const deleteEntryCategoryClicked = (id) => {
        setFocusedCategoryId(id);
        showDeletePopup();
    }

    function deleteEntryCategory(deleteData) {
        const categoryToDelete = items && items.find(category => category.uid === deleteData.id || category.id === deleteData.id);

        if (!categoryToDelete) {
            showToastNotification(`No such category to delete`, NOTIFICATION_ERROR);
            return;
        }
        
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Secure Repository Categories',
            action: 'delete category',
            categoryId: deleteData.id,
            metadata: {...categoryToDelete}
        };
        
        setFocusedCategoryId(null);
        showToastNotification(`Deleting entry category...`, NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'deleteEntryCategory', { id: deleteData.id, organizationId: organizationId }).then(() => {
            showToastNotification(`Entry category has been deleted`, NOTIFICATION_SUCCESS);
        }).then(() => {
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`Entry category could not be deleted`, NOTIFICATION_ERROR);
        });
    }

    const buildFilterSortPanel = () => {
        return (
            <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                { renderFilterSortPanel() }
            </Cell>
        );
    }

    const buildCreateCategoryButton = () => {
        return (
            <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                <ThemedButton className="full-width-field" flat swapTheming primary iconChildren="add" onClick={showCreateDialog}>
                    { getLabel('createButtonText') }
                </ThemedButton>
            </Cell>
        );
    }

    const createEmptyCard = () => {
        const cardInfo = { status: ALL, cardMediaUrl: getAsset('entryCategoriesEmpty'), headerTitle: getLabel('emptyResultsHeader'), subTitle: getLabel('emptyResultsSubheader'), buttonText: getLabel('createButtonText'), buttonClick: showCreateDialog };

        return (<GettingStartedPaper key={cardInfo.status} {...cardInfo}/>);
    }

    const buildCategoriesList = () => {
        return !items || items.length === 0
            ? (<Cell size={12}>{ createEmptyCard() }</Cell>)
            : ( <> { getFilteredAndSortedItems().map(buildCategory) } </> );
    }

    const buildCategory = (category, index) => {
        return (
            <Cell key={`category-card-${index}`} desktopSize={3} tabletSize={2} phoneSize={2}>
                <EntryCategoryCard category={category} canUpdate={true} onUpdate={setUpdateEntryCategory} canDelete={true} onDelete={deleteEntryCategoryClicked}/>
            </Cell>
        );
    }

    function buildPageContent() {
        return (
            <Grid id="organization-entry-categories-grid">
                { buildCreateCategoryButton() }
                { buildFilterSortPanel() }
                { buildCategoriesList() }
            </Grid>
        )
    }

    return (
        <>
            { renderPageContent() }
            { renderCreateDialogForm() }
            { renderUpdateDialogForm() }
            { renderDeletePopupForm() }
        </>
    );
}

export default OrganizationEntryCategories;