import React from 'react';
import { TimePicker } from 'react-md';
import { convertToDate } from '../../utils/dateTime';

const renderTimePicker = ({ input, meta: { touched, invalid, error }, ...props }) => {
    input.value = convertToDate(input.value);
    return (
        <TimePicker
            {...input}
            {...props}
            error={touched && invalid}
            errorText={error}
        />
)};

export default renderTimePicker;