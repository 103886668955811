import React, { PureComponent } from 'react'
import { DialogContainer } from 'react-md';
import ModifyScheduleForm from './FormModifySchedule';

export default class ModifyScheduleDialog extends PureComponent {
    constructor (props) {
        super(props);
        this.dialogConfirm = this.onDialogConfirm.bind(this);
    }

    onDialogConfirm = (e) => {
        const { onConfirm } = this.props;
        const alteredPattern = e.get('alteredPattern');
        const alteredExchangeTimes = e.get('alteredExchangeTimes');

        if (alteredPattern && alteredExchangeTimes) {
            onConfirm(alteredPattern, alteredExchangeTimes);
        }
    }

    render() {
        const { visible, onHide } = this.props;

        return (
            <DialogContainer
                id="modify-schedule-dialog"
                visible={visible}
                onHide={onHide}
                focusOnMount={false}
                height={'600px'}
                width={'600px'}
            >
                <ModifyScheduleForm
                    { ...this.props }
                    onSubmit={this.dialogConfirm}
                />
            </DialogContainer>
        );
    }
}