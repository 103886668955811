import { defineMessages } from 'react-intl';

export default defineMessages({
  mustBe: {
    id: 'app.components.RequestField.mustBe',
    defaultMessage: 'Must be',
  },
  yearMustBe: {
    id: 'app.components.RequestField.yearMustBe',
    defaultMessage: 'Year must be',
  },
  numMustBe: {
    id: 'app.components.RequestField.numMustBe',
    defaultMessage: 'Must be a number',
  },
  chars: {
    id: 'app.components.RequestField.chars',
    defaultMessage: 'characters',
  },
  charOrMore: {
    id: 'app.components.RequestField.charOrMore',
    defaultMessage: 'characters or more',
  },
  charOrLess: {
    id: 'app.components.RequestField.charOrLess',
    defaultMessage: 'characters or less',
  },
  invalidEmail: {
    id: 'app.components.RequestField.invalidEmail',
    defaultMessage: 'Invalid email address',
  },
  invalidPhone: {
    id: 'app.components.RequestField.invalidPhone',
    defaultMessage: 'Invalid phone number',
  },
  invalidNumber: {
    id: 'app.components.RequestField.invalidNumber',
    defaultMessage: 'Must be a number',
  },
  invalidPassword: {
    id: 'app.components.RequestField.invalidPassword',
    defaultMessage: 'Password must be 9 characters and at least 1 numeric.',
  },
  required: {
    id: 'app.components.RequestField.required',
    defaultMessage: 'Required',
  },
  howMuch: {
    id: 'app.components.RequestField.howMuch',
    defaultMessage: 'How much are you asking your coParent to contribute? (optional)',
  },
  notes: {
    id: 'app.components.RequestField.notes',
    defaultMessage: 'Notes/More Info (optional)',
  },
  requestSummary: {
    id: 'app.components.RequestField.requestSummary',
    defaultMessage: 'Request Summary',
  },

  hasEmail: {
    id: 'app.components.RequestField.hasEmail',
    defaultMessage: 'Has Email?',
  },
  email: {
    id: 'app.components.RequestField.requestSummary',
    defaultMessage: 'Email',
  },
  favColor: {
    id: 'app.components.RequestField.favColor',
    defaultMessage: 'Favorite Color',
  },

  red: {
    id: 'app.components.RequestField.red',
    defaultMessage: 'Red',
  },
  blue: {
    id: 'app.components.RequestField.blue',
    defaultMessage: 'Blue',
  },
  green: {
    id: 'app.components.RequestField.green',
    defaultMessage: 'Green',
  },
  submit: {
    id: 'app.components.RequestField.submit',
    defaultMessage: 'Submit',
  },
  clear: {
    id: 'app.components.RequestField.clear',
    defaultMessage: 'Clear Values',
  },
  childNameDialog: {
    id: 'app.components.RequestField.childNameDialog',
    defaultMessage: 'Child Names(s) (optional)',
  },
  uidDoesNotExist: {
    id: 'app.components.RequestField.uidDoesNotExist',
    defaultMessage: 'This uid does not exist',
  },
  existingEmail: {
    id: 'app.components.RequestField.existingEmail',
    defaultMessage: 'This email already exists'
  },
  existingPhone: {
    id: 'app.components.RequestField.existingPhone',
    defaultMessage: 'This phone number already exists'
  },
});