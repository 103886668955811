
import useParticipants from './useParticipants';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import * as REDUX from '../../../constants/redux';

export default function useMainSpeaker() {
    const room = useSelector(state => state.get('global').get('room'));
    const selectedParticipant = useSelector(state => state.get('global').get('selectedParticipant'));
    const dominantSpeaker = useSelector(state => state.get('global').get('dominantSpeaker'));
    const participants = useParticipants();
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(selectedParticipant);
        console.log(dominantSpeaker);
    }, [dominantSpeaker]);
   
    // The participant that is returned is displayed in the main video area. Changing the order of the following
    // variables will change the how the main speaker is determined.
    const mainParticipant = selectedParticipant || dominantSpeaker || participants[0] || room.localParticipant;

    dispatch({ type: REDUX.MAIN_PARTICIPANT, payload: mainParticipant });
}
