const openInNewWindow = (url) => {
    if (!url) return;
    window.open(url, '_blank');
}

const isMatchingArray = (a, b)  => {
    if (!a || !b) {
        return false;
    }

    if (a.length !== b.length) {
        return false;
    }

    const array1 = [...a].sort();
    const array2 = [...b].sort();

    return array1.map((value, index) => array2[index] === value).reduce((prev, curr) => prev && curr, true);
}

const emptyFunction = () => {}

export {
    openInNewWindow,
    isMatchingArray,
    emptyFunction,
};