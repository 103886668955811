import React, { useEffect } from 'react';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { selectCurrentUser, selectOrganizationId } from '../../selectors/global';
import { callCloudRunFunction } from '../../utils/firestore';
import UpdateThemeForm from './UpdateThemeForm';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants/notifications';
import { uploadFile } from '../../utils/fileUpload';

function OrganizationTheme() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    
    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent, buildPageContent });

    useEffect(() => {
        finishedLoading();
    }, []);

    const updateTheme = (themeData) => {
        const { useDefaultLogo, currentCompanyLogoUploadId, updatedCompanyLogo } = themeData;
        delete themeData['useDefaultLogo'];
        delete themeData['currentCompanyLogoUploadId'];
        delete themeData['updatedCompanyLogo'];

        const themeMetadata = {
            organizationId: organizationId,
        };

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Branding',
            action: 'update organization theme',
            metadata: {...themeData},
        };

        showToastNotification(`Updating theme...`, NOTIFICATION_INFO);

        const promises = [];

        if (useDefaultLogo && currentCompanyLogoUploadId) {
            // Delete the current logo, ignore the updatedCompany logo (should be null);
            promises.push(callCloudRunFunction(currentUser, 'deleteUpload', { uid: currentCompanyLogoUploadId }).then(() => {
                themeData.companyLogoUploadId = null;
                log.metadata.companyLogoUploadId = null;
                log.metadata.useDefaultLogo = true;
            }));
        } else if (updatedCompanyLogo) {
            // Save new logo, delete current one if exists
            const basePath = `Organizations/${organizationId}/theme`;
    
            promises.push(uploadFile(updatedCompanyLogo, basePath, currentUser, 'Company Logo', []).then((uploadId) => {
                themeData.companyLogoUploadId = uploadId;
                log.metadata.companyLogoUploadId = uploadId;
            }));
    
            // Delete current company logo if exists
            if (currentCompanyLogoUploadId) {
                promises.push(callCloudRunFunction(currentUser, 'deleteUpload', { uid: currentCompanyLogoUploadId }));
            }
        }

        // Else; no logo changes
        
        Promise.all(promises).then(() => {
            const themeDocument = Object.assign({}, themeData, themeMetadata);
            return callCloudRunFunction(currentUser, 'updateOrganizationTheme', themeDocument);
        }).then(() => {
            showToastNotification('Sucessfully updated theme', NOTIFICATION_SUCCESS);
            callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Failed to update theme', NOTIFICATION_ERROR);
        });
    }

    function buildPageContent() {
        return (
            <UpdateThemeForm onSubmit={updateTheme}/>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default OrganizationTheme;