function validate (values) {
    const errors = {};

    if (!values.displayName) {
        errors.displayName = "Name is required";
    }

    if (!values.organizationId) {
        errors.organizationId = "Organization is required";
    }
    
    if (!values.orgRoles) {
        errors.orgRoles = "A role is required"
    }

    if (values.requiresGroup && !values.orgGroups) {
        errors.orgGroups = "A group is required"
    }
    
    return errors;
}

export {
    validate,
};