import React from 'react';
import usePageFramework from '../../hooks/usePageFramework';
import { selectNotifications } from '../../selectors/global';
import EmptyNotifications from './EmptyNotifications';
import NotificationList from './NotificationList';
import { Card, CardText, CardTitle } from 'react-md';
import { Desktop, TabletOrMobile } from '../ResponsiveMedia';
import useCustomLabels from '../../hooks/useCustomLabels';

function HomeNotificationsCard() {
    const {
        selector,
    } = usePageFramework();
    
    const notifications = selector(selectNotifications());

    const {
        getLabel,
    } = useCustomLabels('Notifications');

    const buildNotificationSection = () => {
        return (
            <>
                {
                    !notifications || notifications.length === 0
                        ? <EmptyNotifications/>
                        : <NotificationList notifications={notifications}/>
                }
            </>
        );
    }

    return (
        <Card>
            <CardTitle
                title={getLabel('notificationsHeader')}
                subtitle={getLabel('notificationsSubheader')}
            />
            <Desktop>
                <CardText style={{ height: '500px', overflowY: 'auto'}}>
                    { buildNotificationSection() }
                </CardText>
            </Desktop>
            <TabletOrMobile>
                <CardText style={{ height: '350px', overflowY: 'auto'}}>
                    { buildNotificationSection() }
                </CardText>
            </TabletOrMobile>
        </Card>
    );
}

export default HomeNotificationsCard