import React, { createContext, useContext, useState } from 'react';

export const StateContext = createContext({
    error: null,
    setError: null,
    getToken: null,
    isFetching: false
});

export default function AppStateProvider(props) {
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [room, setRoom] = useState('');
    const [token, setToken] = useState('');
    
    const contextValue = Object.assign(Object.assign({}, { error, setError, isFetching, room, token }), { getToken: async (identity, roomName) => {
        // TODO: Add Code here to get token from cloud functions
        const headers = new window.Headers();
        const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
        const params = new window.URLSearchParams({ identity, roomName });
        return fetch(`${endpoint}?${params}`, { headers }).then(res => res.text());
    }});

    const getToken = (name, room) => {
        setIsFetching(true);
        setRoom(room);

        return contextValue.getToken(name, room).then((res) => {
            setIsFetching(false);
            setToken(res);
            return res;
        }).catch((err) => {
            setError(err);
            setIsFetching(false);
            return Promise.reject(err);
        });
    };

    return (
        <StateContext.Provider value={{ ...contextValue, getToken }}>
            {props.children}
        </StateContext.Provider>
    );
}

export function useAppState() {
    const context = useContext(StateContext);
    
    if (!context) {
        throw new Error('useAppState must be used within the AppStateProvider');
    }
    return context;
}
