import React, { useEffect, useState } from 'react';
import useLoadingPageFramework from "../../hooks/useLoadingPageFramework";
import { selectCurrentUser, selectOrganizationId } from "../../selectors/global";
import usePageFramework from "../../hooks/usePageFramework";
import { callCloudRunFunction } from '../../utils/firestore';
import { Tab, TabPanel, Grid } from 'react-md';
import { setToolbarTitle, setToolbarButtons } from '../../actions/global';
import SystemLabels from './SystemLabels';
import SystemTheme from './SystemTheme';
import ThemedTabs from '../../components/ThemedTabs';
import SystemAssets from './SystemAssets';

function SystemBranding() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const [ activeTabId, setActiveTabId ] = useState(0);

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        const toolBarTitle = 'Default System Branding';
        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];
        dispatch(setToolbarTitle(toolBarTitle));
        dispatch(setToolbarButtons(toolBarButtons));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'System Branding',
            action: 'visit page',
        };

        finishedLoading();
        callCloudRunFunction(currentUser, 'logProUserAction', log);

        return () => {
            dispatch(setToolbarTitle(''));
            dispatch(setToolbarButtons([]));
        }
    }, []);

    function buildPageContent() {
        const tabs = [
            { id: 'system-labels', label: 'Labels', content: <SystemLabels/> },
            { id: 'system-theme', label: 'Theme', content: <SystemTheme/> },
            { id: 'system-assets', label: 'Assets', content: <SystemAssets/> },
        ];

        return (
            <>
                <ThemedTabs tabId='system-branding-tabs' onTabChange={setActiveTabId}>
                    { tabs.map((x) => <Tab id={x.id} key={x.id} label={x.label}/>) }
                </ThemedTabs>
                <TabPanel id={`system-branding-tab-content`} controlledById={tabs[activeTabId].id}>
                    { tabs[activeTabId].content }
                </TabPanel>
            </>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default SystemBranding;