import React, { useState, useEffect } from 'react'
import { Grid, Cell, Button } from 'react-md';
import { selectOrganizationId, selectDataItemsFromComponent, selectCurrentUser } from '../../selectors/global';
import { setToolbarTitle, setToolbarButtons } from '../../actions/global';
import { NAV_DEFAULT } from '../../constants/navigation';
import OrganizationTypeCard from '../../components/OrganizationTypeCard';
import useDialogFormBuilder from '../../hooks/useDialogFormBuilder';
import AddOrgTypeForm from './AddOrgTypeForm';
import UpdateOrgTypeForm from './UpdateOrgTypeForm';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_INFO } from '../../constants/notifications';
import DetailedOrgTypeCard from './DetailedOrgTypeCard';
import TwoColumnLayout from '../../pageLayouts/TwoColumnLayout';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import usePageFramework from '../../hooks/usePageFramework';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';
import DeleteConfirmationForm from '../../components/Forms/DeleteConfirmationForm';
import { callCloudRunFunction } from '../../utils/firestore';
import { orgIsCoParenter } from '../../utils/permissions';

const COMPONENT_NAME = 'OrganizationTypes';

function OrganizationTypes () {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const dataItems = selector(selectDataItemsFromComponent(COMPONENT_NAME));

    const [ focusedOrgTypeId, setFocusedOrgTypeId ] = useState(null);

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        const toolBarTitle = 'Organization Types';
        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];
        dispatch(setToolbarTitle(toolBarTitle));
        dispatch(setToolbarButtons(toolBarButtons));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Types',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);

        finishedLoading();
        return function cleanup() {
            dispatch(setToolbarButtons([]));
        }
    }, []);

    const foundOrgType = dataItems && dataItems.find(orgType => orgType.id === focusedOrgTypeId);

    // Setup for adding Organization Type dialog form
    const addOrgTypeDialog = {
        FormComponent: AddOrgTypeForm,
        onSubmit: addOrganizationType,
        dialogData: { title: 'New Organization Type' },
    };

    const {
        showDialog: showAddDialog,
        renderDialogForm: renderAddDialogForm
    } = useDialogFormBuilder(addOrgTypeDialog);

    // Setup for updating an Organization Type dialog form
    const updateOrgTypeDialog = {
        FormComponent: UpdateOrgTypeForm,
        onSubmit: updateOrganizationType,
        dialogData: { title: 'Update Organization Type' },
        formData: { initialValues: foundOrgType },
    };

    const {
        showDialog: showUpdateDialog,
        renderDialogForm: renderUpdateDialogForm
    } = useDialogFormBuilder(updateOrgTypeDialog);

    const deleteOrgTypePopup = {
        FormComponent: DeleteConfirmationForm,
        onSubmit: deleteOrganizationType,
        popupData: { title: `Type \'Delete\' to delete ${foundOrgType ? foundOrgType.orgTypeName : ''}`, formId: 'delete-org-type-form' },
        formData: { formId: 'delete-org-type-form', initialValues: foundOrgType },
    }

    const {
        showPopup: showDeletePopup,
        renderPopupForm: renderDeletePopupForm,
    } = usePopupFormBuilder(deleteOrgTypePopup);

    function addOrganizationType(orgType) {
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Types',
            action: 'create organization type',
            metadata: {...orgType},
        };
        
        showToastNotification(`Creating ${orgType.orgTypeName}...`, NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'createOrganizationType', orgType).then((result) => {
            showToastNotification(`Sucessfully created ${orgType.orgTypeName}`, NOTIFICATION_SUCCESS);
            log.metadata.id = result.uid;
            log.orgTypeId = result.uid;
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    function updateOrganizationType(orgType) {
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Types',
            action: 'update organization type',
            orgTypeId: orgType.id,
            metadata: {...orgType},
        };

        showToastNotification(`Updating ${orgType.orgTypeName}...`, NOTIFICATION_INFO)

        callCloudRunFunction(currentUser, 'updateOrganizationType', orgType).then((result) => {
            showToastNotification(`Sucessfully updated ${orgType.orgTypeName}`, NOTIFICATION_SUCCESS);
        }).then(() => {
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    if (!orgIsCoParenter(organizationId)) {
        history.push(NAV_DEFAULT);
        return(<></>);
    }

    const buildAddOrgType = () => {
        return (
            <Cell size={12}>
                <Button className="full-width-field" flat swapTheming primary iconChildren="add" onClick={showAddDialog}>
                    Add a new organization type
                </Button>
            </Cell>
        );
    }

    const returnToListButton = () => {
        return (
            <Cell size={12}>
                <Button className="full-width-field" flat swapTheming primary onClick={() => setFocusedOrgTypeId(null)}>
                    Return to list
                </Button>
            </Cell>
        );
    }

    const deleteOrgTypeClicked = (id) => {
        setFocusedOrgTypeId(id);
        showDeletePopup();
    }

    function deleteOrganizationType(deleteData) {
        const foundOrgType = dataItems.find(orgType => orgType.id === deleteData.id);

        if (!foundOrgType) {
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
            return;
        }

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Types',
            action: 'delete organization type',
            orgTypeId: deleteData.id,
        };

        showToastNotification(`Deleting ${foundOrgType.orgTypeName}...`, NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'deleteOrganizationType', foundOrgType).then((result) => {
            showToastNotification(`Sucessfully deleted ${foundOrgType.orgTypeName}`, NOTIFICATION_SUCCESS);
        }).then(() => {
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    const buildOrgTypeList = () => {
        return !dataItems || dataItems.length === 0
            ? (<p>No Organization Types Available</p>)
            : dataItems.map((orgType, index) => buildOrgType(orgType));
    }

    const buildOrgType = (orgType, index) => {
        return (
            <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                <OrganizationTypeCard onViewClick={setFocusedOrgTypeId} {...orgType} />
            </Cell>
        );
    }

    const buildListSection = () => {
        return (
            <Grid>
                { buildAddOrgType() }
                { buildOrgTypeList() }
            </Grid>
        );
    }

    const buildDetailedOrgType = () => {
        if (!foundOrgType) {
            return <>Click the cards on the left to view the details of an organization type</>;
        }

        return (
            <Cell size={12}>
                <DetailedOrgTypeCard organizationType={foundOrgType} onEdit={showUpdateDialog} onDelete={deleteOrgTypeClicked}/>
            </Cell>
        );
        
    }

    const buildTabletMobileSection = () => {
        return !foundOrgType
            ? ( buildListSection() )
            : (
                <>
                    { returnToListButton() }
                    { buildDetailedOrgType() }
                </>
            );
    }
    function buildPageContent() {
        return (
            <TwoColumnLayout buildLeftColumn={buildListSection} buildRightColumn={buildDetailedOrgType} buildTabletMobile={buildTabletMobileSection}/>
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderAddDialogForm() }
            { renderUpdateDialogForm() }
            { renderDeletePopupForm() }
        </>
    )

}

export default OrganizationTypes;