import React, { PureComponent } from 'react';
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Drawer, Toolbar, TabsContainer, Tabs, Tab, ExpansionList, ExpansionPanel } from 'react-md';
import { selectUniqueChannelName, selectDataItemsFromComponent } from '../../selectors/global.js';
import { emptyFunction } from '../../utils';
import CheckInsDashboard from './CheckInsDashboard';
import ChildrenDashboard from './ChildrenDashboard';
import CoParentDashboard from './CoParentDashboard';
import CalendarDashboard from './CalendarDashboard';
import ScheduleListDashboard from './ScheduleListDashboard.js';

const COMPONENT_NAME = 'GetHelps';

class MessageDashboard extends PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            getHelpDetailsExpanded: false,
            tabIdFocused: null,
            coParentExpanded: false,
            childrenExpanded: false,
            calendarExpanded: false,
            checkInsExpanded: false,
            scheduleExpanded: false,
        };
    }
    
    componentDidUpdate(prevProps) {
        const { members: nextMembers } = this.props;
        const { members: prevMembers } = prevProps;

        if (this.membersHaveChanged(prevMembers, nextMembers)) {
            const firstClient = nextMembers.find((member) => !member.isProfessional);
            if (firstClient) {
                this.setState({ tabIdFocused: firstClient.id });
            }
        }
    }

    membersHaveChanged = (prevMembers, nextMembers) => {
        return prevMembers && prevMembers.length !== nextMembers.length;
    }

    invitedSelf(x) {
        return x.title === 'Invited' && x.answer.includes('your self');
    }

    showDetails = (getHelp) => {
        return getHelp.details.map((x) => 
            !x.answer || this.invitedSelf(x) ? null: (
            <div key={`dashboard-getHelp-details-section-${x.title}`}>
                <h4>{x.title}</h4>
                <p>{x.answer}</p>
            </div>)
        );
    }

    showDisplayDetails = (getHelp) => {
        return getHelp.displayDetails.map((x) => (
            <div key={`dashboard-getHelp-details-section-${x.title}`}>
                <h4>{x.title}</h4>
                <p>{x.value}</p>
            </div>
        ));
    }

    renderGetHelpDetails = () => {
        const { uniqueChannelName, dataItemsGetHelp } = this.props;

        const getHelp = dataItemsGetHelp && dataItemsGetHelp.find((getHelpItem) => {
            return getHelpItem.chatId === uniqueChannelName.chatId;
        });

        if (!getHelp) {
            return (<><h4>{'No Mediation Details Available'}</h4></>);
        }

        return getHelp.details ? this.showDetails(getHelp) : this.showDisplayDetails(getHelp);

    }

    onExpanderClick = (evt) => {
        const idFromButton = evt.target.parentElement.id;
        const idFromIcon = evt.target.parentElement.parentElement.id;
        const obj = {};

        idFromButton === '' ? obj[idFromIcon] = !this.state[idFromIcon] : obj[idFromButton] = !this.state[idFromButton];
        
        this.setState(obj);
    }

    onTabChange = (newActiveTabIndex, tabId, tabControlsId, tabChildren, event) => {
        this.setState({ tabIdFocused: tabId });
    }

    renderTabs = (member) => {
        const { tabIdFocused } = this.state;
        return (
            <Tab id={member.id} key={member.id} label={member.friendlyName}>
                { member.id === tabIdFocused ? this.renderTabItems(member) : null }
            </Tab>
        );
    }

    renderTabItems = (member) => {
        const { coParentExpanded, childrenExpanded, calendarExpanded, checkInsExpanded, scheduleExpanded } = this.state;

        const tabItems = [
            { id: 'childrenExpanded', componentName: ChildrenDashboard, label: 'Children', isExpanded: childrenExpanded },
            { id: 'calendarExpanded', componentName: CalendarDashboard, label: 'Calendar', isExpanded: calendarExpanded },
            { id: 'scheduleExpanded', componentName: ScheduleListDashboard, label: 'Schedule Listing', isExpanded: scheduleExpanded },
            { id: 'checkInsExpanded', componentName: CheckInsDashboard, label: 'Check-Ins', isExpanded: checkInsExpanded },
            { id: 'coParentExpanded', componentName: CoParentDashboard, label: 'coParent Messages', isExpanded: coParentExpanded },
        ];

        return (
            <ExpansionList id="message-dashboard-coparent-info" className="message-drawer-item">
                { tabItems.map((item) => {
                    return (  
                        <ExpansionPanel id={item.id} key={item.id} label={item.label} expanded={item.isExpanded} onExpandToggle={emptyFunction} onClick={this.onExpanderClick} footer={null}>
                            <item.componentName client={member}/>
                        </ExpansionPanel>
                    );
                }) }
            </ExpansionList>
        );
    }

    render() {
        const { visible, drawerNavButton } = this.props;
        const { members } = this.props;
        const drawerPosition = 'right';

        const contents = [
            <ExpansionList id="message-dashboard-gethelp-info" key='message-dashboard-gethelp-info' className="message-drawer-item">
                <ExpansionPanel id={'detailsExpanded'} label={'Mediation Details'} expanded={this.getHelpDetailsExpanded} onExpandToggle={emptyFunction} onClick={this.onExpanderClick} footer={null}>
                    { this.renderGetHelpDetails() }
                </ExpansionPanel>
            </ExpansionList>,
            <TabsContainer colored onTabChange={this.onTabChange} key='message-dashboard-cp-tabs'>
                <Tabs tabId={'message-dashboard-member-tabs'}>
                    {
                        members && members.map((member) => {
                            return !member.isProfessional ? this.renderTabs(member) : null;
                        })
                    }
                </Tabs>
            </TabsContainer>
        ];

        return (
            <Drawer
                className="message-drawer"
                id="message-dashboard-drawer"
                type={Drawer.DrawerTypes.TEMPORARY}
                visible={visible}
                position={drawerPosition}
                navItems={contents}
                onVisibilityChange={emptyFunction}
                overlay={false}
                header={(
                    <Toolbar
                    title="Mediation Information"
                    nav={drawerNavButton}
                    className="md-divider-border"/>
                )}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    uniqueChannelName: selectUniqueChannelName(),
    dataItemsGetHelp: selectDataItemsFromComponent(COMPONENT_NAME),
});

export default connect(mapStateToProps, null)(MessageDashboard);