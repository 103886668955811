import React from 'react';
import { useSelector } from 'react-redux';
import MainParticipantInfo from '../MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks';
export default function MainParticipant() {
    const mainParticipant = useSelector(state => state.get('global').get('mainParticipant'));
    
    return (
        /* audio is disabled for this participant component because this participant's audio 
        is already being rendered in the <ParticipantStrip /> component.  */
        <MainParticipantInfo participant={mainParticipant}>
            <ParticipantTracks participant={mainParticipant} isRightPane={true} disableAudio videoPriority="high" />
        </MainParticipantInfo>
    );
}
