import React from 'react';
import { FontIcon } from 'react-md';
import UserCard from '../UserCard';
import CompanyLogo from '../CompanyLogo';
import ThemedButton from '../Buttons/ThemedButton';

function LayoutPreview(props) {
    const { updatedCompanyLogo, useDefaultLogo, primaryColor, primaryTextColor, secondaryColor, secondaryTextColor, secondaryTextActiveColor, buttonColor } = props;

    const containerStyle = { height: '400px', maxWidth: '900px', display: 'flex', borderStyle: 'solid' };
    const navStyle = { height: '100%', width: '25%', backgroundColor: secondaryColor };
    const navActiveItemStyle = { padding: '15px 25px', height: '25px', color: secondaryTextActiveColor};
    const navItemStyle = { padding: '15px 25px', height: '25px', color: secondaryTextColor};
    const pageStyle = { display: 'block', height: '100%', width: '75%' };
    const titleBarStyle = { height: '25%', backgroundColor: primaryColor, flex: 'auto' };
    const titleBarContentStyle = { padding: '25px', color: primaryTextColor, float: 'left' };
    const titleBarItemStyle = { padding: '25px', height: '20px', color: primaryTextColor, float: 'right' };
    const contentStyle = { height: '75%', padding: '10px', backgroundColor: 'white' };
    const swappedButtonStyle = { color: '#FFFFFF', backgroundColor: buttonColor, margin: '5px' };
    const buttonStyle = { color: buttonColor, backgroundColor: '#FFFFFF', margin: '5px' };
    
    const imageId = 'theme-preview-image';

    if (updatedCompanyLogo) {
        var reader = new FileReader();
        reader.onload = (e) => {
            if (document.getElementById(imageId)) {
                document.getElementById(imageId).src = e.target.result;
            }
        }
        reader.readAsDataURL(updatedCompanyLogo);
    }

    return (
        <>
            <b>Theme Preview. Logo and text positions are approximations.</b>
            <div style={containerStyle}>
                <div style={navStyle}>
                    <CompanyLogo logoFile={updatedCompanyLogo} useDefaultLogo={useDefaultLogo}/>
                    <UserCard enabledMenu={false} style={{ backgroundColor: secondaryColor, color: secondaryTextColor }}/>
                    <p style={navActiveItemStyle}>Current Page</p>
                    <p style={navItemStyle}>Example Page 1</p>
                    <p style={navItemStyle}>Example Page 2</p>
                </div>
                <div style={pageStyle}>
                    <div style={titleBarStyle}>
                        <p className='md-title' style={titleBarContentStyle}>Current Page Title</p>
                        <FontIcon style={titleBarItemStyle}>notifications</FontIcon>
                    </div>
                    <div style={contentStyle}>
                        <ThemedButton raised style={buttonStyle}> Example button 1 </ThemedButton>
                        <ThemedButton raised style={swappedButtonStyle}> Example button 2 </ThemedButton>
                        <ThemedButton icon style={buttonStyle}> lock </ThemedButton>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LayoutPreview;