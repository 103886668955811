import React, { PureComponent } from 'react'
import { Button, Grid, Cell } from 'react-md';
import { Field, reduxForm } from 'redux-form/immutable';
import { buildVisitBubble, buildDayBubble } from './ScheduleBubble';
import { isExchange, isVisit, isCP1, isCP2, buildInnerBubbleText, } from './SchedulePattern'
import ScheduleLegend from './ScheduleLegend';
import { DAYS_ABBREV } from '../../constants/lookupInfo';
import { PARENT_ONE_VISIT, PARENT_TWO_VISIT, PARENT_ONE_DAY, PARENT_TWO_DAY, PARENT_ONE_PICKUP, PARENT_TWO_PICKUP, NUM_DAYS_PER_WEEK } from '../../constants/schedule';
import renderTimePicker from '../RequestFields/renderTimePicker';
import { convert12To24Hour, convert24To12Hour, convertToDate } from '../../utils/dateTime';
import moment from 'moment';

const validate = (values) => {
    const errors = {};

    if (values.has('startTime') && values.has('endTime')) {
        const startTime = moment(convertToDate(values.get('startTime')));
        const endTime = moment(convertToDate(values.get('endTime')));

        if (startTime.isAfter(endTime, 'minute')) {
            errors.startTime = 'Start Time is after End Time';
            errors.endTime = 'End Time is before Start Time';
        }
    }
    return errors;
}

class FormModifySchedule extends PureComponent {
    constructor (props) {
        super(props);
        const { pattern, exchangeTimes, index } = this.props;

        this.state = {
            alteredPattern: pattern.slice(),
            alteredExchangeTimes: exchangeTimes.slice(),
        };

        this.fullDaySwap = this.onFullDaySwap.bind(this);
        this.fullDayInsert = this.onFullDayInsert.bind(this);

        this.pickupDaySwapCP1 = this.onPickupDaySwapCP1.bind(this);
        this.pickupDaySwapCP2 = this.onPickupDaySwapCP2.bind(this);
        this.pickupDayChangeTime = this.onPickupDayChangeTime.bind(this);

        this.visitDayRemove = this.onVisitDayRemove.bind(this);
        this.visitDayChangeStartTime = this.onVisitDayChangeStartTime.bind(this);
        this.visitDayChangeEndTime = this.onVisitDayChangeEndTime.bind(this);
        
        this.props.change('alteredPattern', pattern);
        this.props.change('alteredExchangeTimes', exchangeTimes);

        const item = pattern[index];
        if (isExchange(item)) {
            this.props.change('exchangeTime', exchangeTimes[index].trim());
        }
        if (isVisit(item)) {
            const [startTime, endTime] = exchangeTimes[index].split(',');
            this.props.change('startTime', startTime.trim());
            this.props.change('endTime', endTime.trim());
        }
    }

    getPreviousIndex = () => {
        const { index, pattern } = this.props;
        return index - 1 < 0 ? pattern.length - 1 : index - 1;
    }

    getNextIndex = () => {
        const { index, pattern } = this.props;
        return index + 1 >= pattern.length ? 0 : index + 1;
    }

    onFullDaySwap = () => {
        const { index } = this.props;
        const { alteredPattern } = this.state;
        this.onSwapParentDay(isCP2(alteredPattern[index]));
    }

    onFullDayInsert = () => {
        const { index, defaultTime } = this.props;
        const { alteredPattern, alteredExchangeTimes } = this.state;

        let insertedPattern = alteredPattern.slice();
        let insertedExchangeTimes = alteredExchangeTimes.slice();

        insertedPattern[index] = isCP2(insertedPattern[index]) ? PARENT_ONE_VISIT : PARENT_TWO_VISIT;;
        insertedExchangeTimes[index] = this.makeDefaultVisitTime();

        this.setState({
            alteredPattern: insertedPattern,
            alteredExchangeTimes: insertedExchangeTimes,
        });

        this.props.change('alteredPattern', insertedPattern);
        this.props.change('alteredExchangeTimes', insertedExchangeTimes);
        this.props.change('startTime', defaultTime);
        this.props.change('endTime', defaultTime);
    }

    onPickupDaySwapCP1 = () => {
        this.onSwapParentDay(true);
    }

    onPickupDaySwapCP2 = () => {
        this.onSwapParentDay(false);
    }

    onPickupDayChangeTime = (value, timestamp, event) => {
        const { index } = this.props;
        const { alteredExchangeTimes } = this.state;
        const newExchangeTime = convert12To24Hour(value).trim();

        let changedExchangeTimes = alteredExchangeTimes.slice();
        changedExchangeTimes[index] = newExchangeTime;

        this.setState({
            alteredExchangeTimes: changedExchangeTimes
        });

        this.props.change('exchangeTime', newExchangeTime);
        this.props.change('alteredExchangeTimes', changedExchangeTimes);
    }

    onVisitDayRemove = () => {
        const { index } = this.props;
        const { alteredPattern, alteredExchangeTimes } = this.state;

        let insertedPattern = alteredPattern.slice();
        let insertedExchangeTimes = alteredExchangeTimes.slice();

        insertedPattern[index] = isCP2(insertedPattern[index]) ? PARENT_ONE_DAY : PARENT_TWO_DAY;;
        insertedExchangeTimes[index] = null;

        this.setState({
            alteredPattern: insertedPattern,
            alteredExchangeTimes: insertedExchangeTimes,
        });

        this.props.change('alteredPattern', insertedPattern);
        this.props.change('alteredExchangeTimes', insertedExchangeTimes);
    }
    
    onVisitDayChangeStartTime = (value, timestamp, event) => {
        const { index } = this.props;
        const { alteredExchangeTimes } = this.state;
        const newStartTime = convert12To24Hour(value).trim();
        
        let changedExchangeTimes = alteredExchangeTimes.slice();
        let exchangeTime = changedExchangeTimes[index].split(',');
        changedExchangeTimes[index] = `${newStartTime},${exchangeTime[1].trim()}`;

        this.setState({
            alteredExchangeTimes: changedExchangeTimes
        });

        this.props.change('startTime', newStartTime);
        this.props.change('alteredExchangeTimes', changedExchangeTimes);
    }
    
    onVisitDayChangeEndTime = (value, timestamp, event) => {
        const { index } = this.props;
        const { alteredExchangeTimes } = this.state;
        const newEndTime = convert12To24Hour(value).trim();
        
        let changedExchangeTimes = alteredExchangeTimes.slice();
        let exchangeTime = changedExchangeTimes[index].split(',');
        changedExchangeTimes[index] = `${exchangeTime[0].trim()},${newEndTime}`;

        this.setState({
            alteredExchangeTimes: changedExchangeTimes
        });

        this.props.change('endTime', newEndTime);
        this.props.change('alteredExchangeTimes', changedExchangeTimes);
    }

    // Passed in bool tells us to which coParent we want the day to swap to.
    // Check the previous and next in the pattern to ensure that they are consistent.
    onSwapParentDay = (swapToCP1) => {
        const { index } = this.props;
        const { alteredPattern, alteredExchangeTimes } = this.state;
        let swappedPattern = alteredPattern.slice();
        let swappedTimes = alteredExchangeTimes.slice();
        
        this.matchForward(this.getPreviousIndex(), swapToCP1, swappedPattern, swappedTimes);
        this.matchBackward(this.getNextIndex(), swapToCP1, swappedPattern, swappedTimes);

        swappedPattern[index] = swapToCP1 ? PARENT_ONE_DAY : PARENT_TWO_DAY;
        swappedTimes[index] = null;

        this.setState({
            alteredPattern: swappedPattern,
            alteredExchangeTimes: swappedTimes,
        });

        this.props.change('alteredPattern', swappedPattern);
        this.props.change('alteredExchangeTimes', swappedTimes);
    }

    // Converts the pattern at the given index such that the right half does or does not match to CP1
    matchForward = (index, matchForwardToCP1, swappedPattern, swappedTimes) => {
        let item = swappedPattern[index];
        let exchangeTime = swappedTimes[index];

        if (matchForwardToCP1) {
            if (isCP2(item) && !isExchange(item) && !isVisit(item)) {
                item = PARENT_ONE_PICKUP;
                exchangeTime = this.makeDefaultExchangeTime();
            } else if (isCP2(item) && isExchange(item)) {
                item = PARENT_ONE_DAY;
                exchangeTime = null;
            } else if (isCP1(item) && isVisit(item)) {
                item = PARENT_ONE_PICKUP;
                exchangeTime = this.makeDefaultExchangeTime();
            }
        } else {
            if (isCP1(item) && !isExchange(item) && !isVisit(item)) {
                item = PARENT_TWO_PICKUP;
                exchangeTime = this.makeDefaultExchangeTime();
            } else if (isCP1(item) && isExchange(item)) {
                item = PARENT_TWO_DAY;
                exchangeTime = null;
            } else if (isCP2(item) && isVisit(item)) {
                item = PARENT_TWO_PICKUP;
                exchangeTime = this.makeDefaultExchangeTime();
            }
        }

        swappedPattern[index] = item;
        swappedTimes[index] = exchangeTime;
    }

    // Converts the pattern at the given index such that the left half does or does not match to CP1
    matchBackward = (index, matchBackwardToCP1, swappedPattern, swappedTimes) => {
        let item = swappedPattern[index];
        let exchangeTime = swappedTimes[index];
        
        if (matchBackwardToCP1) {
            if (isCP2(item) && !isExchange(item) && !isVisit(item)) {
                item = PARENT_TWO_PICKUP;
                exchangeTime = this.makeDefaultExchangeTime();
            } else if (isCP1(item) && isExchange(item)) {
                item = PARENT_ONE_DAY;
                exchangeTime = null;
            } else if (isCP1(item) && isVisit(item)) {
                item = PARENT_TWO_PICKUP;
                exchangeTime = this.makeDefaultExchangeTime();
            }
        } else {
            if (isCP1(item) && !isExchange(item) && !isVisit(item)) {
                item = PARENT_ONE_PICKUP;
                exchangeTime = this.makeDefaultExchangeTime();
            } else if (isCP2(item) && isExchange(item)) {
                item = PARENT_TWO_DAY;
                exchangeTime = null;
            } else if (isCP2(item) && isVisit(item)) {
                item = PARENT_ONE_PICKUP;
                exchangeTime = this.makeDefaultExchangeTime();
            }
        }

        swappedPattern[index] = item;
        swappedTimes[index] = exchangeTime;
    }

    makeDefaultExchangeTime = () => {
        const { defaultTime } = this.props;
        return `${defaultTime}`;
    }

    makeDefaultVisitTime = () => {
        const { defaultTime } = this.props;
        return `${defaultTime}, ${defaultTime}`;
    }

    buildCoParents = () => {
        const { p1Name, p2Name } = this.props;

        return (
            <ul id="pattern-modify-coparents" className="flex-container-cal schedule-legend-row">
                <ScheduleLegend id="coparent-one-name" text={p1Name} icon="lens" iconClassName="legend-p1"/>
                <ScheduleLegend id="coparent-two-name" text={p2Name} icon="lens" iconClassName="legend-p2"/>
            </ul>
        );
    }

    buildBubble = (index) => {
        const { alteredPattern, alteredExchangeTimes } = this.state;
        const item = alteredPattern[index];
        const itemClass = `schedule-bubble-${item}`;
        const dayAbbrev = DAYS_ABBREV[index % NUM_DAYS_PER_WEEK];

        if (isVisit(item)) {
            const split = alteredExchangeTimes[index].split(',');
            const exchangeTimeString = `${convert24To12Hour(split[0])} to ${convert24To12Hour(split[1])}`;
            const bubbleText = buildInnerBubbleText(dayAbbrev, exchangeTimeString);
            return buildVisitBubble(itemClass, bubbleText, () => {});
        } else {
            const exchangeTimeString = `${convert24To12Hour(alteredExchangeTimes[index])}`;
            const bubbleText = buildInnerBubbleText(dayAbbrev, exchangeTimeString);
            return buildDayBubble(itemClass, bubbleText, () => {});
        }
    }

    buildModifyActions = () => {
        const { index } = this.props;
        const { alteredPattern } = this.state;
        const item = alteredPattern[index];

        return (
            <div>
                { !isExchange(item) && !isVisit(item) && this.buildFullDayAction() }
                { isExchange(item) && this.buildPickupDayAction() }
                { isVisit(item) && this.buildVisitDayAction() }
            </div>
        );
    }

    buildFullDayAction = () => {
        return (
            <Grid id="full-day-actions">
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <Button id="full-day-swap-cp" raised className="btn-fill-width" onClick={this.fullDaySwap}>Swap Day</Button>
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <Button id="full-day-insert-visit" raised className="btn-fill-width" onClick={this.fullDayInsert}>Insert Visit</Button>
                </Cell>
            </Grid>
        );
    }

    buildPickupDayAction = () => {
        return (
            <Grid id="pickup-day-actions">
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <Button id="pickup-day-swap-cp1" raised className="btn-fill-width" onClick={this.pickupDaySwapCP1}>Swap Day to coParent 1</Button>
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <Button id="pickup-day-swap-cp2" raised className="btn-fill-width" onClick={this.pickupDaySwapCP2}>Swap Day to coParent 2</Button>
                </Cell>
                <Cell id="pickup-day-change-time" desktopSize={12} tabletSize={8} phoneSize={4}>
                    <Field
                        onChange={this.pickupDayChangeTime}
                        id={`exchangeTime`}
                        name={`exchangeTime`}
                        label="Exchange Time"
                        component={renderTimePicker}
                        disableScrollLocking={true}
                    />
                </Cell>
            </Grid>
        );
    }

    buildVisitDayAction = () => {
        return (
            <Grid id="visit-day-actions">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <Button id="visit-day-remove-visit" raised className="btn-fill-width" onClick={this.visitDayRemove}>
                        Remove Visit
                    </Button>
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <Field
                        onChange={this.visitDayChangeStartTime}
                        id="visit-day-change-start-time"
                        name="startTime"
                        label="Start Time"
                        component={renderTimePicker}
                        disableScrollLocking={true}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <Field
                        onChange={this.visitDayChangeEndTime}
                        id="visit-day-change-end-time"
                        name="endTime"
                        label="End Time"
                        component={renderTimePicker}
                        disableScrollLocking={true}
                    />
                </Cell>
            </Grid>
        );
    }

    buildFormButtons = () => {
        const { submitting, pristine, onHide} = this.props;
        return (
            <div className="btn-row-modify-schedule">
                <Button flat secondary className="margin-right" onClick={onHide}>Cancel</Button>
                <Button disabled={pristine || submitting} type="submit" flat primary swapTheming>Submit</Button>
            </div>
        );
    }
    

    render() {
        const { index, handleSubmit } = this.props;

        return (
            <form id="form-modify-schedule" onSubmit={handleSubmit}>
                { this.buildCoParents() }
                <ul id="modify-schedule-dialog-bubbles" className="flex-container-cal">
                    { this.buildBubble(this.getPreviousIndex()) }
                    { this.buildBubble(index) }
                    { this.buildBubble(this.getNextIndex()) }
                </ul>
                { this.buildModifyActions() }
                { this.buildFormButtons() }
            </form>
        );
    }
}

export default reduxForm({
    form: 'FormModifySchedule',
    validate,
    fields: [
        'alteredPattern',
        'alteredExchangeTimes',
        'startTime',
        'endTime',
        'exchangeTime',
    ],
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
})(FormModifySchedule);