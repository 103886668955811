import React, { useState, useEffect } from 'react';
import usePageFramework from '../../hooks/usePageFramework';
import { selectCurrentUser, selectOrganizationId, selectDefaultAssets, selectCurrentOrganization } from '../../selectors/global';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { uploadFile } from '../../utils/fileUpload';
import { callCloudRunFunction } from '../../utils/firestore';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_INFO } from '../../constants/notifications';
import UpdateAdvancedAssetsForm from './UpdateAdvancedAssetsForm';
import { ListItem, List, Cell, Card } from 'react-md';
import ThemedButton from '../../components/Buttons/ThemedButton';
import TwoColumnLayout from '../../pageLayouts/TwoColumnLayout';
import GeneralConfirmationForm from '../../components/Forms/GeneralConfirmationForm';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';

function SystemAssets() {
    const {
        selector
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const defaultAssets = selector(selectDefaultAssets());
    const organization = selector(selectCurrentOrganization());

    const [ focusedItemKey, setFocusedItemKey ] = useState(null);
    const [ pendingSystemAssets, setPendingSystemAssets ] = useState(null);

    const foundAssetGroup = focusedItemKey && defaultAssets && defaultAssets.find(x => focusedItemKey === x.key);
    
    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        finishedLoading();
    }, []);

    const updateSystemAssetsPopup = {
        FormComponent: GeneralConfirmationForm,
        onSubmit: () => saveForm(pendingSystemAssets),
        onHide: () => setPendingSystemAssets(null),
        popupData: { title: `Type \'${'Confirm'}\' to update the default assets.`, formId: 'update-system-assets-form' },
        formData: { formId: 'update-system-assets-form', initialValues: { values: 'Confirm' } },
    };

    const {
        showPopup: showUpdateSystemAssetsPopup,
        renderPopupForm: renderUpdateSystemAssetsPopupForm,
    } = usePopupFormBuilder(updateSystemAssetsPopup);

    const initiateConfirmation = (assetData) => {
        setPendingSystemAssets(assetData);
        showUpdateSystemAssetsPopup();
    }

    const saveForm = (assetData) => {
        const { valueKeys, currentAssets, newAssets } = assetData;
        delete assetData.valueKeys;
        delete assetData.currentAssets;
        delete assetData.newAssets;

        const assetMetadata = {
            organizationId: 'Default',
        }

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'System Branding',
            action: 'update system assets',
            assetKey: assetData.assetKey,
            metadata: {...assetData},
        };

        const promises = [];
        const assetItems = [];

        valueKeys.forEach((valueKey) => {
            const currentAssetId = currentAssets[valueKey];
            const newAssetFile = newAssets[valueKey];

            // Save new picture picture uploaded,
            if (newAssetFile) {
                const basePath = `Organizations/${'Default'}/assets`;
                promises.push(uploadFile(newAssetFile, basePath, currentUser, `Asset for ${valueKey}`, []).then((uploadId) => {
                    assetItems.push({ key: valueKey, value: uploadId });
                    log.metadata[valueKey] = uploadId;
                }));

                // If there is a current asset, delete the asset.
                if (currentAssetId) {
                    promises.push(callCloudRunFunction(currentUser, 'deleteUpload', { uid: currentAssetId }));
                }
            } else {
                assetItems.push({ key: valueKey, value: currentAssetId });
            }
        });

        showToastNotification('Updating assets...', NOTIFICATION_INFO);

        Promise.all(promises).then(() => {
            const assetDocument = Object.assign({}, assetData, { assetItems: assetItems }, assetMetadata);
            return callCloudRunFunction(currentUser, 'updateOrganizationAssets', assetDocument);
        }).then(() => {
            showToastNotification('Sucessfully updated assets', NOTIFICATION_SUCCESS);
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            setFocusedItemKey(null);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Failed to update assets', NOTIFICATION_ERROR);
        });

    }

    const buildAssetGroups = () => {
        if (!defaultAssets) {
            return ( <p> No available assets to change </p> );
        }

        return (
            <List>
                { defaultAssets.map(x => <ListItem primaryText={x.page} onClick={() => setFocusedItemKey(x.key)}/>) }
            </List>  
        );
    }

    const buildFormDisplay = () => {
        if (!foundAssetGroup) {
            return (<p>Select a feature on the left to change its assets</p>);
        }

        const formProperties = { formId: foundAssetGroup.key, title: foundAssetGroup.description, FormComponent: UpdateAdvancedAssetsForm, subtitle: foundAssetGroup.subDescription };

        return (<Card><formProperties.FormComponent id={formProperties.formId} key={formProperties.formId} onSubmit={initiateConfirmation} {...formProperties} /></Card>)
    }

    const returnToListButton = () => {
        return (
            <Cell size={12}>
                <ThemedButton className="full-width-field" flat swapTheming primary onClick={() => setFocusedItemKey(null)}>
                    Return to list
                </ThemedButton>
            </Cell>
        );
    }

    const buildTabletMobileSection = () => {
        return !foundAssetGroup
            ? buildAssetGroups()
            : (
                <>
                    { returnToListButton() }
                    { buildFormDisplay() }
                </>
            )
    }

    function buildPageContent() {
        return (
            <>
                <b className="inactive-text"> You are currently changing default system assets. Updates to assets here will reflect across the entire Professional Tool. </b>
                <TwoColumnLayout
                    buildLeftColumn={buildAssetGroups}
                    buildRightColumn={buildFormDisplay}
                    buildTabletMobile={buildTabletMobileSection}
                />
            </>
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderUpdateSystemAssetsPopupForm() }
        </>
    );
}

export default SystemAssets;