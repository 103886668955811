import React from 'react';
import useFormValidation from '../../hooks/useFormValidation';
import { Grid, Cell, Button, FontIcon } from 'react-md';
import TextFormField from '../../components/FormFields/TextFormField';
import ProfilePictureFormField from '../../components/FormFields/ProfilePictureFormField';
import { normalizePhone } from '../../components/RequestFields/normalizePhone';
import { formatPhone } from '../../components/RequestFields/formatPhone';
import { validate } from './ClientValidate';
import { getInitials } from '../../utils/strings';
import { parseGroupControls } from '../../utils/orgGroups';
import SelectionFormField from '../../components/FormFields/SelectionFormField';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';

function UpdateClientProfileForm(props) {
    const { onSubmit, initialValues, isUsingGroups, selectableOrgGroups } = props;

    const INITIAL_STATE = {
        uid: initialValues.uid,
        sms: initialValues.sms ? formatPhone(initialValues.sms) : '',
        updatedProfilePicture: null,
        email: initialValues.email,
        firstName: initialValues.firstName,
        lastName: initialValues.lastName,
        caseNumber: initialValues.caseNumber || '',
        country : initialValues.country || '',
        province: initialValues.province || '',
        county: initialValues.county || '',
        orgGroups: initialValues.orgGroups || [],
    };

    const orgGroupControls = parseGroupControls(selectableOrgGroups);

    // If the groups feature is enabled, then find an associated group
    if (isUsingGroups) {
        INITIAL_STATE.orgGroup = INITIAL_STATE.orgGroups.find(x => orgGroupControls.find(control => control.value === x)) || '';
    }

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, updateClientProfile, validate(isUsingGroups));

    function updateClientProfile(values) {
        const clientProfile = parseSubmissionToClient(values);
        onSubmit(clientProfile);
    }

    function parseSubmissionToClient(values) {
        const clientProfile = {
            uid: values.uid,
            updatedProfilePicture: values.updatedProfilePicture,
            sms: normalizePhone(values.sms),
            firstName: values.firstName,
            lastName: values.lastName,
            caseNumber: values.caseNumber,
            country: values.country,
            province: values.province,
            county: values.county,
        };

        // If the groups feature is enabled, then insert the selected group
        if (isUsingGroups) {
            const index = values.orgGroups.findIndex(x => orgGroupControls.find(control => control.value === x));
            const groupsCopy = [...values.orgGroups]
            groupsCopy.splice(index !== -1 ? index : 0, index !== -1 ? 1 : 0, values.orgGroup);
            clientProfile.orgGroups = groupsCopy;
        }

        return clientProfile;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={2} tabletSize={2} phoneSize={4} style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                    <ProfilePictureFormField
                        name={'updatedProfilePicture'}
                        values={values}
                        errors={errors}
                        innerText={getInitials(`${values.firstName} ${values.lastName}`)}
                        currentPicture={initialValues.photoUrl}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={8} tabletSize={6} phoneSize={4}>
                    <Grid>
                        <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                            <TextFormField
                                name="firstName"
                                label={getDataLabel('firstNameData')}
                                values={values}
                                errors={errors}
                                placeholder={getDataLabel('firstNameData')}
                                required
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Cell>
                        <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                            <TextFormField
                                name="lastName"
                                label={getDataLabel('lastNameData')}
                                values={values}
                                errors={errors}
                                placeholder={getDataLabel('lastNameData')}
                                required
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Cell>
                        <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                            <TextFormField
                                name="email"
                                label={getDataLabel('emailData')}
                                values={initialValues}
                                errors={errors}
                                placeholder="Email"
                                disabled
                                leftIcon={<FontIcon>email</FontIcon>}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Cell>
                        <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                            <TextFormField
                                name="sms"
                                label={getDataLabel('phoneData')}
                                values={values}
                                errors={errors}
                                placeholder={getDataLabel('phoneData')}
                                leftIcon={<FontIcon>phone</FontIcon>}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Cell>
                    </Grid>
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="caseNumber"
                        label={getDataLabel('caseNumberData')}
                        values={values}
                        errors={errors}
                        placeholder={getDataLabel('caseNumberData')}
                        leftIcon={<FontIcon>shopping_bag</FontIcon>}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    {
                        isUsingGroups && <SelectionFormField
                                name="orgGroup"
                                label={getDataLabel('groupData')}
                                values={values}
                                errors={errors}
                                menuItems={orgGroupControls}
                                itemLabel="label"
                                itemValue="value"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={4} tabletSize={2} phoneSize={4}>
                    <TextFormField
                        name="country"
                        label={getDataLabel('countryData')}
                        values={values}
                        errors={errors}
                        placeholder={getDataLabel('countryData')}
                        leftIcon={<FontIcon>location_on</FontIcon>}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletOffset={1} tabletSize={2} phoneSize={4}>
                    <TextFormField
                        name="province"
                        label={getDataLabel('stateData')}
                        values={values}
                        errors={errors}
                        placeholder={getDataLabel('stateData')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletOffset={1} tabletSize={2} phoneSize={4}>
                    <TextFormField
                        name="county"
                        label={getDataLabel('countyData')}
                        values={values}
                        errors={errors}
                        placeholder={getDataLabel('countyData')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell size={12}>
                    <ThemedButton className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>Update</ThemedButton>
                </Cell>
            </Grid>
        </form>
    )
}

export default UpdateClientProfileForm;