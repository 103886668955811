import React, { useState } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { DialogContainer, Toolbar, Button } from 'react-md';
import { SIDE_DRESSING } from '../constants';
import useCustomTheme from './useCustomTheme';
import ThemedToolbar from '../components/ThemedToolbar';

const onSubmitDefault = (data) => {
    // console.log('Submitting Form:');
    // console.log(data);
}

const onHideDefault = () => {
    // console.log('Hiding Form');
}

const DefaultComponent = () => <> </>;

function useDialogFormBuilder(props) {
    const { FormComponent = DefaultComponent, onSubmit = onSubmitDefault, onHide = onHideDefault, dialogData = {}, formData = {} } = props;
    const { title = 'Dialog Form', sidedressingLeft, sidedressingRight } = dialogData;
    const [ visible, setVisible ] = useState(false);

    const showDialog = () => {
        setVisible(true);
    }

    const hideDialog = () => {
        setVisible(false);
        onHide();
    }

    const onSubmitDialog = (data) => {
        onSubmit(data);
        hideDialog();
    }

    const renderDialogForm = () => {
        return (
            <DialogContainer
                id="dialog-form"
                aria-labelledby="dialog-form"
                visible={visible}
                onHide={hideDialog}
                fullPage
            >
                <CSSTransitionGroup
                    id="dialog-form-transition"
                    className="md-text-container md-toolbar--relative"
                    transitionName="md-cross-fade"
                    transitionEnterTimeout={300}
                    transitionLeave={false}
                >
                    <ThemedToolbar
                        nav={<Button icon onClick={hideDialog}>close</Button>}
                        title={title}
                        titleId="dialog-form-title"
                        fixed
                        colored
                    />
                    { sidedressingLeft && <img src={`${process.env.PUBLIC_URL}${SIDE_DRESSING}${sidedressingLeft}`} alt="org" className="dialogDressingLeft" /> }
                    { sidedressingRight && <img src={`${process.env.PUBLIC_URL}${SIDE_DRESSING}${sidedressingRight}`} alt="org" className="dialogDressingRight" /> }
                    <section className="md-text-container md-toolbar-relative">
                        <FormComponent
                            onSubmit={onSubmitDialog}
                            onHide={hideDialog}
                            {...formData}
                        />
                    </section>
                </CSSTransitionGroup>
            </DialogContainer>
        );
    }

    return {
        showDialog,
        hideDialog,
        renderDialogForm,
    }
}

export default useDialogFormBuilder;