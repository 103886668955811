export const IS_AUTHENTICATED =  'app/IS_AUTHENTICATED';
export const ALL_CLIENT_VIEW_ROLES = 'app/ALL_CLIENT_VIEW_ROLES';
export const ALL_ORGANIZATION_ROLES = 'app/ALL_ORG_ROLES';
export const ALL_MANAGEMENT_ROLES = 'app/ALL_MANAGEMENT_ROLES';
export const CLIENT_PROFILE = 'app/CLIENT_PROFILE';
export const CURRENT_USER = 'app/CURRENT_USER';
export const CURRENT_USER_ROLES = 'app/CURRENT_USER_ROLES';
export const CURRENT_USER_GROUPS = 'app/CURRENT_USER_GROUPS';
export const NEW_CURRENT_USER_DATA = 'app/NEW_CURRENT_USER_DATA';
export const CURRENT_USER_PERMISSIONS = 'app/CURRENT_USER_PERMISSIONS';
export const IS_APPROVED = 'app/IS_APPROVED';
export const IS_AUTH_INFO = 'app/IS_AUTH_INFO';
export const PROPERTY_COMPONENT = 'app/PROPERTY_COMPONENT';
export const NEW_UNIQUE_CHANNEL_NAME = 'app/NEW_UNIQUE_CHANNEL_NAME';
export const ORG_ID = 'app/ORG_ID';
export const TOOLBAR_BUTTONS = 'app/TOOLBAR_BUTTONS';
export const TOOLBAR_TITLE = 'app/TOOLBAR_TITLE';
export const ROOM = 'app/room';
export const SELECTED_PARTICIPANT = 'app/selectedParticipant';
export const DOMINANT_SPEAKER = 'app/dominantSpeaker';
export const MAIN_PARTICIPANT = 'app/mainParticipant';
export const VIDEO_CONFERENCE = 'app/videoConference';
export const DEFAULT_THEME = 'app/DEFAULT_THEME';
export const TENANT_THEME = 'app/TENANT_THEME';
export const DEFAULT_LABELS = 'app/DEFAULT_LABELS';
export const TENANT_LABELS = 'app/TENANT_LABELS';
export const DEFAULT_ASSETS = 'app/DEFAULT_ASSETS';
export const TENANT_ASSETS = 'app/TENANT_ASSETS';