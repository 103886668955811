import { number } from "../../components/RequestFields/renderToolboxField";

function validate(labelKeys) {
    return (values) => {

        let errors = {};
        const labelItemErrors = {};

        labelKeys.map((labelKey) => {
            const labelItemError = {};
            const labelItem = values.labelItems[labelKey];

            const isNumber = number(labelItem.constraint);
            if (isNumber) {
                labelItemError.constraint = "A number is required";
            }

            if (Object.keys(labelItemError).length > 0) {
                labelItemErrors[labelKey] = labelItemError;
            }
        });

        if (Object.keys(labelItemErrors).length > 0) {
            errors.labelItems = labelItemErrors;
        }

        return errors;
    }
}

export {
    validate,
};