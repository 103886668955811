import React, { PureComponent } from 'react'
import { Cell, Card, CardTitle, CardText, Grid } from 'react-md';
import MediaQuery from 'react-responsive';


class BrowserUnsupported extends PureComponent {
    render() {
        const mbolileTopPadding = { paddingTop: "20px" }
        const borderStyle = { background: '#1A237E', width: '100%', height: '100px' };
        const firefoxLink = 'https://www.mozilla.org/en-US/firefox/new/';
        const chromeLink = 'https://www.google.ca/chrome/';
        const edgeLink = 'https://www.microsoft.com/en-ca/windows/microsoft-edge';
        const unsupportedWarningStyle = { paddingLeft:60+'px' };

        const unsupportedRender =
            (<div
                style={unsupportedWarningStyle}>
                <Cell
                size={4}
                id="browser-unsupported-page">
                    <Card className="md-block-centered">
                        <CardTitle title="Browser Unsupported!"/>
                        <CardText>
                            <Grid>
                                <Cell size={12}>
                                    <h4> 
                                        We have detected that you are using Internet Explorer as your browser. Please use one of the following browsers instead:
                                    </h4>
                                </Cell>
                            </Grid>
                                <Cell size={4}>
                                    <a href={chromeLink}>Google Chrome</a>
                                </Cell>
                                <Cell size={4}>
                                    <a href={firefoxLink}>Firefox</a>
                                </Cell>
                                <Cell size={4}>
                                    <a href={edgeLink}>Microsoft Edge</a>
                                </Cell>
                        </CardText>
                    </Card>
                </Cell>
            </div>);

        return (
            <div>
                <MediaQuery query="(min-device-width: 1224px)">
                    <div className="console_viewport layout-column flex-100">
                        <div className="landing-page">
                            <div className="landing-page-header">
                                <img src="/2020.01.21-FINAL-V1-LOGOCOLOR-01.svg" alt="coParenterLogo"  width="179" height="38" className="landing-logo" />
                                <h1 className="landing-welcome">Welcome to coParenter!</h1>
                                <div className="landing-subtitle">coParenter is a coParenting Management and Mediation platform that helps parents take a child-centric approach to separation, divorce and coParenting.</div>
                                {unsupportedRender}
                            </div>
                        </div>

                    </div>
                    <div style={borderStyle}></div>
                </MediaQuery>

                <MediaQuery query="(max-device-width: 1224px)">
                    <div style={mbolileTopPadding} className="text-center">
                        <Cell size={12}>
                            <img src="/2020.01.21-FINAL-V1-LOGOCOLOR-01.svg" width="179" height="38" alt="coParenterLogo" />
                        </Cell>
                        <Cell size={12}>
                            <img className="loginGraphic" src="/mobileAlternates/loginpage_mobile.png" height="300px" width="300px" alt="coParenterLogo" />
                        </Cell>
                        <Cell size={12}>
                            <h2>Welcome to coParenter!</h2>
                        </Cell>
                        <Cell size={12}>
                            <div className="text-center landing-subtitle-mobile">
                                coParenter is a coParenting Management and Mediation platform that helps parents take a child-centric approach to separation, divorce and coParenting.
                        </div>
                        </Cell>
                        {unsupportedRender}
                    </div>
                </MediaQuery>
            </div>
        );
    }
}

export default BrowserUnsupported;