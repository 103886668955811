import React from 'react';
import ThemedButton from './ThemedButton';

function TableButton(props) {
    const { id, label, disabled, onClick } = props;

    const clickedItem = () => {
        onClick(id.split('-')[1]);
    }

    return (
        <ThemedButton id={id} disabled={disabled || false} className="tableColumnButton" flat onClick={clickedItem}>
            { label }
        </ThemedButton>
    );
}

export default TableButton;