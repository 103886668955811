import React from 'react';
import useFormValidation from '../../../hooks/useFormValidation';
import { validate } from './validate';
import { Grid, Cell } from 'react-md';
import TextFormField from '../../FormFields/TextFormField';
import { useCustomDataLabels } from '../../../hooks/useCustomLabels';
/**
 * The VariableConfirmationForm should be used alongside the usePopupBuilder hook.
*/

function VariableConfirmationForm(props) {
    const { onSubmit, onHide, formId, initialValues } = props;

    const INITIAL_STATE = {
        data: initialValues,
        confirmation: '',
    };

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, submitConfirmation, validate);

    function submitConfirmation(values) {
        const confirmation = parseSubmissionToConfirmation(values);
        onSubmit(confirmation);
    }

    function parseSubmissionToConfirmation(values) {
        const confirmation = {
            data: values.data,
            confirmation: values.confirmation.trim(),
        };

        return confirmation;
    }

    const buildWarningText = (warningText) => {
        return (
            <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                <b className="inactive-text">{warningText}</b>
            </Cell>
        );
    }

    return (
        <form id={formId} name={formId} onSubmit={handleSubmit}>
            <Grid className="md-grid">
                { initialValues.warningText && buildWarningText(initialValues.warningText) }
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="confirmation"
                        label={getDataLabel('confirmationData')}
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
            </Grid>
        </form>
    );
}

export default VariableConfirmationForm;