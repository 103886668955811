import React from 'react';
import { Card, CardTitle, CardText, Grid, CardActions, Divider } from 'react-md';
import { convertToDisplayDate } from '../../utils/dateTime';
import { buildHalfCellDataDisplay } from '../CellDataDisplay';
import ThemedButton from '../Buttons/ThemedButton';
import { SCHEDULE_CATEGORY_APPOINTMENT, SCHEDULE_CATEGORY_EXCHANGE_PICKUP, SCHEDULE_CATEGORY_EXCHANGE_DROPOFF } from '../../constants/schedule';

function EventDisplayCard(props) {
    const { id, title, category, createdOn, createdBy, startDateTime,
        endDateTime, notes, children, loadedChildren, canView, canUpdate,
        canDelete, viewSchedule, updateSchedule, deleteSchedule,
     } = props;

    const foundChildren = children && loadedChildren ? children.map((x) => loadedChildren.find(child => child.uid === x) || null).filter(x => x !== null) : [];
    const isCorrectScheduleType = [SCHEDULE_CATEGORY_APPOINTMENT, SCHEDULE_CATEGORY_EXCHANGE_PICKUP, SCHEDULE_CATEGORY_EXCHANGE_DROPOFF].includes(category);

    const buildCardActions = () => {
        if (!canView && !canUpdate && !canDelete) {
            return (<></>);
        }

        return (
            <>
                <Divider/>
                <CardActions>
                    <ThemedButton flat disabled={!canView} onClick={() => viewSchedule(id)}> View on calendar </ThemedButton>
                    <ThemedButton flat disabled={!canUpdate || !isCorrectScheduleType || true} onClick={() => updateSchedule(id)}> Edit </ThemedButton>
                    <ThemedButton flat disabled={!canDelete} onClick={() => deleteSchedule(id)}> Delete </ThemedButton>
                </CardActions>
            </>
        );
    }

    return (
        <Card>
            <CardTitle
                title={title}
                subtitle={`Event starts on ${convertToDisplayDate(startDateTime)} and ends on ${convertToDisplayDate(endDateTime)}`}
                expander
                defaultExpanded={false}
            />
            <CardText expandable>
                <Grid>
                    { buildHalfCellDataDisplay('Created On:', convertToDisplayDate(createdOn)) }
                    { buildHalfCellDataDisplay('Created By:', createdBy) }
                    { buildHalfCellDataDisplay('Start Date:', convertToDisplayDate(startDateTime)) }
                    { buildHalfCellDataDisplay('End Date:', convertToDisplayDate(endDateTime)) }
                    { buildHalfCellDataDisplay('Category:', category) }
                    { buildHalfCellDataDisplay('Notes:', notes) }
                    { buildHalfCellDataDisplay('Children:', foundChildren.map(x => `${x.firstName} ${x.lastName}`).join(', ')) }
                </Grid>
            </CardText>
            { buildCardActions() }
        </Card>
    );
}

export default EventDisplayCard;