function validate(values) {
    const errors = {};

    if (!values.caseNumber.trim()) {
        errors.caseNumber = "A case number is required";
    }

    if (!values.title.trim()) {
        errors.title = "A title is required";
    }

    if (!values.notes.trim() && values.uploads.length === 0) {
        errors.notes = "A note or attachment is required";
    }

    return errors;
}

export {
    validate,
}