import { COUNTRIES } from "../../constants/lookupInfo";
import { emailValidate, phoneValidate } from "../../components/RequestFields/renderToolboxField";

function validate(values) {
    const errors = {};

    if (!values.organizationName.trim()) {
        errors.organizationName = "Organization is required";
    }

    if (!values.organizationType) {
        errors.organizationType = "Organization type is required";
    }

    if (!values.country) {
        errors.country = "Country is required";
    }
    
    if (!values.state) {
        errors.state = "State is required";
    }

    const country = COUNTRIES.find((country) => country.id === values.country);
    const code = country ? country.code : null;

    if (!values.zip) {
        errors.zip = "Zip is required";
    } else if (code && code(values.zip)) {
        errors.zip = code(values.zip);
    }

    if (!values.primaryContact.trim()) {
        errors.primaryContact = "Primary contact is required";
    }

    if (!values.email) {
        errors.email = "Email is required";
    } else if (emailValidate(values.email)) {
        errors.email = emailValidate(values.email);
    }

    if (!values.phone) {
        errors.phone = "Phone number is required";
    } else if (phoneValidate(values.phone)) {
        errors.phone = phoneValidate(values.phone);
    }

    if (!values.orgRoles) {
        errors.orgRoles = "A role is required";
    }


    return errors;
}

export {
    validate,
};