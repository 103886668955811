import React, { useState, useEffect } from 'react';
import { Tab, TabPanel } from 'react-md';
import { DetailedRequestList, AgreedRequestList, RequestHistory, RequestComments } from './DetailsRequestList';
import ThemedTabs from '../ThemedTabs';

function RequestDetailContent(props) {
    const { status } = props;

    const [activeTab, setactiveTab] = useState(0);

    const tabs = [
        { id: 'detailed-request-list', label: 'Details', content: <DetailedRequestList {...props}/> },
        { id: 'request-comments', label: 'Comments', content: <RequestComments {...props}/> },
        { id: 'request-history', label: 'History', content: <RequestHistory {...props}/>},
    ];

    // If the Request is accepted, show the agreement tab
    if (status === 'accepted') {
        const agreementTab = { id: 'agreed-request-list', label: 'Agreement', content: <AgreedRequestList {...props} /> };
        tabs.splice(1, 0, agreementTab);
    }

    const buildDisplay = () => {
        return (
            <>
                <ThemedTabs colored tabId="request-detail-tabs" onTabChange={setactiveTab}>
                    {
                        tabs.map((x) => {
                            return ( <Tab id={x.id} key={x.id} label={x.label}/> );
                        })
                    }
                </ThemedTabs>
                <TabPanel id="request-detail-tab-content" contolledById={tabs[activeTab].id}>
                    { tabs[activeTab].content }
                </TabPanel>
            </>
        );
    }


    return (
        <div id="request-detail-content">
            { buildDisplay() }
        </div>
    );
}

export default RequestDetailContent;