function validate(values) {
    const errors = {};

    if (values.confirmation.trim() !== 'Delete') {
        errors.confirmation = "Type \'Delete\' to confirm";
    }

    return errors;
}

export {
    validate,
}