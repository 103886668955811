import React from 'react';
import { CardTitle, CardText } from 'react-md';
import DetailsList from './DetailsList';
import DisplayDetailsList from './DisplayDetailsList';

const MediationDetailContent = ({ members, details, displayDetails, invitedCoParent }) => {

    const primary = members && members.find(x=> x.isPrimary);
    const primaryContact = primary ? `${primary.firstName} ${primary.lastName}` : '';

    const invitedCoParentText = invitedCoParent ? 'Invited CoParent' : <br/>;
    const invitedCoParentBlock = <h4 align='RIGHT'><b> { invitedCoParentText } </b></h4>;
    const style ={ padding: '0px 15px' };
    const titleStyle ={ paddingBottom: '0px' };

    const returnList = () => {
        return details ? <DetailsList details={details}/> : <DisplayDetailsList displayDetails={displayDetails}/>;
    }
    return (
        
        <div id="get-help-detail-content">
            <CardTitle style={titleStyle} title={primaryContact} />
            <CardText style={style}>
                {returnList()}
                { invitedCoParentBlock }
            </CardText>
        </div>
    );
};

export default MediationDetailContent;