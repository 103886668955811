import React, { useState } from 'react';
import { useEffect } from "react";
import { callCloudRunFunction } from '../../utils/firestore';
import usePageFramework from '../../hooks/usePageFramework';
import { selectCurrentUser } from '../../selectors/global';
import ParentingPlanForm from '../../components/Forms/ParentingPlanForm';
import { OTHER_SHARED_COPARENT_CONTROL } from '../../constants/schedule';

function CreateParentingPlanForm(props) {
    const { onSubmit, clientProfile } = props;
    const {
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const [ loadedChildren, setLoadedChildren ] = useState([]);
    const [ loadedCoParents, setLoadedCoParents ] = useState([ OTHER_SHARED_COPARENT_CONTROL ]);

    const cp1Search = clientProfile ? { id: clientProfile.id, friendlyName: clientProfile.name } : { id: 0, friendlyName: 'coParent 1' };

    // Load Client's children
    useEffect(() => {
        if (!clientProfile) {
            return;
        }

        const cpIds = {
            cp1Uid: clientProfile ? clientProfile.id : null,
            cp2Uid: null,
        };

        callCloudRunFunction(currentUser, 'getChildrenForPros', cpIds).then((childrenData) => {
            if (childrenData) {
                const clientChildren = Object.keys(childrenData).map((id) => {
                    return {
                        value: id,
                        label: `${childrenData[id].firstName} ${childrenData[id].lastName}`.trim(),
                    };
                });

                setLoadedChildren(clientChildren);
            }
        });
    }, [ clientProfile ]);

    // Load Client's coParents
    useEffect(() => {
        if (!clientProfile) {
            return;
        }

        const cpId = {
            userId: clientProfile ? clientProfile.id : null,
        };

        callCloudRunFunction(currentUser, 'getCoParents', cpId).then((coParents) => {
            const coParentData = coParents.map((coParent) => {
                return {
                    label: `${coParent.firstName} ${coParent.lastName}`,
                    value: `${coParent.uid}`,
                };
            });
            coParentData.unshift(OTHER_SHARED_COPARENT_CONTROL);
            setLoadedCoParents(coParentData);
        });

    }, [ clientProfile ]);

    const formData = {
        p1Name: cp1Search.friendlyName,
        p1Id: cp1Search.id,
    };

    return (
        <ParentingPlanForm
            onSubmit={onSubmit}
            availableChildren={loadedChildren}
            availableCoParents={loadedCoParents}
            {...formData}
        />
    );
}

export default CreateParentingPlanForm;