import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import * as firebase from 'firebase';
import { Button } from 'react-md';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import { selectClientProfile, selectOrganizationId, selectDataItemsFromComponent } from '../../selectors/global';
import { setToolbarTitle, setDataItemsToComponent} from '../../actions/global';
import ProTable from '../../components/ProTable';
import { ALL } from '../../constants';
import { NAV_DEFAULT } from '../../constants/navigation';

const COMPONENT_NAME = 'ClientSentiment';

class ClientSentiment extends Component {
    constructor (props) {
        super(props);

        this.state = { dialogVisible: false };
    }

    componentDidMount() {
        const { organizationId, clientProfile } = this.props

        if (organizationId === false || !clientProfile) {
            return this.props.history.push(NAV_DEFAULT);
        }

        this.props.onSetToolbarTitle(`Client Sentiment - ${clientProfile.name}`);
    }

    componentWillUnmount() {
        const { clientSentimentStream } = this.state;

        if (clientSentimentStream) {
            clientSentimentStream();
        }
    }

    loadData = () => {
        const { onSetDataItems, clientProfile } = this.props;
        const db = firebase.firestore();
        
        const clientSentimentStream = db.collection('ProClientSentiment').where('clientId', '==', clientProfile.id).onSnapshot((querySnapshot) => {
            const dataItems = querySnapshot.docs.map((doc) => {
                const item = doc.data();
                item.id = doc.id;
                return item;
            });

            onSetDataItems(dataItems);
        });

        this.setState({  clientSentimentStream });
    }

    showDialog = (evt) => {
        const { dataItems } = this.props;
        const target = !dataItems ? -1 : dataItems.findIndex(x => x.id === evt.target.id);
        this.setState({ dialogVisible: true, agreement: target >=0 ? dataItems[target] : {} });
    }

    backToProfile = () => {
        this.props.history.goBack();
    }

    hideDialog = () => {
        this.setState({ dialogVisible: false, assignmentToEdit: {} });
    }

    createEmptyCard = (status) => {
        const cardsInfo = [
            { status: ALL, cardMediaUrl: 'info_sentiment.png', headerTitle: 'Coming Soon!', subTitle: 'Look for this feature Summer 2020 when we launch our new flagship consumer app!', buttonText: 'Back to Client Profile', buttonClick: this.backToProfile },
        ];

        const cardProps = cardsInfo.find(x => x.status === status);
        return (<GettingStartedPaper {...cardProps} key={cardProps.status} />);
    }

    render() {
        const { dataItems } = this.props;

        const columns = [                                                                              
            { label: 'Title', value: 'coParent', type: 'Text', isGrow: true,  fn: (item, id) => (<Button id={id} className="tableColumnButton" name={item} flat secondary onClick={this.showDialog}>{item}</Button>)},
            { label: 'Created On', value: 'createdOn', isPrimaryColumn: true, type:'Date', fn: (item) => moment(item).calendar()}
        ];

        return (
            <div>
                <ProTable status={ALL} items={dataItems} columns={columns} key={ALL} componentName={COMPONENT_NAME} emptyCard={() => this.createEmptyCard(ALL)}/>
            </div>
        );
    }
}

export function mapDispatchToProps(dispatch) {
    return {
       onSetToolbarTitle: title => dispatch(setToolbarTitle(title)),
       onSetDataItems: dataItems => dispatch(setDataItemsToComponent(dataItems, COMPONENT_NAME)),
    }
}

const mapStateToProps = createStructuredSelector({
    organizationId: selectOrganizationId(),
    dataItems: selectDataItemsFromComponent(COMPONENT_NAME),
    clientProfile: selectClientProfile(),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientSentiment);
