import React, { useEffect, useState } from 'react';
import moment from 'moment';
import usePageFramework from '../../hooks/usePageFramework';
import { selectCurrentUser } from '../../selectors/global';
import useFormValidation from '../../hooks/useFormValidation';
import { Grid, Cell } from 'react-md';
import { TIME_FORMAT } from '../../constants';
import SelectionFormField from '../../components/FormFields/SelectionFormField';
import CheckboxFormField from '../../components/FormFields/CheckboxFormField';
import TimeFormField from '../../components/FormFields/TimeFormField';
import ThemedButton from '../../components/Buttons/ThemedButton';
import { HOLIDAYS, HOLIDAY_ROTATIONS, HOLIDAY_STARTS_BEFORE, HOLIDAY_ENDS_ON } from '../../constants/lookupInfo';
import { callCloudRunFunction } from '../../utils/firestore';
import { NO_SHARED_COPARENT_CONTROL } from '../../constants/schedule';
import RadioFormField from '../../components/FormFields/RadioFormField';

function UpdateHolidayForm(props) {
    const { onSubmit, onHide, initialValues } = props;

    const {
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const [ availableChildren, setAvailableChildren ] = useState([]);
    const [ selectableCoParents, setSelectableCoParents ] = useState([ NO_SHARED_COPARENT_CONTROL ]);

    const INITIAL_STATE = {
        holiday: initialValues.holiday,
        repeats: initialValues.repeats,
        children: initialValues.children.join(','),
        sharedCoParentId: initialValues.participants.length === 2 ? initialValues.participants[1] : '-1',
        startsBefore: initialValues.startsBefore,
        endsOn: initialValues.endsOn,
        startTime: moment(initialValues.startTime, TIME_FORMAT).toDate(),
        endTime: moment(initialValues.endTime, TIME_FORMAT).toDate(),
    };

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        changeValues,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, submitHoliday, () => { return {} });

    useEffect(() => {
        const cpIds = {
            cp1Uid: initialValues.participants[0] || null,
            cp2Uid: initialValues.participants.length > 2 ? initialValues.participants[1] : null || null,
        };

        callCloudRunFunction(currentUser, 'getChildrenForPros', cpIds).then((childrenData) => {
            if (childrenData) {
                const clientChildren = Object.keys(childrenData).map((id) => {
                    return {
                        value: id,
                        label: `${childrenData[id].firstName} ${childrenData[id].lastName}`.trim(),
                    };
                });

                setAvailableChildren(clientChildren);
            }
        }).catch((err) => {
            console.log(err);
        });
    }, [ initialValues.participants ]);

    useEffect(() => {
        callCloudRunFunction(currentUser, 'getCoParents', { userId: initialValues.participants[0] }).then((coParents) => {
            const coParCategories = coParents.map((coParent) => {
                return {
                    label: `${coParent.firstName} ${coParent.lastName}`,
                    value: `${coParent.uid}`,
                };
            });

            coParCategories.unshift(NO_SHARED_COPARENT_CONTROL);
            setSelectableCoParents(coParCategories);
        }).catch((err) => {
            console.log(err);
        });
    }, [ initialValues.participants ]);

    function submitHoliday(values) {
        const holiday = parseSubmissionToHoliday(values);
        onSubmit(holiday);
    }

    function parseSubmissionToHoliday(values) {
        const holiday = {
            holiday: values.holiday,
            repeats: values.repeats,
            children: values.children.split(','),
            startsBefore: values.startsBefore,
            endsOn: values.endsOn,
            startTime: moment(values.startTime).format(TIME_FORMAT),
            endTime: moment(values.endTime).format(TIME_FORMAT),
            sharedCoParentId: values.sharedCoParentId.trim() === '-1' ? null : values.sharedCoParentId.trim(),
        };

        return holiday;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <SelectionFormField
                        name="holiday"
                        label="Holiday"
                        values={values}
                        errors={errors}
                        menuItems={HOLIDAYS}
                        itemLabel="label"
                        itemValue="value"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <SelectionFormField
                        name="repeats"
                        label="Rotation"
                        values={values}
                        errors={errors}
                        menuItems={HOLIDAY_ROTATIONS}
                        itemLabel="label"
                        itemValue="value"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <CheckboxFormField
                        name="children"
                        label="Children"
                        values={values}
                        errors={errors}
                        required
                        controls={availableChildren}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <RadioFormField
                        name="sharedCoParentId"
                        label="Share schedule with coParents"
                        values={values}
                        errors={errors}
                        controls={selectableCoParents}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <SelectionFormField
                        name="startsBefore"
                        label="Holiday Starts Before"
                        values={values}
                        errors={errors}
                        menuItems={HOLIDAY_STARTS_BEFORE}
                        itemLabel="label"
                        itemValue="value"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <TimeFormField
                        name="startTime"
                        label="Start Time"
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <SelectionFormField
                        name="endsOn"
                        label="Holiday Ends On"
                        values={values}
                        errors={errors}
                        menuItems={HOLIDAY_ENDS_ON}
                        itemLabel="label"
                        itemValue="value"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <TimeFormField
                        name="endTime"
                        label="End Time"
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton flat swapTheming type="submit" disabled={isSubmitting} className='full-width-field'>Submit</ThemedButton>
                </Cell>
            </Grid>
        </form>
    );
}

export default UpdateHolidayForm;