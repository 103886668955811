let createdStore;

const createHelperMiddleware = store => next => action => {
    createdStore = store;
    return next(action);
}

export default createHelperMiddleware;

export {
    // handleOrganizationIdChange
};