import React from 'react';
import { Grid, Cell } from 'react-md';
import useFormValidation from '../../hooks/useFormValidation';
import SelectionFormField from '../FormFields/SelectionFormField';
import { validate } from './ReassignMediationValidate';

function ReassignMediationForm(props) {
    const { onSubmit, onHide, formId, initialValues, controlMediators } = props;

    const INITIAL_STATE = {
        mediationId: initialValues.id,
        assignment: initialValues.assignedProffesional,
    };

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, reassignMediation, validate);

    function reassignMediation(values) {
        const reassignment = parseSubmissionToReassignment(values);
        if (initialValues.assignedProffesional === reassignment.assignment) {
            onHide();
        } else {
            onSubmit(reassignment);
        }
    }

    function parseSubmissionToReassignment(values) {
        const reassignment = {
            mediationId: values.mediationId,
            assignment: values.assignment,
        };

        return reassignment;
    }
    
    return (
        <form id={formId} name={formId} onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell size={12}>
                    <SelectionFormField
                        name="assignment"
                        label="Assigned Mediator"
                        values={values}
                        errors={errors}
                        menuItems={controlMediators}
                        itemLabel="label"
                        itemValue="value"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
            </Grid>
        </form>
    )
}

export default ReassignMediationForm;