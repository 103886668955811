import React, { useState } from 'react'
import { ExpansionList, ExpansionPanel, Divider } from "react-md";
import useFilter from "./useFilter";
import useSorter from './useSorter';
import ThemedButton from '../components/Buttons/ThemedButton';

function useFilterSorterPanel(label, columns, items, initialFilterState) {
    const [ expanded, setExpanded ] = useState(false);

    const {
        resetFilterToDefault,
        filterItems,
        renderFilterBar,
    } = useFilter(columns, initialFilterState);

    const {
        resetSorterToDefault,
        sortItems,
        renderSorterBar,
    } = useSorter(columns);

    const getFilteredAndSortedItems = () => {
        return sortItems(filterItems(items));
    }

    const resetPanel = () => {
        resetFilterToDefault();
        resetSorterToDefault();
    }

    const buildFooterButtons = () => {
        const actions = [
            <ThemedButton flat onClick={resetPanel}>{'reset'}</ThemedButton>,
            <ThemedButton flat swapTheming onClick={() => setExpanded(false)}>{'ok'}</ThemedButton>,
        ];

        return actions;
    }

    const renderFilterSortPanel = () => {
        return (
            <ExpansionList>
                <ExpansionPanel
                    expanded={expanded}
                    onExpandToggle={setExpanded}
                    id='filter-sort-panel'
                    label={label || 'Filter and Sort Panel'}
                    secondaryLabel={['|', `${getFilteredAndSortedItems().length} results out of ${items.length}`]}
                    footer={<footer className="md-dialog-footer md-dialog-footer--inline" >{buildFooterButtons()}</footer>}
                >
                    { renderFilterBar() }
                    <Divider style={{margin: '25px 0px'}}/>
                    { renderSorterBar() }
                </ExpansionPanel>
            </ExpansionList>
        );
    }

    return {
        getFilteredAndSortedItems,
        renderFilterSortPanel,
    };
};

export default useFilterSorterPanel;