import React, { useEffect, useState } from 'react'
import * as firebase from 'firebase';
import moment from 'moment';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import { selectDataItemsFromComponent, selectOrganizationId, selectCurrentUser, selectCurrentUserGroups, selectIsSuperAdmin } from '../../selectors/global';
import ProTable from '../../components/ProTable';
import { ALL, FILTER_TEXT, FILTER_DATE, FILTER_NONE } from '../../constants';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_WARNING } from '../../constants/notifications';
import { setDataItemsToComponent, emptyToolbarButtons } from '../../actions/global';
import { formatPhone } from '../../components/RequestFields/formatPhone';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { callCloudRunFunction } from '../../utils/firestore';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import CreateClientInviteForm from './CreateClientInviteForm';
import useDialogFormBuilder from '../../hooks/useDialogFormBuilder';
import { NAV_DEFAULT } from '../../constants/navigation';
import TableIconButton from '../../components/Buttons/TableIconButton';
import UpdateClientInviteForm from './UpdateClientInviteForm';
import DeleteConfirmationForm from '../../components/Forms/DeleteConfirmationForm';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';
import ClientDeletionConsent from '../../components/PopupContent/ClientDeletionConsent';
import usePopupConsentBuilder from '../../hooks/usePopupConsentBuilder';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import { getMatchingGroups } from '../../utils/orgGroups';
import useCustomAssets from '../../hooks/useCustomAssets';
import { CONNECTED } from '../../constants/clients';

const COMPONENT_NAME = 'ClientInvites';

function ClientInvites() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser =  selector(selectCurrentUser());
    const userIsSuperAdmin = selector(selectIsSuperAdmin());
    const userOrgGroups = selector(selectCurrentUserGroups());
    const organizationId =  selector(selectOrganizationId());
    const dataItems =  selector(selectDataItemsFromComponent(COMPONENT_NAME));
    const [ focusedInviteId, setFocusedInviteId ] = useState(null);
    const foundInvite = dataItems && dataItems.find(x => x.id === focusedInviteId);
    const [ isUsingGroups, setIsUsingGroups ] = useState(false);
    const [ selectableOrgGroups, setSelectableOrgGroups ] = useState([]);
    
    const labelVariableData = {
        name: foundInvite && foundInvite.displayName || '',
        email: foundInvite && foundInvite.email || '',
    };

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('ClientInvites', labelVariableData);

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (!organizationId) return;
        
        dispatch(emptyToolbarButtons());

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Client Invites',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);

        return () => {
            dispatch(emptyToolbarButtons());
        }
    }, []);

    useEffect(() => {
        if (!organizationId) return;
        
        var orgGroupStream = null;
        const db = firebase.firestore();

        orgGroupStream = db.collection('ProOrganizations').doc(organizationId).collection('Groups').onSnapshot((snapshot) => {
            const groups = snapshot.docs.map(doc => {
                const group = doc.data();

                group.createdOn = group.createdOn ? group.createdOn.toDate() : null;
                group.updatedOn = group.updatedOn ? group.updatedOn.toDate() : null;
                group.id = doc.id;
                return group;
            });
            
            const availableGroups = userIsSuperAdmin ? groups : getMatchingGroups(userOrgGroups, groups);
            setSelectableOrgGroups(availableGroups);
            setIsUsingGroups(groups.length ? true : false);
        });

        return () => {
            if (orgGroupStream) {
                orgGroupStream();
            }
        }
    }, [ organizationId, userIsSuperAdmin, userOrgGroups ]);

    useEffect(() => {
        // Effect for loading invite data
        var clientInvitesStream = null
        const db = firebase.firestore();

        if (!organizationId) {
            return;
        }

        clientInvitesStream = db.collection('Connections').where('organizationId', '==', organizationId).where('status', '==', 'invited');
        
        // If the organization is using groups, then filter for groups which the user belongs in
        if (isUsingGroups) {
            const groupIds = selectableOrgGroups.map(x => x.uid);
            clientInvitesStream = clientInvitesStream.where('orgGroup', 'in', groupIds.length ? groupIds : [-1]);
        }

        clientInvitesStream = clientInvitesStream.onSnapshot((querySnapshot) => {
            const clientInvites = querySnapshot.docs.map((doc) => {
                const docItem = doc.data();
                
                docItem.id = doc.id;
                docItem.createdOn = docItem.createdOn ? docItem.createdOn.toDate() : null;
                docItem.updatedOn = docItem.updatedOn ? docItem.updatedOn.toDate() : null;

                if (isUsingGroups) {
                    const relatedGroup = selectableOrgGroups.find(x => x.uid === docItem.orgGroup);
                    docItem.orgGroupName = relatedGroup && relatedGroup.name || '';
                }

                return docItem;
            });

            dispatch(setDataItemsToComponent(clientInvites, COMPONENT_NAME));
            finishedLoading();
        });

        return () => {
            if (clientInvitesStream) {
                clientInvitesStream();
            }
        }
    }, [ organizationId, isUsingGroups, selectableOrgGroups ]);

    const createClientInviteDialog = {
        FormComponent: CreateClientInviteForm,
        onSubmit: createClientInvite,
        dialogData: { title: getLabel('createTitleBar') },
        formData: { isUsingGroups: isUsingGroups, selectableOrgGroups: selectableOrgGroups },
    };

    const {
        showDialog: showCreateDialog,
        renderDialogForm: renderCreateDialogForm
    } = useDialogFormBuilder(createClientInviteDialog);

    const updateClientInviteDialog = {
        FormComponent: UpdateClientInviteForm,
        onSubmit: updateClientInvite,
        dialogData: { title: getLabel('updateTitleBar') },
        formData: { initialValues: foundInvite, isUsingGroups: isUsingGroups, selectableOrgGroups: selectableOrgGroups },
    };

    const {
        showDialog: showUpdateDialog,
        renderDialogForm: renderUpdateDialogForm,
    } = useDialogFormBuilder(updateClientInviteDialog);

    const deleteClientInvitePopup = {
        FormComponent: DeleteConfirmationForm,
        onSubmit: deleteClientInvite,
        onHide: () => setFocusedInviteId(null),
        popupData: { title: getLabel('deleteConfirmationText'), formId: 'delete-client-invite-form' },
        formData: { formId: 'delete-client-invite-form', initialValues: foundInvite },
    };
    const {
        showPopup: showDeletePopup,
        renderPopupForm: renderDeletePopupForm,
    } = usePopupFormBuilder(deleteClientInvitePopup);

    const deleteClientInviteConsent = {
        Component: ClientDeletionConsent,
        popupData: { title: getLabel('deleteNotificationText') },
        contentData: { name: foundInvite ? foundInvite.displayName : '' },
        onAccept: showDeletePopup,
        onCancel: () => setFocusedInviteId(null),
    };

    const {
        showPopup: showDeleteConsentPopup,
        // hidePopup: hideDeleteConsentPopup,
        renderPopupConsent: renderDeleteConsentPopup,
    } = usePopupConsentBuilder(deleteClientInviteConsent);

    if (organizationId === false || currentUser === null) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    function createClientInvite(clientData) {
        const inviteMetadata = {
            organization: organizationId,
            user: {
                uid: currentUser.uid,
                displayName: currentUser.displayName,
                email: currentUser.email,
                invites: currentUser.invites || [],
            },
            inviteCategory: 'orgOnboard',
            isSoloMode: false,
        };

        const clientInvite = Object.assign({}, clientData, inviteMetadata);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Client Invites',
            action: 'send client invite',
            metadata: {...clientInvite}
        };

        showToastNotification(`Sending client invite to ${clientInvite.clients[0].firstName} ${clientInvite.clients[0].lastName}`, NOTIFICATION_INFO);
        
        return callCloudRunFunction(currentUser, 'multiOrgInviteCreate', clientInvite).then(() => {
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            showToastNotification(`Invitation has been successfully sent!`, NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    const updateClientInviteClicked = (id) => {
        setFocusedInviteId(id);
        showUpdateDialog();
    }

    function updateClientInvite(clientData) {
        const inviteMetadata = {
            organization: organizationId,
            user: {
                uid: currentUser.uid,
                displayName: currentUser.displayName,
                email: currentUser.email,
                invites: currentUser.invites || [],
            },
        };

        const clientInvite = Object.assign({}, clientData, inviteMetadata);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Client Invites',
            action: 'update client invite',
            metadata: {...clientInvite}
        };

        setFocusedInviteId(null);
        showToastNotification(`Updating and sending client invite for ${clientInvite.clients[0].firstName} ${clientInvite.clients[0].lastName}`, NOTIFICATION_INFO);
        
        return callCloudRunFunction(currentUser, 'multiOrgInviteUpdate', clientInvite).then(() => {
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            showToastNotification(`Invitation has been successfully sent!`, NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    const resendClientInviteClicked = (connectionId) => {
        const foundConnection = dataItems && dataItems.find(x => x.id === connectionId);

        if (!connectionId || !foundConnection) {
            showToastNotification(`Failed to resend invite`, NOTIFICATION_ERROR);
            return;
        }
        
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Client Invites',
            action: 'resend client invite',
            metadata: { connectionId: connectionId }
        };

        showToastNotification(`Sending client invite to ${foundConnection.displayName}`, NOTIFICATION_INFO);
        
        return callCloudRunFunction(currentUser, 'orgInviteResend', { connectionId: connectionId }).then(() => {
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            showToastNotification(`Invitation has been successfully resent!`, NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    const deleteClientInviteClicked = (id) => {
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Client Invites',
            action: 'checking to delete client invite',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        callCloudRunFunction(currentUser, 'checkForReferencedInvite', { connectionId: id }).then((result) => {
            const { hasPostConnect, postConnectData } = result;

            // Warn deleting function if the referenced invite is already accepted
            if (hasPostConnect && postConnectData && postConnectData.status === CONNECTED) {
                showToastNotification(`The other coParent has already accepted the linked invitation. Are you sure you want to delete?`, NOTIFICATION_WARNING);
            }
            
            setFocusedInviteId(id);
            showDeleteConsentPopup();
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    function deleteClientInvite(deleteData) {
        const connectionId = deleteData.id;
        setFocusedInviteId(null);

        if (!connectionId) {
            showToastNotification(`Failed to delete invite`, NOTIFICATION_ERROR);
            return;
        }

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Client Invites',
            action: 'delete client invite',
            metadata: { connectionId: connectionId },
        };

        showToastNotification(`Deleting client invite`, NOTIFICATION_INFO);
        
        return callCloudRunFunction(currentUser, 'multiOrgInviteDelete', { connectionId: connectionId, deleteReferencedConnection: true }).then(() => {
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            showToastNotification(`Invitation has been successfully deleted!`, NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    const createEmptyCard = (status) => {
        const cardInfo = [
            { status: ALL, cardMediaUrl: getAsset('clientInvitesEmpty'), headerTitle: getLabel('emptyHeader'), subTitle: getLabel('emptySubheader'), buttonText: 'Invite Clients', buttonClick: showCreateDialog },
        ];

        const cardProps = cardInfo.find(x => x.status === status);
        return (<GettingStartedPaper {...cardProps} key={cardProps.status} />);
    }

    function buildPageContent() {
        const columns = [
            { label: getDataLabel('clientNameData'), value: 'displayName', type: FILTER_TEXT, fn: (item) => item},
            { label: getDataLabel('emailData'), value: 'email', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('phoneData'), value: 'sms', type: FILTER_TEXT, fn: (item) => formatPhone(item)},
            { label: getDataLabel('resendData'), staticText: true, type: FILTER_NONE, value: 'redo', fn: (item, id, value) => <TableIconButton id={`resend-${id}`} label={value} onClick={resendClientInviteClicked}/> },
            { label: getDataLabel('editData'), staticText: true, type: FILTER_NONE, value: 'edit', fn: (item, id, value) => <TableIconButton id={`edit-${id}`} label={value} onClick={updateClientInviteClicked}/> },
            { label: getDataLabel('deleteData'), staticText: true, type: FILTER_NONE, value: 'delete', fn: (item, id, value) => <TableIconButton id={`delete-${id}`} label={value} onClick={deleteClientInviteClicked}/> },
            { label: getDataLabel('createdData'), value: 'createdOn', isPrimaryColumn: true, type: FILTER_DATE, fn: (item) => moment(item).calendar() },
        ];
        
        if (isUsingGroups) {
            columns.splice(3, 0, { label: getDataLabel('groupData'), type: FILTER_TEXT, value: 'orgGroupName', fn: (item) => item });
        }

        const buttons = [
            { label: 'Invite', icon: 'add', click: showCreateDialog },
        ];

        return (
            <section className="md-grid">
                <ProTable status={ALL} items={dataItems} columns={columns} key={ALL} componentName={COMPONENT_NAME} buttons={buttons} emptyCard={() => createEmptyCard(ALL)} />
            </section>
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderCreateDialogForm() }
            { renderUpdateDialogForm() }
            { renderDeletePopupForm() }
            { renderDeleteConsentPopup() }
        </>  
    );
}

export default ClientInvites;