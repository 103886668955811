import React, { useEffect, useState } from 'react';
import { Cell } from 'react-md';
import { setToolbarButtons } from '../../actions/global';
import { selectClientProfile, selectOrganizationId, selectCurrentUser } from '../../selectors/global';
import ProCalendar from '../../components/ProCalendar';
import { callCloudRunFunction } from '../../utils/firestore';
import ProScheduleDisplay from '../../components/ProScheduleDisplay';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import TwoColumnLayout from '../../pageLayouts/TwoColumnLayout';
import { NAV_DEFAULT } from '../../constants/navigation';
import { getClientLabelVariableData } from '../../utils/customBranding';
import { useCustomPageFrameworkLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';
import ScheduleTypeSelectionForm from '../../components/Forms/ScheduleTypeSelectionForm';
import { SCHEDULE_CATEGORY_PARENTING_PLAN, SCHEDULE_CATEGORY_EVENT, SCHEDULE_CATEGORY_HOLIDAY } from '../../constants/schedule';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_ERROR, NOTIFICATION_INFO, NOTIFICATION_SUCCESS } from '../../constants/notifications';
import useDialogFormBuilder from '../../hooks/useDialogFormBuilder';
import CreateParentingPlanForm from './CreateParentingPlanForm';
import CreateEventForm from './CreateEventForm';
import UpdateParentingPlanForm from './UpdateParentingPlanForm';
import UpdateEventForm from './UpdateEventForm';
import DeleteConfirmationForm from '../../components/Forms/DeleteConfirmationForm';
import CreateHolidayForm from './CreateHolidayForm';
import UpdateHolidayForm from './UpdateHolidayForm';

function ClientSchedule() {
    const {
        history,
        selector,
        dispatch,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const clientProfile = selector(selectClientProfile());
    const [ dateTimeInterval, setDateTimeInterval ] = useState({});
    const [ scheduleData, setScheduleData ] = useState({});

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('ClientSchedule', getClientLabelVariableData(clientProfile));

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (!clientProfile) {
            return;
        }

        const toolBarButtons = [{ icon: 'arrow_back', onClick: () => history.goBack() }];
        dispatch(setToolbarButtons(toolBarButtons));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Calendar',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        finishedLoading();
        
        return () => {
            dispatch(setToolbarButtons([]));
        }
    }, []);

    const selectScheduleTypeDialog = {
        FormComponent: ScheduleTypeSelectionForm,
        onSubmit: onScheduleTypeSelected,
        popupData: { title: 'Select the type of schedule you want to create', formId: 'select-schedule-type-form' },
        formData: { formId: 'select-schedule-type-form' },
    };

    const {
        showPopup: showSelectScheduleTypePopup,
        renderPopupForm: renderSelectScheduleTypePopup,
    } = usePopupFormBuilder(selectScheduleTypeDialog);
    
    const createParentingPlanDialog = {
        FormComponent: CreateParentingPlanForm,
        onSubmit: createParentingPlan,
        dialogData: { title: 'Creating new parenting plan' },
        formData: { clientProfile: clientProfile },
    };

    const {
        showDialog: showCreateParentingPlanDialog,
        renderDialogForm: renderCreateParentingPlanDialog,
    } = useDialogFormBuilder(createParentingPlanDialog);

    const createEventDialog = {
        FormComponent: CreateEventForm,
        onSubmit: createEvent,
        dialogData: { title: 'Creating new event' },
        formData: { clientProfile: clientProfile },
    };

    const {
        showDialog: showCreateEventDialog,
        renderDialogForm: renderCreateEventDialog,
    } = useDialogFormBuilder(createEventDialog);

    const createHolidayDialog = {
        FormComponent: CreateHolidayForm,
        onSubmit: createHoliday,
        dialogData: { title: 'Creating new holiday' },
        formData: { clientProfile: clientProfile },
    };

    const {
        showDialog: showCreateHolidayDialog,
        renderDialogForm: renderCreateHolidayDialog,
    } = useDialogFormBuilder(createHolidayDialog);

    const updateParentingPlanDialog = {
        FormComponent: UpdateParentingPlanForm,
        onSubmit: updateParentingPlan,
        dialogData: { title: 'Update parenting plan' },
        formData: { initialValues: scheduleData },
    };

    const {
        showDialog: showUpdateParentingPlanDialog,
        renderDialogForm: renderUpdateParentingPlanDialog,
    } = useDialogFormBuilder(updateParentingPlanDialog);

    const updateEventDialog = {
        FormComponent: UpdateEventForm,
        onSubmit: updateEvent,
        dialogData: { title: 'Update event' },
        formData: { initialValues: scheduleData },
    };

    const {
        showDialog: showUpdateEventDialog,
        renderDialogForm: renderUpdateEventDialog,
    } = useDialogFormBuilder(updateEventDialog);

    const updateHolidayDialog = {
        FormComponent: UpdateHolidayForm,
        onSubmit: updateHoliday,
        dialogData: { title: 'Update holiday' },
        formData: { initialValues: scheduleData },
    };

    const {
        showDialog: showUpdateHolidayDialog,
        renderDialogForm: renderUpdateHolidayDialog,
    } = useDialogFormBuilder(updateHolidayDialog);

    const deleteSchedulePopup = {
        FormComponent: DeleteConfirmationForm,
        onSubmit: () => deleteSchedule(scheduleData),
        onHide: () => setScheduleData({}),
        popupData: { title: `Type \'${'Delete'}\' to delete this schedule.`, formId: 'delete-schedule-confirmation-form' },
        formData: { formId: 'delete-schedule-confirmation-form', initialValues: scheduleData },
    };

    const {
        showPopup: showDeleteSchedulePopup,
        renderPopupForm: renderDeleteSchedulePopup,
    } = usePopupFormBuilder(deleteSchedulePopup);

    if (!clientProfile) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    function onScheduleTypeSelected(scheduleTypeData) {
        switch (scheduleTypeData.scheduleType) {
            case SCHEDULE_CATEGORY_PARENTING_PLAN:
                showCreateParentingPlanDialog();
                break;
            case SCHEDULE_CATEGORY_EVENT:
                showCreateEventDialog();
                break;
            case SCHEDULE_CATEGORY_HOLIDAY:
                showCreateHolidayDialog();
                break;
            default:
                showToastNotification('Something has gone wrong', NOTIFICATION_ERROR);
                break;
        }
    }

    function createParentingPlan(createScheduleData) {
        const parentingPlanMetadata = {
            createdBy: currentUser.displayName,
            createdByUid: currentUser.uid,
        };

        const createdParentingPlan = Object.assign({}, createScheduleData, parentingPlanMetadata);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Calendar',
            action: 'create parenting plan',
            metadata: { ...createdParentingPlan },
        };

        showToastNotification('Creating parenting plan...', NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'createParentingPlan', createdParentingPlan).then((result) => {
            log.scheduleId = result.scheduleId;
            log.metadata.scheduleId = result.scheduleId;
            showToastNotification('A parenting plan has been created', NOTIFICATION_SUCCESS);
            callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('something has gone wrong', NOTIFICATION_ERROR);
        });
    }

    function createEvent(createScheduleData) {
        const eventMetadata = {
            createdBy: currentUser.displayName,
            createdByUid: currentUser.uid,
            clientId: clientProfile.id,
        };

        const createdEvent = Object.assign({}, createScheduleData, eventMetadata);
        
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Calendar',
            action: 'create event',
            metadata: { ...createdEvent },
        };
        
        // showToastNotification('Creating event...', NOTIFICATION_INFO);

        // callCloudRunFunction(currentUser, 'createEvent', createdEvent).then((result) => {
        //     log.scheduleId = result.scheduleId;
        //     log.metadata.scheduleId = result.scheduleId;
        //     showToastNotification('An event has been created', NOTIFICATION_SUCCESS);
        //     callCloudRunFunction(currentUser, 'logProUserAction', log);
        // }).catch((err) => {
        //     console.log(err);
        //     showToastNotification('something has gone wrong', NOTIFICATION_ERROR);
        // });
    }

    function createHoliday(createHolidayData) {
        const holidayMetadata = {
            createdBy: currentUser.displayName,
            createdByUid: currentUser.uid,
            clientId: clientProfile.id,
        };

        const createdHoliday = Object.assign({}, createHolidayData, holidayMetadata);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Calendar',
            action: 'create holiday',
            metadata: { ...createdHoliday },
        };
        
        // showToastNotification('Creating holiday...', NOTIFICATION_INFO);

        // callCloudRunFunction(currentUser, 'createHoliday', createdHoliday).then((result) => {
        //     log.scheduleId = result.scheduleId;
        //     log.metadata.scheduleId = result.scheduleId;
        //     showToastNotification('A holiday has been created', NOTIFICATION_SUCCESS);
        //     callCloudRunFunction(currentUser, 'logProUserAction', log);
        // }).catch((err) => {
        //     console.log(err);
        //     showToastNotification('something has gone wrong', NOTIFICATION_ERROR);
        // });
    }

    function updateParentingPlan(updatedScheduleData) {
        const parentingPlanMetadata = {
            id: scheduleData.id,
        };

        const updatedParentingPlan = Object.assign({}, updatedScheduleData, parentingPlanMetadata);

        showToastNotification('Updating parenting plan schedule', NOTIFICATION_INFO);
        setScheduleData({});

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Calendar',
            action: 'update parenting plan',
            scheduleId: scheduleData.id,
            metadata: { ...updatedParentingPlan },
        };

        callCloudRunFunction(currentUser, 'updateParentingPlan', updatedParentingPlan).then(() => {
            showToastNotification('The parenting plan has been updated', NOTIFICATION_SUCCESS);
            callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Something has gone wrong', NOTIFICATION_ERROR);
        });
    }

    function updateEvent(updatedScheduleData) {
        const eventMetadata = {
            id: scheduleData.id,
            clientId: clientProfile.id,
        };
        
        const updatedEvent = Object.assign({}, updatedScheduleData, eventMetadata);
        
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Calendar',
            action: 'update event',
            scheduleId: scheduleData.id,
            metadata: { ...updatedEvent },
        };
        
        // showToastNotification('Updating event schedule', NOTIFICATION_INFO);
        // setScheduleData({});
        
        // callCloudRunFunction(currentUser, 'updateEvent', updatedEvent).then(() => {
        //     showToastNotification('The event has been updated', NOTIFICATION_SUCCESS);
        //     callCloudRunFunction(currentUser, 'logProUserAction', log);
        // }).catch((err) => {
        //     console.log(err);
        //     showToastNotification('Something has gone wrong', NOTIFICATION_ERROR);
        // });
    }

    function updateHoliday(updatedScheduleData) {
        const holidayMetadata = {
            id: scheduleData.id,
            clientId: clientProfile.id,
        };

        const updatedHoliday = Object.assign({} , updatedScheduleData, holidayMetadata);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Calendar',
            action: 'update holiday',
            scheduleId: scheduleData.id,
            metadata: { ...updatedHoliday },
        };

        // showToastNotification('Updating holiday schedule', NOTIFICATION_INFO);
        // setScheduleData({});

        // callCloudRunFunction(currentUser, 'updateHoliday', updatedHoliday).then(() => {
        //     showToastNotification('The holiday has been updated', NOTIFICATION_SUCCESS);
        //     callCloudRunFunction(currentUser, 'logProUserAction', log);
        // }).catch((err) => {
        //     console.log(err);
        //     showToastNotification('Something has gone wrong', NOTIFICATION_ERROR);
        // });
    }

    function deleteSchedule(deletedScheduleData) {
        setScheduleData({});

        const deletePromises = [];

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Calendar',
            action: 'delete schedule',
            scheduleId: deletedScheduleData.id,
            metadata: { ...deletedScheduleData },
        };

        switch (deletedScheduleData.itemType) {
            case SCHEDULE_CATEGORY_PARENTING_PLAN:
                deletePromises.push(callCloudRunFunction(currentUser, 'deleteSchedule', { uid: deletedScheduleData.id }));
                break;
            case SCHEDULE_CATEGORY_EVENT:
                deletePromises.push(callCloudRunFunction(currentUser, 'deleteAppointment', { uid: deletedScheduleData.id }));
                break;
            case SCHEDULE_CATEGORY_HOLIDAY:
                deletePromises.push(callCloudRunFunction(currentUser, 'deleteHoliday', { uid: deletedScheduleData.id }));
                break;
            default:
                showToastNotification('Something has gone wrong', NOTIFICATION_ERROR);
                return;
        }

        showToastNotification('Deleting schedule', NOTIFICATION_INFO);
        
        Promise.resolve(deletePromises).then(() => {
            showToastNotification('The event has been deleted', NOTIFICATION_SUCCESS);
            callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Something has gone wrong', NOTIFICATION_ERROR);
        });
    }

    const onSelectSchedule = (schedule) => {
        switch (schedule.itemType) {
            case SCHEDULE_CATEGORY_PARENTING_PLAN:
                setDateTimeInterval({ startDate: schedule.startDate, endDate: schedule.endDate });
                break;
            case SCHEDULE_CATEGORY_EVENT:
                setDateTimeInterval({ startDate: schedule.startDateTime, endDate: schedule.endDateTime });
                break;
            case SCHEDULE_CATEGORY_HOLIDAY:
                break;
                // TODO: Calculate start date and end date of holiday, may be unable to handle since this is calculated on the server
        }
    }

    const onEditSchedule = (schedule) => {
        switch (schedule.itemType) {
            case SCHEDULE_CATEGORY_PARENTING_PLAN:
                setScheduleData(schedule);
                showUpdateParentingPlanDialog();
                break;
            case SCHEDULE_CATEGORY_EVENT:
                setScheduleData(schedule);
                showUpdateEventDialog()
                break;
            case SCHEDULE_CATEGORY_HOLIDAY:
                setScheduleData(schedule);
                showUpdateHolidayDialog();
                break;
            default:
                showToastNotification('No schedule to edit. Try again later', NOTIFICATION_ERROR);
        }
    }

    const onDeleteSchedule = (schedule) => {
        setScheduleData(schedule);
        showDeleteSchedulePopup();
    }

    const buildCreateEntryButton = () => {
        return (
            <Cell size={12}>
                <ThemedButton className="full-width-field" flat swapTheming primary iconChildren="add" onClick={showSelectScheduleTypePopup}>
                    Create new schedule
                </ThemedButton>
            </Cell>
        );
    }

    const buildPlanDisplay = () => {
        return (
            <Cell size={12}>
                <ProScheduleDisplay clientId={clientProfile.id} selectSchedule={onSelectSchedule} editSchedule={onEditSchedule} deleteSchedule={onDeleteSchedule}/>
            </Cell>
        );
    }

    const buildScheduleListSection = () => {
        return (
            <>
                { buildCreateEntryButton() }
                { buildPlanDisplay() }
            </>
        );
    }

    const buildCalendarSection = () => {
        return (
            <Cell size={12}>
                <ProCalendar clientId={clientProfile.id} highlightInterval={dateTimeInterval}/>
            </Cell>
        );
    }

    const buildTabletMobileSection = () => {
        // TODO; design better mobile section for displaying client calendar
        return (
            <>
                { buildCreateEntryButton() }
                { buildPlanDisplay() }
                { buildCalendarSection() }
            </>
        );
    }

    function buildPageContent() {
        return (
            <TwoColumnLayout
                buildLeftColumn={buildScheduleListSection}
                buildRightColumn={buildCalendarSection}
                buildTabletMobile={buildTabletMobileSection}
                columnSplit={40}
            />
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderSelectScheduleTypePopup() }
            { renderCreateParentingPlanDialog() }
            { renderCreateEventDialog() }
            { renderCreateHolidayDialog() }
            { renderUpdateParentingPlanDialog() }
            { renderUpdateEventDialog() }
            { renderUpdateHolidayDialog() }
            { renderDeleteSchedulePopup() }
        </>
    );
}

export default ClientSchedule;