import React from 'react';
import moment from 'moment';
import ScheduleLegend from '../ParentingPlan/ScheduleLegend';
import { Grid, Cell, CardText } from 'react-md';
import { SchedulePattern, bufferExchangeTimes } from '../ParentingPlan/SchedulePattern';
import CellDataDisplay from '../CellDataDisplay';

const buildParentingPlanLegend = (value) => {
    return (
        <Grid>
            <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                <ScheduleLegend text={ value.cp1 || "coParent 1" } icon="lens" iconClassName="legend-p1"/>
            </Cell>
            <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                <ScheduleLegend text={ value.cp2 || "coParent 2" } icon="lens" iconClassName="legend-p2"/>
            </Cell>
        </Grid>
    );
}

const buildExchangeTimes = (value) => {
    const { pattern, exchangeTimes } = value;
    const bufferedExchangeTimes = bufferExchangeTimes(pattern, exchangeTimes);

    return <SchedulePattern pattern={pattern} exchangeTimes={bufferedExchangeTimes}/>
}

const buildString = (value) => {
    return (
        <p>{ value }</p>
    );
}

const agreementControls = [
    { controlType: 'parentingPlanLegend', controlBuilder: buildParentingPlanLegend },
    { controlType: 'exchangeTimes', controlBuilder: buildExchangeTimes },
];


const getControlBuilderFunction = (controlType) => {
    const controlObj = agreementControls.find((control) => control.controlType === controlType);

    return controlObj ? controlObj.controlBuilder : buildString;
}

const buildDetailSection = (detail) => {
    return getControlBuilderFunction(detail.control)(detail.value);
}

const AgreementList = ({ displayAgreement = [] }) => {
    // Exclude building the 'parentingPlanPattern' section due to the pattern being built in the 'exchangeTimes' section
    const details = displayAgreement.sort((a, b) => a.sentence < b.sentence).filter((x) => x.control !== 'parentingPlanPattern');
    return (
        <CardText>
            {
                details.map((detail, index) => {
                    return (
                        <div id={`agreement-list-section-${index}`}>
                            { buildDetailSection(detail) }
                        </div>
                    )
                })
            }
        </CardText>
    );
};

const DetailedAgreementList = ({ displayDetails = [], createdBy, createdOn, updatedOn }) => {
    // Exclude building the 'parentingPlanPattern' section due to the pattern being built in the 'exchangeTimes' section
    const details = displayDetails.filter((x) => x.control !== 'parentingPlanPattern');
    const style = { marginTop: '10px' };

    return (
        <CardText>
            <Grid>
                {
                    details.map((detail) => {
                        const numItemsInRow = details.filter((x) => x.row === detail.row).length || 1;

                        return (
                            <Cell desktopSize={12/numItemsInRow} tabletSize={8/numItemsInRow} phoneSize={4}>
                                <h4 style={style}>{detail.title.toUpperCase()}</h4>
                                { buildDetailSection(detail) }
                            </Cell>
                        )
                    })
                }
                <CellDataDisplay
                    headerText={'Status'}
                    valueText={'Agreement'}
                />
                <CellDataDisplay
                    headerText={`Created By ${createdBy} On`}
                    valueText={moment(createdOn).format('DD-MMM-YYYY [at] h:mm a')}
                />
                <CellDataDisplay
                    headerText={'Agreed by On'}
                    valueText={moment(updatedOn).format('DD-MMM-YYYY [at] h:mm a')}
                />
            </Grid>
        </CardText>
    );
}

export {
    AgreementList,
    DetailedAgreementList,
};