import React from 'react';
import moment from 'moment';
import { Avatar, ListItem, List } from 'react-md';

function buildMessageListItem(message) {
    return (
        <ListItem key={`${message.index}-${message.id}`} 
            leftAvatar={<Avatar suffix={message.member.color}>{message.member.authorName.substring(0,1)}</Avatar>}
            primaryText={`${message.member.authorName} (${moment(message.timestamp).calendar()})`}
            secondaryText={message.body}
            threeLines
        />
    );
}

// Assumes that messages are sorted in the correct order
function MessagesList(props) {
    const { members = [], messages = [] } = props;
    
    const memberColors = ['deep-purple', 'green', 'orange', 'blue', 'yellow', 'pink'];
    const unknownMember = {
        color: 'grey',
        firstName: 'Unknown',
        lastName: '',
        authorName: 'Unknown',
    };

    const membersWithColor = members.map((member, index) => {
        member.color = memberColors[index];
        member.authorName = `${member.firstName} ${member.lastName}`;
        return member;
    })

    const mappedMessages = messages.map((message) => {
        message.member = membersWithColor.find(member => member.uid === message.author) || unknownMember;
        return message;
    });

    return (
        <List>
            { mappedMessages.map(buildMessageListItem) }
        </List>
    );
    
}

export default MessagesList