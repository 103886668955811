import React from 'react';
import { Cell, Button, Grid } from 'react-md';
import { COUNTRIES } from '../../constants/lookupInfo';
import { selectCurrentOrganization, selectDataItemsFromComponent, selectAllOrganizationRoles, selectAllClientViewRoles, selectAllManagementRoles } from '../../selectors/global';
import { parseRoleControls, selectableOrganizationRoles, selectableClientViewRoles, parseRoleControlsStringToInt, parseUserRoleToString, filterSelectableroles, selectableManagementRoles } from '../../utils/permissions';
import TextFormField from '../../components/FormFields/TextFormField';
import SelectionFormField from '../../components/FormFields/SelectionFormField';
import CheckboxFormField from '../../components/FormFields/CheckboxFormField';
import useFormValidation from '../../hooks/useFormValidation';
import { validate } from './CreateOrganizationValidate';
import { ORG_ROLE_VIEW_CLIENT, ORG_ROLE_MANAGE_ORG } from '../../constants/roles';
import { normalizePhone } from '../../components/RequestFields/normalizePhone';
import usePageFramework from '../../hooks/usePageFramework';
import { sortTextFunction, sortDataItems } from '../../utils/sortFilter';
import { FILTER_TEXT } from '../../constants';
import ToggleFormField from '../../components/FormFields/ToggleFormField';

const ORG_TYPES_COMPONENT = 'OrganizationTypes';

function CreateOrganizationForm (props) {
    const { onHide, onSubmit } = props;

    const {
        selector,
    } = usePageFramework();

    const currentOrganization = selector(selectCurrentOrganization());
    const organizationRoles = selector(selectAllOrganizationRoles());
    const clientViewRoles = selector(selectAllClientViewRoles());
    const managementRoles = selector(selectAllManagementRoles());
    const organizationTypes = selector(selectDataItemsFromComponent(ORG_TYPES_COMPONENT));
    const sortedOrganizationTypes = sortDataItems(organizationTypes, {type: FILTER_TEXT, value: 'orgTypeName'}, false);

    const INITIAL_STATE = {
        organizationName: '',
        organizationType: '',
        country: COUNTRIES[1].id,
        state: '',
        city: '',
        zip: '',
        county: '',
        primaryContact: '',
        email: '',
        phone: '',
        orgRoles: '',
        clientViewRoles: '',
        landingPageUrl: 'https://coparenter.com/download/',
        trialDayAmount: '',
        copyTenantTheme: false,
    };

    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, submitNewOrganization, validate);


    const country = COUNTRIES.find((country) => country.id === values.country);
    const divisions = country ? country.divisions : [];
    
    const foundOrgType = organizationTypes.find((x) => x.orgTypeName === values.organizationType);

    // All the possible roles for other organizations
    const selectableOrgRoles = selectableOrganizationRoles(currentOrganization, organizationRoles, false).filter(x => !x.isExclusive);
    const selectableViewRoles = selectableClientViewRoles(currentOrganization, clientViewRoles).filter(x => !x.isExclusive);
    const selectableManagerRoles = selectableManagementRoles(currentOrganization, managementRoles).filter(x => !x.isExclusive);
    
    // All the available roles to select due to org type constraint
    const selectableOrgTypeRoles = filterSelectableroles(selectableOrgRoles, foundOrgType ? foundOrgType.orgRoles : 0);
    const selectableViewTypeRoles = filterSelectableroles(selectableViewRoles, foundOrgType ? foundOrgType.clientViewRoles : 0);
    const selectableManagerTypeRoles = filterSelectableroles(selectableManagerRoles, foundOrgType ? foundOrgType.managementRoles : 0);

    const organizationRoleControls = parseRoleControls(selectableOrgTypeRoles);
    const clientViewRoleControls = parseRoleControls(selectableViewTypeRoles);
    const managementRoleControls = parseRoleControls(selectableManagerTypeRoles);
    const showingClientControls = parseRoleControlsStringToInt(values.orgRoles) & ORG_ROLE_VIEW_CLIENT ? true : false;
    const showingManagementControls = parseRoleControlsStringToInt(values.orgRoles) & ORG_ROLE_MANAGE_ORG ? true : false;

    function submitNewOrganization(values) {
        const organization = parseSubmissionToOrganization(values);
        onSubmit(organization);
    }

    function parseSubmissionToOrganization(values) {
        const organizationObject = {
            orgName: values.organizationName.trim(),
            organizationType: values.organizationType,
            country: values.country,
            state: values.state,
            city: values.city,
            zip: values.zip,
            county: values.county,
            primaryContact: values.primaryContact.trim(),
            email: values.email,
            phone: normalizePhone(values.phone),
            orgRoles: parseRoleControlsStringToInt(values.orgRoles || ''),
            clientViewRoles: showingClientControls ? parseRoleControlsStringToInt(values.clientViewRoles || '') : 0,
            managementRoles: showingManagementControls ? parseRoleControlsStringToInt(values.managementRoles || '') : 0,
            landingPageUrl: values.landingPageUrl,
            trialDayAmount: values.trialDayAmount,
            copyTenantTheme: values.copyTenantTheme || false,
        };

        return organizationObject;
    }

    function handleOrgTypeChange(name, value) {
        // Change roles depending on org type change
        const selectedOrgType = organizationTypes.find((x) => x.orgTypeName === value);
        
        if (!selectedOrgType) {
            return;
        }

        // Auto select roles provided in org type
        const selectedOrgTypeRoles = filterSelectableroles(selectableOrgRoles, selectedOrgType ? selectedOrgType.orgRoles : 0);
        const selectedViewTypeRoles = filterSelectableroles(selectableViewRoles, selectedOrgType ? selectedOrgType.clientViewRoles : 0);
        const selectedManagerTypeRoles = filterSelectableroles(selectableManagerRoles, selectedOrgType ? selectedOrgType.managementRoles : 0);

        const changes = {
            [name]: value,
            orgRoles: parseUserRoleToString(selectedOrgType.orgRoles, selectedOrgTypeRoles),
            clientViewRoles: parseUserRoleToString(selectedOrgType.clientViewRoles, selectedViewTypeRoles),
            managementRoles: parseUserRoleToString(selectedOrgType.managementRoles, selectedManagerTypeRoles),
        };

        changeValues(changes);
    }

    function handleOrgRoleChange(name, value) {
        // Clear selected client view roles if client viewing is toggled off
        const changes = {
            clientViewRoles: '',
            managementRoles: '',
            [name]: value,
        };

        const orgRoleValue = parseRoleControlsStringToInt(value);
        
        if (orgRoleValue & ORG_ROLE_VIEW_CLIENT) {
            delete changes.clientViewRoles;
        }

        if (orgRoleValue & ORG_ROLE_MANAGE_ORG) {
            delete changes.managementRoles;
        }

        changeValues(changes);
    }

    function handleCountryChange(name, value) {
        changeValues({ state: '', zip: '', [name]: value });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="organizationName"
                        label="Organization"
                        values={values}
                        errors={errors}
                        customSize="title"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <ToggleFormField
                        name="copyTenantTheme"
                        label="Copy Default Tenant Theme"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={4} phoneSize={4}>
                    <SelectionFormField
                        name="organizationType"
                        label="Organization Type"
                        values={values}
                        errors={errors}
                        menuItems={sortedOrganizationTypes}
                        itemLabel="orgTypeName"
                        itemValue="orgTypeName"
                        required
                        onChange={handleOrgTypeChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={4} phoneSize={4}>
                    <SelectionFormField
                        name="country"
                        label="Country"
                        values={values}
                        errors={errors}
                        menuItems={COUNTRIES}
                        itemLabel="name"
                        itemValue="id"
                        required
                        onChange={handleCountryChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={4} phoneSize={4}>
                    <SelectionFormField
                        name="state"
                        label="State/Province"
                        values={values}
                        errors={errors}
                        menuItems={divisions}
                        itemLabel="name"
                        itemValue="id"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={4} phoneSize={4}>
                    <TextFormField
                        name="city"
                        label="City"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={4} phoneSize={4}>
                    <TextFormField
                        name="zip"
                        label="Zip/Postal Code"
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={4} phoneSize={4}>
                    <TextFormField
                        name="county"
                        label="County/Zone"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={4} phoneSize={4}>
                    <TextFormField
                        name="primaryContact"
                        label="Primary Contact"
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={4} phoneSize={4}>
                    <TextFormField
                        name="email"
                        label="Email"
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={4} phoneSize={4}>
                    <TextFormField
                        name="phone"
                        label="Phone"
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <CheckboxFormField
                        name="orgRoles"
                        label="Available Roles for Organization"
                        values={values}
                        errors={errors}
                        controls={organizationRoleControls}
                        onChange={handleOrgRoleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    {
                        showingClientControls &&
                            <CheckboxFormField
                                name="clientViewRoles"
                                label="Client Viewing Role(s) (Must have View Client Role Selected)"
                                values={values}
                                errors={errors}
                                controls={clientViewRoleControls}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={2}>
                    {
                        showingManagementControls &&
                            <CheckboxFormField
                            name="managementRoles"
                            label="Management Role(s) (Must have Manage Organization Role selected)"
                                values={values}
                                errors={errors}
                                controls={managementRoleControls}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <TextFormField
                        name="landingPageUrl"
                        label="Landing Page URL"
                        values={values}
                        errors={errors}
                        disabled
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <TextFormField
                        name="trialDayAmount"
                        label="Trial Day Amount (Defaults to 30 days)"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <Button className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>Create Organization</Button>
                </Cell>
            </Grid>
        </form>
    );
}

export default CreateOrganizationForm;