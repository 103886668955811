import React from 'react';
import { Grid, Cell } from 'react-md';
import ScheduleLegend from '../ParentingPlan/ScheduleLegend';

const CalendarLegend = ({coParentOneName = 'coParent One', coParentTwoName = 'coParent Two'}) => {
    const legendItems = [
        { id: 'legend-p1', icon: 'lens', iconClassName: 'legend-p1', text: coParentOneName },
        { id: 'legend-p2', icon: 'lens', iconClassName: 'legend-p2', text: coParentTwoName },
        { id: 'legend-event', icon: 'lens', iconClassName: 'legend-event', text: 'Appointment' },
        { id: 'legend-expense', icon: 'lens', iconClassName: 'legend-expense' , text: 'Expense Due Date' },
        { id: 'legend-activity', icon: 'lens', iconClassName: 'legend-activity' , text: 'Activity' },
        { id: 'legend-holiday', icon: 'lens', iconClassName: 'legend-holiday' , text: 'Holiday' },
        { id: 'legend-swap', icon: 'lens', iconClassName: 'legend-swap' , text: 'Swap Day' },
        { id: 'legend-birthday', icon: 'redeem', iconClassName: 'legend-birthday', text: 'Birthday' },
    ];

    return (
        <Grid id="calendar-legend">
            {
                legendItems.map((legendItem) => {
                    return (
                        <Cell id={legendItem.id} desktopSize={3} tabletSize={4} phoneSize={4}>
                            <ScheduleLegend {...legendItem} size={24} />
                        </Cell>
                    );
                })
            }
        </Grid>
    );
}

export default CalendarLegend;