import React, { useEffect, useState } from 'react';
import useFormValidation from "../../hooks/useFormValidation";
import { getFirstAndLastName } from '../../utils/strings';
import { Grid, Cell } from 'react-md';
import TextFormField from '../../components/FormFields/TextFormField';
import { normalizePhone } from '../../components/RequestFields/normalizePhone';
import { validate } from './UpdateClientInviteValidate';
import { parseGroupControls } from '../../utils/orgGroups';
import SelectionFormField from '../../components/FormFields/SelectionFormField';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_ERROR } from '../../constants/notifications';
import ClientInfoFormSection from './ClientInfoFormSection';
import { EMPTY_CLIENT_OBJECT, CONNECTED } from '../../constants/clients';
import usePageFramework from '../../hooks/usePageFramework';
import { callCloudRunFunction } from '../../utils/firestore';
import { selectCurrentUser } from '../../selectors/global';

function UpdateClientInviteForm(props) {
    const { onSubmit, onHide, initialValues, isUsingGroups, selectableOrgGroups } = props;
    const {
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const [ postClientIsConnected, setPostClientIsConnected ] = useState(false);
    const [ hasPostClient, setHasPostClient ] = useState(false);
    const [ initialClient2Data, setInitialClient2Data ] = useState(EMPTY_CLIENT_OBJECT);

    const parsedNames = getFirstAndLastName(initialValues.displayName || '');

    const initialClient1 = {
        firstName: parsedNames[0],
        lastName: parsedNames[1],
        sms: initialValues.sms,
        email: initialValues.email,
    };

    const INITIAL_STATE = {
        uid: initialValues.uid,
        client1: initialClient1,
        client2: null,
        isUsingGroups: isUsingGroups || false,
        caseNumber: initialValues.caseNumber || '',
        orgGroup: initialValues.orgGroup,
    };

    const orgGroupControls = parseGroupControls(selectableOrgGroups);

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, updateClientInvite, validate);

    // Effect to load the second coParent of the connection
    useEffect(() => {
        if (!initialValues.uid) return;

        callCloudRunFunction(currentUser, 'checkForReferencedInvite', { connectionId: initialValues.uid }).then((result) => {
            const { hasPostConnect, postConnectData } = result;

            if (hasPostConnect && postConnectData) {
                const parsedPostNames = getFirstAndLastName(postConnectData.displayName || '');
                const postClientConnected = postConnectData.status === CONNECTED;
                const hasPostClient = true;
    
                const loadedClient = {
                    firstName: parsedPostNames[0],
                    lastName: parsedPostNames[1],
                    sms: postConnectData.sms,
                    email: postConnectData.email,
                };
    
                setInitialClient2Data(loadedClient);
                handleChange('client2', loadedClient);
                setPostClientIsConnected(postClientConnected);
                setHasPostClient(hasPostClient);
            }

            // else single client invite, do nothing
        }).catch((err) => {
            console.log(err);
            handleChange('client2', null);
            showToastNotification('Failed to load second connected client', NOTIFICATION_ERROR);
        });
    }, [ initialValues ]);

    function updateClientInvite(values) {
        const clientInvite = parseSubmissionToInvite(values);
        onSubmit(clientInvite);
    }

    function parseClientSubmission(key) {
        const clientValues = values[key];

        if (!clientValues) return null;

        const clientData = {
            firstName: clientValues.firstName.trim(),
            lastName: clientValues.lastName.trim(),
            sms: normalizePhone(clientValues.sms.trim()),
            email: clientValues.email.trim(),
        };

        return clientData;
    }

    function parseSubmissionToInvite(values) {
        const clientKeys = [ 'client1', 'client2' ];
        const clientInvite = {
            connectionId: values.uid,
            clients: clientKeys.map((clientKey) => parseClientSubmission(clientKey)).filter(x => x),
            caseNumber: values.caseNumber.trim(),
            orgGroup: values.orgGroup.trim(),
        };

        if (!values.isUsingGroups) {
            delete clientInvite.orgGroup;
        }

        return clientInvite;
    }

    const buildClientSection = (key, disabled = false) => {
        return (
            <ClientInfoFormSection
                values={values}
                errors={errors}
                clientKey={key}
                getDataLabel={getDataLabel}
                disabled={disabled}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />
        );
    }

    const buildClient2HeaderSection = () => {
        return !values.client2
            ? 
                (<>
                    <Cell size={12}>
                        <ThemedButton id={'client-2-add-action'} flat iconChildren='add' onClick={() => handleChange('client2', initialClient2Data)}>
                            Add coParent as client 2
                        </ThemedButton>
                    </Cell>
                </>)
            :
                (<>
                    <Cell desktopSize={6} tabletSize={4} phoneSize={2}><h2>Client 2</h2></Cell>
                    <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                        <ThemedButton id={'client-2-add-action'} flat disabled={postClientIsConnected} iconChildren='remove' onClick={() => handleChange('client2', null)}>
                            Remove client 2
                        </ThemedButton>
                    </Cell>
                </>)
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}><h2>Client 1</h2></Cell>
                { buildClientSection('client1') }
                { buildClient2HeaderSection() }
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>{ postClientIsConnected && <p>Client 2 has already accepted this invitation. You may not change or remove this invitation</p> }</Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>{ hasPostClient && (!values.client2) && <p>This will delete client 2's invitation.</p> }</Cell>
                { buildClientSection('client2', postClientIsConnected) }
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="caseNumber"
                        label={'Case Number'}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    {
                        isUsingGroups && <SelectionFormField
                                name="orgGroup"
                                label={getDataLabel('groupData')}
                                values={values}
                                errors={errors}
                                menuItems={orgGroupControls}
                                itemLabel="label"
                                itemValue="value"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>Submit and Resend Invite</ThemedButton>
                </Cell>
            </Grid>
        </form>
    );
}

export default UpdateClientInviteForm;