import React from 'react';
import { Badge } from 'react-md';

function ThemedBadge(props) {
    const badgeStyle = { backgroundColor: 'red', color: 'white' };

    return (
        <Badge
            badgeStyle={badgeStyle}
            invisibleOnZero
            {...props}
        />
    );
}

export default ThemedBadge;