import React from 'react';
import { SelectionControl } from 'react-md';

export const renderToggleField = ({ input, defaultChecked, label, meta: { touched, error }, ...custom }) => (
    <SelectionControl
        {...custom}
        name={ input.name }
        defaultChecked={defaultChecked}
        label={ label }
        onChange={ input.onChange }
    />
);

export default renderToggleField;