import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { selectCurrentOrganization, selectCurrentUser } from '../../selectors/global';
import UpdateAllocationForm from './UpdateAllocationForm';
import { Grid, Cell, Card, CardText } from 'react-md';
import { NAV_DEFAULT } from '../../constants/navigation';
import { setToolbarButtons } from '../../actions/global';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants/notifications';
import usePopupConsentBuilder from '../../hooks/usePopupConsentBuilder';
import MediationAssignmentConsent from './MediationAssignmentConsent';
import ReassignMediationsForm from '../../components/MediationReassignment/ReassignMediationsForm';
import useDialogFormBuilder from '../../hooks/useDialogFormBuilder';
import { ORG_ROLE_MEDIATION, ORG_ROLE_SUPER_ADMIN } from '../../constants/roles';
import { callCloudRunFunction } from '../../utils/firestore';
import { useCustomPageFrameworkLabels } from '../../hooks/useCustomLabels';

function MediationAllocation() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const currentOrganization = selector(selectCurrentOrganization());
    const [ professionals, setProfessionals ] = useState([]);
    const [ recentlyZeroedPros, setRecentlyZeroedPros ] = useState([]);

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('MediationAllocation');

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (!currentUser || !currentOrganization) {
            return;
        }

        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() } ];

        dispatch(setToolbarButtons(toolBarButtons));

        const log = {
            proId: currentUser.uid,
            organizationId: currentOrganization.id,
            page: 'Mediation Allocation',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        reloadData();
        return () => {
            dispatch(setToolbarButtons([]));
        }
    }, []);

    const reassignMediationsDialog = {
        FormComponent: ReassignMediationsForm,
        onSubmit: reassignMediations,
        dialogData: { title: 'Re-assign Active Mediations' },
        formData: { recentlyZeroedPros: recentlyZeroedPros, professionals: professionals },
    };

    const {
        showDialog: showReassignDialog,
        renderDialogForm: renderReassignDialogForm,
    } = useDialogFormBuilder(reassignMediationsDialog);

    const reassignMediationConsent = {
        Component: MediationAssignmentConsent,
        popupData: { title: 'Do you wish to re-assign active mediations from mediator\'s whose weightings has dropped to zero?', negativeLabel: 'No', positiveLabel: 'Yes' },
        contentData: { professionals: recentlyZeroedPros },
        onAccept: showReassignDialog,
        onCancel: () => setRecentlyZeroedPros([]),
    };

    const {
        showPopup: showReassignConsentPopup,
        renderPopupConsent: renderReassignConsentPopup,
    } = usePopupConsentBuilder(reassignMediationConsent);

    const reloadData = () => {
        const db = firebase.firestore();

        db.collection('ProApprovedProfessionals').where('organizationId', '==', currentOrganization.id).where('isActive', '==', true).get().then((querySnapshot) => {
            const professionals = querySnapshot.docs.reduce((prev, curr) => {
                const professional = curr.data();

                if (professional.roles && professional.roles.orgRoles) {
                    const mediatorOrgRoles = professional.roles.orgRoles;

                    if (mediatorOrgRoles & ORG_ROLE_MEDIATION || mediatorOrgRoles & ORG_ROLE_SUPER_ADMIN) {
                        professional.id = curr.id;
                        professional.mediationWeight = professional.mediationWeight || 0;
                        
                        prev.push(professional);
                    }
                }

                return prev;
            }, []);

            setProfessionals(professionals);
            finishedLoading();
        }).catch((err) => {
            console.log(err);
            errorLoading();
        });
    }

    if (!currentUser || !currentOrganization) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    function reassignMediations(reassignments) {
        const log = {
            proId: currentUser.uid,
            organizationId: currentOrganization.id,
            page: 'Mediation Allocation',
            action: 're-assign mediations',
            metadata: reassignments,
        };

        const promises = [];

        reassignments.forEach((reassignment) => {
            promises.push(callCloudRunFunction(currentUser, 'updateMediationAssignment', reassignment));
        });

        promises.push(callCloudRunFunction(currentUser, 'logProUserAction', log));

        showToastNotification('Re-assigning mediations...', NOTIFICATION_INFO);

        Promise.all(promises).then(() => {
            showToastNotification('Mediations were re-assigned', NOTIFICATION_SUCCESS);
            setRecentlyZeroedPros([]);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Mediations could not be re-assigned', NOTIFICATION_ERROR);
        });
    }
    
    function updateAllocations(allocations) {
        const log = {
            proId: currentUser.uid,
            organizationId: currentOrganization.id,
            page: 'Mediation Allocation',
            action: 'update allocations',
            metadata: { allocations: allocations }
        };
        
        showToastNotification(`Updating mediation weighting for ${currentOrganization.organization}`, NOTIFICATION_INFO);
        
        const promises = [];
        promises.push(callCloudRunFunction(currentUser, 'updateMediationWeightings', {allocations: allocations}));
        promises.push(callCloudRunFunction(currentUser, 'logProUserAction', log));

        Promise.all(promises).then(() => {
            showToastNotification('Sucessfully updated mediation weightings', NOTIFICATION_SUCCESS);

            const allocationsWithZeroWeight = allocations.filter(allocation => allocation.mediationWeight === 0);

            // Find pros that were changed to zero weight, and ask User if they want to also re-assign their mediations
            if (allocationsWithZeroWeight.length > 0) {
                const professionalsWithZeroWeight = allocationsWithZeroWeight.map(allocation => professionals.find(pro => pro.id === allocation.id));
                setRecentlyZeroedPros(professionalsWithZeroWeight);
                showReassignConsentPopup();
            }
        }).catch((err) => {
            console.log(err);
            showToastNotification('An error has occurred', NOTIFICATION_ERROR);
        }).finally(() => {
            reloadData();
        });
    }

    function buildPageContent() {
        return (
            <Grid>
                <Cell size={12}>
                    <Card>
                        <CardText>
                            <UpdateAllocationForm
                                onSubmit={updateAllocations}
                                initialValues={{professionals: professionals}}
                            />
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderReassignConsentPopup() }
            { renderReassignDialogForm() }
        </>
    );
}

export default MediationAllocation;