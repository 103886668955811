import React from 'react';
import { Button } from 'react-md';

export default function EndCallButton({ room, localTracks }) {
    const style={ margin: '0px 00px'};

    const disconnect = () => {
        console.log('disconnecting...');
        const { localParticipant } = room;
        const videoTrack = localTracks.find(track => track.name === 'camera');
        const audioTrack = localTracks.find(track => track.kind === 'audio');

        if (videoTrack) {
            if (localParticipant) {
                const localTrackPublication = localParticipant.unpublishTrack(videoTrack);
                localParticipant.emit('trackUnpublished', localTrackPublication);
            }

            videoTrack.stop();
        }

        if (audioTrack) {
            if (localParticipant) {
                const localTrackPublication = localParticipant.unpublishTrack(audioTrack);
                localParticipant.emit('trackUnpublished', localTrackPublication);
            }

            audioTrack.stop();
        }


        room.disconnect();
    }

    return (
        <Button style={style} floating primary onClick={() => disconnect()}>call_end</Button>
    );
}
