import React, { useEffect } from 'react';
import { Button, Cell, Grid } from 'react-md';
import { parseRoleControls, selectableOrganizationRoles, selectableClientViewRoles, parseRoleControlsStringToInt, selectableManagementRoles } from '../../utils/permissions';
import useFormValidation from '../../hooks/useFormValidation';
import { validate, asyncValidate } from './inviteProValidate';
import CheckboxFormField from '../../components/FormFields/CheckboxFormField';
import SelectionFormField from '../../components/FormFields/SelectionFormField';
import TextFormField from '../../components/FormFields/TextFormField';
import usePageFramework from '../../hooks/usePageFramework';
import { selectAllOrganizationRoles, selectAllClientViewRoles, selectDataItemsFromComponent, selectOrganizationId, selectAllManagementRoles } from '../../selectors/global';
import { parseGroupControls, parseGroupStringToArray } from '../../utils/orgGroups';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';
import { ORG_ROLE_VIEW_CLIENT, ORG_ROLE_MANAGE_ORG } from '../../constants/roles';

function InviteProfessionalForm (props) {
    const { onHide, onSubmit, selectableOrgGroups } = props;
    const {
        selector,
    } = usePageFramework();

    const organizationRoles = selector(selectAllOrganizationRoles());
    const clientViewRoles = selector(selectAllClientViewRoles());
    const managementRoles = selector(selectAllManagementRoles());
    const organizationId = selector(selectOrganizationId());
    const organizations = selector(selectDataItemsFromComponent('Organizations'));

    const hasSelectableGroups = selectableOrgGroups && selectableOrgGroups.length ? true : false;

    const INITIAL_STATE = {
        email: '',
        organizationId: organizationId || '',
        firstName: '',
        lastName: '',
        orgRoles: '',
        clientViewRoles: '',
        managementRoles: '',
        orgGroups: '',
        requiresGroup: hasSelectableGroups,
    };

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        changeValues,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, submitInvite, validate, asyncValidate);

    const focusedOrganization = organizations && organizations.find(x => x.id === organizationId);

    const organizationRoleControls = parseRoleControls(selectableOrganizationRoles(focusedOrganization, organizationRoles, true));
    const clientViewRoleControls = parseRoleControls(selectableClientViewRoles(focusedOrganization, clientViewRoles));
    const managementRoleControls = parseRoleControls(selectableManagementRoles(focusedOrganization, managementRoles));
    const showingClientControls = parseRoleControlsStringToInt(values.orgRoles) & ORG_ROLE_VIEW_CLIENT ? true : false;
    const showingManagementControls = parseRoleControlsStringToInt(values.orgRoles) & ORG_ROLE_MANAGE_ORG ? true : false;

    const organizationGroupsControls = parseGroupControls(selectableOrgGroups);

    function handleOrgRoleChange(name, value) {
        // Clear selected client view roles if client viewing is toggled off
        const changes = {
            clientViewRoles: '',
            managementRoles: '',
            [name]: value,
        };

        const orgRoleValue = parseRoleControlsStringToInt(value);
        
        if (orgRoleValue & ORG_ROLE_VIEW_CLIENT) {
            delete changes.clientViewRoles;
        }

        if (orgRoleValue & ORG_ROLE_MANAGE_ORG) {
            delete changes.managementRoles;
        }

        changeValues(changes);
    }

    function submitInvite(values) {
        const invitation = parseSubmissionToInvite(values);
        onSubmit(invitation);
    }

    function parseSubmissionToInvite(values) {
        const inviteObj = {
            displayName: `${values.firstName.trim()} ${values.lastName.trim()}`,
            email: values.email.toLowerCase(),
            organizationId: values.organizationId,
            roles: {
                orgRoles: parseRoleControlsStringToInt(values.orgRoles || ''),
                clientViewRoles: showingClientControls ? parseRoleControlsStringToInt(values.clientViewRoles || '') : 0,
                managementRoles: showingManagementControls ? parseRoleControlsStringToInt(values.managementRoles || '') : 0,
            },
            orgGroups: values.requiresGroup ? parseGroupStringToArray(values.orgGroups) : [],
            status: 'pending',
        };

        return inviteObj;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="email"
                        label={getDataLabel('emailData')}
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={8} phoneSize={4}>
                    <SelectionFormField
                        name="organizationId"
                        label={getDataLabel('organizationData')}
                        values={values}
                        errors={errors}
                        menuItems={organizations}
                        itemLabel="org"
                        itemValue="id"
                        disabled
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={4} phoneSize={4}>
                    <TextFormField
                        name="firstName"
                        label={getDataLabel('firstNameData')}
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={4} phoneSize={4}>
                    <TextFormField
                        name="lastName"
                        label={getDataLabel('lastNameData')}
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <CheckboxFormField
                        name="orgRoles"
                        label="Member Roles"
                        values={values}
                        errors={errors}
                        controls={organizationRoleControls}
                        onChange={handleOrgRoleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    {
                        showingClientControls &&
                            <CheckboxFormField
                                name="clientViewRoles"
                                label="Client Viewing Role(s) (Must have View Client Role Selected)"
                                values={values}
                                errors={errors}
                                controls={clientViewRoleControls}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    {
                        showingManagementControls &&
                            <CheckboxFormField
                                name="managementRoles"
                                label="Management Role(s) (Must have Manage Organization Role selected)"
                                values={values}
                                errors={errors}
                                controls={managementRoleControls}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    {
                        hasSelectableGroups && <CheckboxFormField
                            name="orgGroups"
                            label={getDataLabel('groupData')}
                            values={values}
                            errors={errors}
                            controls={organizationGroupsControls}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    }
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton flat swapTheming type="submit" disabled={isSubmitting} className='full-width-field'>Invite</ThemedButton>
                </Cell>
            </Grid>
        </form>
    );
}

export default InviteProfessionalForm;