import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useParticipants() {
    const room = useSelector(state => state.get('global').get('room'));
    const dominantSpeaker = useSelector(state => state.get('global').get('dominantSpeaker'));
    const [participants, setParticipants] = useState(Array.from([]));
    
    console.log(dominantSpeaker);
    // When the dominant speaker changes, they are moved to the front of the participants array.
    // This means that the most recent dominant speakers will always be near the top of the
    // ParticipantStrip component.
    
    useEffect(() => {
        if (dominantSpeaker) {
            setParticipants(prevParticipants => [
                dominantSpeaker,
                ...prevParticipants.filter(participant => participant !== dominantSpeaker),
            ]);
        }
    }, [dominantSpeaker]);

    useEffect(() => {
        if (room && room.participants && !dominantSpeaker) {
            console.log('setting partipants');
            setParticipants(Array.from(room.participants.values()));
        }
    }, [room]);
    
    useEffect(() => {
        const participantConnected = (participant) => setParticipants(prevParticipants => [...prevParticipants, participant]);
        const participantDisconnected = (participant) => setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
        
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        
        return () => {
            room.off('participantConnected', participantConnected);
            room.off('participantDisconnected', participantDisconnected);
        };
    }, [room]);
    
    return participants;
}
