function validate(values) {
    let errors = {};
    
    if (!values.assignment) {
        errors.assignment = 'An assignment is required';
    }

    return errors;
}

export {
    validate,
};