import React, { useState } from 'react';
import { SelectionControlGroup, Grid, Cell } from 'react-md';
import Bounce from 'react-reveal/Bounce';

export default function RadioFormField (props) {
    const { name, label, values, errors, controls, onChange, disabled, showSelectAllButton = true } = props;

    const formProps = Object.assign({}, props);
    delete formProps['name'];
    delete formProps['values'];
    delete formProps['errors'];
    delete formProps['onChange'];
    delete formProps['label'];
    delete formProps['showSelectAllButton'];

    const buildControlHeader = () => {
        return (
            <Grid>
                <Cell size={12}>{ label }</Cell>
            </Grid>
        );
    }

    return (
        <>
            <SelectionControlGroup
                value={values[name]}
                id={name}
                name={name}
                type="radio"
                className='full-width-field'
                onChange={(value, selectionname) => onChange(name, value)}
                label={buildControlHeader()}
                labelComponent={'div'}
                {...formProps}
            />
            <Bounce when={!!errors[name]}><p className="error-text">{errors[name] || '...'}</p></Bounce>
        </>
    );
}