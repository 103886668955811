import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import { Grid, Cell } from 'react-md';
import { selectCurrentUser, selectClientProfile,
    selectOrganizationId, selectCurrentUserRoles } from '../../selectors/global';
import { NAV_CLIENT_DETAILS } from '../../constants/navigation';
import { setClientProfile, setToolbarButtons } from '../../actions/global';
import { ManagementCardFromUpload } from '../../components/ManagementCard';
import { getAccessibleClientViewCards } from '../../utils/permissions';
import { formatPhone } from '../../components/RequestFields/formatPhone';
import Fade from 'react-reveal/Fade';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { callCloudRunFunction } from '../../utils/firestore';
import { useCustomPageFrameworkLabels } from '../../hooks/useCustomLabels';
import { getClientLabelVariableData } from '../../utils/customBranding';
import useCustomAssets from '../../hooks/useCustomAssets';

function ClientDetails() {
    const {
        history,
        dispatch,
        selector,
        location,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const currentUserRoles = selector(selectCurrentUserRoles());
    const organizationId = selector(selectOrganizationId());
    const clientProfile = selector(selectClientProfile());
    const [ redirect, setRedirect ] = useState(null);

    const {
        getAsset,
    } = useCustomAssets('ClientCards');

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('ClientDetails', getClientLabelVariableData(clientProfile));

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        const pageState = location.state || {};
        const clientId = pageState.clientId;
        const redirect = pageState.redirect;

        dispatch(setToolbarButtons([]));

        if (!clientId) {
            dispatch(setClientProfile(false));
            errorLoading();
            return;
        }

        const db = firebase.firestore();

        db.collection('Users').doc(clientId).get().then((docRef) => {
            if (docRef.exists) {
                const clientProfile = docRef.data();
                clientProfile.id = docRef.id;
                clientProfile.name = `${clientProfile.firstName} ${clientProfile.lastName}`;
                clientProfile.sms = formatPhone(clientProfile.sms);
                
                dispatch(setClientProfile(clientProfile));
                finishedLoading();
                
                // If a redirect to another page is requested after the client data loads, then remove redirect from history
                if (redirect) {
                    history.replace(NAV_CLIENT_DETAILS, Object.assign({}, pageState, { redirect: null }));
                    setRedirect(redirect);
                }
            } else {
                dispatch(setClientProfile(false));
                errorLoading();
            }
        }).catch((err) => {
            console.log(err);
            dispatch(setClientProfile(false));
            errorLoading();
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientId || '',
            page: 'Client Details',
            action: 'view client details',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
    }, []);

    // Effect for automatic redirecting to a client detail page
    useEffect(() => {
        if (!clientProfile || !redirect) {
            return;
        }

        const accessibleCards = getAccessibleClientViewCards(currentUserRoles, getLabel, getAsset);

        if (accessibleCards.find(x => x.to === redirect)) {
            history.push(redirect);
        }
    }, [ clientProfile, redirect ]);

    const buildCardSection = (card) => {
        return (
            <Cell key={`client-detail-card-${card.to}`} size={4}>
                <Fade duration={500}>
                    <ManagementCardFromUpload {...card} onClick={() => history.push(card.to)} />
                </Fade>
            </Cell>
        );
    }

    function buildPageContent() {
        const accessibleCards = getAccessibleClientViewCards(currentUserRoles, getLabel, getAsset);

        if (!accessibleCards || accessibleCards.length === 0) {
            return (<p>You have no access to view client information. Please contact your organization manager for permission to access these pages</p>);
        }

        return (
            <Grid>
                { accessibleCards.map((card) => buildCardSection(card)) }
            </Grid>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default ClientDetails;