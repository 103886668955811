import { FILTER_TEXT, FILTER_DATE, FILTER_NUMBER, DEFAULT_FILTER_TEXT,
    DEFAULT_FILTER_DATE, DEFAULT_FILTER_NUMBER, FILTER_BOOLEAN,
    DEFAULT_FILTER_BOOLEAN, FILTER_ARRAY_TEXT, DEFAULT_FILTER_ARRAY_TEXT,
    FILTER_ARRAY_CONTROLS, DEFAULT_FILTER_ARRAY_CONTROLS } from '../constants';
    import { isNullOrUndefined } from 'util';

const sortDataItems = (dataItems, sortingColumn, ascending) => {
    if (!dataItems || !sortingColumn) {
        return dataItems || [];
    }
    
    const sortFunction = getSortFunction(sortingColumn.type);
    const sortedItems = dataItems.sort((a, b) => sortFunction(a[sortingColumn.value], b[sortingColumn.value]));
    
    if (ascending) {
        sortedItems.reverse();
    }

    return sortedItems;
}

const getSortFunction = (columnType) => {
    switch (columnType) {
        case FILTER_DATE:
            return sortDateFunction;
        case FILTER_BOOLEAN:
            return sortBooleanFunction;
        case FILTER_TEXT:
            return sortTextFunction;
        case FILTER_NUMBER:
            return sortNumberFunction;
        default:
            return sortTextFunction;
    };
}

const sortTextFunction = (a, b) => sortNulls(a,b) || a.toLowerCase().localeCompare(b.toLowerCase());
const sortDateFunction = (a, b) => sortNulls(a,b) || b - a;
const sortNumberFunction = (a, b) => sortNulls(a,b) || b - a;
const sortBooleanFunction = (a, b) => sortNulls(a,b) || Number(b) - Number(a);
const sortNulls = (a, b) => {
    if (isNullOrUndefined(a) || !a) {
        return 1;
    } else if (isNullOrUndefined(b) || !b) {
        return -1;
    }
    return 0;
}

const getEmptyColumnValue = (columnType) => {
    switch (columnType) {
        case FILTER_NUMBER:
            return '0';
        case FILTER_BOOLEAN:
            return 'No';
        default:
            return '-';
    }
}

const getDefaultFilterValue = (filterType) => {
    switch (filterType) {
        case FILTER_DATE:
            return DEFAULT_FILTER_DATE;
        case FILTER_BOOLEAN:
            return DEFAULT_FILTER_BOOLEAN;
        case FILTER_TEXT:
            return DEFAULT_FILTER_TEXT;
        case FILTER_ARRAY_TEXT:
            return DEFAULT_FILTER_ARRAY_TEXT;
        case FILTER_ARRAY_CONTROLS:
            return DEFAULT_FILTER_ARRAY_CONTROLS;
        case FILTER_NUMBER:
            return DEFAULT_FILTER_NUMBER;
        default:
            return DEFAULT_FILTER_TEXT;
    };
}

export {
    sortDataItems,
    sortTextFunction,
    sortDateFunction,
    sortNumberFunction,
    sortBooleanFunction,
    getEmptyColumnValue,
    getDefaultFilterValue,
}
