import React from 'react';
import { Grid, CardTitle, CardText, Cell } from 'react-md';
import useCustomAssets from '../../hooks/useCustomAssets';
import ThemedButton from '../../components/Buttons/ThemedButton';
import useFormValidation from '../../hooks/useFormValidation';
import PictureFormField from '../../components/FormFields/PictureFormField';

const DEFAULT = 'defaultAssets';
const CURRENT = 'currentAssets';
const NEW = 'newAssets';
const DELETE = 'deleteAssets';

function UpdateAssetsForm(props) {
    const { onSubmit, formId, title, subtitle } = props;

    const {
        getDefaultAsset,
        getTenantAsset,
        getAssetData,
        getAssetKeys,
    } = useCustomAssets(formId);

    const assetData = getAssetData();
    const assetKeys = getAssetKeys();

    const INITIAL_STATE = {
        assetKey: formId,
        [DEFAULT]: {},
        [CURRENT]: {},
        [NEW]: {},
        [DELETE]: {},
    };
    assetKeys.forEach((key) => {
        INITIAL_STATE[CURRENT][key] = getTenantAsset(key);
        INITIAL_STATE[DEFAULT][key] = getDefaultAsset(key);
    });
    
    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, saveAssets);

    function saveAssets(values) {
        const assets = parseSubmissionToAssets(values);
        onSubmit(assets);
    }

    function parseSubmissionToAssets(values) {
        const assets = {
            assetKey: values.assetKey,
            valueKeys: assetKeys,
            [CURRENT]: values[CURRENT],
            [NEW]: values[NEW],
            [DELETE]: values[DELETE],
        };
        return assets;
    }

    const handleAssetChange = (assetKey, file) => {
        const newAssets = Object.assign({}, values[NEW], { [assetKey]: file });
        const deleteAssets = Object.assign({}, values[DELETE]);
        delete deleteAssets[assetKey];
        
        changeValues({ [NEW]: newAssets, [DELETE]: deleteAssets });
    }
    
    const setToDefault = () => {
        changeValues({ [NEW]: {}, [DELETE]: values[CURRENT] });
    }

    const resetAssets = () => {
        changeValues({ [NEW]: {}, [DELETE]: {} });
    }

    const buildPictureFormField = (assetKey) => {
        return (
            <Cell size={6}>
                <PictureFormField
                    name={assetKey}
                    label={assetKey}
                    values={values[NEW]}
                    errors={errors}
                    onChange={handleAssetChange}
                    showPreview={true}
                    currentPicture={values[DELETE][assetKey] ? values[DEFAULT][assetKey] : values[CURRENT][assetKey] || values[DEFAULT][assetKey]}
                    onBlur={handleBlur}
                />
            </Cell>
        );
    }

    return (
        <form id={formId} onSubmit={handleSubmit}>
            <CardTitle title={title} subtitle={subtitle}>
                <ThemedButton className="md-cell--right" flat primary onClick={setToDefault}>Set Default</ThemedButton>
                <ThemedButton className="md-cell--right" flat primary onClick={resetAssets}>Reset</ThemedButton>
                <ThemedButton className="md-cell--right" flat primary swapTheming type="submit" disabled={isSubmitting}>Save</ThemedButton>
            </CardTitle>
            <CardText>
                <Grid>
                    { assetKeys.map(buildPictureFormField) }
                </Grid>
            </CardText>
        </form>
    );
}

export default UpdateAssetsForm;