import React, { useState, useEffect } from 'react';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import { Button } from 'react-md';
import Bounce from 'react-reveal/Bounce';
import useMediaLoader from '../../hooks/useMediaLoader';

function PictureFormField(props) {
    const { name, values, label, errors, placeholder, onChange, showPreview = false, currentPicture } = props;

    const inputId = `picture-field-${name}`;
    const [ newPictureURL, setNewPictureURL ] = useState(null);
    const [ pictureURL, setPictureURL ] = useState(currentPicture);

    const {
        imageSrc,
    } = useMediaLoader({ id: currentPicture });

    useEffect(() => {
        const newPicture = values[name];

        if (newPicture) {
            var reader = new FileReader();
            reader.onload = function(e) {
                setNewPictureURL(e.target.result);
            }

            reader.readAsDataURL(newPicture);
        } else {
            setNewPictureURL(null);
        }
    }, [ values, currentPicture ]);

    useEffect(() => {
        setPictureURL(newPictureURL || imageSrc);
    }, [ newPictureURL, imageSrc ]);

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const openFileInput = () => {
        const inputElement = document.getElementById(inputId);
        inputElement.click();
    }
    
    const onFileSubmit = (evt, a, b) => {
        const inputElement = document.getElementById(inputId);
        const file = inputElement.files[0];
        onChange(name, file);
    }

    return (
        <div style={{display: 'grid'}}>
            <p><b>{ label }</b></p>
            <b>{ placeholder }</b>
            { showPreview && <img src={pictureURL} alt='' height={'250px'} width={'100%'}/> }
            <Button raised onClick={openFileInput}>{ getDataLabel('imageData') }</Button>
            <p>{ `${values[name] && values[name].name || ''}` }</p>
            <input type='file' id={inputId} style={{ display: 'none' }} onChange={onFileSubmit} accept={'image/*'} multiple={false}/>
            <Bounce when={!!errors[name]}><p className="error-text">{errors[name] || '...'}</p></Bounce>
        </div>
    );
}

export default PictureFormField;