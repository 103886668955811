import { postalCode, zipCode } from "../components/RequestFields/renderToolboxField";
import { SCHEDULE_CATEGORY_PARENTING_PLAN, SCHEDULE_CATEGORY_APPOINTMENT, SCHEDULE_CATEGORY_HOLIDAY, SCHEDULE_CATEGORY_EVENT } from "./schedule";

/* eslint-disable linebreak-style */
function sortByName(itemA, itemB) {
    return itemA.name.toLowerCase().localeCompare(itemB.name.toLowerCase());
}

const DATE_FILTER_TYPES = [
    { id: 'Today', days: 0 },
    { id: 'Yesterday', days: 1 },
    { id: 'Last 2 Days', days: 1 },
    { id: 'Last 7 Days', days: 7 },
    { id: 'Last 2 Weeks', days: 14 },
    { id: 'Last 30 Days', days: 30 },
    { id: 'Last 90 Days', days: 90 },
    { id: 'All', days: -1 },
];

const BOOLEAN_FILTER_TYPES = [
    { id: 'Yes', value: true },
    { id: 'No', value: false },
    { id: 'All', value: null },
];

const STATES = [
    { id: 'AL', name: 'Alabama' },
    { id: 'AK', name: 'Alaska' },
    { id: 'AZ', name: 'Arizona' },
    { id: 'AR', name: 'Arkansas' },
    { id: 'CA', name: 'California' },
    { id: 'CO', name: 'Colorado' },
    { id: 'CT', name: 'Connecticut' },
    { id: 'DE', name: 'Delaware' },
    { id: 'DC', name: 'District Of Columbia' },
    { id: 'FL', name: 'Florida' },
    { id: 'GA', name: 'Georgia' },
    { id: 'HI', name: 'Hawaii' },
    { id: 'ID', name: 'Idaho' },
    { id: 'IL', name: 'Illinois' },
    { id: 'IN', name: 'Indiana' },
    { id: 'IA', name: 'Iowa' },
    { id: 'KS', name: 'Kansas' },
    { id: 'KY', name: 'Kentucky' },
    { id: 'LA', name: 'Louisiana' },
    { id: 'ME', name: 'Maine' },
    { id: 'MD', name: 'Maryland' },
    { id: 'MA', name: 'Massachusetts' },
    { id: 'MI', name: 'Michigan' },
    { id: 'MN', name: 'Minnesota' },
    { id: 'MS', name: 'Mississippi' },
    { id: 'MO', name: 'Missouri' },
    { id: 'MT', name: 'Montana' },
    { id: 'NE', name: 'Nebraska' },
    { id: 'NV', name: 'Nevada' },
    { id: 'NH', name: 'New Hampshire' },
    { id: 'NJ', name: 'New Jersey' },
    { id: 'NM', name: 'New Mexico' },
    { id: 'NY', name: 'New York' },
    { id: 'NC', name: 'North Carolina' },
    { id: 'ND', name: 'North Dakota' },
    { id: 'OH', name: 'Ohio' },
    { id: 'OK', name: 'Oklahoma' },
    { id: 'OR', name: 'Oregon' },
    { id: 'PA', name: 'Pennsylvania' },
    { id: 'PR', name: 'Puerto Rico' },
    { id: 'RI', name: 'Rhode Island' },
    { id: 'SC', name: 'South Carolina' },
    { id: 'SD', name: 'South Dakota' },
    { id: 'TN', name: 'Tennessee' },
    { id: 'TX', name: 'Texas' },
    { id: 'UT', name: 'Utah' },
    { id: 'VT', name: 'Vermont' },
    { id: 'VA', name: 'Virginia' },
    { id: 'WA', name: 'Washington' },
    { id: 'WV', name: 'West Virginia' },
    { id: 'WI', name: 'Wisconsin' },
    { id: 'WY', name: 'Wyoming' }
];

const PROVINCES = [
    { id: 'AB', name: 'Alberta' },
    { id: 'BC', name: 'British Columbia' },
    { id: 'MB', name: 'Manitoba' },
    { id: 'NB', name: 'New Brunswick' },
    { id: 'NL', name: 'Newfoundland and Labrador' },
    { id: 'NS', name: 'Nova Scotia' },
    { id: 'NT', name: 'Northwest Territories' },
    { id: 'NU', name: 'Nunavut' },
    { id: 'ON', name: 'Ontario' },
    { id: 'PE', name: 'Prince Edward Island' },
    { id: 'QC', name: 'Québec' },
    { id: 'SK', name: 'Saskatchewan' },
    { id: 'YT', name: 'Yukon' }
];

const COUNTRIES = [
    { id: 'CAN', name: 'Canada', divisions: PROVINCES, code: postalCode },
    { id: 'USA', name: 'United States', divisions: STATES, code: zipCode }
];

const CLOSE_TYPES = [
    {
        id: 'Resolved - Full Agreement',
        name: 'Resolved - Full Agreement',
    }, {
        id: 'Resolved - Partial Agreement (resolved some issues but not other issues)',
        name: 'Resolved - Partial Agreement (resolved some issues but not other issues)',
    }, {
        id: 'Unresolved',
        name: 'Unresolved',
    }, {
        id: 'Closed - Violation of our ToS',
        name: 'Closed - Violation of our ToS',
    }, {
        id: 'Closed - No progress',
        name: 'Closed - No progress',
    }, {
        id: 'Closed - Issue is Moot',
        name: 'Closed - Issue is Moot',
    }, {
        id: 'Closed - Referral to outside resources',
        name: 'Closed - Referral to outside resources',
    }, {
        id: 'Closed - Referral to outside intervention',
        name: 'Closed - Referral to outside intervention',
    }, {
        id: 'Closed - Not Responsive',
        name: 'Closed - Not Responsive',
    }, {
        id: 'Closed - Other',
        name: 'Closed - Other',
    }
];

const PARENTING_PLANS = [
    { id: 1, plan: '50/50 - Alternating Weeks', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2'],
        desc: 'The alternating weeks schedule is a 50/50 residential schedule. Your child spends 1 week with one parent and the next week with the other parent. This repeats throughout the year.' },
    { id: 2, plan: '50/50 - Two (2) Weeks Each', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2'],
        desc: 'The 2 weeks each schedule is a 50/50 residential schedule. Your child spends 2 weeks with one parent and then 2 weeks with the other parent and this repeats.' },
    { id: 3, plan: '50/50 - Every Two (2) Days', pattern: ['p1PU', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p2PU', 'p2'],
        desc: 'The alternating every 2 days schedule is a 50/50 residential schedule that has the child alternate living with each parent every 2 days. It is also known as 2 days on, 2 days off.' },
    { id: 4, plan: '50/50 - 3/4/4/3', pattern: ['p1PU', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p2', 'p1PU', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2'],
        desc: 'The 3-4-4-3 schedule is a 50/50 residential schedule that has your child stay with one parent for 3 days of the week and then 4 days with the other parent. The next week it switches so the first parent has the child for 4 days and the other parent 3.' },
    { id: 5, plan: '50/50 - 2/2/5/5', pattern: ['p1PU', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p2', 'p2'],
        desc: 'In the 2-2-5-5 residential schedule, your child lives 2 days with one parent, 2 days with the other parent, 5 days with the first parent, and 5 days with the second parent.' },
    { id: 6, plan: '50/50 - 2/2/3', pattern: ['p1PU', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p2PU', 'p2', 'p2'],
        desc: 'In the 2-2-3 residential schedule, your child lives with one parent for 2 days of the week, the next 2 days with the other parent, and then 3 days with the first parent. The next week it switches.' },
    { id: 7, plan: '60/40 - Every Extended Weekend', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p1PU', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2'],
        desc: 'The every extended weekend residential schedule has your child spend weekdays with one parent a long weekend with the other parent.' },
    { id: 8, plan: '60/40 - 4-3', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p1PU', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2'],
        desc: 'The 4-3 schedule is a residential schedule where your child spends four days of the week with one parent and the other three days with the other parent.' },
    { id: 9, plan: '70/30 - Every Weekend', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2'],
        desc: 'In the every weekend residential schedule your child spends weekdays with one parent and the weekend with the other parent.' },
    { id: 10, plan: '70/30 - Every 3rd Week', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2'],
        desc: 'The every 3rd week residential schedule has the child living 2 weeks with one parent and then 1 week with the other parent.' },
    { id: 11, plan: '70/30 - 5-2', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2'],
        desc: 'The 5-2 residential schedule has your child living 5 days with one parent and 2 days with the other parent.' },
    { id: 12, plan: '80/20 - Alternating Weekends', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2'],
        desc: 'The alternating weekends residential schedule has your child living with one parent and visiting the other parent every other weekend.' },
    { id: 13, plan: '80/20 - 1st, 3rd, 5th Weekends', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2'],
        desc: 'custom schedule' },
    { id: 14, plan: '80/20 - 2st, 4rd, 5th Weekends', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p1PU', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p1PU', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2'],
        desc: 'The 2nd, 4th and 5th weekends schedule has your child live with one parent and visit the other parent on the 2nd, 4th and 5th weekends of the month.' },
    { id: 15, plan: '80/20 - every 3rd Weekend', pattern: ['p1PU', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2'],
        desc: 'In the every 3rd weekend schedule the child lives with one parent and visits the other parent every 3rd weekend.' },
    { id: 16, plan: '80/20 - Alternating Weekends End on Sunday', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2'],
        desc: 'The alternating weekends residential schedule has your child living with one parent and visiting the other parent every other weekend.' },
    { id: 17, plan: '80/20 - Alternating Saturdays', pattern: ['p2PU', 'p1PU', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p1PU', 'p1', 'p1', 'p1', 'p1', 'p1'],
        desc: 'The alternating weekends residential schedule has your child living with one parent and visiting the other parent every other weekend.' },
    { id: 18, plan: '80/20 - Alternating Weekends with Sunday Pickup', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2'],
        desc: 'The alternating weekends residential schedule has your child living with one parent and visiting the other parent every other weekend.' }, 
    { id: 19, plan: 'Modified Shared Week', pattern: ['p1PU', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p2'],
        desc: '' },
    { id: 20, plan: 'Weekends only', pattern: ['p1PU', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2'],
        desc: '' }, 
    { id: 21, plan: 'Alternating Saturdays', pattern: ['p2PU', 'p1PU', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p1PU', 'p1', 'p1', 'p1', 'p1', 'p1'],
        desc: 'The alternating weekends residential schedule has your child living with one parent and visiting the other parent every other weekend.' },
    { id: 22, plan: 'Every other Week', pattern: ['p1PU', 'p1', 'p2PU', 'p2', 'p1PU', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p2', 'p2'],
        desc: 'The alternating weeks residential schedule has your child living with one parent.' },
    { id: 23, plan: 'Every week custom', pattern: ['p1', 'p1', 'p2MU', 'p1', 'p2MU', 'p1', 'p1'],
        desc: 'Visitation of coParent every Tuesday and Thursday from after school until setup time' },
    { id: 24, plan: 'Weekly, Thurs visit, Friday over night', pattern: ['p1', 'p1', 'p1', 'p1', 'p2MU', 'p2PU', 'p1PU'],
        desc: '' },
    { id: 25, plan: '2 Week Special Schedule', pattern: ['p1', 'p1', 'p1', 'p1', 'p1', 'p2PU', 'p2', 'p2', 'p2', 'p2', 'p2', 'p2', 'p1PU', 'p1'],
        desc: '' },
    { id: 26, plan: '2 Week Alternating Days', pattern: ['p1PU', 'p2PU', 'p1PU', 'p2PU', 'p1PU', 'p2PU', 'p1PU', 'p2PU', 'p1PU', 'p2PU', 'p1PU', 'p2PU', 'p1PU', 'p2PU'],
        desc: '' },
    { id: 27, plan: '1 Week Customizable Schedule', pattern: ['p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1'],
        desc: '' },
    { id: 28, plan: '2 Week Customizable Schedule', pattern: ['p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1'],
        desc: '' },
    { id: 29, plan: '3 Week Customizable Schedule', pattern: ['p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1', 'p1'],
        desc: '' },
];

const SCHEDULE_TYPES = [
    {id: 0, name: 'Default'},
    {id: 1, name: 'Springbreak'},
    {id: 2, name: 'Winterbreak'},
    {id: 3, name: 'Summerbreak'},
];

const DAYS_ABBREV = {
    0: 'SUN',
    1: 'MON',
    2: 'TUE',
    3: 'WED',
    4: 'THU',
    5: 'FRI',
    6: 'SAT',
};

const AGREEMENT_STYLES = {
    blue: 'agreement-blue-interaction-wrapper',
    green: 'agreement-green-interaction-wrapper',
    red: 'agreement-red-interaction-wrapper',
};

const TIMEZONES = [
    { id: 'Hawaii', name: '(UTC-10:00) Hawaii' },
    { id: 'Alaska', name: '(UTC-09:00) Alaska' },
    { id: 'Pacific', name: '(UTC-08:00) Pacific' },
    { id: 'Mountain Standard', name: '(UTC-07:00) Mountain Standard' },
    { id: 'Mountain', name: '(UTC-07:00) Mountain' },
    { id: 'Central Standard', name: '(UTC-06:00) Central Standard' },
    { id: 'Central', name: '(UTC-06:00) Central' },
    { id: 'Eastern', name: '(UTC-05:00) Eastern' },
    { id: 'Atlantic', name: '(UTC-04:00) Atlantic' },
    { id: 'Newfoundland', name: '(UTC-03:30) Newfoundland' },
    { id: 'UTC', name: '(UTC-00:00) UTC' },
];

// In the future, refine create/update event and holiday type schedules
const SCHEDULE_CATEGORIES = [
    { id: SCHEDULE_CATEGORY_PARENTING_PLAN, label: 'Parenting Plan' },
    // { id: SCHEDULE_CATEGORY_EVENT, label: 'Event' },
    // { id: SCHEDULE_CATEGORY_HOLIDAY, label: 'Holiday' },
];

const HOLIDAYS = [
    { index: 0, label: "New Year's Day", value: "New Year's Day" },
    { index: 1, label: "Valentines", value: "Valentine;s Day" },
    { index: 2, label: "Mother's Day", value: "Mother's Day" },
    { index: 3, label: "Easter", value: "Easter Sunday" },
    { index: 4, label: "Memorial Day", value: "Memorial Day" },
    { index: 5, label: "Columbus Day", value: "Columbus Day" },
    { index: 6, label: "Father's Day", value: "Father's Day" },
    { index: 7, label: "Canada Day", value: "Canada Day" },
    { index: 8, label: "4th of July", value: "Independence Day" },
    { index: 9, label: "Labor Day", value: "Labor Day" },
    { index: 10, label: "Thanksgiving Day", value: "Thanksgiving Day" },
    { index: 11, label: "Christmas Eve", value: "Christmas Eve" },
    { index: 12, label: "Christmas Day", value: "Christmas Day" },
    { index: 13, label: "New Year's Eve", value: "New Year's Eve "}
];

const HOLIDAY_ROTATIONS = [
    { label: 'Every Year', value: 'every' },
    { label: 'Even Years', value: 'even' },
    { label: 'Odd Years', value: 'odd' },
];

const HOLIDAY_STARTS_BEFORE = [
    { label: 'Day of Holiday', value: 0 },
    { label: '1 Day Before', value: 1 },
    { label: '2 Days Before', value: 2 },
    { label: '3 Days Before', value: 3 },
];

const HOLIDAY_ENDS_ON = [
    { label: 'Day of Holiday', value: 0 },
    { label: '1 Day After', value: 1 },
    { label: '2 Days After', value: 2 },
    { label: '3 Days After', value: 3 },
];

const ALL_DIVISIONS = PROVINCES.concat(STATES);

COUNTRIES.sort(sortByName);
PROVINCES.sort(sortByName);
STATES.sort(sortByName);
ALL_DIVISIONS.sort(sortByName);

export {
    ALL_DIVISIONS,
    COUNTRIES,
    PROVINCES,
    STATES,
    CLOSE_TYPES,
    PARENTING_PLANS,
    DATE_FILTER_TYPES,
    BOOLEAN_FILTER_TYPES,
    SCHEDULE_TYPES,
    DAYS_ABBREV,
    AGREEMENT_STYLES,
    TIMEZONES,
    SCHEDULE_CATEGORIES,
    HOLIDAYS,
    HOLIDAY_ROTATIONS,
    HOLIDAY_STARTS_BEFORE,
    HOLIDAY_ENDS_ON,
};
