import usePageFramework from "./usePageFramework";
import { selectTenantAssetsFromKey, selectDefaultAssetsFromKey } from "../selectors/global";
import { getValueByKey, getValueFromItems } from "../utils/customBranding";
import { sortTextFunction } from "../utils/sortFilter";

function useCustomAssets(key) {
    const {
        selector,
    } = usePageFramework();

    const tenantAssets = selector(selectTenantAssetsFromKey(key));
    const defaultAssets = selector(selectDefaultAssetsFromKey(key));


    const getDefaultAsset = (assetName) => getValueFromItems(defaultAssets, assetName);
    const getTenantAsset = (assetName) => getValueFromItems(tenantAssets, assetName);
    const getAssetData = () => defaultAssets || [];
    const getAsset = (assetName) => getValueByKey(tenantAssets, defaultAssets, assetName);
    const getAssetKeys = () => getAssetData().map(x => x.key).sort(sortTextFunction) || [];

    return {
        getDefaultAsset,
        getTenantAsset,
        getAssetData,
        getAssetKeys,
        getAsset,
    };
}

export default useCustomAssets;