// Table Render Type
export const TAB_CONTENT_CARD = 'card';
export const TAB_CONTENT_TABLE = 'table';

// Status States
export const INBOUND = 'Inbound';
export const ACCEPT = 'accept';
export const DECLINE = 'decline'
export const OPEN = 'Open';
export const NEW = 'New';
export const CLOSED = 'Closed';
export const DECLINED = 'Declined';
export const ALL = 'All';

export const ACCEPTED = 'Accepted';
export const REJECTED = 'Rejected';

export const INACTIVE = 'Inactive';
export const ACTIVE = 'Active';
export const INVITED = 'Invited';

export const INITIAL = 'Initial';
export const NO_RESULTS = 'NoResults';
export const ERROR = 'Error';

export const REQUEST_GENERAL = 'requestGeneral';
export const REQUEST_ACTIVITY = 'requestActivity';
export const REQUEST_SWAP = 'requestSwap';
export const REQUEST_EXPENSES = 'expenses';

// Filter
export const FILTER_TEXT = 'Text';
export const FILTER_DATE = 'Date';
export const FILTER_NUMBER = 'Number';
export const FILTER_BOOLEAN = 'Boolean';
export const FILTER_NONE = 'None';
export const FILTER_ARRAY_TEXT = 'ArrayText';
export const FILTER_ARRAY_CONTROLS = 'ArrayControl';
export const DEFAULT_FILTER_TEXT = '';
export const DEFAULT_FILTER_ARRAY_TEXT = '';
export const DEFAULT_FILTER_ARRAY_CONTROLS = '';
export const DEFAULT_FILTER_DATE = 'All';
export const DEFAULT_FILTER_NUMBER = '';
export const DEFAULT_FILTER_BOOLEAN = 'All';

// Messaging
export const SYSTEM = 'System';

export const INTERACTION = '$INTERACTION$';
export const URL = '$URL$';
export const IMAGE = '$IMAGE$';
export const PDF = '$PDF$';
export const VIDEO_CONFERENCE = '$VIDEO_CONFERENCE$';
export const AUDIO_CONFERENCE = '$AUDIO_CONFERENCE$';

export const INTERACTION_TYPE_PARENTING_PLAN = 'ParentingPlan';
export const INTERACTION_TYPE_AGREEMENT = 'Agreement';

export const CREATE_AGREEMENT = 'Create Agreement';
export const CLOSE_MESSAGE = 'Close Message';
export const PARENTING_PLAN_SCHEDULE = 'Parenting Plan Schedule';
export const ADD_LINK = 'Add Link';
export const ADD_FILE = 'Add Image(s) or PDF(s)';
export const INITIATE_APP_REVIEW = 'Initiate App Review';
export const INITIATE_VIDEO = 'Intiate Video Call';
export const INVITE_GUEST = 'Invite Guest';

export const CHAT_GETHELP = 'getHelp';
export const CHAT_ORGANIZATION = 'OrganizationChat';
export const CHAT_DIRECT_COMMUNICATION = 'direct';

// Paths
export const PATH_CARDS = '/cards/';
export const PATH_ICONS = '/icons/';
export const SIDE_DRESSING = '/sidedressing/'

// External website links
export const IDEAS_SUBMISSION = 'https://drive.google.com/open?id=1Pb83D5lERLnJf5hNJmv1FtVhNgls6Y3S6vPckYsJhro';
export const TERMS_OF_SERVICE = 'https://coparenter.com/coparenter-protool-terms-of-service/';
export const PRIVACY_POLICY = 'https://coparenter.com/privacy-policy-2/';

// user menu
export const USER_MENU_LOGOUT = 'Logout';
export const USER_MENU_PROFILE = 'Profile';
export const USER_MENU_SETTINGS = 'Settings';

// Default date format
export const DISPLAY_DATE_FORMAT = 'MMM Do YYYY h:mm:ss a';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'h:mm A';