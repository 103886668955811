import React from 'react';
import { TimePicker } from 'react-md';
import Bounce from 'react-reveal/Bounce';

function TimeFormField(props) {
    const { name, values, errors, onChange, ...formProps } = props;

    const onTimeChanged = (dateString, dateObject, event) => {
        onChange(name, dateObject);
    }

    return (
        <TimePicker
            id={name}
            value={values[name]}
            onChange={onTimeChanged}
            fullWidth
            error={!!errors[name]}
            errorText={<Bounce when={!!errors[name]}>{errors[name] || '...'}</Bounce>}
            {...formProps}
        />
    );
}

export default TimeFormField;