import React, { PureComponent } from 'react';
import { DialogContainer } from "react-md";
import MessageTranscriptForm from './MessageTranscriptForm';

export default class MessageTranscriptDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.handleSubmit = this.onHandleSubmit.bind(this);
    }

    parseTranscriptFields = (values) => {
        const transcriptValues = {};

        transcriptValues['timezoneValue'] = values.get('timezoneValue');
        return transcriptValues;
    }

    onHandleSubmit = (e) => {
        const { onConfirm } = this.props;
        const transcriptFields = this.parseTranscriptFields(e);

        onConfirm(transcriptFields);
    }

    render() {
        const { visible, onHide } = this.props;

        return (
            <DialogContainer
                id="message-transcript-dialog"
                visible={visible}
                onHide={onHide}
                title="Get Transcript"
                dialogStyle={{minHeight:'500px', minWidth:'400px'}}
            >
                <MessageTranscriptForm
                    { ...this.props }
                    onSubmit={this.handleSubmit}
                />
            </DialogContainer>
        );
    };
}