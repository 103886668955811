import React, { useEffect } from 'react';
import { Grid, Cell } from 'react-md';
import { selectOrganizationId, selectCurrentUser } from '../../selectors/global';
import { setToolbarTitle, setToolbarButtons } from '../../actions/global';
import { NAV_SYSTEM_MSG, NAV_VIEW_ORGS, NAV_DEFAULT, NAV_ORG_TYPES,
    NAV_VIEW_COPARENTER_USERS, NAV_DEFAULT_BRANDING } from '../../constants/navigation';
import ManagementCard from '../../components/ManagementCard/';
import Fade from 'react-reveal/Fade';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { callCloudRunFunction } from '../../utils/firestore';
import { orgIsCoParenter } from '../../utils/permissions';

function SuperAdmin() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const organizationId = selector(selectOrganizationId());
    const currentUser = selector(selectCurrentUser());

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });
    
    const superAdminCards = [
        { cardImage: 'card_Organization.png', cardTitle: 'Organizations', cardSubtitle: 'Manage all organizations', onClick: () =>  history.push(NAV_VIEW_ORGS) },
        { cardImage: 'card_Organization.png', cardTitle: 'Organization Types', cardSubtitle: 'Define a preset organization type', disabled: !orgIsCoParenter(organizationId), onClick: () =>  history.push(NAV_ORG_TYPES) },
        { cardImage: 'card_systemMessage.png', cardTitle: 'System Message', cardSubtitle: 'A system message for everyone', disabled: !orgIsCoParenter(organizationId), onClick: () =>  history.push(NAV_SYSTEM_MSG) },
        { cardImage: 'card_userDocuments.png', cardTitle: 'User Search', cardSubtitle: 'Search for a user', disabled: !orgIsCoParenter(organizationId), onClick: () =>  history.push(NAV_VIEW_COPARENTER_USERS) },
        { cardImage: 'card_userDocuments.png', cardTitle: 'Default Labels and Theme', cardSubtitle: 'Update default labels and theme', disabled: !orgIsCoParenter(organizationId), onClick: () =>  history.push(NAV_DEFAULT_BRANDING) },
    ];

    useEffect(() => {
        if (organizationId === false || currentUser === null) {
            return;
        }

        const toolBarTitle = 'Super Admin';
        dispatch(setToolbarTitle(toolBarTitle));
        dispatch(setToolbarButtons([]));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Super Admin',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        finishedLoading();
        return () => {
            dispatch(setToolbarTitle(''));
            dispatch(setToolbarButtons([]));
        }
    }, []);

    if (organizationId === false || currentUser === null) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    function buildPageContent() {
        return (
            <Grid id="super-admin-grid">
                {
                    superAdminCards.map((card, index) => {
                        return (
                            <Cell key={`super-admin-card-${index}`} size={4}>
                                <Fade duration={500}>
                                    <ManagementCard {...card}/>
                                </Fade>
                            </Cell>
                        );
                    })
                }
            </Grid>
        )
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default SuperAdmin;