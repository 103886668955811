import React, { useEffect } from 'react';
import { Grid, Cell } from 'react-md';
import { selectCurrentUser, selectOrganizationId, selectCurrentUserRoles } from '../../selectors/global';
import { setToolbarButtons } from '../../actions/global';
import { NAV_DEFAULT } from '../../constants/navigation';
import { ManagementCardFromUpload } from '../../components/ManagementCard/';
import Fade from 'react-reveal/Fade';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { callCloudRunFunction } from '../../utils/firestore';
import { useCustomPageFrameworkLabels } from '../../hooks/useCustomLabels';
import useCustomAssets from '../../hooks/useCustomAssets';
import { getAccessibleManagementCards } from '../../utils/permissions';

// Also referenced as organization management page
function Organization() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const userRoles = selector(selectCurrentUserRoles());
    const organizationId = selector(selectOrganizationId());

    const {
        getAsset,
    } = useCustomAssets('OrganizationCards');

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('Organization');

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (organizationId === false || currentUser === null) {
            return;
        }
        const toolBarButtons = [];
        dispatch(setToolbarButtons(toolBarButtons));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization',
            action: 'visit page',
        };
        
        callCloudRunFunction(currentUser, 'logProUserAction', log);
        finishedLoading();
        
        return () => {
            dispatch(setToolbarButtons([]));
        }
    }, []);

    if (organizationId === false || currentUser === null) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    const buildCardSection = (card) => {
        return (
            <Cell key={`client-detail-card-${card.to}`} size={4}>
                <Fade duration={500}>
                    <ManagementCardFromUpload {...card} onClick={() => history.push(card.to)} />
                </Fade>
            </Cell>
        );
    }

    function buildPageContent() {
        const accessibleCards = getAccessibleManagementCards(userRoles, getLabel, getAsset);

        if (!accessibleCards || accessibleCards.length === 0) {
            return (<p>You have no access to view management information. Please contact your organization manager for permission to access these pages</p>);
        }

        return (
            <Grid>
                { accessibleCards.map((card) => buildCardSection(card)) }
            </Grid>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default Organization;