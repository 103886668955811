import React, { Component } from 'react';
import { Avatar,  ListItem } from 'react-md';

class ChildListItem extends Component {

    clickedItem = () => {
        this.props.itemClicked(this.props.item.id);
    } 

    render() {

        const { index, item } = this.props;
        
        return (
            <ListItem key={`${index}-${item.id}`}
                id={`${index}-${item.id}`}
                leftAvatar={<Avatar suffix={index % 2 ? 'green' : 'orange'}>{item.firstName.substring(0, 1)}</Avatar>}
                primaryText={`${item.firstName} - ${item.age} years old`}
                secondaryText={item.birthDay}
                onClick={this.clickedItem}
                threeLines
            />
        );
    }
}

export default ChildListItem;