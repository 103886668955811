import React, { useState } from 'react';
import useFormValidation from '../../../hooks/useFormValidation';
import SelectionFormField from '../../FormFields/SelectionFormField';
import { PARENTING_PLANS, SCHEDULE_TYPES } from '../../../constants/lookupInfo';
import CheckboxFormField from '../../FormFields/CheckboxFormField';
import { Cell, Button, Grid, FontIcon } from 'react-md';
import ThemedButton from '../../Buttons/ThemedButton';
import DateFormField from '../../FormFields/DateFormField';
import TextFormField from '../../FormFields/TextFormField';
import { SchedulePattern, isVisit, isExchange, isCP1, isCP2, flattenExchangeTimes } from '../../ParentingPlan/SchedulePattern';
import { DEFAULT_DROP_PICKUP_TIME, PARENT_TWO_DAY, PARENT_ONE_DAY } from '../../../constants/schedule';
import ScheduleLegend from '../../ParentingPlan/ScheduleLegend';
import ModifyScheduleDialog from '../../ParentingPlan/ModifyScheduleDialog';
import { validate } from './validate';

function ParentingPlanForm(props) {
    const { onSubmit, availableChildren, children, pattern, exchangeTimes, p1Name, p1Id, availableCoParents } = props;

    const [ showModifyDialog, setShowModifyDialog ] = useState(false);
    const [ modifyIndex, setModifyIndex ] = useState(null);

    const INITIAL_STATE = {
        title: '',
        planTemplate: '',
        children: children || '',
        pattern: pattern || null,
        exchangeTimes: exchangeTimes || null,
        scheduleType: SCHEDULE_TYPES[0].name,
        description: '',
        p1Name: p1Name,
        p1Id: p1Id,
        p2Id: availableCoParents[0].value,
    };

    const parseParentingPlan = (values) => {
        const parentingPlan = {
            pattern: values.pattern,
            exchangeTimes: flattenExchangeTimes(values.exchangeTimes),
            children: values.children.split(','),
            startDate: values.startDate,
            endDate: values.endDate,
            scheduleType: values.scheduleType,
            title: values.title,
            description: values.description,
            p1Name: values.p1Name,
            p1Id: values.p1Id,
            p2Name: availableCoParents.find(x => x.value === values.p2Id).label,
            p2Id: values.p2Id,
        };

        return parentingPlan;
    }

    const submitParentingPlan = (values) => {
        const parentingPlan = parseParentingPlan(values);
        onSubmit(parentingPlan);
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        changeValues,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, submitParentingPlan, validate);
    
    const showPlan = values.pattern && values.exchangeTimes ? true : false;
    const p2Item = availableCoParents.find(x => x.value === values.p2Id);

    let modifyScheduleDialogData;

    if (showModifyDialog) {
        modifyScheduleDialogData = {
            index: modifyIndex,
            p1Name: values.p1Name,
            p2Name: p2Item.label,
            pattern: values.pattern,
            exchangeTimes: values.exchangeTimes,
            defaultTime: DEFAULT_DROP_PICKUP_TIME,
        };
    }

    const handlePlanTemplateChange = (name, value) => {
        const planTemplate = PARENTING_PLANS.find((plan) => plan.id === value);

        const { plan, pattern, desc } = planTemplate;

        const exchangeTimes = pattern.map((item) => {
            if (isVisit(item)) {
                return `${DEFAULT_DROP_PICKUP_TIME},${DEFAULT_DROP_PICKUP_TIME}`;
            } else if (isExchange(item)) {
                return `${DEFAULT_DROP_PICKUP_TIME}`;
            } else {
                return null;
            }
        });

        const defaultTemplateValues = {
            title: plan,
            pattern: pattern,
            exchangeTimes: exchangeTimes,
            description: desc,
        };
        
        changeValues(defaultTemplateValues);
    }

    const onSwapCoParent = () => {
        const { pattern } = values;

        if (!pattern) return;
        
        const swappedPattern = pattern.map((item) => {
            if (isCP1(item)) {
                return `${PARENT_TWO_DAY}${item.slice(2)}`;
            } else if (isCP2(item)) {
                return `${PARENT_ONE_DAY}${item.slice(2)}`;
            } else {
                return item;
            }
        });

        const swappedValues = {
            pattern: swappedPattern,
        };

        changeValues(swappedValues);
    }

    const onShiftLeft = () => {
        const { pattern, exchangeTimes } = values;

        if (!pattern || !exchangeTimes) return;

        const shiftedPattern = pattern.slice();
        const shiftedExchangeTimes = exchangeTimes.slice();
        const item = shiftedPattern.shift();
        const exchangeItem = shiftedExchangeTimes.shift();
        
        shiftedPattern.push(item);
        shiftedExchangeTimes.push(exchangeItem);

        const shiftedValues = {
            pattern: shiftedPattern,
            exchangeTimes: shiftedExchangeTimes,
        };

        changeValues(shiftedValues);
    }

    const onShiftRight = () => {
        const { pattern, exchangeTimes } = values;

        if (!pattern || !exchangeTimes) return;

        const shiftedPattern = pattern.slice();
        const shiftedExchangeTimes = exchangeTimes.slice();
        const item = shiftedPattern.pop();
        const exchangeItem = shiftedExchangeTimes.pop();

        shiftedPattern.unshift(item);
        shiftedExchangeTimes.unshift(exchangeItem);

        const shiftedValues = {
            pattern: shiftedPattern,
            exchangeTimes: shiftedExchangeTimes,
        };

        changeValues(shiftedValues);
    }

    const onPatternTapped = (index) => {
        setShowModifyDialog(true);
        setModifyIndex(index);
    }

    const hideModifyScheduleDialog = () => {
        setShowModifyDialog(false);
        setModifyIndex(null);
    }

    const onConfirmModifyScheduleDialog = (newPattern, newExchangeTimes) => {
        const modifiedValues = {
            pattern: newPattern,
            exchangeTimes: newExchangeTimes,
        };

        changeValues(modifiedValues);
        hideModifyScheduleDialog();
    }

    const buildTitle = () => {
        return (
            <TextFormField
                name='title'
                label="Parenting Plan Title"
                values={values}
                errors={errors}
                rows={2}
                maxRows={4}
                required
                onChange={handleChange}
                onBlur={handleBlur}
            />
        );
    }

    const buildParent2Selection = () => {
        return (
            <SelectionFormField
                name="p2Id"
                label="Share Parenting Plan"
                values={values}
                errors={errors}
                menuItems={availableCoParents}
                itemLabel="label"
                itemValue="value"
                required
                onChange={handleChange}
                onBlur={handleBlur}
            />
        );
    }

    const buildCoParents = () => {
        return (
            <ul id="pattern-interface-coparents" className="flex-container-cal schedule-legend-row">
                <ScheduleLegend id="coparent-one-name" text={p1Name} icon="lens" iconClassName="legend-p1"/>
                <Button id="swap-button" flat onClick={onSwapCoParent}>
                    <FontIcon id="swap=icon">
                        swap_horiz
                    </FontIcon>
                </Button>
                <ScheduleLegend id="coparent-two-name" text={p2Item.label} icon="lens" iconClassName="legend-p2"/>
            </ul>
        );
    }

    const buildShiftButtons = () => {
        return (
            <Grid id="pattern-interface-shift">
                <Cell desktopSize={3} tabletSize={2} phoneSize={1}>
                    <Button id="left-shift-button" className="btn-fill-width" flat iconChildren="chevron_left" onClick={onShiftLeft}>Shift Left</Button>
                </Cell>
                <Cell desktopSize={3} tabletSize={2} phoneSize={1} desktopOffset={6} tabletOffset={4} phoneOffset={2}>
                    <Button id="right-shift-button" className="btn-fill-width" flat iconChildren="chevron_right" iconBefore={false} onClick={onShiftRight}>Shift Right</Button>
                </Cell>
            </Grid>
        );
    }

    const buildPatternDisplay = () => {
        const { pattern, exchangeTimes } = values;

        return (
            <SchedulePattern id="pattern-interface-display" pattern={pattern} exchangeTimes={exchangeTimes} onTapBubble={onPatternTapped} />
        );
    }

    const buildDescription = () => {
        return (
            <TextFormField
                name="description"
                label="Description"
                values={values}
                errors={errors}
                rows={4}
                maxRows={6}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        );
    }

    const buildPatternInterface = () => {
        return (
            <div id="pattern-interface">
                { buildTitle() }
                { buildParent2Selection() }
                { buildCoParents() }
                { buildShiftButtons() }
                { buildPatternDisplay() }
                { buildDescription() }
            </div>
        );
    }

    const buildScheduleTemplateActions = () => {
        return (
            <form onSubmit={handleSubmit} className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <SelectionFormField
                        name="planTemplate"
                        label="Parenting Plan Templates"
                        values={values}
                        errors={errors}
                        menuItems={PARENTING_PLANS}
                        itemLabel="plan"
                        itemValue="id"
                        required={values['pattern'] && values['exchangeTimes'] ? false : true}
                        onChange={handlePlanTemplateChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <CheckboxFormField
                        name="children"
                        label="Children"
                        values={values}
                        errors={errors}
                        required
                        controls={availableChildren}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <SelectionFormField
                        name="scheduleType"
                        label="Schedule Type"
                        values={values}
                        errors={errors}
                        menuItems={SCHEDULE_TYPES}
                        itemLabel="name"
                        itemValue="name"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <DateFormField
                        name="startDate"
                        label="Start Plan On"
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <DateFormField
                        name="endDate"
                        label="End Plan On"
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>Submit</ThemedButton>
                </Cell>
            </form>
        );
    }

    return (
        <>
            { showPlan && buildPatternInterface() }
            { buildScheduleTemplateActions() }
            <ModifyScheduleDialog onHide={hideModifyScheduleDialog} onConfirm={onConfirmModifyScheduleDialog} visible={showModifyDialog} {...modifyScheduleDialogData}/>
        </>
    );
}

export default ParentingPlanForm;