import React from 'react';
import ParticipantInfo from '../ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks';

export default function Participant(props) {
    const { participant, disableAudio, enableScreenShare, onClick, isSelected } = props;

    return (
        <ParticipantInfo participant={participant} onClick={onClick} isSelected={isSelected}>
            <ParticipantTracks participant={participant} disableAudio={disableAudio} enableScreenShare={enableScreenShare} />
        </ParticipantInfo>
    );
}
