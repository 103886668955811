function validate(values) {
    let errors = {};

    Object.keys(values).forEach((key) => {
        try {
            JSON.parse(values[key]);
        } catch (error) {
            console.log(error);
            errors[key] = 'Value must be in JSON format';
        }
    })

    return errors;
}

export {
    validate,
};