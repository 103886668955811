import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../selectors/global';
import useFormValidation from '../../hooks/useFormValidation';
import { Grid, Cell, Button } from 'react-md';
import CheckboxFormField from '../../components/FormFields/CheckboxFormField';
import { validate } from './CreateCommunicationValidate';
import ThemedButton from '../../components/Buttons/ThemedButton';

function CreateCommunicationForm (props) {
    const { onSubmit, onHide, professionals, getLabel } = props;

    const INITIAL_STATE = {
        professionalsIncluded: '',
    };

    const currentUser = useSelector(selectCurrentUser());
    const selectableProfessionals = professionals.filter(x => x.uid !== currentUser.uid).map(x => { return { value: x.uid, label: x.displayName } });
    
    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, createNewChat, validate);

    function createNewChat(values) {
        const chat = parseSubmissionToChat(values);
        onSubmit(chat);
    }

    function parseSubmissionToChat(values) {
        const selectedProfessionals = values.professionalsIncluded ? values.professionalsIncluded.split(',') : [];
        const chat = {
            participants: [ currentUser.uid, ...selectedProfessionals ],
        };

        return chat;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <CheckboxFormField
                        name="professionalsIncluded"
                        label={getLabel('createBlurb')}
                        values={values}
                        errors={errors}
                        controls={selectableProfessionals}
                        showSelectAllButton={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>{ getLabel('createChatButton') }</ThemedButton>
                </Cell>
            </Grid>
        </form>
    );
}

export default CreateCommunicationForm;