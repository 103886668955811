import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganizationId, selectCurrentUser, selectDataItemsFromComponent } from '../../selectors/global';
import { setDataItemsToComponent, setUniqueChannelName, setToolbarButtons } from '../../actions/global';
import { NAV_DEFAULT, NAV_MSGING } from '../../constants/navigation';
import CreateCommunicationForm from './CreateCommunicationForm';
import useDialogFormBuilder from '../../hooks/useDialogFormBuilder';
import moment from 'moment';
import ProTable from '../../components/ProTable';
import { ALL, FILTER_TEXT, CHAT_ORGANIZATION, ACCEPTED, FILTER_DATE, FILTER_NUMBER } from '../../constants';
import { NOTIFICATION_INFO, NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from '../../constants/notifications';
import TableButton from '../../components/Buttons/TableButton';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import { callCloudRunFunction } from '../../utils/firestore';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import useCustomAssets from '../../hooks/useCustomAssets';
import useNotifications from '../../hooks/useNotifications';
import ThemedBadge from '../../components/ThemedBadge';

const COMPONENT_NAME = 'OrganizationChats';

function InternalCommunications() {
    const history = useHistory();
    const dispatch = useDispatch();

    const organizationId = useSelector(selectOrganizationId());
    const currentUser = useSelector(selectCurrentUser());
    const internalChats = useSelector(selectDataItemsFromComponent(COMPONENT_NAME));

    const [ professionals, setProfessionals ] = useState([]);

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        hasMatchingNotification,
    } = useNotifications();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('InternalCommunication');

    useEffect(() => {
        dispatch(setToolbarButtons([]));
        
        const db = firebase.firestore();
        let professionalsStream = null;
        let internalChatsStream = null;

        if (organizationId === false) {
            return;
        }

        professionalsStream = db.collection('ProApprovedProfessionals').where('organizationId', '==', String(organizationId)).where('status', '==', ACCEPTED).where('isActive', '==', true).onSnapshot((querySnapshot) => {
            const professionals = querySnapshot.docs.map((doc) => {
                const professional = doc.data();
                professional.uid = doc.id;
                professional.createdOn = professional.createdOn ? professional.createdOn.toDate() : null;

                return professional;
            });

            setProfessionals(professionals);
        });

        internalChatsStream = db.collection('chat').where('category', '==', 'OrganizationChat').where('organizationId', '==', organizationId).where('participants', 'array-contains', currentUser.uid).onSnapshot((snapshot) => {
            const chats = snapshot.docs.map((doc) => {
                // Create other important data, unread messages, last message time, members of chat
                const chatData = doc.data();

                const currentUserMember = chatData.members.find(x => x.id === currentUser.uid);
                const lastSentFromMember = chatData.members.find(x => x.id === chatData.lastMessageAuthor);
                const lastUserReadIndex = currentUserMember ? currentUserMember.msgReadIndex : 0 || 0;
                
                // Generate name of chat from User's perspective
                chatData.headerTitle = chatData.members.filter(x => x.id !== currentUser.uid).map(x => x.friendlyName).join(', ');
                chatData.id = doc.id;
                chatData.createdOn = chatData.createdOn ? chatData.createdOn.toDate() : null;
                chatData.updatedOn = chatData.updatedOn ? chatData.updatedOn.toDate() : null;

                //TODO: Need message last sent as well.
                chatData.userUnreadMessages = chatData.messageCount - lastUserReadIndex;
                chatData.msgLastSentFrom = !lastSentFromMember ? 'Unknown' : `${lastSentFromMember.friendlyName}`;
                return chatData;
            });

            dispatch(setDataItemsToComponent(chats, COMPONENT_NAME));
            finishedLoading();
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Internal Communication',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);

        return function cleanup() {
            // stop stream of chats
            if (professionalsStream) {
                professionalsStream();
            }

            if (internalChatsStream) {
                internalChatsStream();
            }
        }
    }, []);

    const userDoesNotMatchOrgId = currentUser.organizationId !== organizationId;

    const createOrgChatDialog = {
        FormComponent: CreateCommunicationForm,
        onSubmit: createCommunication,
        dialogData: { title: getLabel('createTitleBar') },
        formData: { professionals: professionals, getLabel: getLabel },
    };

    const {
        showDialog: showCreateDialog,
        renderDialogForm: renderCreateDialogForm
    } = useDialogFormBuilder(createOrgChatDialog);


    if (organizationId === false || currentUser == null) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    function createCommunication(chatData) {
        const chatMetadata = {
            organizationId: organizationId,
            title: 'Internal Communication'
        };

        const chat = Object.assign({}, chatData, chatMetadata);
        showToastNotification(`Creating new chat or checking for existing one`, NOTIFICATION_INFO);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Internal Communication',
            action: 'create new communication',
            metadata: {...chat},
        };

        callCloudRunFunction(currentUser, 'createInternalChat', chat).then((result) => {
            const { chatId, existing } = result;
            const promises = [];

            log.chatId = result.chatId;
            log.metadata.id = result.chatId;
            promises.push(chatId, existing, callCloudRunFunction(currentUser, 'logProUserAction', log));

            if (existing) {
                showToastNotification(`Redirecting you to an existing internal chat`, NOTIFICATION_SUCCESS);
            } else {
                showToastNotification(`Sucessfully created an internal chat`, NOTIFICATION_SUCCESS);
            }
            
            return Promise.all(promises);
        }).then(([chatId, existing]) => {
            openChat(chatId, existing || false);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    const openChat = (chatId, existing) => {
        const foundChat = internalChats.find(x => x.id === chatId);

        // If the chat was just created, redirect to the chat right away, otherwise determine if the chat is an archived chat

        dispatch(setUniqueChannelName({ id: chatId, chatId, category: CHAT_ORGANIZATION }));

        if (existing) {
            if (!foundChat) {
                showToastNotification('Error opening this chat. Please try again later.', NOTIFICATION_ERROR);
                return;
            }
    
            // Find other professional users and map then to their isActive account state.
            const participantsAreActive = mapParticipantActivity(foundChat.participants);
    
            const allParticipantsInactive = participantsAreActive.includes(false) && !participantsAreActive.includes(true);
            const someParticipantsInactive = participantsAreActive.includes(false) && participantsAreActive.includes(true);
    
            if (allParticipantsInactive) {
                showToastNotification('The other member(s) of this chat are inactive, you are viewing an archived chat', NOTIFICATION_INFO);
                dispatch(setUniqueChannelName({ id: chatId, chatId, category: CHAT_ORGANIZATION, isArchived: true }));
            } else if (someParticipantsInactive) {
                showToastNotification('Some member(s) of this chat are inactive', NOTIFICATION_INFO);
            }
        }

        // Load chat session
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Internal Communication',
            action: 'enter communication',
            chatId: chatId,
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        history.push(NAV_MSGING);
    }

    const mapParticipantActivity = (participants) => {
        return participants.filter(participant => participant !== currentUser.uid)
            .map(participant => professionals.find(professional => professional.uid === participant))
            .map((professional) => {
            if (!professional) {
                return false;
            }

            return professional.isActive || false;
        });
    }

    const buildEmptyPage = () => {
        const cardProps = { status: ALL, cardMediaUrl: getAsset('internalCommunicationEmpty'), headerTitle: getLabel('emptyResultsHeader'), subTitle: getLabel('emptyResultsSubheader'), buttonText: getLabel('createChatButton'), buttonClick: showCreateDialog };

        return (<GettingStartedPaper {...cardProps} key={cardProps.status} />);
    }
    
    function buildPageContent() {
        const buttons = [{ label: getLabel('createChatButton'), icon: 'add', click: showCreateDialog }];
        
        const columns = [
            { label: getDataLabel('memberData'), value: 'headerTitle', type: FILTER_TEXT, fn: (item, id) => (<TableButton id={`internal-${id}`} label={item} onClick={(chatId) => openChat(chatId, true)}/>) },
            { label: getDataLabel('unreadData'), value: 'userUnreadMessages', type: FILTER_NUMBER, isPrimaryColumn: true,  fn: (item, id) => <ThemedBadge badgeContent={hasMatchingNotification(id) ? item : 0}>{item}</ThemedBadge> },
            { label: getDataLabel('lastMemberMsg'), value: 'msgLastSentFrom', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('lastDateData'), value: 'updatedOn', type: FILTER_DATE, fn: (item) => moment(item).fromNow() }
        ];

        return (
            <section className="md-grid">
                <ProTable
                    status={ALL}
                    items={internalChats}
                    columns={columns}
                    buttons={buttons}
                    key={ALL}
                    componentName={COMPONENT_NAME}
                    emptyCard={() => buildEmptyPage()}
                />
            </section>
        );
    }

    return userDoesNotMatchOrgId
        ? (<p>You are not allowed to view this page</p>)
        : <>
            { renderPageContent() }
            { renderCreateDialogForm() }
        </>
}

export default InternalCommunications;