import React from 'react';
import { FontIcon } from 'react-md';

function DefaultErrorFramework() {
    const centerStyle = { height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'};
    const alignmentStyle = { display:'grid' };
    const iconStyle = { fontSize: '48px', color: '#ff0202' };

    return(
        <div style={centerStyle}>
            <div style={alignmentStyle}>
                <FontIcon style={iconStyle}>error</FontIcon>
                <p>Looks like something has gone wrong. Please try again later</p>
            </div>
        </div>
    );
}

export default DefaultErrorFramework;