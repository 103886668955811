import React from 'react';
import { getPrimaryCPName, getSecondaryCPName } from './';
import { isVisit, isExchange, isCP1, isCP2 } from '../ParentingPlan/SchedulePattern';
import { convert24To12Hour } from '../../utils/dateTime';

const buildExchangeTimes = (item) => {
    if (isVisit(item.pattern)) {
        return `Visit child between ${convert24To12Hour(item.exchangeTime[0])} and ${convert24To12Hour(item.exchangeTime[1])}`;
    } else if (isExchange(item.pattern)) {
        return `Exchange child at ${convert24To12Hour(item.exchangeTime[0])}`;
    } else {
        return null;
    }
}

const buildDetails = (item) => {
    if (isVisit(item.pattern)) {
        return buildVisitDetails(item);
    } else if (isExchange(item.pattern)) {
        return buildExchangeDetails(item);
    } else {
        return buildDayDetails(item);
    }
}

const buildVisitDetails = (item) => {
    return `${isCP1(item.pattern) ? getPrimaryCPName(item) : getSecondaryCPName(item)} will visit the child`;
}

const buildExchangeDetails = (item) => {
    return `${isCP2(item.pattern) ? getPrimaryCPName(item) : getSecondaryCPName(item)} will exchange the child to ${isCP1(item.pattern) ? getPrimaryCPName(item) : getSecondaryCPName(item)}`;
}

const buildDayDetails = (item) => {
    return `${isCP1(item.pattern) ? getPrimaryCPName(item) : getSecondaryCPName(item)} has the child`;
}

const ParentingScheduleCard = ({item}) => {
    return (
        <div id="parenting-plan-card-contents">
            <p>{ item.pattern && (isCP1(item.pattern) || isCP2(item.pattern)) ? buildDetails(item) : null }</p>
            <p>{ buildExchangeTimes(item) }</p>
        </div>
    );
}

export default ParentingScheduleCard;