import React from 'react';

function MediationAssignmentConsent(props) {
    const { professionals } = props;

    return (
        <>
            <p>
                We will find active mediations to re-assign from the following Mediators:
            </p>
            {
                professionals.map(pro => <p>{pro.displayName}</p>)
            }
        </>
    );
}

export default MediationAssignmentConsent;