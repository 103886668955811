import React from 'react';
import { DAYS_ABBREV } from '../../constants/lookupInfo';
import { buildDayBubble, buildVisitBubble } from './ScheduleBubble';
import { PARENT_ONE_DAY, PARENT_TWO_DAY, PARENT_PICKUP, PARENT_VISIT, NUM_DAYS_PER_WEEK } from '../../constants/schedule';
import { convert24To12Hour } from '../../utils/dateTime';

const bufferExchangeTimes = (pattern=[], exchangeTimes=[]) => {
    let tempExchangeTimes = exchangeTimes.slice().reverse();

    return pattern.map((item, index) => {
        if (isVisit(item) || isExchange(item)) {
            return tempExchangeTimes.pop();
        }
        return null;
    });
}

const flattenExchangeTimes = (exchangeTimes=[]) => {
    return exchangeTimes.filter((item) => {
        return item !== null;
    });
}

const flipPattern = (string) => {
    return string ? isCP1(string) ? string.replace(PARENT_ONE_DAY, PARENT_TWO_DAY) : string.replace(PARENT_TWO_DAY, PARENT_ONE_DAY) : string;
}

const isVisit = (string) => {
    if (string && string.endsWith(PARENT_VISIT)) {
        return true;
    }
    return false;
}

const isExchange = (string) => {
    if (string && string.endsWith(PARENT_PICKUP)) {
        return true;
    }
    return false;
}

const isCP1 = (string) => {
    if (string && string.startsWith(PARENT_ONE_DAY)) {
        return true;
    }
    return false;
}

const isCP2 = (string) => {
    if (string && string.startsWith(PARENT_TWO_DAY)) {
        return true;
    }
    return false;
}

const buildInnerBubbleText = (dayAbbrev, timeString) => {
    return (
        <div className="schedule-bubble-text">
            <div>{dayAbbrev}</div>
            <div>{timeString}</div>
        </div>
    );
}

const SchedulePattern = ({pattern=[], exchangeTimes=[], onTapBubble}) => {
    const numWeeks = pattern.length / NUM_DAYS_PER_WEEK;
    const schedule = [];
    const onClick = onTapBubble ? onTapBubble : () => {};

    const bubbles = pattern.map((item, index) => {
        const itemClass = `schedule-bubble-${item}`;
        const bubbleClass = `${itemClass} ${onTapBubble ? `${itemClass}-hover` : ''}`;
        const dayAbbrev = DAYS_ABBREV[index % NUM_DAYS_PER_WEEK];

        if (isVisit(item)) {
            const split = exchangeTimes[index].split(',');
            const exchangeTimeString = `${convert24To12Hour(split[0])} to ${convert24To12Hour(split[1])}`;
            const bubbleText = buildInnerBubbleText(dayAbbrev, exchangeTimeString);
            return buildVisitBubble(bubbleClass, bubbleText, () => {onClick(index)});
        } else {
            const exchangeTimeString = `${convert24To12Hour(exchangeTimes[index])}`;
            const bubbleText = buildInnerBubbleText(dayAbbrev, exchangeTimeString);
            return buildDayBubble(bubbleClass, bubbleText, () => {onClick(index)});
        }
    });

    for (var i = 0; i < numWeeks; i++) {
        schedule.push(
            <ul className="flex-container-cal" key={`pattern-row-${i}`}>
                {bubbles.slice(i * NUM_DAYS_PER_WEEK, (i + 1) * NUM_DAYS_PER_WEEK).map((bubble, index) => {
                    return (
                        <li key={`pattern-bubble-${index}`}>
                            {bubble}
                        </li>
                    );
                })}
            </ul>
        );
    }

    return (
        <div id="schedule-pattern">
            {schedule}
        </div>
    );
}

export {
    SchedulePattern,
    buildInnerBubbleText,
    isVisit,
    isExchange,
    isCP1,
    isCP2,
    bufferExchangeTimes,
    flattenExchangeTimes,
    flipPattern,
};