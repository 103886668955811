import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter} from "react-router-dom";
import { createStructuredSelector } from 'reselect';
import * as firebase from 'firebase';
import _ from 'underscore';
import moment from 'moment';
import { Button, FontIcon, MenuButton } from 'react-md/lib';
import { selectCurrentUser, selectUniqueChannelName, selectOrganizationId, selectDataItemsFromComponent, selectNotifications } from '../../selectors/global.js';
import { setToolbarButtons, setUniqueChannelName, setVideo, setToolbarTitle } from '../../actions/global.js';
import AgreementDialog from './AgreementDialog';
import CloseDialog from './CloseDialog';
import ParentingPlanDialog from './ParentingPlanDialog';
import InteractionCard from '../../components/InteractionCards';
import ConferenceCard from '../../components/ConferenceCard';
import { INTERACTION, URL, IMAGE, PDF, INTERACTION_TYPE_PARENTING_PLAN, VIDEO_CONFERENCE, AUDIO_CONFERENCE,
    INTERACTION_TYPE_AGREEMENT, SYSTEM, CREATE_AGREEMENT, CLOSE_MESSAGE,
    PARENTING_PLAN_SCHEDULE, ADD_LINK, ADD_FILE, INITIATE_APP_REVIEW, INITIATE_VIDEO, INVITE_GUEST, PATH_ICONS,
    CHAT_GETHELP, CHAT_ORGANIZATION, CHAT_DIRECT_COMMUNICATION, CLOSED } from '../../constants';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants/notifications';
import { NAV_MEDIATION, NAV_DEFAULT, NAV_VIDEO } from '../../constants/navigation';
import MessageDashboard from '../../components/MessageDashboard/';
import HyperlinkDialog from './HyperlinkDialog.js';
import FileUploadDialog from './FileUploadDialog.js';
import ImageViewDialog from './ImageViewDialog.js';
import ConfirmActionDialog from './ConfirmActionDialog.js';
import InviteGuestDialog from './InviteGuestDialog.js';
import { emptyFunction, openInNewWindow } from '../../utils';
import { getInitials, getFirstAndLastName, getMessageBodyFromFile } from '../../utils/strings';
import { callCloudRunFunction } from '../../utils/firestore';
import { isNullOrUndefined } from 'util';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { Cell, Grid } from 'react-md';

const uuidv4 = require('uuid/v4');

const INITIAL_STATE = {
    chatData: null,
    messages: null,
    members: [],
    loadedChildren: null,
    scrollDownOnNewMessage: true,
    scrolledOnStart: false,
    hasLoadedChat: false,
    currentMessageTimestamp: moment('DD-MM-YYYY hh:mm A'),
    dialogAgreementVisible: false,
    dialogCloseVisible: false,
    dialogParentingPlanVisible: false,
    dialogHyperlinkVisible: false,
    dialogFileUploadVisible: false,
    dialogImageViewVisible: false,
    imageViewURL: '',
    dialogConfirmActionVisible: false,
    confirmActionTitle: '',
    confirmActionText: '',
    dialogInviteGuestVisible: false,
    confirmActionOnConfirm: emptyFunction,
    drawerVisible: false,
    messageToModify: null,
    messageToModifyIndex: 0,
    isToken: false,
    isArchived: false,
}

class Messages extends PureComponent { // eslint-disable-line react/prefer-stateless-function
    
    constructor(props) {
        console.log('messaging....');
        
        super(props);
        
        this.onSendMessage = this.sendMessage.bind(this);
        this.sendMessageClick = this.sendMessageClick.bind(this);
        this.menuClicked = this.onMenuClick.bind(this);
        this.isMessageLoaded = false;
        this.goingBack = false;
        this.channelHistory = [];

        this.state = INITIAL_STATE;
    }

    componentDidMount() {
        const { channel } = this.props;
        
        if (!channel || !channel.id) {
            return this.props.history.push(NAV_DEFAULT);
        }

        const chatContainer = document.getElementById('chat-messages');

        const observe = (element, event, handler) => {
            element.addEventListener(event, handler, false);
        };

        function scrollChat() {
           // const pos = chatContainer.scrollTop;
        }

        observe(chatContainer, 'scroll', scrollChat);

        chatContainer.scrollTop = chatContainer.scrollHeight;
        
        const toolBarButtons = [
            { icon: 'arrow_back', onClick: this.onGoBackClicked },
        ];

        this.props.onSetToolbarButton(toolBarButtons);

        this.loadData();
        this.onPageVisit();
    }

    componentDidUpdate(prevProps, prevState) {
        const { channel: prevChannel } = prevProps;
        const { channel, userProfile, onSetToolbarTitle, onSetToolbarButton, notifications } = this.props
        const { hasLoadedChat, isArchived } = this.state;
        
        const { messages: nextMessages, members: nextMembers, chatData: nextChatData } = this.state;
        const { messages: prevMessages, members: prevMembers, chatData: prevChatData, chatDocStream, messagesStream } = prevState;

        if (!hasLoadedChat) {
            return;
        }
        const chatId = channel.id || channel.chatId;
        const prevChatId = prevChannel.id || prevChannel.chatId;

        this.setState({ isArchived: isArchived || channel.isArchived || false });

        // Cancel and reload chat if the channel has changed
        // This allows changing chat channels while inside a messaging page
        if (this.channelHasChanged(prevChatId, chatId)) {
            // Track if going forward to a channel or back to an old channel
            if (this.goingBack) {
                this.goingBack = false;
            } else {
                this.channelHistory.push(prevChannel);
            }

            if (chatDocStream) {
                chatDocStream();
            }
            if (messagesStream) {
                messagesStream();
            }

            // Reset state and reload with new channel id
            this.isMessageLoaded = false;
            this.state = INITIAL_STATE;
            this.loadData();
            return;
        }

        if (notifications) {
            notifications.filter(x => x.responseId === chatId).forEach((toDo) => {
                if (toDo.toDoId) {
                    callCloudRunFunction(userProfile, 'removeToDoById', toDo);
                }
            });
        }
        
        // If the messages have changed and were not from the user, update read index
        if (this.messagesHaveChanged(prevMessages, nextMessages)) {
            const body = {
                collection: 'chat',
                chatUid: chatId,
                currentMemberUid: userProfile.uid
            };

            callCloudRunFunction(userProfile, 'updateChatMsgReadIndex', body).catch((err) => {
                console.log(err);
            });
        }

        // If the members have changed, reload children
        if (nextChatData.category === CHAT_GETHELP && this.membersHaveChanged(prevMembers, nextMembers)) {
            const cp1 = nextMembers.find((x) => x.isPrimary);
            const cp2 = nextMembers.find((x) => !x.isProfessional && x.id !== cp1.id);

            const cpIds = {
                cp1Uid: cp1 ? cp1.id : null,
                cp2Uid: cp2 && cp2.friendlyName !== 'coParent Guest' ? cp2.id : null,
            };

            callCloudRunFunction(userProfile, 'getChildrenForPros', cpIds).then((childrenData) => {
                if (childrenData) {
                    const loadedChildren = _.keys(childrenData).map((id) => {
                        return {
                            id: id,
                            childName: `${childrenData[id].firstName} ${childrenData[id].lastName}`.trim(),
                        };
                    });

                    this.setState({ loadedChildren: loadedChildren });
                }
            }).catch((err) => {
                console.log(err);
            });
        }
        
        if (this.categoryHasChanged(prevChatData || {}, nextChatData)) {
            var chatMembers = [];

            // Grab chat members to display in title
            if (nextChatData.category === CHAT_ORGANIZATION) {
                chatMembers = nextChatData.members.filter(x => x.isProfessional && x.id !== userProfile.uid);
            } else {
                chatMembers = nextChatData.members.filter(x => !x.isProfessional);
            }

            const participatingNames = chatMembers.length !== 0 ? ` - ${chatMembers.map(x => x.friendlyName).join(', ')}` : '';

            onSetToolbarTitle(`${nextChatData.title}${participatingNames}` || 'Messaging');
        }

        if (this.titleHasChanged(prevChatData || {}, nextChatData)) {
            const toolBarButtons = [
                { icon: 'arrow_back', onClick: this.onGoBackClicked },
                { icon: 'menu', onClick: this.onDrawerClick }
            ];
    
            onSetToolbarButton(toolBarButtons);
        }

        this.scrollMessagesDown();
    }
    
    componentWillUnmount() {
        const { chatDocStream, messagesStream } = this.state;

        this.props.onSetToolbarButton([]);
        this.props.onSetUniqueChannelName(false);

        if (chatDocStream) {
            chatDocStream();
        }
        if (messagesStream) {
            messagesStream();
        }

        //TODO: other cleanup
    }
    
    scrollMessagesDown = () => {
        const { scrollDownOnNewMessage, scrolledOnStart } = this.state;
        const chatContainer = document.getElementById('chat-messages');
        
        if (scrollDownOnNewMessage) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        } else if (!scrolledOnStart) {
            this.setState({ scrolledOnStart: true });
            chatContainer.scrollTop = 0;
        }
    }

    channelHasChanged = (prevChatId, chatId) => {
        return prevChatId && prevChatId !== chatId ? true : false;
    }

    messagesHaveChanged = (prevMessages, nextMessages) => {
        const { userProfile } = this.props;

        if (isNullOrUndefined(prevMessages)) {
            return true;
        }

        if (nextMessages.length > 0 && prevMessages.length !== nextMessages.length) {
            return true;
        }

        return false;
    }

    membersHaveChanged = (prevMembers, nextMembers) => {
        if (prevMembers.length !== nextMembers.length) {
            return true;
        }

        return false;
    }

    categoryHasChanged = (prevChatData, nextChatData) => {
        return prevChatData.title !== nextChatData.title;
    }

    titleHasChanged = (prevChatData, nextChatData) => {
        return prevChatData.category !== nextChatData.category && nextChatData.category === CHAT_GETHELP;
    }

    onMenuClick(e, a, b, c) {
        const menuItem = e.target.innerText;

        switch (menuItem) {
            case CREATE_AGREEMENT:
                return this.setState({ dialogAgreementVisible: true });
            case CLOSE_MESSAGE:
                return this.setState({ dialogCloseVisible: true });
            case PARENTING_PLAN_SCHEDULE:
                return this.setState({ dialogParentingPlanVisible: true });
            case ADD_LINK:
                return this.setState({ dialogHyperlinkVisible: true });
            case ADD_FILE:
                return this.setState({ dialogFileUploadVisible: true });
            case INITIATE_APP_REVIEW:
                return this.setState({
                    dialogConfirmActionVisible: true,
                    confirmActionTitle: 'Do you wish to initiate an app review request?',
                    confirmActionText: `This action will send an in-app review pop-up
                        to all users of this mediation session. Only send this pop-up
                        if you are certain that coParent(s) will give a 5 star review!`,
                    confirmActionOnConfirm: () => { this.initiateAppReview(); },
                });
            case INITIATE_VIDEO: 
                const attributes = { status: 'intializing', room: this.props.channel.id };
                return this.submitMessage(VIDEO_CONFERENCE, attributes, true).then((results) => {
                    const messageCount = results.messageCount;
                    const payload = { currentRoom: this.props.channel.id, messageCount };
                    this.props.onSetVideo(payload);
                    this.props.history.push(NAV_VIDEO);
                });
            case INVITE_GUEST:
                return this.setState({ dialogInviteGuestVisible: true });
        }
    };

    onGoBackClicked = () => {
        // Mark as going to a previous channel. Load previous channel from history
        this.goingBack = true;

        if (this.channelHistory.length !== 0) {
            this.props.onSetUniqueChannelName(this.channelHistory.pop());
        }

        this.props.history.goBack();
    }

    onDrawerClick = () => {
        const { drawerVisible } = this.state
        this.setState({ drawerVisible: !drawerVisible });
    }

    addAgreement = (agreement) => {
        const { userProfile } = this.props;
        const { messageToModify, messageToModifyIndex, members } = this.state;

        let requiredProfiles = members.filter(x => !x.isProfessional && x.id !== '-1');

         const db = firebase.firestore();

         requiredProfiles.forEach((x) => {
            db.collection('ProOnBoardedCoParents').doc(x.id).get().then((results) => {
                if (results.exists) {
                    const data = results.data();
                    if (data.parseId && data.parseId.length) {
                        x.id = data.parseId;
                    }
                }
            });
         });

         
        const attributes = {
            displayType: 'Agreement',
            title: agreement.agreementType,
            message: agreement.agreement,
            mediatorProfileId: userProfile.uid,
            agreementValue: 'agree',
            responseControl: 'buttons',
            controls: {buttons: [{value: 'agree', text: 'Agree', response: 'Agrees', default: true}, {value: 'disagree', text: 'Disagree', response: 'Disagrees', default: false}]},
            responses: [],
            response: '',
            requiredResponseUserProfile: requiredProfiles
        };

        if (messageToModify) {
            this.onDeleteMessage(messageToModifyIndex, () => showToastNotification('The Agreement has been modified', NOTIFICATION_INFO));
        }

        this.submitMessage(INTERACTION, attributes, true);
        this.hideAgreementDialog();
    }

    addClose = (close) => {
        const { channel } = this.props;

        this.hideCloseDialog();

        const db = firebase.firestore();
        const getHelpRef = db.collection('getHelp').doc(channel.id);
        const closedMessage = `${close.closeType} ${close.closeReason && close.closeReason.trim().length ? ` - ${close.closeReason}` : ''}`;

        if (close.sendAppReview) {
            this.sendAppReviewRequests();
        }

        getHelpRef.update( { status: 'Closed' } ).then(() => {
            this.submitMessage(closedMessage, {status: 'closed'}, true);
            this.props.history.push(NAV_MEDIATION);
        }).catch((error) => {
            console.error('Error updating document: ', error);
        });
    };

    addParentingPlan = (parentingPlan) => {
        const { userProfile } = this.props;
        const { messageToModify, messageToModifyIndex, members, loadedChildren } = this.state;
        const selectedChildren = [];
        const selectedChildrenNames = [];
        
        parentingPlan.children.forEach((childId) => {
            const selectedChild = loadedChildren.find(x => x.id === childId);
            if (selectedChild) {
                selectedChildren.push(selectedChild.id);
                selectedChildrenNames.push(selectedChild.childName);
            }
        });

        const attributes = {
            displayType: 'ParentingPlan',
            agreementType: 'parentingPlanSchedule',
            agreementValue: 'agree',
            responseControl: 'buttons',
            mediatorProfileId: userProfile.uid,
            responses: [],
            response: '',
            controls: {buttons: [{value: 'agree', text: 'Agree', response: 'Agrees', default: true}, {value: 'disagree', text: 'Disagree', response: 'Disagrees', default: false}]},
            requiredResponseUserProfile: members.filter(x => !x.isProfessional && x.id !== '-1'),
            pattern: parentingPlan.pattern,
            exchangeTimes: parentingPlan.exchangeTimes,
            startDay: parentingPlan.startDate || new Date(),
            endDay: parentingPlan.endDate || new Date(),
            scheduleType: parentingPlan.scheduleType,
            title:  parentingPlan.title,
            message: parentingPlan.desc,
            p1Name: parentingPlan.p1Name,
            p2Name: parentingPlan.p2Name,
            children: selectedChildren,
            childrenNames: selectedChildrenNames,
            p1Id: parentingPlan.p1Id,
            p2Id: parentingPlan.p2Id,
        };

        if (messageToModify) {
            this.onDeleteMessage(messageToModifyIndex, () => showToastNotification('The Parenting Plan has been modified', NOTIFICATION_INFO));
        }

        this.submitMessage(INTERACTION, attributes, true);
        this.hideParentingPlanDialog();
    };

    addHyperlink = (hyperlink) => {
        this.setState({ dialogHyperlinkVisible: false });
        const attributes = { url: hyperlink.url };
        this.submitMessage(URL, attributes, true);
    }

    addFiles = (filesToUpload) => {
        const { files } = filesToUpload;
        this.setState({ dialogFileUploadVisible: false });

        if (!files && files.length === 0) {
            return;
        }

        showToastNotification(`Sending ${files.length} files. Please wait...`, NOTIFICATION_INFO);
        Array.from(files).forEach(this.uploadFile);
    };
    
    uploadFile = (file) => {
        const { userProfile } = this.props;
        const message = getMessageBodyFromFile(file);

        if (isNullOrUndefined(message)) {
            return;
        }

        const userId = userProfile.uid;
        const { members } = this.state;
        const bucketPath = `Mediation/Pro/${userId}/${uuidv4()}`;
        const sharedWith = [];

        members.filter(x => userId !== x.id).forEach((member) => {
            sharedWith.push(member.id);
        });
        
        const uploadMap = {
            bucketPath: bucketPath,
            createdBy: userId,
            notes: "Mediation file",
            sharedWith: sharedWith,
            title: file.name,
        };

        const storage = firebase.storage();
        var uploadTask = storage.ref().child(bucketPath).put(file);
        
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            // State Change
            console.log(snapshot.state);
        }, (error) => {
            // Error
            console.log(error);
            showToastNotification(`${file.name} has failed to send. Please try again later`, NOTIFICATION_ERROR);
        }, () => {
            // Complete
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                uploadMap['url'] = downloadURL;

                return callCloudRunFunction(userProfile, 'createUpload', uploadMap).then((doc) => {
                    const attributes = { url: downloadURL };
                    this.submitMessage(message, attributes, true);
                }).catch((err) => {
                    console.log(err);
                });
            }).catch((err) => {
                console.log(err);
            });
        });
    }

    initiateAppReview = () => {
        this.sendAppReviewRequests();
        this.hideConfirmActionDialog();
        showToastNotification(`The in-app review was sent!\n A request to review the app will appear on the coParent(s)' screen.`, NOTIFICATION_SUCCESS);
    }

    sendAppReviewRequests = () => {
        const { channel, userProfile, organizationId } = this.props;
        const { members } = this.state;

        members.filter((x) => !x.isProfessional).forEach((member) => {

            const log = {
                proId: userProfile.uid,
                organizationId: organizationId,
                clientId: member.id,
                page: 'Messaging',
                action: 'send app review',
            };

            callCloudRunFunction(userProfile, 'showAppReview', { userId: member.id });
            callCloudRunFunction(userProfile, 'logProUserAction', log);
        });

        const db = firebase.firestore();
        db.collection('getHelp').doc(channel.id).update({ sentAppReview: true});
    }

    inviteGuest = (invite) => {
        const { channel } = this.props;

        this.hideInviteGuestDialog();
        const updateDoc = {
            guestNumber: invite['sms'],
            updatedOn: new Date(),
        };
        
        const db = firebase.firestore();
        db.collection('getHelp').doc(channel.id).update(updateDoc).then((result) => {
            showToastNotification(`The request to join the session has been sent to the guest!`, NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
        });
    }

    hideAgreementDialog = () => {
        this.setState({
            dialogAgreementVisible: false,
            messageToModify: null,
            messageToModifyIndex: 0,
        });
    };

    hideCloseDialog = () => {
        this.setState({ dialogCloseVisible: false });
    };

    hideParentingPlanDialog = () => {
        this.setState({
            dialogParentingPlanVisible: false,
            messageToModify: null,
            messageToModifyIndex: 0,
        });
    };

    hideHyperlinkDialog = () => {
        this.setState({ dialogHyperlinkVisible: false });
    };

    hideFileDialog = () => {
        this.setState({ dialogFileUploadVisible: false });
    };

    hideImageViewDialog = () => {
        this.setState({ dialogImageViewVisible: false, imageViewURL: '' });
    };

    hideConfirmActionDialog = () => {
        this.setState({
            dialogConfirmActionVisible: false,
            confirmActionTitle: '',
            confirmActionText: '',
            confirmActionOnConfirm: emptyFunction
        });
    };

    hideInviteGuestDialog = () => {
        this.setState({ dialogInviteGuestVisible: false });
    }

    loadData = () => {
        const { channel, userProfile } = this.props;

        if (!channel && !channel.id) return;

        const db = firebase.firestore();
        const chatId = channel.id || channel.chatId;
        const chatRef = db.collection('chat').doc(chatId);
        
        const chatDocStream = chatRef.onSnapshot((doc) => {
            if (doc.data()) {
                const docData = doc.data();
                const { members, participants, status, category } = docData;

                // If the chat is closed and not a mediation, lock the chat
                const isArchivedChat = status === CLOSED && category !== CHAT_GETHELP ? true : false;

                // If user is not part of the chat, add user to chat
                if (members.findIndex(x=> x.id === userProfile.uid) < 0) {
                    members.push({ id: userProfile.uid, friendlyName: 'Mediator', hiddenName: userProfile.displayName, isPrimary: false, isProfessional: true });
                    participants.push(userProfile.uid);
                    doc.ref.update({ members: members, participants: participants, updatedOn: new Date() });
                }

                this.setState({ hasLoadedChat: true, members: members, chatData: docData, isArchived: isArchivedChat });
            }
        });

        const messagesStream = db.collection('chat').doc(chatId).collection('messages').onSnapshot((querySnapshot) => {
            let fireStoreMessages = [];

            querySnapshot.forEach((doc) => {
                const docItems = doc.data();
                fireStoreMessages.push(docItems);
            });

            fireStoreMessages = fireStoreMessages.sort((a, b) => a.index - b.index);
            this.setState({ messages: fireStoreMessages.slice() });
        });

        this.setState({
            chatDocStream: chatDocStream,
            messagesStream: messagesStream,
        });
    }
    onPageVisit = () => {
        const { channel, userProfile, organizationId } = this.props;
        const chatId = channel.id || channel.chatId;

        const log = {
            proId: userProfile.uid,
            organizationId: organizationId,
            page: 'Messaging',
            action: 'visit page',
            chatId: chatId,
        };

        callCloudRunFunction(userProfile, 'logProUserAction', log);
    }

    onDeleteInteractionClick = (messageIndex) => {
        this.setState({
            dialogConfirmActionVisible: true,
            confirmActionTitle: 'Do you wish to cancel this Agreement?',
            confirmActionText: 'This Agreement will be permanently deleted.',
            confirmActionOnConfirm: () => {
                this.onDeleteMessage(messageIndex, () => showToastNotification('The Agreement has been deleted.', NOTIFICATION_INFO));
            },
        });

    }

    onDeleteMessage = (messageIndex, onDeleteSucessFunction) => {
        const { userProfile, channel } = this.props;
        
        const body = {
            chatId: channel.id,
            indexToDelete: messageIndex,
        };

        callCloudRunFunction(userProfile, 'deleteChatMessage', body).then(() => {
            if (onDeleteSucessFunction) {
                onDeleteSucessFunction();
            }
        }).catch((err) => {
            console.log(err);
            showToastNotification('An error has occurred.', NOTIFICATION_ERROR);
        }).finally(() => {
            this.hideConfirmActionDialog();
        });
    };

    onOverrideToAgree = (messageIndex) => {
        this.setState({
            dialogConfirmActionVisible: true,
            confirmActionTitle: 'Do you wish to override all responses to this Agreement?',
            confirmActionText: 'All responses will be overridden to agree to the Agreement.',
            confirmActionOnConfirm: () => { this.onOverrideInteraction(messageIndex, true); },
        });
    }

    onOverrideInteraction = (messageIndex, isAgree) => {
        const { channel, userProfile } = this.props;
        const { messages } = this.state;
        
        const message = messages.find(message => message.index === messageIndex);

        if (message) {
            const attributes = message.attributes;

            const control = attributes.controls[attributes.responseControl].find((control) => {
                return (control.value === attributes.agreementValue) === isAgree;
            });

            const responseList = [];
            const responsesList = [];

            attributes.requiredResponseUserProfile.forEach((userProfile) => {
                const firstLastName = getFirstAndLastName(userProfile.friendlyName);
                responseList.push(`${firstLastName[0]} ${control.response}`);
                responsesList.push({ response: control.value, responseFrom: userProfile.id, overridden: true });
            });

            const msgAttributes = {
                response: responseList.join(' | '),
                responses: responsesList,
            };

            const body = {
                chatId: channel.id,
                index: messageIndex,
                msgAttributes: msgAttributes,
            };

            return callCloudRunFunction(userProfile, 'updateChatAttributes', body).then(() => {
                this.hideConfirmActionDialog();
                showToastNotification('The Agreement has been overridden.', NOTIFICATION_INFO);
            }).catch((err) => {
                console.log(err);
                this.hideConfirmActionDialog();
                showToastNotification('An error has occurred.', NOTIFICATION_ERROR);
            });
        }

        this.hideConfirmActionDialog();
    }

    onResetAgreementClick = (messageIndex) => {
        this.setState({
            dialogConfirmActionVisible: true,
            confirmActionTitle: 'Do you wish to reset this Agreement?',
            confirmActionText: 'coParents will have to resubmit their responses to the agreement.',
            confirmActionOnConfirm: () => { this.onResetAgreement(messageIndex); },
        });
    }

    onResetAgreement = (messageIndex) => {
        const { channel, userProfile } = this.props;
        const { messages } = this.state;
        const message = messages.find(message => message.index === messageIndex);

        if (message) {
            const msgAttributes = {
                response: '',
                responses: [],
            };

            const body = {
                chatId: channel.id,
                index: messageIndex,
                msgAttributes: msgAttributes,
            };

            return callCloudRunFunction(userProfile, 'updateChatAttributes', body).then(() => {
                this.hideConfirmActionDialog();
                showToastNotification('The Agreement has been reset.', NOTIFICATION_INFO);
            }).catch((err) => {
                console.log(err);
                this.hideConfirmActionDialog();
                showToastNotification('An error has occurred.', NOTIFICATION_ERROR);
            });
        }

        this.hideConfirmActionDialog();
    }

    onModifyAgreement = (messageIndex) => {
        const { messages } = this.state;
        const messageToModify = messages.find(message => message.index === messageIndex);

        if (messageToModify && messageToModify.attributes) {
            if (messageToModify.attributes.displayType === INTERACTION_TYPE_AGREEMENT) {
                this.setState({
                    dialogAgreementVisible: true,
                    messageToModify: messageToModify,
                    messageToModifyIndex: messageIndex,
                });
            } else if (messageToModify.attributes.displayType === INTERACTION_TYPE_PARENTING_PLAN) {
                this.setState({
                    dialogParentingPlanVisible: true,
                    messageToModify: messageToModify,
                    messageToModifyIndex: messageIndex,
                });
            }
        } else {
            console.log(`Could not find message at index ${messageIndex} to modify`);
        }
    }

    submitMessage(messageToSend, attributes, skipClearingUserInput) {
        const { channel, userProfile } = this.props;
        const newMessageToSend = !_.isEmpty(messageToSend) ? messageToSend : document.getElementById('chat-input').value;
        const chatId = channel.id || channel.chatId;
        
        const message = {
            chatId,
            body: newMessageToSend,
            msgAttributes: attributes,
            uid: userProfile.uid
        };

        return callCloudRunFunction(userProfile, 'addChatMessage', message).then((messageCount) => {
            if (!skipClearingUserInput) {
                document.getElementById('chat-input').value = '';
            }

            return messageCount;
        }).catch((err) => {
            console.log(err);
            showToastNotification('Message failed to send. Please try again later.', NOTIFICATION_ERROR);
        })
    };

    prepSend(messageToSend) {
        if (!messageToSend || !messageToSend.trim().length) return;

        this.submitMessage(messageToSend);
    }

    sendMessage(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            const messageToSend = e.currentTarget.value;
            this.prepSend(messageToSend);
            e.currentTarget.value = '';
        }
    }

    sendMessageClick() {
        const messageToSend = document.getElementById('chat-input').value;
        this.prepSend(messageToSend);
        document.getElementById('chat-input').value = '';
    }

    renderSystemAndTimeBreak = (timestamp) => {
        const timestampFormat = timestamp ? moment(timestamp).calendar() : '';
        return (
            <div className="member-info text-center">
                <div className="member-time-bold">{timestampFormat}</div>
            </div>
        );
    }

    renderAuthorTitle = (timestamp, index, userType, author) => {
        const initialsIcon = userType === 'current-user' ? null : this.renderNameInitialsIcon(author ? author.friendlyName : '', userType);
        const timestampFormat = timestamp ? moment(timestamp).calendar() : '';
        const displayName =  userType === 'current-user' ?  null : author ? `${author.friendlyName}${author.isProfessional && author.hiddenName ? ` (${author.hiddenName})` : ''}` : '';

        return (
            <div>
                {initialsIcon}
                <div className="member-info">
                    <h3 className={`member-title chat-member-${userType}`} key={index}>
                        {displayName}
                    </h3>
                    <span className="member-time">{timestampFormat}</span>
                </div>
            </div>
        );
    }
    
    renderNameInitialsIcon = (fullName, userType) => {
        let icon = null;
        const initials = getInitials(fullName);

        icon = (<div className={`name-initials-icon name-initials-${userType}`}>{initials}</div>)
        return icon;
    };

    // Rendering for individual messages
    renderMessage = (item, index, allMessages) => {
        const { userProfile } = this.props;
        const { currentMessageTimestamp, members } = this.state;
                
        let messageBody = item.body;
        const messageAttribute = item.attributes || {};

        const author = members.find(member => member.id === item.author);
        const authorIndex = members.findIndex(member => member.id === item.author);
        const isCurrentUser = author && author.id === userProfile.uid;
        const isProfessional = author && author.isProfessional;
        const userType = author ? isCurrentUser ? 'current-user' : isProfessional ? 'professional' : authorIndex == 0 ? 'coparent-primary' : 'coparent-secondary' : 'unknown';

        let bodyStyle = author && author.friendlyName ? `body-text-${userType}` : 'body-top-text';
        const timestamp = item.timestamp.toDate();
        const difference = moment(timestamp).diff(currentMessageTimestamp, 'days');
        
        let memberTitle = (item.author === SYSTEM || difference > 0 )
            ? this.renderSystemAndTimeBreak(timestamp)
            : this.renderAuthorTitle(timestamp, index, userType, author);

        if (index > 0 && allMessages[index - 1].author === item.author && moment(timestamp).diff(moment(allMessages[index - 1].timestamp.toDate()), 'minutes') < 5) {
            memberTitle = '';
        }

        if (messageBody === INTERACTION && messageAttribute.displayType) {
            item.userProfile = userProfile;
            messageBody = <InteractionCard messages={item}
                onDeleteInteraction={() => { this.onDeleteInteractionClick(item.index); }}
                onOverrideToAgree={() => { this.onOverrideToAgree(item.index); }}
                onReset={() => { this.onResetAgreementClick(item.index); }}
                onModify={() => { this.onModifyAgreement(item.index); }}
            />
            bodyStyle = 'body-content';
        }

        if (messageBody === URL && messageAttribute.url) {
            messageBody = <a onClick={ () => openInNewWindow(messageAttribute.url) }>{messageAttribute.url}</a>
        }

        if (messageBody === IMAGE && messageAttribute.url) {
            messageBody = <img className="body-image" src={messageAttribute.url} alt={'Image Not Found'} onClick={() => { this.setState({ dialogImageViewVisible: true, imageViewURL: messageAttribute.url }); }}/>;
        }

        if (messageBody === PDF && messageAttribute.url) {
            messageBody = <img className="body-image" src={`${process.env.PUBLIC_URL}${PATH_ICONS}2019.06.27-DRAFT-V1-ICON-PDF-02.svg`} alt={messageAttribute.title || 'PDF'} onClick={ () => openInNewWindow(messageAttribute.url) }/>;
        }

        if (messageBody === VIDEO_CONFERENCE) {
            messageBody = <ConferenceCard attributes={messageAttribute} cardType='video' id={this.props.channel.id} />
        }

        if (messageBody === AUDIO_CONFERENCE) {
            messageBody =  <ConferenceCard attributes={messageAttribute} cardType='audio' id={this.props.channel.id} />
        }

        this.isMessageLoaded = true;

        return (
            <Grid key={index} className="holiday-box-margin">
                <Cell size={12} style={{margin: '0px'}}>
                    <div className={`row-padding ${isCurrentUser ? 'member-right' : 'member-left'}`}>
                        {memberTitle}
                    </div>
                </Cell>
                <Cell size={12} style={{margin: '0px'}}>
                    <div className={bodyStyle}>
                        {messageBody}
                    </div>
                </Cell>
            </Grid>
        );
    }

    renderMenuItems = () => {
        const { chatData } = this.state;

        // Depending on chatData category and permissions? Allow specific actions
        const chatCategory = chatData && chatData.category ? chatData.category : null;

        switch (chatCategory) {
            case CHAT_GETHELP:
                return [ CREATE_AGREEMENT, CLOSE_MESSAGE, PARENTING_PLAN_SCHEDULE, ADD_LINK, ADD_FILE, INITIATE_APP_REVIEW, INITIATE_VIDEO ];
            case CHAT_ORGANIZATION:
                return [ ADD_LINK, ADD_FILE, INITIATE_VIDEO ];
            case CHAT_DIRECT_COMMUNICATION:
                return [ ADD_LINK, ADD_FILE, INITIATE_VIDEO ];
            default:
                return [];
        };
    }

    // Render page frame
    render() {
        const { members, messages, loadedChildren, drawerVisible,
            dialogAgreementVisible, dialogCloseVisible, dialogParentingPlanVisible,
            dialogHyperlinkVisible, dialogFileUploadVisible, dialogImageViewVisible, messageCount,
            dialogConfirmActionVisible, dialogInviteGuestVisible, confirmActionTitle,
            confirmActionText, confirmActionOnConfirm, imageViewURL, messageToModify, chatDoc, isArchived } = this.state;

        const closeDrawerButton = (<Button icon onClick={this.onDrawerClick}>{'arrow_forward'}</Button>);
        let messagesToShow = (<h1 className="text-center chat-loading-text">Loading...Please Wait</h1>);

        if (members && messages && messages.length) {
            messagesToShow = messages.map(this.renderMessage);
        }

        return (
            <div>
                <div id="chat-window" className={ drawerVisible ? "chat-drawer" : null }>
                    <div className="chat-main">
                        <div className="chat-input">
                            <div id="chat-messages">
                                {messagesToShow}
                            </div>
                            <div className="input-container">
                                <div className="frow">
                                    <div className={`frow main-input ${isArchived ? 'disabled-messaging' : ''}`}>
                                        <MenuButton
                                            disabled={isArchived}
                                            id="menu-button-2"
                                            icon
                                            onMenuClick = {this.menuClicked}
                                            menuItems={this.renderMenuItems()}
                                            simplifiedMenu={false}
                                            repositionOnScroll={false}
                                            position={MenuButton.Positions.TOP_RIGHT}
                                            anchor={{
                                                x: MenuButton.HorizontalAnchors.RIGHT,
                                                y: MenuButton.VerticalAnchors.TOP,
                                            }}
                                        >
                                            <FontIcon primary forceFontSize forceSize={25} className="chat-action-add">add</FontIcon>
                                        </MenuButton>
                                        <textarea disabled={isArchived} autoFocus id="chat-input" rows="10" autoCorrect="on" autoComplete="on" spellCheck="true" onKeyPress={this.onSendMessage} />
                                    </div>
                                    <div className="frow sendArea">
                                        <Button disabled={isArchived} raised primary swapTheming onClick={this.sendMessageClick}>Send</Button>
                                    </div>
                                </div>
                                <div className="space-between">
                                    <div id="typing-indicator" className="frow space-between isTyping">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AgreementDialog addAgreement={this.addAgreement} onHide={this.hideAgreementDialog} visible={dialogAgreementVisible} {...messageToModify ? messageToModify.attributes : {}}/>
                <CloseDialog addClose={this.addClose} onHide={this.hideCloseDialog} visible={dialogCloseVisible} />
                <MessageDashboard visible={drawerVisible} drawerNavButton={closeDrawerButton} members={members} />
                <ParentingPlanDialog addParentingPlan={this.addParentingPlan} onHide={this.hideParentingPlanDialog} visible={dialogParentingPlanVisible} members={members} availableChildren={loadedChildren} {...messageToModify ? messageToModify.attributes : {}}/>
                <HyperlinkDialog addHyperlink={this.addHyperlink} onHide={this.hideHyperlinkDialog} visible={dialogHyperlinkVisible} />
                <FileUploadDialog addFiles={this.addFiles} onHide={this.hideFileDialog} visible={dialogFileUploadVisible} />
                <ImageViewDialog imageUrl={imageViewURL} onHide={this.hideImageViewDialog} visible={dialogImageViewVisible} />
                <ConfirmActionDialog title={confirmActionTitle} text={confirmActionText} onCancel={this.hideConfirmActionDialog} onConfirm={confirmActionOnConfirm} visible={dialogConfirmActionVisible}/>
                <InviteGuestDialog inviteGuest={this.inviteGuest} onHide={this.hideInviteGuestDialog} visible={dialogInviteGuestVisible} />
            </div>
        );
    }
}

export function mapDispatchToProps(dispatch) {
    return {
        onSetToolbarTitle: title => dispatch(setToolbarTitle(title)),
        onSetToolbarButton: btns => dispatch(setToolbarButtons(btns)),
        onSetUniqueChannelName: channelName => dispatch(setUniqueChannelName(channelName)),
        onSetVideo: videoDetails => dispatch(setVideo(videoDetails)),
    };
}

const mapStateToProps = createStructuredSelector({
    channel: selectUniqueChannelName(),
    userProfile: selectCurrentUser(),
    organizationId: selectOrganizationId(),
    notifications: selectNotifications(),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Messages));
