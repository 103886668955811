import React, { PureComponent } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Button, DialogContainer, Toolbar } from 'react-md';
import InviteGuestForm from './InviteGuestForm.js';
import { normalizePhone } from '../../components/RequestFields/normalizePhone';

export default class InviteGuestDialog extends PureComponent {
    
    parseGuestInvite = (values) => {
        const inviteValues = {};
        values.forEach((val, index) => { inviteValues[index] = val;});
        inviteValues['sms'] = normalizePhone(inviteValues['sms']);
        return inviteValues;
    }

    handleSubmit = (e) => {
        const { inviteGuest } = this.props;
        const invite = this.parseGuestInvite(e);
        inviteGuest(invite);
    }

    render() {
        const { visible, onHide } = this.props;
        const groups = (<InviteGuestForm onSubmit={this.handleSubmit} key={0} index={0} />);

        return (
            <DialogContainer
                id="invite-guest-dialog"
                aria-labelledby="invite-guest-dialog-title"
                visible={visible}
                onHide={onHide}
                fullPage
            >
                <CSSTransitionGroup
                    id="invite-guest-form"
                    className="md-text-container md-toolbar--relative"
                    transitionName="md-cross-fade"
                    transitionEnterTimeout={300}
                    transitionLeave={false}
                >
                    <Toolbar
                        nav={<Button icon onClick={onHide}>close</Button>}
                        title="Invite Guest" titleId="invite-guest-dialog-title" fixed colored
                    />
                    {groups}
                </CSSTransitionGroup>
            </DialogContainer>
        );
    };
};