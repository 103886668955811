import React from 'react';
import useFormValidation from '../../hooks/useFormValidation';
import { ExpansionList, ExpansionPanel, Grid, Cell } from 'react-md';
import TextFormField from '../../components/FormFields/TextFormField';
import DateFormField from '../../components/FormFields/DateFormField';
import { validate } from './UserSearchValidate';

function UserSearchForm(props) {
    const { onSubmit } = props;

    const INITIAL_STATE = {
        userId: '',
        email: '',
        startDate: null,
        endDate: null,
    };

    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, searchForUser, validate);

    function searchForUser(values) {
        const search = parseSubmissionToSearch(values);
        onSubmit(search);
    }

    function parseSubmissionToSearch(values) {
        return {
            userId: values.userId.trim(),
            email: values.email.trim(),
            startDate: values.startDate,
            endDate: values.endDate,
        };
    }

    return (
        <form id={'user-search-form'} onSubmit={handleSubmit}>
            <ExpansionList>
                <ExpansionPanel
                    id='user-search-panel'
                    label='Search for a User by parameters'
                    saveProps={{ swapTheming: true, type:"submit", disabled: isSubmitting }}
                    cancelProps={{ primary: true }}
                    closeOnSave={false}
                    onCancel={() => {}}
                    saveLabel={'Search'}
                    cancelLabel={'Close'}
                >
                    <TextFormField
                        name="userId"
                        label="User ID (exact match)"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextFormField
                        name="email"
                        label="Email (exact match)"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <Grid className='md-grid--no-spacing' style={{ marginTop: '36px' }}>
                        <Cell desktopSize={2} tabletSize={8} phoneSize={4}>
                            <p>Account created Interval:</p>
                        </Cell>
                        <Cell desktopSize={5} tabletSize={4} phoneSize={4}>
                            <DateFormField
                                name="startDate"
                                label="Start Date"
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Cell>
                        <Cell desktopSize={5} tabletSize={4} phoneSize={4}>
                            <DateFormField
                                name="endDate"
                                label="End Date"
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Cell>
                    </Grid>
                </ExpansionPanel>
            </ExpansionList>
        </form>
    );
}

export default UserSearchForm;