import React, { useState } from 'react';
import { SelectionControlGroup, Grid, Cell } from 'react-md';
import Bounce from 'react-reveal/Bounce';

export default function CheckboxFormField (props) {
    const { name, label, values, errors, controls, onChange, disabled, showSelectAllButton = true } = props;

    const formProps = Object.assign({}, props);
    delete formProps['name'];
    delete formProps['values'];
    delete formProps['errors'];
    delete formProps['onChange'];
    delete formProps['label'];
    delete formProps['showSelectAllButton'];

    const [isAllChecked, setIsAllChecked] = useState(false);

    const toggleSelection = () => {
        if (isAllChecked) {
            onChange(name, '');
        } else {
            onChange(name, controls.map(x => x.value).join(','));
        }
        
        setIsAllChecked(!isAllChecked);
    }

    const buildSelectAllButton = () => {
        return (
            <a href={'#'} onClick={() => disabled ? null : toggleSelection() }>{ isAllChecked ? 'Deselect All' : 'Select All' }</a>
        );
    }

    const buildControlHeader = () => {
        return (
            <Grid>
                <Cell size={12}>{ label }</Cell>
                { showSelectAllButton && (<Cell size={12}>{ buildSelectAllButton() }</Cell>) }
            </Grid>
        );
    }

    return (
        <>
            <SelectionControlGroup
                value={values[name]}
                id={name}
                name={name}
                type="checkbox"
                className='full-width-field'
                onChange={(value, selectionname) => onChange(name, value)}
                label={buildControlHeader()}
                labelComponent={'div'}
                {...formProps}
            />
            <Bounce when={!!errors[name]}><p className="error-text">{errors[name] || '...'}</p></Bounce>
        </>
    );
}