import React from 'react';
import { FontIcon } from 'react-md';
import { PATH_ICONS } from '../../constants';
import useMediaLoader from '../../hooks/useMediaLoader';

function NavItemIcon(props) {
    const { id, svg } = props;

    const {
        imageHasLoaded,
        imageSrc,
    } = useMediaLoader({ id: id, suppressNotification: true });

    const buildIcon = (src) => {
        return (<FontIcon children={<img alt='navIcon' src={src} height="25" />} />);
    }

    return !imageSrc || !imageHasLoaded
        ? !svg
            ? null
            : buildIcon(`${process.env.PUBLIC_URL}${PATH_ICONS}${svg}`)
        : buildIcon(imageSrc)
}

export default NavItemIcon;