import React from 'react';
import { SelectField } from 'react-md';
import Bounce from 'react-reveal/Bounce';

export default function SelectionFormField (props) {
    const { name, values, errors, onChange } = props;

    const formProps = Object.assign({}, props);
    delete formProps['name'];
    delete formProps['values'];
    delete formProps['errors'];
    delete formProps['onChange'];

    return (
        <SelectField
            value={values[name]}
            id={name}
            name={name}
            className='full-select-field'
            onChange={(value, index, event, {id, name}) => onChange(name, value)}
            error={!!errors[name]}
            errorText={<Bounce when={!!errors[name]}>{errors[name] || '...'}</Bounce>}
            {...formProps}
        />
    )
}