function validate(values) {
    let errors = {};

    if (values.totalWeight < 100 && values.totalWeight !== 0) {
        errors.totalWeight = 'Not enough weight has been allocated!';
    }

    if (values.totalWeight > 100) {
        errors.totalWeight = 'Too much weight has been allocated!';
    }

    return errors;
}

export {
    validate,
};