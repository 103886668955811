function validate(values) {
    let errors = {};

    if (!values.professionalsIncluded) {
        errors.professionalsIncluded = "At least one other member is required";
    }
    
    return errors;
}

export {
    validate,
};