import React, { useEffect } from 'react';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { setToolbarTitle, setToolbarButtons } from '../../actions/global';
import { selectCurrentUser, selectCurrentOrganization, selectOrganizationId } from '../../selectors/global';
import ProfileCard from './ProfileCard';
import { Grid, Cell } from 'react-md';
import OrganizationCard from './OrganizationCard';
import useDialogFormBuilder from '../../hooks/useDialogFormBuilder';
import UpdateProfileForm from './UpdateProfileForm';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_ERROR, NOTIFICATION_INFO, NOTIFICATION_SUCCESS } from '../../constants/notifications';
import { putFile, deleteFile } from '../../utils/fileUpload';
import StipulationsList from '../../components/StipulationsList';
import { callCloudRunFunction } from '../../utils/firestore';
import { useCustomPageFrameworkLabels } from '../../hooks/useCustomLabels';

function MyProfile() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const currentOrganization = selector(selectCurrentOrganization());

    const labelVariableData = {
        organizationName: currentOrganization && currentOrganization.organization || '',
        name: currentUser && currentUser.displayName || '',
        email: currentUser && currentUser.email || '',
    };

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('Profile', labelVariableData);

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        // const toolBarTitle = `My Profile - ${currentUser.displayName}`;
        const toolBarButtons = [];

        // dispatch(setToolbarTitle(toolBarTitle));
        dispatch(setToolbarButtons(toolBarButtons));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'My Profile',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        finishedLoading();

        return () => {
            // dispatch(setToolbarTitle(''));
            dispatch(setToolbarButtons([]));
        }
    }, []);
    
    const updateProfessionalDialog = {
        FormComponent: UpdateProfileForm,
        onSubmit: updateProfessional,
        dialogData: { title: getLabel('updateTitleBar') },
        formData: { initialValues: currentUser },
    }

    const {
        showDialog: showUpdateDialog,
        renderDialogForm: renderUpdateDialogForm,
    } = useDialogFormBuilder(updateProfessionalDialog);

    function updateProfessional(updateProProfile) {
        if (!updateProProfile.uid) {
            showToastNotification('Profile has failed to be updated', NOTIFICATION_ERROR);
            return;
        }

        const { updatedProfilePicture } = updateProProfile;
        delete updateProProfile['updatedProfilePicture'];

        const log = {
            proId: currentUser.uid,
            organizationId: currentOrganization.id,
            page: 'Profile',
            action: 'update profile',
            metadata: {...updateProProfile}
        };

        showToastNotification('Updating your profile', NOTIFICATION_INFO);

        const promises = [];

        // If a new profile picture is uploaded
        if (updatedProfilePicture) {
            const basePath = `Professionals/${updateProProfile.uid}/`;
            promises.push(putFile(`${basePath}${updatedProfilePicture.name}`, updatedProfilePicture).then((url) => {
                updateProProfile.photoUrl = url;
                log.metadata.photoUrl = url;
            }));

            // Delete current profile picture if exists
            if (updateProProfile.photoUrl) {
                try {
                    const uri = decodeURIComponent(updateProProfile.photoUrl);
                    const baseName = uri.split(updateProProfile.uid)[1];
                    const fileName = baseName.substring(1, baseName.indexOf('?'));

                    deleteFile(`${basePath}${fileName}`);
                } catch (err) {
                    console.log(err);
                }
            }
        }

        Promise.all(promises).then(() => {
            return callCloudRunFunction(currentUser, 'updateProApprovedProfessionals', updateProProfile);
        }).then(() => {
            showToastNotification('Sucessfully updated your profile', NOTIFICATION_SUCCESS);
            callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Profile failed to be updated', NOTIFICATION_ERROR);    
        });
    }

    function buildPageContent() {
        return (
            <Grid>
                <Cell desktopSize={3} tabletSize={4} phoneSize={4}>
                    <Grid>
                        <Cell size={12}>
                            <ProfileCard getLabel={getLabel} updateClicked={showUpdateDialog} {...currentUser}/>
                        </Cell>
                        <Cell size={12}>
                            <OrganizationCard getLabel={getLabel} {...currentOrganization}/>
                        </Cell>
                    </Grid>
                </Cell>
                <Cell desktopSize={9} tabletSize={4} phoneSize={4}>
                    <Grid>
                        <Cell size={12}>
                            <StipulationsList getLabel={getLabel} {...currentUser}/>
                        </Cell>
                    </Grid>
                </Cell>
            </Grid>
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderUpdateDialogForm() }
        </>
    );
}

export default MyProfile;