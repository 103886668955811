import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import usePageFramework from '../../hooks/usePageFramework';
import { selectCurrentUser, selectOrganizationId } from '../../selectors/global';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { setToolbarButtons, emptyToolbarButtons, emptyToolbarTitle, setToolbarTitle } from '../../actions/global';
import { callCloudRunFunction } from '../../utils/firestore';
import GeneralConfirmationForm from '../../components/Forms/GeneralConfirmationForm';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';
import { ThemedTabsContainer } from '../../components/ThemedTabs';
import { Tab } from 'react-md';
import TenantTheme from './TenantTheme';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants/notifications';
import TenantThemeSubCollection from './TenantThemeSubCollection';
import DeleteConfirmationForm from '../../components/Forms/DeleteConfirmationForm';
import VariableConfirmationForm from '../../components/Forms/VariableConfirmationForm';

function OrganizationAppTheme() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const [ wasPrompted, setWasPrompted ] = useState(false);
    const [ subCollections, setSubCollections ] = useState([]);
    const [ pendingThemeData, setPendingThemeData ] = useState(null);

    const {
        finishedLoading,
        errorLoading,
        startLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });


    useEffect(() => {
        const toolBarTitle = `Organization App Theme`;
        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];

        dispatch(setToolbarTitle(toolBarTitle));
        dispatch(setToolbarButtons(toolBarButtons));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization App Theme',
            action: 'visit page',
        };
        
        callCloudRunFunction(currentUser, 'logProUserAction', log);

        return () => {
            dispatch(emptyToolbarTitle());
            dispatch(emptyToolbarButtons());
        }
    }, []);

    useEffect(() => {
        let tenantThemeStream = null;
        const db = firebase.firestore();

        tenantThemeStream = db.collection('TenantThemes').doc(organizationId).onSnapshot((snapshot) => {
            if (!snapshot.exists) {
                // show error and ask if they want to copy/generate new app theme
                console.log('does not exist');
                if (!wasPrompted) {
                    showCopyTenantThemePopup();
                }
                errorLoading();
            } else {
                startLoading();
                setWasPrompted(true)
                finishedLoading();
                console.log('Now exists');
            }
        });

        callCloudRunFunction(currentUser, 'getTenantThemesSubCollections', {organizationId: organizationId}).then((result) => {
            console.log(result);
            setSubCollections(result);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Error grabbing sub-collections', NOTIFICATION_ERROR);
        })

        return () => {
            if (tenantThemeStream) {
                tenantThemeStream();
            }
        }
    }, [ wasPrompted ]);

    const copyTenantAppTheme = () => {
        showToastNotification('Copying default tenant theme', NOTIFICATION_INFO);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization App Theme',
            action: 'copy default tenant theme',
        };
        
        callCloudRunFunction(currentUser, 'copyDefaultTenantTheme', { organizationId: organizationId }).then(() => {
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            showToastNotification('Default tenant theme has been copied!', NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Something has gone wrong', NOTIFICATION_ERROR);
        });
    }

    const copyTenantAppThemePopup = {
        FormComponent: GeneralConfirmationForm,
        onSubmit: () => copyTenantAppTheme(),
        onHide: () => setWasPrompted(true),
        popupData: { title: `Type \'${'Confirm'}\' to copy the default tenant theme form.`, formId: 'copy-default-tenant-theme-form' },
        formData: { formId: 'copy-default-tenant-theme-form', initialValues: { values: 'Confirm' } },
    };

    const {
        showPopup: showCopyTenantThemePopup,
        renderPopupForm: renderCopyTenantThemePopupForm,
    } = usePopupFormBuilder(copyTenantAppThemePopup);

    const updateTenantTheme = (tenantThemeData) => {
        const tenantThemeMetadata = {
            organizationId: organizationId
        };
        const updateTenantThemeData = Object.assign({}, tenantThemeData, tenantThemeMetadata);
        
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Tenant Theme',
            action: 'update tenant theme',
            metadata: { ...updateTenantThemeData },
        };
        
        showToastNotification('Updating tenant theme', NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'updateTenantTheme', updateTenantThemeData).then(() => {
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            showToastNotification('Sucessfully updated tenant theme', NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Something has gone wrong', NOTIFICATION_ERROR);
        });
    }

    const updateTenantAppThemePopup = {
        FormComponent: GeneralConfirmationForm,
        onSubmit: () => updateTenantTheme(pendingThemeData),
        onHide: () => setPendingThemeData(null),
        popupData: { title: `Type \'${'Confirm'}\' to update tenant theme.`, formId: 'update-tenant-theme-popup' },
        formData: { formId: 'update-tenant-theme-popup', initialValues: { values: 'Confirm', warningText: 'Changes will be live immediately!' } },
    };

    const {
        showPopup: showUpdateTenantThemePopup,
        renderPopupForm: renderUpdateTenantThemePopupForm,
    } = usePopupFormBuilder(updateTenantAppThemePopup);

    const tenantThemeSubmitted = (tenantThemeData) => {
        setPendingThemeData(tenantThemeData);
        showUpdateTenantThemePopup();
    }

    const updateTenantThemeSub = (tenantThemeData) => {
        const tenantThemeMetadata = {
            organizationId: organizationId,
        };
        const updateTenantThemeData = Object.assign({}, tenantThemeData.data, tenantThemeMetadata);
        
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Tenant Theme',
            action: 'update tenant theme',
            metadata: { ...updateTenantThemeData },
        };
        
        showToastNotification('Updating tenant theme', NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'updateTenantThemeSubCollectionDoc', updateTenantThemeData).then(() => {
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            showToastNotification('Sucessfully updated tenant theme', NOTIFICATION_SUCCESS);
            tenantThemeData.function();
        }).catch((err) => {
            console.log(err);
            showToastNotification('Something has gone wrong', NOTIFICATION_ERROR);
        });
    }

    const updateTenantThemeSubPopup = {
        FormComponent: GeneralConfirmationForm,
        onSubmit: () => updateTenantThemeSub(pendingThemeData),
        onHide: () => setPendingThemeData(null),
        popupData: { title: `Type \'${'Confirm'}\' to update tenant theme.`, formId: 'update-tenant-theme-sub-popup' },
        formData: { formId: 'update-tenant-theme-sub-popup', initialValues: { values: 'Confirm', warningText: 'Changes will be live immediately!' } },
    };

    const {
        showPopup: showUpdateTenantThemeSubPopup,
        renderPopupForm: renderUpdateTenantThemeSubPopupForm,
    } = usePopupFormBuilder(updateTenantThemeSubPopup);

    const tenantThemeSubSubmitted = (tenantThemeData) => {
        setPendingThemeData(tenantThemeData);
        showUpdateTenantThemeSubPopup();
    }

    const createTenantSubDocumentPopup = {
        FormComponent: VariableConfirmationForm,
        onSubmit: (values) => pendingThemeData && pendingThemeData.function(values, pendingThemeData.data),
        onHide: () => setPendingThemeData(null),
        popupData: { title: pendingThemeData && pendingThemeData.title || '', formId: 'create-tenant-sub-document-popup' },
        formData: { formId: 'create-tenant-sub-document-popup', initialValues: { values: 'Confirm', warningText: pendingThemeData && pendingThemeData.warning || '' } },
    };

    const {
        showPopup: showCreateTenantSubDocument,
        renderPopupForm: renderCreateTenantSubDocumentPopupForm,
    } = usePopupFormBuilder(createTenantSubDocumentPopup);

    const createTenantThemeSubDocument = (tenantThemeData) => {
        setPendingThemeData(tenantThemeData);
        showCreateTenantSubDocument();
    }

    const deleteTenantTheme = () => {
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Tenant Theme',
            action: 'delete tenant theme',
        };

        showToastNotification('Deleting tenant theme', NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'deleteTenantTheme', { organizationId: organizationId }).then(() => {
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            showToastNotification('Sucessfully deleted tenant theme', NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Something has gone wrong', NOTIFICATION_ERROR);
        });
    }

    const deleteTenantThemePopup = {
        FormComponent: DeleteConfirmationForm,
        onSubmit: deleteTenantTheme,
        onHide: () => {},
        popupData: { title: `Type \'${'Delete'}\' to delete tenant theme form.`, formId: 'delete-tenant-theme-form' },
        formData: { formId: 'delete-tenant-theme-form', initialValues: {} },
    };

    const {
        showPopup: showDeleteTenantThemePopup,
        renderPopupForm: renderDeleteTenantThemePopupForm,
    } = usePopupFormBuilder(deleteTenantThemePopup);

    const deleteTenantSubDocPopup = {
        FormComponent: DeleteConfirmationForm,
        onSubmit: (values) => pendingThemeData && pendingThemeData.function(values, pendingThemeData.data),
        onHide: () => {},
        popupData: { title: `Type \'${'Delete'}\' to delete this section.`, formId: 'delete-tenant-sub-document-form' },
        formData: { formId: 'delete-tenant-sub-document-form', initialValues: {} },
    };

    const {
        showPopup: showDeleteTenantSubDocPopup,
        renderPopupForm: renderDeleteTenantSubDocumentPopupForm,
    } = usePopupFormBuilder(deleteTenantSubDocPopup);

    const deleteTenantSubDocument = (tenantThemeData) => {
        setPendingThemeData(tenantThemeData);
        showDeleteTenantSubDocPopup();
    }

    const buildSubCollectionTab = (subCollection) => {
        return { label: subCollection, content: <TenantThemeSubCollection onUpdate={tenantThemeSubSubmitted} subCollection={subCollection} onAddSubDocument={createTenantThemeSubDocument} onDeleteSubDocument={deleteTenantSubDocument}/> };
    }

    function buildPageContent() {
        // Dynamic generation of sub collection tabs?
        const tabs = [
            { label: 'Tenant Theme', content: <TenantTheme onUpdate={tenantThemeSubmitted} onDelete={showDeleteTenantThemePopup} /> },
            ...subCollections.map(subCollection => buildSubCollectionTab(subCollection)),
        ];

        return (
            <ThemedTabsContainer>
                { tabs.map(x => <Tab id={x.label} label={x.label}>{x.content}</Tab>) }
            </ThemedTabsContainer>
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderCopyTenantThemePopupForm() }
            { renderUpdateTenantThemePopupForm() }
            { renderDeleteTenantThemePopupForm() }
            { renderCreateTenantSubDocumentPopupForm() }
            { renderUpdateTenantThemeSubPopupForm() }
            { renderDeleteTenantSubDocumentPopupForm() }
        </>
    );
}

export default OrganizationAppTheme;