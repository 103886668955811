import React, { useRef, useEffect } from 'react';

export default function VideoTrack({ track, isLocal, priority, isRightPane }) {
  const ref = useRef();

    useEffect(() => {
            const el = ref.current;
            el.muted = true;
            
            if (track.setPriority && priority) {
                track.setPriority(priority);
            }

            track.attach(el);
            return () => {
                track.detach(el);
                if (track.setPriority && priority) {
                    // Passing `null` to setPriority will set the track's priority to that which it was published with.
                    track.setPriority(null);
                }
            };
    }, [track, priority]);

    // The local video track is mirrored.
    const style = isLocal ? 
    { 
        transform: 'rotateY(180deg)',
        width: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
    } : 
    {
        width: isRightPane ? '90%' : '100%',
        maxHeight: isRightPane ? '850px' : '50%',
        objectFit: 'contain',
    };

    return <video ref={ref} style={style} />;
}
