import { useState, useEffect } from "react";
import { PDF, IMAGE, PATH_ICONS } from "../constants";
import { showToastNotification } from "../components/Notifications/ToastNotification";
import { NOTIFICATION_ERROR } from "../constants/notifications";
import { getDataFromUploadId } from "../utils/fileUpload";

// Specialized hook for loading data/image from firestore given an upload document id
function useMediaLoader(props) {
    const { id, suppressNotification } = props;

    const [ uploadId, setUploadId ] = useState(id);
    const [ imageHasLoaded, setImageHasLoaded ] = useState(false);
    const [ imageSrc, setImageSrc ] = useState('#');
    const [ imageLabel, setImageLabel ] = useState('');
    const [ imageNote, setImageNote ] = useState('');
    const [ imageDate, setImageDate ] = useState('');

    const setContent = (src, label, note, date) => {
        setImageSrc(src);
        setImageLabel(label);
        setImageHasLoaded(true);
        setImageNote(note);
        setImageDate(date);
    }

    useEffect(() => {
        if (id && id !== uploadId) {
            setUploadId(id);
        }
    }, [ id ]);

    useEffect(() => {
        if (!uploadId) {
            return;
        }

        getDataFromUploadId(uploadId).then(([ uploadData, fileType ]) => {
            switch (fileType) {
                case PDF:
                    setContent(`${process.env.PUBLIC_URL}${PATH_ICONS}2019.06.27-DRAFT-V1-ICON-PDF-02.svg`, uploadData.title, uploadData.notes, uploadData.createdOn);
                    break;

                case IMAGE:
                    setContent(uploadData.url, uploadData.title, uploadData.notes, uploadData.createdOn);
                    break;

                default:
                    break;
            }

        }).catch((err) => {
            console.log(err);
            if (!suppressNotification) {
                showToastNotification('Could not load media', NOTIFICATION_ERROR);
            }
        });
    }, [uploadId]);
    
    return {
        imageHasLoaded,
        imageSrc,
        imageLabel,
        imageNote,
        imageDate,
    };
}

export default useMediaLoader;