import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { Cell, Button } from 'react-md';
import { renderFileField } from '../../components/RequestFields/renderFileField';

class FileForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { 
            files: null,
        };
    }

    onFileChangeHandle = (event, newValue) => {
        this.setState({
            files: newValue,
        });
    }

    render() {
        const { error, submitting, pristine, handleSubmit } = this.props;
        const { files } = this.state;
        
        const formStyle = { paddingLeft: '25px', paddingRight: '25px', marginTop: '80px'};

        return (
            <form onSubmit={handleSubmit} style={formStyle} className='md-grid'>
                <Cell size={12}>
                    <Field
                        id={`file`}
                        accept="image/*,application/pdf"
                        name="files"
                        onChange={this.onFileChangeHandle}
                        required
                        multiple
                        props={{
                            label: 'Select Image or PDF file(s) to share in the Mediation session'
                        }}
                        component={renderFileField}
                    />
                </Cell>
                <Cell size={12}>
                    { !files && 'No files currently selected.\n' }
                    { files && `${Array.from(files).length} files selected.\n` }
                </Cell>
                { files && Array.from(files).map((x) => <Cell size={12}>{`${x.name}`}</Cell> ) }
                {error && <strong>{error}</strong>}
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <Button className="full-width-field" disabled={pristine || submitting} type="submit" flat primary swapTheming>Submit</Button>
                </Cell>
            </form>
        );
    }
};

export default reduxForm({
    form: 'FileForm',
    fields: ['files'],
    enableReinitialize: true,
})(FileForm);