import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as firebase from 'firebase';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import { selectClientProfile, selectOrganizationId, selectDataItemsFromComponent, selectCurrentUser } from '../../selectors/global';
import { setToolbarButtons, setDataItemsToComponent } from '../../actions/global';
import ProTable from '../../components/ProTable';
import { ALL, FILTER_DATE, FILTER_TEXT, FILTER_NONE } from '../../constants';
import { NAV_DEFAULT } from '../../constants/navigation';
import DetailsDialog from '../../components/DetailsDialog';
import AgreementDetailContent from '../../components/DetailsDialog/AgreementDetailContent';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { callCloudRunFunction } from '../../utils/firestore';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import { getClientLabelVariableData } from '../../utils/customBranding';
import useCustomAssets from '../../hooks/useCustomAssets';
import TableIconButton from '../../components/Buttons/TableIconButton';

const COMPONENT_NAME = 'ClientAgreements';

function ClientAgreements() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const clientProfile = selector(selectClientProfile());
    const dataItems = selector(selectDataItemsFromComponent(COMPONENT_NAME));

    const [ focusedAgreementId, setFocusedAgreementId ] = useState(null);

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('ClientAgreements', getClientLabelVariableData(clientProfile));

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (organizationId === false || !clientProfile) {
            return;
        }

        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];
        dispatch(setToolbarButtons(toolBarButtons));

        var clientAgreementsStream = null;
        const db = firebase.firestore();

        clientAgreementsStream = db.collection('Agreements').where('participants', 'array-contains', clientProfile.id).onSnapshot((snapShot) => {
            const dataItems = snapShot.docs.map((doc) => {
                const agreementItem = doc.data();
                agreementItem.id = doc.ref.id;
                agreementItem.createdOn = agreementItem.createdOn ? agreementItem.createdOn.toDate() : null;
                agreementItem.updatedOn = agreementItem.updatedOn ? agreementItem.updatedOn.toDate() : null;
                return agreementItem;
            });
            
            finishedLoading();
            dispatch(setDataItemsToComponent(dataItems, COMPONENT_NAME));
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Agreements',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        return () => {
            dispatch(setToolbarButtons([]));

            if (clientAgreementsStream) {
                clientAgreementsStream();
            }
        }
    }, []);

    const foundAgreement = dataItems && dataItems.find(x => x.id === focusedAgreementId) || {};

    if (organizationId === false || !clientProfile) {
        history.push(NAV_DEFAULT);
        return (<></>)
    }

    const viewAgreementDetails = (id) => {
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Agreements',
            action: 'view agreement details',
            agreementId: id,
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        setFocusedAgreementId(id);
    }

    const createEmptyCard = (status) => {
        const cardsInfo = [
            { status: ALL, cardMediaUrl: getAsset('clientAgreementsEmpty'), headerTitle: getLabel('emptyResultsHeader'), subTitle: getLabel('emptyResultsHeader'), buttonText: 'Back to Client Profile', buttonClick: () => history.goBack() },
        ];

        const cardProps = cardsInfo.find(x => x.status === status);
        return (< GettingStartedPaper {...cardProps} key = {cardProps.status}/>);
    }

    function buildPageContent() {
        const columns = [
            { label: getDataLabel('categoryData'), value: 'category', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('titleData'), value: 'title', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('detailsData'), value: 'info', staticText: true, type: FILTER_NONE, fn: (item, id, value) => (<TableIconButton id={`details-${id}`} label={value} onClick={viewAgreementDetails}/>) },
            { label: getDataLabel('createdByData'), value: 'createdBy', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('createdData'), value: 'createdOn', isPrimaryColumn: true, type: FILTER_DATE, fn: (item) => moment(item).calendar() },
        ];

        return (
            <section className="md-grid">
                <ProTable status={ALL} items={dataItems} columns={columns} key={ALL} componentName={COMPONENT_NAME} emptyCard={() => createEmptyCard(ALL)}/>
            </section>
        );
    }

    return (
        <>
            { renderPageContent() }
            <DetailsDialog content={AgreementDetailContent} onHide={() => setFocusedAgreementId(null)} visible={focusedAgreementId !== null} data={foundAgreement}/>
        </>
    );
}

export default ClientAgreements;