import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import { Cell, Grid, Button, Card, CardTitle, CardText } from 'react-md';
import useFormValidation from '../../hooks/useFormValidation';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import SelectionFormField from '../FormFields/SelectionFormField';
import ThemedButton from '../Buttons/ThemedButton';

function ReassignMediationsFormComponents(props) {
    const { onSubmit, onHide, controlMediators, recentlyZeroedPros, activeMediations } = props;

    const reassignedMediations = {};

    activeMediations.forEach((mediation) => {
        reassignedMediations[mediation.id] = mediation.assignedProffesional
    });

    const INITIAL_STATE = {
        reassignedMediations: reassignedMediations,
    };

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, submitReassignments);

    function submitReassignments(values) {
        const reassignments = parseSubmissionToReassignments(values);
        onSubmit(reassignments);
    }
    
    function parseSubmissionToReassignments(values) {
        const reassignments = Object.entries(values['reassignedMediations']).map(([ id, value ]) => {
            return {
                mediationId: id,
                assignment: value,
            };
        });

        // Filter changes in mediation assignment
        const changedAssignments = reassignments.filter((assignment) => {
            return activeMediations.find(x => x.id === assignment.mediationId).assignedProffesional !== assignment.assignment;
        });

        return changedAssignments;
    }

    const handleAssignmentChange = (id, value) => {
        const mergedWeights = Object.assign(values['reassignedMediations'], {[id]: value});
        handleChange('reassignedMediations', mergedWeights);
    }

    const buildNotAssignedWarning = (mediation) => {
        const currentlyAssignedPro = values.reassignedMediations[mediation.id];
        const originalAssignedPro = activeMediations.find(x => x.id === mediation.id).assignedProffesional;

        const label = currentlyAssignedPro === originalAssignedPro ? 'You have not re-assigned this mediation to another professional' : 'You have assigned this mediation to another professional that was recently changed to have zero mediation weighting';

        return (
            <Button icon tooltipLabel={label}>warning</Button>
        );
    }

    const buildActiveMediationCard = (mediation) => {
        return (
            <Card>
                <CardTitle title={`Mediation from ${mediation.createdBy}`}>
                    {
                        recentlyZeroedPros.find(zeroedPro => zeroedPro.id === values.reassignedMediations[mediation.id])
                            && buildNotAssignedWarning(mediation)
                    }
                </CardTitle>
                <CardText>
                    <SelectionFormField
                        name={mediation.id}
                        label="Assigned Mediator"
                        values={values.reassignedMediations}
                        errors={errors}
                        menuItems={controlMediators}
                        itemLabel="label"
                        itemValue="value"
                        required
                        onChange={handleAssignmentChange}
                        onBlur={handleBlur}
                    />
                </CardText>
            </Card>
        );
    }

    const buildNoMediations = () => {
        return (
            <Grid>
                <Cell size={12}>
                    <p>There were no active mediations found which were assigned to the following Mediators: </p>
                </Cell>
                {
                    recentlyZeroedPros.map(pro => <Cell size={12}><p>{pro.displayName}</p></Cell>)
                }
                <Cell size={12}><p>You may now close this form</p></Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton className="full-width-field" disabled={isSubmitting} onClick={onHide} flat primary swapTheming>Close</ThemedButton>
                </Cell>
            </Grid>
        );
    }

    const buildForm = () => {
        return (
            <form onSubmit={handleSubmit}>
                <Grid className="md-grid">
                    {
                        activeMediations.map(mediation => (<Cell size={12}>{ buildActiveMediationCard(mediation) }</Cell>))
                    }
                    <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                        <ThemedButton className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>Finalize Assignments</ThemedButton>
                    </Cell>
                </Grid>
            </form>
        );
    }

    return activeMediations.length === 0
        ? buildNoMediations()
        : buildForm()
}

function ReassignMediationsForm(props) {
    const { onSubmit, onHide, recentlyZeroedPros, professionals } = props;

    const controlMediators = professionals.map((pro) => { return { label: pro.displayName, value: pro.id } });

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    const [ activeMediations, setActiveMediations ] = useState([]);

    useEffect(() => {
        const professionalIds = recentlyZeroedPros.map(pros => pros.id);
        const db = firebase.firestore();

        db.collection('getHelp').where('assignedProffesional', 'in', professionalIds).get().then((snapshot) => {
            const activeMediations = snapshot.docs.reduce((prev, curr) => {
                const activeMediation = curr.data();

                if (['Inbound', 'Open'].includes(activeMediation.status)) {
                    activeMediation.id = curr.id;
                    
                    prev.push(activeMediation);
                }

                return prev;
            }, []);

            setActiveMediations(activeMediations);
            finishedLoading();
        }).catch((err) => {
            console.log(err);
            errorLoading();
        });
    }, []);

    function buildPageContent() {
        return (
            <ReassignMediationsFormComponents onSubmit={onSubmit} onHide={onHide} recentlyZeroedPros={recentlyZeroedPros} controlMediators={controlMediators} activeMediations={activeMediations}/>
        );
    }

    return (
        <section className="md-grid">
            { renderPageContent() }
        </section>
    )
}

export default ReassignMediationsForm;