function validate(values) {
    let errors = {};

    if (!values.confirmation) {
        errors.confirmation = 'Required';
    }

    return errors;
}

export {
    validate,
};