import React from 'react';
import { getPrimaryCPName, getSecondaryCPName, getCreatedByCPName } from './';

const ExpenseScheduleCard = ({item}) => {
    const percentage = item.coParentPercentage ? parseFloat(item.coParentPercentage.replace('%', '')) / 100 : null;
    const cost = item.totalCost ? parseFloat(item.totalCost.replace('$', '')) : null;

    return (
        <div id="expense-card-contents">
            <p>{ item.title ? item.title : null }</p>
            <p>{ item.totalCost ? `Total Cost of ${item.totalCost}` : null }</p>
            <p>{ item.coParentPercentage ? `Percentage split: ${item.coParentPercentage}` : null }</p>
            <p>{ percentage !== null && cost !== null ? `${getPrimaryCPName(item)} pays $${(cost * (1 - percentage)).toFixed(2)}` : null }</p>
            <p>{ percentage !== null && cost !== null ? `${getSecondaryCPName(item)} pays $${(cost * (percentage)).toFixed(2)}` : null }</p>
            <p>{ item.notes ? `Notes: ${item.notes}` : null }</p>
            <p>{ item.location ? `Location: ${item.location}` : null }</p>
            <p>{ getCreatedByCPName(item) ? `Created by: ${getCreatedByCPName(item)}` : null }</p>
        </div>
    );
}

export default ExpenseScheduleCard;