import moment from 'moment';
import { SCHEDULE_CATEGORY_EXCHANGE_PICKUP, SCHEDULE_CATEGORY_EXCHANGE_DROPOFF } from "../../constants/schedule";

function validate(values) {
    const errors = {};

    if (!values.children) {
        errors.children = "Select at least one child";
    }

    if (!values.title) {
        errors.title = "A title is required";
    }

    const startDate = values.startDate;
    const startTime = values.startTime;
    const endDate = values.endDate;
    const endTime = values.endTime;

    const isPickup = values.category === SCHEDULE_CATEGORY_EXCHANGE_PICKUP;
    const isDropOff = values.category === SCHEDULE_CATEGORY_EXCHANGE_DROPOFF;

    let startDateTime = moment(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime.getHours(), startTime.getMinutes()));
    let endDateTime = moment(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.getHours(), endTime.getMinutes()));

    // If the event is a pickup or dropoff event, date/times should be merged
    if (isPickup) {
        endDateTime = moment(startDateTime);
        endDateTime.add(1, 'minutes');
    } else if (isDropOff) {
        startDateTime = moment(endDateTime);
        startDateTime.subtract(1, "minutes");
    }

    if (startDateTime.isAfter(endDateTime)) {
        errors.startDate = 'Start date/time is after end date/time';
        errors.startTime = 'Start date/time is after end date/time';
        errors.endDate = 'End date/time is before start date/time';
        errors.endTime = 'End date/time is before start date/time';
    }

    return errors;
}

export {
    validate,
}