import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as firebase from 'firebase';
import { Media, Card, Grid, Cell } from 'react-md';
import { selectisAuthenticated, selectCurrentUser, selectOrganizationId } from '../../selectors/global';
import { setToolbarTitle, setToolbarButtons } from '../../actions/global';
import OrganizationDetailsForm from './OrganizationDetailsForm';
import { normalizePhone } from '../../components/RequestFields/normalizePhone';
import { formatPhone } from '../../components/RequestFields/formatPhone';
import { NAV_DEFAULT } from '../../constants/navigation';
import { PATH_CARDS } from '../../constants';
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants/notifications';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import Fade from 'react-reveal/Fade';
import { callCloudRunFunction } from '../../utils/firestore';

class OrganizationDetails extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            organization: undefined,
            docId: undefined,
        };

        this.submitOrganizationDetails = this.submitOrganizationDetails.bind(this);
    }

    componentDidMount() {
        const { organizationId } = this.props;

        if (organizationId === false) {
            return this.props.history.push(NAV_DEFAULT);
        }

        const orgId = this.props.location.hash.length > 1 ? this.props.location.hash.substring(1) : organizationId;

        this.props.onSetToolbarTitle('Organization Details');
        this.props.onSetToolbarButtons([ { icon: 'arrow_back', onClick: () => this.props.history.goBack() }]);
        
        this.loadOrganization(orgId);
        this.onPageVisit();
    }

    componentWillUnmount() {
        this.props.onSetToolbarTitle(``);
        this.props.onSetToolbarButtons([]);
    }

    loadOrganization(id) {
        const db = firebase.firestore();

        db.collection("ProOrganizations").doc(id).get().then((doc) => {
            if (doc.exists) {
                const data = doc.data();
                data.phone = formatPhone(data.phone);
                this.setState({ organization: data, docId: id });
            }
        });
    }

    onPageVisit() {
        const { currentUser, organizationId } = this.props;

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Details',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
    }

    submitOrganizationDetails(valueMap) {
        const { currentUser, organizationId } = this.props;
        const { docId } = this.state;
        const valueObject = valueMap.toObject();
        const db = firebase.firestore();
        
        const newOrgDetailValues = {
            organization: valueObject.organization,
            country: valueObject.country,
            state: valueObject.state,
            city: valueObject.city,
            zip: valueObject.zip,
            county: valueObject.county,
            primaryContact: valueObject.primaryContact,
            email: valueObject.email,
            phone: normalizePhone(valueObject.phone),
        };

        if (!docId) {
            console.log('No organization found');
            return;
        }

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Details',
            action: 'update organization details',
            metadata: {...newOrgDetailValues}
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        
        db.collection("ProOrganizations").doc(docId).set(newOrgDetailValues, {merge: true}).then(() => {
            showToastNotification('Organization successfully updated', NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Error occured when submitting', NOTIFICATION_ERROR);
        });
    }

    render() {
        const { organization } = this.state;

        return (
            <Grid>
                <Cell size={12}>
                    <Fade duration={500}>
                        <Card>
                            <Media aspectRatio="5-1">
                                <img src={`${process.env.PUBLIC_URL}${PATH_CARDS}card_settingsHalfSize.png`} alt="org" />
                            </Media>
                            {organization && <OrganizationDetailsForm onSubmit={this.submitOrganizationDetails} initialValues={organization} />}
                        </Card>
                    </Fade>
                </Cell>
            </Grid>
        );
    }
}

export function mapDispatchToProps(dispatch) {
    return {
       onSetToolbarTitle: title => dispatch(setToolbarTitle(title)),
       onSetToolbarButtons: buttons => dispatch(setToolbarButtons(buttons)),
    }
}

const mapStateToProps = createStructuredSelector({
    isAuthenticated: selectisAuthenticated(),
    currentUser: selectCurrentUser(),
    organizationId: selectOrganizationId(),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetails);