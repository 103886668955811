import React, { useState } from 'react';
import { LinearProgress } from 'react-md';
import Fade from 'react-reveal/Fade';
import DefaultLoadedFramework from '../components/LoadingPageFramework/DefaultLoaded';
import DefaultErrorFramework from '../components/LoadingPageFramework/DefaultError';

const defaultBuildPageContent = () => {
    return (<DefaultLoadedFramework/>);
}

const defaultBuildLoadingComponent = () => {
    return (<LinearProgress id={'loading-progress-framework'}/>);
}

const defaultBuildErrorComponent = () => {
    return (<DefaultErrorFramework/>);
}

function useLoadingPageFramework(frameworkData) {
    const {
        buildPageContent = defaultBuildPageContent,
        buildLoadingComponent = defaultBuildLoadingComponent,
        buildErrorComponent = defaultBuildErrorComponent } = frameworkData;

    const [ isLoading, setIsLoading ] = useState(true);
    const [ hasLoadingError, setHasLoadingError ] = useState(false);

    const startLoading = () => {
        setHasLoadingError(false);
        setIsLoading(true);
    }

    const finishedLoading = () => {
        setIsLoading(false);
    }

    const errorLoading = () => {
        finishedLoading();
        setHasLoadingError(true);
    }

    const renderPageContent = () => {
        return isLoading
            ? buildLoadingComponent()
            : hasLoadingError
                ? <Fade duration={500}>{ buildErrorComponent() }</Fade>
                : <Fade duration={500}>{ buildPageContent() }</Fade>
    }
    
    return {
        startLoading,
        finishedLoading,
        errorLoading,
        renderPageContent,
    };
}

export default useLoadingPageFramework;