import React, { useEffect } from 'react';
import useCustomTheme from "../../hooks/useCustomTheme";
import { useState } from "react";
import { getDataFromUploadId } from '../../utils/fileUpload';
import usePageFramework from '../../hooks/usePageFramework';
import { selectCurrentOrganization } from '../../selectors/global';
import { THEME_COMPANY_LOGO } from '../../constants/themeKeys';

function CompanyLogo(props) {
    const { logoFile, useDefaultLogo = false } = props;
    const {
        selector,
    } = usePageFramework();
    
    const {
        getTheme,
        getDefaultTheme,
    } = useCustomTheme();

    const currentOrganization = selector(selectCurrentOrganization());
    const uploadId = useDefaultLogo ? getDefaultTheme(THEME_COMPANY_LOGO) : getTheme(THEME_COMPANY_LOGO);
    const [ logoUrl, setLogoUrl ] = useState(null);
    
    useEffect(() => {
        if (logoFile) {
            var reader = new FileReader();
            reader.onload = function(e) {
                setLogoUrl(e.target.result);
            }

            reader.readAsDataURL(logoFile);
        } else if (uploadId) {
            getDataFromUploadId(uploadId).then(([ uploadData, fileType ]) => {
                setLogoUrl(uploadData.url);
            });
        } else {
            setLogoUrl('/HyphenusLogoLight.png');
        }
    }, [ uploadId, logoFile ]);

    return (
        <img src={logoUrl} alt={currentOrganization && currentOrganization.organization || "Company Logo"} className="landing-logo"/>
    );
}

export default CompanyLogo;