import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { Button, Grid, Cell } from 'react-md';
import renderSelectField from '../../components/RequestFields/renderSelectField';
import { TIMEZONES } from '../../constants/lookupInfo';

class MessageTranscriptForm extends PureComponent {
    constructor(props) {
        super(props);

        this.props.change('timezoneValue', 'UTC');
    };

    buildTimezoneSelection = () => {
        const fieldStyle = { 'padding-bottom': '100px' };

        return (
            <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                <Field
                    id='timezoneValue'
                    name='timezoneValue'
                    label='Transcript Timezone'
                    className='full-width-field'
                    style={fieldStyle}
                    menuItems={TIMEZONES}
                    itemLabel="name"
                    itemValue="id"
                    component={renderSelectField}
                />
            </Cell>
        );
    };

    buildFormButtons = () => {
        const { submitting, pristine, onHide } = this.props;

        return (
            <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                <div className="btn-row-modify-schedule">
                    <Button flat secondary className="margin-right" onClick={onHide}>Cancel</Button>
                    <Button disabled={pristine || submitting} type="submit" flat primary swapTheming>Submit</Button>
                </div>
            </Cell>
        );
    };

    render() {
        const { handleSubmit } = this.props;

        return (
            <form id="message-transcript-form" onSubmit={handleSubmit}>
                <Grid>
                    { this.buildTimezoneSelection() }
                    { this.buildFormButtons() }
                </Grid>
            </form>
        );
    };
}

export default reduxForm({
    form: 'MessageTranscriptForm',
    fields: ['timezoneValue'],
    enableReinitialize: true,
})(MessageTranscriptForm);