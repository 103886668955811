import React from 'react';
import { Desktop, TabletOrMobile } from "../components/ResponsiveMedia";
import { Divider } from 'react-md';

const TwoColumnLayout = (props) => {
    const { buildLeftColumn, buildRightColumn, buildTabletMobile, columnSplit = 49 } = props;

    const leftWidth = 0 <= columnSplit && columnSplit <= 98 ? columnSplit : 49;
    const rightWidth = 100 - leftWidth - 2;
    
    const leftColumnStyle = { float: 'left', width: `${leftWidth}%`, height: '90vh', overflowy: 'auto', overflowX: "hidden" };
    const rightColumnStyle = { float: 'left', width: `${rightWidth}%`, height: '90vh', overflowy: 'auto', overflowX: "hidden" };
    const dividerStyle = { float: 'left', width: '2%', height: '90vh' };

    const buildDesktopSection = () => {
        return (
            <Desktop>
                <div style={leftColumnStyle}>
                    { buildLeftColumn() }
                </div>
                <div style={dividerStyle}>
                    <Divider vertical={true}/>
                </div>
                <div style={rightColumnStyle}>
                    { buildRightColumn() }
                </div>
            </Desktop>
        );
    }

    const buildTabletMobileSection = () => {
        return (
            <TabletOrMobile>
                { buildTabletMobile() }
            </TabletOrMobile>
        );
    }

    return (
        <section style={{ padding: '8px', overflow: 'hidden' }}>
            { buildDesktopSection() }
            { buildTabletMobileSection() }
        </section>
    );
}

export default TwoColumnLayout;