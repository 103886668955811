import React from 'react'
import { Grid, Cell, CardText, Card, CardTitle, CardActions, Button, Divider, SelectionControlGroup } from 'react-md';
import { selectDataItemsFromComponent, selectCurrentOrganization, selectAllOrganizationRoles, selectAllClientViewRoles, selectAllManagementRoles } from '../../selectors/global';
import { parseRoleControls, selectableOrganizationRoles, selectableClientViewRoles, parseUserRoleToString, selectableManagementRoles } from '../../utils/permissions';
import { ORG_ROLE_VIEW_CLIENT, ORG_ROLE_MANAGE_ORG } from '../../constants/roles';
import usePageFramework from '../../hooks/usePageFramework';

const ORGANIZATION_COMPONENT = 'Organizations';

function DetailedOrgTypeCard(props) {
    const { onEdit, onDelete, organizationType } = props;

    const {
        selector,
    } = usePageFramework();

    const currentOrganization = selector(selectCurrentOrganization());
    const organizationRoles = selector(selectAllOrganizationRoles());
    const clientViewRoles = selector(selectAllClientViewRoles());
    const managementRoles = selector(selectAllManagementRoles());
    const organizations = selector(selectDataItemsFromComponent(ORGANIZATION_COMPONENT));

    if (!organizationType) {
        return (<> </>);
    }

    const relatedOrganizations = organizations.filter((x) => x.organizationType === organizationType.orgTypeName);

    const selectableOrgRoles = selectableOrganizationRoles(currentOrganization, organizationRoles, false).filter(x => !x.isExclusive);
    const selectableViewRoles = selectableClientViewRoles(currentOrganization, clientViewRoles).filter(x => !x.isExclusive);
    const selectableManageRoles = selectableManagementRoles(currentOrganization, managementRoles).filter(x => !x.isExclusive);

    const selectedOrgRoles = parseUserRoleToString(organizationType.orgRoles || 0, selectableOrgRoles) || '';
    const selectedViewRoles = parseUserRoleToString(organizationType.clientViewRoles || 0, selectableViewRoles) || '';
    const selectedManageRoles = parseUserRoleToString(organizationType.managementRoles || 0, selectableManageRoles) || '';
    
    const organizationRoleControls = parseRoleControls(selectableOrgRoles);
    const clientViewRoleControls = parseRoleControls(selectableViewRoles);
    const managementRoleControls = parseRoleControls(selectableManageRoles);
    const showingClientControls = organizationType.orgRoles & ORG_ROLE_VIEW_CLIENT ? true : false;
    const showingManagementControls = organizationType.orgRoles & ORG_ROLE_MANAGE_ORG ? true : false;


    return (
        <Card>
            <CardTitle title={organizationType.orgTypeName} subtitle={organizationType.orgTypeDescription}/>
            <CardText>
                <h4>Related Organizations:</h4>
                {
                    relatedOrganizations.length
                        ? relatedOrganizations.map((x) => {
                            return (
                                <p>{x.organization}</p>
                            )
                        })
                        : <p> There are no organizations of this type </p>
                }
            </CardText>
            <CardText>
                <h4>Roles:</h4>
                {
                    <Grid>
                        <Cell size={6}>
                            <SelectionControlGroup
                                value={selectedOrgRoles}
                                label="Available Roles for Organization"
                                type='checkbox'
                                className='full-width-field'
                                controls={organizationRoleControls}
                                disabled
                            />
                        </Cell>
                        <Cell size={6}>
                            {
                                showingClientControls &&
                                    <SelectionControlGroup
                                        value={selectedViewRoles}
                                        label="Client Viewing Role(s) (Must have View Client Role selected)"
                                        type='checkbox'
                                        className='full-width-field'
                                        controls={clientViewRoleControls}
                                        disabled
                                    />
                            }
                        </Cell>
                        <Cell size={6}>
                            {
                                showingManagementControls &&
                                    <SelectionControlGroup
                                        value={selectedManageRoles}
                                        label="Management Role(s) (Must have Manage Organization Role selected)"
                                        type='checkbox'
                                        className='full-width-field'
                                        controls={managementRoleControls}
                                        disabled
                                    />
                            }
                        </Cell>
                    </Grid>
                }
            </CardText>
            <Divider/>
            {
                relatedOrganizations.length
                    ? <CardText><p>You are not able to delete this organization type since existing orgaizations are using this type</p></CardText>
                    : <></>
            }
            <CardActions>
                <Button flat primary onClick={onEdit} disabled={false}> Edit </Button>
                <Button flat primary onClick={() => onDelete(organizationType.id)} disabled={relatedOrganizations.length ? true : false}> Delete </Button>
            </CardActions>
        </Card>
    );
}

export default DetailedOrgTypeCard;