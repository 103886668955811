import React, { useState, useEffect } from 'react'
import useLoadingPageFramework from "../../hooks/useLoadingPageFramework";
import moment from 'moment';
import { CardTitle, CardText, Media, Card, CircularProgress } from 'react-md';
import usePageFramework from '../../hooks/usePageFramework';
import { selectOrganizationId, selectOrganizationName, selectCurrentUser } from '../../selectors/global';
import { timestampConvertToDate } from '../../utils/dateTime';
import { callCloudRunFunction } from '../../utils/firestore';
import Fade from 'react-reveal/Fade';
import useCustomAssets from '../../hooks/useCustomAssets';
import useMediaLoader from '../../hooks/useMediaLoader';

function OrganizationMessageCard() {
    const {
        selector,
    } = usePageFramework();

    const {
        getAsset,
    } = useCustomAssets('InformationCards');

    const {
        imageHasLoaded,
        imageSrc,
    } = useMediaLoader({ id: getAsset('organizationMessageCard'), suppressNotification: true });

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const organizationName = selector(selectOrganizationName());
    const [ organizationMessage, setOrganizationMessage ] = useState({});

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent, buildErrorComponent: () => <></> });

    useEffect(() => {
        if (!organizationId) {
            return;
        }
        
        callCloudRunFunction(currentUser, 'getOrganizationMessage', { organizationId: organizationId }).then((data) => {
            data.createdOn = timestampConvertToDate(data.createdOn);

            setOrganizationMessage(data);
            finishedLoading();
        }).catch((err) => {
            console.log(err);
            errorLoading();
        });
    }, [ organizationId ]);

    function buildPageContent() {
        return (
            <Card className="home-card">
                {
                    !imageHasLoaded
                        ? <CircularProgress centered/>
                        : <Fade>
                            <Media aspectRatio="4-1">
                                <img src={imageSrc} alt="org"/>
                            </Media>
                        </Fade>

                }
                <CardTitle title={`A message from ${organizationName}`} subtitle={`Posted on: ${moment(organizationMessage.createdOn).format("MMM Do YYYY h:mm:ss a")}`}/>
                <CardText>
                    <p>{organizationMessage.message}</p>
                </CardText>
            </Card>
        );
    }

    return renderPageContent();
}

export default OrganizationMessageCard;