import React, { Component } from 'react';
import LayoutApp from './layout';
import BrowserUnsupported from './containers/BrowserUnsupported';

class App extends Component {
    render() {
        const isIE = /*@cc_on!@*/false || !!document.documentMode;

        return (
            <div className="App">
                { isIE ? <BrowserUnsupported/> : <LayoutApp/> }
            </div>
        );
    }
}

export default App;