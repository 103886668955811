import React from 'react';
import useFormValidation from '../../hooks/useFormValidation';
import { Grid, CardText, CardTitle, Cell } from 'react-md';
import ThemedButton from '../../components/Buttons/ThemedButton';
import TextFormField from '../../components/FormFields/TextFormField';
import { validate } from './UpdateTenantThemeSubValidate';
import TableIconButton from '../../components/Buttons/TableIconButton';

function UpdateTenantThemeSubForm(props) {
    const { onSubmit, onSubmitNewProperty, onDeleteSection, formId, documentData } = props;

    const INITIAL_STATE = {};

    Object.keys(documentData).forEach((key) => {
        INITIAL_STATE[key] = JSON.stringify(documentData[key], null, 4);
    });

    const parseSubmissionToSubData = (values) => {
        const subData = {};

        Object.keys(values).forEach((keys) => {
            subData[keys] = JSON.parse(values[keys]);
        });

        return subData;
    }

    const saveSubForm = (values) => {
        const subData = parseSubmissionToSubData(values);
        onSubmit(subData);
    }

    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        removeValue,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, saveSubForm, validate);

    const resetData = () => {
        changeValues(INITIAL_STATE, true);
    }

    const addNewProperty = (values) => {
        handleChange(values.confirmation, '');
    }

    const handleAddNewProperty = () => {
        const propertySectionData = {
            title: `Type the name of the property to create.`,
            function: addNewProperty,
            data: {},
        };

        onSubmitNewProperty(propertySectionData);
    }

    const buildTextFormField = (key) => {
        return (
            <>
                <Cell size={1}>
                    <TableIconButton id={`delete-${key}-property`} label={'delete'} onClick={() => removeValue(key)}/>
                </Cell>
                <Cell size={11}>
                    <TextFormField
                        name={key}
                        label={key}
                        values={values}
                        errors={errors}
                        rows={4}
                        floating={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
            </>
        );
    }

    return (
        <form id={formId} name={formId} onSubmit={handleSubmit}>
            <CardTitle title={formId}>
                <ThemedButton className="md-cell--right" flat primary onClick={onDeleteSection}>Delete section</ThemedButton>
                <ThemedButton className="md-cell--right" flat primary onClick={resetData}>Reset</ThemedButton>
                <ThemedButton className="md-cell--right" flat primary onClick={handleAddNewProperty}>Add new property</ThemedButton>
                <ThemedButton className="md-cell--right" flat primary swapTheming type="submit" disabled={isSubmitting}>Save</ThemedButton>
            </CardTitle>
            <CardText>
                <Grid>
                    { Object.keys(values).map(buildTextFormField) }
                </Grid>
            </CardText>
        </form>
    )
}

export default UpdateTenantThemeSubForm;