import React, { useEffect, useState } from 'react';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { selectCurrentUser, selectOrganizationId } from '../../selectors/global';
import { callCloudRunFunction } from '../../utils/firestore';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants/notifications';
import { uploadFile } from '../../utils/fileUpload';
import UpdateAdvancedThemeFrom from './UpdateAdvancedThemeForm';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';
import GeneralConfirmationForm from '../../components/Forms/GeneralConfirmationForm';

function SystemTheme() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());

    const [ pendingSystemTheme, setPendingSystemTheme ] = useState(null);
    
    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent, buildPageContent });

    useEffect(() => {
        finishedLoading();
    }, []);

    const updateSystemThemePopup = {
        FormComponent: GeneralConfirmationForm,
        onSubmit: () => saveForm(pendingSystemTheme),
        onHide: () => setPendingSystemTheme(null),
        popupData: { title: `Type \'${'Confirm'}\' to update the default theme.`, formId: 'update-system-theme-form' },
        formData: { formId: 'update-system-theme-form', initialValues: { values: 'Confirm' } },
    };

    const {
        showPopup: showUpdateSystemThemePopup,
        hidePopup: hideUpdateSystemThemePopup,
        renderPopupForm: renderUpdateSystemThemePopupForm,
    } = usePopupFormBuilder(updateSystemThemePopup);

    const initiateConfirmation = (themeData) => {
        setPendingSystemTheme(themeData);
        showUpdateSystemThemePopup();
    }

    const saveForm = (themeData) => {
        console.log('here for saving theme');
        if (!themeData) {
            showToastNotification('An error has occurred', NOTIFICATION_ERROR);
            return;
        }

        const { useDefaultLogo, currentCompanyLogoUploadId, updatedCompanyLogo } = themeData;
        delete themeData['useDefaultLogo'];
        delete themeData['currentCompanyLogoUploadId'];
        delete themeData['updatedCompanyLogo'];

        const themeMetadata = {
            organizationId: 'Default',
        };

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Branding',
            action: 'update system theme',
            metadata: {...themeData},
        };

        showToastNotification(`Updating theme...`, NOTIFICATION_INFO);

        const promises = [];
        
        if (updatedCompanyLogo) {
            // Save new logo, delete current one if exists
            const basePath = `Organizations/${'Default'}/theme`;
    
            promises.push(uploadFile(updatedCompanyLogo, basePath, currentUser, 'Company Logo', []).then((uploadId) => {
                themeData.companyLogoUploadId = uploadId;
                log.metadata.companyLogoUploadId = uploadId;
            }));
    
            // Delete current company logo if exists
            if (currentCompanyLogoUploadId) {
                promises.push(callCloudRunFunction(currentUser, 'deleteUpload', { uid: currentCompanyLogoUploadId }));
            }
        }

        // Else; no logo changes
        
        Promise.all(promises).then(() => {
            const themeDocument = Object.assign({}, themeData, themeMetadata);
            return callCloudRunFunction(currentUser, 'updateOrganizationTheme', themeDocument);
        }).then(() => {
            showToastNotification('Sucessfully updated theme', NOTIFICATION_SUCCESS);
            callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Failed to update theme', NOTIFICATION_ERROR);
        });
    }

    function buildPageContent() {
        return (
            <>
                <b className="inactive-text"> You are currently changing the default system theme. Updates to the theme here will reflect across the entire Professional Tool. </b>
                <UpdateAdvancedThemeFrom onSubmit={initiateConfirmation}/>
            </>
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderUpdateSystemThemePopupForm() }
        </>
    );
}

export default SystemTheme;