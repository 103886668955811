import React from 'react';
import { INTERACTION_TYPE_PARENTING_PLAN, INTERACTION_TYPE_AGREEMENT } from '../../constants/index';
import AgreementInteractionCard from './agreementCard';
import ParentingPlanInteractionCard from './parentingPlanCard';
// import HolidayInteractionCard from './holidayPicksCard';
// import YesNoInteractionCard from './yesNoCard';

const cards = [
    { displayType: INTERACTION_TYPE_AGREEMENT, card: AgreementInteractionCard },
    { displayType: INTERACTION_TYPE_PARENTING_PLAN, card: ParentingPlanInteractionCard },
    // { displayType: 'HolidayPick', card: HolidayInteractionCard },
    // { displayType: 'YesNo', card: YesNoInteractionCard },
]

const InteractionCard = ({ messages, onDeleteInteraction, onOverrideToAgree, onReset, onModify }) => {
    const messageAttributes = messages.attributes ? messages.attributes : null;
    const { displayType } = messageAttributes;
    
    const { agreementValue,
        requiredResponseUserProfile,
        response,
        responses,
    } = messageAttributes;

    // Calculate user responses
    const messageResponse = response.split('|');
    const isAnyDisagree = messageResponse.find(x => !x.toLowerCase().includes(` ${agreementValue}`)) ? true : false;
    const isAllUserResponded = isAnyDisagree ? true : requiredResponseUserProfile ? requiredResponseUserProfile.map((item, key) => {
        const compareTo = ((typeof item === 'object') && (item !== null)) ? item.id : item;
        const returnedItem = responses.find(x => x.responseFrom === compareTo);
        return returnedItem && returnedItem.response && returnedItem.response.length ? true: false;
    }).reduce((sum, next) => sum && next, true) : false;
    
    let isAllAgree = isAnyDisagree ? false : null;

    if (responses && responses.length && isAllUserResponded && isAllAgree === null) {
        if (!isAllAgree) {
            isAllAgree = responses.map(item => item.response === agreementValue).reduce((sum, next) => sum && next, true);
        }
    }

    const cardAttributes = {
        messageAttributes,  // Attributes of agreement
        isAnyDisagree, isAllUserResponded, isAllAgree, // coParent responses
        onDeleteInteraction, onOverrideToAgree, onReset, onModify // Agreement Action Functions
    };
    
    const DisplayCard = cards.find(x => x.displayType === displayType).card;
    return (
        <DisplayCard {...cardAttributes} />
    );
};

export default InteractionCard;
