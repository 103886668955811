import React from 'react';
import { Button, Cell, Grid } from 'react-md';
import { Field, Form, reduxForm } from 'redux-form/immutable';
import { required, emailValidate, phoneValidate, organization } from '../../components/RequestFields/renderToolboxField';
import renderTextField from '../../components/RequestFields/renderTextField';
import renderSelectField from '../../components/RequestFields/renderSelectField';
import { ALL_DIVISIONS, COUNTRIES } from '../../constants/lookupInfo';

const checkCode = (value) => value && !/(^\d{5}$)|(^\d{5}-\d{4}$)|(^[A-Za-z]\d[A-Za-z][\s]?\d[A-Za-z]\d$)/.test(value) ? "Invalid ZIP/Postal Code" : undefined;

class OrganizationDetailsForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            defaultCountry: undefined,
            defaultDivision: undefined,
            divisions: ALL_DIVISIONS
        }
    }

    componentDidMount() {
        this.updateState();
    }

    handleCountryChange = (evt, newVal, oldVal, name) => {
        this.updateState(newVal);
    }

    hideForm = () => {
        const { onHide } = this.props;
        this.props.reset();
        onHide();
    }

    updateState = (country = undefined) => {
        const { initialValues } = this.props;
        const currentCountry = country || initialValues.get('country');
        const mapping = COUNTRIES.find(x => x.id === currentCountry || x.name === currentCountry);
        const currentDivision = initialValues.get('state');
        const defaultDivision = mapping.divisions.find(x => x.id === currentDivision || x.name === currentDivision);

        this.setState({
            defaultCountry: country ? undefined : mapping.id,
            defaultDivision: country ? undefined: defaultDivision.id,
            divisions: mapping.divisions
        });
    }

    render() {
        const { handleSubmit } = this.props;
        const { divisions, defaultCountry, defaultDivision } = this.state;
        const fieldStyle = { width: "100%" };

        return (
            <Form onSubmit={handleSubmit}>
                <Grid>
                    <Cell size={12}>
                        <Field
                            id='org-name'
                            name='organization'
                            label="Organization"
                            customSize="title"
                            type='text'
                            component={renderTextField}
                            validate={[required, organization]}
                        />
                    </Cell>
                    <Cell size={4}>
                        <Field
                            id={'org-country'}
                            name='country'
                            label="Country"
                            defaultValue={defaultCountry}
                            menuItems={COUNTRIES}
                            itemLabel="name"
                            itemValue="id"
                            style={fieldStyle}
                            component={renderSelectField}
                            validate={[required]}
                            onChange={this.handleCountryChange}
                        />
                    </Cell>
                    <Cell size={4}>
                        <Field
                            id={'org-state-prov'}
                            name='state'
                            label="State/Province"
                            defaultValue={defaultDivision}
                            menuItems={divisions}
                            itemLabel="name"
                            itemValue="id"
                            style={fieldStyle}
                            component={renderSelectField}
                            validate={[required]}
                        />
                    </Cell>
                    <Cell size={4}>
                        <Field
                            id={'org-city'}
                            name='city'
                            label="City"
                            style={fieldStyle}
                            component={renderTextField}
                            validate={[required]}
                        />
                    </Cell>
                    <Cell size={4}>
                        <Field
                            id={'org-zip'}
                            name='zip'
                            label="ZIP/Postal Code"
                            style={fieldStyle}
                            component={renderTextField}
                            validate={[required, checkCode]}
                        />
                    </Cell>
                    <Cell size={4}>
                        <Field
                            id={'org-county'}
                            name='county'
                            label="County"
                            style={fieldStyle}
                            component={renderTextField}
                        />
                    </Cell>
                    <Cell size={4}>
                    </Cell>
                    <Cell size={4}>
                        <Field
                            id={'org-contact'}
                            name='primaryContact'
                            label="Primary Contact"
                            style={fieldStyle}
                            component={renderTextField}
                            validate={[required]}
                        />
                    </Cell>
                    <Cell size={4}>
                        <Field
                            id={'org-email'}
                            name='email'
                            label="Email Address"
                            style={fieldStyle}
                            component={renderTextField}
                            validate={[required, emailValidate]}
                        />
                    </Cell>
                    <Cell size={4}>
                        <Field
                            id={'org-phone'}
                            name='phone'
                            label="Phone Number"
                            style={fieldStyle}
                            component={renderTextField}
                            validate={[required, phoneValidate]}
                        />
                    </Cell>
                    <Cell desktopSize={12} tabletSize={12} phoneSize={12} className="md-text-center">
                        <Button flat primary swapTheming type="submit" className="half-width-field">Save</Button>
                    </Cell>
                </Grid>
            </Form>
        );
    }
}

export default reduxForm({
    form: 'organizationDetailsForm',
    enableReinitialize: true
})(OrganizationDetailsForm);
