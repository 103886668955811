import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import ReassignMediationForm from '../../components/MediationReassignment/ReassignMediationForm';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';
import usePageFramework from '../../hooks/usePageFramework';
import { selectCurrentUser, selectOrganizationId } from '../../selectors/global';
import { ORG_ROLE_MEDIATION, ORG_ROLE_SUPER_ADMIN } from '../../constants/roles';

// Wrapper solution to use React Hooks within React Components
function MediationAssignmentDialog(props) {
    const { visible, mediationData, onSubmit, onHide } = props;

    const {
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    
    const [ controlMediators, setControlMediators ] = useState([]);

    const reassignMediationPopup = {
        FormComponent: ReassignMediationForm,
        onSubmit: handleSubmitAssignment,
        onHide: onHide,
        popupData: { title: `Re-assign Mediation from ${mediationData.displayName}`, formId: 'reassign-mediation-form' },
        formData: { formId: 'reassign-mediation-form', initialValues: mediationData, controlMediators: controlMediators },
    };

    const {
        showPopup,
        hidePopup,
        renderPopupForm,
    } = usePopupFormBuilder(reassignMediationPopup);

    useEffect(() => {
        const db = firebase.firestore();

        db.collection('ProApprovedProfessionals').where('organizationId', '==', organizationId).where('isActive', '==', true).onSnapshot((snapshot) => {
            const controlMediators = snapshot.docs.reduce((prev, curr) => {
                const mediator = curr.data();

                if (mediator.roles && mediator.roles.orgRoles) {
                    const mediatorOrgRoles = mediator.roles.orgRoles;

                    if (mediatorOrgRoles & ORG_ROLE_MEDIATION || mediatorOrgRoles & ORG_ROLE_SUPER_ADMIN) {
                        mediator.id = curr.id;
                        
                        const control = { label: mediator.displayName, value: mediator.id };
                        prev.push(control);
                    }
                }

                return prev;
            }, []);

            setControlMediators(controlMediators);
        })
    }, []);

    useEffect(() => {
        if (visible) {
            showPopup();
        } else {
            hidePopup();
        }
    }, [ visible ]);

    function handleSubmitAssignment(values) {
        onSubmit(values);
    }

    return (
        <>
            { renderPopupForm() }
        </>
    );
}


export default MediationAssignmentDialog;