
import React from 'react';
import { Grid, Cell, Button, Divider } from 'react-md';
import useFormValidation from '../../hooks/useFormValidation';
import TextFormField from '../../components/FormFields/TextFormField';
import FileFormField from '../../components/FormFields/FileFormField';
import { MediaPreviewArrayFromUploadId } from '../../components/MediaPreviewCard';
import { validate } from './UpdateEntryValidate';
import CheckboxFormField from '../../components/FormFields/CheckboxFormField';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';

function UpdateEntryForm(props) {
    const { onSubmit, onHide, initialValues, selectableOrgCategories = [], selectableChildrenCategories = [], selectableCoParentCategories = [] } = props;

    const INITIAL_STATE = {
        id: initialValues.id,
        caseNumber: initialValues.caseNumber || '',
        title: initialValues.title || '',
        notes: initialValues.notes || '',
        oldUploads: initialValues.uploads || [],
        deletedUploads: [],
        newUploads: [],
        organizationCategories: initialValues.organizationCategories ? initialValues.organizationCategories.join(',') : '',
        childrenCategories: initialValues.childrenCategories ? initialValues.childrenCategories.join(',') : '',
        coParentCategories: initialValues.coParentCategories ? initialValues.coParentCategories.join(',') : '',
    };

    const checkboxFormConverter = (x) => {
        return {
            label: x,
            value: x,
        };
    }

    // The entry document could contain categories which have either; not been loaded yet or will not be loaded (deleted categories).
    // In this case, include the entries which are part of the entry document but is one of the above.
    const unknownOrgCategories = initialValues.organizationCategories ? initialValues.organizationCategories.filter((x) => !selectableOrgCategories.find(category => category.value === x)).map(checkboxFormConverter) : [];
    const unknownChildrenCategories = initialValues.childrenCategories ? initialValues.childrenCategories.filter((x) => !selectableChildrenCategories.find(category => category.value === x)).map(checkboxFormConverter) : [];
    const unknownCoParentCategories = initialValues.coParentCategories ? initialValues.coParentCategories.filter((x) => !selectableCoParentCategories.find(category => category.value === x)).map(checkboxFormConverter) : [];

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();
    
    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, updateEntry, validate);
    
    function updateEntry(values) {
        const entry = parseSubmissionToEntry(values);
        onSubmit(entry);
    }

    function parseSubmissionToEntry(values) {
        const entry = {
            id: values.id,
            caseNumber: values.caseNumber.trim(),
            title: values.title.trim(),
            notes: values.notes.trim(),
            oldUploads: values.oldUploads || [],
            deletedUploads: values.deletedUploads || [],
            newUploads: values.newUploads || [],
            organizationCategories: values.organizationCategories ? values.organizationCategories.split(',') : [],
            childrenCategories: values.childrenCategories ? values.childrenCategories.split(',') : [],
            coParentCategories: values.coParentCategories ? values.coParentCategories.split(',') : [],
        };

        return entry;
    }

    function removeFileFromIndex (index) {
        const files = [...values.oldUploads];
        const deletedFile = files.splice(index, 1);
        const deletedFiles = [...values.deletedUploads, ...deletedFile];

        changeValues({ oldUploads: files, deletedUploads: deletedFiles });
    }

    function buildPreviewButtons(uploadId, index) {
        const buttons = [
            <ThemedButton key={`remove-button-${uploadId}`} className="md-cell--right" icon onClick={() => removeFileFromIndex(index)}>delete</ThemedButton>
        ];

        return buttons;
    }

    function buildPreviewWrapper(preview, key) {
        return (
            <Cell key={key} desktopSize={4} tabletSize={4} phoneSize={2}>
                { preview }
            </Cell>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="caseNumber"
                        label={getDataLabel('caseNumberData')}
                        values={values}
                        errors={errors}
                        customSize="title"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="title"
                        label={getDataLabel('titleData')}
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="notes"
                        label={getDataLabel('notesData')}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Divider/>
                    { <MediaPreviewArrayFromUploadId mediaArray={values.oldUploads} actionBuilder={buildPreviewButtons} wrapper={buildPreviewWrapper}/> }
                <Divider/>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <FileFormField
                        name="newUploads"
                        label={getDataLabel('addItemsData')}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={2} phoneSize={1}>
                    <CheckboxFormField
                        name="organizationCategories"
                        label={getDataLabel('generalCategoriesData')}
                        values={values}
                        errors={errors}
                        controls={[...selectableOrgCategories, ...unknownOrgCategories]}
                        showSelectAllButton={false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletOffset={1} tabletSize={2} phoneSize={1}>
                    <CheckboxFormField
                        name="childrenCategories"
                        label={getDataLabel('childrenCategoriesData')}
                        values={values}
                        errors={errors}
                        controls={[...selectableChildrenCategories, ...unknownChildrenCategories]}
                        showSelectAllButton={false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletOffset={1} tabletSize={2} phoneSize={1}>
                    <CheckboxFormField
                        name="coParentCategories"
                        label={getDataLabel('coParentCategoriesData')}
                        values={values}
                        errors={errors}
                        controls={[...selectableCoParentCategories, ...unknownCoParentCategories]}
                        showSelectAllButton={false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>Update Entry</ThemedButton>
                </Cell>
            </Grid>
        </form>
    );
}

export default UpdateEntryForm;