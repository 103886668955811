import { phoneValidate } from "../../components/RequestFields/renderToolboxField";

function validate(values) {
    let errors = {};
    
    if (!values.sms) {
        errors.sms = "SMS is Required";
    } else if (phoneValidate(values.sms)) {
        errors.sms = phoneValidate(values.sms);
    }
    
    if (!values.displayName) {
        errors.firstName = "Name is required";
    }

    return errors;
}

export {
    validate,
};