import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable'
import globalReducer from './global';

console.log('calling reducer setup');

export default function createReducer(asyncReducers) {
    return combineReducers({
        form: formReducer,
        global: globalReducer,
        ...asyncReducers,
    });
}

