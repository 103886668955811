import React from 'react';
import { SelectField } from 'react-md';

const renderSelectField = ({ input, meta: { touched, invalid, error, dirty }, ...props }) => (
    <SelectField
        {...input}
        {...props}
        onChange={input.onChange}
        error={ (touched && invalid) || (error !== undefined && dirty) }
        errorText={error}
    />
);

export default renderSelectField;
