import React from 'react';
import { FontIcon } from 'react-md';

function DefaultLoadedFramework() {
    const centerStyle = { height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'};
    const alignmentStyle = { display:'grid' };
    const iconStyle = { fontSize: '48px', color: '#f3e51a' };

    return(
        <div style={centerStyle}>
            <div style={alignmentStyle}>
                <FontIcon style={iconStyle}>warning</FontIcon>
                <p>Looks like there is nothing here. Please try again later</p>
            </div>
        </div>
    );
}

export default DefaultLoadedFramework;