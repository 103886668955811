import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { Cell, Button } from 'react-md';
import { required } from '../../components/RequestFields/renderToolboxField';
import renderTextField from '../../components/RequestFields/renderTextField';

class AgreementForm extends PureComponent {
    constructor(props) {
        super(props);
        const { agreementType, agreement } = this.props;

        this.props.change('agreementType', agreementType);
        this.props.change('agreement', agreement);
    }

    render() {
        const { error, submitting, pristine, handleSubmit } = this.props;
        const fieldStyle = { width: '100%'};
        const formStyle = { paddingLeft: '25px', paddingRight: '25px', marginTop: '80px' };

        return (
            <form onSubmit={handleSubmit} style={formStyle} className="md-grid">
                <Cell size={12}>
                    <Field
                        id={`agreementType`}
                        name={`agreementType`}
                        customSize="title"
                        label="Agreement Type"
                        component={renderTextField}
                        style={fieldStyle}
                        validate={[required]}
                    />
                </Cell>
                <Cell size={12}>
                    <Field
                        id={`agreement`}
                        name={`agreement`}
                        label="Agreement"
                        placeholder=""
                        rows={4}
                        maxRows={25}
                        style={fieldStyle}
                        component={renderTextField}
                        validate={[required]}
                    />
                </Cell>
                {error && <strong>{error}</strong>}
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <Button className="full-width-field" disabled={pristine || submitting} type="submit" flat primary swapTheming>Submit</Button>
                </Cell>
            </form>
        );
    }
};

export default reduxForm({
    form: 'AgreementForm',
    fields: [ 'agreementType',  'agreement' ],
    enableReinitialize: true,
})(AgreementForm);
