import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Participant from '../Participant';
import useParticipants from '../../hooks/useParticipants';
import * as REDUX from '../../../../constants/redux';

export default function ParticipantStrip() {
    const room = useSelector(state => state.get('global').get('room'));
    const selectedParticipant = useSelector(state => state.get('global').get('selectedParticipant'));
    const dispatch = useDispatch();
    const participants = useParticipants();

    return (
        <>
            <Participant
                participant={room.localParticipant}
                isSelected={selectedParticipant === room.localParticipant}
                onClick={() => {
                    dispatch({ type: REDUX.SELECTED_PARTICIPANT, payload: room.localParticipant });
                    console.log('clicked');
                }}
            />
            {participants.map(participant => (
                <Participant
                    key={participant.sid}
                    participant={participant}
                    isSelected={selectedParticipant === participant}
                    onClick={() => dispatch({ type: REDUX.SELECTED_PARTICIPANT, payload: participant })}
                />
            ))}
        </>
    );
}
