import React, { useState } from 'react';
import { Button, Grid, Cell } from "react-md";
import SelectionFormField from '../components/FormFields/SelectionFormField';
import { sortDataItems } from '../utils/sortFilter';

function useSorter(columns) {
    const initialColumn = columns.find(x => x.isPrimary || false) || columns[0];
    
    const INITIAL_STATE = {
        sortColumn: initialColumn.value,
        ascending: false,
    };

    const [sorter, setSorter] = useState(INITIAL_STATE);
    
    const resetSorterToDefault = () => {
        setSorter(INITIAL_STATE);
    }

    function handleChange(name, value) {
        changeSorterValues({[name]: value});
    }

    function changeSorterValues(changedSorters) {
        const newValues = Object.assign({}, sorter, changedSorters);
        setSorter(newValues);
    }

    const changeSortDirection = () => {
        handleChange('ascending', !sorter.ascending);
    }

    const sortItems = (items) => {
        const sortingColumn = columns.find(x => x.value === sorter.sortColumn);
        return sortDataItems(items, sortingColumn, sorter.ascending);
    }

    const renderSorterBar = () => {
        return (
            <Grid>
                <Cell desktopSize={11} tabletSize={6} phoneSize={3} style={{margin: '0px'}}>
                    <SelectionFormField
                        name='sortColumn'
                        label='Sorting items On'
                        menuItems={columns}
                        itemLabel='label'
                        itemValue='value'
                        values={sorter}
                        errors={{}}
                        onChange={handleChange}
                    />
                </Cell>
                <Cell desktopSize={1} tabletSize={2} phoneSize={1} style={{margin: '0px'}}>
                    <>
                        <p>{sorter.ascending ? 'Ascending' : 'Descending'}</p>
                        <Button onClick={changeSortDirection} icon>{ sorter.ascending ? 'arrow_upward' : 'arrow_downward' }</Button>
                    </>
                </Cell>
            </Grid>
        )
    }

    return {
        resetSorterToDefault,
        sortItems,
        renderSorterBar,
    };
}

export default useSorter;