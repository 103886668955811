import React from 'react';
import moment from 'moment';
import { Card, CardTitle, CardActions, CardText, Media, CircularProgress } from 'react-md';
import TableIconButton from '../Buttons/TableIconButton';
import ThemedButton from '../Buttons/ThemedButton';
import useCustomAssets from '../../hooks/useCustomAssets';
import useMediaLoader from '../../hooks/useMediaLoader';
import Fade from 'react-reveal/Fade';

const style = { marginTop: '20px', marginBottom: '10px', minHeight: '200px', minWidth: '400px' };

const NewGetHelpCard = (props) => {
    const { category, acceptDeclineClicked, id, createdOn, members, invitedCoParent, detailsClick, assignedProfessionalName, isAssignedProfessional, isSuper, reassignClick } = props;

    const {
        getAsset,
    } = useCustomAssets('InformationCards');

    const categories = [
        { category: 'mediation', title: 'Mediation', img: 'wizMediation.png', assetKey: 'infoMediationCard' },
        { category: 'Parenting Plan', title: 'Parenting Plan', img: 'wizParentingPlan.png', assetKey: 'infoParentingCard' },
        { category: 'Coaching', title: 'Coaching', img: 'wizCoaching2.png', assetKey: 'infoCoachingCard' },
        { category: 'Covid', title: 'Covid Question', img: 'wizEvent.png', assetKey: 'infoEventCard' },
    ];

    const categoryFound = categories.find(x => x.category === category);

    const {
        imageHasLoaded,
        imageSrc,
    } = useMediaLoader({ id: getAsset(categoryFound && categoryFound.assetKey), suppressNotification: true });

    const subTitle = (
        <div>
            <div>{`${categoryFound.title} - ${moment((createdOn instanceof Date) ? createdOn : createdOn.toDate()).calendar()}`}</div>
            <div>{`Assigned Professional: ${assignedProfessionalName}`}</div>
        </div>
    );
    
    const primary = members.find(x => x.isPrimary);
    const primaryContact = primary ? `${primary.firstName} ${primary.lastName}`: '';
    const invitedCoParentText = invitedCoParent ? 'Invited CoParent' : <br/>;
    const invited = <h5 id={`invitedStatus-${id}`} align='right'><b>{ invitedCoParentText }</b></h5>
    const detailsButton = <TableIconButton id={`details-${id}`} label='info' onClick={detailsClick}/>
    const reassignButton = <TableIconButton id={`assign-${id}`} label='assignment_ind' onClick={reassignClick}/>
    
    return (
        <Card style={style} className="md-block-centered">
            {
                !imageHasLoaded
                    ? <CircularProgress centered/>
                    : <Media id="details-dialog-image" aspectRatio="4-1">
                        <Fade>
                            <img src={imageSrc} alt="org"/>
                        </Fade>
                    </Media>
            }
            <CardTitle title={primaryContact} subtitle={subTitle}>
                { detailsClick && detailsButton }
                { (isAssignedProfessional || isSuper) && reassignClick && reassignButton }
            </CardTitle>
            <CardText>
                { invited }
            </CardText>
            <CardActions>
                <ThemedButton flat primary swapTheming id={`accept-${id}`} onClick={acceptDeclineClicked}>Accept</ThemedButton>
                <ThemedButton flat primary id={`decline-${id}`} onClick={acceptDeclineClicked}>Decline</ThemedButton>
            </CardActions>
        </Card>
    );
};

export default NewGetHelpCard;
