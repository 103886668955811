import React from 'react';
import { GridList, Cell } from 'react-md';


const AcceptRejectGrid = ({ status, dataItems, card, acceptReject, emptyCard, detailsClick, reassignClick }) => {
    if (!dataItems) return null;

    const DataCard = card;
    const filtered = dataItems.filter(x => x.status === status);

    if (!filtered || !filtered.length) {
        return emptyCard ? emptyCard() : null;
    }
    
    return (
        <GridList container="pictures" desktopSize={6} size={6} tabletSize={12} phoneSize={12} component="section" position="right" align="top">
            {filtered.sort((a, b) => b.createdOn - a.createdOn).map((item) => {
                item.acceptDeclineClicked = acceptReject;
                return (
                    <Cell key={item.id} size={6} phoneSize={12} tabletSize={12}>
                        <DataCard { ...item} key={item.createdOn} detailsClick={detailsClick} reassignClick={reassignClick} />
                    </Cell>
                );
            })}
        </GridList>
    );
};

export default AcceptRejectGrid;
