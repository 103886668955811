import React from 'react';
import ThemedButton from './ThemedButton';

function TableIconButton(props) {
    const { id, label, disabled, onClick } = props;

    const clickedItem = () => {
        onClick(id.split('-')[1]);
    }

    return (
        <ThemedButton id={id} icon disabled={disabled || false} primary onClick={clickedItem}>
            { label }
        </ThemedButton>
    );
}

export default TableIconButton;