import React from 'react';
import { formatPhone } from '../../components/RequestFields/formatPhone';
import useFormValidation from '../../hooks/useFormValidation';
import { normalizePhone } from '../../components/RequestFields/normalizePhone';
import { Grid, Cell, FontIcon, Button } from 'react-md';
import ProfilePictureFormField from '../../components/FormFields/ProfilePictureFormField';
import TextFormField from '../../components/FormFields/TextFormField';
import { getInitials } from '../../utils/strings';
import { validate } from './UpdateProfileValidate';
import ThemedButton from '../../components/Buttons/ThemedButton';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';

function UpdateProfileForm(props) {
    const { onSubmit, initialValues } = props;
    
    const INITIAL_STATE = {
        uid: initialValues.uid,
        displayName: initialValues.displayName,
        sms: initialValues.sms ? formatPhone(initialValues.sms) : '',
        updatedProfilePicture: null,
        email: initialValues.email,
    };

    const centeredStyle = { display: 'flex', justifyContent: 'center', alignItems:'center' };

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, updateProfile, validate);

    function updateProfile(values) {
        const professionalProfile = parseSubmissionToProfessional(values);
        onSubmit(professionalProfile);
    }

    function parseSubmissionToProfessional(values) {
        const professionalProfile = {
            id: values.uid,
            uid: values.uid,
            displayName: values.displayName,
            updatedProfilePicture: values.updatedProfilePicture,
            sms: normalizePhone(values.sms),
        };

        return professionalProfile;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4} style={centeredStyle}>
                    <ProfilePictureFormField
                        name={'updatedProfilePicture'}
                        values={values}
                        errors={errors}
                        innerText={getInitials(values.displayName)}
                        currentPicture={initialValues.photoUrl}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="displayName"
                        label={getDataLabel('proNameData')}
                        values={values}
                        errors={errors}
                        placeholder="Name"
                        customSize="title"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <TextFormField
                        name="email"
                        label={getDataLabel('emailData')}
                        values={initialValues}
                        errors={errors}
                        placeholder="Email"
                        disabled
                        leftIcon={<FontIcon>email</FontIcon>}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <TextFormField
                        name="sms"
                        label={getDataLabel('phoneData')}
                        values={values}
                        errors={errors}
                        placeholder="SMS Number (Text)"
                        leftIcon={<FontIcon>phone</FontIcon>}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton flat swapTheming type="submit" disabled={isSubmitting} className='full-width-field'>Update</ThemedButton>
                </Cell>
            </Grid>
        </form>
    );
}

export default UpdateProfileForm;