import React, { useState, useEffect } from 'react'
import * as firebase from 'firebase';
import { useLocation } from "react-router-dom";
import { selectOrganizationId, selectCurrentUser } from '../../selectors/global';
import { setToolbarButtons } from '../../actions/global';
import { NAV_DEFAULT } from '../../constants/navigation';
import CaseSearchForm from './CaseSearchForm';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { INITIAL, NO_RESULTS, FILTER_DATE } from '../../constants';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants/notifications';
import RepositoryCard from '../../components/RepositoryCard';
import { Cell, Grid } from 'react-md';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import DetailedRepositoryCard from '../ClientRepository/DetailedRepositoryCard';
import DetailedClientCard from '../../components/DetailedClientCard';
import useMediaViewer from '../../hooks/useMediaViewer';
import { sortDataItems } from '../../utils/sortFilter';
import { callCloudRunFunction } from '../../utils/firestore';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import TwoColumnLayout from '../../pageLayouts/TwoColumnLayout';
import ClientCard from '../../components/ClientCard';
import usePageFramework from '../../hooks/usePageFramework';
import { useCustomPageFrameworkLabels } from '../../hooks/useCustomLabels';
import useCustomAssets from '../../hooks/useCustomAssets';

function CaseSearch() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const initialCaseNumber = useLocation().hash.substring(1);
    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    
    const [ caseSearch, setCaseSearch ] = useState(null);
    const [ focusedItemId, setFocusedItemId ] = useState(null);
    const [ caseSearchResults, setCaseSearchResults ] = useState(null);
    const [ userSearchHistory, setUserSearchHistory ] = useState(null);

    const {
        startLoading,
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    const labelVariableData = {
        caseNumber: caseSearch || '',
    };

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('CaseSearch', labelVariableData);
    
    useEffect(() => {
        dispatch(setToolbarButtons([]));

        var dataStreamCaseHistory = null;

        if (organizationId === false) {
            return;
        }

        const db = firebase.firestore();
        
        dataStreamCaseHistory = db.collection('ProApprovedProfessionals').doc(currentUser.uid).collection('CaseSearchHistory').where('organizationId', '==', organizationId).onSnapshot((querySnapshot) => {
            const dataItems = querySnapshot.docs.map((doc) => {
                const searchHistoryData = doc.data();

                searchHistoryData.id = doc.id;
                searchHistoryData.createdOn = searchHistoryData.createdOn ? searchHistoryData.createdOn.toDate() : null;
                return searchHistoryData;
            });
            
            const sortedItems = sortDataItems(dataItems, {type: FILTER_DATE, value: 'createdOn'}, false);

            const searchHistory = [];

            // Remove duplicate case number searches
            sortedItems.forEach((item) => {
                if (searchHistory.find((x) => x.caseNumberSearched === item.caseNumberSearched)) {
                    return;
                }
                searchHistory.push(item);
            })

            setUserSearchHistory(searchHistory);
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Case Search',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        finishedLoading();
        return () => {
            if (dataStreamCaseHistory) {
                dataStreamCaseHistory();
            }
        }
    }, []);
    
    const {
        showDialog: showMediaViewDialog,
        hideDialog: hideMediaViewDialog,
        renderMediaViewer,
    } = useMediaViewer({});
    
    var foundItem = null;
    
    if (focusedItemId && caseSearchResults) {
        foundItem = caseSearchResults[focusedItemId.type].find(x => x.uid === focusedItemId.id);
    }

    if (initialCaseNumber && !caseSearch) {
        initiateCaseSearch({ caseNumber: initialCaseNumber });
    }
    
    if (organizationId === false || currentUser === null) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }
    
    function initiateCaseSearch(searchValues) {
        const searchMetadata = {
            organizationId: organizationId,
            userId: currentUser.uid,
        };

        const searchData = Object.assign({}, searchValues, searchMetadata);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Case Search',
            action: 'case number search',
            searchParameters: { caseNumber: searchValues.caseNumber },
        };

        showToastNotification(`Starting search by case number: ${searchValues.caseNumber}`, NOTIFICATION_INFO);
        setCaseSearch(searchValues.caseNumber);
        startLoading();

        const promises = [
            callCloudRunFunction(currentUser, 'searchDocsByCaseNumber', searchData),
            callCloudRunFunction(currentUser, 'createSearchCaseHistory', log),
            callCloudRunFunction(currentUser, 'logProUserAction', log),
        ];

        Promise.all(promises).then(([results]) => {
            setCaseSearchResults(results);
            finishedLoading();
        }).catch((err) => {
            console.log(err);
            errorLoading();
        });
    }

    function clearHistory() {
        const history = {
            organizationId: organizationId,
            uid: currentUser.uid,
        };

        showToastNotification('Clearing your search history', NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'clearSearchCaseHistory', history).then(() => {
            showToastNotification('Your history has been cleared', NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err)
            showToastNotification('Your history has failed to be cleared', NOTIFICATION_ERROR);
        });
    }

    const buildForm = () => {
        return (
            <Cell size={12}>
                <CaseSearchForm onSubmit={initiateCaseSearch} clearHistory={clearHistory} caseSearchHistory={userSearchHistory}/>
            </Cell>
        );
    }

    const checkForNoResults = () => {
        return Object.entries(caseSearchResults).map(([ key, value ]) => value.length === 0).reduce((prev, curr) => prev && curr, true);
    }

    const buildEmptyCard = (status) => {
        const cardsInfo = [
            { status: INITIAL, cardMediaUrl: getAsset('caseSearchInitial'), headerTitle: getLabel('initialPageHeader'), subTitle: getLabel('initialPageSubheader'), learnMoreLink: false  },
            { status: NO_RESULTS, cardMediaUrl: getAsset('caseSearchEmpty'), headerTitle: getLabel('emptyResultsHeader'), subTitle: getLabel('emptyResultsSubHeader'), learnMoreLink: false  },
        ];

        const cardProps = cardsInfo.find(x => x.status === status);
        return (<GettingStartedPaper {...cardProps} key={cardProps.status} />);
    }

    const resultsWrapper = (child) => {
        return (
            <Fade>
                { child }
            </Fade>
        );
    }

    const buildResultsDisplay = ([ collectionName, results ]) => {
        switch (collectionName) {
            case 'ProRepositoryEntries':
                return (
                    results.length === 0
                        ? (<></>)
                        : results.map(data => resultsWrapper(<RepositoryCard key={`${collectionName}-${data.uid}`} onViewClick={(id) => setFocusedItemId({id: id, type: collectionName})} entry={data} />))
                );
            case 'Users':
                return (
                    results.length === 0
                    ? (<></>)
                    : results.map(data => resultsWrapper(<ClientCard key={`${collectionName}-${data.uid}`} onViewClick={(id) => setFocusedItemId({id: id, type: collectionName})} client={data}/>))
                )
            default:
                return (<></>);
        }
    }

    const buildResults = () => {
        return !caseSearchResults
            ? buildEmptyCard(INITIAL)
            : checkForNoResults()
                ? buildEmptyCard(NO_RESULTS)
                : Object.entries(caseSearchResults).map(buildResultsDisplay).flat();
    }

    const buildDetailedItemView = () => {
        if (!foundItem) {
            return (<></>);
        }

        switch(focusedItemId.type) {
            case 'ProRepositoryEntries':
                return ( <DetailedRepositoryCard canUpdate={false} canDelete={false} entry={foundItem} unfocus={() => setFocusedItemId(null)} onView={showMediaViewDialog}/> );
            case 'Users':
                return ( <DetailedClientCard client={foundItem} unfocus={() => setFocusedItemId(null)}/> );
            default:
                return (<></>);
        }
    }

    const buildFoundItem = () => {
        if (!foundItem) {
            return (<></>);
        }

        return (
            <Bounce right>
                { buildDetailedItemView() }
            </Bounce>
        );
    }

    const buildTabletMobileSection = () => {
        return !foundItem
        ? ( buildResults() )
        : ( buildDetailedItemView() );
    }

    function buildPageContent() {
        return (
            <TwoColumnLayout
                buildLeftColumn={buildResults}
                buildRightColumn={buildFoundItem}
                buildTabletMobile={buildTabletMobileSection}
            />
        );
    }

    return (
        <Grid>
            { buildForm() }
            <Cell size={12}>
                { renderPageContent() }
            </Cell>
            { renderMediaViewer() }
        </Grid>
    );
}

export default CaseSearch;