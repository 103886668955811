import React from 'react';
import { Grid, Cell} from 'react-md';
import ParticipantStrip from '../ParticipantStrip';
import MainParticipant from '../MainParticipant';

export default function Room() {
    const gridStyle= { height: '100%' };
    
    return (
        <Grid style={gridStyle}>
            <Cell size={3}>
                <ParticipantStrip />
            </Cell>
            <Cell size={9}>
                <MainParticipant />
            </Cell>
        </Grid>
    );
}
