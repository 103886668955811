import { firestore } from 'firebase';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../constants';

const convertToDateFromFirestoreDate = (timestamp) => {
    if (!timestamp) {
        return null;
    }

    return new firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
}

const timestampConvertToDate = (timestamp) => {
    if (!timestamp) {
        return null;
    }

    return new firestore.Timestamp(timestamp._seconds, timestamp._nanoseconds).toDate();
}

const convertToDate = (time) => {
    if (!time || time instanceof Date && !isNaN(time.valueOf())) return time;

    time = time.toLowerCase();

    const hours = parseInt(time.substr(0, 2));
    
    if (time.indexOf('am') != -1 && hours == 12) {
        time = time.replace('12', '0');
    }

    if (time.indexOf('pm')  != -1 && hours < 12) {
        time = time.replace(hours, (hours + 12));
    }

    const replaced = time.replace(/(am|pm)/, '').split(':');

    return new Date(2018, 1, 1, replaced[0], replaced[1], 0, 0);
}

const convert24To12Hour = (time) => time ? moment(time.trim(), ['hh:mm']).format('LT') : '';

const convert12To24Hour = (time) => {
    time = time.toLowerCase();
    const hours = parseInt(time.substr(0, 2));
    
    if (time.indexOf('am') != -1 && hours == 12) {
        time = time.replace('12', '0');
    }

    if (time.indexOf('pm') != -1 && hours < 12) {
        time = time.replace(hours, (hours + 12));
    }

    return time.replace(/(am|pm)/, '');
}

const convertStringToDate = (string) => string ? moment(string.trim()).format('LL') : '';

const convertToDisplayDate = (date) => {
    if (date && moment(date).isValid()) {
        return moment(date).format(DISPLAY_DATE_FORMAT);
    }

    return null;
}

const getStartOfDay = () => moment().startOf('day').toDate();
const getEndOfDay = () => moment().endOf('day').toDate();

export {
    convertToDateFromFirestoreDate,
    timestampConvertToDate,
    convertToDate,
    convert24To12Hour,
    convert12To24Hour,
    convertStringToDate,
    convertToDisplayDate,
    getStartOfDay,
    getEndOfDay,
};