import React from 'react';

const isExcluded = (detail) => {
    return detail.title === 'Invited' && detail.answer.includes('your self');
}

const DetailsList = ({ details = [] }) => {
    const style={ marginTop: '10px' };

    return details.map((detail) => {
        return !detail.title || !detail.answer || isExcluded(detail) ? null : (
            <div id={`details-list-section-${detail.title}`} key={`details-list-section-${detail.title}`}>
                <h4 style={style}>{ detail.title }</h4>
                <p>{ detail.answer }</p>
            </div>
        );
    });
};

export default DetailsList;