export const formatPhone = (value) => {
    if (!value) {
        return value;
    }

    const numsFromValue = value.replace(/\D/g, '');

    if (numsFromValue.length <= 3) {
        return numsFromValue;
    }

    if (numsFromValue.length <= 7) {
        return `${numsFromValue.slice(0, 3)}-${numsFromValue.slice(3)}`;
    }
    return `(${numsFromValue.slice(0, 3)}) ${numsFromValue.slice(3, 6)}-${numsFromValue.slice(6, 10)}`;
};