import { fromJS } from 'immutable';
import * as REDUX from '../constants/redux';

export const initialState = fromJS({
    isAuthInfo: false,
    isAuthenticated: null,
    currentUser: null,
    isApproved: false,
    organizationId: false,
    currentUserRoles: {},
    currentUserGroups: [],
    uniqueChannelName: false,
    clientProfile: false,
    room: null,
    selectedParticipant: null,
    dominantSpeaker: null,
    mainParticipant: null,
    defaultTheme: null,
    tenantTheme: null,
    defaultLabels: null,
    tenantLabels: null,
    defaultAssets: null,
    tenantAssets: null,
    toolbarTitle: 'coParenter Professional',
    toolbarButtons: [],
    dataItemsGetHelps: {},
    dataItemsClient: {},
    dataItemsParentingPlan: {},
    dataItemsPro: {},
    dataItemsProInvited: {},
    dataItemsClientAssign: {},
    dataItemsClientInvites: {},
    dataItemsOrgClients: {},
    dataItemsCoParentUsers: {},
    dataItemsOrganizations: {},
    dataItemsOrganizationTypes: {},
    dataItemsOrganizationChats: {},
    dataItemsClientAgreements: {},
    dataItemsClientCheckIns: {},
    dataItemsClientCPMsgs: {},
    dataItemsClientChildren: {},
    dataItemsClientDocuments: {},
    dataItemsClientRequests: {},
    dataItemsClientExpenses: {},
    dataItemsClientGetHelps: {},
    dataItemsClientConnections: {},
    dataItemsClientProfessionals: {},
    dataItemsClientNotes: {},
    dataItemsNotesDashboard: {},
    dataItemsCheckInsDashboard: {},
    dataItemsChildrenDashboard: {},
    dataItemsGetHelpDashboard: {},
    dataItemsCoParentDashboard: {},
    dataItemsNotifications: {},
    allOrganizationRoles: [],
    allClientViewRoles: [],
    allManagementRoles: [],
});

// console.log(initialState);

function globalReducer(state = initialState, action) {
    switch (action.type) {
        case REDUX.IS_AUTHENTICATED:
            return state.set('isAuthenticated', action.payload);
        case REDUX.IS_APPROVED:
            return state.set('isApproved', action.payload);
        case REDUX.CURRENT_USER:
            return state.set('currentUser', action.payload);
        case REDUX.CURRENT_USER_ROLES:
            return state.set('currentUserRoles', action.payload);
        case REDUX.CURRENT_USER_GROUPS:
            return state.set('currentUserGroups', action.payload);
        case REDUX.NEW_CURRENT_USER_DATA:
            return state.set('currentUser', Object.assign({}, state.get('currentUser'), action.payload));
        case REDUX.ORG_ID:
            return state.set('organizationId', action.payload);
        case REDUX.NEW_UNIQUE_CHANNEL_NAME:
            return state.set('uniqueChannelName', action.payload);
        case REDUX.CLIENT_PROFILE:
            return state.set('clientProfile', action.payload);
        case REDUX.ROOM:
            return state.set('room', action.payload);
        case REDUX.SELECTED_PARTICIPANT:
            return state.set('selectedParticipant', action.payload);
        case REDUX.DOMINANT_SPEAKER:
            return state.set('dominantSpeaker', action.payload);
        case REDUX.VIDEO_CONFERENCE:
            return state.set('videoConference', action.payload);
        case REDUX.MAIN_PARTICIPANT:
            return state.set('mainParticipant', action.payload);
        case REDUX.IS_AUTH_INFO:
            return state.set('isAuthInfo', action.payload);
        case REDUX.TOOLBAR_TITLE:
            return state.set('toolbarTitle', action.payload);
        case REDUX.TOOLBAR_BUTTONS:
            return state.set('toolbarButtons', action.payload);
        case REDUX.PROPERTY_COMPONENT:
            return action.component !== null && action.property !== null ? state.set(`dataItems${action.component}`, state.get(`dataItems${action.component}`).set(action.property, action.payload)) : state;
        case REDUX.ALL_ORGANIZATION_ROLES:
            return state.set('allOrganizationRoles', action.payload);
        case REDUX.ALL_CLIENT_VIEW_ROLES:
            return state.set('allClientViewRoles', action.payload);
        case REDUX.ALL_MANAGEMENT_ROLES:
            return state.set('allManagementRoles', action.payload);
        case REDUX.DEFAULT_THEME:
            return state.set('defaultTheme', action.payload);
        case REDUX.TENANT_THEME:
            return state.set('tenantTheme', action.payload);
        case REDUX.DEFAULT_LABELS:
            return state.set('defaultLabels', action.payload);
        case REDUX.TENANT_LABELS:
            return state.set('tenantLabels', action.payload);
        case REDUX.DEFAULT_ASSETS:
            return state.set('defaultAssets', action.payload);
        case REDUX.TENANT_ASSETS:
            return state.set('tenantAssets', action.payload);
        default:
            return state;
    }
}

export default globalReducer;
