// Used by Labels and Assets to search for values
const findItemByKey = (items, key) => {
    return items && items.find(x => x.key === key);
}

const getValueFromItems = (Items, key) => {
    const foundItem = findItemByKey(Items, key);

    if (foundItem) {
        return foundItem.value || '';
    }

    return '';
}

const getValueByKey = (tenantItems, defaultItems, key) => {
    const foundTenantValue = getValueFromItems(tenantItems, key);
    
    if (foundTenantValue !== '') {
        return foundTenantValue;
    }

    return getValueFromItems(defaultItems, key);
}

// Branding related utils
const getThemeProperty = (tenantTheme, defaultTheme, property) => {

    return tenantTheme && tenantTheme[property] ? tenantTheme[property] : defaultTheme[property];
}

const getClientLabelVariableData = (clientProfile) => {
    return {
        clientName: clientProfile && clientProfile.name || '',
        clientEmail: clientProfile && clientProfile.email || '',
    };
}

export {
    findItemByKey,
    getValueFromItems,
    getValueByKey,
    getThemeProperty,
    getClientLabelVariableData,
};