import moment from 'moment';

function validate(values) {
    const errors = {};

    if (!values.startDate) {
        errors.startDate = 'Start date is required';
    }

    if (!values.endDate) {
        errors.endDate = 'End date is required';
    }

    if (values.startDate && values.endDate) {
        const momentStart = moment(values.startDate);
        const momentEnd = moment(values.endDate);

        if (momentStart.isAfter(momentEnd)) {
            errors.startDate = 'Start Date is after End Date';
            errors.endDate = 'End Date is before Start Date';
        }
    }

    if (!values.children) {
        errors.children = 'A child must be selected';
    }

    if (!values.pattern && !values.exchangeTimes) {
        errors.planTemplate = 'Select a plan template to get started';
    }

    if (!values.title) {
        errors.title = 'Title is required';
    }

    return errors;
}

export {
    validate,
}