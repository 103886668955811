/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Button, DialogContainer, Toolbar } from 'react-md';
import CloseForm from './FormClose';
import { isNullOrUndefined } from 'util';

export default class CloseDialog extends PureComponent {

    parseClose = (values) => {
        const closeValues = {};
        const findKeys = ['closeType', 'closeReason'];
        findKeys.forEach(x => closeValues[x] = '');

        values.map((value, key) => {
            const name = findKeys.find(x => key === x);
            return name ? closeValues[name] = value.trim() : null;
        });

        closeValues['sendAppReview'] = isNullOrUndefined(values.get('sendAppReview')) ? false : values.get('sendAppReview');
        
        return closeValues;
    };

    handleSubmit = (e) => {
        const close = this.parseClose(e);
        this.props.addClose(close);
    };

    render() {
        const { visible, onHide } = this.props;
        const groups = (<CloseForm onSubmit={this.handleSubmit} key={0} index={0} />)

        return (
            <DialogContainer
                id="add-Orgs-dialog"
                aria-labelledby="add-org-dialog-title"
                visible={visible}
                onHide={onHide}
                fullPage
                // actions={actions}
            >
                <CSSTransitionGroup
                    id="add-org-form"
                    className="md-text-container md-toolbar--relative"
                    transitionName="md-cross-fade"
                    transitionEnterTimeout={300}
                    transitionLeave={false}
                >
                    <Toolbar
                        nav={<Button icon onClick={onHide}>close</Button>}
                        title="Close Message" titleId="add-org-dialog-title" fixed colored
                    />
                    {groups}
                </CSSTransitionGroup>
            </DialogContainer>
        );
    }
}
