import React from 'react';
import { getCreatedByCPName } from './';
import { convert24To12Hour } from '../../utils/dateTime';
import { flipPattern } from '../ParentingPlan/SchedulePattern';
import ParentingScheduleCard from './ParentingScheduleCard';

const SwapScheduleCard = ({item}) => {
    if (item.associatedPlan) {
        item.associatedPlan.pattern = flipPattern((item.associatedPlan.pattern));
    }

    return (
        <div id="swap-card-contents">
            { item.associatedPlan && <ParentingScheduleCard item={item.associatedPlan}/> }
            <p>{ item.startTime ? `Starts at ${convert24To12Hour(item.startTime)}` : null }</p>
            <p>{ item.endTime ? `Ends at ${convert24To12Hour(item.endTime)}` : null }</p>
            <p>{ item.notes ? `Notes: ${item.notes}` : null }</p>
            <p>{ getCreatedByCPName(item) ? `Created by: ${getCreatedByCPName(item)}` : null }</p>
        </div>
    );
}

export default SwapScheduleCard;