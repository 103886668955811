import React, { PureComponent } from 'react';
import { DialogContainer, Media } from 'react-md';

export default class ImageViewDialog extends PureComponent {

    render() {
        const { imageUrl, visible, onHide } = this.props;

        const actions = [{
            id: 'dialog-cancel',
            primary: true,
            children: 'Close',
            onClick: onHide,
        }];

        return (
            <DialogContainer
                id="view-image-dialog"
                aria-labelledby="view-GetHelp-dialog-title"
                visible={visible}
                onHide={onHide}
                actions={actions}
                display={'inline-block'}
                autosizeContent={true}
                width={800}
                >
                <Media aspectRatio="4-3">
                    <img alt='imgUrl' src={imageUrl} />
                </Media>
            </DialogContainer>
        );
    }
}