import React, { useEffect } from 'react';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { selectCurrentUser, selectOrganizationId } from '../../selectors/global';
import { setToolbarButtons } from '../../actions/global';
import { Grid, Cell } from 'react-md';
import SystemMessageCard from '../../components/InfoMessageCards/SystemMessageCard';
import OrganizationMessageCard from '../../components/InfoMessageCards/OrganizationMessageCard';
import { Desktop, TabletOrMobile } from '../../components/ResponsiveMedia';
import { callCloudRunFunction } from '../../utils/firestore';
import { useCustomPageFrameworkLabels } from '../../hooks/useCustomLabels';
import HomeNotificationsCard from '../../components/Notifications/HomeNotificationCard';

function HomeDashboard() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('HomeDashboard');

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        dispatch(setToolbarButtons([]));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Home Dashboard',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        finishedLoading();
    }, []);

    function buildPageContent() {
        return (
            <>
                <Desktop>
                    <Grid>
                        <Cell desktopSize={8} tabletSize={8} phoneSize={4}>
                            <Grid>
                                <Cell size={12}>
                                    <HomeNotificationsCard/>
                                </Cell>
                            </Grid>
                        </Cell>
                        <Cell desktopSize={4} tabletSize={8} phoneSize={4}>
                            <Cell size={12}>
                                <SystemMessageCard/>
                            </Cell>
                            <Cell size={12}>
                                <OrganizationMessageCard/>
                            </Cell>
                        </Cell>
                    </Grid>
                </Desktop>
                <TabletOrMobile>
                    <Grid>
                        <Cell tabletSize={8} phoneSize={4}>
                            <HomeNotificationsCard/>
                        </Cell>
                        <Cell tabletSize={8} phoneSize={4}>
                            <SystemMessageCard/>
                        </Cell>
                        <Cell tabletSize={8} phoneSize={4}>
                            <OrganizationMessageCard/>
                        </Cell>
                    </Grid>
                </TabletOrMobile>
            </>
        );
    }

    return (
        <div className="welcomeBackground">
            { renderPageContent() }
        </div>
    );
}

export default HomeDashboard;