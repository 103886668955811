import React from 'react';
import { Card, CardTitle, CardText, Cell, Grid } from 'react-md';
import moment from 'moment';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../Buttons/ThemedButton';

function DetailedClientCard(props) {
    const { client, onCaseNumberClicked = () => {}, unfocus } = props;

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    if (!client) {
        return (<></>);
    }

    const titleStyle = { position: 'relative', padding: '16px 16px 8px' };
    const contentStyle = { paddingTop: '8px', paddingBottom: '8px' };
    const infoHeaderStyle = { margin: '0px' };
    const unfocusButtonStyle = { position: 'absolute', right: '16px' };

    const buildInfoHeader = (headerText) => {
        return (
            <h4 style={infoHeaderStyle}>
                <b>{ `${headerText}:` }</b>
            </h4>
        );
    }

    const buildUnfocusButton = () => {
        if (!unfocus) {
            return null;
        }

        return (<ThemedButton icon style={unfocusButtonStyle} onClick={unfocus}>close</ThemedButton>);
    }

    return (
        <Card>
            <CardTitle
                style={titleStyle}
                title={`${client.firstName} ${client.lastName}`}
                subtitle={`A client of your organization`}
                children={buildUnfocusButton()}
            />
            <CardText style={contentStyle}>
                <Grid>
                    <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('caseNumberData')) }
                        <a href={'#'} onClick={() => onCaseNumberClicked(client.caseNumber)}>{ `${client.caseNumber}` }</a>
                    </Cell>
                    <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('emailData')) }
                        <p>{ client.email }</p>
                    </Cell>
                    <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('phoneData')) }
                        <p>{ client.phone }</p>
                    </Cell>
                    <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('createdData')) }
                        <p>{ moment(client.createdOn).format("MMM Do YYYY h:mm:ss a") }</p>
                    </Cell>
                    <Cell desktopSize={4} tabletSize={2} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('countryData')) }
                        <p>{ client.country }</p>
                    </Cell>
                    <Cell desktopSize={4} tabletOffset={1} tabletSize={2} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('stateData')) }
                        <p>{ client.province }</p>
                    </Cell>
                    <Cell desktopSize={4} tabletOffset={1} tabletSize={2} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('countyData')) }
                        <p>{ client.county }</p>
                    </Cell>
                </Grid>
            </CardText>
        </Card>
    );
}

export default DetailedClientCard;