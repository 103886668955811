import React from 'react';
import { Card, CardText, Divider, CardActions, Button } from 'react-md';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';

const EntryCategoryCard = (props) => {
    const { category, canUpdate, onUpdate, canDelete, onDelete } = props;

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const buildCardActions = () => {
        return (
            <>
                <Divider/>
                <CardActions>
                    { canUpdate && <ThemedButton flat onClick={() => onUpdate(category.uid || category.id)}>Update</ThemedButton> }
                    { canDelete && <ThemedButton flat onClick={() => onDelete(category.uid || category.id)}>Delete</ThemedButton> }
                </CardActions>
            </>
        );
    }

    return (
        <Card style={{height: '100%'}}>
            <CardText>
                <p><b>{ `${getDataLabel('categoryData')}:` }</b>{ ` ${category.category}` }</p>
                <p><b>{ `${getDataLabel('descriptionData')}:` }</b>{ ` ${category.description}` }</p>
            </CardText>
            {
                !canUpdate && !canDelete
                    ? (<></>)
                    : buildCardActions()
            }
        </Card>
    );
}

export default EntryCategoryCard;