import React, { useState, useEffect } from 'react';
import usePageFramework from "../../hooks/usePageFramework";
import { Button, Dialog } from 'react-md';
import { selectCurrentUser, selectOrganizationId, selectNotifications } from '../../selectors/global';
import Fade from 'react-reveal/Fade';
import Tada from 'react-reveal/Tada';
import EmptyNotifications from './EmptyNotifications';
import NotificationList from './NotificationList';
import { callCloudRunFunction } from '../../utils/firestore';
import useCustomLabels from '../../hooks/useCustomLabels';
import ThemedButton from '../Buttons/ThemedButton';

function NotificationBell(props) {
    const { badgeId } = props;
    const {
        selector,
    } = usePageFramework();

    const [ showDropdown, setShowDropdown ] = useState(false);
    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const notifications = selector(selectNotifications());
    const noNotifications = notifications.length === 0 ? true : false;

    const {
        getLabel,
    } = useCustomLabels('Notifications');

    useEffect(() => {
        window.addEventListener('click', handleWindowClick);

        return () => {
            window.removeEventListener('click', handleWindowClick);
        }
    }, []);

    const handleWindowClick = (e) => {
        const badge = document.getElementById(badgeId);

        if (!badge || !badge.contains(e.target)) {
            setShowDropdown(false);
        }
    }

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    }

    const deleteAllNotifications = () => {
        if (noNotifications) {
            return;
        }

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Notification Bell',
            action: 'delete all notifications',
        };
        
        callCloudRunFunction(currentUser, 'logProUserAction', log);
        callCloudRunFunction(currentUser, 'removeAllUserToDos', { uid: currentUser.uid })
    }

    return (
        <>
            <Tada spy={notifications && notifications.length || 0}>
                <Button icon primary onClick={toggleDropdown}>notifications</Button>
            </Tada>
            <Fade duration={500} when={showDropdown} top mountOnEnter unmountOnExit>
                <Dialog
                    id={'notification-bell-dialog'}
                    autopadContent={false}
                    title={getLabel('notificationsHeader')}
                    style={{ width: '400px', minHeight: '200px', maxHeight: '500px', overflowY: 'auto', backgroundColor: 'white', position: 'absolute', right: '25px' }}
                    actions={ noNotifications ? [] : [<ThemedButton swapTheming primary flat onClick={deleteAllNotifications}>{ getLabel('clearNotificationsButton') }</ThemedButton>]}
                >
                    {
                        noNotifications
                            ? <EmptyNotifications/>
                            : <NotificationList notifications={notifications}/>
                    }
                </Dialog>
            </Fade>
        </>
    );
}

export default NotificationBell;