// Checks for value changes and previous errors for a single name property
// Runs a validation function if the value has changed
const asyncValidationChanges = async (name, values, previousValues, existingErrors, previousAsyncErrors, asyncValidationFunction) => {
    if (existingErrors[name]) return undefined;

    if (values[name] !== previousValues[name]) {
        return await asyncValidationFunction();
    }

    return previousAsyncErrors[name];
}

export {
    asyncValidationChanges
};