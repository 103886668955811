import React from 'react';
import { ScheduleIcon } from '../ScheduleIcons';

const ScheduleLegend = ({icon, iconClassName, text, textClassName, size}) => {
    const iconClass = `margin-right ${iconClassName || ''}`;
    const iconItem = icon ? <ScheduleIcon icon={icon} iconClass={iconClass} size={size}/> : null

    return (
        <ul id={`legend-${text || ''}`} className="flex-container-list">
            {iconItem}
            <li id={`legend-item-${text || ''}`} className={textClassName}>
                {text}
            </li>
        </ul>
    );
}

export default ScheduleLegend;