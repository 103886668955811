import React from 'react';
import { Cell } from 'react-md';
import TextFormField from '../../components/FormFields/TextFormField';

function ClientInfoFormSection(props) {
    const { values, errors, clientKey, getDataLabel, disabled = false, handleChange, handleBlur} = props;

    if (!values[clientKey]) {
        return (<></>);
    }

    const handleClientChange = (name, value) => {
        const changedClientData = Object.assign({}, values[clientKey], { [name]: value });
        handleChange(clientKey, changedClientData);
    }

    const handleClientBlur = (name, value) => {
        const changedClientData = Object.assign({}, values[clientKey], { [name]: value });
        handleBlur(clientKey, changedClientData);
    }

    return (
        <>
            <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                <TextFormField
                    name="firstName"
                    label={getDataLabel('firstNameData')}
                    values={values[clientKey]}
                    errors={errors[clientKey]}
                    disabled={disabled}
                    required
                    onChange={handleClientChange}
                    onBlur={handleClientBlur}
                />
            </Cell>
            <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                <TextFormField
                    name="lastName"
                    label={getDataLabel('lastNameData')}
                    values={values[clientKey]}
                    errors={errors[clientKey]}
                    disabled={disabled}
                    required
                    onChange={handleClientChange}
                    onBlur={handleClientBlur}
                />
            </Cell>
            <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                <TextFormField
                    name="sms"
                    label={getDataLabel('phoneData')}
                    values={values[clientKey]}
                    errors={errors[clientKey]}
                    disabled={disabled}
                    required
                    onChange={handleClientChange}
                    onBlur={handleClientBlur}
                />
            </Cell>
            <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                <TextFormField
                    name="email"
                    label={getDataLabel('emailData')}
                    values={values[clientKey]}
                    errors={errors[clientKey]}
                    disabled={disabled}
                    required
                    onChange={handleClientChange}
                    onBlur={handleClientBlur}
                />
            </Cell>
        </>
    );
}

export default ClientInfoFormSection;