import React from 'react';

const BirthdayScheduleCard = ({item}) => {
    const text = item && item.child && item.child.firstName ? `It is ${item.child.firstName}'s birthday!` : 'Child\'s Birthday today!';
    return (
        <div id="birthday-card-contents">
            <p>{ text }</p>
        </div>
    );
}

export default BirthdayScheduleCard;