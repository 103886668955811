import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { selectCurrentUser, selectOrganizationId } from '../../selectors/global';
import TwoColumnLayout from '../../pageLayouts/TwoColumnLayout';
import { ListItem, List, Card, Grid, Cell } from 'react-md';
import UpdateTenantThemeSubForm from './UpdateTenantThemeSubForm';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_INFO } from '../../constants/notifications';
import ThemedButton from '../../components/Buttons/ThemedButton';
import { callCloudRunFunction } from '../../utils/firestore';

function TenantThemeSubCollection(props) {
    const { onUpdate, subCollection, onAddSubDocument, onDeleteSubDocument } = props
    const {
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const [ subDocuments, setSubDocuments ] = useState([]);
    const [ focusedDocumentKey, setFocusedDocumentKey ] = useState(null);
    const foundDocumentGroup = focusedDocumentKey && subDocuments && subDocuments.find(x => focusedDocumentKey === x.documentId);

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        let tenantThemeSubStream = null;
        const db = firebase.firestore();

        if (!subCollection) {
            errorLoading();
            return;
        }

        tenantThemeSubStream = db.collection('TenantThemes').doc(organizationId).collection(subCollection).onSnapshot((querySnapshot) => {
            const subDocuments = querySnapshot.docs.map((docRef) => {

                const documentId = docRef.id;
                const data = docRef.data();
                let copyData = Object.assign({}, data);
                
                // Exceptions to only display/allow changing certain properties on specified sub-collectons/sub-documents
                if (subCollection === 'Platforms' && documentId === 'deeplink') {
                    copyData = { messages: data.messages, social: data.social };
                }

                const documentData = Object.assign({}, copyData);

                return {
                    documentId: documentId,
                    documentData: documentData,
                };
            });

            setSubDocuments(subDocuments);
            finishedLoading();
        });

        return () => {
            if (tenantThemeSubStream) {
                tenantThemeSubStream();
            }
        }
    }, []);

    const updateSubCollection = (subCollectionData) => {
        const updateData = {
            subCollection: subCollection,
            subDoc: focusedDocumentKey,
            tenantThemeData: subCollectionData,
        }

        onUpdate({ function: () => setFocusedDocumentKey(null), data: updateData});
    }

    const createSubDocument = (values, tenantThemeData) => {
        const tenantThemeMetadata = {
            organizationId: organizationId,
        };

        const createTenantSubDocument = Object.assign({}, tenantThemeData, { documentId : values.confirmation }, tenantThemeMetadata);
        
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Tenant Theme',
            action: 'create tenant theme sub collection document',
            metadata: { ...createTenantSubDocument },
        };
        
        setFocusedDocumentKey(null);
        showToastNotification('Creating sub document...', NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'createTenantSubDocument', createTenantSubDocument).then(() => {
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            showToastNotification('Sucessfully created sub document', NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Something has gone wrong', NOTIFICATION_ERROR);
        });
    }
    
    const onAddNewSection = () => {
        const sectionTenantData = {
            title: `Type the name of the section to create.`,
            warning: 'Changes will be live immediately!',
            function: createSubDocument,
            data: { subCollection: subCollection },
        };

        onAddSubDocument(sectionTenantData);
    }

    const deleteSubDocument = (values, tenantThemeData) => {
        const tenantThemeMetadata = {
            organizationId: organizationId,
        };

        const deleteTenantSubDocument = Object.assign({}, tenantThemeData, tenantThemeMetadata);
        
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Tenant Theme',
            action: 'delete tenant theme sub collection document',
            metadata: { ...deleteTenantSubDocument },
        };
        
        setFocusedDocumentKey(null);
        showToastNotification(`Deleting section ${focusedDocumentKey}...`, NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'deleteTenantSubDocument', deleteTenantSubDocument).then(() => {
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            showToastNotification('Sucessfully deleted section', NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Something has gone wrong', NOTIFICATION_ERROR);
        });
    }
    
    const onDeleteSection = () => {
        const sectionTenantData = {
            title: `Type the name of the section to create.`,
            warning: 'Changes will be live immediately!',
            function: deleteSubDocument,
            data: { subCollection: subCollection, documentId: focusedDocumentKey },
        };
        
        onDeleteSubDocument(sectionTenantData);
    }
    
    const buildSubDocumentGroups = () => {
        if (!subDocuments && !subDocuments.length) {
            return ( <p> No available sub documents to change </p> );
        }

        return (
            <Grid>
                <Cell size={12}>
                    <ThemedButton className="full-width-field" flat swapTheming iconChildren="add" onClick={onAddNewSection}>
                        Add new section
                    </ThemedButton>
                </Cell>
                <Cell size={12}>
                    <List>
                        { subDocuments.map(x => <ListItem primaryText={x.documentId} onClick={() => setFocusedDocumentKey(x.documentId)}/>) }
                    </List>  
                </Cell>
            </Grid>
        );
    }

    const buildFormDisplay = () => {
        if (!foundDocumentGroup) {
            return (<p>Select a sub document on the left to change its data</p>);
        }

        return (
            <Card>
                <UpdateTenantThemeSubForm id={foundDocumentGroup.documentId} key={foundDocumentGroup.documentId} onSubmit={updateSubCollection} onSubmitNewProperty={onAddSubDocument} onDeleteSection={onDeleteSection} documentData={foundDocumentGroup.documentData} />
            </Card>)
    }

    function buildPageContent() {
        return (
            <TwoColumnLayout
                columnSplit={30}
                buildLeftColumn={buildSubDocumentGroups}
                buildRightColumn={buildFormDisplay}
                buildTabletMobile={() => <p>Tablet/Mobile mode not available</p>}
            />
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default TenantThemeSubCollection;