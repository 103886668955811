import React, { useState, useEffect } from 'react';
import { FontIcon, List, Card, CardTitle, CardText, Grid, Cell, Tab, Tabs, TabPanel } from 'react-md';
import moment from 'moment';
import * as firebase from 'firebase';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import ListTable from '../../components/ListTable';
import ChildLineItem from './ChildLineItem';
import { selectClientProfile, selectOrganizationId, selectCurrentUser } from '../../selectors/global';
import { setToolbarButtons } from '../../actions/global';
import { ALL, FILTER_TEXT, FILTER_NUMBER } from '../../constants';
import ClientRepository from '../ClientRepository';
import { getValueFromContextData } from '../../utils/contextData';
import { callCloudRunFunction } from '../../utils/firestore';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { NAV_DEFAULT } from '../../constants/navigation';
import { getClientLabelVariableData } from '../../utils/customBranding';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import GeneralChildContent from './GeneralChildContent';
import ThemedTabs from '../../components/ThemedTabs';
import useCustomAssets from '../../hooks/useCustomAssets';

const COMPONENT_NAME = 'ClientChildren';

function ClientChildren() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const clientProfile = selector(selectClientProfile());
    const [ dataItems, setDataItems ] = useState([]);
    const [ focusedChildId, setFocusedChildId ] = useState(null);
    const [ activeTabId, setActiveTabId ] = useState(0);

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('ClientChildren', getClientLabelVariableData(clientProfile));

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (!clientProfile) {
            return;
        }

        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];
        dispatch(setToolbarButtons(toolBarButtons));

        const db = firebase.firestore();
        db.collection('Children').where('participants', 'array-contains', clientProfile.uid).get().then((childrenDocs) => {
            const children = childrenDocs.docs.map((childDoc) => {
                const child = childDoc.data();

                child.id = childDoc.id;
                child.birthDay = getValueFromContextData(child.contextData, 'start', 'birthDate');
                child.age = new moment().diff(new moment(child.birthDay, 'YYYY-MM-DD'), 'years');
                return child;
            });

            setDataItems(children);
            finishedLoading();
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Professionals',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);

        return () => {
            dispatch(setToolbarButtons([]));
        }
    }, []);

    if (!clientProfile) {
        history.push(NAV_DEFAULT);
        return(<></>);
    }

    const createEmptyCard = () => {
        const cardProps = { status: ALL, cardMediaUrl: getAsset('clientChildrenEmpty'), headerTitle: getLabel('emptyResultsHeader'), subTitle: getLabel('emptyResultsSubheader'), buttonText: 'Back to Client Profile', buttonClick: () => history.goBack() };
        return (<GettingStartedPaper {...cardProps} key={cardProps.status} />);
    }

    const buildListItem = (item, index) => {
        return (<ChildLineItem item={item} index={index} itemClicked={setFocusedChildId}/>);
    }

    const renderGeneralChildInfo = () => {
        const child = dataItems.find(x => x.id === focusedChildId);

        return (<GeneralChildContent child={child}/>);
    }

    const renderRepositoryInfo = () => {
        const child = dataItems.find(x => x.id === focusedChildId);
        return child ? (<ClientRepository initialFilterState={{allCategories: `${child.firstName} ${child.lastName}`}} freezeToolbar={true}/>) : (<></>);
    }

    const buildSelectedChildTabs = () => {
        const cardProps = { cardMediaUrl: getAsset('clientChildrenInitial'), headerTitle: 'Select a child for more Details' };
        if (!focusedChildId) return (<GettingStartedPaper {...cardProps} />);

        const tabs = [
            { id: 'general-info', label: getLabel('generalTab'), content: renderGeneralChildInfo },
            { id: 'secure-repository', label: getLabel('repositoryTab'), content: renderRepositoryInfo },
        ];

        return (
            <>
                <ThemedTabs tabId={`child-tab-${focusedChildId}`} onTabChange={setActiveTabId}>
                    { tabs.map((x) => <Tab id={x.id} key={x.id} label={x.label}/>) }
                </ThemedTabs>
                <TabPanel id={`child-tab-content-${focusedChildId}`} controlledById={tabs[activeTabId].id}>
                    { tabs[activeTabId].content() }
                </TabPanel>
            </>
        );
    }

    function buildPageContent() {
        const columns = [
            { label: getDataLabel('firstNameData'), value: 'firstName', type: FILTER_TEXT },
            { label: getDataLabel('ageData'), value: 'age', type: FILTER_NUMBER },
        ];
        
        const filteredItems = (items) => { setDataItems(items); setFocusedChildId(null) };

        return (
            <>
                <ListTable status={ALL} items={dataItems} filteredItems={filteredItems} columns={columns} key={ALL} componentName={COMPONENT_NAME} emptyCard={() => createEmptyCard()} />
                <Grid>
                    <Cell desktopSize={3} tabletSize={8} phoneSize={4}>
                        <List>
                            { dataItems.map(buildListItem) }
                        </List>
                    </Cell>
                    <Cell desktopSize={9} tabletSize={8} phoneSize={4}>
                        { buildSelectedChildTabs() }
                    </Cell>
                </Grid>
            </>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default ClientChildren;