import React from 'react';
import { getCreatedByCPName } from './';

const DefaultScheduleCard = ({item}) => {
    return (
        <div id="default-card-contents">
            <p>{ item.title ? `Title: ${item.title}` : null }</p>
            <p>{ item.notes ? `Notes: ${item.notes}` : null }</p>
            <p>{ getCreatedByCPName(item) ? `Created by: ${getCreatedByCPName(item)}` : null }</p>
        </div>
    );
}

export default DefaultScheduleCard;