import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as firebase from 'firebase';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import { selectOrganizationId, selectDataItemsFromComponent, selectCurrentUser, selectCurrentOrganization, selectIsSuperAdmin, selectCurrentUserGroups } from '../../selectors/global';
import { setDataItemsToComponent, emptyToolbarButtons, emptyToolbarTitle } from '../../actions/global';
import ProTable from '../../components/ProTable';
import { ALL, FILTER_DATE, FILTER_TEXT, FILTER_NONE } from '../../constants';
import { ORG_COPARENTER_PROFESSIONAL } from '../../constants/roles';
import {  NAV_DEFAULT, NAV_CLIENT_DETAILS, NAV_CLIENT_INVITES, NAV_CLIENT_PROFILE, NAV_CASE_SEARCH, NAV_CLIENT_COMMUNICATION } from '../../constants/navigation';
import { formatPhone } from '../../components/RequestFields/formatPhone';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import TableIconButton from '../../components/Buttons/TableIconButton';
import TableButton from '../../components/Buttons/TableButton';
import { callCloudRunFunction } from '../../utils/firestore';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import { getMatchingGroups } from '../../utils/orgGroups';
import useCustomAssets from '../../hooks/useCustomAssets';
import useNotifications from '../../hooks/useNotifications';
import ClientMessageButton from './ClientMessageButton';

const COMPONENT_NAME = 'OrgClients';

function OrganizationClients() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const userOrgGroups = selector(selectCurrentUserGroups());
    const userIsSuperAdmin = selector(selectIsSuperAdmin());
    const organizationId = selector(selectOrganizationId());
    const currentOrganization = selector(selectCurrentOrganization());
    const dataItems = selector(selectDataItemsFromComponent(COMPONENT_NAME));
    const [ isUsingGroups, setIsUsingGroups ] = useState(null);
    const [ selectableOrgGroups, setSelectableOrgGroups ] = useState([]);

    const labelVariableData = {
        organizationName: currentOrganization && currentOrganization.organization || '',
    };

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('Clients', labelVariableData);

    const {
        hasMatchingNotification,
    } = useNotifications();

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (!organizationId) return;

        dispatch(emptyToolbarButtons());

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Clients',
            action: 'visit page',
        };
        
        callCloudRunFunction(currentUser, 'logProUserAction', log);

        return () => {
            dispatch(emptyToolbarButtons());
        }
    }, []);

    useEffect(() => {
        if (!organizationId) return;
        
        var orgGroupStream = null;
        const db = firebase.firestore();

        orgGroupStream = db.collection('ProOrganizations').doc(organizationId).collection('Groups').onSnapshot((snapshot) => {
            const groups = snapshot.docs.map(doc => {
                const group = doc.data();

                group.createdOn = group.createdOn ? group.createdOn.toDate() : null;
                group.updatedOn = group.updatedOn ? group.updatedOn.toDate() : null;
                group.id = doc.id;
                return group;
            });
            
            const availableGroups = userIsSuperAdmin ? groups : getMatchingGroups(userOrgGroups, groups);
            setSelectableOrgGroups(availableGroups);
            setIsUsingGroups(groups.length ? true : false);
        });

        return () => {
            if (orgGroupStream) {
                orgGroupStream();
            }
        }
    }, [ organizationId, userIsSuperAdmin, userOrgGroups ])

    useEffect(() => {
        if (!organizationId) return;
        
        var orgGroupStream = null;
        const db = firebase.firestore();

        orgGroupStream = db.collection('ProOrganizations').doc(organizationId).collection('Groups').onSnapshot((snapshot) => {
            const groups = snapshot.docs.map(doc => {
                const group = doc.data();

                group.createdOn = group.createdOn ? group.createdOn.toDate() : null;
                group.updatedOn = group.updatedOn ? group.updatedOn.toDate() : null;
                group.id = doc.id;
                return group;
            });
            
            const availableGroups = userIsSuperAdmin ? groups : getMatchingGroups(userOrgGroups, groups);
            setSelectableOrgGroups(availableGroups);
            setIsUsingGroups(groups.length ? true : false);
        });

        return () => {
            if (orgGroupStream) {
                orgGroupStream();
            }
        }
    }, [ organizationId, userIsSuperAdmin, userOrgGroups ])

    useEffect(() => {
        if (!organizationId || isUsingGroups === null ) {
            return
        }

        var dataStreamOrgClients = null
        const db = firebase.firestore();

        dataStreamOrgClients = db.collection('Users').where('orgAssociations', 'array-contains', organizationId).onSnapshot((snapshot) => {
            const organizationClients = snapshot.docs.reduce((prev, doc) => {
                const client = doc.data();

                client.id = doc.id;
                client.displayName = `${client.firstName} ${client.lastName}`;
                client.createdOn = client.createdOn ? client.createdOn.toDate() : null;
                client.updatedOn = client.updatedOn ? client.updatedOn.toDate() : null;

                if (!isUsingGroups) {
                    prev.push(client);
                    return prev;
                }
                
                // If the organization is using groups, apply the filter for groups
                const relatedGroups = getMatchingGroups(client.orgGroups || [], selectableOrgGroups);
                if (relatedGroups.length) {
                    client.orgGroupName = relatedGroups[0].name || '';
                    prev.push(client);
                    return prev;
                }

                return prev;
            }, [], []);

            dispatch(setDataItemsToComponent(organizationClients, COMPONENT_NAME));
            finishedLoading();
        });

        return () => {
            if (dataStreamOrgClients) {
                dataStreamOrgClients();
            }
        }
    }, [ organizationId, isUsingGroups, selectableOrgGroups ])

    if (organizationId === false || currentUser === null) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    const clientRowClicked = (clientId) => {
        history.push(`${NAV_CLIENT_DETAILS}`, { clientId: clientId });
    }

    const onCaseNumberClicked = (caseNumber) => {
        return history.push(`${NAV_CASE_SEARCH}#${caseNumber}`);
    }

    const clientRowEditClicked = (clientId) => {
        history.push(`${NAV_CLIENT_DETAILS}`, { clientId: clientId, redirect: NAV_CLIENT_PROFILE });
    }

    const clientRowChatClicked = (clientId) => {
        history.push(`${NAV_CLIENT_DETAILS}`, { clientId: clientId, redirect: NAV_CLIENT_COMMUNICATION });
    }

    const createEmptyCard = (status) => {
        const cardsInfo = [
            { status: ALL, cardMediaUrl: getAsset('organizationClientsEmpty'), headerTitle: getLabel('emptyHeader'), subTitle: getLabel('emptySubheader'), learnMoreLink: true, buttonText: `Onboard ${organizationId === ORG_COPARENTER_PROFESSIONAL ? 'coParent' : 'Client'}`, buttonClick: () => history.push(NAV_CLIENT_INVITES) },
        ];
        
        const cardProps = cardsInfo.find(x => x.status === status);
        return (<GettingStartedPaper {...cardProps} key={cardProps.status} />);
    }

    function buildPageContent() {
        const columns = [
            { label: getDataLabel('clientNameData'), value: 'displayName', type: FILTER_TEXT, fn: (item, id) => (<TableButton id={`client-${id}`} label={item} onClick={clientRowClicked}/>) },
            { label: getDataLabel('emailData'), value: 'email', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('phoneData'), value: 'sms', type: FILTER_TEXT, fn: (item) => formatPhone(item) },
            { label: getDataLabel('caseNumberData'), value: 'caseNumber', type: FILTER_TEXT, fn: (item) => <a href={'#'} onClick={() => onCaseNumberClicked(item)}>{ `${item}` }</a> },
            { label: getDataLabel('editData'), staticText: true, type: FILTER_NONE, value: 'edit', fn: (item, id, value) => <TableIconButton id={`edit-${id}`} label={value} onClick={clientRowEditClicked}/> },
            { label: getDataLabel('messageData'), staticText: true, type: FILTER_NONE, value: 'chat_bubble', fn: (item, id, value) => <ClientMessageButton id={`chat-${id}`} clientId={id} label={value} onClick={clientRowChatClicked}/> },
            { label: getDataLabel('createdData'), value: 'createdOn', isPrimaryColumn: true, type: FILTER_DATE, fn: (item) => moment(item).calendar() },
            { label: getDataLabel('clientActiveData'), value: 'updatedOn', type: FILTER_DATE, fn: (item) => moment(item).calendar() },
        ];

        if (isUsingGroups) {
            columns.splice(3, 0, { label: getDataLabel('groupData'), type: FILTER_TEXT, value: 'orgGroupName', fn: (item) => item });
        }

        return (
            <section className="md-grid">
                <ProTable status={ALL} items={dataItems} columns={columns} key={ALL} componentName={COMPONENT_NAME} emptyCard={() => createEmptyCard(ALL)} />
            </section>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default OrganizationClients;