import React, { useState, useEffect } from 'react';
import { emptyFunction, openInNewWindow } from '../utils';
import moment from 'moment';
import { DialogContainer, CircularProgress, CardText } from 'react-md';
import { PDF, IMAGE } from '../constants';
import { NOTIFICATION_ERROR} from '../constants/notifications';
import { getDataFromUploadId } from '../utils/fileUpload';
import { showToastNotification } from '../components/Notifications/ToastNotification';
import Fade from 'react-reveal/Fade';
import { useCustomDataLabels } from './useCustomLabels';
import ThemedButton from '../components/Buttons/ThemedButton';

function useMediaViewer({ onShow = emptyFunction, onHide = emptyFunction }) {
    const [ visible, setVisible ] = useState(false);
    const [ uploadId, setUploadId ] = useState(null);
    const [ imageHasLoaded, setImageHasLoaded ] = useState(false);
    const [ imageSrc, setImageSrc ] = useState('#');
    const [ imageLabel, setImageLabel ] = useState('');
    const [ imageNote, setImageNote ] = useState('');
    const [ imageDate, setImageDate ] = useState('');

    const id = 'media-view-dialog-image';
    const labelId = 'media-view-dialog-label';

    const setContent = (src, label, note, date) => {
        setImageSrc(src);
        setImageLabel(label);
        setImageHasLoaded(true);
        setImageNote(note);
        setImageDate(date);
    }

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    useEffect(() => {
        if (!uploadId) {
            return;
        }

        getDataFromUploadId(uploadId).then(([ uploadData, fileType ]) => {
            switch (fileType) {
                case PDF:
                    openInNewWindow(uploadData.url);
                    hideDialog();
                    break;

                case IMAGE:
                    setContent(uploadData.url, uploadData.title, uploadData.notes, uploadData.createdOn);
                    break;

                default:
                    hideDialog();
                    break;
            }

        }).catch((err) => {
            console.log(err);
            showToastNotification('Could not load media', NOTIFICATION_ERROR);
            hideDialog();
        });
    }, [uploadId]);
    
    const showDialog = ( uploadId ) => {
        if (!uploadId) {
            console.log('no media to show');
            showToastNotification('Could not load media', NOTIFICATION_ERROR);
            return;
        }

        setImageHasLoaded(false);
        setUploadId(uploadId);
        setVisible(true);
        onShow();
    }

    const hideDialog = () => {
        setVisible(false);
        setContent('#', '');
        setImageHasLoaded(false);
        setUploadId(null);
        onHide();
    }

    const renderMediaViewer = () => {
        const actions = [
            <ThemedButton flat swapTheming id='dialog-close' onClick={hideDialog}>Close</ThemedButton>
        ]

        return (
            <DialogContainer
                id="media-view-dialog"
                aria-labelledby="media-view-dialog"
                visible={visible}
                actions={actions}
                dialogStyle={{ height: 'auto', width: 'auto' }}
                onHide={hideDialog}
                >
                    {
                        !imageHasLoaded
                            ? <CircularProgress id={'media-view-loading'} style={{ margin: '50px' }}/>
                            : <Fade>
                                <img id={id} alt='' style={{ width: '100%', height: 'auto' }} src={imageSrc} />
                                <CardText>
                                    <b>{ `${getDataLabel('titleData')}:` }</b>
                                    <p id={labelId}>{imageLabel}</p>
                                    <b>{ `${getDataLabel('notesData')}:` }</b>
                                    <p id={`note-${labelId}`}>{imageNote}</p>
                                    <b>{ `${getDataLabel('createdData')}:` }</b>
                                    <p>{ moment(imageDate).format("MMM Do YYYY h:mm:ss a") }</p>
                                </CardText>
                            </Fade>
                    }
            </DialogContainer>
        );
    }

    return {
        showDialog,
        hideDialog,
        renderMediaViewer,
    };
}

export default useMediaViewer;