import React from 'react';
import { Grid, Cell } from 'react-md';
import TextFormField from '../../FormFields/TextFormField';
import { validate } from './validate';
import useFormValidation from '../../../hooks/useFormValidation';
import { useCustomDataLabels } from '../../../hooks/useCustomLabels';
/**
 * The DeleteConfirmationForm should be used alongside the usePopupBuilder hook.
*/

function DeleteConfirmationForm(props) {
    const { onSubmit, onHide, formId, initialValues } = props;

    const INITIAL_STATE = {
        id: initialValues.id,
        confirmation: '',
    };

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, deleteConfirmation, validate);

    function deleteConfirmation(values) {
        const confirmation = parseSubmissionToConfirmation(values);
        onSubmit(confirmation);
    }

    function parseSubmissionToConfirmation(values) {
        const confirmation = {
            id: values.id,
            confirmation: values.confirmation.trim(),
        };

        return confirmation;
    }

    return (
        <form id={formId} name={formId} onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <b className="inactive-text">Deleting can not be reversed.</b>
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="confirmation"
                        label={getDataLabel('confirmationData')}
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
            </Grid>
        </form>
    );
}

export default DeleteConfirmationForm;