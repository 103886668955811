import React from 'react';
import { TextField } from 'react-md';

const renderTextField = ({ input, errorClass, meta: { touched, invalid, pristine, error, warning }, ...props }) => (
    <TextField
        {...input}
        {...props}
        error={touched && invalid}
        errorText={error}
    />
);

export default renderTextField;