import React, { useEffect } from 'react';
import moment from 'moment';
import * as firebase from 'firebase';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import { selectClientProfile, selectOrganizationId, selectDataItemsFromComponent, selectCurrentUser } from '../../selectors/global';
import { setToolbarTitle, setToolbarButtons, setDataItemsToComponent } from '../../actions/global';
import ProTable from '../../components/ProTable';
import { convertToDateFromFirestoreDate } from '../../utils/dateTime';
import { callCloudRunFunction } from '../../utils/firestore';
import { ALL, FILTER_DATE, FILTER_NONE, FILTER_TEXT } from '../../constants';
import { NAV_DEFAULT } from '../../constants/navigation';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { getValueFromContextData } from '../../utils/contextData';
import { getClientLabelVariableData } from '../../utils/customBranding';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import useCustomAssets from '../../hooks/useCustomAssets';

const COMPONENT_NAME = 'ClientCheckIns';

function ClientCheckIns() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const dataItems = selector(selectDataItemsFromComponent(COMPONENT_NAME));
    const clientProfile = selector(selectClientProfile());

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('ClientCheckIns', getClientLabelVariableData(clientProfile));

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (organizationId === false || clientProfile === false) {
            return;
        }

        const toolBarTitle = `Client Check-Ins - ${clientProfile.name}`;
        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() } ];
        dispatch(setToolbarTitle(toolBarTitle));
        dispatch(setToolbarButtons(toolBarButtons));

        // Load Data
        const db = firebase.firestore();
        let checkinStream = null;

        checkinStream = db.collection('CheckIns').where('participants', 'array-contains', clientProfile.id).onSnapshot((snapshot) => {
            const checkIns = snapshot.docs.map((doc) => {
                const checkIn = doc.data();

                checkIn.notes = getValueFromContextData(checkIn.contextData, 'notes', 'notes');
                checkIn.mapUrl = getValueFromContextData(checkIn.contextData, 'start', 'mapUrl');
                checkIn.address = getValueFromContextData(checkIn.contextData, 'start', 'address');
                checkIn.createdOn = convertToDateFromFirestoreDate(checkIn.createdOn);
                checkIn.id = doc.ref.id;

                return checkIn;
            });

            dispatch(setDataItemsToComponent(checkIns, COMPONENT_NAME));
            finishedLoading();
        });


        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Check-Ins',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        return () => {
            dispatch(setToolbarTitle(''));
            dispatch(setToolbarButtons([]));

            if (checkinStream) {
                checkinStream();
            }
        }
    }, []);

    if (organizationId === false || clientProfile === false) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    const logMapView = (checkinId) => {
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Check-Ins',
            action: 'view map',
            checkinId: checkinId,
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
    }

    const createEmptyCard = (status) => {
        const cardsInfo = [
            { status: ALL, cardMediaUrl: getAsset('clientCheckInsEmpty'), headerTitle: getLabel('emptyResultsHeader'), subTitle: getLabel('emptyResultsSubheader'), buttonText: 'Back to Client Profile', buttonClick: () => history.goBack() },
        ];

        const cardProps = cardsInfo.find(x => x.status === status);
        return (<GettingStartedPaper {...cardProps} key={cardProps.status} />);
    }

    function buildPageContent() {
        const columns = [
            { label: getDataLabel('addressData'), value: 'address', type: FILTER_TEXT, isGrow: true, fn: (item) => item },
            { label: getDataLabel('locationUriData'), value: 'mapUrl', type: FILTER_NONE,  fn: (item, id) => (<a target="_blank" rel="noopener noreferrer" href={item} onClick={() => logMapView(id)}>View Map</a>) },
            { label: getDataLabel('notesData'), value: 'notes', type: FILTER_TEXT,  fn: (item) => item },
            { label: getDataLabel('createdData'), value: 'createdOn', isPrimaryColumn: true, type: FILTER_DATE, fn: (item) => moment(item).calendar() }
        ];

        return (
            <section className="md-grid">
                <ProTable status={ALL} items={dataItems} columns={columns} key={ALL} componentName={COMPONENT_NAME} emptyCard={() => createEmptyCard(ALL)}/>
            </section>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default ClientCheckIns;