import React from 'react';
import { DatePicker } from 'react-md';
import Bounce from 'react-reveal/Bounce';

export default function DateFormField (props) {
    const { name, values, errors, onChange, ...formProps } = props;

    const onDateChange = (dateString, dateObject, event) => {
        const selectedDate = new Date(dateString);
        onChange(name, selectedDate);
    }

    return (
        <DatePicker
            id={name}
            value={values[name]}
            onChange={onDateChange}
            fullWidth
            error={!!errors[name]}
            errorText={<Bounce when={!!errors[name]}>{errors[name] || '...'}</Bounce>}
            {...formProps}
        />
    );
}