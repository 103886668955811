import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase';
import { Button, Grid, Cell } from 'react-md';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import { selectClientProfile, selectOrganizationId, selectDataItemsFromComponent, selectCurrentUser } from '../../selectors/global';
import { setToolbarTitle, setDataItemsToComponent, setToolbarButtons} from '../../actions/global';
import { ALL, FILTER_DATE, FILTER_TEXT } from '../../constants';
import { NAV_DEFAULT } from '../../constants/navigation';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { MediaPreviewArrayFromUploadId } from '../../components/MediaPreviewCard';
import useMediaViewer from '../../hooks/useMediaViewer';
import useFilterSorterPanel from '../../hooks/useFilterSorterPanel';
import usePageFramework from '../../hooks/usePageFramework';
import { callCloudRunFunction } from '../../utils/firestore';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import { getClientLabelVariableData } from '../../utils/customBranding';
import ThemedButton from '../../components/Buttons/ThemedButton';
import useCustomAssets from '../../hooks/useCustomAssets';

const COMPONENT_NAME = 'ClientDocuments';

function ClientDocuments(props) {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const [ createdByDocs, setCreatedByDocs ] = useState([]);
    const [ sharedWithDocs, setSharedWithDocs ] = useState([]);

    const organizationId = selector(selectOrganizationId());
    const clientProfile = selector(selectClientProfile());
    const currentUser = selector(selectCurrentUser());
    const dataItems = selector(selectDataItemsFromComponent(COMPONENT_NAME));

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('ClientDocuments', getClientLabelVariableData(clientProfile));

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];
        
        dispatch(setToolbarButtons(toolBarButtons));

        // Load data stream to upload collection
        const db = firebase.firestore();

        let createdDocStream = null;
        let sharedDocStream = null;

        if (organizationId === false || !clientProfile) {
            return;
        }

        createdDocStream = db.collection('Uploads').where('createdBy', '==', clientProfile.id).onSnapshot((querySnapshots) => {
            const docs = querySnapshots.docs.map((doc) => {
                const upload = doc.data();
                upload.id = doc.ref.id;
                upload.createdOn = upload.createdOn ? upload.createdOn.toDate() : null;
                upload.updatedOn = upload.updatedOn ? upload.updatedOn.toDate() : null;

                return upload;
            });

            setCreatedByDocs(docs);
            finishedLoading();
        });
        
        sharedDocStream = db.collection('Uploads').where('sharedWith', 'array-contains', clientProfile.id).onSnapshot((querySnapshots) => {
            const docs = querySnapshots.docs.map((doc) => {
                const upload = doc.data();
                upload.id = doc.ref.id;
                upload.createdOn = upload.createdOn ? upload.createdOn.toDate() : null;
                upload.updatedOn = upload.updatedOn ? upload.updatedOn.toDate() : null;

                return upload;
            });

            setSharedWithDocs(docs);
            finishedLoading();
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Documents',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        return () => {
            dispatch(setToolbarButtons([]));

            if (createdDocStream) {
                createdDocStream();
            }

            if (sharedDocStream) {
                sharedDocStream();
            }
        }
    }, []);

    // This effect is for combining the data from the two streams.
    useEffect(() => {
        const uploadDocuments = [ ...createdByDocs, ...sharedWithDocs ];
        dispatch(setDataItemsToComponent(uploadDocuments, COMPONENT_NAME));
    }, [createdByDocs, sharedWithDocs]);

    // Define data properties for filtering
    const columns = [
        { label: getDataLabel('titleData'), value: 'title', type: FILTER_TEXT },
        { label: getDataLabel('notesData'), value: 'notes', type: FILTER_TEXT },
        { label: getDataLabel('createdData'), value: 'createdOn', type: FILTER_DATE, isPrimary: true },
    ];

    const {
        getFilteredAndSortedItems,
        renderFilterSortPanel,
    } = useFilterSorterPanel(getLabel('filterSorterHeader'), columns, dataItems);

    const {
        showDialog: showMediaDialog,
        renderMediaViewer,
    } = useMediaViewer({});

    if (organizationId === false || !clientProfile) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    const buildEmptyPage = () => {
        const cardProps = { status: ALL, cardMediaUrl: getAsset('clientDocumentsEmpty'), headerTitle: getLabel('noResultsHeader'), subTitle: getLabel('noResultsSubheader'), buttonText: 'Back to Client Profile', buttonClick: () => history.goBack() };
        return (<GettingStartedPaper {...cardProps} key={cardProps.status} />);
    }

    const buildFilterSorterPanel = () => {
        return (
            <Cell size={12}>
                { renderFilterSortPanel() }
            </Cell>
        );
    }

    const openMediaDialog = (uploadId) => {
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Documents',
            action: 'view media',
            uploadId: uploadId,
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        showMediaDialog(uploadId);
    }

    const buildPreviewButtons = (uploadId, index) => {
        const buttons = [
            <ThemedButton key={`preview-button-${uploadId}`} className="md-cell--right" icon onClick={() => openMediaDialog(uploadId)}>preview</ThemedButton>
        ];

        return buttons;
    }

    const buildPreviewWrapper = (preview, key) => {
        return (
            <Cell key={key} desktopSize={3} tabletSize={4} phoneSize={4}>
                { preview }
            </Cell>
        );
    }

    function buildPageContent() {
        if (dataItems.length === 0) {
            return buildEmptyPage();
        }

        return (
            <Grid>
                { buildFilterSorterPanel() }
                <MediaPreviewArrayFromUploadId mediaArray={getFilteredAndSortedItems().map(x => x.id)} actionBuilder={buildPreviewButtons} wrapper={buildPreviewWrapper}/>
            </Grid>
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderMediaViewer() }
        </>
    );
}

export default ClientDocuments;