import React from 'react';
import { Avatar } from 'react-md';

function ProfilePictureAvatar(props) {
    const { innerText, pictureURL, style } = props;

    return (
        <Avatar className='profile-avatar' random src={pictureURL} style={style || {}} contentStyle={{width: 'inherit'}}>
            { innerText }
        </Avatar>
    );
}

export default ProfilePictureAvatar;