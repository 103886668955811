import React from 'react'
import { Card, CardActions, Button, Divider, CardTitle, CardText, Grid, Cell } from 'react-md';
import moment from 'moment';
import LabelList from '../LabelList';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../Buttons/ThemedButton';

const RepositoryCard = (props) => {
    const { onViewClick, onCaseNumberClicked = () => {}, entry } = props;

    const titleStyle = { display: 'block ', padding: '16px 8px 8px' };
    const contentStyle = { padding: '8px' };

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    return (
        <Card>
            <CardTitle
                style={titleStyle}
                title={<div className='single-line-overflow'>{ entry.title }</div>}
                subtitle={`${getDataLabel('createdByData')} ${entry.createdByName || ''}`}
            />
            <CardText style={contentStyle}>
                <Grid>
                    <Cell desktopSize={12} tabletSize={4} phoneSize={4}>
                        <p><b>{ `${getDataLabel('caseNumberData')}:` }</b> <a href={'#'} onClick={() => onCaseNumberClicked(entry.caseNumber)}>{ `${entry.caseNumber}` }</a></p>
                    </Cell>
                    <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                        <b>{ `${getDataLabel('createdData')}:` }</b>
                        <p>{ `${moment(entry.createdOn).format("MMM Do YYYY h:mm:ss a")}` }</p>
                    </Cell>
                    <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                        <b>{ `${getDataLabel('updatedData')}:` }</b>
                        <p>{ `${moment(entry.updatedOn).format("MMM Do YYYY h:mm:ss a")}` }</p>
                    </Cell>
                    <Cell desktopSize={12} tabletSize={4} phoneSize={4}>
                        <p><b>{`${getDataLabel('numAttachmentsData')}:`}</b>{` ${entry.uploads.length || 0}`}</p>
                    </Cell>
                </Grid>
                <LabelList list={entry.allCategories}/>
            </CardText>
            <Divider/>
            <CardActions>
                <ThemedButton flat primary onClick={() => onViewClick(entry.uid || entry.id)}> View Details </ThemedButton>
            </CardActions>
        </Card>
    )
}

export default RepositoryCard;