import { phoneValidate } from "../../components/RequestFields/renderToolboxField";


function validate(isUsingGroups) {
    return (values) => {
        let errors = {};
        
        if (!values.sms) {
            errors.sms = "SMS is Required";
        } else if (phoneValidate(values.sms)) {
            errors.sms = phoneValidate(values.sms);
        }
        
        if (!values.caseNumber) {
            errors.caseNumber = "Case number is Required";
        }
    
        if (!values.firstName) {
            errors.firstName = "First name is required";
        }
    
        if (!values.lastName) {
            errors.lastName = "Last name is required";
        }

        if (isUsingGroups && !values.orgGroup) {
            errors.orgGroup = "Organization Group is required";
        }
    
        return errors;
    }
}

export {
    validate,
};