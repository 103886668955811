import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Avatar, ListItem, List } from 'react-md';
import moment from 'moment'
import * as firebase from 'firebase'
import { CircularProgress } from 'react-md';
import { getValueFromContextData } from '../../utils/contextData';

class ChildrenDashboard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, children: null };
    }
    
    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { client } = this.props;
        const db = firebase.firestore();

        db.collection('Children').where('participants', 'array-contains', client.id).get().then((childrenDocRefs) => {
            const children = childrenDocRefs.docs.map((childDoc) => {
                const child = childDoc.data();

                child.id = childDoc.id;
                child.birthDay = getValueFromContextData(child.contextData, 'start', 'birthDate');
                child.age = new moment().diff(new moment(child.birthDay, 'YYYY-MM-DD'), 'years');
                return child;
            });

            this.setState({ isLoading: false, children });
        }).catch((err) => {
            console.log(err);
            return err;
        });
    }

    createEmptyCard = () => {
        return (<div align="center"><h3>No Children</h3></div>);
    }

    buildListItem = (item, index) => {
        return (
            <ListItem key={`${index}-${item.id}`} 
                leftAvatar={<Avatar suffix={index % 2 ? 'green' : 'orange'}>{item.firstName.substring(0, 1)}</Avatar>}
                primaryText={`${item.firstName} - ${item.age} years old`}
                secondaryText={item.birthDay}
                threeLines
            />
        );
    }

    render() {
        const { isLoading, children } = this.state;
    
        return isLoading ? <CircularProgress id="children-expanded-loading"/> : (
            <List>
                { children.map(this.buildListItem) }
            </List>
        );
    }
}

export default connect(null, null)(ChildrenDashboard);