import React, { useEffect } from 'react';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { selectCurrentUser, selectOrganizationId } from '../../selectors/global';
import { Tab } from 'react-md';
import { callCloudRunFunction } from '../../utils/firestore';
import { setToolbarTitle, setToolbarButtons } from '../../actions/global';
import OrganizationLabels from './OrganizationLabels';
import OrganizationTheme from './OrganizationTheme';
import { ThemedTabsContainer } from '../../components/ThemedTabs';
import OrganizationAssets from './OrganizatonAssets';

function OrganizationBranding() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        const toolBarTitle = `Organization Branding`;
        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];

        dispatch(setToolbarTitle(toolBarTitle));
        dispatch(setToolbarButtons(toolBarButtons));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Branding',
            action: 'visit page',
        };
        
        finishedLoading();
        callCloudRunFunction(currentUser, 'logProUserAction', log);

        return () => {
            dispatch(setToolbarTitle(''));
            dispatch(setToolbarButtons([]));
        }
    }, []);

    function buildPageContent() {
        const tabs = [
            { label: 'Labels', content: <OrganizationLabels/> },
            { label: 'Theme', content: <OrganizationTheme/> },
            { label: 'Assets', content: <OrganizationAssets/> },
        ];

        return (
            <ThemedTabsContainer>
                { tabs.map(x => <Tab id={x.label} label={x.label}>{x.content}</Tab>) }
            </ThemedTabsContainer>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default OrganizationBranding;