import React, { useEffect, useState } from 'react';
import { Grid, Cell, Card } from 'react-md';
import { selectCurrentUser, selectOrganizationId, selectDataItemsFromComponent } from '../../selectors/global';
import { setToolbarTitle, setToolbarButtons, setDataItemsToComponent } from '../../actions/global';
import { timestampConvertToDate } from '../../utils/dateTime';
import { callCloudRunFunction } from '../../utils/firestore';
import moment from 'moment';
import { FILTER_TEXT, FILTER_DATE, INITIAL, NO_RESULTS, ERROR, FILTER_NONE } from '../../constants';
import { NAV_DEFAULT, NAV_CLIENT_DETAILS } from '../../constants/navigation';
import { formatPhone } from '../../components/RequestFields/formatPhone';
import TableButton from '../../components/Buttons/TableButton';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { orgIsCoParenter } from '../../utils/permissions';
import UserSearchForm from './UserSearchForm';
import DataSheet from '../../components/ProTable/DataSheet';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import TableIconButton from '../../components/Buttons/TableIconButton';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import UserReviewConsent from '../../components/PopupContent/UserReviewConsent';
import usePopupConsentBuilder from '../../hooks/usePopupConsentBuilder';
import { NOTIFICATION_ERROR, NOTIFICATION_INFO, NOTIFICATION_SUCCESS } from '../../constants/notifications';

const COMPONENT_NAME = 'CoParentUsers';

function CoParenterUsers() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const dataItems = selector(selectDataItemsFromComponent(COMPONENT_NAME));
    const [ focusedReviewId, setFocusedReviewId ] = useState(null);

    const {
        finishedLoading,
        errorLoading,
        startLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent, buildErrorComponent: buildErrorComponent });

    useEffect(() => {
        const toolBarTitle = 'CoParenter User Search';
        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];
        dispatch(setToolbarTitle(toolBarTitle));
        dispatch(setToolbarButtons(toolBarButtons));
        dispatch(setDataItemsToComponent(null, COMPONENT_NAME));
        
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'User Search',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        finishedLoading();

        return () => {
            dispatch(setToolbarTitle(''));
            dispatch(setToolbarButtons([]));
        }
    }, []);

    const foundUser = dataItems && dataItems.find(x => x.id === focusedReviewId);

    const sendAppReviewConsent = {
        Component: UserReviewConsent,
        popupData: { title: `You are about to trigger an app review`},
        contentData: { name: foundUser ? foundUser.displayName : '' },
        onAccept: sendAppReview,
        onCancel: () => setFocusedReviewId(null),
    };

    const {
        showPopup: showAppReviewConsentPopup,
        renderPopupConsent: renderAppReviewPopupConsent,
    } = usePopupConsentBuilder(sendAppReviewConsent);

    if (!orgIsCoParenter(organizationId)) {
        history.push(NAV_DEFAULT);
        return(<></>);
    }

    function sendAppReview() {
        const appReviewUser = dataItems && dataItems.find(x => x.id === focusedReviewId);

        if (!appReviewUser) {
            showToastNotification(`Failed to send an app review`, NOTIFICATION_ERROR);
            setFocusedReviewId(null);
        }

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'App Review',
            action: 'send app review request',
            userId: focusedReviewId,
        };

        const promises = [
            callCloudRunFunction(currentUser, 'showAppReview', { userId: focusedReviewId }),
            callCloudRunFunction(currentUser, 'logProUserAction', log),
        ];

        showToastNotification(`Sending in-app review to ${appReviewUser.displayName}`, NOTIFICATION_INFO);
        
        Promise.all(promises).then(() => {
            showToastNotification(`The in-app review has been sucessfully sent to ${appReviewUser.displayName}`, NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`Failed to send an app review`, NOTIFICATION_ERROR);
        }).finally(() => {
            setFocusedReviewId(null);
        });
    }

    const initiateUserSearch = (searchValues) => {
        const metaData = {
            proId: currentUser.uid,
        };

        const searchData = Object.assign({}, searchValues, metaData);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'User Search',
            action: 'search users',
            metadata: {...searchData},
        };

        startLoading();
        callCloudRunFunction(currentUser, 'userSearch', searchData).then((results) => {
            const users = results.map((result) => {
                result.id = result.uid;
                result.createdOn = timestampConvertToDate(result.createdOn);
                result.updatedOn = timestampConvertToDate(result.updatedOn);
                result.displayName = `${result.firstName} ${result.lastName}`;

                return result;
            });

            dispatch(setDataItemsToComponent(users, COMPONENT_NAME));
            finishedLoading();
        }).catch((err) => {
            console.log(err);
            errorLoading();
        });
        callCloudRunFunction(currentUser, 'logProUserAction', log);
    }

    const sendAppReviewClicked = (clientId) => {
        setFocusedReviewId(clientId);
        showAppReviewConsentPopup();
    }

    const userRowClicked = (clientId) => {
        history.push(`${NAV_CLIENT_DETAILS}`, { clientId: clientId });
    }

    const buildForm = () => {
        return (
            <Cell size={12}>
                <UserSearchForm onSubmit={initiateUserSearch}/>
            </Cell>
        );
    }
    
    const buildEmptyCard = (status) => {
        const cardsInfo = [
            { status: INITIAL, cardMediaUrl: 'info_getHelp_close.png', headerTitle: 'Search the database for Users' , learnMoreLink: false  },
            { status: NO_RESULTS, cardMediaUrl: 'info_getHelp_close.png', headerTitle: `No results were found`, learnMoreLink: false  },
            { status: ERROR, cardMediaUrl: 'info_getHelp_close.png', headerTitle: `Something has gone wrong`, learnMoreLink: false  },
        ];

        const cardProps = cardsInfo.find(x => x.status === status);
        return (<GettingStartedPaper {...cardProps} key={cardProps.status} />);
    }

    function buildErrorComponent() {
        return (buildEmptyCard(ERROR));
    }

    function buildPageContent() {
        const columns = [
            { label: 'ID', value: 'id', type: FILTER_TEXT, fn: (item, id) => (<TableButton id={`user-${id}`} label={item} onClick={userRowClicked}/>) },
            { label: 'Name', value: 'displayName', type: FILTER_TEXT, fn: (item) => item },
            { label: 'Email', value: 'email', type: FILTER_TEXT, fn: (item) => item },
            { label: 'Phone Number', value: 'sms', type: FILTER_TEXT, fn: (item) => formatPhone(item) },
            { label: 'Date Created', value: 'createdOn', type: FILTER_DATE, fn: (item) => moment(item).calendar() },
            { label: 'Last Active', value: 'updatedOn', isPrimaryColumn: true, type: FILTER_DATE, fn: (item) => moment(item).calendar() },
            { label: 'Send App Review', value: 'rate_review', staticText: true, type: FILTER_NONE, fn: (label, id, value) => (<TableIconButton id={`review-${id}`} label={value} onClick={sendAppReviewClicked}/>) },
        ];

        return !dataItems
            ? buildEmptyCard(INITIAL)
            : dataItems.length === 0
                ? buildEmptyCard(NO_RESULTS)
                : <Card>
                    <DataSheet items={dataItems} columns={columns} componentName={COMPONENT_NAME} />
                </Card>
    }

    return (
        <>
            <Grid>
                { buildForm() }
                <Cell size={12}>{ renderPageContent() }</Cell>
            </Grid>
            { renderAppReviewPopupConsent() }
        </>
    );
}

export default CoParenterUsers;