import React from 'react';
import useCustomTheme from '../../hooks/useCustomTheme';
import ColorFormField from '../../components/FormFields/ColorFormField';
import useFormValidation from '../../hooks/useFormValidation';
import { Grid, Cell, Button } from 'react-md';
import LayoutPreview from '../../components/LayoutPreview';
import PictureFormField from '../../components/FormFields/PictureFormField';
import { THEME_COMPANY_LOGO, THEME_PRIMARY, THEME_PRIMARY_TEXT, THEME_SECONDARY, THEME_SECONDARY_TEXT, THEME_SECONDARY_ACTIVE_TEXT, THEME_BUTTON, THEME_BUTTON_TEXT, THEME_BUTTON_HOVER, THEME_BUTTON_TEXT_HOVER } from '../../constants/themeKeys';
import ThemedButton from '../../components/Buttons/ThemedButton';

function UpdateThemeForm(props) {
    const { onSubmit } = props;

    const {
        getTenantTheme,
        getDefaultTheme,
        getTheme,
    } = useCustomTheme();
    
    const INITIAL_STATE = {
        updatedCompanyLogo: null,
        primaryColor: getTheme(THEME_PRIMARY),
        primaryTextColor: getTheme(THEME_PRIMARY_TEXT),
        secondaryColor: getTheme(THEME_SECONDARY),
        secondaryTextColor: getTheme(THEME_SECONDARY_TEXT),
        secondaryTextActiveColor: getTheme(THEME_SECONDARY_ACTIVE_TEXT),
        buttonColor: getTheme(THEME_BUTTON),
        useDefaultLogo: false,
    };
    
    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, saveTheme);
    
    function saveTheme(values) {
        const theme = parseSubmissionToTheme(values);
        onSubmit(theme);
    }

    function parseSubmissionToTheme(values) {
        const theme = {
            currentCompanyLogoUploadId: getTenantTheme(THEME_COMPANY_LOGO),
            updatedCompanyLogo: values.updatedCompanyLogo,
            primaryColor: values.primaryColor,
            primaryTextColor: values.primaryTextColor,
            secondaryColor: values.secondaryColor,
            secondaryTextColor: values.secondaryTextColor,
            secondaryTextActiveColor: values.secondaryTextActiveColor,
            buttonColor: values.buttonColor,
            useDefaultLogo: values.useDefaultLogo,
        };

        return theme;
    }

    const setToDefault = () => {
        const DEFAULT_STATE = {
            updatedCompanyLogo: null,
            primaryColor: getDefaultTheme(THEME_PRIMARY),
            primaryTextColor: getDefaultTheme(THEME_PRIMARY_TEXT),
            secondaryColor: getDefaultTheme(THEME_SECONDARY),
            secondaryTextColor: getDefaultTheme(THEME_SECONDARY_TEXT),
            secondaryTextActiveColor: getDefaultTheme(THEME_SECONDARY_ACTIVE_TEXT),
            buttonColor: getDefaultTheme(THEME_BUTTON),
            useDefaultLogo: true,
        };

        changeValues(DEFAULT_STATE);
    }

    const resetForm = () => {
        changeValues(INITIAL_STATE);
    }

    return (
        <form id={'theme-form'} onSubmit={handleSubmit}>
            <Grid>
                <Cell desktopOffset={6} desktopSize={1} tabletOffset={2} tabletSize={1} phoneSize={4}>
                    <ThemedButton className='full-width-field' flat primary onClick={setToDefault}>Set Default</ThemedButton>
                </Cell>
                <Cell desktopOffset={1} desktopSize={1} tabletOffset={1} tabletSize={1} phoneSize={4}>
                    <ThemedButton className='full-width-field' flat primary onClick={resetForm}>Reset</ThemedButton>
                </Cell>
                <Cell desktopOffset={1} desktopSize={1} tabletOffset={1} tabletSize={1} phoneSize={4}>
                    <ThemedButton className='full-width-field' flat primary swapTheming type="submit">Save</ThemedButton>
                </Cell>
                <Cell desktopSize={3} tabletSize={8} phoneSize={4}>
                    <Grid>
                        <Cell size={12}>
                            <PictureFormField
                                name="updatedCompanyLogo"
                                values={values}
                                errors={errors}
                                label={'Company Logo'}
                                placeholder={'Recommended logo sizes 3:1, 4:1, or 5:1 ratio'}
                                currentPicture={null}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Cell>
                        <Cell desktopSize={6} tabletSize={3} phoneSize={2}>
                            <ColorFormField
                                name={THEME_PRIMARY}
                                label={'Primary Color'}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                            />
                        </Cell>
                        <Cell desktopSize={6} tabletSize={3} phoneSize={2}>
                            <ColorFormField
                                name={THEME_PRIMARY_TEXT}
                                label={'Primary Text Color'}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                            />
                        </Cell>
                        <Cell desktopSize={6} tabletSize={3} phoneSize={2}>
                            <ColorFormField
                                name={THEME_SECONDARY}
                                label={'Secondary Color'}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                            />
                        </Cell>
                        <Cell desktopSize={6} tabletSize={3} phoneSize={2}>
                            <ColorFormField
                                name={THEME_SECONDARY_TEXT}
                                label={'Secondary Text Color'}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                            />
                        </Cell>
                        <Cell desktopSize={6} tabletSize={3} phoneSize={2}>
                            <ColorFormField
                                name={THEME_SECONDARY_ACTIVE_TEXT}
                                label={'Secondary Active Text Color'}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                            />
                        </Cell>
                        <Cell desktopSize={6} tabletSize={3} phoneSize={2}>
                            <ColorFormField
                                name={THEME_BUTTON}
                                label={'Button Color'}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                            />
                        </Cell>
                    </Grid>
                </Cell>
                <Cell desktopSize={9} tabletSize={8} phoneSize={4}>
                    <LayoutPreview {...values}/>
                </Cell>
            </Grid>
        </form>
    );
}

export default UpdateThemeForm;