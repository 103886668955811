import React, { useState } from 'react';
import { Button, Cell, Grid } from 'react-md';
import { parseRoleControls, parseUserRoleToString, selectableOrganizationRoles, selectableClientViewRoles, parseRoleControlsStringToInt, selectableManagementRoles } from '../../utils/permissions';
import useFormValidation from '../../hooks/useFormValidation';
import CheckboxFormField from '../../components/FormFields/CheckboxFormField';
import SelectionFormField from '../../components/FormFields/SelectionFormField';
import TextFormField from '../../components/FormFields/TextFormField';
import { validate } from './updateProValidate';
import ToggleFormField from '../../components/FormFields/ToggleFormField';
import usePageFramework from '../../hooks/usePageFramework';
import { selectDataItemsFromComponent, selectAllOrganizationRoles, selectAllClientViewRoles, selectIsSuperAdmin, selectAllManagementRoles } from '../../selectors/global';
import DeactivationConfirmationForm from '../../components/Forms/DeactivationConfirmationForm';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';
import { parseUserGroupToString, parseGroupControls, parseGroupStringToArray } from '../../utils/orgGroups';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';
import { ORG_ROLE_VIEW_CLIENT, ORG_ROLE_MANAGE_ORG } from '../../constants/roles';

function UpdateProfessionalForm(props) {
    const { initialValues, onHide, onSubmit, redirectToAllocations, selectableOrgGroups } = props;
    const {
        selector,
    } = usePageFramework();

    const organizationRoles = selector(selectAllOrganizationRoles());
    const clientViewRoles = selector(selectAllClientViewRoles());
    const managementRoles = selector(selectAllManagementRoles());
    const isSuper = selector(selectIsSuperAdmin());
    const organizations = selector(selectDataItemsFromComponent('Organizations'));

    const { id, email, displayName, organizationId, isActive, createdOn, roles, mediationWeight, orgGroups } = initialValues;
    const hasSelectableGroups = selectableOrgGroups && selectableOrgGroups.length ? true : false;

    const [ focusedOrgId, setFocusedOrgId ] = useState(organizationId);
    const [ isActiveData, setIsActiveData ] = useState({});

    const focusedOrganization = organizations.find(x => x.id === focusedOrgId);
    
    const selectableOrgRoles = selectableOrganizationRoles(focusedOrganization, organizationRoles, true);
    const selectableViewRoles = selectableClientViewRoles(focusedOrganization, clientViewRoles);
    const selectableManagerRoles = selectableManagementRoles(focusedOrganization, managementRoles);

    const INITIAL_STATE = {
        id: id,
        email: email,
        isActive: isActive,
        displayName: displayName || '',
        organizationId: organizationId || '',
        createdOn: createdOn,
        orgRoles: parseUserRoleToString(roles.orgRoles, selectableOrgRoles),
        clientViewRoles: parseUserRoleToString(roles.clientViewRoles, selectableViewRoles),
        managementRoles: parseUserRoleToString(roles.managementRoles, selectableManagerRoles),
        orgGroups: parseUserGroupToString(orgGroups),
        requiresGroup: hasSelectableGroups,
        isActiveChangeConfirmation: '',
    };

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        changeValues,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, submitUpdate, validate);

    const deactivationProfessionalPopup = {
        FormComponent: DeactivationConfirmationForm,
        onSubmit: isActiveChangeConfirmed,
        popupData: { title: `Type \'${isActiveData.value ? 'Activate' : 'Deactivate'}\' to confirm ${isActiveData.value ? 'activating' : 'deactivating'} the account ${initialValues.email}.`, formId: 'update-active-professional-form' },
        formData: { formId: 'update-active-professional-form', initialValues: isActiveData },
    };

    const {
        showPopup: showDeactivationPopup,
        hidePopup: hideDeletePopup,
        renderPopupForm: renderDeactivationPopupForm,
    } = usePopupFormBuilder(deactivationProfessionalPopup);

    const organizationRoleControls = parseRoleControls(selectableOrganizationRoles(focusedOrganization, organizationRoles, true));
    const clientViewRoleControls = parseRoleControls(selectableClientViewRoles(focusedOrganization, clientViewRoles));
    const managementRoleControls = parseRoleControls(selectableManagementRoles(focusedOrganization, managementRoles));
    const showingClientControls = parseRoleControlsStringToInt(values.orgRoles) & ORG_ROLE_VIEW_CLIENT ? true : false;
    const showingManagementControls = parseRoleControlsStringToInt(values.orgRoles) & ORG_ROLE_MANAGE_ORG ? true : false;

    const disableFields = !values.isActive;
    const disableIsActiveButton = mediationWeight !== undefined && mediationWeight !== null && mediationWeight !== 0;

    const organizationGroupsControls = parseGroupControls(selectableOrgGroups);

    function handleOrgRoleChange(name, value) {
        // Clear selected client view roles if client viewing is toggled off
        const changes = {
            clientViewRoles: '',
            managementRoles: '',
            [name]: value,
        };

        const orgRoleValue = parseRoleControlsStringToInt(value);
        
        if (orgRoleValue & ORG_ROLE_VIEW_CLIENT) {
            delete changes.clientViewRoles;
        }

        if (orgRoleValue & ORG_ROLE_MANAGE_ORG) {
            delete changes.managementRoles;
        }

        changeValues(changes);
    }

    function submitUpdate(values) {
        const professionalUpdate = parseSubmissionToUpdate(values);
        onSubmit(professionalUpdate);
    }

    function parseSubmissionToUpdate(values) {
        const inviteObj = {
            id: values.id,
            displayName: `${values.displayName.trim()}`,
            email: values.email.toLowerCase(),
            organizationId: values.organizationId,
            isActive: values.isActive,
            roles: {
                orgRoles: parseRoleControlsStringToInt(values.orgRoles || ''),
                clientViewRoles: showingClientControls ? parseRoleControlsStringToInt(values.clientViewRoles || '') : 0,
                managementRoles: showingManagementControls ? parseRoleControlsStringToInt(values.managementRoles || '') : 0,
            },
            orgGroups: values.requiresGroup ? parseGroupStringToArray(values.orgGroups) : [],
        };

        return inviteObj;
    }

    function handleOrganizationChange(name, value) {
        changeValues({ orgRoles: '', clientViewRoles: '', managementRoles: '', [name]: value });
        setFocusedOrgId(value);
    }

    function handleIsActiveChange(name, value) {
        if (value !== initialValues.isActive) {
            setIsActiveData({ name, value });
            showDeactivationPopup();
        } else {
            handleChange(name, value);
        }
    }

    function isActiveChangeConfirmed(values) {
        const { name, value } = values.data;
        
        setIsActiveData({});
        handleChange(name, value);
    }

    return (
        <>
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="displayName"
                        label={getDataLabel('proNameData')}
                        values={values}
                        errors={errors}
                        disabled={disableFields}
                        customSize="title"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <TextFormField
                        name="email"
                        label={getDataLabel('emailData')}
                        values={values}
                        errors={errors}
                        disabled
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <ToggleFormField
                        name="isActive"
                        label="Professional Account Active"
                        values={values}
                        errors={errors}
                        disabled={disableIsActiveButton}
                        onChange={handleIsActiveChange}
                    />
                    { disableIsActiveButton && <p className='inactive-text'>This professional still has mediation weight allocated. If you wish to deactivate this account, first unallocate all mediation weightings from this professional. <a className='md-pointer--hover' onClick={redirectToAllocations}><b>Click here to manage mediation allocations.</b></a></p> }
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <SelectionFormField
                        name="organizationId"
                        label={getDataLabel('organizationData')}
                        values={values}
                        errors={errors}
                        menuItems={organizations}
                        itemLabel="org"
                        itemValue="id"
                        disabled={!isSuper || disableFields}
                        required
                        onChange={handleOrganizationChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <TextFormField
                        name="createdOn"
                        label={getDataLabel('createdData')}
                        values={values}
                        errors={errors}
                        disabled
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <CheckboxFormField
                        name="orgRoles"
                        label="Member Roles"
                        values={values}
                        errors={errors}
                        disabled={disableFields}
                        controls={organizationRoleControls}
                        onChange={handleOrgRoleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    {
                        showingClientControls &&
                            <CheckboxFormField
                                name="clientViewRoles"
                                label="Client Viewing Role(s) (Must have View Client Role Selected)"
                                values={values}
                                errors={errors}
                                disabled={disableFields}
                                controls={clientViewRoleControls}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    {
                        showingManagementControls &&
                            <CheckboxFormField
                                name="managementRoles"
                                label="Management Role(s) (Must have Manage Organization Role selected)"
                                values={values}
                                errors={errors}
                                disabled={disableFields}
                                controls={managementRoleControls}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    {
                        hasSelectableGroups && <CheckboxFormField
                            name="orgGroups"
                            label="Groups"
                            values={values}
                            errors={errors}
                            controls={organizationGroupsControls}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    }
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton flat primary swapTheming type="submit" disabled={isSubmitting} className='full-width-field'>Update</ThemedButton>
                </Cell>
            </Grid>
        </form>
        { renderDeactivationPopupForm() }
        </>
    );
}

export default UpdateProfessionalForm;