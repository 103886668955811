import React, { useState, useEffect } from 'react';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import moment from 'moment';
import * as firebase from 'firebase';
import { selectCurrentUser, selectOrganizationId, selectPropertyFromComponent, selectDataItemsFromComponent, selectIsSuperAdmin } from '../../selectors/global';
import { setToolbarButtons, setPropertyToComponent, setDataItemsToComponent, setUniqueChannelName } from '../../actions/global';
import { NEW, INBOUND, TAB_CONTENT_CARD, OPEN, TAB_CONTENT_TABLE, CLOSED, FILTER_TEXT, FILTER_NONE, FILTER_NUMBER, FILTER_DATE, ACCEPT, DECLINE } from '../../constants';
import { ThemedTabsContainer } from '../../components/ThemedTabs';
import { Tab, Badge } from 'react-md';
import TableButton from '../../components/Buttons/TableButton';
import TableIconButton from '../../components/Buttons/TableIconButton';
import AcceptRejectGrid from '../../components/AcceptRejectGrid';
import { callCloudRunFunction } from '../../utils/firestore';
import NewGetHelpCard from '../../components/NewGetHelpCard';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import { NOTIFICATION_INFO, NOTIFICATION_ERROR, NOTIFICATION_WARNING, NOTIFICATION_SUCCESS } from '../../constants/notifications';
import DetailsDialog from '../../components/DetailsDialog';
import MediationDetailContent from '../../components/DetailsDialog/MediationDetailContent';
import { getFirstAndLastName } from '../../utils/strings';
import ProTable from '../../components/ProTable';
import MediationAssignmentDialog from '../GetHelp/MediationAssignmentDialog';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NAV_MSGING } from '../../constants/navigation';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import useCustomAssets from '../../hooks/useCustomAssets';
import useNotifications from '../../hooks/useNotifications';
import ThemedBadge from '../../components/ThemedBadge';

const COMPONENT_NAME = 'GetHelps';

function Mediations(props) {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const userIsSuperAdmin = selector(selectIsSuperAdmin());
    const organizationId = selector(selectOrganizationId());
    const defaultTab = selector(selectPropertyFromComponent(COMPONENT_NAME, 'index'));
    const dataItems = selector(selectDataItemsFromComponent(COMPONENT_NAME));

    const [ professionals, setProfessionals ] = useState([]);

    const [ detailsVisible, setDetailsVisible ] = useState(false);
    const [ detailsInfo, setDetailsInfo ] = useState({});

    const [ reassignVisible, setReassignVisible ] = useState(false);
    const [ reassignInfo, setReassignInfo ] = useState({});

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('Mediations');

    const {
        hasMatchingNotification,
    } = useNotifications();

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        dispatch(setToolbarButtons([]));

        const db = firebase.firestore();

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Mediations',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        finishedLoading();
        return () => {}
    }, []);

    useEffect(() => {

        var professionalStream = null;
        const db = firebase.firestore();

        professionalStream = db.collection('ProApprovedProfessionals').where('organizationId', '==', String(organizationId)).onSnapshot((snapshot) => {
            const professionals = snapshot.docs.map((doc) => {
                const professional = doc.data();
                professional.id = doc.id;
                professional.createdOn = professional.createdOn ? professional.createdOn.toDate() : null;
                return professional;
            });

            setProfessionals(professionals);
        });

        return () => {
            if (professionalStream) {
                professionalStream();
            }
        }
    }, [ organizationId ]);

    useEffect(() => {
        const currentUserUid = currentUser.uid;

        var mediationStream = null
        const db = firebase.firestore();

        mediationStream = db.collection('getHelp').onSnapshot((querySnapshot) => {
            const dataItems = querySnapshot.docs.reduce((prev, getHelpDoc) => {
                const getHelpData = getHelpDoc.data();
                const currentUserMember = getHelpData.members.find(x => x.uid === currentUserUid);
                const isNew = getHelpData.status === 'Inbound';
                const orgIndex = getHelpData.orgAssociations && getHelpData.orgAssociations.length ? getHelpData.orgAssociations.findIndex(orgId => orgId === organizationId) : -1;
                
                if (isNew && orgIndex !== -1) {
                    console.log(orgIndex);
                }

                const isNewAssignedPro = (isNew && getHelpData.assignedProffesional && getHelpData.assignedProffesional === currentUserUid) || (isNew && !getHelpData.assignedProffesional && orgIndex !== -1) ? true: false;

                if (userIsSuperAdmin || isNewAssignedPro || currentUserMember) {
                    // Find members of the Mediation
                    const primaryCoParent = getHelpData.members.find(x => x.isPrimary);
                    const sentFromMember = getHelpData.members.find(x => x.uid === getHelpData.msgLastSentFrom);
                    const assignedPro = getHelpData.members.find(x => x.uid === getHelpData.assignedProffesional);

                    // Calculate read indexes
                    const lastReadIndex = !assignedPro ? 0 : assignedPro.msgReadIndex;
                    const lastUserReadIndex = currentUserMember ? currentUserMember.msgReadIndex : 0 || 0;

                    // Get names
                    const createdByFirstName = getFirstAndLastName(getHelpData.createdBy)[0];
                    const invitedCoParent = !getHelpData.invited || (!getHelpData.invited.includes(createdByFirstName) && !getHelpData.invited.includes(',')) ? true : false;
                    const assignedProfessionalObj = professionals && professionals.find(x => x.id === getHelpData.assignedProffesional);

                    getHelpData.id = getHelpDoc.id;
                    getHelpData.displayName = `${primaryCoParent.firstName} ${primaryCoParent.lastName}`;
                    getHelpData.assignedDelta = (getHelpData.msgIndex || 0) - lastReadIndex;
                    getHelpData.userDelta = (getHelpData.msgIndex || 0) - lastUserReadIndex;
                    getHelpData.sentFrom = !sentFromMember ? 'Unknown' : `${sentFromMember.firstName} ${sentFromMember.lastName}`;
                    getHelpData.msgLastSent = getHelpData.msgLastSent ? getHelpData.msgLastSent.toDate() : null;
                    getHelpData.createdOn = getHelpData.createdOn ? getHelpData.createdOn.toDate() : null;
                    getHelpData.assignedProfessionalName = assignedProfessionalObj && assignedProfessionalObj.displayName || '';
                    getHelpData.isAssignedProfessional = getHelpData.assignedProffesional === currentUserUid;
                    getHelpData.isSuper = userIsSuperAdmin;
                    getHelpData.invitedCoParent = invitedCoParent;

                    prev.push(getHelpData);
                }

                return prev;
            }, []);

            dispatch(setDataItemsToComponent(dataItems, COMPONENT_NAME));
            finishedLoading();
        });

        return () => {
            if (mediationStream) {
                mediationStream();
            }
        }
    }, [ userIsSuperAdmin, professionals ]);

    const hideDetailsDialog = () => {
        setDetailsVisible(false);
        setDetailsInfo({});
    }

    const hideReassignDialog = () => {
        setReassignVisible(false);
        setReassignInfo({});
    }

    const handleMediationReassignment = (reassignment) => {
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Mediations',
            action: 're-assign mediation',
            metadata: reassignment,
        };

        showToastNotification('Re-assigning mediation...', NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'updateMediationAssignment', reassignment).then(() => {
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            showToastNotification('Mediation was re-assigned', NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Mediation could not be re-assigned', NOTIFICATION_ERROR);
        });
    }

    const onTabChange = (newActiveTabIndex, tabId, tabControlsId, tabChildren, event) => {
        dispatch(setPropertyToComponent(newActiveTabIndex, COMPONENT_NAME, 'index'));
    }

    const onAcceptDeclineClicked = (evt) => {
        const [ buttonType, id ] = evt.target.id.split('-');

        // Get clicked Mediation data
        const selectedItem = dataItems.find(x => x.id === id);

        if (!selectedItem) {
            console.log(`Could not find GetHelp id ${id}`);
            return;
        }

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Mediations',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);

        // Accept Mediation
        if (buttonType === ACCEPT) {
            const title = selectedItem.getHelpType || 'mediation/coaching';
            const mediationChatData = { mediationId: id, mediatorId: currentUser.uid, category: 'getHelp', title };

            log.action = 'accept mediation';
            log.mediationId = id;
            log.metadata = mediationChatData;
                    
            showToastNotification('Accepting the Mediation. You will be redirected shortly.', NOTIFICATION_INFO);

            callCloudRunFunction(currentUser, 'acceptMediation', mediationChatData).then((result) => {
                callCloudRunFunction(currentUser, 'logProUserAction', log);
                dispatch(setUniqueChannelName({ id: result.id, category: 'getHelp', title }));
                history.push(NAV_MSGING);
            }).catch((err) => {
                console.log(err);
                showToastNotification('Failed to accept the mediation. Please try again later.', NOTIFICATION_ERROR);
            });

        // Decline Mediation
        } else if (buttonType === DECLINE) {
            log.action = 'decline mediation';
            log.mediationId = id;
            
            callCloudRunFunction(currentUser, 'declineMediation', { mediationId: id }).then(() => {
                showToastNotification('The mediation has been declined', NOTIFICATION_WARNING);
                callCloudRunFunction(currentUser, 'logProUserAction', log);
            }).catch((err) => {
                console.log(err);
                showToastNotification('Something has gone wrong. Please try again later.', NOTIFICATION_ERROR);
            });
        }
    }

    const onCPClicked = (mediationId) => {
        const foundMediation = dataItems.find(x => x.id === mediationId);
        const db = firebase.firestore();
        
        if (!foundMediation) return;

        const { id, members, chatId } = foundMediation;
        
        // If user is not part of members, add to members
        if (!members.find(x => x.uid === currentUser.uid)) {
            members.push({firstName: 'Mediator', lastName: '', hiddenName: currentUser.displayName, isProfessional: true, isPrimary: false, lastReadMessage: null, msgReadIndex: 0, uid: currentUser.uid});
            db.collection('getHelp').doc(id).update({members, updatedOn: new Date() });
        }

        // Load chat session
        dispatch(setUniqueChannelName({id, chatId, category: 'getHelp'}));
        history.push(NAV_MSGING);
    }

    const onDetailsClicked = (mediationId) => {
        const foundMediation = dataItems && dataItems.find(x => x.id === mediationId);

        if (foundMediation) {
            setDetailsVisible(true);
            setDetailsInfo(foundMediation);
        }
    }

    const onReassignClicked = (mediationId) => {
        const foundMediation = dataItems && dataItems.find(x => x.id === mediationId);

        if (foundMediation) {
            setReassignVisible(true);
            setReassignInfo(foundMediation);
        }
    }

    const buildEmptyCard = (status) => {
        const cardsInfo = [
            { status: INBOUND, cardMediaUrl: getAsset('mediationInboundEmpty'), headerTitle: getLabel('emptyInboundHeader'), subTitle: getLabel('emptyInboundSubheader'), learnMoreLink: true  },
            { status: OPEN, cardMediaUrl: getAsset('mediationOpenEmpty'), headerTitle: getLabel('emptyOpenHeader'), subTitle: getLabel('emptyOpenSubheader'), learnMoreLink: true  },
            { status: CLOSED, cardMediaUrl: getAsset('mediationClosedEmpty'), headerTitle: getLabel('emptyClosedHeader'), subTitle: getLabel('emptyClosedSubheader'), learnMoreLink: true  },
        ];

        const cardProps = cardsInfo.find(x => x.status === status);
        const returnCard = (<GettingStartedPaper {...cardProps} key={cardProps.status} />);

        return returnCard;
    }

    function buildPageContent() {

        const columns = [
            { label: getDataLabel('cpNameData'), value: 'displayName', type: FILTER_TEXT, fn: (item, id) => (<TableButton id={`gethelp-${id}`} label={item} onClick={onCPClicked}/>) },
            { label: getDataLabel('detailsData'), value: 'info', staticText: true, type: FILTER_NONE, fn: (item, id, value) => (<TableIconButton id={`details-${id}`} label={value} onClick={onDetailsClicked}/>) },
            { label: getDataLabel('deltaData'), value: 'userDelta', type: FILTER_NUMBER, isPrimaryColumn: false,  fn: (item, id) => <ThemedBadge badgeContent={hasMatchingNotification(id) ? item : 0}>{item}</ThemedBadge> },
            { label: getDataLabel('lastMemberMsg'), value: 'sentFrom', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('lastSentData'), value: 'msgLastSent', type: FILTER_DATE, fn: (item) => moment(item).fromNow() },
            { label: getDataLabel('createdData'), value: 'createdOn', type: FILTER_DATE, fn: (item) => moment(item).format("MMM Do YY") },
        ];

        if (userIsSuperAdmin) {
            columns.push({ label: getDataLabel('assignedDeltaData'), value: 'assignedDelta', type: FILTER_NUMBER, isPrimaryColumn: true,  fn: (item) => item });
            columns.push({ label: getDataLabel('assignedNameData'), value: 'assignedProfessionalName', type: FILTER_TEXT, fn: (item) => item });
        }

        columns.push({ label: getDataLabel('reassignData'), value: 'assignment_ind', staticText: true, type: FILTER_NONE, fn: (header, id, value, item) => (<TableIconButton id={`reassign-${id}`} disabled={!item.isAssignedProfessional && !userIsSuperAdmin} label={value} onClick={onReassignClicked}/>) });

        const buildTab = (status, contentType) => {
            return dataItems
                ? contentType === TAB_CONTENT_CARD
                    ? (<AcceptRejectGrid status={status} dataItems={dataItems} card={NewGetHelpCard} acceptReject={onAcceptDeclineClicked} detailsClick={onDetailsClicked} reassignClick={onReassignClicked} emptyCard={() => buildEmptyCard(status)} />)
                    : (<ProTable status={status} items={dataItems} columns={columns} key={status} componentName={COMPONENT_NAME} emptyCard={() => buildEmptyCard(status)} />)
                : buildEmptyCard(status);
        }

        const tabs = [
            { title: NEW, content: buildTab(INBOUND, TAB_CONTENT_CARD) },
            { title: OPEN, content: buildTab(OPEN, TAB_CONTENT_TABLE) },
            { title: CLOSED, content: buildTab(CLOSED, TAB_CONTENT_TABLE) },
        ];

        return (
            <ThemedTabsContainer panelClassName="md-grid" colored defaultTabIndex={defaultTab || 0} onTabChange={onTabChange}>
                { tabs.map(x => (<Tab key={`mediation-tab-${x.title}`} label={x.title}>{ x.content }</Tab>)) }
            </ThemedTabsContainer>
        );
    }

    return (
        <>
            { renderPageContent() }
            { <DetailsDialog content={MediationDetailContent} onHide={hideDetailsDialog} visible={detailsVisible} data={detailsInfo}/> }
            { <MediationAssignmentDialog visible={reassignVisible} onHide={hideReassignDialog} mediationData={reassignInfo} onSubmit={handleMediationReassignment}/> }
        </>
    );
}

export default Mediations;