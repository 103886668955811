import React from 'react';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications';
import useTrack from '../../hooks/useTrack';
import BandwidthWarning from '../BandwidthWarning';

export default function MainParticipantInfo({ participant, children }) {
    const publications = usePublications(participant);
    const videoPublication = publications.find(p => p.trackName === 'camera');
    const screenSharePublication = publications.find(p => p.trackName === 'screen');
    const isVideoEnabled = Boolean(videoPublication);
    const videoTrack = useTrack(screenSharePublication || videoPublication);
    const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);

    const styleIdentity = {
        background: 'green',
        fontSize: '1.2em',
        display: 'inline-flex',
        color: 'white',
        padding: '0px 5px',
        marginTop: '0px'
    };

    const infoContainer = {
        position: 'absolute',
        zIndex: 1,
        padding: '0.4em',
    }
    
    return (
        <div>
            <div style={infoContainer}>
                <h4 style={styleIdentity}>
                    {participant.identity}
                    {/* {!isVideoEnabled && <VideocamOff />} */}
                </h4>
            </div>
            {isVideoSwitchedOff && <BandwidthWarning />}
            {children}
        </div>
    );
}
