import React, { useState } from 'react';
import { DialogContainer, Button } from 'react-md';
import ThemedButton from '../components/Buttons/ThemedButton';

const onAcceptDefault = () => {
    console.log('Accepted');
}

const onCancelDefault = () => {
    console.log('Cancelled');
}

const DefaultComponent = () => <> </>;

function usePopupConsentBuilder({Component = DefaultComponent, onAccept = onAcceptDefault, onCancel = onCancelDefault, popupData = {}, contentData = {}}) {
    const { title = 'Consent Popup', negativeLabel = 'Cancel', positiveLabel = 'Confirm' } = popupData;
    const [ visible, setVisible] = useState(false);

    const showPopup = () => {
        setVisible(true);
    }

    const hidePopup = () => {
        setVisible(false);
        onCancel();
    }

    const acceptPopup = () => {
        setVisible(false);
        onAccept();
    }

    const renderPopupConsent = () => {
        const actions = [
            <ThemedButton flat onClick={hidePopup}>{negativeLabel}</ThemedButton>,
            <ThemedButton flat swapTheming onClick={acceptPopup}>{positiveLabel}</ThemedButton>,
        ];

        return (
            <DialogContainer
                id='popup-consent'
                aria-labelledby='popup-consent'
                visible={visible}
                onHide={hidePopup}
                title={title}
                actions={actions}
            >
                <Component {...contentData}/>
            </DialogContainer>
        );
    }

    return {
        showPopup,
        hidePopup,
        renderPopupConsent,
    }

}

export default usePopupConsentBuilder;