import React from 'react';
import { Toolbar } from "react-md";
import useCustomTheme from "../../hooks/useCustomTheme";

function ThemedToolbar(props) {
    const {
        getToolbarTheme,
    } = useCustomTheme();

    return (
        <Toolbar style={getToolbarTheme()} {...props}/>
    );
}

export default ThemedToolbar;