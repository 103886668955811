import React, { useEffect, useState } from 'react';
import { Tab, List } from 'react-md';
import * as firebase from 'firebase';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import { selectCurrentUser, selectClientProfile, selectOrganizationId } from '../../selectors/global';
import { setToolbarButtons } from '../../actions/global';
import ListTable from '../../components/ListTable';
import { ALL, FILTER_DATE, FILTER_TEXT } from '../../constants';
import { NOTIFICATION_INFO, NOTIFICATION_ERROR } from '../../constants/notifications';
import MessageTranscriptDialog from './MessageTranscriptDialog';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import ClientMessageCard from './ClientMessageCard';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { NAV_DEFAULT } from '../../constants/navigation';
import { callCloudRunFunction } from '../../utils/firestore';
import { getClientLabelVariableData } from '../../utils/customBranding';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import { ThemedTabsContainer } from '../../components/ThemedTabs';
import useCustomAssets from '../../hooks/useCustomAssets';

const COMPONENT_NAME = 'ClientCPMsgs';

function ClientMessages() {
    const {
        selector,
        dispatch,
        history,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const clientProfile = selector(selectClientProfile());

    const [ dataItems, setDataItems] = useState([]);
    const [ chatTabs, setChatTabs] = useState([]);
    const [ focusedTabId, setFocusedTabId] = useState(null);
    const [ dialogTranscriptVisible, setDialogTranscriptVisible ] = useState(false);

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('ClientMessages', getClientLabelVariableData(clientProfile));

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (!organizationId || !clientProfile) {
            return;
        }

        const toolBarButtons = [{ icon: 'arrow_back', onClick: () => history.goBack() }];
        dispatch(setToolbarButtons(toolBarButtons));

        // Grab chat data
        callCloudRunFunction(currentUser, 'getClientCoParentChatMessages', { clientId: clientProfile.uid, isUsingConnections: true }).then((result) => {
            const { messages, chats } = result;

            if (chats.length) {
                setFocusedTabId(chats[0].chatId);
            }

            setChatTabs(chats);
            setDataItems(messages);
            finishedLoading();
        }).catch((err) => {
            console.log(err);
            errorLoading();
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Messages',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);

        return () => {
            dispatch(setToolbarButtons([]));
        }
    }, []);

    const onGetTranscriptClick = (values) => {
        const { timezoneValue } = values;
        const cf = firebase.functions();
        const friendlyName = `${clientProfile.firstName} ${clientProfile.lastName}`;

        const pdfData = {
            to: currentUser.email,
            from: 'donotreply@coparenter.com',
            subject: 'coParenter Professional Transcript',
            text: `Attached below is the coParent messaging history for ${friendlyName}`,
            sid: focusedTabId,
            pdfName: `coParenter_Messaging_History_${friendlyName}`,
            startAt: undefined,
            endAt: undefined,
            timezone: timezoneValue,
        };

        const log = {
            proId: currentUser.uid,
            organizationId,
            clientId: clientProfile.uid,
            page: 'Client Messages',
            action: 'get transcript',
            chatId: focusedTabId,
        };

        setDialogTranscriptVisible(false);
        showToastNotification('The Messaging Transcript will be sent to your email. Please note, this may take a few minuets.', NOTIFICATION_INFO, {autoClose: 6000});

        cf.httpsCallable('createAndSendPDF')(pdfData).then(() => {
            callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Transcript has failed, please try again later.', NOTIFICATION_ERROR);
        });
    }

    if (!organizationId || !clientProfile) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    const createEmptyCard = () => {
        const cardProps = { status: ALL, cardMediaUrl: getAsset('clientMessagesEmpty'), headerTitle: getLabel('emptyResultsHeader'), subTitle: getLabel('emptyResultsSubheader'), buttonText: 'Back to Client Profile', buttonClick: () => history.goBack() };
        return (<GettingStartedPaper {...cardProps} key={cardProps.status} />);
    }

    const renderTable = (status) => {
        const columns = [
            { label: getDataLabel('authorData'), value: 'authorName', type: FILTER_TEXT },
            { label: getDataLabel('messageData'), value: 'body', type: FILTER_TEXT },
            { label: getDataLabel('createdData'), value: 'timestamp', type: FILTER_DATE }
        ];

        const buttons = [{ label: 'Get Transcript', icon: 'note', click: () => setDialogTranscriptVisible(true) }];

        const messagesByChat = dataItems.filter(x => x.chatId === status);

        return (
            <>
                <ListTable status={ALL} items={dataItems} filteredItems={(items) => setDataItems(items)} columns={columns} key={status} componentName={COMPONENT_NAME} buttons={buttons} emptyCard={createEmptyCard} />
                <List style={{ margin: '8px' }}>
                    { messagesByChat.map((item, index) => (<ClientMessageCard item={item} index={index}/>)) }
                </List>
            </>
        );
    }

    function buildPageContent() {
        return chatTabs.length === 0
            ? <p id="client-coParent-messages-none"> { getLabel('emptyHeader') }</p>
            : (
                <ThemedTabsContainer panelClassName="md-grid" onTabChange={(index, tabId) => setFocusedTabId(tabId)}>
                    { chatTabs.map(x => (<Tab key={`client-cp-messages-${x.chatId}`} id={x.chatId} label={x.title}>{ renderTable(x.chatId) }</Tab>)) }
                </ThemedTabsContainer>
            );
    }

    return (
        <>
            { renderPageContent() }
            <MessageTranscriptDialog onConfirm={onGetTranscriptClick} onHide={() => setDialogTranscriptVisible(false)} visible={dialogTranscriptVisible}/>
        </>
    );
}

export default ClientMessages;