import React, { useEffect } from 'react';
import moment from 'moment';
import { setToolbarTitle, setToolbarButtons, setDataItemsToComponent } from '../../actions/global';
import { selectClientProfile, selectOrganizationId, selectDataItemsFromComponent, selectCurrentUser} from '../../selectors/global';
import { formatPhone } from '../../components/RequestFields/formatPhone';
import ProTable from '../../components/ProTable';
import { FILTER_TEXT, FILTER_DATE, ALL } from '../../constants/index';
import { timestampConvertToDate } from '../../utils/dateTime';
import { callCloudRunFunction } from '../../utils/firestore';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { NAV_DEFAULT } from '../../constants/navigation';
import usePseudoStream from '../../hooks/usePseudoStream';
import { getClientLabelVariableData } from '../../utils/customBranding';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import useCustomAssets from '../../hooks/useCustomAssets';

const COMPONENT_NAME = 'ClientConnections';

function ClientConnections() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const clientProfile = selector(selectClientProfile());
    const organizationId = selector(selectOrganizationId());
    const dataItems = selector(selectDataItemsFromComponent(COMPONENT_NAME));

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('ClientConnections', getClientLabelVariableData(clientProfile));

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    usePseudoStream({ streamFunction: loadConnectionData });

    useEffect(() => {
        if (organizationId === false || !clientProfile) {
            return;
        }

        const toolBarButtons = [{ icon: 'arrow_back', onClick: () => history.goBack() }];
        dispatch(setToolbarButtons(toolBarButtons));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Connections',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);

        return () => {
            dispatch(setToolbarButtons([]));
        }
    }, []);

    if (organizationId === false || !clientProfile) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    function loadConnectionData() {
        callCloudRunFunction(currentUser, 'getClientConnections', { userId: clientProfile.id }).then((connections) => {
            const connectedUsers = connections.map((connection) => {
                connection.displayName = `${connection.firstName} ${connection.lastName}`;
                connection.createdOn = timestampConvertToDate(connection.createdOn);
                connection.updatedOn = timestampConvertToDate(connection.updatedOn);

                return connection;
            });
            
            dispatch(setDataItemsToComponent(connectedUsers, COMPONENT_NAME));
            finishedLoading();

        }).catch((err) => {
            console.log(err);
            errorLoading();
        });
    }

    const createEmptyCard = (status) => {
        const cardsInfo = [
            { status: ALL, cardMediaUrl: getAsset('clientConnectionsEmpty'), headerTitle: getLabel('noResultsHeader'), subTitle: getLabel('noResultsSubheader'), buttonText: 'Back to Client Profile', buttonClick: () => history.goBack() },
        ];

        const cardProps = cardsInfo.find(x => x.status === status);
        return (<GettingStartedPaper {...cardProps} key={cardProps.status}/>);
    }

    function buildPageContent() {
        const columns = [
            { label: getDataLabel('clientNameData'), value: 'displayName', isPrimaryColumn: true, type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('emailData'), value: 'email', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('phoneData'), value: 'sms', type: FILTER_TEXT, fn: (item) => formatPhone(item) },
            { label: getDataLabel('createdData'), value: 'createdOn', type: FILTER_DATE, fn: (item) => moment(item).calendar() },
            { label: getDataLabel('clientActiveData'), value: 'updatedOn', type: FILTER_DATE, fn: (item) => moment(item).calendar() },
            { label: getDataLabel('relationshipData'), value: 'role', type: FILTER_TEXT, fn: (item) => item },
        ];

        return (
            <ProTable items={dataItems} columns={columns} componentName={COMPONENT_NAME} emptyCard={() => createEmptyCard(ALL)} />
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default ClientConnections;