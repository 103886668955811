import React from 'react';
import { FontIcon } from 'react-md';
import useCustomLabels from '../../hooks/useCustomLabels';

function EmptyNotifications() {
    const centerStyle = { height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'};
    const alignmentStyle = { display:'grid' };
    const iconStyle = { fontSize: '48px', color: '#00BA34' };

    const {
        getLabel,
    } = useCustomLabels('Notifications');

    return(
        <div style={centerStyle}>
            <div style={alignmentStyle}>
                <FontIcon style={iconStyle}>thumb_up</FontIcon>
                <p>{ getLabel('noNotifications') }</p>
            </div>
        </div>
    );
}

export default EmptyNotifications;