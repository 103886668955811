import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { SelectField, Cell, Button } from 'react-md';
import { required } from '../../components/RequestFields/renderToolboxField';
import renderTextField from '../../components/RequestFields/renderTextField';
import { CLOSE_TYPES } from '../../constants/lookupInfo';
import renderToggleField from '../../components/RequestFields/renderToggleField';

const renderSelectField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <SelectField
        {...input}
        id={input.name}
        label={label}
        onChange={input.onChange}
        {...custom}
    />
);

class CloseForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { closeReason: '' };
    }

    render() {
        const { error, submitting, pristine, handleSubmit } = this.props;
        const fieldStyle = { width: '100%'};
        const formStyle = { paddingLeft: '25px', paddingRight: '25px', marginTop: '80px'}

        return (
            <form onSubmit={handleSubmit} style={formStyle} className="md-grid">
                <Cell size={12}>
                    <Field
                        id={`closeType`}
                        name={`closeType`}
                        label="Close Get Help"
                        menuItems={CLOSE_TYPES}
                        itemLabel="name"
                        itemValue="id"
                        required
                        validate={[required]}
                        style={fieldStyle}
                        component={renderSelectField}
                    />
                </Cell>
                <Cell size={12}>
                    <Field
                        id={`closeReason`}
                        name={`closeReason`}
                        label="Reason (If more details are needed)"
                        placeholder=""
                        rows={4}
                        maxRows={25}
                        style={fieldStyle}
                        component={renderTextField}
                    />
                </Cell>
                {error && <strong>{error}</strong>}
                <br></br>
                <h4>Only check this box if you are certain that coParent(s) will give a 5 star review!</h4>
                <Cell size={12}>
                    <Field
                        id={`sendAppReview`}
                        name={`sendAppReview`}
                        label={`Send an in-app review to the coParent(s)!`}
                        type={`checkbox`}
                        component={renderToggleField}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <Button className="full-width-field" disabled={pristine || submitting} type="submit" flat primary swapTheming>Submit</Button>
                </Cell>
            </form>
        );
    }
};

export default reduxForm({
    form: 'CloseForm',
    enableReinitialize: true,
})(CloseForm);
