import React from 'react';
import { DialogContainer, Card, Media, CircularProgress } from 'react-md';
import { REQUEST_GENERAL, REQUEST_ACTIVITY, REQUEST_SWAP } from '../../constants';
import ThemedButton from '../Buttons/ThemedButton';
import useCustomAssets from '../../hooks/useCustomAssets';
import useMediaLoader from '../../hooks/useMediaLoader';
import Fade from 'react-reveal/Fade';

const DetailsDialog = (props) => {
    const { content: DetailContents, data, visible, onHide } = props;

    const {
        getAsset,
    } = useCustomAssets('InformationCards');

    const actions = [
        <ThemedButton flat swapTheming id='dialog-close' onClick={onHide}>Close</ThemedButton>
    ];

    const categories = [
        // Categories to handle Mediation dialogs
        { category: 'getHelp', title: 'Mediation', img: 'wizMediation.png', assetKey: 'infoMediationCard' },
        { category: 'mediation', title: 'Mediation', img: 'wizMediation.png', assetKey: 'infoMediationCard' },
        { category: 'Parenting Plan', title: 'Parenting Plan', img: 'wizParentingPlan.png', assetKey: 'infoParentingCard' },
        { category: 'Coaching', title: 'Coaching', img: 'wizCoaching2.png', assetKey: 'infoCoachingCard' },
        { category: 'Covid', title: 'Covid Question', img: 'wizEvent.png', assetKey: 'infoEventCard' },

        // Categories to handle Agreement dialogs
        { category: 'General', title: 'Agreement', img: 'wizAgreement.png', assetKey: 'infoAgreementCard' },
        { category: 'Agreement', title: 'Agreement', img: 'wizAgreement.png', assetKey: 'infoAgreementCard' },
        { category: 'expenses', title: 'Agreement', img: 'wizAgreement.png', assetKey: 'infoExpensesCard' },
        { category: 'Activity', title: 'Agreement', img: 'wizAgreement.png', assetKey: 'infoAgreementCard' },
        { category: 'Swap', title: 'Agreement', img: 'wizAgreement.png', assetKey: 'infoAgreementCard' },
        { category: 'ParentingPlan', title: 'Agreement', img: 'wizAgreement.png', assetKey: 'infoAgreementCard' },

        // Categories to handle Request dialogs
        // TODO: Change img
        { category: REQUEST_GENERAL, title: 'Request', img: 'wizEvent.png', assetKey: 'infoRequestsCard' },
        { category: REQUEST_ACTIVITY, title: 'Request', img: 'wizEvent.png', assetKey: 'infoRequestsCard' },
        { category: REQUEST_SWAP, title: 'Request', img: 'wizEvent.png', assetKey: 'infoRequestsCard' },
    ];
    
    const categoryFound = categories.find(x => x.category === data.category);

    const {
        imageHasLoaded,
        imageSrc,
    } = useMediaLoader({ id: getAsset(categoryFound && categoryFound.assetKey), suppressNotification: true });

    if (!data || !data.category)
        return (<></>);

    const cardStyle = { padding: '0px', margin: '-24px'};

    return (
        <DialogContainer
            id="details-dialog-container"
            aria-describedby="details-dialog-container"
            visible={visible}
            onHide={onHide}
            actions={actions}
            display={'inline-block'}
            autosizeContent={true}
            width="1000px">
            <Card id="details-dialog-card" className="md-block-centered" style={cardStyle}>
                {
                    !imageHasLoaded
                        ? <CircularProgress centered/>
                        : <Media id="details-dialog-image" aspectRatio="4-1">
                            <Fade>
                                <img src={imageSrc} alt="org"/>
                            </Fade>
                        </Media>
                }
                { DetailContents && data && <DetailContents id="details-dialog-content" {...data}/> }
            </Card>
        </DialogContainer>
    );
};

export default DetailsDialog;
