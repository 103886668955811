import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Tab, Grid, Cell, LinearProgress } from 'react-md';
import { createStructuredSelector } from 'reselect';
import Calendar from 'react-calendar';
import * as firebase from 'firebase';
import { CalendarTileContent } from './CalendarTileContent';
import CalendarLegend from './CalendarLegend';
import ProCalendarDisplay from './ProCalendarDisplay';
import { timestampConvertToDate } from '../../utils/dateTime';
import { callCloudRunFunction } from '../../utils/firestore';
import { selectCurrentUser } from '../../selectors/global';
import { ThemedTabsContainer } from '../ThemedTabs';

class ProCalendar extends React.PureComponent {
    constructor() {
        super();
        this.dateChange = this.onDateChange.bind(this);
        this.activeDateChange = this.onActiveDateChange.bind(this);
        
        this.state = {
            isLoadingChildren: true,
            hasErrorLoadingChildren: false,
            children: [],
            isLoading: true,
            hasErrorLoading: false,
            scheduleData: {},
            selectedItemDate: new Date(),
        }
    }
    
    componentDidMount() {
        this.loadChildrenData().then(() => {
            this.loadScheduleData(new Date());
        }).catch((err) => {
            console.log(err);
            this.setState({
                isLoadingChildren: false,
                hasErrorLoadingChildren: true,
            });
            return err;
        });
    }

    loadChildrenData = () => {
        const { clientId } = this.props;

        const db = firebase.firestore();
        return db.collection('Children').where('participants', 'array-contains', clientId).get().then((childrenDocs) => {
            const children = childrenDocs.docs.map((docRef) => {
                const child = docRef.data();

                child.id = docRef.id;
                child.uid = docRef.id;
                child.birthDate = timestampConvertToDate(child.birthDate);
                child.createdOn = timestampConvertToDate(child.createdOn);
                child.updatedOn = timestampConvertToDate(child.updatedOn);

                return child;
            });

            this.setState({
                isLoadingChildren: false,
                children: children,
            });
        }).catch((err) => {
            console.log(err);
            this.setState({
                isLoadingChildren: false,
                hasErrorLoadingChildren: true,
            });
        });
    }

    loadScheduleData(newDate) {
        const { clientId, currentUser } = this.props;
        const { scheduleData } = this.state;
        const newYear = newDate.getFullYear();
        this.setState({ selectedItemDate: newDate });
        
        // Load schedule data if year/month mapping does not exist
        if (scheduleData[newYear]) {
            return;
        }

        this.setState({ isLoading: true, hasErrorLoading: false });

        callCloudRunFunction(currentUser, 'getAllSchedulesForYear', { uid: clientId, year: newYear }).then((scheduleResult) => {
            if (scheduleResult && scheduleResult instanceof Array) {
                scheduleData[newYear] = scheduleResult;

                this.setState({ isLoading: false, scheduleData });
            } else {
                this.setState({ isLoading: false, hasErrorLoading: true });
            }
        }).catch((err) => {
            console.log(err);
            this.setState({ isLoading: false, hasErrorLoading: true });
            return err;
        });
    }

    onDateChange = (value) => {
        this.loadScheduleData(value);
    }

    onActiveDateChange = (value) => {
        this.loadScheduleData(value.activeStartDate);
    }

    buildCalendarTileFunction = (child) => {
        const { isLoading, scheduleData } = this.state;

        return ({ date, view }) => {
            const tileYear = date.getFullYear();
            const tileMonth = date.getMonth();
            const tileDay = date.getDate();

            const dateItems = view === 'month' && scheduleData[tileYear] && scheduleData[tileYear][tileMonth] ? scheduleData[tileYear][tileMonth].find((data) => data.day === tileDay) : null
            const hasBirthday = !isLoading && child.birthDate.getMonth() === tileMonth && child.birthDate.getDate() === tileDay;
            return (
                <CalendarTileContent id={`pro-calendar-content-${child.uid}`} dateItems={dateItems} child={child} hasBirthday={hasBirthday}/>
            );
        }
    }

    buildCalendarTileStyleFunction = () => {
        const { highlightInterval } = this.props;
        const { selectedItemDate } = this.state;


        const { startDate, endDate } = highlightInterval;
        const momentStartDate = moment(startDate).startOf('day');
        const momentEndDate = moment(endDate).endOf('day');

        return ({ date, view }) => {

            if (view !== 'month') {
                return null;
            }

            const momentDate = moment(date);
            const momentSelectedDate = moment(selectedItemDate);

            const isActiveTile = momentDate.isSame(momentSelectedDate, 'day');
            const isIntervalTile = momentStartDate.isSameOrBefore(momentDate) && momentEndDate.isSameOrAfter(momentDate);
            
            if (isActiveTile) {
                return null;
            } else if (isIntervalTile) {
                return 'calendar-tile-highlight';
            } else {
                return null;
            }
        }
    }

    buildProCalendarTabs = () => {
        const { children } = this.state;

        return (
            !children.length
                ? <p id="pro-calendar-children-none">No children were found</p>
                : <div>
                    <CalendarLegend id="pro-calendar-legend"/>
                    <ThemedTabsContainer id="pro-calendar-tabs-container" colored>
                        { children.map((child) => this.buildProCalendarTab(child)) }
                    </ThemedTabsContainer>
                </div>
        );
    }

    buildProCalendarTab = (child) => {
        const { isLoading, hasErrorLoading } = this.state;

        return (
            <Tab id={`pro-calendar-tab-${child.firstName}-${child.lastName}`} label={`${child.firstName} ${child.lastName}`}>
                <Grid>
                    <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                        { this.buildProCalendar(child) }
                    </Cell>
                    <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                        { this.buildProCalendarDisplay(child) }
                    </Cell>
                </Grid>
                { isLoading && <p id="pro-calendar-schedule-loading">Loading...Please Wait</p> }
                { hasErrorLoading && <p id="pro-calendar-schedule-error" className="md-text--error">An error has occurred. Please try again later</p> }
            </Tab>
        );
    }

    buildProCalendar = (child) => {
        const { selectedItemDate } = this.state;
        
        return (
            <Calendar
                id={`pro-calendar-${child.firstName}-${child.lastName}`}
                calendarType="US"
                value={selectedItemDate}
                onClickDay={this.dateChange}
                onClickMonth={this.dateChange}
                onActiveDateChange={this.activeDateChange}
                tileContent={this.buildCalendarTileFunction(child)}
                tileClassName={this.buildCalendarTileStyleFunction()}
                activeStartDate={selectedItemDate}
            />
        );
    }

    buildProCalendarDisplay = (child) => {
        const { isLoading, scheduleData, selectedItemDate } = this.state;
        const displayYear = selectedItemDate.getFullYear();
        const displayMonth = selectedItemDate.getMonth();
        const displayDay = selectedItemDate.getDate();
        const dateItems = scheduleData[displayYear] && scheduleData[displayYear][displayMonth] ? scheduleData[displayYear][displayMonth].find((data) => data.day === displayDay) : null
        const hasBirthday = !isLoading && child.birthDate.getMonth() === displayMonth && child.birthDate.getDate() === displayDay;

        return (
            <ProCalendarDisplay dateItems={dateItems} child={child} hasBirthday={hasBirthday} date={selectedItemDate} />
        );
    }

    render() {
        const { isLoadingChildren, hasErrorLoadingChildren } = this.state;

        return isLoadingChildren
            ?  <LinearProgress />
            : hasErrorLoadingChildren
                ? <p id="pro-calendar-children-error" className="md-text--error">An error has occurred. Please try again later</p>
                : this.buildProCalendarTabs()
    }
}

export function mapDispatchToProps(dispatch) {
    return {}
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser(),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProCalendar);