import React, { useState, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import { Button } from 'react-md';
import { convertToARGBObject, convertARGBObjectToPreview, convertARGBObjectToARGB } from '../../utils/colors';

// Specialized color form field for ARGB format (AARRGGBB)
function AlphaColorFormField(props) {
    const { name, label, values, errors, onChange } = props;
    
    const color = values[name];
    const colorObject = convertToARGBObject(color);

    const formFieldId = `color-picker-${name}`;

    const [ colorPickerVisible , setColorPickerVisible ] = useState(false);

    useEffect(() => {
        if (colorPickerVisible) {
            window.addEventListener('click', handleWindowClick);
        } else {
            window.removeEventListener('click', handleWindowClick);
        }

        return () => {
            window.removeEventListener('click', handleWindowClick);
        }
    }, [ colorPickerVisible ]);

    const handleWindowClick = (e) => {
        const picker = document.getElementById(formFieldId);

        if (!picker || !picker.contains(e.target)) {
            setColorPickerVisible(false);
        }
    }

    const onColorChange = (color) => {
        const argb = convertARGBObjectToARGB(color.rgb);

        onChange(name, argb);
    }

    const previewClicked = () => {
        setColorPickerVisible(!colorPickerVisible);
    }

    const previewStyle = {
        width: '100%',
        height: '100%',
        background: convertARGBObjectToPreview(colorObject),
    };

    const pickerPopupStyle = {
        position: 'absolute',
        zIndex: '2',
    };

    const pickerStyle = {
        position: 'fixed',
    };

    return (
        <>
            <p><b>{ label }</b></p>
            <Button raised onClick={previewClicked}> <div style={previewStyle}/> </Button>
            <p>{ color }</p>
            <Fade duration={500} when={colorPickerVisible} top mountOnEnter unmountOnExit>
                <div style={pickerPopupStyle} id={formFieldId}>
                    <div style={pickerStyle}>
                        <ChromePicker
                            key={name}
                            color={colorObject}
                            onChange={onColorChange}
                        /> 
                    </div>
                </div>
            </Fade>
            <Bounce when={!!(errors && errors[name])}><p className="error-text">{errors && errors[name] || '...'}</p></Bounce>
        </>
    )
}

export default AlphaColorFormField;