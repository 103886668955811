import React, { useEffect } from 'react';
import usePageFramework from '../../hooks/usePageFramework';
import { selectCurrentUser, selectCurrentOrganization, selectOrganizationId } from '../../selectors/global';
import { Grid, Cell, Media, CardTitle, CardText, Card } from 'react-md';
import { setToolbarTitle } from '../../actions/global';
import { PATH_CARDS } from '../../constants';
import { useCustomPageFrameworkLabels } from '../../hooks/useCustomLabels';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { callCloudRunFunction } from '../../utils/firestore';

function AccountDisabled() {
    const {
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const currentOrganization = selector(selectCurrentOrganization());

    const userIsActive = currentUser.isActive;
    const orgIsActive = currentOrganization.isActive;

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('AccountDisabled');

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        dispatch(setToolbarTitle([]));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Account Disabled',
            action: 'visit page',
            metaData: {
                userIsActive: userIsActive,
                orgIsActive: orgIsActive,
            },
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        finishedLoading();
    }, []);

    const getMessage = () => {
        const orgInactive = { title: getLabel('orgDisabledHeader'), subtitle: getLabel('orgDisabledSubheader'), message: getLabel('orgDisabledMessage') };
        const userInactive = { title: getLabel('accountDisabledHeader'), subtitle: getLabel('accountDisabledSubheader'), message: getLabel('accountDisabledMessage') };
        const errorLoading = { title: getLabel('errorHeader'), subtitle: getLabel('errorSubheader'), message: getLabel('errorMessage') };

        return !orgIsActive
            ? orgInactive
            : !userIsActive
                ? userInactive
                : errorLoading;
    }

    const buildCardMessage = () => {
        const cardContents = getMessage();

        return (
            <Card className="home-card">
                <Media aspectRatio="4-1">
                    <img src={`${process.env.PUBLIC_URL}${PATH_CARDS}${'card_messageFromcoParenter.png'}`} alt="org"/>
                </Media>
                <CardTitle title={cardContents.title} subtitle={cardContents.subtitle}/>
                <CardText>
                    <p>{ cardContents.message }</p>
                </CardText>
            </Card>
        );
    }

    function buildPageContent() {
        return (
            <div className="welcomeBackground">
                <h1 className="welcomeHello">Home</h1>
                <Grid>
                    <Cell desktopSize={5} tabletSize={12} phoneSize={12} className="md-text-left home-card">
                        { buildCardMessage() }
                    </Cell>
                </Grid>
            </div>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default AccountDisabled;