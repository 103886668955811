import React from 'react';
import { Button } from 'react-md';
import useLocalVideoToggle from '../../hooks/useLocalVideoToggle';

export default function ToggleVideoButton({ disabled, localTracks, room }) {
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle(room, localTracks);
    const icon = isVideoEnabled ? 'videocam' : 'videocam_off';
    const style={ margin: '0px 95px'};
  
    return (
        <Button style={style} floating primary onClick={toggleVideoEnabled} disabled={disabled}>{icon}</Button>
    );
}
