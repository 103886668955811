import React from 'react';
import { FontIcon, ListItem, Button } from "react-md";
import moment from 'moment';
import usePageFramework from '../../hooks/usePageFramework';
import { setUniqueChannelName } from '../../actions/global';
import { NAV_MSGING } from '../../constants/navigation';
import { selectCurrentUser, selectOrganizationId } from '../../selectors/global';
import { callCloudRunFunction } from '../../utils/firestore';

function NotificationCard(props) {
    const { category, responseId, title, createdOn, uid, toDoId } = props;

    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());

    // TODO: define ProTool notification categories
    const buildLeftIcon = () => {
        if (category.endsWith('chat')) {
            return 'chat_bubble';
        }
        return 'notification';
    }

    
    const redirectToTopic = (evt) => {
        if (category.endsWith('chat')) {
            const log = {
                proId: currentUser.uid,
                organizationId: organizationId,
                page: 'Notification',
                action: 'clicked chat notification',
                chatId: responseId,
                metadata: {...props}
            };

            callCloudRunFunction(currentUser, 'logProUserAction', log).then(() => {
                const [chatCategory, chat] = category.split(' ');
                dispatch(setUniqueChannelName({ id: responseId, chatId: responseId, category: chatCategory}));
                history.push(NAV_MSGING);
            });
        }
    }
    
    const closeNotification = (evt) => {
        evt.stopPropagation();

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Notification',
            action: 'clicked chat notification',
            notificationId: toDoId,
            metadata: {...props}
        };
        
        callCloudRunFunction(currentUser, 'logProUserAction', log);
        callCloudRunFunction(currentUser, 'removeToDoById', { toDoId: toDoId });
    }

    return (
        <ListItem
            onClick={redirectToTopic}
            leftIcon={<FontIcon>{ buildLeftIcon() }</FontIcon>}
            rightAvatar={<Button icon style={{top: '0px'}} onClick={closeNotification}>close</Button>}
            primaryText={title}
            secondaryText={moment(createdOn).format("MMM Do YYYY h:mm:ss a")}
        />
    );
}

export default NotificationCard;