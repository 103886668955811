import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as REDUX from '../../../constants/redux';

export default function useDominantSpeaker() {
    const dispatch = useDispatch();
    const room = useSelector(state => state.get('global').get('room'));
    const dominantSpeaker = useSelector(state => state.get('global').get('dominantSpeaker'));
    
    useEffect(() => {
        console.log('SHOULD ONLY BE CALLED ONCE!');
        // Sometimes, the 'dominantSpeakerChanged' event can emit 'null', which means that
        // there is no dominant speaker. If we change the main participant when 'null' is
        // emitted, the effect can be jarring to the user. Here we ignore any 'null' values
        // and continue to display the previous dominant speaker as the main participant.
        const handleDominantSpeakerChanged = (newDominantSpeaker) => {
            
            if (newDominantSpeaker !== null) {
                console.log('dominate speaker changed!', newDominantSpeaker.identity);
                dispatch({ type: REDUX.DOMINANT_SPEAKER, payload: newDominantSpeaker });
            }
        };

        // Since 'null' values are ignored, we will need to listen for the 'participantDisconnected'
        // event, so we can set the dominantSpeaker to 'null' when they disconnect.
        const handleParticipantDisconnected = (participant) => {
            const newDominantSpeaker = dominantSpeaker === participant ? null : dominantSpeaker;
            dispatch({ type: REDUX.DOMINANT_SPEAKER, payload: newDominantSpeaker });
        };

        room.on('dominantSpeakerChanged', handleDominantSpeakerChanged);
        room.on('participantDisconnected', handleParticipantDisconnected);
        
        return () => {
            room.off('dominantSpeakerChanged', handleDominantSpeakerChanged);
            room.off('participantDisconnected', handleParticipantDisconnected);
        };
    }, [room]);

    return dominantSpeaker;
}
