import React from 'react';
import useFormValidation from '../../hooks/useFormValidation';
import { Grid, Cell } from 'react-md';
import AlphaColorFormField from '../../components/FormFields/AlphaColorFormField';
import ThemedButton from '../../components/Buttons/ThemedButton';
import TextFormField from '../../components/FormFields/TextFormField';
import { validate } from './UpdateTenantThemeValidate';
import TenantPageOrder from '../../components/TenantPageOrder';

function UpdateTenantThemeForm(props) {
    const { onSubmit, onDelete, formId, initialValues } = props;
    
    const INITIAL_STATE = {
        pagesOrder: initialValues.pagesOrder ? initialValues.pagesOrder.split(',') : [],
        subPagesOrder: initialValues.subPagesOrder ? initialValues.subPagesOrder.split(',') : [],
    };

    // Split tenant theme document into three categories;
    // listOrderKeys - keys for page ordering
    // colorKeys - keys with the word 'color'
    // jsonKeys - all other keys are stringified/parsed with JSON
    const listOrderKeys = [...Object.keys(INITIAL_STATE)];
    const colorKeys = [];
    const jsonKeys = [];
    
    // For now, this will grab all color fields and build the AlphaColorFormField component
    const colorRegex = /color/gi;
    Object.keys(initialValues).filter((key) => key.match(colorRegex)).forEach((key) => {
        INITIAL_STATE[key] = initialValues[key];
        colorKeys.push(key);
    });
    
    Object.keys(initialValues).filter((key) => !listOrderKeys.includes(key) && !colorKeys.includes(key)).forEach((key) => {
        INITIAL_STATE[key] = JSON.stringify(initialValues[key], null, 4);
        jsonKeys.push(key);
    });

    const parseSubmissionToTenantTheme = (values) => {
        const tenantTheme = {};
        
        listOrderKeys.forEach((key) => {
            tenantTheme[key] = values[key].join(',');
        });

        colorKeys.forEach((key) => {
            tenantTheme[key] = values[key].trim();
        });

        jsonKeys.forEach((key) => {
            tenantTheme[key] = JSON.parse(values[key]);
        });

        delete tenantTheme.uid;

        return tenantTheme;
    }

    const saveTenantTheme = (values) => {
        const tenantTheme = parseSubmissionToTenantTheme(values);
        onSubmit(tenantTheme);
    }
    
    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, saveTenantTheme, validate(listOrderKeys, colorKeys, jsonKeys));

    const deleteTenantTheme = () => {
        onDelete();
    }

    const resetForm = () => {
        changeValues(INITIAL_STATE);
    }

    const buildColorPicker = (key) => {
        return (
            <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                <AlphaColorFormField
                    name={key}
                    label={key}
                    values={values}
                    errors={errors}
                    onChange={handleChange}
                />
            </Cell>
        );
    }

    const buildTextFormField = (key) => {
        return (
            <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                <TextFormField
                    name={key}
                    label={key}
                    values={values}
                    errors={errors}
                    rows={4}
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Cell>
        );
    }

    return (
        <form id={formId} name={formId} onSubmit={handleSubmit}>
            <Grid>
                <Cell desktopOffset={6} desktopSize={1} tabletOffset={2} tabletSize={1} phoneSize={4}>
                    <ThemedButton className='full-width-field' flat primary onClick={deleteTenantTheme}>Delete Tenant Theme</ThemedButton>
                </Cell>
                <Cell desktopOffset={1} desktopSize={1} tabletOffset={1} tabletSize={1} phoneSize={4}>
                    <ThemedButton className='full-width-field' flat primary onClick={resetForm}>Reset</ThemedButton>
                </Cell>
                <Cell desktopOffset={1} desktopSize={1} tabletOffset={1} tabletSize={1} phoneSize={4}>
                    <ThemedButton className='full-width-field' flat primary swapTheming type="submit">Save</ThemedButton>
                </Cell>
                <Cell desktopSize={3} tabletSize={4} phoneSize={4}>
                    <Grid>
                        { colorKeys.sort().map(key => buildColorPicker(key)) }
                    </Grid>
                </Cell>
                <Cell desktopSize={3} tabletSize={4} phoneSize={4}>
                    <Grid>
                        { jsonKeys.sort().map(key => buildTextFormField(key)) }
                    </Grid>
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <TenantPageOrder onChange={changeValues} pagesOrder={values.pagesOrder} subPagesOrder={values.subPagesOrder}/>
                </Cell>
            </Grid>
        </form>
    );
}

export default UpdateTenantThemeForm;