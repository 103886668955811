import React from 'react';
import { Grid, Cell, Button } from 'react-md';
import useFormValidation from '../../hooks/useFormValidation';
import TextFormField from '../../components/FormFields/TextFormField';
import FileFormField from '../../components/FormFields/FileFormField';
import CheckboxFormField from '../../components/FormFields/CheckboxFormField';
import { validate } from './CreateEntryValidate';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';

function CreateEntryForm(props) {
    const { onSubmit, onHide, selectableOrgCategories = [], selectableChildrenCategories = [], selectableCoParentCategories = [] } = props;

    const INITIAL_STATE = {
        caseNumber: '',
        title: '',
        notes: '',
        uploads: [],
        organizationCategories: '',
        childrenCategories: '',
        coParentCategories: '',
    };

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, createNewEntry, validate, () => {return {}});

    function createNewEntry(values) {
        const entry = parseSubmissionToEntry(values);
        onSubmit(entry);
    }

    function parseSubmissionToEntry(values) {
        const entry = {
            caseNumber: values.caseNumber.trim(),
            title: values.title.trim(),
            notes: values.notes.trim(),
            uploads: values.uploads || [],
            organizationCategories: values.organizationCategories ? values.organizationCategories.split(',') : [],
            childrenCategories: values.childrenCategories ? values.childrenCategories.split(',') : [],
            coParentCategories: values.coParentCategories ? values.coParentCategories.split(',') : [],
        };

        return entry;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="caseNumber"
                        label={getDataLabel('caseNumberData')}
                        values={values}
                        errors={errors}
                        customSize="title"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="title"
                        label={getDataLabel('titleData')}
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="notes"
                        label={getDataLabel('notesData')}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <FileFormField
                        name="uploads"
                        label={getDataLabel('addItemsData')}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletSize={2} phoneSize={1}>
                    <CheckboxFormField
                        name="organizationCategories"
                        label={getDataLabel('generalCategoriesData')}
                        values={values}
                        errors={errors}
                        controls={selectableOrgCategories}
                        showSelectAllButton={false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletOffset={1} tabletSize={2} phoneSize={1}>
                    <CheckboxFormField
                        name="childrenCategories"
                        label={getDataLabel('childrenCategoriesData')}
                        values={values}
                        errors={errors}
                        controls={selectableChildrenCategories}
                        showSelectAllButton={false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={4} tabletOffset={1} tabletSize={2} phoneSize={1}>
                    <CheckboxFormField
                        name="coParentCategories"
                        label={getDataLabel('coParentCategoriesData')}
                        values={values}
                        errors={errors}
                        controls={selectableCoParentCategories}
                        showSelectAllButton={false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>Create Entry</ThemedButton>
                </Cell>
            </Grid>
        </form>
    );
}

export default CreateEntryForm;