import React from 'react';
import { Button } from 'react-md';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle';


export default function ToggleAudioButton({ disabled, localTracks }) {
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle(localTracks);
    const icon = isAudioEnabled ? 'mic' : 'mic_off';
    const style={ margin: '0px 0px 0px 130px'};
  
    return (
        <Button style={style} floating primary onClick={toggleAudioEnabled} disabled={disabled}>{icon}</Button>
    );
}
