import React from 'react';
import { ListItem, Avatar } from 'react-md';
import moment from 'moment';

function ClientMessageCard(props) {
    const { item, index } = props;
    return (
        <ListItem
            key={`${index}-${item.id}`}
            leftAvatar={<Avatar suffix={item.color}>{item.authorName.substring(0,1)}</Avatar>}
            primaryText={`${item.authorName} (${moment(item.timestamp).calendar()})`}
            secondaryText={item.body}
            threeLines
        />
    );
}

export default ClientMessageCard;