import { emailValidate, asyncValidateEmail } from '../../components/RequestFields/renderToolboxField';
import { asyncValidationChanges } from '../../utils/formFields';

function validate(values) {
    const errors = {};

    if (!values.email) {
        errors.email = "Email is required";
    } else if (emailValidate(values.email)) {
        errors.email = emailValidate(values.email);
    }

    if (!values.organizationId) {
        errors.organizationId = "Organization is required";
    }

    if (!values.firstName) {
        errors.firstName = "First name is required";
    }

    if (!values.lastName) {
        errors.lastName = "Last name is required";
    }

    if (!values.orgRoles) {
        errors.orgRoles = "A role is required"
    }

    if (values.requiresGroup && !values.orgGroups) {
        errors.orgGroups = "A group is required"
    }

    return errors;
}

async function asyncValidate(values, previousValues, existingErrors, previousAsyncErrors) {
    const asyncErrors = {};

    const asyncValidateEmailFunction = async () => {
        return await asyncValidateEmail(values.email, 'ProApprovedProfessionals');
    }

    const emailError = await asyncValidationChanges('email', values, previousValues, existingErrors, previousAsyncErrors, asyncValidateEmailFunction);
    if (emailError) {
        asyncErrors.email = emailError;
    }

    return asyncErrors;
};

export {
    validate,
    asyncValidate,
};