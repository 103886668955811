import usePageFramework from "./usePageFramework";
import { selectNotifications } from "../selectors/global";

function useNotifications() {
    const {
        selector,
    } = usePageFramework();

    const notifications = selector(selectNotifications());

    const getMatchingNotification = (id) => {
        return notifications && notifications.find(x => x.responseId === id) || null;
    }

    const hasMatchingNotification = (id) => {
        return getMatchingNotification(id)
            ? true
            : false
    }

    return {
        notifications,
        getMatchingNotification,
        hasMatchingNotification,
    }
}

export default useNotifications;