import { sortTextFunction } from "./sortFilter";

const parseGroupControls = (groups) => {
    return groups.map((x) => {
        return {
            label: x.name || '',
            value: x.uid,
        };
    }).sort((a, b) => {
        return sortTextFunction(a.value, b.value);
    });
}

const parseUserGroupToString = (userGroups) => {
    if (userGroups) {
        return userGroups.join(',');
    }

    return '';
}

const parseGroupStringToArray = (groupString) => {
    const parsedGroupArray = groupString.split(',').map(x => x.trim());

    return parsedGroupArray;
}

const getMatchingGroups = (groupIds, groups) => {
    const matchingGroups = [];

    groupIds.forEach((groupId) => {
        const matchingGroup = groups.find(group => group.uid === groupId);
        if (matchingGroup) {
            matchingGroups.push(matchingGroup);
        }
    });

    return matchingGroups;
}

export {
    parseGroupControls,
    parseUserGroupToString,
    parseGroupStringToArray,
    getMatchingGroups,
};