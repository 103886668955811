import React from 'react';
import { TextField } from "react-md";
import Bounce from 'react-reveal/Bounce';

export default function TextFormField (props) {
    const { name, values, errors, onChange } = props;

    const formProps = Object.assign({}, props);
    delete formProps['name'];
    delete formProps['values'];
    delete formProps['errors'];
    delete formProps['onChange'];

    return (
        <TextField
            value={values[name]}
            id={name}
            name={name}
            className="full-width-field"
            lineDirection="center"
            onChange={(value, event) => onChange(name, value)}
            error={errors && errors[name]}
            errorText={<Bounce when={errors && errors[name]}>{errors && errors[name] || '...'}</Bounce>}
            {...formProps}
        />
    );
}