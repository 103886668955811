import React from 'react'
import { Chip } from "react-md";

const LabelList = (props) => {
    const { list, itemStyle } = props;

    return (
        <div style={{marginBottom: '10px'}}>
            {
                list && list.map((item, index) =>
                    <Chip key={`${item}-${index}`} label={item} className='label-list' style={itemStyle}/>
                )
            }
        </div>
    );
}

export default LabelList;