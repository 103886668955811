import React from 'react';

function UserReviewConsent(props) {
    const { name } = props;

    return (
        <p>{`An in-app review request will be sent to ${name}! Are you sure you wish to continue?`}</p>
    );
}

export default UserReviewConsent;