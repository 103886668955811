import React from 'react';
import moment from 'moment';
import { Card, Media, CardActions, CircularProgress, CardText } from 'react-md';
import { getMessageBodyFromFile } from '../../utils/strings';
import { PATH_ICONS, PDF, IMAGE } from '../../constants';
import Fade from 'react-reveal/Fade';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import useMediaLoader from '../../hooks/useMediaLoader';

const uuidv4 = require('uuid/v4');

const MediaPreviewArrayFromFile = (props) => {
    const { mediaArray, actionBuilder = () => null, wrapper = (x) => x } = props;

    if (!mediaArray) {
        return (<></>);
    }

    return Array.from(mediaArray).map((file, index) => {
        const mediaId = uuidv4();
        const id = `media-preview-${mediaId}`;
        const labelId = `media-preview-label-${mediaId}`;

        const fileType = getMessageBodyFromFile(file);
        var initialImage = '#';

        switch (fileType) {
            case IMAGE:
                var reader = new FileReader();
                reader.onload = function(e) {
                    if (document.getElementById(id)) {
                        document.getElementById(id).src = e.target.result;
                    }
                }
                reader.readAsDataURL(file);
                break;
            case PDF:
                initialImage = `${process.env.PUBLIC_URL}${PATH_ICONS}2019.06.27-DRAFT-V1-ICON-PDF-02.svg`;
                break;
            default:
                break;
        }

        return [
            <MediaPreviewCard id={id} labelId={labelId} image={initialImage} label={file.name} actions={actionBuilder(file, index)}/>,
            file.name,
        ];
    }).map(([ mediaElement, key ]) => {
        return wrapper(mediaElement, key);
    });
}

const MediaPreviewCard = (props) => {
    const { id, labelId, label, image, actions } = props;

    return (
        <Card>
            <Media aspectRatio="1-1">
                <img id={id} src={image}/>
            </Media>
            <p id={labelId} className='single-line-overflow'>{label}</p>
            {
                !actions
                    ? <></>
                    : <CardActions>{actions}</CardActions>
            }
        </Card>
    );
}

const MediaPreviewArrayFromUploadId = (props) => {
    const { mediaArray = [], actionBuilder = () => null, wrapper = (x) => x } = props;

    return Array.from(mediaArray).map((uploadId, index) => {

        const mediaId = uuidv4();
        const id = `media-preview-${mediaId}`;
        const labelId = `media-preview-label-${mediaId}`;

        return [
            <MediaPreviewFirestoreCard id={id} labelId={labelId} uploadId={uploadId} actions={actionBuilder(uploadId, index)}/>,
            uploadId,
        ];
    }).map(([ mediaElement, key ]) => {
        return wrapper(mediaElement, key);
    })
}

function MediaPreviewFirestoreCard(props){
    const { id, labelId, uploadId, actions } = props;

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        imageHasLoaded,
        imageSrc,
        imageLabel,
        imageNote,
        imageDate,
    } = useMediaLoader({ id: uploadId });

    return (
        <Card>
            {
                !imageHasLoaded
                    ? <CircularProgress centered/>
                    : <Fade>
                        <Media aspectRatio="1-1">
                            <img id={id} src={imageSrc}/>
                        </Media>
                        <CardText>
                            <b>{ `${getDataLabel('titleData')}:` }</b>
                            <p id={labelId} className='single-line-overflow'>{imageLabel}</p>
                            <b>{ `${getDataLabel('notesData')}:` }</b>
                            <p id={`note-${labelId}`} className='single-line-overflow'>{imageNote}</p>
                            <b>{ `${getDataLabel('createdData')}:` }</b>
                            <p>{ moment(imageDate).format("MMM Do YYYY h:mm:ss a") }</p>
                        </CardText>
                    </Fade>
            }
            {
                !actions
                    ? <></>
                    : <CardActions>{actions}</CardActions>
            }
        </Card>
    );
}

export default MediaPreviewCard;

export {
    MediaPreviewArrayFromFile,
    MediaPreviewArrayFromUploadId,
};