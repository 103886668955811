import React from 'react';
import { useSelector } from 'react-redux';
import { selectOrganizationId } from '../../selectors/global';
import useFormValidation from '../../hooks/useFormValidation';
import { validate, asyncValidate } from './UpdateGroupValidate';
import { Grid, Cell, Button } from 'react-md';
import TextFormField from '../../components/FormFields/TextFormField';
import ThemedButton from '../../components/Buttons/ThemedButton';

function UpdateGroupForm(props) {
    const { initialValues, onSubmit, onHide } = props;

    const organizationId = useSelector(selectOrganizationId());

    const INITIAL_STATE = {
        uid: initialValues.uid,
        name: initialValues.name,
        description: initialValues.description,
        organizationId: organizationId,
    };

    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, updateGroup, validate, asyncValidate);

    function updateGroup(values) {
        const group = parseSubmissionToGroup(values);
        onSubmit(group);
    }

    function parseSubmissionToGroup(values) {
        const group = {
            id: initialValues.uid,
            name: values.name.trim(),
            description: values.description.trim(),
        };

        return group;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="name"
                        label="Group Name"
                        values={values}
                        errors={errors}
                        customSize="title"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="description"
                        label="Description"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>Update Group</ThemedButton>
                </Cell>
            </Grid>
        </form>
    )

}

export default UpdateGroupForm;