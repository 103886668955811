import usePageFramework from "./usePageFramework";
import { selectTenantLabelsFromKey, selectDefaultLabelsFromKey } from "../selectors/global";
import { getValueByKey, getValueFromItems } from "../utils/customBranding";
import { useState, useEffect } from "react";
import { setToolbarTitle, emptyToolbarTitle } from "../actions/global";
import { dataRegExpReplace } from "../utils/strings";
import { sortTextFunction } from "../utils/sortFilter";

const defaultTitleBarFunction = (title) => `${title}`;

function useCustomDataLabels() {
    return useCustomLabels('Data');
}

// Generalized custom label hook for pages
function useCustomPageFrameworkLabels(key, labelVariableData) {
    const labelData = {
        customTitleBar: { titleBarKey: 'titleBar' },
        labelVariableData: labelVariableData
    };

    return useCustomLabels(key, labelData);
}


// Generic hook for custom labels
function useCustomLabels(key, { customTitleBar, labelVariableData } = {}) {
    const { titleBarFunction = defaultTitleBarFunction, titleBarKey, titleBarDependencies } = customTitleBar || {};
    const {
        selector,
        dispatch,
    } = usePageFramework();
    
    // labelsHasUpdated and variablesHasUpdated are boolean variables which switches between true/false when the labels has changed/updated.
    // Used as a dependency in useEffect hooks to watch and reload on label changes.
    const [ labelsHasUpdated, setLabelsHasUpdated ] = useState(false);
    const [ variablesHasUpdated, setVariableHasUpdated ] = useState(false);
    const [ variableData, setVariableData ] = useState(labelVariableData);
    
    const tenantPageLabels = selector(selectTenantLabelsFromKey(key));
    const defaultPageLabels = selector(selectDefaultLabelsFromKey(key));

    const getDefaultRawLabel = (labelName) => getValueFromItems(defaultPageLabels, labelName);
    const getTenantRawLabel = (labelName) => getValueFromItems(tenantPageLabels, labelName);
    const getLabelData = () => defaultPageLabels || [];
    const getLabel = (labelName) => dataRegExpReplace(getValueByKey(tenantPageLabels, defaultPageLabels, labelName), variableData);
    const getLabelKeys = () => getLabelData().map(x => x.key).sort(sortTextFunction) || [];

    // Optional effect to help re-render the titlebar given custom labels
    useEffect(() => {
        if (!customTitleBar) return;

        dispatch(setToolbarTitle(titleBarFunction(getLabel(titleBarKey))));

        return () => {
            dispatch(emptyToolbarTitle());
        }
    }, [labelsHasUpdated, variablesHasUpdated, ...titleBarDependencies || [] ]);

    useEffect(() => {
        setLabelsHasUpdated(!labelsHasUpdated);
    }, [ tenantPageLabels, defaultPageLabels ]);

    useEffect(() => {
        if (JSON.stringify(variableData) !== JSON.stringify(labelVariableData)) {
            setVariableHasUpdated(!variablesHasUpdated);
            setVariableData(labelVariableData);
        }
    }, [ labelVariableData ]);

    // Other functions which access label data from Redux

    return {
        labelsHasUpdated,
        getLabel,
        getDefaultRawLabel,
        getTenantRawLabel,
        getLabelKeys,
        getLabelData,
    };
}

export default useCustomLabels;

export {
    useCustomDataLabels,
    useCustomPageFrameworkLabels,
};