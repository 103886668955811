import React, { PureComponent } from 'react';
import { DialogContainer } from 'react-md';

export default class ConfirmActionDialog extends PureComponent {
    render() {
        const { visible, onCancel, onConfirm, title, text } = this.props;

        const actions = [{
            id: 'dialog-cancel',
            primary: true,
            children: 'Cancel',
            onClick: onCancel,
        }, {
            id: 'dialog-confirm',
            swapTheming: true,
            primary: true,
            children: 'Confirm',
            onClick: onConfirm,
        }];

        return (
            <DialogContainer
                id="confirm-action-dialog"
                visible={visible}
                actions={actions}
                onHide={onCancel}
                title={title}
                focusOnMount={false}
            >
                <p>{text}</p>
            </DialogContainer>
        );
    }
}