import { createSelector } from 'reselect';
import { ORG_ROLE_SUPER_ADMIN } from '../constants/roles';
import { isNullOrUndefined } from 'util';

const selectStoreDomain = () => (state) => {
    return state.get('global');
}

const createSimpleSelector = (key, emptyValue = null) => () => createSelector(
    selectStoreDomain(),
    substate => substate.get(key) || emptyValue,
);

const selectAuthenication = () => createSelector(
    selectStoreDomain(),
    substate => substate.toJS(),
);

export const selectisAuthenticated = () => createSelector(
    selectStoreDomain(),
    substate => substate.get('isAuthenticated'),
);

// export const selectCurrentUserRoles = createSimpleSelector('currentUserRoles');

const userRoleSelector = createSimpleSelector('currentUserRoles');
export const selectUserOrganizationRoles = () => createSelector(
    userRoleSelector(),
    roles => !isNullOrUndefined(roles.orgRoles) ? roles.orgRoles : -1,
);

export const selectUserClientViewRoles = () => createSelector(
    userRoleSelector(),
    roles => roles.clientViewRoles || 0,
);

export const selectUserManagementRoles = () => createSelector(
    userRoleSelector(),
    roles => roles.managementRoles || 0,
);

export const selectCurrentUserRoles = () => createSelector(
    selectUserOrganizationRoles(),
    selectUserClientViewRoles(),
    selectUserManagementRoles(),
    (userOrgRoles, userClientRoles, userManagementRoles) => { return { orgRoles: userOrgRoles, clientViewRoles: userClientRoles, managementRoles: userManagementRoles } },
);

export const selectCurrentUserGroups = createSimpleSelector('currentUserGroups');

export const selectIsSuperAdmin = () => createSelector(
    selectCurrentUserRoles(),
    roles => roles.orgRoles & ORG_ROLE_SUPER_ADMIN ? true : false,
);

export const selectOrganizationId = createSimpleSelector('organizationId');

export const selectOrganizationName = () => createSelector(
    selectCurrentOrganization(),
    currentOrganization =>  currentOrganization && currentOrganization.organization || '',
);

export const selectCurrentOrganization = () => createSelector(
    selectOrganizationId(),
    selectDataItemsFromComponent('Organizations'),
    (organizationId, allOrgs = []) => allOrgs.find(x => x.id === organizationId),
);

export const selectCurrentUser = createSimpleSelector('currentUser');

export const selectUniqueChannelName = createSimpleSelector('uniqueChannelName');

export const selectIsAuthInfo = createSimpleSelector('isAuthInfo');

export const selectClientProfile = createSimpleSelector('clientProfile');

export const selectRoom = createSimpleSelector('room');

export const selectToolbarTitle = createSimpleSelector('toolbarTitle');

export const selectToolbarButtons = createSimpleSelector('toolbarButtons');

export const selectAllOrganizationRoles = createSimpleSelector('allOrganizationRoles');

export const selectAllClientViewRoles = createSimpleSelector('allClientViewRoles');

export const selectAllManagementRoles = createSimpleSelector('allManagementRoles');

export const selectNotifications = () => createSelector(
    selectCurrentUser(),
    selectDataItemsFromComponent('Notifications'),
    (currentUser, notifications) => {
        if (currentUser && currentUser.isActive) {
            return notifications || [];
        }

        return [];
    }
)

// Getters data from component key
export const selectPropertyFromComponent = (componentName, property) => createSelector(
    selectStoreDomain(),
    (substate) => substate.has(`dataItems${componentName}`) ? substate.get(`dataItems${componentName}`).get(property) : null
)

export const selectDataItemsFromComponent = (componentName) => createSelector(
    selectPropertyFromComponent(componentName, 'items'),
    items => items,
);

export const selectFilterFromComponent = (componentName) => createSelector(
    selectPropertyFromComponent(componentName, 'filter'),
    items => items,
);

export const selectSortFromComponent = (componentName) => createSelector(
    selectPropertyFromComponent(componentName, 'sort'),
    items => items,
);

// Selectors for custom theme
export const selectDefaultTheme = createSimpleSelector('defaultTheme', {});

export const selectTenantTheme = createSimpleSelector('tenantTheme', {});

// Selectors for custom labels
export const selectDefaultLabels = createSimpleSelector('defaultLabels');

export const selectTenantLabels = createSimpleSelector('tenantLabels');

export const selectDefaultLabelsFromKey = (key) => createSelector(
    selectDefaultLabels(),
    defaultLabels => defaultLabels && defaultLabels.find(x => x.key === key) && defaultLabels.find(x => x.key === key).labels || null
);

export const selectTenantLabelsFromKey = (key) => createSelector(
    selectTenantLabels(),
    tenantLabels => tenantLabels && tenantLabels.find(x => x.key === key) && tenantLabels.find(x => x.key === key).labels || null
);

// Selectors for custom assets

export const selectDefaultAssets = createSimpleSelector('defaultAssets');

export const selectTenantAssets = createSimpleSelector('tenantAssets');

export const selectDefaultAssetsFromKey = (key) => createSelector(
    selectDefaultAssets(),
    defaultAssets => defaultAssets && defaultAssets.find(x => x.key === key) && defaultAssets.find(x => x.key === key).assets || null
);

export const selectTenantAssetsFromKey = (key) => createSelector(
    selectTenantAssets(),
    tenantAssets => tenantAssets && tenantAssets.find(x => x.key === key) && tenantAssets.find(x => x.key === key).assets || null
);

export default selectAuthenication;