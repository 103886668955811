import React, { Component } from 'react';
import { Button, Media } from 'react-md';

class NotFoundPage extends Component {

    goBackHome = () => {
        this.props.history.push('/');
    }

    render() {
        return (
            <div className="centered">
                <Media aspectRatio="1-1">
                    <img src="/loading_coparenter.gif" height="50px" width="50px" alt="loading"/>
                </Media>
                <Button onClick={this.goBackHome} flat>
                    <h1> Something has gone wrong. Click here to go back </h1>
                </Button>
            </div>
        );
    }
};

export default NotFoundPage;