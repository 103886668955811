export const PARENT_ONE_DAY = 'p1';
export const PARENT_TWO_DAY = 'p2';
export const PARENT_ONE_PICKUP = 'p1PU';
export const PARENT_TWO_PICKUP = 'p2PU';
export const PARENT_ONE_VISIT = 'p1MU';
export const PARENT_TWO_VISIT = 'p2MU';
export const PARENT_PICKUP = 'PU';
export const PARENT_VISIT = 'MU';

export const NUM_DAYS_PER_WEEK = 7;
export const DEFAULT_DROP_PICKUP_TIME = '16:00';

export const SCHEDULE_CATEGORY_PARENTING_PLAN = 'parentingPlan';
export const SCHEDULE_CATEGORY_EXCHANGE_DAY = 'exchangeDay';
export const SCHEDULE_CATEGORY_SAME_DAY_VISIT = 'sameDayVisit';
export const SCHEDULE_CATEGORY_EVENT = 'event';
export const SCHEDULE_CATEGORY_APPOINTMENT = 'appointment';
export const SCHEDULE_CATEGORY_ACTIVITY = 'activity';
export const SCHEDULE_CATEGORY_EXPENSE = 'expense';
export const SCHEDULE_CATEGORY_SWAP = 'swap';
export const SCHEDULE_CATEGORY_HOLIDAY = 'holiday';
export const SCHEDULE_CATEGORY_BIRTHDAY = 'birthday';
export const SCHEDULE_CATEGORY_EXCHANGE_PICKUP = 'exchangePickup';
export const SCHEDULE_CATEGORY_EXCHANGE_DROPOFF = 'exchangeDropOff';

export const PARENTING_SCHEDULE_CATEGORIES = [
    SCHEDULE_CATEGORY_SWAP,
    SCHEDULE_CATEGORY_PARENTING_PLAN,
    SCHEDULE_CATEGORY_EXCHANGE_DAY,
    SCHEDULE_CATEGORY_SAME_DAY_VISIT,
    SCHEDULE_CATEGORY_EXCHANGE_PICKUP,
    SCHEDULE_CATEGORY_EXCHANGE_DROPOFF
];

export const ADDITIONAL_CATEGORIES = [
    SCHEDULE_CATEGORY_APPOINTMENT,
    SCHEDULE_CATEGORY_EVENT,
    SCHEDULE_CATEGORY_ACTIVITY,
    SCHEDULE_CATEGORY_EXPENSE,
    SCHEDULE_CATEGORY_HOLIDAY,
];

export const NO_SHARED_COPARENT_CONTROL = { label: 'None', value: '-1' };
export const OTHER_SHARED_COPARENT_CONTROL = { label: 'coParent 2', value: '-1' };