function validate (listOrderKeys, colorKeys, jsonKeys) {
    return (values) => {
        let errors = {};

        colorKeys.forEach((key) => {
            if (!values[key] || !values[key].trim()) {
                errors[key] = 'Required color';
            };
        });

        jsonKeys.forEach((key) => {
            try {
                JSON.parse(values[key]);
            } catch (error) {
                console.log(error);
                errors[key] = 'Value must be in JSON format';
            }
        });

        return errors;
    };
}

export {
    validate,
};