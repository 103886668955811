import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import moment from 'moment'
import * as firebase from 'firebase'
import ProTable from '../../components/ProTable';
import { convertToDateFromFirestoreDate } from '../../utils/dateTime';
import { selectDataItemsFromComponent } from '../../selectors/global';
import { ALL, FILTER_TEXT, FILTER_DATE, FILTER_NONE } from '../../constants'
import { setDataItemsToComponent } from '../../actions/global.js';
import { CircularProgress } from 'react-md';
import { getValueFromContextData } from '../../utils/contextData'

const COMPONENT_NAME = 'CheckInsDashboard';

class CheckInsDashboard extends PureComponent {
    constructor(props) {
        super(props);
        this.props.onSetDataItemsCheckInsDashboard([]);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { client, onSetDataItemsCheckInsDashboard } = this.props;

        const db = firebase.firestore();

        db.collection('CheckIns').where('participants', 'array-contains', client.id).get().then((querySnapshot) => {
            const dataItems = querySnapshot.docs.map((doc) => {
                const checkIn = doc.data();

                checkIn.notes = getValueFromContextData(checkIn.contextData, 'notes', 'notes');
                checkIn.mapUrl = getValueFromContextData(checkIn.contextData, 'start', 'mapUrl');
                checkIn.address = getValueFromContextData(checkIn.contextData, 'start', 'address');
                checkIn.createdOn = convertToDateFromFirestoreDate(checkIn.createdOn);

                return checkIn;
            });

            onSetDataItemsCheckInsDashboard(dataItems);
            this.setState({ isLoading: false });
        }).catch((err) => {
            console.log(err);
            return err;
        });
    }

    createEmptyCard = () => {
        const { client } = this.props;
        return (<div align="center"><h3>No Exchanges have been found for {client.friendlyName}</h3></div>);
    }

    render() {
        const { dataItems } = this.props;
        const { isLoading } = this.state;

        const columns = [                                                                              
            { label: 'Address', value: 'address', type: FILTER_TEXT, isGrow: true, fn: (item) => item },
            { label: 'Location (Google Maps)', value: 'mapUrl', type: FILTER_NONE,  fn: (item) => (<a target="_blank" href={item} rel="noopener noreferrer">View Map</a>) },
            { label: 'Notes', value: 'notes', type: FILTER_TEXT,  fn: (item) => item },
            { label: 'Created On', value: 'createdOn', isPrimaryColumn: true, type: FILTER_DATE, fn: (item) => moment(item).calendar() }
        ];

        return isLoading
            ? <CircularProgress id="checkIns-expanded-loading"/>
            : <ProTable status={ALL} items={dataItems} columns={columns} key={ALL} componentName={COMPONENT_NAME} emptyCard={() => this.createEmptyCard(ALL)}/>
    }
}

export function mapDispatchToProps(dispatch) {
    return { onSetDataItemsCheckInsDashboard: checkIns => dispatch(setDataItemsToComponent(checkIns, COMPONENT_NAME)) };     
}

const mapStateToProps = createStructuredSelector({ dataItems: selectDataItemsFromComponent(COMPONENT_NAME) });

export default connect(mapStateToProps, mapDispatchToProps)(CheckInsDashboard);