import React from 'react';
import { Card, CardText, CardTitle, Button, FontIcon, IconSeparator } from 'react-md';
import ProfilePictureAvatar from '../../components/ProfilePictureAvatar';
import { getInitials } from '../../utils/strings';
import { formatPhone } from '../../components/RequestFields/formatPhone';
import ThemedButton from '../../components/Buttons/ThemedButton';

function ProfileCard(props) {
    const { uid, displayName, email, sms, photoUrl, updateClicked, getLabel } = props;

    const avatarContainerStyle = { display: 'flex' };
    const avatarStyle = {marginLeft: 'auto', marginRight: 'auto', marginTop: '20px', marginBottom: '20px'};
    const infoPadding = { margin: '10px 0px' };

    return (
        <Card>
            <div style={avatarContainerStyle}>
                <ProfilePictureAvatar style={avatarStyle} pictureURL={photoUrl} innerText={getInitials(displayName)}/>
            </div>
            <CardTitle
                title={displayName}
            >
                { updateClicked && <ThemedButton className="md-cell--right" icon onClick={() => updateClicked(uid)}>edit</ThemedButton> }
            </CardTitle>
            <CardText>
                <b>{ getLabel('contactInfoHeader') }</b>
                <IconSeparator style={infoPadding} label={email || ''} iconBefore>
                    <FontIcon>email</FontIcon>
                </IconSeparator>
                <IconSeparator style={infoPadding} label={formatPhone(sms  || '')} iconBefore>
                    <FontIcon>phone</FontIcon>
                </IconSeparator>
            </CardText>
        </Card>
    );
}

export default ProfileCard;