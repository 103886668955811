const getValueFromContextData = (contextData, key, param) =>  {

    if (!contextData) {
        return null;
    }

    if (contextData[key] && contextData[key].find(x => x.param === param)) {
        return contextData[key].find(x => x.param === param).value;
    }

    return null;
}

export {
    getValueFromContextData
};