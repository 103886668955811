import React from 'react';
const STEP = 3;

export default function NetworkQualityLevel({ qualityLevel }) {
    if (qualityLevel === null) return null;

    const container = {
        display: 'flex',
        alignItems: 'flex-end',
        '& div': {
            width: '2px',
            border: '1px solid black',
            boxSizing: 'content-box',
            '&:not(:last-child)': {
                borderRight: 'none',
            },
        },
    };
  
    return (
        <div style={container}>
            {[0, 1, 2, 3, 4].map(level => (
                <div key={level}
                    style={{
                        height: `${STEP * (level + 1)}px`,
                        background: qualityLevel > level ? '#0c0' : '#040',
                    }}
                />
            ))}
        </div>
    );
}
