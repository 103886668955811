import React, { useEffect, useState } from 'react';
import useLoadingPageFramework from "../../hooks/useLoadingPageFramework";
import { Grid, Cell, Button } from 'react-md';
import { callCloudRunFunction } from '../../utils/firestore';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_INFO } from '../../constants/notifications';

const PRO = 'Professionals';
const INVITE = 'PendingInvites';
const CLIENT = 'Clients';

function CheckGroupFormComponents(props) {
    const { onSubmit, onHide, group, data } = props;

    if (!data) {
        return (<p>{'Looks like something has gone wrong. Please try again later'}</p>)
    }

    const hasLinks = Object.entries(data).map(([key, value]) => value !== 0).reduce((prev, curr) => prev || curr, false);

    const buildExistingLinksMessage = () => {
        return (
            <>
                <p> {`We have detected that there is data related to the group: ${group.name}`}</p>
                <p> {`There are ${data[PRO] || 0} Professional(s) related to this group`}</p>
                <p> {`There are ${data[INVITE] || 0} Client Invitation(s) related to this group`}</p>
                <p> {`There are ${data[CLIENT] || 0} Client(s) related to this group`}</p>
                <p> {`Please transfer all data to a different group first`}</p>
            </>
        );
    }

    const buildNoLinksMessage = () => {
        return (
            <>
                <p> {`There is no data related to the group ${group.name}`}</p>
                <p> {`Click the button below to continue deleting the group`}</p>
            </>
        );
    };

    const buildExitButton = () => {
        return (<Button className="full-width-field" onClick={onHide} flat primary swapTheming>Exit</Button>);
    }

    const buildDeleteButton = () => {
        return (<Button className="full-width-field" onClick={onSubmit} flat primary swapTheming>Continue Deleting</Button>);
    }

    return (
        <Grid>
            <Cell size={12}>
                { hasLinks ? buildExistingLinksMessage() : buildNoLinksMessage() }
            </Cell>
            <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    { hasLinks ? buildExitButton() : buildDeleteButton() }
            </Cell>
        </Grid>
    );
}

function CheckGroupForm(props) {
    const { onSubmit, onHide, currentUser, group, organizationId } = props;

    const [ summary, setSummary ] = useState(null);

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        // Search DB for clients/client invites/professionals related to the group.
        showToastNotification(`Checking for any data related to ${group.name}`, NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'checkOrganizationGroupLinks', {userId: currentUser.uid, organizationId: organizationId, groupId: group.uid }).then((result) => {
            setSummary(result);
            finishedLoading();
        }).catch((err) => {
            console.log(err);
            errorLoading();
        });
    }, []);

    function buildPageContent() {
        return (
            <CheckGroupFormComponents onSubmit={onSubmit} onHide={onHide} group={group} data={summary}/>
        );
    }

    return (
        <section className="md-grid">
            { renderPageContent() }
        </section>
    );
}

export default CheckGroupForm;