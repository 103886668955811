import React, { useState, useEffect } from 'react';
import moment from 'moment';
import CopyButton from '../../components/Buttons/CopyButton';
import { selectCurrentUser,selectDataItemsFromComponent, selectOrganizationId } from '../../selectors/global';
import { setToolbarTitle, setToolbarButtons } from '../../actions/global';
import { setOrganizationId } from '../../actions/thunks';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import ProTable from '../../components/ProTable';
import { ALL, ACTIVE, FILTER_DATE, FILTER_TEXT, FILTER_NONE, FILTER_BOOLEAN } from '../../constants';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants/notifications';
import { NAV_ORG } from '../../constants/navigation';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import TableIconButton from '../../components/Buttons/TableIconButton';
import { orgIsCoParenter } from '../../utils/permissions';
import CreateOrganizationForm from './CreateOrganizationForm';
import UpdateOrganizationForm from './UpdateOrganizationForm';
import useDialogFormBuilder from '../../hooks/useDialogFormBuilder';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import TableButton from '../../components/Buttons/TableButton';
import { callCloudRunFunction } from '../../utils/firestore';

const COMPONENT_NAME = 'Organizations';

function Organizations () {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const organizationId = selector(selectOrganizationId());
    const currentUser = selector(selectCurrentUser());
    const dataItems = selector(selectDataItemsFromComponent(COMPONENT_NAME));

    const [ focusedOrgId, setFocusedOrgId ] = useState(null);

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        const toolBarTitle = 'Organizations';
        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];
        dispatch(setToolbarTitle(toolBarTitle));
        dispatch(setToolbarButtons(toolBarButtons));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organizations',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        
        finishedLoading();
        return () => {
            dispatch(setToolbarButtons([]));
        }
    }, []);

    const foundOrg = dataItems && dataItems.find(org => org.id === focusedOrgId);

    // Setup for adding Organization dialog form
    const createOrgDialog = {
        FormComponent: CreateOrganizationForm,
        onSubmit: createOrganization,
        dialogData: { title: 'New Organization' },
    };

    const {
        showDialog: showCreateDialog,
        renderDialogForm: renderCreateDialogForm,
    } = useDialogFormBuilder(createOrgDialog);

    // Setup for updating an Organization dialog form
    const updateOrgDialog = {
        FormComponent: UpdateOrganizationForm,
        onSubmit: updateOrganization,
        dialogData: { title: 'Update Organization' },
        formData: { initialValues: foundOrg}
    };

    const {
        showDialog: showUpdateDialog,
        renderDialogForm: renderUpdateDialog,
    } = useDialogFormBuilder(updateOrgDialog);

    function createOrganization(orgData) {
        const orgMetadata = {
            onboardedByName: currentUser.displayName,
            onboardedByEmail: currentUser.email,
            status: ACTIVE,
            createdOn: new Date(),
            updatedOn: new Date(),
        };
        const organizationDocument = Object.assign({}, orgData, orgMetadata);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organizations',
            action: 'create organization',
            metadata: {...organizationDocument},
        };

        showToastNotification(`Creating ${organizationDocument.orgName}...`, NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'createOrganization', organizationDocument).then(() => {
            showToastNotification('Organization successfully created!', NOTIFICATION_SUCCESS);
        }).then(() => {
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    function updateOrganization(orgData) {
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organizations',
            action: 'update organization',
            orgId: orgData.id,
            metadata: {...orgData},
        };

        setFocusedOrgId(null);
        showToastNotification(`Updating ${orgData.organization}...`, NOTIFICATION_INFO);

        const promises = [];
        promises.push(callCloudRunFunction(currentUser, 'updateOrganization', orgData));
        promises.push(callCloudRunFunction(currentUser, 'logProUserAction', log));

        Promise.all(promises).then(() => {
            showToastNotification('Organization successfully updated!', NOTIFICATION_SUCCESS);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    const createEmptyCard = (status) => {
        const cardsInfo = [
            { status: ALL, cardMediaUrl: 'info_invitepending.png', headerTitle: 'No Organizations have been created!', buttonText: 'Get the sales team on this ;)', buttonClick: () => history.goBack() },
        ];

        const cardProps = cardsInfo.find(x => x.status === status);
        return (<GettingStartedPaper {...cardProps} key={cardProps.status} />);
    }

    function orgClicked(orgId) {
        const orgInfo = dataItems.find(x => x.id === orgId);

        if (orgInfo) {
            const log = {
                proId: currentUser.uid,
                organizationId: organizationId,
                page: 'Organizations',
                action: 'changed organization perspective',
                orgId: orgInfo.id,
            };

            callCloudRunFunction(currentUser, 'logProUserAction', log);
            dispatch(setOrganizationId(orgInfo.id));
            history.push(NAV_ORG);
        }
    }

    function editClicked(id) {
        const target = dataItems.find(x => x.id === id);

        if (!target) {
            console.log('No organization to edit');
            return;
        }

        setFocusedOrgId(id);
        showUpdateDialog();
    }

    function buildPageContent() {
        const buttons = [{ label: 'Add', icon: 'add', click: showCreateDialog }];

        const columns = [
            { label: 'Organization Name', value: 'organization', type: FILTER_TEXT, isGrow: true,  fn: (item, id) => (<TableButton id={`organization-${id}`} label={item} onClick={orgClicked}/>) },
            { label: 'Organization Active', value: 'isActive', type: FILTER_BOOLEAN, fn: (item) => item ? <div className="active-text">Active</div> : <div className="inactive-text">Disabled</div> },
            // { label: 'Status', value: 'status', type: FILTER_TEXT, fn: (item) => item },
            { label: 'State/Province', value: 'state', type: FILTER_TEXT, fn: (item) => item },
            { label: 'Created On', value: 'createdOn', type: FILTER_DATE, fn: (item) => moment(item).calendar() },
            { label: 'Users Onboarded', value: '', type: FILTER_NONE, fn: (item) => '0' },
            { label: 'Users Connected', value: '', type: FILTER_NONE, fn: (item) => '0' },
            { label: 'Edit', staticText: true, type: FILTER_NONE, value: 'edit',  fn: (item, id, value) => orgIsCoParenter(id) ? '-' : (<TableIconButton id={`edit-${id}`} disabled={!orgIsCoParenter(organizationId)} label={value} onClick={editClicked}/>)},
            { label: 'Dynamic Link', type: FILTER_NONE, value: 'dynamicLink',  fn: (item) => (<CopyButton copyTextValue={item} buttonText='Get Link' altText='Copied!' />) },
        ];

        return (
            <section className="md-grid">
                <ProTable status={ALL} items={dataItems} columns={columns} key={ALL} componentName={COMPONENT_NAME} buttons={buttons} emptyCard={() => createEmptyCard(ALL)}/>
            </section>
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderCreateDialogForm() }
            { renderUpdateDialog() }
        </>
    );

}

export default Organizations;