import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as firebase from 'firebase';
import { selectCurrentUser, selectOrganizationId, selectOrganizationName,
    selectDataItemsFromComponent } from '../../selectors/global';
import { setDataItemsToComponent, setToolbarButtons } from '../../actions/global';
import InviteProfessionalForm from './inviteProfessionalForm';
import UpdateProfessionalForm from './updateProfessionalForm';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import ProTable from '../../components/ProTable';
import { ACTIVE, FILTER_DATE, FILTER_TEXT, FILTER_BOOLEAN, ALL, FILTER_NONE } from '../../constants';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR, } from '../../constants/notifications';
import { NAV_DEFAULT, NAV_MEDIATION_ALLOCATION, NAV_PRO_PROFILE, NAV_MY_PROFILE } from '../../constants/navigation';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import TableIconButton from '../../components/Buttons/TableIconButton';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import useDialogFormBuilder from '../../hooks/useDialogFormBuilder';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';
import DeleteConfirmationForm from '../../components/Forms/DeleteConfirmationForm';
import { callCloudRunFunction } from '../../utils/firestore';
import TableButton from '../../components/Buttons/TableButton';
import MediationAssignmentConsent from '../MediationAllocation/MediationAssignmentConsent';
import ReassignMediationsForm from '../../components/MediationReassignment/ReassignMediationsForm';
import usePopupConsentBuilder from '../../hooks/usePopupConsentBuilder';
import { ORG_ROLE_MEDIATION } from '../../constants/roles';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import { getMatchingGroups } from '../../utils/orgGroups';
import useCustomAssets from '../../hooks/useCustomAssets';

const COMPONENT_NAME = 'Pro';

function Professionals() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const organizationName = selector(selectOrganizationName());
    const dataItems = selector(selectDataItemsFromComponent(COMPONENT_NAME));

    const [ focusedProId, setFocusedProId ] = useState(null);
    const [ deactivatedPros, setDeactivatedPros ] = useState([]);
    const foundPro = dataItems && dataItems.find(professional => professional.id === focusedProId);
    const [ selectableOrgGroups, setSelectableOrgGroups ] = useState([]);
    const [ isUsingGroups, setIsUsingGroups ] = useState(false);

    const labelVariableData = {
        organizationName: organizationName || '',
        name: foundPro && foundPro.displayName || '',
        email: foundPro && foundPro.email || '',
    };

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('Professionals', labelVariableData);

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (organizationId === false || currentUser === null) {
            return;
        }

        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];
        dispatch(setToolbarButtons(toolBarButtons));

        // var dataStreamProfessionals = null;
        var dataStreamGroups = null;
        const db = firebase.firestore();

        dataStreamGroups = db.collection('ProOrganizations').doc(organizationId).collection('Groups').onSnapshot((snapshot) => {
            const groups = snapshot.docs.map(doc => {
                const group = doc.data();

                group.createdOn = group.createdOn ? group.createdOn.toDate() : null;
                group.updatedOn = group.updatedOn ? group.updatedOn.toDate() : null;
                group.id = doc.id;
                return group;
            });
            
            setSelectableOrgGroups(groups);
            setIsUsingGroups(groups.length ? true : false);
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Professionals',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        return () => {
            dispatch(setToolbarButtons([]));

            if (dataStreamGroups) {
                dataStreamGroups();
            }
        }
    }, []);

    useEffect(() => {
        var dataStreamProfessionals = null;
        const db = firebase.firestore();

        dataStreamProfessionals = db.collection('ProApprovedProfessionals').where("organizationId", "==", String(organizationId)).onSnapshot((querySnapshot) => {
            const professionals = querySnapshot.docs.map((docRef) => {
                const professional = docRef.data();
                professional.id = docRef.ref.id;
                professional.uid = docRef.ref.id;

                professional.createdOn = professional.createdOn ? professional.createdOn.toDate() : null;
                professional.updatedOn = professional.updatedOn ? professional.updatedOn.toDate() : null;
                professional.orgGroupNames = getMatchingGroups(professional.orgGroups || [], selectableOrgGroups || []).map(x => x.name).join(', ');

                return professional;
            });

            dispatch(setDataItemsToComponent(professionals, COMPONENT_NAME));
            finishedLoading();
        });
        
        return () => {
            if (dataStreamProfessionals) {
                dataStreamProfessionals();
            }
        }
    }, [ selectableOrgGroups ]);

    const inviteProfessionalDialog = {
        FormComponent: InviteProfessionalForm,
        onSubmit: inviteProfessional,
        dialogData: { title: getLabel('createTitleBar') },
        formData: { selectableOrgGroups: selectableOrgGroups },
    };

    const {
        showDialog: showInviteDialog,
        renderDialogForm: renderInviteDialogForm,
    } = useDialogFormBuilder(inviteProfessionalDialog);

    const updateProfessionalDialog = {
        FormComponent: UpdateProfessionalForm,
        onSubmit: updateProfessional,
        dialogData: { title: getLabel('updateTitleBar') },
        formData: { initialValues: foundPro, redirectToAllocations: handleRedirectToAllocations, selectableOrgGroups: selectableOrgGroups }
    };

    const {
        showDialog: showUpdateDialog,
        hideDialog: hideUpdateDialog,
        renderDialogForm: renderUpdateDialogForm,
    } = useDialogFormBuilder(updateProfessionalDialog);

    const deleteProfessionalPopup = {
        FormComponent: DeleteConfirmationForm,
        onSubmit: deleteProfessional,
        popupData: { title: getLabel('deleteTitleBar'), formId: 'delete-professional-form' },
        formData: { formId: 'delete-professional-form', initialValues: foundPro },
    };

    const reassignMediationsDialog = {
        FormComponent: ReassignMediationsForm,
        onSubmit: reassignMediations,
        dialogData: { title: getLabel('reassignTitleBar') },
        formData: { recentlyZeroedPros: deactivatedPros, professionals: dataItems },
    };

    const {
        showDialog: showReassignDialog,
        renderDialogForm: renderReassignDialogForm,
    } = useDialogFormBuilder(reassignMediationsDialog);

    const reassignMediationConsent = {
        Component: MediationAssignmentConsent,
        popupData: { title: 'The Mediator will not have access to the Mediations feature any more. Do you wish to re-assign their active mediations?', negativeLabel: 'No', positiveLabel: 'Yes' },
        contentData: { professionals: deactivatedPros },
        onAccept: showReassignDialog,
        onCancel: () => setDeactivatedPros([]),
    };

    const {
        showPopup: showReassignConsentPopup,
        renderPopupConsent: renderReassignConsentPopup,
    } = usePopupConsentBuilder(reassignMediationConsent);

    const {
        showPopup: showDeletePopup,
        renderPopupForm: renderDeletePopupForm,
    } = usePopupFormBuilder(deleteProfessionalPopup);

    if (organizationId === false || currentUser == false) {
        history.push(NAV_DEFAULT);
        return(<></>);
    }

    const updateProfessionalClicked = (proId) => {
        if (!proId) return;

        setFocusedProId(proId);
        showUpdateDialog();
    }

    const deleteProfessionalClicked = (id) => {
        setFocusedProId(id);
        showDeletePopup();
    }

    const viewProfessionalProfile = (proId) => {
        if (!proId) return;
        const professional = dataItems.find(x => x.id === proId);

        proId === currentUser.uid
            ? history.push(NAV_MY_PROFILE)
            : history.push(NAV_PRO_PROFILE, { professional })
    }

    function inviteProfessional(inviteData) {
        const inviteMetadata = {
            invitedByEmail: currentUser.email,
            invitedByName: currentUser.displayName,
            redirectUrl: window.location.hostname
        };

        const invite = Object.assign({}, inviteData, inviteMetadata);

        console.log(invite);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Professionals',
            action: 'send professional invite',
            metadata: { ...invite }
        };

        showToastNotification(`Sending invite to ${invite.displayName}...`, NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'createInviteProfessional', invite).then(() => {
            showToastNotification('The invitiation has been sent!', NOTIFICATION_SUCCESS);
        }).then(() => {
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('An error has occurred', NOTIFICATION_ERROR);
        });
    }

    function updateProfessional(updateData) {
        const foundProfessional = dataItems && dataItems.find(professional => professional.id === updateData.id);

        const promises = [];

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Professionals',
            action: 'update professional',
            professionalId: updateData.id,
            metadata: { ...updateData },
        };

        setFocusedProId(null);
        showToastNotification(`Updating ${updateData.displayName}...`, NOTIFICATION_INFO);
        
        promises.push(callCloudRunFunction(currentUser, 'updateProApprovedProfessionals', updateData));
        promises.push(callCloudRunFunction(currentUser, 'logProUserAction', log));

        Promise.all(promises).then(() => {
            showToastNotification(`${updateData.displayName} has been updated!`, NOTIFICATION_SUCCESS);


            const wasDeactivated = foundProfessional.isActive === true && updateData.isActive === false;
            const revokedMediationRole = foundProfessional.roles.orgRoles & ORG_ROLE_MEDIATION && !(updateData.roles.orgRoles & ORG_ROLE_MEDIATION);

            if (wasDeactivated || revokedMediationRole) {
                console.log('transfer mediations');
                setDeactivatedPros([foundProfessional]);
                showReassignConsentPopup();
            }
        }).catch((err) => {
            console.log(err);
            showToastNotification('An error has occurred', NOTIFICATION_ERROR);
        });
    }

    function handleRedirectToAllocations() {
        hideUpdateDialog();
        history.push(NAV_MEDIATION_ALLOCATION);
    }

    function deleteProfessional(deleteData) {
        const professionalToDelete = dataItems && dataItems.find(professional => professional.id === deleteData.id);

        if (!professionalToDelete) {
            showToastNotification(`No such Professional to delete`, NOTIFICATION_ERROR);
            return;
        }

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Professionals',
            action: 'delete professional',
            professionalId: deleteData.id,
            metadata: { ...professionalToDelete },
        };

        setFocusedProId(null);
        showToastNotification(`Deleting professional user...`, NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'deleteProApprovedProfessional', { id: deleteData.id }).then(() => {
            showToastNotification(`Professional has been deleted!`, NOTIFICATION_SUCCESS);
        }).then(() => {
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    function reassignMediations(reassignments) {
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Professionals',
            action: 're-assign mediations',
            metadata: reassignments,
        };

        const promises = [];

        reassignments.forEach((reassignment) => {
            promises.push(callCloudRunFunction(currentUser, 'updateMediationAssignment', reassignment));
        });

        promises.push(callCloudRunFunction(currentUser, 'logProUserAction', log));

        showToastNotification('Re-assigning mediations...', NOTIFICATION_INFO);

        Promise.all(promises).then(() => {
            showToastNotification('Mediations were re-assigned', NOTIFICATION_SUCCESS);
            setDeactivatedPros([]);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Mediations could not be re-assigned', NOTIFICATION_ERROR);
        });
    }

    const createEmptyCard = (status) => {
        const cardsInfo = [
            { status: ALL, cardMediaUrl: getAsset('professionalsEmpty'), headerTitle: getLabel('emptyResultsHeader'), subTitle: getLabel('emptyResultsSubheader'), buttonText: 'Invite', buttonClick: showInviteDialog },
        ];

        const cardProps = cardsInfo.find(x => x.status === status);
        return (<GettingStartedPaper {...cardProps} key={cardProps.status} />);
    }

    function buildPageContent() {
        const buttons = [ { label: 'Invite', icon: 'add', click: showInviteDialog } ];

        const columns = [                                                                              
            { label: getDataLabel('proNameData'), value: 'displayName', type: FILTER_TEXT, isGrow: true, fn: (item, id) => (<TableButton id={`professional-${id}`} label={item} onClick={viewProfessionalProfile}/>) },
            { label: getDataLabel('permissionsData'), staticText: true, type: FILTER_NONE, value: 'lock',  fn: (item, id, value) => <TableIconButton id={`permissions-${id}`} label={value} onClick={updateProfessionalClicked}/>},
            { label: getDataLabel('emailData'), value: 'email', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('accountStatusData'), value: 'status', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('accountActiveData'), value: 'isActive', type: FILTER_BOOLEAN, fn: (item) => item ? <div className="active-text">Active</div> : <div className="inactive-text">Disabled</div> },
            { label: getDataLabel('deleteData'), staticText: true, type: FILTER_NONE, value: 'delete',  fn: (item, id, value) => <TableIconButton id={`delete-${id}`} label={value} onClick={deleteProfessionalClicked}/>},
            { label: getDataLabel('createdData'), value: 'createdOn', isPrimaryColumn: true, type: FILTER_DATE, fn: (item) => moment(item).calendar() }
        ];

        if (isUsingGroups) {
            columns.splice(3, 0, { label: getDataLabel('groupData'), type: FILTER_TEXT, value: 'orgGroupNames', fn: (item) => item });
        }

        return (
            <section className="md-grid">
                <ProTable key={`${COMPONENT_NAME}${ACTIVE}`} status={ALL} items={dataItems} columns={columns} componentName={COMPONENT_NAME} buttons={buttons} emptyCard={() => createEmptyCard(ALL)}/>
            </section>
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderInviteDialogForm() }
            { renderUpdateDialogForm() }
            { renderDeletePopupForm() }
            { renderReassignDialogForm() }
            { renderReassignConsentPopup() }
        </>
    )
}

export default Professionals;