import React from 'react';
import { FontIcon } from 'react-md';
import { isVisit } from '../ParentingPlan/SchedulePattern';
import { SCHEDULE_CATEGORY_PARENTING_PLAN, SCHEDULE_CATEGORY_BIRTHDAY,
    SCHEDULE_CATEGORY_EXCHANGE_DAY, SCHEDULE_CATEGORY_SAME_DAY_VISIT,
    SCHEDULE_CATEGORY_EXCHANGE_DROPOFF, SCHEDULE_CATEGORY_EXCHANGE_PICKUP,
    PARENT_TWO_PICKUP, PARENT_ONE_PICKUP } from '../../constants/schedule';

const buildScheduleIcon = (item, size) => {
    const parentingCategory = [ SCHEDULE_CATEGORY_PARENTING_PLAN, SCHEDULE_CATEGORY_EXCHANGE_DAY, SCHEDULE_CATEGORY_SAME_DAY_VISIT ];
    const exchangeCategory = [ SCHEDULE_CATEGORY_EXCHANGE_PICKUP, SCHEDULE_CATEGORY_EXCHANGE_DROPOFF ];

    if (parentingCategory.includes(item.category)) {
        return ( <ParentingIcon item={item} size={size}/> );
    }
    if (exchangeCategory.includes(item.category)) {
        if (item.category === SCHEDULE_CATEGORY_EXCHANGE_PICKUP) {
            item.pattern = PARENT_ONE_PICKUP;
        } else {
            item.pattern = PARENT_TWO_PICKUP;
        }
        return ( <ParentingIcon item={item} size={size}/> );
    }
    if (item.category === SCHEDULE_CATEGORY_BIRTHDAY) {
        return ( <BirthdayIcon item={item} size={size}/> );
    }
    return ( <DefaultCircularIcon item={item} size={size}/> );
}

const ParentingIcon = ({item, size}) => {
    return !isVisit(item.pattern)
    ? <ScheduleIcon icon="lens" iconClass={`legend-${item.pattern}`} size={size}/>
    : <div className={'stack'}>
        <ScheduleIcon icon="lens" iconClass={`legend-${item.pattern}`} size={size}/>
        <ScheduleIcon icon="lens" iconClass={`legend-inner legend-${item.pattern}`} size={20}/>
    </div>
}

const BirthdayIcon = ({item, size}) => {
    return ( <ScheduleIcon icon="redeem" iconClass={`legend-birthday`} size={size}/> );
}

const DefaultCircularIcon = ({item, size}) => {
    return ( <ScheduleIcon icon="lens" iconClass={`legend-${item.category}`} size={size}/> );
}

const ScheduleIcon = ({icon, iconClass, size}) => {
    return (
        <FontIcon id={`legend-icon-${icon}`} className={`legend ${iconClass}`} style={{fontSize:size || 36}}>
            {icon}
        </FontIcon>
    );
}

export {
    buildScheduleIcon,
    ScheduleIcon,
};