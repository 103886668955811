import React from 'react';

function ClientDeletionConsent(props) {
    const { name } = props;

    return (
        <p className='inactive-text'><b>{`The invitation has already been sent to ${name}! If a second invite was sent to ${name}'s coParent, their invitation will also be deleted! Are you sure you wish to continue?`}</b></p>
    );
}

export default ClientDeletionConsent;