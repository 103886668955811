import React from 'react';
import useCustomTheme from "../../hooks/useCustomTheme";
import { Tabs, TabsContainer } from 'react-md';

// ThemedTabs component for manual use of React-md Tabs
function ThemedTabs(props) {
    const { children, ...tabsProps } = props;

    const {
        getToolbarTheme,
    } = useCustomTheme();

    return (
        <Tabs style={getToolbarTheme()} {...tabsProps}>
            { children }
        </Tabs>
    );
}

// ThemedTabsContainer component for React-md TabsContainer useage
function ThemedTabsContainer(props) {
    const { children, ...containerProps } = props;

    const {
        getToolbarTheme,
    } = useCustomTheme();

    return (
        <TabsContainer {...containerProps}>
            <Tabs style={getToolbarTheme()}>
                { children }
            </Tabs>
        </TabsContainer>
    );
}

export default ThemedTabs;

export {
    ThemedTabsContainer
};