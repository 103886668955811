function validate(values) {
    const errors = {};

    if (!values.orgTypeName.trim()) {
        errors.orgTypeName = "Organization type name is required";
    }

    if (!values.orgTypeDescription.trim()) {
        errors.orgTypeDescription = "Organization type description is required"
    }

    if (!values.orgRoles) {
        errors.orgRoles = "A role is required";
    }

    return errors;
};

export {
    validate,
};