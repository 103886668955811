import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router-dom";
import * as firebase from 'firebase';
import { Card, Media, Grid, Cell } from 'react-md';
import { selectOrganizationName, selectOrganizationId, selectCurrentUser } from '../../selectors/global';
import { setToolbarTitle, setToolbarButtons } from '../../actions/global';
import ChangeSystemMessage from '../SystemMessages/SystemMessageForm'
import { NAV_DEFAULT } from '../../constants/navigation';
import { PATH_CARDS } from '../../constants';
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants/notifications';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import Fade from 'react-reveal/Fade';
import { callCloudRunFunction } from '../../utils/firestore';

class OrganizationMessages extends Component {
    constructor(props) {
        super(props);

        this.state = { title: null, message: null };
        this.clearMessage = this.clearMessage.bind(this);
    }

    componentDidMount() {
        const { currentUser, organizationId, organizationName } = this.props;
        const db = firebase.firestore();

        if (organizationId === false) {
            return this.props.history.push(NAV_DEFAULT);
        }

        this.props.onSetToolbarTitle(`${organizationName} Message`);
        this.props.onSetToolbarButtons([ { icon: 'arrow_back', onClick: () => this.props.history.goBack() }]);

        db.collection('ProSystemMessages').doc(organizationId).get().then((doc) => {
            const { title, message } = doc.data();
            this.setState({ title, message });
        }).catch((err) => {
            console.log(err);
            this.setState({ title: null, message: null });
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Messages',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
    }

    componentWillUnmount() {
        this.props.onSetToolbarTitle(``);
        this.props.onSetToolbarButtons([]);
    }

    handleSubmit = (messageMap) => {
        const { currentUser, organizationId } = this.props;

        const organizationMessage = {
            createdOn: new Date(),
            message: messageMap.get('message') || '',
            title: messageMap.get('title') || ''
        };

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Messages',
            action: 'update organization message',
            metadata: {...organizationMessage}
        };

        const db = firebase.firestore();
        callCloudRunFunction(currentUser, 'logProUserAction', log);
        
        db.collection('ProSystemMessages').doc(organizationId).set(organizationMessage).then(() => {
            showToastNotification('The Organization message has been changed', NOTIFICATION_SUCCESS);
            this.props.history.push(NAV_DEFAULT);
        }).catch((err) => {
            console.log(err);
            showToastNotification('An error has occurred', NOTIFICATION_ERROR);
        });
    };

    clearMessage() {
        this.setState({ title: '', message: '' });
    }

    render() {
        const { systemTitle, systemMessage } = this.state;

        return (
            <Grid>
                <Cell size={12}>
                    <Fade duration={500}>
                        <Card>
                            <Media aspectRatio="5-1">
                                <img src={`${process.env.PUBLIC_URL}${PATH_CARDS}card_organizationMessageHalfSize.png`} alt="org" />
                            </Media>
                            <ChangeSystemMessage
                                onClear={this.clearMessage}
                                initialValues={this.state}
                                onSubmit={this.handleSubmit}
                                title={systemTitle}
                                message={systemMessage}
                            />
                        </Card>
                    </Fade>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    organizationName: selectOrganizationName(),
    organizationId: selectOrganizationId(),
    currentUser: selectCurrentUser(),
    // roles: selectRoles(),
});

export function mapDispatchToProps(dispatch) {
    return {
       onSetToolbarTitle: title => dispatch(setToolbarTitle(title)),
       onSetToolbarButtons: buttons => dispatch(setToolbarButtons(buttons)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationMessages));