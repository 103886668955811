export const ORG_COPARENTER_PROFESSIONAL = '0';
export const ROLE_SEPARATOR = 'role';

// Organization Roles
export const ORG_ROLE_NOT_AUTHENTICATED = -1;
export const ORG_ROLE_AUTHENTICATED_PENDING = 0;
export const ORG_ROLE_ON_BOARD = 1;
export const ORG_ROLE_CLIENT_COMMUNICATION = 2;
export const ORG_ROLE_MEDIATION = 4;
export const ORG_ROLE_PARENTING_PLAN = 8;
export const ORG_ROLE_HOLIDAY = 16;
export const ORG_ROLE_MANAGE_ORG = 32;
export const ORG_ROLE_SUPER_ADMIN = 64;
export const ORG_ROLE_VIEW_CLIENT = 128;
export const ORG_ROLE_CASE_SEARCH = 256;
export const ORG_ROLE_INTERNAL_COMMUNICATION = 512;

// View Client Roles
export const VIEW_CLIENT_ROLE_NOT_AUTHENTICATED = 0;
export const VIEW_CLIENT_ROLE_PROFILE = 1;
export const VIEW_CLIENT_ROLE_AGREEMENTS = 2;
export const VIEW_CLIENT_ROLE_MESSAGES = 4;
export const VIEW_CLIENT_ROLE_CHECKINS = 8;
export const VIEW_CLIENT_ROLE_CHILDREN = 16;
export const VIEW_CLIENT_ROLE_DOCUMENTS = 32;
export const VIEW_CLIENT_ROLE_SENTIMENT = 64;
export const VIEW_CLIENT_ROLE_COMMUNICATION = 128;
export const VIEW_CLIENT_ROLE_CALENDAR = 256;
export const VIEW_CLIENT_ROLE_REQUESTS = 512;
export const VIEW_CLIENT_ROLE_CONNECTIONS = 1024;
export const VIEW_CLIENT_ROLE_SUBSCRIPTIONS = 2048;
export const VIEW_CLIENT_ROLE_REPOSITORY = 4096;
export const VIEW_CLIENT_ROLE_EXPENSES = 8192;
export const VIEW_CLIENT_ROLE_MEDIATIONS = 16384;

// Management Roles
export const MANAGEMENT_ROLE_NOT_AUTHENTICATED = 0;
export const MANAGEMENT_ROLE_PRO_USERS = 1;
export const MANAGEMENT_ROLE_ORG_DETAILS = 2;
export const MANAGEMENT_ROLE_ORG_BRANDING = 4;
export const MANAGEMENT_ROLE_MEDIATION_ALLOCATION = 8;
export const MANAGEMENT_ROLE_ORG_CATEGORIES = 16;
export const MANAGEMENT_ROLE_ORG_GROUPS = 32;
export const MANAGEMENT_ROLE_ORG_APP_THEME = 64;