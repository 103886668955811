import React from 'react';
import { Card, CardTitle, CardText, IconSeparator, FontIcon } from 'react-md';

function OrganizationCard(props) {
    const { organization, website, email, phone, state, city, zip, county, getLabel } = props;
    
    const infoPadding = { margin: '10px 0px' };

    return (
        <Card>
            <CardTitle
                title={getLabel('organizationHeader')}
                subtitle={getLabel('organizationSubheader')}
            />
            <CardText>
                <b>{ getLabel('contactInfoHeader') }</b>
                <IconSeparator style={infoPadding} label={website || ''} iconBefore>
                    <FontIcon>language</FontIcon>
                </IconSeparator>
                <IconSeparator style={infoPadding} label={email || ''} iconBefore>
                    <FontIcon>email</FontIcon>
                </IconSeparator>
                <IconSeparator style={infoPadding} label={phone || ''} iconBefore>
                    <FontIcon>phone</FontIcon>
                </IconSeparator>
                <IconSeparator style={infoPadding} label={`${state} ${city} ${zip} ${county}`} iconBefore>
                    <FontIcon>location_on</FontIcon>
                </IconSeparator>
            </CardText>
        </Card>
    );
}

export default OrganizationCard;