import { NAV_DEFAULT, NAV_ORG_CLIENTS,  NAV_CLIENT_INVITES,
    NAV_MEDIATION, NAV_ORG, NAV_ORG_DETAILS, NAV_VIDEO, NAV_VIEW_ORGS,
    NAV_VIEW_PROS, NAV_SUPER_ADMIN, NAV_VIEW_COPARENTER_USERS, NAV_MSGING,
    NAV_CLIENT_DETAILS, NAV_CLIENT_PROFILE, NAV_CLIENT_AGREEMENTS, NAV_CLIENT_MESSAGES,
    NAV_CLIENT_CHECKINS, NAV_CLIENT_SENTIMENT, NAV_CLIENT_DOCUMENTS, NAV_CLIENT_CHILDREN,
    NAV_CLIENT_CALENDAR, NAV_CLIENT_REQUESTS, NAV_CLIENT_COMMUNICATION,
    NAV_CLIENT_MEDIATIONS, NAV_CLIENT_CONNECTIONS, NAV_SUBMIT_IDEAS,
    NAV_ORG_TYPES, NAV_CLIENT_REPOSITORY, NAV_ORG_ENTRY_CATEGORIES, NAV_CASE_SEARCH,
    NAV_INTERNAL_COMMUNICATIONS, NAV_CLIENT_EXPENSES, NAV_MEDIATION_ALLOCATION,
    NAV_MY_PROFILE, NAV_SETTINGS, NAV_ORG_MESSAGE, NAV_SYSTEM_MSG, NAV_PRO_PROFILE,
    NAV_ORG_GROUPS, NAV_ORG_BRANDING, NAV_DEFAULT_BRANDING, NAV_ORG_APP_THEME } from '../constants/navigation';
    
// Organization Roles
import { ORG_COPARENTER_PROFESSIONAL, ORG_ROLE_AUTHENTICATED_PENDING,
    ORG_ROLE_SUPER_ADMIN, ORG_ROLE_VIEW_CLIENT, ORG_ROLE_ON_BOARD,
    ORG_ROLE_MEDIATION, ORG_ROLE_PARENTING_PLAN, ORG_ROLE_MANAGE_ORG,
    ORG_ROLE_CASE_SEARCH, ORG_ROLE_INTERNAL_COMMUNICATION, MANAGEMENT_ROLE_ORG_APP_THEME } from '../constants/roles';

// View Client Roles
import { ROLE_SEPARATOR, VIEW_CLIENT_ROLE_PROFILE,
    VIEW_CLIENT_ROLE_AGREEMENTS, VIEW_CLIENT_ROLE_MESSAGES, VIEW_CLIENT_ROLE_CHECKINS,
    VIEW_CLIENT_ROLE_CHILDREN, VIEW_CLIENT_ROLE_DOCUMENTS, VIEW_CLIENT_ROLE_SENTIMENT,
    VIEW_CLIENT_ROLE_MEDIATIONS, VIEW_CLIENT_ROLE_CALENDAR, VIEW_CLIENT_ROLE_REQUESTS,
    VIEW_CLIENT_ROLE_CONNECTIONS, VIEW_CLIENT_ROLE_SUBSCRIPTIONS,
    VIEW_CLIENT_ROLE_EXPENSES, VIEW_CLIENT_ROLE_COMMUNICATION, VIEW_CLIENT_ROLE_REPOSITORY } from '../constants/roles';

import {
    MANAGEMENT_ROLE_PRO_USERS, MANAGEMENT_ROLE_ORG_DETAILS,
    MANAGEMENT_ROLE_ORG_CATEGORIES, MANAGEMENT_ROLE_MEDIATION_ALLOCATION,
    MANAGEMENT_ROLE_ORG_BRANDING, MANAGEMENT_ROLE_ORG_GROUPS } from '../constants/roles';

import { isNullOrUndefined } from 'util';
import { IDEAS_SUBMISSION } from '../constants';
import { NOTIFICATION_CATEGORY_INTERNAL, NOTIFICATION_CATEGORY_MEDIATION, NOTIFICATION_CATEGORY_DIRECT } from '../constants/notifications';
import { openInNewWindow } from '.';

import OrganizationClients from '../containers/OrganizationClients' ;
import ClientInvites from '../containers/ClientInvites' ;
import SuperAdmin from '../containers/SuperAdmin' ;
import Organization from '../containers/Organization' ;
import Messaging from '../containers/Messaging' ;
import Video from '../containers/Video' ;
import ViewOrganizations from '../containers/Organizations' ;
import OrganizationTypes from '../containers/OrganizationTypes' ;
import ViewProfessionals from '../containers/Professionals' ;
import ClientProfile from '../containers/ClientProfile' ;
import OrganizationDetails from '../containers/OrganizationDetails' ;
import CoParenterUsers from '../containers/CoParenterUsers' ;
import CaseSearch from '../containers/CaseSearch' ;
import ClientDetails from '../containers/ClientDetails' ;
import ClientAgreements from '../containers/ClientAgreements' ;
import ClientCheckIns from '../containers/ClientCheckIns' ;
import ClientMessages from '../containers/ClientMessages' ;
import ClientSentiment from '../containers/ClientSentiment' ;
import ClientDocuments from '../containers/ClientDocuments' ;
import ClientCommuncation from '../containers/ClientCommunication' ;
import ClientChildren from '../containers/ClientChildren' ;
import ClientSchedule from '../containers/ClientSchedule' ;
import ClientRequests from '../containers/ClientRequests' ;
import ClientMediations from '../containers/ClientMediations' ;
import ClientConnections from '../containers/ClientConnections' ;
import ClientRepository from '../containers/ClientRepository' ;
import OrganizationEntryCategories from '../containers/OrganizationEntryCategories' ;
import InternalCommunications from '../containers/InternalCommunications' ;
import AccountDisabled from '../containers/AccountDisabled' ;
import ClientExpenses from '../containers/ClientExpenses' ;
import MediationAllocation from '../containers/MediationAllocation' ;
import MyProfile from '../containers/Profile/MyProfile' ;
import Settings from '../containers/Settings' ;
import HomeDashboard from '../containers/HomeDashboard' ;
import OrganizationMessages from '../containers/OrganizationMessages' ;
import SystemMessages from '../containers/SystemMessages' ;
import ProfessionalProfile from '../containers/ProfessionalProfile' ;
import OrganizationBranding from '../containers/OrganizationBranding' ;
import OrganizationAppTheme from '../containers/OrganizationAppTheme' ;
import OrganizationGroups from '../containers/OrganizationGroups' ;
import SystemBranding from '../containers/SystemBranding' ;
import Mediations from '../containers/Mediations' ;

const orgIsCoParenter = (organizationId) => {
    return organizationId === ORG_COPARENTER_PROFESSIONAL;
}

const userIsAuthenticated = (userOrgRoles) => {
    return userOrgRoles > ORG_ROLE_AUTHENTICATED_PENDING;
}

const parseRoleControls = (roles) => {
    return roles.map((x) => {
        return {
            label: x.role || '',
            value: `${ROLE_SEPARATOR}${String(x.uid || 0) }${ROLE_SEPARATOR}`,
        };
    });
}

const parseUserRoleToString = (userRoles, availableRoles) => {
    return availableRoles.reduce((appendRoles, role) => {
        const roleBit = parseInt(role.uid, 10);
        return roleBit & userRoles ? `${appendRoles}${ROLE_SEPARATOR}${roleBit}${ROLE_SEPARATOR},` : appendRoles;
    }, '');
}

const parseRoleControlsStringToInt = (roleString) => {
    const regExp = new RegExp(ROLE_SEPARATOR, 'g');
    const parsedRoleArray = roleString.replace(regExp,'').split(',');
    return parsedRoleArray.reduce((val, x) => {
        return x ? val + Number(x) : val;
    }, 0);
}

const selectableOrganizationRoles = (organization, allOrgRoles, isExisting) => {
    if (!organization) return [];

    const isCPProOrg = organization && organization.id === ORG_COPARENTER_PROFESSIONAL;
    const rolesFilteredByCPOrg = allOrgRoles && allOrgRoles.filter(x => !x.isExclusive || (x.isExclusive && isCPProOrg));

    return !isExisting ? rolesFilteredByCPOrg : rolesFilteredByCPOrg.filter(x => organization && organization.roles && (organization.roles.orgRoles & x.uid));
}

const selectableClientViewRoles = (organization, allClientViewRoles) => {
    if (!organization) return [];
    const isCPProOrg = organization && organization.id === ORG_COPARENTER_PROFESSIONAL;
    const rolesFilteredByCPOrg = allClientViewRoles && allClientViewRoles.filter(x => !x.isExclusive || (x.isExclusive && isCPProOrg));
    return rolesFilteredByCPOrg.filter(x => organization && organization.roles && (organization.roles.clientViewRoles & x.uid));
}

const selectableManagementRoles = (organization, allManagementRoles) => {
    if (!organization) return [];
    const isCPProOrg = organization && organization.id === ORG_COPARENTER_PROFESSIONAL;
    const rolesFilteredByCPOrg = allManagementRoles && allManagementRoles.filter(x => !x.isExclusive || (x.isExclusive && isCPProOrg));
    return rolesFilteredByCPOrg.filter(x => organization && organization.roles && (organization.roles.managementRoles & x.uid));
}

const filterSelectableroles = (selectableRoles, roleNumber) => {
    if (!selectableRoles) return [];
    return selectableRoles.filter(x => x.uid & roleNumber);
}

const matchToUserRoles = (userRoles, rolesToMatch) => {
    const userIsSuperAdmin = userRoles.orgRoles & ORG_ROLE_SUPER_ADMIN;
    
    if (userIsSuperAdmin) {
        return true;
    }
    
    if (!rolesToMatch) {
        return false;
    }
    
    const roleKeys = Object.keys(rolesToMatch);
    
    // Compare rolesToMatch is a subset bitwise & of userRoles in all role categories
    return roleKeys.map((roleKey) => {
        return userRoles[roleKey] & rolesToMatch[roleKey];
    }).reduce((prev, curr) => prev && curr, true);
}

const filterItemsByRole = (userRoles) => (item) => {
    const isSuperAdmin = userRoles.orgRoles & ORG_ROLE_SUPER_ADMIN;

    const fitsRoles = matchToUserRoles(userRoles, item.roles);
    const fitsCondition = isNullOrUndefined(item.secondaryCondition) ? true : item.secondaryCondition;
    const fitsOnlyCondition = !item.roles && fitsCondition;

    return (isSuperAdmin || (fitsRoles && fitsCondition) || fitsOnlyCondition);
}

const getAccessiblePages = (user, organization, userRoles, getLabel, getAsset) => {
    if (!user || !organization) {
        return [];
    }

    if (userRoles.orgRoles < ORG_ROLE_AUTHENTICATED_PENDING) {
        return [];
    }

    const isCoParenterUser = orgIsCoParenter(user.organizationId);
    const isCoParenterOrg = orgIsCoParenter(organization.id);
    const isAuthenticatedRole = userIsAuthenticated(userRoles.orgRoles);

    const userIsActive = user.isActive || false;
    const orgIsActive = organization.isActive || false;

    // If the account is disabled, only allow access to the one page.
    if (!userIsActive || (!orgIsActive && !isCoParenterUser)) {
        return [
            { label: getLabel('homeTitle'), to: NAV_DEFAULT, componentObject: AccountDisabled, isNav: true, icon: getAsset('disabledIcon') },
            { to: NAV_MY_PROFILE, componentObject: MyProfile },
            // { to: NAV_SETTINGS, componentObject: Settings },
        ];
    }

    const orgSubRoutes = [
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_PRO_USERS }, label: getLabel('proUsersTitle'), to: NAV_VIEW_PROS },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_DETAILS }, label: getLabel('orgDetailsTitle'), to: NAV_ORG_DETAILS },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_DETAILS }, label: getLabel('orgMessageTitle'), to: NAV_ORG_MESSAGE },
    ];

    const accessibleSubRoutes = orgSubRoutes.filter(filterItemsByRole(userRoles));

    const pages = [
        // Navigation routes
        { secondaryCondition: isAuthenticatedRole, label: getLabel('homeTitle'), to: NAV_DEFAULT, componentObject: HomeDashboard, isNav: true, icon: getAsset('homeIcon') },
        { roles: { orgRoles: ORG_ROLE_MEDIATION }, label: getLabel('mediationTitle'), to: NAV_MEDIATION, componentObject: Mediations, isNav: true, icon: getAsset('mediationIcon'), notificationCategories: [NOTIFICATION_CATEGORY_MEDIATION] },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT }, label: getLabel('clientsTitle'), to: NAV_ORG_CLIENTS, componentObject: OrganizationClients, isNav: true, icon: getAsset('clientsIcon'), notificationCategories: [NOTIFICATION_CATEGORY_DIRECT] },
        { roles: { orgRoles: ORG_ROLE_ON_BOARD }, label: getLabel('clientInvitesTitle'), to: NAV_CLIENT_INVITES, componentObject: ClientInvites, isNav: true, icon: getAsset('clientInvitesIcon') },
        { roles: { orgRoles: ORG_ROLE_CASE_SEARCH }, label: getLabel('caseSearchTitle'), to: NAV_CASE_SEARCH, componentObject: CaseSearch, isNav: true, icon: getAsset('caseSearchIcon')},
        { roles: { orgRoles: ORG_ROLE_INTERNAL_COMMUNICATION }, label: getLabel('communicationTitle'), to: NAV_INTERNAL_COMMUNICATIONS, componentObject: InternalCommunications, isNav: true, icon: getAsset('communicationIcon'), notificationCategories: [NOTIFICATION_CATEGORY_INTERNAL] },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG }, label: getLabel('organizationTitle'), to: NAV_ORG, componentObject: Organization, isNav: true, icon: getAsset('organizationIcon'), nestedItems: accessibleSubRoutes.length ? accessibleSubRoutes : null },
        { roles: { orgRoles: ORG_ROLE_SUPER_ADMIN }, label: 'Super Admin', to: NAV_SUPER_ADMIN, componentObject: SuperAdmin , isNav: true, svg: 'superAdmin.svg' },
        { secondaryCondition: isAuthenticatedRole && isCoParenterOrg, label: 'Submit Idea', to: NAV_SUBMIT_IDEAS, isNav: true, svg: 'notifications.svg', onClick: () => openInNewWindow(IDEAS_SUBMISSION) },

        // User
        { secondaryCondition: isAuthenticatedRole, to: NAV_MY_PROFILE, componentObject: MyProfile },
        // { secondaryCondition: isAuthenticatedRole, to: NAV_SETTINGS, componentObject: Settings },
        
        // Sub-pages requred by mediation feature
        { roles: { orgRoles: ORG_ROLE_MEDIATION + ORG_ROLE_PARENTING_PLAN }, to: NAV_MSGING, componentObject: Messaging },
        { roles: { orgRoles: ORG_ROLE_MEDIATION + ORG_ROLE_PARENTING_PLAN }, to: NAV_VIDEO, componentObject: Video },
        
        { roles: { orgRoles: ORG_ROLE_INTERNAL_COMMUNICATION }, to: NAV_MSGING, componentObject: Messaging },

        // Managers
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_PRO_USERS }, to: NAV_VIEW_PROS, componentObject: ViewProfessionals },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_PRO_USERS }, to: NAV_PRO_PROFILE, componentObject: ProfessionalProfile },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_DETAILS }, to: NAV_ORG_DETAILS, componentObject: OrganizationDetails },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_DETAILS }, to: NAV_ORG_MESSAGE, componentObject: OrganizationMessages },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_CATEGORIES }, to: NAV_ORG_ENTRY_CATEGORIES, componentObject: OrganizationEntryCategories },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_MEDIATION_ALLOCATION }, to: NAV_MEDIATION_ALLOCATION, componentObject: MediationAllocation },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_BRANDING }, to: NAV_ORG_BRANDING, componentObject: OrganizationBranding },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_APP_THEME }, to: NAV_ORG_APP_THEME, componentObject: OrganizationAppTheme },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_GROUPS }, to: NAV_ORG_GROUPS, componentObject: OrganizationGroups },
        
        // Super User
        { roles: { orgRoles: ORG_ROLE_SUPER_ADMIN }, to: NAV_VIEW_ORGS, componentObject: ViewOrganizations },
        { roles: { orgRoles: ORG_ROLE_SUPER_ADMIN }, to: NAV_ORG_TYPES, componentObject: OrganizationTypes },
        { roles: { orgRoles: ORG_ROLE_SUPER_ADMIN }, to: NAV_SYSTEM_MSG, componentObject: SystemMessages },
        { roles: { orgRoles: ORG_ROLE_SUPER_ADMIN }, to: NAV_VIEW_COPARENTER_USERS, componentObject: CoParenterUsers },
        { roles: { orgRoles: ORG_ROLE_SUPER_ADMIN }, to: NAV_DEFAULT_BRANDING, componentObject: SystemBranding },
    
        // Client Detail Viewing
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT }, to: NAV_CLIENT_DETAILS, componentObject: ClientDetails },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_SENTIMENT }, to: NAV_CLIENT_SENTIMENT, componentObject: ClientSentiment },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_MESSAGES }, to: NAV_CLIENT_MESSAGES, componentObject: ClientMessages },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_DOCUMENTS }, to: NAV_CLIENT_DOCUMENTS, componentObject: ClientDocuments },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_COMMUNICATION }, to: NAV_CLIENT_COMMUNICATION, componentObject: ClientCommuncation },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_PROFILE }, to: NAV_CLIENT_PROFILE, componentObject: ClientProfile },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_CHECKINS }, to: NAV_CLIENT_CHECKINS, componentObject: ClientCheckIns },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_AGREEMENTS }, to: NAV_CLIENT_AGREEMENTS, componentObject: ClientAgreements },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_CHILDREN }, to: NAV_CLIENT_CHILDREN, componentObject: ClientChildren },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_CALENDAR }, to: NAV_CLIENT_CALENDAR, componentObject: ClientSchedule },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_REQUESTS }, to: NAV_CLIENT_REQUESTS, componentObject: ClientRequests },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_CONNECTIONS }, to: NAV_CLIENT_CONNECTIONS, componentObject: ClientConnections },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_EXPENSES }, to: NAV_CLIENT_EXPENSES, componentObject: ClientExpenses },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_REPOSITORY }, to: NAV_CLIENT_REPOSITORY, componentObject: ClientRepository },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_COMMUNICATION }, to: NAV_MSGING, componentObject: Messaging },
        // { role: ORG_ROLE_VIEW_CLIENT, to: NAV_CLIENT_MEDIATIONS, componentObject: ClientMediations },
    ];

    return pages.filter(filterItemsByRole(userRoles));
}

const getAccessibleClientViewCards = (userRoles, getLabel, getAsset) => {
    const cards = [
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_PROFILE }, cardImage: getAsset('clientProfileAsset'), cardTitle: getLabel('profileTitle'), cardSubtitle: getLabel('profileSubtitle'), to: NAV_CLIENT_PROFILE, buttonText: 'Manage' },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_COMMUNICATION }, cardImage: getAsset('directCommunicationAsset'), cardTitle: getLabel('directCommunicationTitle'), cardSubtitle: getLabel('directCommunicationSubtitle'), to: NAV_CLIENT_COMMUNICATION, buttonText: 'View' },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_AGREEMENTS }, cardImage: getAsset('clientAgreementAsset'), cardTitle: getLabel('agreementsTitle'), cardSubtitle: getLabel('agreementsSubtitle'), to: NAV_CLIENT_AGREEMENTS, buttonText: 'View' },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_MESSAGES }, cardImage: getAsset('clientMessagesAsset'), cardTitle: getLabel('coParentMessagesTitle'), cardSubtitle: getLabel('coParentMessagesSubtitle'), to: NAV_CLIENT_MESSAGES, buttonText: 'View' },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_REQUESTS }, cardImage: getAsset('clientRequestsAsset'), cardTitle: getLabel('requestsTitle'), cardSubtitle: getLabel('requestsSubtitle'), to: NAV_CLIENT_REQUESTS, buttonText: 'View' },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_CHECKINS }, cardImage: getAsset('clientCheckInAsset'), cardTitle: getLabel('checkInsTitle'), cardSubtitle: getLabel('checkInsSubtitle'), to: NAV_CLIENT_CHECKINS, buttonText: 'View' },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_CHILDREN }, cardImage: getAsset('clientChildrenAsset'), cardTitle: getLabel('childrenTitle'), cardSubtitle: getLabel('childrenSubtitle'), to: NAV_CLIENT_CHILDREN, buttonText: 'View'},
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_CALENDAR }, cardImage: getAsset('clientScheduleAsset'), cardTitle: getLabel('calendarTitle'), cardSubtitle: getLabel('calendarSubtitle'), to: NAV_CLIENT_CALENDAR, buttonText: 'View' },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_REPOSITORY }, cardImage: getAsset('clientDocumentsAsset'), cardTitle: getLabel('fileRepositoryTitle'), cardSubtitle: getLabel('fileRepositorySubtitle'), to: NAV_CLIENT_REPOSITORY, buttonText: 'View' },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_DOCUMENTS }, cardImage: getAsset('clientRepositoryAsset'), cardTitle: getLabel('documentsTitle'), cardSubtitle: getLabel('documentsSubtitle'), to: NAV_CLIENT_DOCUMENTS, buttonText: 'View' },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_SENTIMENT }, cardImage: getAsset('clientSentimentAsset'), cardTitle: 'Client Sentiment Graph', cardSubtitle: 'View Sentiment Score', to: NAV_CLIENT_SENTIMENT, buttonText: 'View' },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_CONNECTIONS }, cardImage: getAsset('clientConnectionsAsset'), cardTitle: getLabel('connectionsTitle'), cardSubtitle: getLabel('connectionsSubtitle'), to: NAV_CLIENT_CONNECTIONS, buttonText: 'View' },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_EXPENSES }, cardImage: getAsset('clientExpensesAsset'), cardTitle: getLabel('expensesTitle'), cardSubtitle: getLabel('expensesSubtitle'), to: NAV_CLIENT_EXPENSES, buttonText: 'View' },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT, clientViewRoles: VIEW_CLIENT_ROLE_SUBSCRIPTIONS }, cardImage: getAsset('clientSubscriptionsAsset'), cardTitle: 'Client Subscription History', cardSubtitle: 'View Client Subscription History', to: '', buttonText: 'View' },
        // { role: VIEW_CLIENT_ROLE_MEDIATIONS, cardImage: 'card_subscriptionHistory.png', cardTitle: 'Client Mediations', cardSubtitle: 'View client mediation history', to: NAV_CLIENT_MEDIATIONS, buttonText: 'View' },
    ];

    return cards.filter(filterItemsByRole(userRoles));
}

const getAccessibleManagementCards = (userRoles, getLabel, getAsset) => {
    const cards = [
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_PRO_USERS }, cardImage: getAsset('orgProfessionalsAsset'), cardTitle: getLabel('proUsersCardTitle'), cardSubtitle: getLabel('proUsersCardSubtitle'), to: NAV_VIEW_PROS },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_DETAILS }, cardImage: getAsset('orgMessageAsset'), cardTitle: getLabel('messageCardTitle'), cardSubtitle: getLabel('messageCardSubtitle'), to: NAV_ORG_MESSAGE },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_DETAILS }, cardImage: getAsset('orgDetailsAsset'), cardTitle: getLabel('detailsCardTitle'), cardSubtitle: getLabel('detailsCardSubtitle'), to: NAV_ORG_DETAILS },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_CATEGORIES }, cardImage: getAsset('orgCategoriesAsset'), cardTitle: getLabel('categoriesCardTitle'), cardSubtitle: getLabel('categoriesCardSubtitle'), to: NAV_ORG_ENTRY_CATEGORIES },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_MEDIATION_ALLOCATION }, cardImage: getAsset('orgAllocationAsset'), cardTitle: getLabel('allocationCardTitle'), cardSubtitle: getLabel('allocationCardSubtitle'), to: NAV_MEDIATION_ALLOCATION },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_BRANDING }, cardImage: getAsset('orgCustomBrandAsset'), cardTitle: getLabel('customBrandingCardTitle'), cardSubtitle: getLabel('customBrandingCardSubtitle'), to: NAV_ORG_BRANDING },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_APP_THEME }, cardImage: getAsset('orgCustomBrandAsset'), cardTitle: 'Tenant Themes', cardSubtitle: 'Update tenant themes', to: NAV_ORG_APP_THEME },
        { roles: { orgRoles: ORG_ROLE_MANAGE_ORG, managementRoles: MANAGEMENT_ROLE_ORG_GROUPS }, cardImage: getAsset('orgGroupsAsset'), cardTitle: getLabel('groupsTitle'), cardSubtitle: getLabel('groupsSubtitle'), to: NAV_ORG_GROUPS },
        { roles: { orgRoles: ORG_ROLE_VIEW_CLIENT }, cardImage: getAsset('orgClientsAsset'), cardTitle: getLabel('viewClientsTitle'), cardSubtitle: getLabel('viewClientsSubtitle'), to: NAV_ORG_CLIENTS },
        { roles: { orgRoles: ORG_ROLE_ON_BOARD }, cardImage: getAsset('orgInvitedClientsAsset'), cardTitle: getLabel('viewInvitesTitle'), cardSubtitle: getLabel('viewInvitesSubtitle'), to: NAV_CLIENT_INVITES }
    ];
    
    return cards.filter(filterItemsByRole(userRoles));
}

const getAccessibleLabelGroups = (groups, organization) => {
    if (!groups) {
        return [];
    }

    const organizationRoles = organization && organization.roles || {};

    return groups.reduce((prev, curr) => {
        if (!curr.permissions) {
            return prev;
        }

        if (curr.permissions.orgRoles & organizationRoles.orgRoles) {
            prev.push(curr);
            return prev;
        }

        if (curr.permissions.clientViewRoles & organizationRoles.clientViewRoles) {
            prev.push(curr);
            return prev;
        }

        return prev;
    }, []);
}

export {
    getAccessiblePages,
    getAccessibleClientViewCards,
    getAccessibleManagementCards,
    getAccessibleLabelGroups,
    orgIsCoParenter,
    userIsAuthenticated,
    parseRoleControls,
    parseUserRoleToString,
    parseRoleControlsStringToInt,
    selectableOrganizationRoles,
    selectableClientViewRoles,
    selectableManagementRoles,
    filterSelectableroles,
}