import * as firebase from 'firebase';
import { asyncValidationChanges } from '../../utils/formFields';

function validate(values) {
    const errors = {};

    if (!values.orgTypeName.trim()) {
        errors.orgTypeName = "Organization type name is required";
    }

    if (!values.orgTypeDescription.trim()) {
        errors.orgTypeDescription = "Organization type description is required"
    }

    if (!values.orgRoles) {
        errors.orgRoles = "A role is required";
    }

    return errors;
};

async function asyncValidate(values, previousValues, existingErrors, previousAsyncErrors) {
    const asyncErrors = {};

    const asyncValidateNameFunction = async () => {
        return await asyncValidate(values.orgTypeName, 'ProOrganizationTypes');
    };

    const asyncValidate = (value, collectionName) => {
        const db = firebase.firestore();
        return value === undefined ? Promise.resolve(false) : db.collection(collectionName).where('orgTypeName', '==', value.toString()).get().then((snapshot) => {
            return snapshot.empty ? Promise.resolve(false) : Promise.resolve('An organization type with this name already exists');
        }).catch((err) => {
            console.log(err);
            return Promise.reject(err);
        });
    }

    const nameError = await asyncValidationChanges('orgTypeName', values, previousValues, existingErrors, previousAsyncErrors, asyncValidateNameFunction);
    if (nameError) {
        asyncErrors.orgTypeName = nameError;
    }

    return asyncErrors;
};

export {
    validate,
    asyncValidate,
};