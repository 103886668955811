import { IMAGE, PDF } from '../constants';
import { isEmpty } from 'underscore';

const getInitials = (displayName) => {
    return getFirstAndLastName(displayName)
        .map(name => name.substring(0, 1).toUpperCase())
        .reduce((a, b) => `${a}${b}`);
}

const getFirstAndLastName = (displayName) => {
    const names = displayName ? displayName.split(' ') : [];
    if (names.length == 2) {
        return names;
    } else if (names.length > 2) {
        const firstName = names[0];
        const lastName = names.slice(1).join(' ');
        const result = [firstName, lastName];
        return result;
    } else {
        while (names.length < 2) {
            names.push('');
        }
        return names;
    }
}

const getMessageBodyFromFile = (file) => {
    if (file && file.type) {
        if (file.type.includes('image/')) {
            return IMAGE;
        } else if (file.type.includes('application/pdf')) {
            return PDF;
        }
    }

    return null;
}

const dataRegExpReplace = (string, data) => {
    if (!data || !string) return string || '';

    let alteredString = string;

    Object.keys(data).forEach((key) => {
        const expression = new RegExp(`{${key}}`, 'g');
        alteredString = alteredString.replaceAll(expression, data[key] || '');
    });
    
    return alteredString;
}

export {
    getInitials,
    getFirstAndLastName,
    getMessageBodyFromFile,
    dataRegExpReplace,
};