import { useEffect } from "react";

// Given a function, will continue to call the function after the given duration

function usePseudoStream(props) {
    const { streamFunction, duration } = props;
    const DEFAULT_DURATION = 300000; // 5 minutes

    useEffect(() => {
        streamFunction();

        var stream = setInterval(streamFunction, duration || DEFAULT_DURATION);

        return () => {
            clearInterval(stream);
        };
    }, []);

    return {}
}

export default usePseudoStream;