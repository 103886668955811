import React, { useState, useEffect } from 'react';
import usePageFramework from '../../hooks/usePageFramework';
import { selectCurrentUser } from '../../selectors/global';
import useFormValidation from '../../hooks/useFormValidation';
import { Grid, Cell } from 'react-md';
import ThemedButton from '../../components/Buttons/ThemedButton';
import moment from 'moment';
import TextFormField from '../../components/FormFields/TextFormField';
import CheckboxFormField from '../../components/FormFields/CheckboxFormField';
import DateFormField from '../../components/FormFields/DateFormField';
import TimeFormField from '../../components/FormFields/TimeFormField';
import { callCloudRunFunction } from '../../utils/firestore';
import { DATE_FORMAT, TIME_FORMAT } from '../../constants';
import { SCHEDULE_CATEGORY_APPOINTMENT, SCHEDULE_CATEGORY_EXCHANGE_PICKUP, SCHEDULE_CATEGORY_EXCHANGE_DROPOFF, NO_SHARED_COPARENT_CONTROL } from '../../constants/schedule';
import SelectionFormField from '../../components/FormFields/SelectionFormField';
import { validate } from './CreateEventValidate';
import { getStartOfDay, getEndOfDay } from '../../utils/dateTime';
import RadioFormField from '../../components/FormFields/RadioFormField';

function CreateEventForm(props) {
    const { onSubmit, onHide, clientProfile } = props;
    const {
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const [ availableChildren, setAvailableChildren ] = useState([]);
    const [ selectableCoParents, setSelectableCoParents ] = useState([ NO_SHARED_COPARENT_CONTROL ]);
    const scheduleItems = [ { value: SCHEDULE_CATEGORY_APPOINTMENT }, { value: SCHEDULE_CATEGORY_EXCHANGE_PICKUP }, { value: SCHEDULE_CATEGORY_EXCHANGE_DROPOFF } ];

    const INITIAL_STATE = {
        children: '',
        sharedCoParentId: '-1',
        title: '',
        notes: '',
        location: '',
        category: scheduleItems[0].value,
        startDate: getStartOfDay(),
        startTime: getStartOfDay(),
        endDate: getEndOfDay(),
        endTime: getEndOfDay(),
    };

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        changeValues,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, submitEvent, validate);

    const isAppointment = values.category === SCHEDULE_CATEGORY_APPOINTMENT;
    const isPickup = values.category === SCHEDULE_CATEGORY_EXCHANGE_PICKUP;
    const isDropOff = values.category === SCHEDULE_CATEGORY_EXCHANGE_DROPOFF;

    useEffect(() => {
        if (!clientProfile) {
            return;
        }

        const cpIds = {
            cp1Uid: clientProfile.uid,
            cp2Uid: null,
        };

        callCloudRunFunction(currentUser, 'getChildrenForPros', cpIds).then((childrenData) => {
            if (childrenData) {
                const clientChildren = Object.keys(childrenData).map((id) => {
                    return {
                        value: id,
                        label: `${childrenData[id].firstName} ${childrenData[id].lastName}`.trim(),
                    };
                });

                setAvailableChildren(clientChildren);
            }
        }).catch((err) => {
            console.log(err);
        });
    }, [ clientProfile ]);

    useEffect(() => {
        if (!clientProfile) {
            return;
        }

        callCloudRunFunction(currentUser, 'getCoParents', { userId: clientProfile.uid }).then((coParents) => {
            const coParCategories = coParents.map((coParent) => {
                return {
                    label: `${coParent.firstName} ${coParent.lastName}`,
                    value: `${coParent.uid}`,
                };
            });

            coParCategories.unshift(NO_SHARED_COPARENT_CONTROL);
            setSelectableCoParents(coParCategories);
        }).catch((err) => {
            console.log(err);
        });
    }, [ clientProfile ]);

    function submitEvent(values) {
        const event = parseSubmissionToEvent(values);
        onSubmit(event);
    }

    function parseSubmissionToEvent(values) {
        const startDate = values.startDate;
        const startTime = values.startTime;
        const endDate = values.endDate;
        const endTime = values.endTime;

        let startDateTime = moment(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime.getHours(), startTime.getMinutes()));
        let endDateTime = moment(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.getHours(), endTime.getMinutes()));

        // If the event is a pickup or dropoff event, date/times should be merged
        if (isPickup) {
            endDateTime = moment(startDateTime);
            endDateTime.add(1, 'minutes');
        } else if (isDropOff) {
            startDateTime = moment(endDateTime);
            startDateTime.subtract(1, "minutes");
        }

        const event = {
            category: values.category.trim(),
            children: values.children.split(','),
            title: values.title.trim(),
            notes: values.notes.trim(),
            location: values.location.trim(),
            startDate: startDateTime.format(DATE_FORMAT),
            startTime: startDateTime.format(TIME_FORMAT),
            endDate: endDateTime.format(DATE_FORMAT),
            endTime: endDateTime.format(TIME_FORMAT),
            sharedCoParentId: values.sharedCoParentId.trim() === '-1' ? null : values.sharedCoParentId.trim(),
        };

        return event;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="title"
                        label="Title"
                        values={values}
                        errors={errors}
                        customSize="title"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="notes"
                        label="notes"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="location"
                        label="location"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={2}>
                    <SelectionFormField
                        name="category"
                        label="Schedule Category"
                        values={values}
                        errors={errors}
                        menuItems={scheduleItems}
                        itemLabel="value"
                        itemValue="value"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <CheckboxFormField
                        name="children"
                        label="Children"
                        values={values}
                        errors={errors}
                        required
                        controls={availableChildren}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <RadioFormField
                        name="sharedCoParentId"
                        label="Share schedule with coParents"
                        values={values}
                        errors={errors}
                        controls={selectableCoParents}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                {
                    (isAppointment || !isDropOff) &&
                        <>
                            <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                                <DateFormField
                                    name="startDate"
                                    label="Start Date"
                                    values={values}
                                    errors={errors}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Cell>
                            <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                                <TimeFormField
                                    name="startTime"
                                    label="Start Time"
                                    values={values}
                                    errors={errors}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Cell>
                        </>
                }
                {
                    (isAppointment || !isPickup) &&
                        <>
                            <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                                <DateFormField
                                    name="endDate"
                                    label="End Date"
                                    values={values}
                                    errors={errors}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Cell>
                            <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                                <TimeFormField
                                    name="endTime"
                                    label="End Time"
                                    values={values}
                                    errors={errors}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Cell>
                        </>
                }
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton flat swapTheming type="submit" disabled={isSubmitting} className='full-width-field'>Submit</ThemedButton>
                </Cell>
            </Grid>
        </form>
    );

}

export default CreateEventForm;