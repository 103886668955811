import React from 'react';
import { Slider } from 'react-md';
import Bounce from 'react-reveal/Bounce';

export default function SliderFormField(props) {
    const {name, values, errors, onChange, disabled, ...otherProps} = props;

    const handleSliderChange = (value, event) => {
        onChange(name, value);
    }

    return (
        <>
            <Slider
                id={name}
                inputWidth={60}
                editable={!disabled}
                discrete
                disabled={disabled}
                value={values[name]}
                onChange={handleSliderChange}
                { ...otherProps }
            />
            <Bounce when={!!errors[name]}><p className="error-text">{errors[name] || '...'}</p></Bounce>
        </>
    );
}