import React from 'react';
import { Card, CardText, Grid, Cell } from 'react-md';
import moment from 'moment';
import CellDataDisplay from '../CellDataDisplay';

function RequestHistoryCard(props) {
    const { createdOn, history } = props;

    return (
        <Card>
            <CardText>
                <Grid>
                    <CellDataDisplay
                        headerText={'Date Modified'}
                        valueText={moment(createdOn).calendar()}
                    />
                    <CellDataDisplay
                        headerText={'Old Value'}
                        valueText={history.oldValue}
                    />
                    <CellDataDisplay
                        headerText={'New Value'}
                        valueText={history.newValue}
                    />
                </Grid>
            </CardText>
        </Card>
    );
}

export default RequestHistoryCard;