import React from 'react';
import { Tooltipped } from 'react-md';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting';

export default function ParticipantConnectionIndicator({ participant, }) {
    const isReconnecting = useParticipantIsReconnecting(participant);
    const indicator = {
        width: '10px',
        height: '10px',
        borderRadius: '100%',
        background: '#0c0',
        display: 'inline-block',
        marginRight: '3px',
    };

    const isReconnectingStyle = {
        background: '#ffb100',
    };
    
    return (
    <Tooltipped title={isReconnecting ? 'Participant is reconnecting' : 'Participant is connected'}>
        <span style={isReconnecting ? isReconnectingStyle : indicator }></span>
    </Tooltipped>
    );
}