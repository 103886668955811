import React, { useEffect, useState } from 'react';
import { Avatar } from 'react-md';
import Bounce from 'react-reveal/Bounce';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';

export default function ProfilePictureFormField(props) {
    const { name, values, errors, onChange, onBlur, innerText, currentPicture } = props;

    const inputId = `profile-picture-field-${name}`;
    const [ pictureURL, setPictureURL ] = useState(currentPicture);

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    useEffect(() => {
        const newPicture = values[name];

        if (newPicture) {
            var reader = new FileReader();
            reader.onload = function(e) {
                setPictureURL(e.target.result);
            }

            reader.readAsDataURL(newPicture);
        } else {
            setPictureURL(currentPicture);
        }
    }, [ values, currentPicture ]);

    const openFileInput = () => {
        const inputElement = document.getElementById(inputId);
        inputElement.click();
    }
    
    const onFileSubmit = (evt, a, b) => {
        const inputElement = document.getElementById(inputId);
        const file = inputElement.files[0];
        onChange(name, file);
    }
    
    return (
        <div style={{display: 'block'}}>
            <Avatar className={'profile-avatar profile-avatar-clickable'} random src={pictureURL} contentStyle={{width: 'inherit'}} onClick={openFileInput}>
                { innerText }
            </Avatar>
            <p>{ getDataLabel('imageData') }</p>
            <input type='file' id={inputId} style={{ display: 'none' }} onChange={onFileSubmit} accept={'image/*'} multiple={false}/>
            <Bounce when={!!errors[name]}><p className="error-text">{errors[name] || '...'}</p></Bounce>
        </div>
    );
}