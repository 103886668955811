import React from 'react';
import { Grid, Cell, Button } from 'react-md';
import { parseRoleControls, selectableOrganizationRoles, selectableClientViewRoles, selectableManagementRoles, parseRoleControlsStringToInt, parseUserRoleToString } from '../../utils/permissions';
import { selectCurrentOrganization, selectAllOrganizationRoles, selectAllClientViewRoles, selectAllManagementRoles } from '../../selectors/global';
import useFormValidation from '../../hooks/useFormValidation';
import TextFormField from '../../components/FormFields/TextFormField';
import CheckboxFormField from '../../components/FormFields/CheckboxFormField';
import { validate } from './UpdateOrgTypeValidate';
import { ORG_ROLE_VIEW_CLIENT, ORG_ROLE_MANAGE_ORG } from '../../constants/roles';
import usePageFramework from '../../hooks/usePageFramework';

function UpdateOrgTypeForm(props) {
    const { initialValues, onSubmit } = props;

    const {
        selector,
    } = usePageFramework();

    const currentOrganization = selector(selectCurrentOrganization());
    const organizationRoles = selector(selectAllOrganizationRoles());
    const clientViewRoles = selector(selectAllClientViewRoles());
    const managementRoles = selector(selectAllManagementRoles());

    const selectableOrgRoles = selectableOrganizationRoles(currentOrganization, organizationRoles, false).filter(x => !x.isExclusive)
    const selectableViewRoles = selectableClientViewRoles(currentOrganization, clientViewRoles).filter(x => !x.isExclusive);
    const selectableManageViewRoles = selectableManagementRoles(currentOrganization, managementRoles).filter(x => !x.isExclusive);

    const INITIAL_STATE = {
        id: initialValues.id,
        orgTypeName: initialValues.orgTypeName || '',
        orgTypeDescription: initialValues.orgTypeDescription || '',
        orgRoles: parseUserRoleToString(initialValues.orgRoles || 0, selectableOrgRoles) || '',
        clientViewRoles: parseUserRoleToString(initialValues.clientViewRoles || 0, selectableViewRoles) || '',
        managementRoles: parseUserRoleToString(initialValues.managementRoles || 0, selectableManageViewRoles) || '',
    };

    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, submitUpdateToOrgType, validate);

    const organizationRoleControls = parseRoleControls(selectableOrgRoles);
    const clientViewRoleControls = parseRoleControls(selectableViewRoles);
    const managementRoleControls = parseRoleControls(selectableManageViewRoles);
    const showingClientControls = parseRoleControlsStringToInt(values.orgRoles) & ORG_ROLE_VIEW_CLIENT ? true : false;
    const showingManagementControls = parseRoleControlsStringToInt(values.orgRoles) & ORG_ROLE_MANAGE_ORG ? true : false;

    function handleOrgRoleChange(name, value) {
        // Clear selected client view roles if client viewing is toggled off
        const changes = {
            clientViewRoles: '',
            managementRoles: '',
            [name]: value,
        };

        const orgRoleValue = parseRoleControlsStringToInt(value);
        
        if (orgRoleValue & ORG_ROLE_VIEW_CLIENT) {
            delete changes.clientViewRoles;
        }

        if (orgRoleValue & ORG_ROLE_MANAGE_ORG) {
            delete changes.managementRoles;
        }

        changeValues(changes);
    }

    function submitUpdateToOrgType(values) {
        const organizationType = parseSubmissionToOrgType(values);
        onSubmit(organizationType);
    }

    function parseSubmissionToOrgType(values) {
        const orgTypeObject = {
            id: values.id,
            orgTypeName: values.orgTypeName.trim(),
            orgTypeDescription: values.orgTypeDescription.trim(),
            orgRoles: parseRoleControlsStringToInt(values.orgRoles || ''),
            clientViewRoles: showingClientControls ? parseRoleControlsStringToInt(values.clientViewRoles || '') : 0,
            managementRoles: showingManagementControls ? parseRoleControlsStringToInt(values.managementRoles || '') : 0,
        };

        return orgTypeObject;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="orgTypeName"
                        label="Organization Type Name"
                        values={values}
                        errors={errors}
                        customSize="title"
                        disabled
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="orgTypeDescription"
                        label="Description"
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell size={12}>
                    <b className='inactive-text'>Changing the roles of an Organization Type will affect all associated Organizations and their members</b>
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <CheckboxFormField
                        name="orgRoles"
                        label="Available Roles for Organization"
                        values={values}
                        errors={errors}
                        controls={organizationRoleControls}
                        onChange={handleOrgRoleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    {
                        showingClientControls &&
                            <CheckboxFormField
                                name="clientViewRoles"
                                label="Client Viewing Role(s) (Must have View Client Role Selected)"
                                values={values}
                                errors={errors}
                                controls={clientViewRoleControls}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    {
                        showingManagementControls &&
                            <CheckboxFormField
                                name="managementRoles"
                                label="Management Role(s) (Must have Manage Organization Role selected)"
                                values={values}
                                errors={errors}
                                controls={managementRoleControls}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <Button className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>Update Organization Type</Button>
                </Cell>
            </Grid>
        </form>
    );
}

export default UpdateOrgTypeForm;