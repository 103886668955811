import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { DragDropContext } from 'react-beautiful-dnd';
import { Grid, Cell } from 'react-md';
import TenantPageColumn from './TenantPageColumn';

/*
    The TenantPageOrder component uses react-beautiful-dnd to reorder the Hyphenus app menu pages.
    Since this component is rendered within react-md tabs, Drag and Drop on Tablet/Mobile devices may not work as well.

    TODO: Refactor this component into a generalized FormField component to use with form hook.
*/

function TenantPageOrder (props) {
    const { onChange, pagesOrder = [], subPagesOrder = [] } = props;
    
    const [ pages, setPages ] = useState([]);

    // Three columns
    const COLUMN_ORDER = [
        'unused',
        'pagesOrder',
        'subPagesOrder',
    ];

    const COLUMNS = {
        [COLUMN_ORDER[0]]: {
            id: COLUMN_ORDER[0],
            title: 'Unused Pages',
        },
        [COLUMN_ORDER[1]]: {
            id: COLUMN_ORDER[1],
            title: 'Pages',
        },
        [COLUMN_ORDER[2]]: {
            id: COLUMN_ORDER[2],
            title: 'Sub Pages',
        },
    };

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        const db = firebase.firestore();
        // Grab and filter out app pages without ids and duplicates

        db.collection('Pages').get().then((querySnapshot) => {
            const pages = querySnapshot.docs.map((docRef) => {
                const pageData = docRef.data();
                return { id: pageData.id ? `${pageData.id}` : false, label: pageData.uid };
            }).reduce((prev, curr) => {
                if (curr.id && !prev.find(x => x.id === curr.id)) {
                    prev.push(curr);
                }

                return prev;
            }, []);

            setPages(pages);
            finishedLoading();
        }).catch((err) => {
            console.log(err);
            errorLoading();
        });
    }, []);

    const getIds = (columnId) => {
        switch (columnId) {
            case COLUMN_ORDER[1]:
                return pagesOrder;
            case COLUMN_ORDER[2]:
                return subPagesOrder;
            default:
                return pages.filter(page => !pagesOrder.includes(page.id) && !subPagesOrder.includes(page.id)).map(x => x.id);
        }
    }

    const getItems = (columnId) => {
        switch (columnId) {
            case COLUMN_ORDER[1]:
                return pagesOrder.map(orderId => pages.find(page => page.id === orderId) || null).filter(x => x);
            case COLUMN_ORDER[2]:
                return subPagesOrder.map(orderId => pages.find(page => page.id === orderId) || null).filter(x => x);
            default:
                return pages.filter(page => !pagesOrder.includes(page.id) && !subPagesOrder.includes(page.id));
        }
    }

    const onDragEnd = (result) => {
        const { source, destination, draggableId } = result;

        if (!destination) {
            // No destination
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            // Dropped in same spot
            return;
        }
        
        const newPageOrder = Array.from(getIds(COLUMN_ORDER[1]));
        const newSubPageOrder = Array.from(getIds(COLUMN_ORDER[2]));

        if (source.droppableId === COLUMN_ORDER[1]) {
            newPageOrder.splice(source.index, 1);
        } else if (source.droppableId === COLUMN_ORDER[2]) {
            newSubPageOrder.splice(source.index, 1);
        } else {
            // Goes to unused columnm, ignore
        }

        if (destination.droppableId === COLUMN_ORDER[1]) {
            newPageOrder.splice(destination.index, 0, draggableId);
        } else if (destination.droppableId === COLUMN_ORDER[2]) {
            newSubPageOrder.splice(destination.index, 0, draggableId);
        } else {
            // Goes to unused columnm, ignore
        }
    
        const draggedColumnChanges = {
            [COLUMN_ORDER[1]]: newPageOrder,
            [COLUMN_ORDER[2]]: newSubPageOrder,
        };

        onChange(draggedColumnChanges);
    }

    const buildColumn = (columnId) => {
        const column = COLUMNS[columnId];
        const items = getItems(columnId);

        return (
            <Cell desktopSize={4} tabletSize={2} phoneSize={1}>
                <TenantPageColumn key={column.id} column={column} items={items}/>
            </Cell>
        );
    }

    function buildPageContent() {
        return (
            <Grid>
                <DragDropContext onDragEnd={onDragEnd}>
                    { COLUMN_ORDER.map(buildColumn) }
                </DragDropContext>
            </Grid>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    )
}

export default TenantPageOrder;