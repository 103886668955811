import ColorString from 'color-string';

const hashRegex = /#/g;

/* These functions are used to convert between different formats;
    - #RRGGBBAA; color format on web/ProTool
    - AARRGGBB; color format stored on Firebase for app/Flutter
    - { a: ,r: ,g: ,b }; color format for various npm packages
*/


// Takes in AARRGGBB format and converts to object with { a: ,r: ,g: ,b: } values
const convertToARGBObject = (hexColor) => {
    const color = hexColor.replace(hashRegex, '');
    const alpha = color.slice(0, 2);
    const rgb = color.slice(2);

    // ColorString package requires rgba format
    const colorValues = ColorString.get(`#${rgb}${alpha}`).value;

    const colorObject = {
        r: colorValues[0],
        g: colorValues[1],
        b: colorValues[2],
        a: colorValues[3],
    };

    return colorObject;
}

// Takes in { a: ,r: ,g: ,b: } object and converts to #RRGGBBAA format
const convertARGBObjectToPreview = (colorObject) => {
    const { a, r, g, b } = colorObject;

    return ColorString.to.hex([ r, g, b, a ]);
}

// Takes in { a: ,r: ,g: ,b: } object and converts to AARRGGBB format
const convertARGBObjectToARGB = (colorObject) => {
    const rgba = convertARGBObjectToPreview(colorObject);
    const color = rgba.replace(hashRegex, '');
    const alpha = color.slice(6);
    const rgb = color.slice(0, 6);
    
    return `${alpha}${rgb}`.padStart(8, 'F');
}

export {
    convertToARGBObject,
    convertARGBObjectToPreview,
    convertARGBObjectToARGB,
};