import React, { useState } from 'react'
import { ExpansionList, ExpansionPanel, Grid, Cell, Button } from 'react-md';
import TextFormField from '../../components/FormFields/TextFormField';
import useFormValidation from '../../hooks/useFormValidation';
import moment from 'moment';
import { validate } from './CaseSearchValidate';
import useCustomLabels, { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';

function CaseSearchForm(props) {
    const { onSubmit, clearHistory, caseSearchHistory } = props;
    const [ expanded, setExpanded ] = useState(false);

    const INITIAL_STATE = {
        caseNumber: '',
    };
    
    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, searchByCaseNumber, validate);

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomLabels('CaseSearch');

    function searchByCaseNumber(values) {
        const search = parseSubmissionToSearch(values);
        onSubmit(search);
    }

    function parseSubmissionToSearch(values) {
        return {
            caseNumber: values.caseNumber.trim(),
        };
    }

    const buildSearchHistory = () => {
        return (
            <>
                <h4 style={{marginBottom: '5px'}}>{ `${getLabel('historyHeader')}:` }</h4>
                {
                    !caseSearchHistory || caseSearchHistory.length === 0
                        ? null
                        : <ThemedButton flat onClick={clearHistory}> Clear History </ThemedButton>
                }
                {
                    !caseSearchHistory || caseSearchHistory.length === 0
                        ? <p>{getLabel('emptyHistory')}</p>
                        : caseSearchHistory.map(buildCaseSearchItem)
                }
            </>
        );
    }

    const buildCaseSearchItem = (searchHistory) => {
        return (
            <Grid key={`case-seach-${searchHistory.uid}`}>
                <Cell desktopSize={3} tabletSize={4} phoneSize={2}>
                    <p>Searched On: {moment(searchHistory.createdOn).format("MMM Do YYYY h:mm:ss a")}</p>
                </Cell>
                <Cell desktopSize={9} tabletSize={4} phoneSize={2}>
                    <a href={'#'} onClick={() => handleChange('caseNumber', searchHistory.caseNumberSearched)}><p>{ `${searchHistory.caseNumberSearched}` }</p></a>
                </Cell>
            </Grid>
        );
    }

    const buildFooterButtons = () => {
        const actions = [
            <ThemedButton flat onClick={() => setExpanded(false)}>{'Close'}</ThemedButton>,
            <ThemedButton flat swapTheming type='submit'>{'Search'}</ThemedButton>,
        ];

        return actions;
    }

    return (
        <form id={'case-search-form'} onSubmit={handleSubmit}>
            <ExpansionList>
                <ExpansionPanel
                    expanded={expanded}
                    onExpandToggle={setExpanded}
                    id='CaseSearchForm'
                    label={getLabel('searchBarHeader')}
                    footer={<footer className="md-dialog-footer md-dialog-footer--inline" >{ buildFooterButtons() }</footer>}
                >
                    <TextFormField
                        name="caseNumber"
                        label={getDataLabel('caseNumberData')}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    { buildSearchHistory() }
                </ExpansionPanel>
            </ExpansionList>
        </form>
    );
}

export default CaseSearchForm;