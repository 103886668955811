import React, { useMemo } from 'react';
import { Link, Route } from 'react-router-dom';
import { FontIcon, ListItem, Avatar, Badge } from 'react-md';
import useCustomTheme from '../../hooks/useCustomTheme';
import NavItemIcon from './NavItemIcon';
import ThemedBadge from '../ThemedBadge';

/**
 * Due to the fact that react-router uses context and most of the components
 * in react-md use PureComponent, the matching won't work as expected since
 * the PureComponent will block the context updates. This is a simple wrapper
 * with Route to make sure that the active state is correctly applied after
 * an item has been clicked.
 * 
**/

function NavItemLink(props) {
    const { label, to, svg, icon, nestedItems, onClick, isNested, badgeCount } = props;

    const navIcon = useMemo(() => !icon && !svg ? null : <NavItemIcon id={icon} svg={svg}/>, [ icon, svg ]);

    const {
        getNavMenuTheme,
        getNavNoBackgroundTheme,
        getNavOnlyBackgroundTheme
    } = useCustomTheme();

    const buildNestedItems = (nestedItems) => {
        return !nestedItems
            ? null
            : nestedItems.map((item) => <NavItemLink {...item} key={`nav-nested-${item.to}`} isNested={true}/>)
    }

    const buildBadgeCounter = (to, badgeCount) =>  {
        const child = (<p style={getNavMenuTheme(false, true, true)}>.</p>);
        
        if (badgeCount === null || badgeCount === undefined) {
            return child;
        }

        return (
            <ThemedBadge
                badgeId={`nav-badge-${to}`}
                badgeContent={badgeCount}
            >
                { child }
            </ThemedBadge>
        );
    }

    const buildExpanderIcon = (style) => {
        return (
            <FontIcon style={style}>keyboard_arrow_down</FontIcon>
        );
    }

    const buildNavigationItem = (props) => {
        const { match, location } = props;
        
        const nestedColor = getNavOnlyBackgroundTheme(!!match);
        const nestedStyle = Object.assign({ height: '.80em', width: '.80em', marginTop: '15px' }, nestedColor);
        const leftAvatar = isNested ? <Avatar style={nestedStyle}/> : null;

        return (
            <ListItem
                contentStyle={{flexGrow: 0}}
                leftAvatar={leftAvatar}
                leftIcon={navIcon}
                active={!!match}
                component={Link}
                onClick={onClick}
                to={onClick ? location.pathname : to}
                primaryText={label}
                rightIcon={nestedItems ? null : buildBadgeCounter(to, badgeCount)}
                primaryTextStyle={ getNavNoBackgroundTheme(!!match) }
                nestedItems={ buildNestedItems(nestedItems) }
                expanderIcon={ buildExpanderIcon(getNavNoBackgroundTheme(!!match)) }
            />
        );
    }

    return (
        <Route path={to} exact key={`nav-${to}`}>
            { buildNavigationItem }
        </Route>
    );
}

export default NavItemLink;
