/* eslint-disable linebreak-style */
import  React,  { Component } from 'react';
import  { Button } from 'react-md';
import  { CopyToClipboard } from 'react-copy-to-clipboard';
import { setTimeout } from 'timers';

class CopyButton extends Component {
    constructor(props) {
        super(props);
        this.copyAction = this.copyAction.bind(this);
        this.state = { copied: false };
    }
    copyAction = () => {
        this.setState({ copied: true });
        setTimeout(function() {
            this.setState({ copied: false })
        }.bind(this), 1700);   
    }
    render() {
        return (
            <CopyToClipboard text={this.props.copyTextValue} onCopy={() => this.copyAction()}>
                <Button flat primary>{this.state.copied === false ? this.props.buttonText : this.props.altText }</Button>
            </CopyToClipboard>   
        );
    }
}

export default CopyButton;