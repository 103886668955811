function validate(values) {
    const errors = {};

    if (values.confirmation.trim() !== values.expectedConfirmation.trim()) {
        errors.confirmation = `Type \'${values.expectedConfirmation.trim()}\' to confirm`;
    }

    return errors;
}

export {
    validate,
}