import { useCallback } from 'react';
import useIsTrackEnabled from './useIsTrackEnabled';

export default function useLocalVideoToggle(room, localTracks) {
    const videoTrack = localTracks.find(track => track.name === 'camera');
    const isEnabled = useIsTrackEnabled(videoTrack);

    const toggleVideoEnabled = useCallback(() => {
        if (videoTrack) {
            videoTrack.isEnabled ? videoTrack.disable() : videoTrack.enable();
        }
    }, [videoTrack]);

    return [isEnabled, toggleVideoEnabled];
}


// import { useCallback } from 'react';
// import useLocalVideoTrack from './useLocalTracks';

// export default function useLocalVideoToggle(room, localTracks) {

//     const videoTrack = localTracks.find(track => track.name === 'camera');
//     const { localParticipant } = room;
//     const { getLocalVideoTrack } = useLocalVideoTrack();

//     const toggleVideoEnabled = useCallback(() => {
//         if (videoTrack) {
//             if (localParticipant) {
//                 const localTrackPublication = localParticipant.unpublishTrack(videoTrack);
//                 localParticipant.emit('trackUnpublished', localTrackPublication);
//             }

//             videoTrack.stop();
//         } else {
//             if (localParticipant) {
//                 getLocalVideoTrack().then((track) => {
//                     localParticipant.publishTrack(track);
//                 });
//             }
//         }
//     }, [videoTrack, localParticipant]);

//     return [!!videoTrack, toggleVideoEnabled];
// }
