import React from 'react';
import { Cell } from 'react-md';

const buildHalfCellDataDisplay = (headerText, valueText) => {
    return ( <CellDataDisplay desktopSize={6} tabletSize={4} phoneSize={2} headerText={headerText} valueText={valueText}/> );
}

function CellDataDisplay(props) {
    const { desktopSize, tabletSize, phoneSize,
        headerText, valueText,
        headerStyle = {}, valueStyle = {},
        headerClassName, valueClassName } = props;
        
    const defaultHeaderStyle = { marginTop: '10px' };

    const combinedHeaderStyle = Object.assign(defaultHeaderStyle, headerStyle);

    return (
        <Cell desktopSize={desktopSize || 12} tabletSize={tabletSize || 8} phoneSize={phoneSize || 4}>
            <h4 className={headerClassName || ''} style={combinedHeaderStyle}>{ ( headerText || '').toUpperCase() }</h4>
            <p className={valueClassName || ''} style={valueStyle}>{ valueText || '' }</p>
        </Cell>
    );
}

export default CellDataDisplay;

export {
    buildHalfCellDataDisplay,
};