import React from 'react';
import { useSelector } from 'react-redux';
import usePublications from '../../hooks/usePublications';
import Publication from '../Publication';
/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */
export default function ParticipantTracks({ participant, disableAudio, enableScreenShare, videoPriority, isRightPane }) {
    const room = useSelector(state => state.get('global').get('room'));
    const publications = usePublications(participant);
    const isLocal = !room ? true : participant === room.localParticipant;
    let filteredPublications;
    
    if (enableScreenShare && publications.some(p => p.trackName === 'screen')) {
        filteredPublications = publications.filter(p => p.trackName !== 'camera');
    } else {
        filteredPublications = publications.filter(p => p.trackName !== 'screen');
    }

   return (
        <>
            {filteredPublications.map((publication) => (
                <Publication
                    key={publication.kind}
                    publication={publication}
                    participant={participant}
                    isLocal={isLocal}
                    disableAudio={disableAudio}
                    videoPriority={videoPriority}
                    isRightPane={isRightPane}
                />
            ))}
        </>
  );
}
