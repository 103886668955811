import moment from 'moment';

function validate(values) {
    const errors = {};

    if (!values.userId.trim() && !values.email.trim() && !values.startDate && !values.endDate) {
        errors.userId = "Atleast one search parameter is required";
        errors.email = "Atleast one search parameter is required";
        errors.startDate = "Atleast one search parameter is required";
        errors.endDate = "Atleast one search parameter is required";
    }

    if (!values.startDate && values.endDate) {
        errors.startDate = 'Start date is required';
    }

    if (values.startDate && !values.endDate) {
        errors.endDate = 'End date is required';
    }

    if (values.startDate && values.endDate && moment(values.startDate).isSameOrAfter(moment(values.endDate))) {
        errors.startDate = 'Start date can not be the same or after end date';
        errors.endDate = 'End date can not be the same or before start date';
    }
    
    return errors;
};

export {
    validate,
};