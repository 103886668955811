import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import createHelperMiddleware from './utils/store';
import thunk from 'redux-thunk';

//import { saveState } from './localStorage';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
    const middlewares = [
        sagaMiddleware,
        createHelperMiddleware,
        thunk,
    ];

    const enhancers = [
        applyMiddleware(...middlewares),
    ];

    /* eslint-disable no-underscore-dangle */
    const composeEnhancers = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
    /* eslint-enable */
    const store = createStore(
        createReducer(),
        fromJS(initialState),
        composeEnhancers(...enhancers)
    );

    // store.subscribe(() => {
    //     saveState(store.getState());
    // });

    // Extensions
    store.asyncReducers = {}; // Async reducer registry

    // // Make reducers hot reloadable, see https://mxs.is/googmo
    // /* istanbul ignore next */
    // if (module.hot) {
    //     module.hot.accept('./reducers', () => {
    //         System.import('./reducers').then((reducerModule) => {
    //             const createReducers = reducerModule.default;
    //             const nextReducers = createReducers(store.asyncReducers);

    //             store.replaceReducer(nextReducers);
    //         });
    //     });
    // }

    return store;
}

