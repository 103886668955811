import React from 'react';
import { getCreatedByCPName } from './';
import { convert24To12Hour } from '../../utils/dateTime';

const EventScheduleCard = ({item}) => {
    return (
        <div id="event-card-contents">
            <p>{ item.title ? `Appointment for ${item.title}` : null }</p>
            <p>{ item.startTime ? `Starts at ${convert24To12Hour(item.startTime)}` : null }</p>
            <p>{ item.endTime ? `Ends at ${convert24To12Hour(item.endTime)}` : null }</p>
            <p>{ item.location ? `Location: ${item.location}` : null }</p>
            <p>{ item.notes ? `Notes: ${item.notes}` : null }</p>
            <p>{ getCreatedByCPName(item) ? `Created by: ${getCreatedByCPName(item)}` : null }</p>
        </div>
    );
}

export default EventScheduleCard;