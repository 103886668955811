import React, { useState, useEffect } from 'react'
import * as firebase from 'firebase';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { selectClientProfile, selectOrganizationId, selectCurrentUser } from '../../selectors/global';
import { setToolbarButtons } from '../../actions/global';
import { NAV_DEFAULT, NAV_CASE_SEARCH } from '../../constants/navigation';
import { Cell } from 'react-md';
import CreateEntryForm from './CreateEntryForm';
import UpdateEntryForm from './UpdateEntryForm';
import useDialogFormBuilder from '../../hooks/useDialogFormBuilder';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { FILTER_DATE, FILTER_TEXT, FILTER_ARRAY_CONTROLS, INITIAL, FILTER_ARRAY_TEXT } from '../../constants';
import { NOTIFICATION_ERROR, NOTIFICATION_INFO, NOTIFICATION_SUCCESS  } from '../../constants/notifications';
import RepositoryCard from '../../components/RepositoryCard';
import DetailedRepositoryCard from './DetailedRepositoryCard';
import { uploadFiles } from '../../utils/fileUpload';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';
import useFilterSorterPanel from '../../hooks/useFilterSorterPanel';
import useMediaViewer from '../../hooks/useMediaViewer';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import { TabletOrMobile } from '../../components/ResponsiveMedia';
import TwoColumnLayout from '../../pageLayouts/TwoColumnLayout';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import DeleteConfirmationForm from '../../components/Forms/DeleteConfirmationForm';
import { callCloudRunFunction } from '../../utils/firestore';
import { getClientLabelVariableData } from '../../utils/customBranding';
import useCustomLabels, { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';
import useCustomAssets from '../../hooks/useCustomAssets';

function ClientRepository(props) {
    const { initialFilterState, freezeToolbar = false } = props;
    const history = useHistory();
    const dispatch = useDispatch();

    const organizationId = useSelector(selectOrganizationId());

    const clientProfile = useSelector(selectClientProfile());
    const currentUser = useSelector(selectCurrentUser());

    const [ focusedEntryId, setFocusedEntryId ] = useState(null);
    const [ items, setItems ] = useState([]);
    const [ selectableOrgCategories, setSelectableOrgCategories ] = useState([]);
    const [ selectableChildrenCategories, setSelectableChildrenCategories ] = useState([]);
    const [ selectableCoParentCategories, setSelectableCoParentCategories ] = useState([]);

    const labelData = {
        customTitleBar: { titleBarKey: 'titleBar' },
        labelVariableData: getClientLabelVariableData(clientProfile),
    };
    
    if (freezeToolbar) {
        delete labelData.customTitleBar;
    }

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();
    
    const {
        getLabel
    } = useCustomLabels('ClientFileRepository', labelData);
    
    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        // const toolBarTitle = `Secure File Repository - ${clientProfile.name}`;
        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];
        
        if (!freezeToolbar) {
            // dispatch(setToolbarTitle(toolBarTitle));
            dispatch(setToolbarButtons(toolBarButtons));
        }
        
        var dataStreamEntries = null;
        var dataStreamCategories = null;

        if (organizationId === false || !clientProfile) {
            return;
        }

        // Loading repository entries
        const db = firebase.firestore();
        
        dataStreamEntries = db.collection('ProRepositoryEntries').where('organizationId', '==', organizationId).where('clientId', '==', clientProfile.id).onSnapshot((snapshot) => {
            const entries = snapshot.docs.map(doc => {
                const entryItem = doc.data();

                entryItem.id = doc.ref.id;
                entryItem.createdOn = entryItem.createdOn ? entryItem.createdOn.toDate() : null;
                entryItem.updatedOn = entryItem.updatedOn ? entryItem.updatedOn.toDate() : null;
                entryItem.allCategories = [ ...(entryItem.organizationCategories || []), ...(entryItem.childrenCategories || []), ...(entryItem.coParentCategories || []) ];

                return entryItem;
            });

            setItems(entries);
            finishedLoading();
        });

        // Load organization categories
        dataStreamCategories = db.collection('ProOrganizations').doc(organizationId).collection('EntryCategories').onSnapshot((snapshot) => {
            const categories = snapshot.docs.map(doc => {
                const category = doc.data();
                category.label = category.category;
                category.value = category.category;

                return category;
            });

            setSelectableOrgCategories(categories);
        });

        // Loading children categories
        db.collection('Children').where('participants', 'array-contains', clientProfile.uid).get().then((childrenDocs) => {
            const childCategories = childrenDocs.docs.map((docRef) => {
                const child = docRef.data();

                return {
                    label: `${child.firstName} ${child.lastName}`,
                    value: `${child.firstName} ${child.lastName}`,
                };
            });

            setSelectableChildrenCategories(childCategories);
        }).catch((err) => {
            console.log(err);
        });

        // Loading coParent as categories
        callCloudRunFunction(currentUser, 'getCoParents', { userId: clientProfile.uid }).then((coParents) => {
            const coParCategories = coParents.map((coParent) => {
                return {
                    label: `${coParent.firstName} ${coParent.lastName}`,
                    value: `${coParent.firstName} ${coParent.lastName}`,
                };
            });

            setSelectableCoParentCategories(coParCategories);
        }).catch((err) => {
            console.log(err);
        })
        
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Repository',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        return function cleanup() {
            if (!freezeToolbar) {
                dispatch(setToolbarButtons([]));
            }

            if (dataStreamEntries) {
                dataStreamEntries();
            }

            if (dataStreamCategories) {
                dataStreamCategories();
            }
        }
    }, []);

    const foundEntry = items && items.find(entry => entry.uid === focusedEntryId);
    const allSelectableCategories = [...selectableOrgCategories, ...selectableChildrenCategories, ...selectableCoParentCategories];

    // Define data properties for filtering
    const columns = [
        { label: getDataLabel('caseNumberData'), value: 'caseNumber', type: FILTER_TEXT },
        { label: getDataLabel('titleData'), value: 'title', type: FILTER_TEXT },
        { label: getDataLabel('notesData'), value: 'notes', type: FILTER_TEXT },
        { label: getDataLabel('categoryData'), value: 'allCategories', type: FILTER_ARRAY_CONTROLS, controls: allSelectableCategories },
        { label: getDataLabel('categoryData'), value: 'allCategories', type: FILTER_ARRAY_TEXT },
        { label: getDataLabel('createdByData'), value: 'createdByName', type: FILTER_TEXT },
        { label: getDataLabel('createdData'), value: 'createdOn', type: FILTER_DATE, isPrimary: true },
        { label: getDataLabel('updatedData'), value: 'updatedOn', type: FILTER_DATE },
    ];

    const {
        getFilteredAndSortedItems,
        renderFilterSortPanel,
    } = useFilterSorterPanel(getLabel('filterSorterHeader'), columns, items, initialFilterState);

    // Create entry dialog setup
    const createEntryDialog = {
        FormComponent: CreateEntryForm,
        onSubmit: createRepositoryEntry,
        dialogData: { title: getLabel('createTitleBar') },
        formData: { selectableOrgCategories: selectableOrgCategories, selectableChildrenCategories: selectableChildrenCategories, selectableCoParentCategories: selectableCoParentCategories },
    };

    const {
        showDialog: showCreateDialog,
        renderDialogForm: renderCreateDialogForm
    } = useDialogFormBuilder(createEntryDialog);

    // Update entry dialog setup
    const updateEntryDialog = {
        FormComponent: UpdateEntryForm,
        onSubmit: updateRepositoryEntry,
        dialogData: { title: getLabel('updateTitleBar') },
        formData: { initialValues: foundEntry, selectableOrgCategories: selectableOrgCategories, selectableChildrenCategories: selectableChildrenCategories, selectableCoParentCategories: selectableCoParentCategories },
    };

    const {
        showDialog: showUpdateDialog,
        renderDialogForm: renderUpdateDialogForm,
    } = useDialogFormBuilder(updateEntryDialog);

    const deleteEntryPopup = {
        FormComponent: DeleteConfirmationForm,
        onSubmit: deleteRepositoryEntry,
        popupData: { title: getLabel('deleteConfirmationText'), formId: 'delete-entry-form' },
        formData: { formId: 'delete-entry-form', initialValues: foundEntry },
    };

    const {
        showPopup: showDeletePopup,
        renderPopupForm: renderDeletePopupForm,
    } = usePopupFormBuilder(deleteEntryPopup);

    const {
        showDialog: showMediaViewDialog,
        renderMediaViewer,
    } = useMediaViewer({});
    
    if (organizationId === false || !clientProfile) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }
    
    const caseSearchRedirect = (caseNumber) => {
        return history.push(`${NAV_CASE_SEARCH}#${caseNumber}`);
    }

    function createRepositoryEntry(entryData) {
        const entryMetadata = {
            createdById: currentUser.uid,
            createdByName: currentUser.displayName,
            organizationId: organizationId,
            clientId: clientProfile.id,
            uploads: [],
        };

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Client Repository',
            action: 'create repository entry',
            clientId: clientProfile.id,
            metadata: {...entryData, uploads: []},
        };

        const entryDocument = Object.assign({}, entryData, entryMetadata);
        
        showToastNotification(`Creating new entry...`, NOTIFICATION_INFO);
        
        // Create Repository Entry Document
        callCloudRunFunction(currentUser, 'createRepositoryEntry', entryDocument).then((entryDoc) => {
            showToastNotification(`Sucessfully created a new entry. Your files will be uploaded shortly`, NOTIFICATION_SUCCESS);
            setFocusedEntryId(entryDoc.uid);
            log.metadata.id = entryDoc.uid;
            log.entryId = entryDoc.uid;
            // Upload files and update Entry Document
            return uploadFiles(entryData.uploads, `SecureRepository/${organizationId}`, currentUser, 'Secure Repository File', []).then((uploadIds) => {
                log.metadata.uploads = uploadIds;
                return callCloudRunFunction(currentUser, 'updateRepositoryEntry', { id: entryDoc.uid, uploads: uploadIds });
            });
        }).then(() => {
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`An error has occurred`, NOTIFICATION_ERROR);
        });
    }

    function updateRepositoryEntry(entryData) {
        const entryToUpdate = items && items.find(entry => entry.uid === entryData.id);
        
        if (!entryToUpdate) {
            showToastNotification(`No such entry to update`, NOTIFICATION_ERROR);
            return;
        }

        const updateData = {
            id: entryData.id,
            caseNumber: entryData.caseNumber,
            title: entryData.title,
            notes: entryData.notes,
            uploads: entryData.oldUploads,
            organizationCategories: entryData.organizationCategories,
            childrenCategories: entryData.childrenCategories,
            coParentCategories: entryData.coParentCategories,
        };

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Client Repository',
            action: 'update repository entry',
            entryId: entryData.id,
            clientId: clientProfile.id,
            metadata: {...updateData, uploads: []},
        };
        
        showToastNotification(`Updating entry...`, NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'updateRepositoryEntry', updateData).then(() => {
        // Update Entry Document and delete unwanted uploads
            if (entryData.deletedUploads) {
                Array.from(entryData.deletedUploads).forEach((uploadId) => {
                    callCloudRunFunction(currentUser, 'deleteUpload', { uid: uploadId });
                });
            }
            showToastNotification(`Sucessfully updated the entry. Any new files will be uploaded shortly`, NOTIFICATION_SUCCESS);
        }).then(() => {
            // Upload files and update Entry Document
            return uploadFiles(entryData.newUploads, `SecureRepository/${organizationId}`, currentUser, 'Secure Repository File', []).then((uploadIds) => {
                log.metadata.uploads = [...entryData.oldUploads, ...uploadIds];
                log.metadata.deletedUploads = entryData.deletedUploads;
                return callCloudRunFunction(currentUser, 'updateRepositoryEntry', { id: entryData.id, uploads: [...entryData.oldUploads, ...uploadIds] });
            });
        }).then(() => {
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`Entry failed to be updated`, NOTIFICATION_ERROR);
        });
    }

    function deleteRepositoryEntry(entryData) {
        const entryToDelete = items && items.find(entry => entry.uid === entryData.id);

        if (!entryToDelete) {
            showToastNotification(`No such entry to delete`, NOTIFICATION_ERROR);
            return;
        }

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Client Repository',
            action: 'delete repository entry',
            clientId: clientProfile.id,
            entryId: entryToDelete.uid,
        };

        showToastNotification(`Deleting entry...`, NOTIFICATION_INFO);

        // Delete Entry document
        return callCloudRunFunction(currentUser, 'deleteRepositoryEntry', { id: entryToDelete.uid }).then(() => {
            showToastNotification(`Entry has been deleted`, NOTIFICATION_SUCCESS);
        }).then(() => {
            // Delete Upload document & files
            const promises = [];

            if (entryToDelete.uploads) {
                Array.from(entryToDelete.uploads).forEach((uploadId) => {
                    promises.push(callCloudRunFunction(currentUser, 'deleteUpload', { uid: uploadId }));
                });
            }

            return Promise.all(promises);
        }).then(() => {
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`Entry could not be deleted`, NOTIFICATION_ERROR);
        });
    }

    const buildFilterSortPanel = () => {
        return (
            <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                { renderFilterSortPanel() }
            </Cell>
        );
    }

    const buildCreateEntryButton = () => {
        return (
            <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                <ThemedButton className="full-width-field" flat swapTheming primary iconChildren="add" onClick={showCreateDialog}>
                    { getLabel('createButtonText') }
                </ThemedButton>
            </Cell>
        );
    }

    const returnToListButton = () => {
        return (
            <Cell size={12}>
                <ThemedButton className="full-width-field" flat swapTheming primary onClick={() => setFocusedEntryId(null)}>
                    { getLabel('returnToResultsText') }
                </ThemedButton>
            </Cell>
        );
    }

    const buildEmptyEntries = () => {
        const initialCard = { status: INITIAL, cardMediaUrl: getAsset('clientRepositoryEmpty'), headerTitle: getLabel('noResultsHeader'), subTitle: getLabel('noResultsSubheader'), learnMoreLink: false  };
        return (<GettingStartedPaper {...initialCard} key={initialCard.status} />);
    }

    const buildEmptyEntriesMobile = () => {
        const initialCard = { status: INITIAL, cardMediaUrl: getAsset('clientRepositoryEmpty'), headerTitle: getLabel('noResultsMobileHeader'), subTitle: getLabel('noResultsMobileSubheader'), learnMoreLink: false  };
        
        return (
            <Cell size={12}>
                <GettingStartedPaper {...initialCard} key={initialCard.status} />
            </Cell>
        );
    }

    const buildEntriesList = () => {
        return !items || items.length === 0
            ? ( <TabletOrMobile>{ buildEmptyEntriesMobile() }</TabletOrMobile> )
            : ( <> { getFilteredAndSortedItems().map(buildEntry) } </> );
    }

    const buildEntry = (entry, index) => {
        return (
            <Fade>
                <Cell key={`repository-entry-card-${index}`} size={12}>
                    <RepositoryCard onViewClick={setFocusedEntryId} onCaseNumberClicked={caseSearchRedirect} entry={entry} />
                </Cell>
            </Fade>
        );
    }

    const buildDetailedEntry = () => {
        if (!foundEntry) {
            return buildEmptyEntries();
        }

        return (
            <Bounce right>
                <Cell size={12}>
                    <DetailedRepositoryCard canUpdate={true} onUpdate={showUpdateDialog} canDelete={true} onDelete={showDeletePopup} entry={foundEntry} onView={showMediaViewDialog} onCaseNumberClicked={caseSearchRedirect}/>
                </Cell>
            </Bounce>
        );
    }

    const buildListSection = () => {
        return (
            <>
                { buildCreateEntryButton() }
                { buildFilterSortPanel() }
                { buildEntriesList() }
            </>
        );
    }

    const buildTabletMobileSection = () => {
        return !foundEntry
            ? ( buildListSection() )
            : (
                <>
                    { returnToListButton() }
                    { buildDetailedEntry() }
                </>
            );
    }

    function buildPageContent() {
        return (<TwoColumnLayout buildLeftColumn={buildListSection} buildRightColumn={buildDetailedEntry} buildTabletMobile={buildTabletMobileSection}/>);
    }

    return (
        <>
            { renderPageContent() }
            { renderCreateDialogForm() }
            { renderUpdateDialogForm() }
            { renderDeletePopupForm() }
            { renderMediaViewer() }
        </>
    );
}

export default ClientRepository;