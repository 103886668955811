import React from 'react';
import { FileInput, MenuTab } from 'react-md';

export const renderFileField = ({ input, errorClass, meta, meta: { touched, pristine, error, warning }, ...props }) => (
    <FileInput
        {...input}
        {...props}
        error={MenuTab.touched && MenuTab.invalid}
        errorText={error}
    />
);

export default renderFileField;