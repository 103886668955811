import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import usePageFramework from '../../hooks/usePageFramework';
import { selectOrganizationId } from '../../selectors/global';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import UpdateTenantThemeForm from './UpdateTenantThemeForm';

function TenantTheme(props) {
    const { onUpdate, onDelete } = props;
    const {
        selector,
    } = usePageFramework();

    const organizationId = selector(selectOrganizationId());
    const [ tenantThemeData, setTenantThemeData ] = useState(null);

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        let tenantThemeStream = null;
        const db = firebase.firestore();

        tenantThemeStream = db.collection('TenantThemes').doc(organizationId).onSnapshot((snapshot) => {
            if (!snapshot.exists) {
                errorLoading();
            } else {
                const tenantThemeData = snapshot.data();
                setTenantThemeData(tenantThemeData);
                finishedLoading();
            }
        });

        return () => {
            if (tenantThemeStream) {
                tenantThemeStream();
            }
        }
    }, []);

    function buildPageContent() {
        return (
            <UpdateTenantThemeForm formId={'update-tenant-theme-form'} initialValues={tenantThemeData} onSubmit={onUpdate} onDelete={onDelete}/>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default TenantTheme;