import React, { useEffect, useState } from 'react';
import useCustomLabels from '../../hooks/useCustomLabels';
import useFormValidation from '../../hooks/useFormValidation';
import TextFormField from '../../components/FormFields/TextFormField';
import { findItemByKey } from '../../utils/customBranding';
import { Cell, CardTitle, Button, CardText, Grid } from 'react-md';
import { validate } from './UpdateAdvancedLabelsValidate';

function UpdateAdvancedLabelsForm(props) {
    const { onSubmit, formId, title } = props;

    const {
        getLabelData,
        getLabelKeys,
    } = useCustomLabels(formId);

    const labelData = getLabelData();
    const labelKeys = getLabelKeys();

    const INITIAL_STATE = {
        labelKey: formId,
    };

    // similar system to Mediation ALlocation form, map of maps to hold values
    const labelItems = {};

    labelKeys.forEach((key) => {
        const initialLabelItem = findItemByKey(labelData, key);

        const labelItem = {
            key: key,
            value: initialLabelItem.value || '',
            constraint: initialLabelItem.constraint || 20,
            component: initialLabelItem.component || 'text',
            keyHeader: initialLabelItem.keyHeader || key,
        };

        labelItems[key] = labelItem;
    });

    INITIAL_STATE.labelItems = labelItems;

    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, saveLabels, validate(labelKeys));

    function saveLabels(values) {
        const labels = parseSubmissionToLabels(values);
        onSubmit(labels);
    }

    function resetLabels() {
        changeValues(INITIAL_STATE);
    }

    function parseSubmissionToLabels(values) {
        const labels = {
            labelKey: values.labelKey,
            labelItems: labelKeys.map(x => values.labelItems[x]),
        };

        return labels;
    }

    const buildLabelTextFormField = (labelKey) => {
        const labelItemValues = values.labelItems[labelKey];
        const labelItemErrors = errors.labelItems && errors.labelItems[labelKey] || {};

        const handleLabelFieldChange = (fieldKey, fieldValue) => {
            const mergedLabelItem = Object.assign(labelItemValues, { [fieldKey]: fieldValue })
            const mergedLabelItems = Object.assign(values['labelItems'], { [labelKey]: mergedLabelItem });
            handleChange('labelItems', mergedLabelItems);
        }

        return (
            <>
                <Cell desktopSize={4}>
                    <TextFormField
                        name={'keyHeader'}
                        label={'Value Name'}
                        values={labelItemValues}
                        errors={labelItemErrors}
                        floating={true}
                        placeHolder={''}
                        onChange={handleLabelFieldChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6}>
                    <TextFormField
                        name={'value'}
                        label={'Default Value'}
                        values={labelItemValues}
                        errors={labelItemErrors}
                        floating={true}
                        placeHolder={''}
                        onChange={handleLabelFieldChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={2}>
                    <TextFormField
                        name={'constraint'}
                        label={'Constraint'}
                        values={labelItemValues}
                        errors={labelItemErrors}
                        floating={true}
                        placeHolder={''}
                        onChange={handleLabelFieldChange}
                        onBlur={handleBlur}
                    />
                </Cell>
            </>
        )

    }

    return (
        <form id={formId} name={formId} onSubmit={handleSubmit}>
            <CardTitle title={title}>
                <Button className="md-cell--right" flat primary onClick={resetLabels}>Reset</Button>
                <Button className="md-cell--right" flat primary swapTheming type="submit" disabled={isSubmitting}>Save</Button>
            </CardTitle>
            <CardText>
                <Grid>
                    { labelKeys.map(buildLabelTextFormField) }
                </Grid>
            </CardText>
        </form>
    );
}

export default UpdateAdvancedLabelsForm;