import React from 'react';
import { Switch } from 'react-md';
import { Bounce } from 'react-toastify';

function ToggleFormField(props) {
    const { name, label, values, errors, onChange, ...otherProps } = props;

    const toggleField = () => {
        onChange(name, !values[name]);
    }

    return (
        <>
            <Switch
                id={name}
                name={name}
                checked={values[name]}
                label={label}
                onChange={toggleField}
                {...otherProps}
            />
            <Bounce when={!!errors[name]}>{<p className={"error-text"}> </p>}</Bounce>
        </>
    );
}

export default ToggleFormField;