import React from 'react';

export default function BandwidthWarning() {
    const bandwitdthStyle = {
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const warning = {
        textAlign: 'center',
        margin: '0.6em 0',
    };

    return (
        <div style={bandwitdthStyle}>
            <h3 style={warning}>Insufficient Bandwidth</h3>
        </div>
    );
}
