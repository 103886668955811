import React, { useState, useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import { NavigationDrawer, Button, List, Badge, IconSeparator, FontIcon } from 'react-md';
import NavItemLink from './components/NavItemLink';
import UserCard from './components/UserCard';
import Login from './containers/Login';
import Loading from './containers/Loading';
import NotFoundPage from './containers/NotFoundPage';
import { ToastNotification } from './components/Notifications/ToastNotification';
import NotificationBell from './components/Notifications/NotificationBell';
import { getAccessiblePages } from './utils/permissions';
import { selectisAuthenticated, selectToolbarTitle, selectToolbarButtons,
    selectUserOrganizationRoles, selectCurrentUser, selectCurrentOrganization,
    selectNotifications, selectClientProfile, selectCurrentUserRoles } from './selectors/global';
import { TERMS_OF_SERVICE, PRIVACY_POLICY } from './constants';
import { openInNewWindow } from './utils';
import InformationDialog from './components/InformationDialog';
import { NAV_DEFAULT } from './constants/navigation';
import usePageFramework from './hooks/usePageFramework';
import useCustomLabels from './hooks/useCustomLabels';
import { setClientProfile } from './actions/global';
import useCustomTheme from './hooks/useCustomTheme';
import CompanyLogo from './components/CompanyLogo';
import useCustomAssets from './hooks/useCustomAssets';
import ThemedBadge from './components/ThemedBadge';

function LayoutApp() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const currentOrganization = selector(selectCurrentOrganization());
    const currentUserRoles = selector(selectCurrentUserRoles());
    const userOrgRoles = selector(selectUserOrganizationRoles());
    const toolbarTitle = selector(selectToolbarTitle());
    const toolbarButtons = selector(selectToolbarButtons());
    const notifications = selector(selectNotifications());
    const isAuthenticated = selector(selectisAuthenticated());
    const clientProfile = selector(selectClientProfile());
    const isLoading = isAuthenticated === null;
    const [ noticeDialogVisible, setNoticeDialogVisible] = useState(false);
    const page = history.location.pathname;

    const {
        getTheme,
        getToolbarTheme,
        getNavMenuTheme,
    } = useCustomTheme();

    const navigationMenuTheme = getNavMenuTheme();

    const {
        getAsset,
    } = useCustomAssets('NavigationIcons');

    const {
        getLabel: getLinksLabel,
    } = useCustomLabels('QuickLinks');

    const {
        getLabel,
    } = useCustomLabels('Navigation');

    // Specialized hook to cleanup client profile property when navigating away from Client Pages
    useEffect(() => {
        if (clientProfile && !page.startsWith('/client')) {
            dispatch(setClientProfile(false));
        }
    }, [page, clientProfile]);

    const hasNoRoles = () => {
        return userOrgRoles < 0;
    }

    const buildTitleImage = () => {
        return (
            <a id="nav-drawer-title" className="md-pointer--hover" onClick={() => history.push(NAV_DEFAULT)}>
                <CompanyLogo/>
            </a>
        );
    }

    const buildToolBarActions = () => {
        // Map page provided toolbar buttons to components
        const pageActions = toolbarButtons && toolbarButtons.length
            ? toolbarButtons.map(x => {
                return (<Button icon primary onClick={x.onClick}>{x.icon}</Button>);
            })
            : [];

        // Push default notification button
        pageActions.push(
            <ThemedBadge
                id={'toolbar-notification-badge'}
                badgeId={'toolbar-notification-badge'}
                badgeContent={notifications ? notifications.length : 0 || 0}
            >
                <NotificationBell badgeId={'toolbar-notification-badge'}/>
            </ThemedBadge>
        );

        return pageActions;
    }

    const buildNavDrawerContents = (availableNavigation) => {
        const drawerContentStyle = { height: '100%' };
        const serviceLinksStyle = { position:'absolute', bottom: '0px', padding: '0px 20px 10px' };
        const fontStyle = { fontSize: '16px' };

        return (
            <div id="nav-drawer-contents" style={drawerContentStyle}>
                <UserCard/>
                <List style={navigationMenuTheme} className="md-list">
                    { availableNavigation }
                </List>
                <div style={serviceLinksStyle}>
                    <div className="md-pointer--hover menu-links-text" style={navigationMenuTheme} onClick={() => setNoticeDialogVisible(true)}>{ getLinksLabel('contactUs') }</div>
                    <IconSeparator className="md-pointer--hover" onClick={() => openInNewWindow(TERMS_OF_SERVICE)} label={getLinksLabel('termsOfService')} style={navigationMenuTheme} labelClassName="menu-links-text">
                        <FontIcon style={Object.assign({}, fontStyle, navigationMenuTheme)} className="menu-links-icon">open_in_new</FontIcon>
                    </IconSeparator>
                    <IconSeparator className="md-pointer--hover" onClick={() => openInNewWindow(PRIVACY_POLICY)} label={getLinksLabel('privacyPolicy')} style={navigationMenuTheme} labelClassName="menu-links-text">
                        <FontIcon style={Object.assign({}, fontStyle, navigationMenuTheme)} className="menu-links-icon">open_in_new</FontIcon>
                    </IconSeparator>
                </div>
            </div>
        );
    }

    const routesSignedIn = () => {
        if (hasNoRoles()) {
            return null; // This should avoid the flicker between pending and AUTHENTICATED with roles
        }
        
        const accessibleRoutes = [];

        const accessibleNavigation = getAccessiblePages(currentUser, currentOrganization, currentUserRoles, getLabel, getAsset).reduce((prev, page, i,a) => {
            if (page.isNav) {
                // Filter and find number of related notifications
                const numRelatedNotifications = page.notificationCategories && notifications ? notifications.filter(x => page.notificationCategories.includes(x.category)).length || 0 : null;

                prev.push(<NavItemLink {...page} key={page.to} badgeCount={numRelatedNotifications}/>);
            }

            accessibleRoutes.push(<Route path={page.to} exact component={page.componentObject} key={page.to}/>);

            return prev;
        }, []);

        accessibleRoutes.push(<Route component={NotFoundPage} key={'not-found'}/>);

        const styles = { content: { minHeight: 'auto', backgroundColor: '#ffffff' } };
        const navStyle = { marginTop: '80px' };
        
        const toolBarIsEmpty = toolbarTitle === null || toolbarTitle === undefined || toolbarTitle === '';
        const toolBarText = `${currentOrganization.organization}${toolBarIsEmpty ? '' : ` - ${toolbarTitle || ''}`}`

        return (
            <>
                { getTheme('urlStyle') && <link href={getTheme('urlStyle')} type='text/css' rel='stylesheet'/> }
                <NavigationDrawer
                    drawerHeader={buildTitleImage()}
                    toolbarStyle={getToolbarTheme()}
                    toolbarTitle={<div className='single-line-overflow'>{toolBarText}</div>}
                    toolbarActions={buildToolBarActions()}
                    toolbarZDepth={0}
                    contentId="main-demo-content"
                    contentStyle={styles.content}
                    mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
                    tabletDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
                    desktopDrawerType={NavigationDrawer.DrawerTypes.FULL_HEIGHT}
                    navStyle={navStyle}
                    contentClassName="md-grid"
                    drawerChildren={buildNavDrawerContents(accessibleNavigation, notifications)}
                    drawerStyle={navigationMenuTheme}>
                    <Switch>
                        { accessibleRoutes }
                    </Switch>
                </NavigationDrawer>
                <ToastNotification/>
                <InformationDialog title={`${getLinksLabel('contactUsHeader')}:`} text={<a href="mailto:support@coparenter.org?subject=Support:%20Professional%20Tool">support@coparenter.org</a>} onHide={() => setNoticeDialogVisible(false)} visible={noticeDialogVisible} dismissable={true}/>
            </>
        );
    }

    return isAuthenticated
        ? routesSignedIn()
        : isLoading
            ? <Loading/>
            : <Login/>;
}

export default LayoutApp;