import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

function usePageFramework() {
    const history = useHistory();
    const dispatch = useDispatch();
    const selector = useSelector;
    const location = useLocation();

    return {
        history,
        dispatch,
        selector,
        location,
    };

}

export default usePageFramework;