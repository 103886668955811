function validate(values) {
    const errors = {};

    if (!values.scheduleType.trim()) {
        errors.scheduleType = 'Need to select a schedule type';
    }

    return errors;
}

export {
    validate,
};