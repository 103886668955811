import React from 'react';

const DisplayDetailsList = ({ displayDetails = [] }) => {
    const style={ marginTop: '10px' };

    const details = displayDetails.sort((a, b) => a.colum < b.column);

    return details.map((detail) => {
        return (
            <div id={`details-list-section-${detail.title}`} key={`details-list-section-${detail.title}`}>
                <h4 style={style}>{ detail.title }</h4>
                <p>{ detail.value }</p>
            </div>
        );
    });
};

export default DisplayDetailsList;