import React from 'react';
import BandwidthWarning from '../BandwidthWarning';
import NetworkQualityLevel from '../NewtorkQualityLevel';
import ParticipantConnectionIndicator from '../ParticipantConnectionIndicator';
// import PinIcon from './PinIcon';

import usePublications from '../../hooks/usePublications';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff';
import useTrack from '../../hooks/useTrack';
import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel';
import usePublicationIsTrackEnabled from '../../hooks/usePublicationIsTrackEnabled';

export default function ParticipantInfo({ participant, onClick, isSelected, children }) {
    const publications = usePublications(participant);
    const audioPublication = publications.find(p => p.kind === 'audio');
    const videoPublication = publications.find(p => p.trackName === 'camera');
    const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
    const isAudioEnabled = usePublicationIsTrackEnabled(audioPublication);
    const isVideoEnabled = Boolean(videoPublication);
    const videoTrack = useTrack(videoPublication);
    const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);

    const infoRow = {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        marginLeft: '4px',
        zIndex: '1',
        overflowY: 'hidden'
    };

    const identity = {
        background: 'rgba(0, 0, 0, 0.7)',
        padding: '0.1em 0.3em',
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        fontSize: '10pt'
    };

    return (
        <div onClick={onClick} data-cy-participant={participant.identity}>
            <div>
                <div style={infoRow}>
                    <h4 style={identity}>
                        <ParticipantConnectionIndicator participant={participant} />
                        {participant.identity}
                    </h4>
                    <NetworkQualityLevel qualityLevel={networkQualityLevel} />
                </div>
                <div>
                    {/* {!isAudioEnabled && <MicOff data-cy-audio-mute-icon />} */}
                    {/* {!isVideoEnabled && <VideocamOff />} */}
                    {/* {isSelected && <PinIcon />} */}
                </div>
            </div>
            {isVideoSwitchedOff && <BandwidthWarning />}
            {children}
        </div>
    );
}
