import React from 'react';
import { List } from 'react-md';
import NotificationCard from './NotificationCard';

function NotificationList(props) {
    const { notifications } = props;

    const notificationsList = notifications || []

    const buildNotificationItem = (item) => {
        return <NotificationCard {...item}/>
    }

    return (
        <List>
            { notificationsList.map(buildNotificationItem) }
        </List>
    );
}

export default NotificationList;