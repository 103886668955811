import React from 'react'
import { Card, CardActions, Button, Divider, CardTitle } from 'react-md';

const OrganizationTypeCard = (data) => {
    const { orgTypeName, onViewClick, id } = data;

    return (
        <Card>
            <CardTitle
                style={{ display: 'block' }}
                title={<div className='single-line-overflow'>{ orgTypeName }</div>}
            />
            <Divider/>
            <CardActions>
                <Button flat primary onClick={() => onViewClick(id)}> View Details </Button>
            </CardActions>
        </Card>
    )
}

export default OrganizationTypeCard
