import React from 'react'
import { Card, CardTitle, CardText, CardActions, Divider, Button, Cell, Grid } from 'react-md';
import moment from 'moment';
import { MediaPreviewArrayFromUploadId } from '../../components/MediaPreviewCard';
import LabelList from '../../components/LabelList';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';

const DetailedRepositoryCard = (props) => {
    const { canUpdate, onUpdate, canDelete, onDelete, entry, onView, unfocus, onCaseNumberClicked = () => {} } = props;

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    if (!entry) {
        return (<></>);
    }

    const titleStyle = { position: 'relative', padding: '16px 16px 8px' };
    const contentStyle = { paddingTop: '8px', paddingBottom: '8px' };
    const infoHeaderStyle = { margin: '0px' };
    const unfocusButtonStyle = { position: 'absolute', right: '16px' };

    const buildPreviewButtons = (uploadId, index) => {
        const buttons = [
            <ThemedButton key={`preview-button-${uploadId}`} className="md-cell--right" icon onClick={() => onView(uploadId, index)}>preview</ThemedButton>
        ];

        return buttons;
    }

    const buildPreviewWrapper = (preview, key) => {
        return (
            <Cell key={key} desktopSize={4} tabletSize={4} phoneSize={2}>
                { preview }
            </Cell>
        )
    }

    const buildCardActions = () => {
        if (!canUpdate && !canDelete) {
            return (<></>);
        }

        return (
            <>
                <Divider/>
                <CardActions>
                    { canUpdate && <ThemedButton flat primary onClick={onUpdate}>Update</ThemedButton> }
                    { canDelete && <ThemedButton flat primary onClick={() => onDelete(entry.id)}>Delete</ThemedButton> }
                </CardActions>
            </>
        );
    }

    const buildInfoHeader = (headerText) => {
        return (
            <h4 style={infoHeaderStyle}>
                <b>{ `${headerText}:` }</b>
            </h4>
        );
    }

    const buildUnfocusButton = () => {
        if (!unfocus) {
            return null;
        }

        return (<ThemedButton icon style={unfocusButtonStyle} onClick={unfocus}>close</ThemedButton>);
    }

    return (
        <Card>
            <CardTitle
                style={titleStyle}
                title={entry.title}
                subtitle={`Created by ${entry.createdByName || ''}`}
                children={buildUnfocusButton()}
            />
            <CardText style={contentStyle}>
                <Grid>
                    <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('caseNumberData')) }
                        <a href={'#'} onClick={() => onCaseNumberClicked(entry.caseNumber)}>{ `${entry.caseNumber}` }</a>
                    </Cell>
                    <Cell desktopSize={6} tabletSize={6} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('createdData')) }
                        <p>{ moment(entry.createdOn).format("MMM Do YYYY h:mm:ss a") }</p>
                    </Cell>
                    <Cell desktopSize={6} tabletSize={6} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('updatedData')) }
                        <p>{ moment(entry.updatedOn).format("MMM Do YYYY h:mm:ss a") }</p>
                    </Cell>
                    <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('notesData')) }
                        <p>{ entry.notes }</p>
                    </Cell>
                    <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('categoriesData')) }
                        <LabelList list={entry.allCategories}/>
                    </Cell>
                    <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                        { buildInfoHeader(getDataLabel('attachmentsData')) }
                        <Grid>
                            { <MediaPreviewArrayFromUploadId mediaArray={entry.uploads} actionBuilder={buildPreviewButtons} wrapper={buildPreviewWrapper} /> }
                        </Grid>
                    </Cell>
                </Grid>
            </CardText>
            { buildCardActions() }
        </Card>
    );
}

export default DetailedRepositoryCard;