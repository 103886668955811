import React from 'react';
import { Grid, Cell, Button } from 'react-md';
import { parseRoleControls, selectableOrganizationRoles, selectableClientViewRoles, parseRoleControlsStringToInt, selectableManagementRoles } from '../../utils/permissions';
import useFormValidation from '../../hooks/useFormValidation';
import TextFormField from '../../components/FormFields/TextFormField';
import CheckboxFormField from '../../components/FormFields/CheckboxFormField';
import { validate, asyncValidate } from './AddOrgTypeValidate';
import { ORG_ROLE_VIEW_CLIENT, ORG_ROLE_MANAGE_ORG } from '../../constants/roles';
import { selectCurrentOrganization, selectAllOrganizationRoles, selectAllClientViewRoles, selectAllManagementRoles } from '../../selectors/global';
import usePageFramework from '../../hooks/usePageFramework';

function AddOrgTypeForm(props) {
    const { onSubmit, onHide } = props;

    const {
        selector,
    } = usePageFramework();

    const currentOrganization = selector(selectCurrentOrganization());
    const organizationRoles = selector(selectAllOrganizationRoles());
    const clientViewRoles = selector(selectAllClientViewRoles());
    const managementRoles = selector(selectAllManagementRoles());

    const INITIAL_STATE = {
        orgTypeName: '',
        orgTypeDescription: '',
        orgRoles: '',
        clientViewRoles: '',
        managementRoles: '',
    };

    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, submitNewOrgType, validate, asyncValidate);

    const organizationRoleControls = parseRoleControls(selectableOrganizationRoles(currentOrganization, organizationRoles, false).filter(x => !x.isExclusive));
    const clientViewRoleControls = parseRoleControls(selectableClientViewRoles(currentOrganization, clientViewRoles).filter(x => !x.isExclusive));
    const managementRoleControls = parseRoleControls(selectableManagementRoles(currentOrganization, managementRoles).filter(x => !x.isExclusive));
    const showingClientControls = parseRoleControlsStringToInt(values.orgRoles) & ORG_ROLE_VIEW_CLIENT ? true : false;
    const showingManagementControls = parseRoleControlsStringToInt(values.orgRoles) & ORG_ROLE_MANAGE_ORG ? true : false;

    function handleOrgRoleChange(name, value) {
        // Clear selected client view roles if client viewing is toggled off
        const changes = {
            clientViewRoles: '',
            managementRoles: '',
            [name]: value,
        };

        const orgRoleValue = parseRoleControlsStringToInt(value);
        
        if (orgRoleValue & ORG_ROLE_VIEW_CLIENT) {
            delete changes.clientViewRoles;
        }

        if (orgRoleValue & ORG_ROLE_MANAGE_ORG) {
            delete changes.managementRoles;
        }

        changeValues(changes);
    }

    function submitNewOrgType(values) {
        const organizationType = parseSubmissionToOrgType(values);
        onSubmit(organizationType);
    }

    function parseSubmissionToOrgType(values) {
        const orgTypeObject = {
            orgTypeName: values.orgTypeName.trim(),
            orgTypeDescription: values.orgTypeDescription.trim(),
            orgRoles: parseRoleControlsStringToInt(values.orgRoles || ''),
            clientViewRoles: showingClientControls ? parseRoleControlsStringToInt(values.clientViewRoles || '') : 0,
            managementRoles: showingManagementControls ? parseRoleControlsStringToInt(values.managementRoles || '') : 0,
        };

        return orgTypeObject;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="orgTypeName"
                        label="Organization Type Name"
                        values={values}
                        errors={errors}
                        customSize="title"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="orgTypeDescription"
                        label="Description"
                        values={values}
                        errors={errors}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    <CheckboxFormField
                        name="orgRoles"
                        label="Available Roles for Organization"
                        values={values}
                        errors={errors}
                        controls={organizationRoleControls}
                        onChange={handleOrgRoleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    {
                        showingClientControls &&
                            <CheckboxFormField
                                name="clientViewRoles"
                                label="Client Viewing Role(s) (Must have View Client Role selected)"
                                values={values}
                                errors={errors}
                                controls={clientViewRoleControls}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                    {
                        showingManagementControls &&
                            <CheckboxFormField
                                name="managementRoles"
                                label="Management Role(s) (Must have Manage Organization Role selected)"
                                values={values}
                                errors={errors}
                                controls={managementRoleControls}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <Button className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>Create Organization Type</Button>
                </Cell>
            </Grid>
        </form>
    );
}

export default AddOrgTypeForm;