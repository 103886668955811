import React from 'react';
import { CardText, Card, Divider, CardActions, Button } from 'react-md';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';

function GroupCard(props) {
    const { group, onUpdate, canDelete, onDelete } = props;

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    return (
        <Card style={{ height: '100%' }}>
            <CardText>
                <p><b>{ `${getDataLabel('groupNameData')}:` }</b>{ ` ${group.name}` }</p>
                <p><b>{ `${getDataLabel('descriptionData')}:` }</b>{ ` ${group.description}` }</p>
            </CardText>
            <Divider/>
            <CardActions>
                <Button flat primary onClick={() => onUpdate(group.uid || group.id)}>Update</Button>
                <Button flat primary disabled={!canDelete} onClick={() => onDelete(group.uid || group.id)}>Delete</Button>
            </CardActions>
        </Card>
    );
}

export default GroupCard;