import React, { useEffect, useState } from "react";
import * as firebase from 'firebase';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { selectOrganizationId, selectClientProfile, selectDataItemsFromComponent, selectCurrentUser } from "../../selectors/global";
import { setToolbarButtons, setDataItemsToComponent } from "../../actions/global";
import useLoadingPageFramework from "../../hooks/useLoadingPageFramework";
import { FILTER_TEXT, ALL, FILTER_NONE, FILTER_DATE } from "../../constants";
import { NAV_DEFAULT } from "../../constants/navigation";
import ProTable from "../../components/ProTable";
import GettingStartedPaper from "../../components/GettingStartedPaper";
import DetailsDialog from "../../components/DetailsDialog";
import RequestDetailContent from "../../components/DetailsDialog/RequestDetailContent";
import { callCloudRunFunction } from "../../utils/firestore";
import { useCustomDataLabels, useCustomPageFrameworkLabels } from "../../hooks/useCustomLabels";
import { getClientLabelVariableData } from "../../utils/customBranding";
import useCustomAssets from "../../hooks/useCustomAssets";
import TableIconButton from "../../components/Buttons/TableIconButton";

const COMPONENT_NAME = 'ClientExpenses';

function ClientExpenses() {
    const history = useHistory();
    const dispatch = useDispatch();
    const selector = useSelector;

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const [ focusedExpenseId, setFocusedExpenseId ] = useState(null);

    const clientProfile = selector(selectClientProfile());
    const dataItems = selector(selectDataItemsFromComponent(COMPONENT_NAME));

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('ClientDocuments', getClientLabelVariableData(clientProfile));

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (organizationId === false || !clientProfile) {
            return;
        }

        // const toolBarTitle = `Client Expenses - ${clientProfile.name}`;
        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() } ];

        // dispatch(setToolbarTitle(toolBarTitle));
        dispatch(setToolbarButtons(toolBarButtons));

        var dataStreamExpenses = null;
        const db = firebase.firestore();

        // Only grab expense type requests. Other Requests can be found on the Client Requests Page
        dataStreamExpenses = db.collection('Requests').where('participants', 'array-contains', clientProfile.id).where('category', '==', 'expenses').onSnapshot((querySnapshot) => {
            const dataItems = querySnapshot.docs.map((doc) => {
                const expenseItem = doc.data();
                expenseItem.createdOn = expenseItem.createdOn ? expenseItem.createdOn.toDate() : null;
                expenseItem.updatedOn = expenseItem.updatedOn ? expenseItem.updatedOn.toDate() : null;
                expenseItem.expenseCategory = 'Expense';
                expenseItem.id = doc.id;

                return expenseItem;
            });

            dispatch(setDataItemsToComponent(dataItems, COMPONENT_NAME));
            finishedLoading();
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Expenses',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        return function cleanup() {
            // dispatch(setToolbarTitle(''));
            dispatch(setToolbarButtons([]));

            if (dataStreamExpenses) {
                dataStreamExpenses();
            }
        }
    }, []);

    const foundExpense = dataItems && dataItems.find(x => x.id === focusedExpenseId) || {};

    if (organizationId === false || !clientProfile) {
        history.push(NAV_DEFAULT);
        return (<></>)
    }

    const viewExpenseDetails = (id) => {
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Expenses',
            action: 'view expense details',
            expenseId: id,
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        setFocusedExpenseId(id);
    }

    const createEmptyCard = (status) => {
        const cardsInfo = [
            { status: ALL, cardMediaUrl: getAsset('clientExpensesEmpty'), headerTitle: getLabel('noResultsHeader'), subTitle: getLabel('noResultsSubheader'), buttonText: 'Back to Client Profile', buttonClick: () => history.goBack() },
        ];

        const cardProps = cardsInfo.find(x => x.status === status);
        return (<GettingStartedPaper {...cardProps} key={cardProps.status}/>);
    }

    function buildPageContent() {
        const columns = [
            { label: getDataLabel('categoryData'), value: 'expenseCategory', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('detailsData'), value: 'info', staticText: true, type: FILTER_NONE, fn: (item, id, value) => (<TableIconButton id={`details-${id}`} label={value} onClick={viewExpenseDetails}/>) },
            { label: getDataLabel('statusData'), value: 'status', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('createdByData'), value: 'createdBy', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('createdData'), value: 'createdOn', type: FILTER_DATE, fn: (item) => moment(item).calendar() },
            { label: getDataLabel('updatedData'), value: 'updatedOn', isPrimaryColumn: true, type: FILTER_DATE, fn: (item) => moment(item).calendar() },
        ];

        return (
            <section className='md-grid'>
                <ProTable status={ALL} items={dataItems} columns={columns} key={ALL} componentName={COMPONENT_NAME} emptyCard={() => createEmptyCard(ALL)}/>
            </section>
        );
    }

    return (
        <>
            { renderPageContent() }
            <DetailsDialog content={RequestDetailContent} onHide={() => setFocusedExpenseId(null)} visible={focusedExpenseId !== null} data={foundExpense}/>
        </>
    );
}

export default ClientExpenses;