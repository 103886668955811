import React from 'react';
import { Card, CardTitle, CardText } from 'react-md';
import { SCHEDULE_CATEGORY_PARENTING_PLAN, SCHEDULE_CATEGORY_EVENT, SCHEDULE_CATEGORY_ACTIVITY,
    SCHEDULE_CATEGORY_EXPENSE, SCHEDULE_CATEGORY_SWAP, SCHEDULE_CATEGORY_HOLIDAY, SCHEDULE_CATEGORY_BIRTHDAY, SCHEDULE_CATEGORY_EXCHANGE_DAY, SCHEDULE_CATEGORY_SAME_DAY_VISIT, SCHEDULE_CATEGORY_APPOINTMENT, SCHEDULE_CATEGORY_EXCHANGE_PICKUP, SCHEDULE_CATEGORY_EXCHANGE_DROPOFF } from '../../constants/schedule';
import { buildScheduleIcon } from '../ScheduleIcons';
import DefaultScheduleCard from './DefaultScheduleCard';
import ParentingScheduleCard from './ParentingScheduleCard';
import BirthdayScheduleCard from './BirthdayScheduleCard';
import EventScheduleCard from './EventScheduleCard';
import ActivityScheduleCard from './ActivityScheduleCard';
import ExpenseScheduleCard from './ExpenseScheduleCard';
import SwapScheduleCard from './SwapScheduleCard';

const getPrimaryCPName = (item) => {
    const cp = item.members ? item.members.find(x => x.isPrimary) : null;
    return cp ? `${cp.firstName} ${cp.lastName}` : 'coParent 1';
}

const getSecondaryCPName = (item) => {
    const coParentMembers = item.members.filter(x => x.isPrimary);
    if (coParentMembers.length > 1) {
        const cp = coParentMembers[1];
        return `${cp.firstName} ${cp.lastName}`;
    }

    return 'coParent 2';
}

const getCreatedByCPName = (item) => {
    const cp = item.members ? item.members.find(x => x.uid === item.createdByUid) : null;
    return cp ? `${cp.firstName} ${cp.lastName}` : null;
}

const ScheduleCard = ({id, item}) => {
    const cards = [
        { category: SCHEDULE_CATEGORY_PARENTING_PLAN, cardContent: ParentingScheduleCard, cardTitle: 'Parenting Plan Schedule'},
        { category: SCHEDULE_CATEGORY_EXCHANGE_DAY, cardContent: ParentingScheduleCard, cardTitle: 'Parenting Plan Schedule'},
        { category: SCHEDULE_CATEGORY_SAME_DAY_VISIT, cardContent: ParentingScheduleCard, cardTitle: 'Parenting Plan Schedule'},
        { category: SCHEDULE_CATEGORY_APPOINTMENT, cardContent: EventScheduleCard, cardTitle: 'Appointment'},
        { category: SCHEDULE_CATEGORY_EVENT, cardContent: EventScheduleCard, cardTitle: 'Appointment'},
        { category: SCHEDULE_CATEGORY_EXPENSE, cardContent: ExpenseScheduleCard, cardTitle: 'Expense Due Date'},
        { category: SCHEDULE_CATEGORY_ACTIVITY, cardContent: ActivityScheduleCard, cardTitle: 'Activity'},
        { category: SCHEDULE_CATEGORY_HOLIDAY, cardContent: DefaultScheduleCard, cardTitle: 'Holiday'},
        { category: SCHEDULE_CATEGORY_SWAP, cardContent: SwapScheduleCard, cardTitle: 'Swap Day'},
        { category: SCHEDULE_CATEGORY_BIRTHDAY, cardContent: BirthdayScheduleCard, cardTitle: 'Birthday'},
        { category: SCHEDULE_CATEGORY_EXCHANGE_PICKUP, cardContent: DefaultScheduleCard, cardTitle: 'Pickup'},
        { category: SCHEDULE_CATEGORY_EXCHANGE_DROPOFF, cardContent: DefaultScheduleCard, cardTitle: 'Drop off'},
    ];
    const defaultCard = { cardContent: DefaultScheduleCard, cardTitle: '' };

    const card = cards.find(x => item && x.category === item.category) || defaultCard;
    const { cardContent: ContentCard, cardTitle } = card;
    
    return (
        <Card id={`${id}-card`} className="schedule-card-space">
            <CardTitle
                id={`${id}-card-title`}
                title={cardTitle}
                avatar={buildScheduleIcon(item || {})}
            />
            <CardText id={`${id}-card-content`}>
                <ContentCard item={item || {}}/>
            </CardText>
        </Card>
    );
}

export {
    ScheduleCard,
    getPrimaryCPName,
    getSecondaryCPName,
    getCreatedByCPName,
};