import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Cell, Button } from 'react-md';
import TextFormField from '../../components/FormFields/TextFormField';
import useFormValidation from '../../hooks/useFormValidation';
import { validate, asyncValidate } from './UpdateEntryCategoryValidate';
import { selectOrganizationId } from '../../selectors/global';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';

function UpdateEntryCategoryForm(props) {
    const { onSubmit, onHide, initialValues } = props;

    const organizationId = useSelector(selectOrganizationId());

    const INITIAL_STATE = {
        uid: initialValues.uid || initialValues.id,
        category: initialValues.category || '',
        description: initialValues.description || '',
        organizationId: organizationId,
    };

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, updateEntryCategory, validate, asyncValidate);

    function updateEntryCategory(values) {
        const category = parseSubmissionToCategory(values);
        onSubmit(category);
    }

    function parseSubmissionToCategory(values) {
        const category = {
            id: values.uid,
            category: values.category.trim(),
            description: values.description.trim(),
        };

        return category;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="category"
                        label={getDataLabel('categoryData')}
                        values={values}
                        errors={errors}
                        customSize="title"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="description"
                        label={getDataLabel('descriptionData')}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>Update Category</ThemedButton>
                </Cell>
            </Grid>
        </form>
    );
}

export default UpdateEntryCategoryForm;