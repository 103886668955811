/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Cell, Grid } from 'react-md';
import { Field, reduxForm, reset } from 'redux-form/immutable';
import { required, maxLength2000 } from '../../components/RequestFields/renderToolboxField';
import renderTextField from '../../components/RequestFields/renderTextField';

class ChangeSystemMessage extends React.PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        initialValues:  PropTypes.object.isRequired
    };

    render() {
        const { handleSubmit, onClear, pristine } = this.props;
        const fieldStyle = { width: "100%" };
        const formSyle = { marginLeft: '25px', marginRight: '25px' };
        
        return (
            <form onSubmit={handleSubmit} style={formSyle}>
                <Grid className="md-grid">
                    <Cell size={12}>
                        <Field
                            id={`org-name-1`}
                            name="title"
                            label="Title"
                            customSize="title"
                            component={renderTextField}
                            type="text"
                            validate={[required]}>
                        </Field>
                    </Cell>
                    <Cell size={12}>
                        <Field
                            id="messageBody"
                            name="message"
                            label="System Message"
                            component={renderTextField}
                            type="text"
                            rows={5}
                            paddedBlock
                            maxLength={2000}
                            validate={[required, maxLength2000]}>
                        </Field>
                    </Cell>
                    <Cell size={4}>
                        <Button disabled={pristine} style={fieldStyle} type="submit" flat primary swapTheming>Send Message</Button>
                    </Cell>
                    <Cell size={4}>
                        <Button style={fieldStyle} onClick={onClear} flat secondary swapTheming>Clear</Button>
                    </Cell>
                </Grid>
            </form>
        );
    }
}

const afterSubmit = (result, dispatch) => {
    dispatch(reset('addCoparentDialog'));
}

export default reduxForm({
    form: 'changeSystemMessage',
    enableReinitialize: true,
    onSubmitSuccess: afterSubmit
})(ChangeSystemMessage);