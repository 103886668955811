import React from 'react';
import ProCalendarDisplay from '../ProCalendar/ProCalendarDisplay';
import ProScheduleDisplay from '../ProScheduleDisplay';

function ScheduleListDashboard (props) {
    const { client } = props;

    return (
        <ProScheduleDisplay clientId={client.id}/>
    );
}

export default ScheduleListDashboard;