import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FontIcon } from 'react-md';

function TenantPageItem(props) {
    const { item, index } = props;

    return (
        <Draggable draggableId={item.id} index={index}>
            {
                (provided, snapshot) => {
                    const itemContainerStyle = {
                        backgroundColor: snapshot.isDragging ? 'lightgreen' : '#FFFFFF',
                        ...provided.draggableProps.style,
                    };

                    const iconStyle = {
                        marginRight: '8px',
                    };

                    return (
                        <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            class={'drag-page-item'}
                            style={itemContainerStyle}
                        >
                            <FontIcon style={iconStyle}>drag_indicator</FontIcon>
                            { `${item.label} (${item.id})` }
                        </div>
                    )
                }
            }
        </Draggable>
    )
}

export default TenantPageItem;