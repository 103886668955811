import React, { PureComponent } from 'react';
import { Button, DialogContainer, Toolbar } from 'react-md';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import HyperlinkForm from './FormHyperlink';

export default class HyperlinkDialog extends PureComponent {

    parseLink = (values) => {
        const hyperlinkValues = {};
        const findKeys = ['url'];
        findKeys.forEach(x => hyperlinkValues[x] = '');

        values.map((value, key) => {
            const name = findKeys.find(x => key === x);
            return hyperlinkValues[name] = value.trim();
        });

        return hyperlinkValues;
    }

    handleSubmit = (e) => {
        const hyperlink = this.parseLink(e);
        this.props.addHyperlink(hyperlink);
    }

    render() {
        const { handleSubmit, visible, onHide } = this.props;
        const groups = (<HyperlinkForm onSubmit={this.handleSubmit}/>);

        const actions = [{
            id: 'dialog-cancel',
            secondary: true,
            children: 'Cancel',
            onClick: onHide,
        }, {
            id: 'dialog-ok',
            type: 'submit',
            primary: true,
            children: 'Save',
            onClick: handleSubmit,
        }];

        return (
            <DialogContainer
                id="add-hyperlink-dialog"
                aria-labelledby="add-hyperlink-dialog-title"
                visible={visible}
                onHIde={onHide}
                fullPage
                actions={actions}
            >
                <CSSTransitionGroup
                    id="add-hyperlink-form"
                    className="md-text-container md-toolbar--relative"
                    transitionName="md-cross-fade"
                    transitionEnterTimeout={300}
                    transitionLeave={false}
                >
                    <Toolbar
                        nav={<Button icon onClick={onHide}>close</Button>}
                        title="Add Link" titleId="add-hyperlink-dialog-title" fixed colored
                    />
                    {groups}
                </CSSTransitionGroup>
            </DialogContainer>
        );
    }
}