function validate(values) {
    const errors = {};

    if (!values.caseNumber.trim()) {
        errors.caseNumber = "An exact case number is required";
    }

    return errors;
};

export {
    validate,
};