import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as firebase from 'firebase';
import { BrowserRouter as Router } from "react-router-dom";
import configureStore from './store';
import AppStateProvider from './state';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './App.css';
import registerServiceWorker from './registerServiceWorker';
import { setisAuthenticated, setCurrentUser, setIsApproved,
    setIsAuthInfo, setCurrentUserRoles, setDataItemsToComponent,
    setAllOrganizationRoles, setAllClientViewRoles, setNewUserData,
    setCurrentUserGroups, setDefaultTheme, setDefaultLabels,
    setDefaultAssets, setAllManagementRoles } from './actions/global';
import { setOrganizationId } from './actions/thunks';
import { orgIsCoParenter } from './utils/permissions';
import { requestNotificationPermissions, hasNotificationsEnabled, sendBrowserNotification } from './utils/notifications';
import { callCloudRunFunction } from './utils/firestore';

const initialState = {};
const storeInstance = configureStore(initialState);

const fireBaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    storageBucket: process.env.REACT_APP_CLIENT_FIREBASE_STORAGE_BUCKET,
};

window.addEventListener('load', requestNotificationPermissions);

firebase.initializeApp(fireBaseConfig);

firebase.auth().onAuthStateChanged((user) => {
    const db = firebase.firestore();

    const notAUTHENTICATED = () => {
        storeInstance.dispatch(setisAuthenticated(false));
        storeInstance.dispatch(setCurrentUser(null));
        storeInstance.dispatch(setIsApproved(false));
        storeInstance.dispatch(setCurrentUserRoles({}));
        storeInstance.dispatch(setOrganizationId(false));
    };

    const checkToSeeIfClientInvite = (user) => {
        const { uid, email, displayName } = user;

        if (!uid || !email) {
            console.log('no uid or email passed in');
            return notAUTHENTICATED();
        }

        const fetchOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ uid, email, displayName })
        };

        fetch('/cloudrun/onboardProfessional', fetchOptions).then((response) => response.json()).then((body) => {
            if (body && body.uid && body.userStatus && body.userStatus.toLowerCase() === 'approved') {
                const { uid, roles, organizationId } = body;

                const proUser = {
                    displayName: displayName || '',
                    email: email,
                    photoUrl: user.photoURL || '',
                    organizationId: organizationId,
                    uid: uid,
                };

                setupAfterAUTHENTICATED(proUser, roles, organizationId);
            }
            else {
                storeInstance.dispatch(setIsApproved(false));
                storeInstance.dispatch(setisAuthenticated(false));
            }
        }).catch((err) => {
            console.log(err);
            notAUTHENTICATED();
        });
    };

    const setupAfterAUTHENTICATED = (proUser, roles, organizationId) => {
        const log = {
            proId: proUser.uid,
            organizationId: organizationId,
            page: 'System',
            action: 'sign in',
        };

        callCloudRunFunction(proUser, 'logProUserAction', log);
        storeInstance.dispatch(setCurrentUser(proUser));
        const isCoParenterOrg = orgIsCoParenter(organizationId);
        storeInstance.dispatch(setIsApproved(true));
        storeInstance.dispatch(setCurrentUserRoles(roles));
        storeInstance.dispatch(setOrganizationId(organizationId));

        // Load all roles
        callCloudRunFunction(proUser, 'getAllOrganizationRoles', {}).then((orgRoles) => {
            storeInstance.dispatch(setAllOrganizationRoles(orgRoles));
        }).catch((err) => {
            console.log(err);
        });

        callCloudRunFunction(proUser, 'getAllClientViewRoles', {}).then((clientViewRoles) => {
            storeInstance.dispatch(setAllClientViewRoles(clientViewRoles));
        }).catch((err) => {
            console.log(err);
        });

        callCloudRunFunction(proUser, 'getAllManagementRoles', {}).then((managementRoles) => {
            storeInstance.dispatch(setAllManagementRoles(managementRoles));
        }).catch((err) => {
            console.log(err);
        });

        var orgCollectionRef = db.collection('ProOrganizations');
        // Filter for single organization if not main organization
        if (!isCoParenterOrg) {
            orgCollectionRef = orgCollectionRef.where('id', '==', organizationId);
        }

        // Watch Organizations Documents for changes
        orgCollectionRef.onSnapshot((querySnapshot) => {
            const organizations = querySnapshot.docs.map((doc) => {
                const organization = doc.data();
                organization.org = organization.organization;
                organization.createdOn = organization.createdOn ? organization.createdOn.toDate() : null;
                return organization;
            });

            storeInstance.dispatch(setDataItemsToComponent(organizations, 'Organizations'));
            storeInstance.dispatch(setisAuthenticated(true));
        });
        
        // Watch Organization Type Documents for changes if main organization
        if (isCoParenterOrg) {
            db.collection('ProOrganizationTypes').onSnapshot((snapshot) => {
                const orgTypes = snapshot.docs.map((doc) => {
                    const orgType = doc.data();
                    orgType.id = doc.ref.id;
                    orgType.createdOn = orgType.createdOn ? orgType.createdOn.toDate() : null;
                    orgType.updatedOn = orgType.updatedOn ? orgType.updatedOn.toDate() : null;
                    return orgType;
                });

                storeInstance.dispatch(setDataItemsToComponent(orgTypes, 'OrganizationTypes'));
            });
        }

        // Watch Notifications collection for changes
        db.collection('UserToDo').where('uid', '==', proUser.uid).orderBy('priority').onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((docChange) => {
                if (docChange.type === 'added') {
                    const notification = docChange.doc.data();
                    notification.createdOn = notification.createdOn;
                    if (hasNotificationsEnabled()) {
                        sendBrowserNotification(notification);
                    }
                }
            });

            const notifications = snapshot.docs.map((docRef) => {
                const notification = docRef.data();
                notification.createdOn = notification.createdOn;
                notification.id = docRef.id;

                return notification;
            }).filter(x => x.toDoId);

            // sort by most recent to oldest
            storeInstance.dispatch(setDataItemsToComponent(notifications, 'Notifications'));
        });

        // Watch Professional Document for changes
        db.collection('ProApprovedProfessionals').doc(proUser.uid).onSnapshot((snapshot) => {
            if (snapshot.exists) {
                const userData = snapshot.data();

                const { roles, isActive, photoUrl, sms, displayName, orgGroups } = userData;

                const newUserData = { isActive: isActive, sms: sms || '', displayName: displayName || '' };

                if (photoUrl) {
                    newUserData.photoUrl = photoUrl;
                }


                storeInstance.dispatch(setCurrentUserRoles(roles || {}));
                storeInstance.dispatch(setCurrentUserGroups(orgGroups || []));
                storeInstance.dispatch(setNewUserData(newUserData));
            } else {
                // Professional was deleted?
                firebase.auth().signOut();
            }
        });

        // Watch Tenant Themes
        db.collection('ProTenantThemes').doc('Default').onSnapshot((snapshot) => {
            const defaultTheme = snapshot.data();
            storeInstance.dispatch(setDefaultTheme(defaultTheme));
        });

        db.collection('ProTenantThemes').doc('Default').collection('Labels').onSnapshot((snapshot) => {
            const defaultLabels = snapshot.docs.map(x => x.data());
            storeInstance.dispatch(setDefaultLabels(defaultLabels));
        });

        db.collection('ProTenantThemes').doc('Default').collection('Assets').onSnapshot((snapshot) => {
            const defaultAssets = snapshot.docs.map(x => x.data());
            storeInstance.dispatch(setDefaultAssets(defaultAssets));
        });
    };
    
    if (user && user.uid) {
        callCloudRunFunction(null, 'getProApprovedProfessionalByUuid', { uid: user.uid }).then((professionalData) => {
            if (professionalData) {
                const { organizationId, uid, isActive, roles } = professionalData;

                const proUser = {
                    displayName: user.displayName || '',
                    email: user.email,
                    photoUrl: user.photoURL || '',
                    organizationId: organizationId,
                    uid: uid,
                    isActive: isActive || false,
                };

                return setupAfterAUTHENTICATED(proUser, roles, organizationId);
            }

            throw 'Approved Professional does not exist';
        }).catch((err) => {
            console.log(err);
            checkToSeeIfClientInvite(user);
        });
    } else {
        if (!firebase.auth().isSignInWithEmailLink(window.location.href)) {
            return notAUTHENTICATED();
        }

        const email = window.localStorage.getItem('emailForSignIn');

        if (!email) {
            return notAUTHENTICATED();
        }

        firebase.auth().signInWithEmailLink(email, window.location.href).then((result) => {
            window.localStorage.removeItem('emailForSignIn');

            if (!result.additionalUserInfo.isNewUser) {
                return notAUTHENTICATED();
            }

            storeInstance.dispatch(setIsAuthInfo(true));
            storeInstance.dispatch(setIsApproved(false));

            return checkToSeeIfClientInvite(result.user);
        }).catch((err) => {
            console.log(err);
        });
    }
});

ReactDOM.render(<Provider store={storeInstance}><Router><AppStateProvider><App /></AppStateProvider></Router></Provider>, document.getElementById('root'));
registerServiceWorker();
