import React from 'react';
import { Paper } from 'react-md';

const buildDayBubble = (bubbleClassColor, children, onClick) => {
    const bubbleClass = `schedule-bubble-large ${bubbleClassColor}`;
    return (
        <ScheduleBubble className={bubbleClass} children={children} onClick={onClick}/>
    );
}

const buildVisitBubble = (bubbleClassColor, children, onClick) => {
    const outerBubbleClass = `schedule-bubble-large ${bubbleClassColor}`;
    const innerBubbleClass = `schedule-bubble-inner schedule-bubble-small ${bubbleClassColor}`;

    return (
        <ScheduleBubble className={outerBubbleClass} onClick={onClick} children={
            <ScheduleBubble className={innerBubbleClass} children={children}/>
        }/>
    );
}

const ScheduleBubble = ({className, onClick=()=>{}, children}) => {
    const bubbleClass = `${className || ''} round`;

    return (
        <Paper onClick={onClick} className={bubbleClass} zDepth={2} children={children}/>
    );
}

export {
    ScheduleBubble,
    buildDayBubble,
    buildVisitBubble,
};