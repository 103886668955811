// Notification types (snackbar)
export const NOTIFICATION_INFO = 'info';
export const NOTIFICATION_SUCCESS = 'success';
export const NOTIFICATION_WARNING = 'warn';
export const NOTIFICATION_ERROR = 'error';

// Notification categories
export const NOTIFICATION_CATEGORY_MEDIATION = 'getHelp chat';
export const NOTIFICATION_CATEGORY_INTERNAL = 'OrganizationChat chat';
export const NOTIFICATION_CATEGORY_DIRECT = 'direct chat';

// Notification Permissions (using web api)
export const GRANTED = 'granted';
export const DENIED = 'denied';