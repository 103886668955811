import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { setToolbarButtons, setDataItemsToComponent } from '../../actions/global';
import { selectClientProfile, selectOrganizationId, selectDataItemsFromComponent, selectCurrentUser } from '../../selectors/global';
import * as firebase from 'firebase';
import ProTable from '../../components/ProTable';
import { ALL, FILTER_TEXT, FILTER_DATE, FILTER_NONE, REQUEST_GENERAL, REQUEST_SWAP, REQUEST_ACTIVITY, REQUEST_EXPENSES  } from '../../constants/index';
import { NAV_DEFAULT } from '../../constants/navigation';
import DetailsDialog from '../../components/DetailsDialog';
import RequestDetailContent from '../../components/DetailsDialog/RequestDetailContent';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import usePageFramework from '../../hooks/usePageFramework';
import { callCloudRunFunction } from '../../utils/firestore';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import { getClientLabelVariableData } from '../../utils/customBranding';
import useCustomAssets from '../../hooks/useCustomAssets';
import TableIconButton from '../../components/Buttons/TableIconButton';

const COMPONENT_NAME = 'ClientRequests';

function ClientRequests() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const clientProfile = selector(selectClientProfile());

    const dataItems = selector(selectDataItemsFromComponent(COMPONENT_NAME));
    const [ focusedRequestId, setFocusedRequestId ] = useState(null);

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('ClientRequests', getClientLabelVariableData(clientProfile));

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (organizationId === false || !clientProfile) {
            return;
        }

        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() } ];
        dispatch(setToolbarButtons(toolBarButtons));

        var dataStreamRequests = null;
        const db = firebase.firestore();

        // Load data stuff
        dataStreamRequests = db.collection('Requests').where('participants', 'array-contains', clientProfile.id).onSnapshot((snapshotQuery) => {
            const dataItems = snapshotQuery.docs.map((doc) => {
                const requestItem = doc.data();
                requestItem.createdOn = requestItem.createdOn ? requestItem.createdOn.toDate() : null;
                requestItem.updatedOn = requestItem.updatedOn ? requestItem.updatedOn.toDate() : null;
                requestItem.requestCategory = getActivityType(requestItem.category);
                requestItem.id = doc.id;

                return requestItem;
            }).filter(x => x.category !== REQUEST_EXPENSES);

            dispatch(setDataItemsToComponent(dataItems, COMPONENT_NAME));
            finishedLoading();
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Requests',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        return () => {
            dispatch(setToolbarButtons([]));

            if (dataStreamRequests) {
                dataStreamRequests();
            }
        }
    }, []);

    const foundRequest = dataItems && dataItems.find(x => x.id === focusedRequestId) || {};

    const getActivityType = (category) => {
        switch (category) {
            case REQUEST_GENERAL:
                return 'General';
            case REQUEST_ACTIVITY:
                return 'Activity';
            case REQUEST_SWAP:
                return 'Swap';
            case REQUEST_EXPENSES:
                return 'Expense';
            default:
                return null;
        }
    }

    if (organizationId === false || !clientProfile) {
        history.push(NAV_DEFAULT);
        return (<></>)
    }

    const viewRequestDetails = (id) => {
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            clientId: clientProfile.id,
            page: 'Client Requests',
            action: 'view request details',
            requestId: id,
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        setFocusedRequestId(id);
    }

    
    const createEmptyCard = (status) => {
        const cardsInfo = [
            { status: ALL, cardMediaUrl: getAsset('clientRequestsEmpty'), headerTitle: getLabel('emptyResultsHeader'), subTitle: getLabel('emptyResultsSubheader'), buttonText: 'Back to Client Profile', buttonClick: () => history.goBack() },
        ];

        const cardProps = cardsInfo.find(x => x.status === status);
        return (<GettingStartedPaper {...cardProps} key={cardProps.status}/>);
    }

    function buildPageContent() {
        const columns = [
            { label: getDataLabel('categoryData'), value: 'requestCategory', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('detailsData'), value: 'info', staticText: true, type: FILTER_NONE, fn: (item, id, value) => (<TableIconButton id={`details-${id}`} label={value} onClick={viewRequestDetails}/>) },
            { label: getDataLabel('statusData'), value: 'status', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('createdByData'), value: 'createdBy', type: FILTER_TEXT, fn: (item) => item },
            { label: getDataLabel('createdData'), value: 'createdOn', type: FILTER_DATE, fn: (item) => moment(item).calendar() },
            { label: getDataLabel('updatedData'), value: 'updatedOn', isPrimaryColumn: true, type: FILTER_DATE, fn: (item) => moment(item).calendar() },
        ];

        return (
            <section className='md-grid'>
                <ProTable status={ALL} items={dataItems} columns={columns} key={ALL} componentName={COMPONENT_NAME} emptyCard={() => createEmptyCard(ALL)}/>
            </section>
        );
    }

    return (
        <>
            { renderPageContent() }
            <DetailsDialog content={RequestDetailContent} onHide={() => setFocusedRequestId(null)} visible={focusedRequestId !== null} data={foundRequest}/>
        </>
    );
}

export default ClientRequests;