import React from 'react';
import { Media } from 'react-md';

const Loading = () => {
    return (
        <div className="centered">
            <Media aspectRatio="1-1">
                <img src="/loading_coparenter.gif" height="50px" width="50px" alt="loading"/>
            </Media>
            <h1>Loading ProTool</h1>
        </div>
    );
};

export default Loading;