import { useMediaQuery } from 'react-responsive'
 
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 840 });
    return isDesktop ? children : null;
}

const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 839 });
    return isTablet ? children : null;
}

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 600 });
    return isMobile ? children : null;
}

const DesktopOrTablet = ({ children }) => {
    const isDesktopOrTablet = useMediaQuery({ minWidth: 600 });
    return isDesktopOrTablet ? children : null;
}

const TabletOrMobile = ({ children }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 839 });
    return isTabletOrMobile ? children : null;
}

export {
    Desktop,
    Tablet,
    Mobile,
    DesktopOrTablet,
    TabletOrMobile,
};