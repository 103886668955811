import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { Button, Card, TableCardHeader, TextField, SelectField, Tooltipped } from 'react-md';
import {  selectFilterFromComponent } from '../../selectors/global';
import { DATE_FILTER_TYPES, BOOLEAN_FILTER_TYPES } from '../../constants/lookupInfo';
import { FILTER_TEXT, FILTER_DATE, FILTER_NUMBER, FILTER_NONE, DEFAULT_FILTER_TEXT, DEFAULT_FILTER_DATE, DEFAULT_FILTER_NUMBER, FILTER_BOOLEAN, DEFAULT_FILTER_BOOLEAN, ALL } from '../../constants';                                      
import { setFilterToComponent, setSortToComponent } from '../../actions/global';
import { isNullOrUndefined } from 'util';

class ListTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterText: '',
            filterProperty: this.props.columns[0].value,
            filterControl: FILTER_TEXT,
            isFiltering: false,
        };
    }
    
    componentDidMount() {
        const { tableFilter, items } = this.props;
        
        if (tableFilter) {
            this.setState(tableFilter);
        }

        this.setState({ items, itemsByProperty: items });
    }

    componentDidUpdate(prevProps, prevState) {
        const { tableFilter: prevTableFilter } = prevProps;
        const { tableFilter, columns, componentName } = this.props;

        // Handle new incoming data sorting props. If there is no sorting data, default to primary column.
        if (!isNullOrUndefined(tableFilter)) {
            if (isNullOrUndefined(prevTableFilter)) {
                return this.setState(tableFilter);
            }

            if (this.filterObjHasChanged(prevTableFilter, tableFilter)) {
                return this.setState(tableFilter);
            }
        } else if (!isNullOrUndefined(prevTableFilter)) {
            return this.setState({
                filterText: '',
                filterProperty: columns[0].value,
                filterControl: FILTER_TEXT,
                isFiltering: false,
            });
        }

        // If there are any filter changes, save the filter into redux
        if (this.filterObjHasChanged(prevState, this.state)) {
            this.props.setTableFilter(this.state, componentName);
        }
    }

    updateFilter = (filterText) => {
        if (typeof filterText === 'undefined') return;

        const { items } = this.state;
        const { status, filteredItems } = this.props;
        const { filterProperty } = this.state;
        const itemsByStatus = this.filterByStatus(status, items);
        const itemsByProperty = this.filterByProperty(itemsByStatus, filterText, filterProperty);

        const dataItems = this.state.itemsByProperty || [];
        let isUpdating = false;

        if (dataItems.length !== itemsByProperty.length) {
            isUpdating = true;
        } else {
            for (let i = 0; i < itemsByProperty.length; i++) {
                if (itemsByProperty[i].id !== dataItems[i].id) {
                    isUpdating = true;
                    break;
                }
            }
        }

        isUpdating && filteredItems(itemsByProperty.length ? itemsByProperty : []);
        this.setState({ itemsByProperty });
    }

    filterObjHasChanged = (prevFilterObj, nextFilterObj) => {
        const { filterText: prevFilterText, filterProperty: prevFilterProperty, FilterControl: prevfilterControl, isFiltering: prevIsFiltering } = prevFilterObj;
        const { filterText: nextFilterText, filterProperty: nextFilterProperty, FilterControl: nextfilterControl, isFiltering: nextIsFiltering } = nextFilterObj;
        
        return prevFilterText !== nextFilterText || prevFilterProperty !== nextFilterProperty || prevfilterControl !== nextfilterControl || prevIsFiltering !== nextIsFiltering;
    }

    handleTextSearchChange = (text, event) => {
        this.setState({filterText: text, isFiltering: text.trim().length ? true : false });
        this.updateFilter(text);
    }
    
    handleDateSearchChange = (name, value) => {
        const text = DATE_FILTER_TYPES[value].id;
        this.setState({filterText: text, isFiltering: value >= 0 ? true : false });
        this.updateFilter(text);
    }

    handleBooleanSearchChange = (name, value) => {
        const text = BOOLEAN_FILTER_TYPES[value].id;
        this.setState({filterText: text, isFiltering: value >= 0 ? true : false });
        this.updateFilter(text);
    }
    
    handlePropertyChange = (value, index, evt) => {
        const { columns } = this.props;
        const filterType = columns.find(x => x.value === value).type;
        let filteredState = {};

        switch(filterType) {
            case FILTER_TEXT:
                filteredState = { filterText: DEFAULT_FILTER_TEXT, isFiltering: false, filterProperty: value, filterControl: filterType };
                break;
            case FILTER_DATE:
                filteredState = { filterText: DEFAULT_FILTER_DATE, isFiltering: false, filterProperty: value, filterControl: filterType };
                break;
            case FILTER_NUMBER:
                filteredState = { filterText: DEFAULT_FILTER_NUMBER, isFiltering: false, filterProperty: value, filterControl: filterType };
                break;
            case FILTER_BOOLEAN:
                filteredState = { filterText: DEFAULT_FILTER_BOOLEAN, isFiltering: false, filterProperty: value, filterControl: filterType };
                break;
            default:
                filteredState = { filterText: DEFAULT_FILTER_TEXT, isFiltering: false, filterProperty: value, filterControl: filterType };
        }

        this.setState(filteredState);
    }

    filterByStatus = (status, items) => {
        if (!items) return null;

        return status === ALL ? items : items.filter(x => x.status === status);
    }

    filterByProperty = (items, text, property) => {
        const { columns } = this.props;
        if (!items) return;

        if (!text.trim().length) return items;

        const lowerText = text.toLowerCase();
        const filterColumn = columns.find(x => x.value === property);
        
        switch(filterColumn.type) {
            case FILTER_TEXT: 
                return items.filter((x) => {
                    return x[property] ? x[property].toLowerCase().includes(lowerText) : false
                });
            case FILTER_DATE:
                return items.filter((x) => {
                    const dateFilterObj = DATE_FILTER_TYPES.find(filter => filter.id.toLowerCase() === lowerText);
                    const dateFilterDays = dateFilterObj.days;
                    const dateFilterName = dateFilterObj.id.toLowerCase();

                    if (dateFilterDays < 0) {
                        return x;
                    } else if (!x[property]){
                        return false;
                    } else if (dateFilterName === 'Yesterday'.toLowerCase()) {
                        const startDate = moment().subtract(dateFilterDays, 'days').startOf('day');
                        const endDate = moment().subtract(0, 'days').startOf('day');
                        return moment(x[property]).isBetween(startDate, endDate, 'minute', '[]');
                    } else {
                        const date = moment().subtract(dateFilterDays, 'days').startOf('day');
                        return moment(x[property]).isAfter(date);
                    }
                });
            case FILTER_NUMBER:
                return items.filter(x => Number(lowerText) === Number(x[property]));
            case FILTER_BOOLEAN:
                return items.filter((x) => {
                    const booleanFilterValue = BOOLEAN_FILTER_TYPES.find(filter => filter.id.toLowerCase() === lowerText).value;
                    return booleanFilterValue === null || Boolean(x[property]) === booleanFilterValue; 
                });
            default:
                return items.filter((x) => {
                    return x[property] ? x[property].toLowerCase().includes(lowerText) : false
                });
        }
    }

    buildTitle = (header) => {
        return (header ? <TableCardHeader title={header} visible={false}/> : null);
    }

    buildFilterBar = () => {
        const { columns } = this.props;
        const { filterControl, filterProperty, filterText } = this.state;
        const displayFilters = columns.filter(x=> x.type !== FILTER_NONE);

        return (
            <Tooltipped
                label="The last filter you have applied will be saved. Click the button on the right to remove filters on this table."
                position="bottom"
            >
                <TableCardHeader key="table-header" visible={false}>
                    <SelectField
                        id="filter-by"
                        className="tabContainerSelectField"
                        label="Filter by"
                        menuItems={displayFilters}
                        itemLabel="label"
                        itemValue="value"
                        defaultValue="displayName"
                        value={filterProperty}
                        onChange={this.handlePropertyChange}
                    />
                    { (filterControl === FILTER_TEXT || filterControl === FILTER_NUMBER) &&
                        <TextField
                            id="search-text"
                            value={filterText}
                            className="tabContainerSearch"
                            label="Search"
                            onChange={this.handleTextSearchChange}
                        />
                    }
                    { filterControl === FILTER_DATE && 
                        <SelectField
                            id="search-date"
                            className="tabContainerSearch"
                            label="Date Filter"
                            menuItems={DATE_FILTER_TYPES}
                            itemLabel="id"
                            itemValue="id"
                            defaultValue="All"
                            value={filterText}
                            onChange={this.handleDateSearchChange}
                        />
                    }
                    { filterControl === FILTER_BOOLEAN && 
                        <SelectField
                            id="search-boolean"
                            className="tabContainerSearch"
                            label="Yes/No Filter"
                            menuItems={BOOLEAN_FILTER_TYPES}
                            itemLabel="id"
                            itemValue="id"
                            defaultValue="All"
                            value={filterText}
                            onChange={this.handleBooleanSearchChange}
                        />
                    }
                    { this.buildButtons() }
                </TableCardHeader>
            </Tooltipped>
        );
    }

    buildButtons = () => {
        const { buttons } = this.props;
        const tableButtons = [{ label: 'Reset Table', icon: 'replay', click: this.resetFilterAndSorter }];

        if (buttons) {
            buttons.forEach(x => tableButtons.push(x));
        }

        return tableButtons.map((buttonData) => {
            return (
                <Button key={buttonData.label} className="tabContainerButton" iconChildren={buttonData.icon} onClick={buttonData.click} flat primary {...buttonData.otherProps}>
                    { buttonData.label }
                </Button>
            );
        });
    }

    resetFilterAndSorter = () => {
        const { componentName } = this.props;
        this.props.setTableFilter(null, componentName);
        this.props.setTableSorter(null, componentName);
        this.updateFilter('');
    }

    render() {
        const { header, hideHeader, emptyCard } = this.props;
        const { isFiltering, items } = this.state;
        const headerStyle = { width: '100%' };

        if (!isFiltering && (!items || !items.length)) {
            return emptyCard ? emptyCard() : null;
        }

        return (
            <Card style={headerStyle} className={isFiltering ? 'filtering-gradiant' : ''}>
                { !hideHeader && this.buildTitle(header) }
                { !hideHeader && this.buildFilterBar() }
            </Card>
        );
    }
}

export function mapDispatchToProps(dispatch) {
    return {
        setTableFilter: (filter, componentName) => dispatch(setFilterToComponent(filter, componentName)),
        setTableSorter: (sorter, componentName) => dispatch(setSortToComponent(sorter, componentName)),
    };
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    tableFilter: selectFilterFromComponent(ownProps.componentName),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListTable);
