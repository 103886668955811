import React, { useState, useEffect } from 'react';
import { CardTitle, Card, CardText } from 'react-md';

export default function ConferenceCard({attributes, cardType, id}) {

    return (
        <Card>
            <CardTitle title={`${cardType} Conference`} />
            <CardText>
                <p>status: {attributes.status} </p>
            </CardText>
        </Card>
    );
}