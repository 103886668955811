import React from 'react';
import moment from 'moment';
import { ScheduleCard } from '../ScheduleCards';
import { filterScheduleItem } from './CalendarTileContent';
import { SCHEDULE_CATEGORY_BIRTHDAY, PARENTING_SCHEDULE_CATEGORIES, ADDITIONAL_CATEGORIES } from '../../constants/schedule';
import { Grid, Cell } from 'react-md';

const buildParentingScheduleCards = (items, childUid) => items.filter((item) => filterScheduleItem(item, childUid, PARENTING_SCHEDULE_CATEGORIES)).map((item) => <ScheduleCard item={item}/>);
const buildAdditionalScheduleCards = (items, childUid) => items.filter((item) => filterScheduleItem(item, childUid, ADDITIONAL_CATEGORIES)).map((item) => <ScheduleCard item={item}/>);
const buildBirthdayDetails = (child) => <ScheduleCard id={`pro-calendar-birthday`} item={{category: SCHEDULE_CATEGORY_BIRTHDAY, child: child}}/>

const buildDisplay = (dateItems, child, hasBirthday) => {
    const displayCards = [];

    if (dateItems && dateItems.items) {
        buildParentingScheduleCards(dateItems.items, child.uid).forEach((x) => displayCards.push(x));
        buildAdditionalScheduleCards(dateItems.items, child.uid).forEach((x) => displayCards.push(x));
    }

    if (hasBirthday) {
        displayCards.push(buildBirthdayDetails(child));
    }

    return (
        <Grid id="pro-calendar-display">
            { displayCards.map((card) => <Cell size={12}>{ card }</Cell>) }
        </Grid>
    );
}

const buildEmptyDisplay = () => {
    return (
        <p id="pro-calendar-empty-display">No Appointments or Schedules for today!</p>
    );
}

const ProCalendarDisplay = ({dateItems, child, hasBirthday, date}) => {
    return (
        <div id="pro-calendar">
            <h2>{ `Calendar details for ${moment(date).format('MM/DD/YYYY')}` }</h2>
            { dateItems || hasBirthday ? buildDisplay(dateItems, child, hasBirthday) : buildEmptyDisplay() }
        </div>
    );
}

export default ProCalendarDisplay;