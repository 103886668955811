import React from 'react';
import { Card, CardTitle, CardText } from 'react-md';

function GeneralChildContent(props) {
    const { child } = props;

    const yearsStyle = {
        alignSelf: 'flex-start',
        marginTop: '12px',
        verticalAlign: 'top',
        fontSize: '20px'
    }

    const ageStyle = {
        fontSize: '90px'
    }

    const cardTextStyle = {
        alignItems: 'center',
        display: 'flex',
        paddingTop: '0px'
    }

    if (!child) {
        return (<></>);
    }

    return (
        <Card>
            <CardTitle
                title={`${child.firstName} ${child.lastName}`} 
                subtitle={child.birthDay}
            />
            <CardText style={cardTextStyle}>
                <h4 className="md-display-4" style={ageStyle}>{child.age}</h4>
                <h5 className="md-display-2" style={yearsStyle}>years old</h5>
            </CardText>
        </Card>
    );
}

export default GeneralChildContent;