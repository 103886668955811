import React, { useState } from 'react';
import { Button } from 'react-md';
import useCustomTheme from '../../hooks/useCustomTheme';

function ThemedButton(props) {
    const { style = {}, swapTheming, disabled, ...otherProps } = props;
    const { color, backgroundColor, ...otherStyles } = style;
    const [ hover, setHover ] = useState(false);

    const {
        getButtonTheme,
    } = useCustomTheme();

    const buttonColorTheme = getButtonTheme(swapTheming, hover, color, backgroundColor);
    const themedStyle = Object.assign({}, buttonColorTheme, otherStyles);

    return (
        <Button onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} style={!disabled && themedStyle || null} disabled={disabled} {...otherProps}/>
    );
}

export default ThemedButton;

export {
    ThemedButton,
}