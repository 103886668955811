import React from 'react';
import { FileInput, Cell, Grid, Button } from 'react-md';
import { MediaPreviewArrayFromFile } from '../../components/MediaPreviewCard';
import Bounce from 'react-reveal/Bounce';

export default function FileFormField(props) {
    const { name, values, errors, onChange, onBlur } = props;

    const formProps = Object.assign({}, props);
    delete formProps['name'];
    delete formProps['values'];
    delete formProps['errors'];
    delete formProps['onChange'];
    delete formProps['onBlur'];

    function removeFileFromIndex (index) {
        const files = [...values[name]];
        files.splice(index, 1);

        onChange(name, files);
    }
    
    function buildPreviewButtons(file, index) {
        const buttons = [
            <Button key={`delete-button-${file.name}`} className="md-cell--right" icon onClick={() => removeFileFromIndex(index)}>delete</Button>
        ];
        return buttons;
    }

    function buildPreviewWrapper(preview, key) {
        return (
            <Cell key={key} desktopSize={4} tabletSize={4} phoneSize={2}>
                { preview }
            </Cell>
        );
    }

    return (
        <>
            <FileInput
                id={name}
                name={name}
                multiple
                accept="image/*,application/pdf"
                onChange={(files, e) => onChange(name, [...values[name] , ...files])}
                onTouchEnd={onBlur}
                {...formProps}
            />
            <Grid>
                {
                    <MediaPreviewArrayFromFile mediaArray={values[name]} actionBuilder={buildPreviewButtons} wrapper={buildPreviewWrapper}/>
                }
            </Grid>
            { values[name] && Array.from(values[name]).length === 0 && <p>No Files</p> }
            <Bounce when={!!errors[name]}><p className="error-text">{errors[name] || '...'}</p></Bounce>
        </>
    );
}