import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { Cell, Button } from 'react-md';
import { required } from '../../components/RequestFields/renderToolboxField';
import renderTextField from '../../components/RequestFields/renderTextField';

class HyperlinkForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { url: '' };
    }

    render() {
        const { error, submitting, pristine, handleSubmit,
        fields: { url } } = this.props;

        const fieldStyle = { width: '100%'};
        const formStyle = { paddingLeft: '25px', paddingRight: '25px', marginTop: '80px'}

        return (
            <form onSubmit={handleSubmit} style={formStyle} className="md-grid">
                <Cell size={12}>
                    <Field
                        id={`url`}
                        name={`url`}
                        label="Link"
                        placeholder=""
                        rows={1}
                        maxRows={1}
                        required
                        validate={[required]}
                        style={fieldStyle}
                        component={renderTextField}
                    />
                </Cell>
                {error && <strong>{error}</strong>}
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <Button className="full-width-field" disabled={pristine || submitting} type="submit" flat primary swapTheming>Submit</Button>
                </Cell>
            </form>
        )
    }
};

export default reduxForm({
    form: 'HyperlinkForm',
    fields: [ 'url' ],
    enableReinitialize: true,
})(HyperlinkForm);