import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { selectOrganizationId, selectCurrentUser } from '../../selectors/global';
import { setToolbarButtons } from '../../actions/global';
import { callCloudRunFunction } from '../../utils/firestore';
import { FILTER_TEXT, ALL } from '../../constants';
import useFilterSorterPanel from '../../hooks/useFilterSorterPanel';
import { Grid, Cell } from 'react-md';
import useDialogFormBuilder from '../../hooks/useDialogFormBuilder';
import CreateGroupForm from './CreateGroupForm';
import GroupCard from './GroupCard';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS, NOTIFICATION_INFO } from '../../constants/notifications';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import UpdateGroupForm from './UpdateGroupForm';
import DeleteConfirmationForm from '../../components/Forms/DeleteConfirmationForm';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';
import CheckGroupForm from './CheckGroupForm';
import { useCustomPageFrameworkLabels, useCustomDataLabels } from '../../hooks/useCustomLabels';
import GettingStartedPaper from '../../components/GettingStartedPaper';
import ThemedButton from '../../components/Buttons/ThemedButton';
import useCustomAssets from '../../hooks/useCustomAssets';

function OrganizationGroups() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());

    const [ items, setItems ] = useState([]);
    const [ focusedGroupId, setFocusedGroupId ] = useState(null);
    const foundGroup = items && items.find(group => group.uid === focusedGroupId || group.id === focusedGroupId);

    const labelVariableData = {
        name: foundGroup && foundGroup.name || '',
    };

    const {
        getAsset,
    } = useCustomAssets('EmptyBackground');

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('OrganizationGroups', labelVariableData);

    const {
        finishedLoading,
        renderPageContent,    
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];
        dispatch(setToolbarButtons(toolBarButtons));

        var dataStream = null;
        const db = firebase.firestore();
        
        dataStream = db.collection('ProOrganizations').doc(organizationId).collection('Groups').onSnapshot((snapshot) => {
            const groups = snapshot.docs.map(doc => {
                const group = doc.data();

                group.createdOn = group.createdOn ? group.createdOn.toDate() : null;
                group.updatedOn = group.updatedOn ? group.updatedOn.toDate() : null;
                group.id = doc.id;
                return group;
            });
            
            setItems(groups);
            finishedLoading();
        });

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Groups',
            action: 'visit page',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);

        return () => {
            dispatch(setToolbarButtons([]));

            if (dataStream) {
                dataStream();
            }
        }
    }, []);

    const columns = [
        { label: getDataLabel('groupNameData'), value: 'name', type: FILTER_TEXT },
        { label: getDataLabel('descriptionData'), value: 'description', type: FILTER_TEXT },
    ];

    const {
        getFilteredAndSortedItems,
        renderFilterSortPanel,
    } = useFilterSorterPanel(getLabel('filterSorterHeader'), columns, items);

    const createGroupDialog = {
        FormComponent: CreateGroupForm,
        onSubmit: createGroup,
        dialogData: { title: getLabel('createTitleBar') },
    };

    const {
        showDialog: showCreateDialog,
        renderDialogForm: renderCreateDialogForm
    } = useDialogFormBuilder(createGroupDialog);

    const updateGroupDialog = {
        FormComponent: UpdateGroupForm,
        onSubmit: updateGroup,
        dialogData: { title: getLabel('updateTitleBar') },
        formData: { initialValues: foundGroup },
    };

    const {
        showDialog: showUpdateDialog,
        renderDialogForm: renderUpdateDialogForm
    } = useDialogFormBuilder(updateGroupDialog);

    const deleteGroupPopup = {
        FormComponent: DeleteConfirmationForm,
        onSubmit: deleteGroup,
        onHide: () => setFocusedGroupId(null),
        popupData: { title: `Type \'Delete\' to delete ${foundGroup ? foundGroup.name : ''}`, formId: 'delete-group-form' },
        formData: { formId: 'delete-group-form', initialValues: foundGroup },
    };

    const {
        showPopup: showDeletePopup,
        renderPopupForm: renderDeletePopupForm,
    } = usePopupFormBuilder(deleteGroupPopup);

    const checkGroupForm = {
        FormComponent: CheckGroupForm,
        onSubmit: () => showDeletePopup(),
        dialogData: { title: getLabel('deleteTitleBar') },
        formData: { currentUser: currentUser, group: foundGroup, organizationId: organizationId },
    };

    const {
        showDialog: showCheckGroupDialog,
        renderDialogForm: renderCheckGroupDialog,
    } = useDialogFormBuilder(checkGroupForm);

    function createGroup(groupData) {
        const groupMetaData = {
            organizationId: organizationId,
        };

        const groupDocument = Object.assign({}, groupData, groupMetaData);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Groups',
            action: 'create group',
            metadata: {...groupDocument},
        };
        
        showToastNotification(`Creating group ${groupDocument.name}`, NOTIFICATION_INFO);
        
        callCloudRunFunction(currentUser, 'createOrganizationGroup', groupDocument).then((result) => {
            showToastNotification('Sucessfully created a new group', NOTIFICATION_SUCCESS);
            log.metadata.id = result.uid;
            log.groupId = result.uid;
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('An error has occurred', NOTIFICATION_ERROR);
        });
    }

    const updateGroupClicked = (id) => {
        setFocusedGroupId(id);
        showUpdateDialog();
    }

    function updateGroup(groupData) {
        const groupToUpdate = items && items.find(group => group.uid === groupData.id);
        if (!groupToUpdate) {
            showToastNotification('No such group to update', NOTIFICATION_ERROR);
            return;
        }

        const groupMetadata = {
            organizationId: organizationId,
        };

        const groupDocument = Object.assign({}, groupData, groupMetadata);

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Groups',
            action: 'update group',
            groupId: groupDocument.id,
            metadata: {...groupDocument},
        };

        showToastNotification(`Updating group ${groupDocument.name}`, NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'updateOrganizationGroup', groupDocument).then(() => {
            showToastNotification('Sucessfully updated group', NOTIFICATION_SUCCESS);
        }).then(() => {
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('An error has occurred', NOTIFICATION_ERROR);
        });
        setFocusedGroupId(null);
    }

    const deleteGroupClicked = (id) => {
        setFocusedGroupId(id);
        showCheckGroupDialog();
    }

    function deleteGroup(deleteData) {
        const groupToDelete = items && items.find(group => group.uid === deleteData.id || group.id === deleteData.id);

        if (!groupToDelete) {
            showToastNotification(`No such group to delete`, NOTIFICATION_ERROR);
            return;
        }

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Groups',
            action: 'delete group',
            categoryId: deleteData.id,
            metadata: {...groupToDelete}
        };

        setFocusedGroupId(null);
        showToastNotification(`Deleting group...`, NOTIFICATION_INFO);

        callCloudRunFunction(currentUser, 'deleteOrganizationGroup', { id: deleteData.id, organizationId: organizationId }).then(() => {
            showToastNotification(`Group has been deleted`, NOTIFICATION_SUCCESS);
        }).then(() => {
            return callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification(`Group could not be deleted`, NOTIFICATION_ERROR);
        });
    }

    const buildCreateGroupButton = () => {
        return (
            <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                <ThemedButton className="full-width-field" flat swapTheming primary iconChildren="add" onClick={showCreateDialog}>
                    { getLabel('createButtonText') }
                </ThemedButton>
            </Cell>
        );
    }

    const buildFilterSortPanel = () => {
        return (
            <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                { renderFilterSortPanel() }
            </Cell>
        );
    }

    const createEmptyCard = () => {
        const cardInfo = { status: ALL, cardMediaUrl: getAsset('organizationGroupsEmpty'), headerTitle: getLabel('emptyResultsHeader'), subTitle: getLabel('emptyResultsSubheader'), buttonText: getLabel('createButtonText'), buttonClick: showCreateDialog };

        return (<GettingStartedPaper key={cardInfo.status} {...cardInfo}/>);
    }

    const buildGroupsList = () => {
        return !items || items.length === 0
            ? (createEmptyCard())
            : ( <> { getFilteredAndSortedItems().map(buildGroupCard) } </> );
    }

    const buildGroupCard = (group, index) => {
        // Prevent deleting the last Group
        const canDelete = items.length > 1;

        return (
            <Cell key={`group-card-${index}`} desktopSize={3} tabletSize={2} phoneSize={2}>
                <GroupCard group={group} onUpdate={updateGroupClicked} canDelete={canDelete} onDelete={deleteGroupClicked}/>
            </Cell>
        );
    }

    function buildPageContent() {
        return (
            <Grid>
                { buildCreateGroupButton() }
                { buildFilterSortPanel() }
                { buildGroupsList() }
            </Grid>
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderCreateDialogForm() }
            { renderUpdateDialogForm() }
            { renderCheckGroupDialog() }
            { renderDeletePopupForm() }
        </>
    );
}

export default OrganizationGroups;