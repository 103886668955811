import React from 'react';
import { ToastContainer, Slide, toast } from 'react-toastify';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_WARNING, NOTIFICATION_ERROR } from '../../constants/notifications';

const NOTIFICATION_TYPES = [ NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_WARNING, NOTIFICATION_ERROR ];
const ToastNotification = () => {
    return (
        <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
            closeOnClick
            draggable
            pauseOnHover={false}
            transition={Slide}
        />
    );
}

const showToastNotification = (text, toastType, options) => {
    if (text) {
        NOTIFICATION_TYPES.includes(toastType)
            ? toast[toastType](text, options || {})
            : toast(text, options || {});
    }
}

export {
    ToastNotification,
    showToastNotification,
};