import React, { useEffect, useState } from "react";
import usePageFramework from "../../hooks/usePageFramework";
import { selectCurrentUser, selectOrganizationId, selectDefaultAssets, selectCurrentOrganization } from "../../selectors/global";
import useLoadingPageFramework from "../../hooks/useLoadingPageFramework";
import UpdateAssetsForm from "./UpdateAssetsForm";
import { List, Card, ListItem, Cell } from "react-md";
import TwoColumnLayout from "../../pageLayouts/TwoColumnLayout";
import ThemedButton from "../../components/Buttons/ThemedButton";
import { uploadFile } from "../../utils/fileUpload";
import { callCloudRunFunction } from "../../utils/firestore";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS, NOTIFICATION_INFO } from "../../constants/notifications";
import { showToastNotification } from "../../components/Notifications/ToastNotification";

function OrganizationAssets() {
    const {
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const defaultAssets = selector(selectDefaultAssets());
    const organization = selector(selectCurrentOrganization());

    const accessibleAssets = defaultAssets;
    // const accessibleAssets = getAccessibleGroups(defaultAssets, organization);

    const [ focusedItemKey, setFocusedItemKey ] = useState(null);
    const foundAssetGroup = focusedItemKey && defaultAssets && defaultAssets.find(x => focusedItemKey === x.key);

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        finishedLoading();
    }, []);

    const updateAssets = (assetData) => {
        const { valueKeys, currentAssets, newAssets, deleteAssets } = assetData;
        delete assetData.valueKeys;
        delete assetData.currentAssets;
        delete assetData.newAssets;
        delete assetData.deleteAssets;

        const assetMetadata = {
            organizationId: organizationId,
        }

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organizaton Branding',
            action: 'update organization assets',
            assetKey: assetData.assetKey,
            metadata: {...assetData},
        };

        const promises = [];
        const assetItems = [];

        valueKeys.forEach((valueKey) => {
            const currentAssetId = currentAssets[valueKey];
            const newAssetFile = newAssets[valueKey];
            const deleteAssetFile = deleteAssets[valueKey];

            // Asset of the valueKey has been marked to be deleted back to default asset
            if (deleteAssetFile) {
                promises.push(callCloudRunFunction(currentUser, 'deleteUpload', { uid: deleteAssetFile }).then(() => {
                    assetItems.push({ key: valueKey, value: '' });
                    log.metadata[valueKey] = '';
                }))
            
            } else if (newAssetFile) {
                // Save new picture picture uploaded
            
                const basePath = `Organizations/${organizationId}/assets`;
                promises.push(uploadFile(newAssetFile, basePath, currentUser, `Asset for ${valueKey}`, []).then((uploadId) => {
                    assetItems.push({ key: valueKey, value: uploadId });
                    log.metadata[valueKey] = uploadId;
                }));

                // If there is a current asset, delete the asset.
                if (currentAssetId) {
                    promises.push(callCloudRunFunction(currentUser, 'deleteUpload', { uid: currentAssetId }));
                }
            } else {
                assetItems.push({ key: valueKey, value: currentAssetId });
            }
        });

        showToastNotification('Updating assets...', NOTIFICATION_INFO);

        Promise.all(promises).then(() => {
            const assetDocument = Object.assign({}, assetData, { assetItems: assetItems }, assetMetadata);
            return callCloudRunFunction(currentUser, 'updateOrganizationAssets', assetDocument);
        }).then(() => {
            showToastNotification('Sucessfully updated assets', NOTIFICATION_SUCCESS);
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            setFocusedItemKey(null);
        }).catch((err) => {
            console.log(err);
            showToastNotification('Failed to update assets', NOTIFICATION_ERROR);
        });

    }

    const buildAssetGroups = () => {
        if (!accessibleAssets) {
            return ( <p> No available assets to change </p> );
        }

        return (
            <List>
                { accessibleAssets.map(x => <ListItem primaryText={x.page} onClick={() => setFocusedItemKey(x.key)}/>) }
            </List>  
        );
    }

    const buildFormDisplay = () => {
        if (!foundAssetGroup) {
            return (<p>Select a feature on the left to change its assets</p>);
        }

        const formProperties = { formId: foundAssetGroup.key, title: foundAssetGroup.description, FormComponent: UpdateAssetsForm, subtitle: foundAssetGroup.subDescription };

        return (<Card><formProperties.FormComponent id={formProperties.formId} key={formProperties.formId} onSubmit={updateAssets} {...formProperties} /></Card>)
    }

    const returnToListButton = () => {
        return (
            <Cell size={12}>
                <ThemedButton className="full-width-field" flat swapTheming primary onClick={() => setFocusedItemKey(null)}>
                    Return to list
                </ThemedButton>
            </Cell>
        );
    }

    const buildTabletMobileSection = () => {
        return !foundAssetGroup
            ? buildAssetGroups()
            : (
                <>
                    { returnToListButton() }
                    { buildFormDisplay() }
                </>
            )
    }

    function buildPageContent() {
        return (
            <TwoColumnLayout
                buildLeftColumn={buildAssetGroups}
                buildRightColumn={buildFormDisplay}
                buildTabletMobile={buildTabletMobileSection}
            />
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default OrganizationAssets;