import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as firebase from 'firebase';
import { CardText, Grid } from "react-md";
import RequestHistoryCard from '../RequestHistoryCard';
import Fade from 'react-reveal/Fade';
import MessagesList from '../MessagesList';
import CellDataDisplay from '../CellDataDisplay';

const buildPaddedSection = (children) => {
    return (
        <CardText>
            { children }
        </CardText>
    )
}

const AgreedRequestList = (props) => {
    const { displayAgreement = [], status } = props;
    const details = displayAgreement.sort((a, b) => a.sentence < b.sentence);

    return buildPaddedSection(
        <>
            {
                details.map((detail, index) => {
                    return (
                        <div id={`agreed-request-list-section-${index}`}>
                            <p>{ detail.value }</p>
                        </div>
                    )
                })
            }
        </>
    );
}

const DetailedRequestList = (props) => {
    const { displayDetails = [], disagreeReason, status, createdBy, createdOn, updatedOn } = props;
    const style = { marginTop: '10px' };

    return buildPaddedSection(
        <Grid>
            {
                disagreeReason && (
                    <CellDataDisplay
                        headerClassName={'chat-card-red-text'}
                        valueClassName={'chat-card-red-text'}
                        headerText={'Disagreement Reason'}
                        valueText={ disagreeReason }
                    />
                )
            }
            {
                displayDetails.map((detail) => {
                    const numItemsInRow = displayDetails.filter((x) => x.row === detail.row).length || 1;

                    return (
                        <CellDataDisplay
                            desktopSize={12/numItemsInRow}
                            tabletSize={8/numItemsInRow}
                            headerText={detail.title}
                            valueText={detail.value}
                        />
                    )
                })
            }
            <CellDataDisplay
                headerText={'Status'}
                valueText={status}
            />
            <CellDataDisplay
                headerText={`Created By ${createdBy} On`}
                valueText={moment(createdOn).format('DD-MMM-YYYY [at] h:mm a')}
            />
        </Grid>
    );
}

function RequestComments(props) {
    const { id, members } = props;

    const [ messages, setMessages ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ loadingError, setLoadingError ] = useState(false);


    useEffect(() => {
        setMessages([]);
        setIsLoading(true);
        setLoadingError(false);

        if (!id) {
            setIsLoading(false);
            return;
        }

        const db = firebase.firestore();

        db.collection('Requests').doc(id).collection('messages').orderBy('index', 'asc').get().then((snapshot) => {
            const messages = snapshot.docs.map((doc) => {
                const message = doc.data();
                message.id = doc.id;
                message.timestamp = message.timestamp ? message.timestamp.toDate() : null;
                // message.member = members.find(member => member.uid === message.author);
                return message;
            });

            setMessages(messages);
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
            setLoadingError(true);
        });
    }, [id]);

    const buildCommentsContent = () => {
        return loadingError
            ? ( <p>Error Loading Messages</p> )
            : isLoading
                ? ( <p>Loading Messages</p> )
                : !messages || messages.length === 0
                    ? ( <p>No Messages Available</p> )
                    : (
                        <Fade>
                            <MessagesList members={members} messages={messages}/>
                        </Fade>
                    );
    }

    return buildPaddedSection(buildCommentsContent());
}

function RequestHistory(props) {
    const { id } = props;

    const [ history, setHistory ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ loadingError, setLoadingError ] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setLoadingError(false);
        setHistory(null);

        if (!id) {
            setIsLoading(false);
            return;
        }

        const db = firebase.firestore();

        db.collection('Requests').doc(id).collection('history').orderBy('createdOn', 'asc').get().then((snapshot) => {
            const requestHistory = snapshot.docs.map((doc) => {
                const change = doc.data();
                change.id = doc.id;
                change.createdOn = change.createdOn ? change.createdOn.toDate() : null;
                return change;
            });

            // const sortedHistory = sortDataItems(requestHistory, { type: FILTER_DATE, value: 'createdOn' }, false);
            setHistory(requestHistory);
            setIsLoading(false);

        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
            setLoadingError(true);
        });
    }, [id]);

    const buildHistoryContent = () => {
        return loadingError
            ? ( <p>Error Loading History</p> )
            : isLoading
                ? ( <p>Loading History</p> )
                : !history || history.length === 0
                    ? ( <p>No History Available</p> )
                    : (
                        <Fade>
                            <>
                                {
                                    history.map(item => <RequestHistoryCard {...item}/>)
                                }
                            </>
                        </Fade>
                    );
    }

    return buildPaddedSection(buildHistoryContent());
}

export {
    AgreedRequestList,
    DetailedRequestList,
    RequestComments,
    RequestHistory,
};