import React from 'react';
import { Card, CardTitle, CardText } from 'react-md';
import TenantPageItem from './TenantPageItem';
import { Droppable } from 'react-beautiful-dnd';
import { THEME_SECONDARY } from '../../constants/themeKeys';
import useCustomTheme from '../../hooks/useCustomTheme';

function TenantPageColumn(props) {
    const { column, items } = props;

    const {
        getTheme,
    } = useCustomTheme();

    const buildItem = (item, index) => {
        return (
            <TenantPageItem key={item.id} item={item} index={index}/>
        );
    }

    return (
        <Card>
            <CardTitle title={column.title} subtitle={items.map(x => x.id).join(',')}/>
            <CardText>
                <Droppable droppableId={column.id}>
                    {
                        (provided, snapshot) => {
                            const style = {
                                backgroundColor: snapshot.isDraggingOver ? getTheme(THEME_SECONDARY) : '#FFFFFF',
                                ...provided.droppableProps.style,
                            };

                            return (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    class={'drop-page-column'}
                                    style={style}
                                >
                                    { items.map(buildItem) }
                                    {provided.placeholder}
                                </div>
                            );
                        }
                    }
                </Droppable>
            </CardText>
        </Card>
    );
}

export default TenantPageColumn;