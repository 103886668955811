import React from 'react';
import useFormValidation from '../../hooks/useFormValidation';
import useCustomLabels from '../../hooks/useCustomLabels';
import { Grid, Cell, CardTitle, Button, CardText } from 'react-md';
import TextFormField from '../../components/FormFields/TextFormField';
import { validate } from './UpdateLabelsValidate';
import { findItemByKey } from '../../utils/customBranding';
import ThemedButton from '../../components/Buttons/ThemedButton';

function UpdateLabelsForm(props) {
    const { onSubmit, formId, title } = props;

    const {
        getTenantRawLabel,
        getDefaultRawLabel,
        getLabelKeys,
        getLabelData,
    } = useCustomLabels(formId);

    const labelData = getLabelData();
    const labelKeys = getLabelKeys();

    const INITIAL_STATE = {
        labelKey: formId,
    };
    labelKeys.forEach(key => INITIAL_STATE[key] = getTenantRawLabel(key));

    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting,
    } = useFormValidation(INITIAL_STATE, saveLabels, validate(labelData));

    function saveLabels(values) {
        const labels = parseSubmissionToLabels(values);
        onSubmit(labels);
    }

    function parseSubmissionToLabels(values) {
        const labels = {
            labelKey: values.labelKey,
            labelItems: labelKeys.map(x => { return { key: x, value: values[x].trim() } }),
        };

        return labels;
    }

    const resetLabels = () => {
        changeValues(INITIAL_STATE);
    }

    const setToDefault = () => {
        const defaultValues = {};
        labelKeys.forEach(key => defaultValues[key] = '');

        changeValues(defaultValues);
    }

    const buildLabelTextFormField = (labelKey) => {
        const item = findItemByKey(labelData, labelKey);

        return (
            <Cell size={12}>
                <TextFormField
                    name={labelKey}
                    label={item.keyHeader ? item.keyHeader : labelKey}
                    values={values}
                    errors={errors}
                    floating={true}
                    placeHolder={`Defaults to: ${getDefaultRawLabel(labelKey)}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Cell>
        );
    }

    return (
        <form id={formId} name={formId} onSubmit={handleSubmit}>
            <CardTitle title={title}>
                <ThemedButton className="md-cell--right" flat primary onClick={setToDefault}>Set Default</ThemedButton>
                <ThemedButton className="md-cell--right" flat primary onClick={resetLabels}>Reset</ThemedButton>
                <ThemedButton className="md-cell--right" flat primary swapTheming type="submit" disabled={isSubmitting}>Save</ThemedButton>
            </CardTitle>
            <CardText>
                <Grid>
                    { labelKeys.map(buildLabelTextFormField) }
                </Grid>
            </CardText>
        </form>
    );
}

export default UpdateLabelsForm;