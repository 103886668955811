import React, { useState } from 'react';
import { Tab, TabPanel } from 'react-md';
import { AgreementList, DetailedAgreementList } from './DetailsAgreementList';
import ThemedTabs from '../ThemedTabs';

const buildDisplay = (agreementData) => {
    const { displayAgreement, displayDetails } = agreementData;

    if (displayAgreement && displayDetails) {
        return (
            AgreementTabManager(agreementData)
        );
    } else if (displayDetails) {
        return (
            <DetailedAgreementList {...agreementData}/>
        );
    } else {
        return (
            <AgreementList {...agreementData}/>
        );
    }
};

function AgreementTabManager (data) {
    // These tabs are handled manually due to a sizing issue with react-md's TabContainer component
    const [activeTab, setactiveTab] = useState(0);

    const tabs = [
        { id: 'agreement-list', label: 'Agreement', content: <AgreementList {...data}/>, },
        { id: 'detailed-agreement-list', label: 'Details', content: <DetailedAgreementList {...data}/> }
    ];

    return (
        <>
            <ThemedTabs colored tabId="agreement-detail-tabs" onTabChange={setactiveTab}>
                {
                    tabs.map((x) => {
                        return (
                            <Tab id={x.id} key={x.id} label={x.label}/>
                        );
                    })
                }
            </ThemedTabs>
            <TabPanel id="agreement-detail-tab-content" contolledById={tabs[activeTab].id}>
                { tabs[activeTab].content }
            </TabPanel>
        </>
    );
}

const AgreementDetailContent = (agreementData) => {
    return (
        <div id="agreement-detail-content">
            { buildDisplay(agreementData) }
        </div>
    );
};

export default AgreementDetailContent;