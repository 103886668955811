import React, { useEffect } from 'react';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { setToolbarTitle, setToolbarButtons } from '../../actions/global';
import { selectCurrentUser, selectCurrentOrganization, selectOrganizationId } from '../../selectors/global';
import { Grid, Cell } from 'react-md';
import { NAV_DEFAULT } from '../../constants/navigation';
import ProfileCard from '../Profile/ProfileCard';
import OrganizationCard from '../Profile/OrganizationCard';
import StipulationsList from '../../components/StipulationsList';
import { callCloudRunFunction } from '../../utils/firestore';
import { useCustomPageFrameworkLabels } from '../../hooks/useCustomLabels';

function ProfessionalProfile() {
    const {
        history,
        dispatch,
        selector,
        location,
    } = usePageFramework();

    const pageState = location.state || {};
    const professional = pageState.professional;

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const currentOrganization = selector(selectCurrentOrganization());

    const labelVariableData = {
        organizationName: currentOrganization && currentOrganization.organization || '',
        name: professional && professional.displayName || '',
        email: professional && professional.email || '',
    };

    const {
        getLabel,
    } = useCustomPageFrameworkLabels('Profile', labelVariableData);

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        if (!professional) {
            return;
        }

        const toolBarButtons = [ { icon: 'arrow_back', onClick: () => history.goBack() }];

        dispatch(setToolbarButtons(toolBarButtons));

        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Professional Profile',
            action: 'visit page',
            professionalId: professional.id,
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log);
        finishedLoading();

        return () => {
            dispatch(setToolbarButtons([]));
        }
    }, []);

    if (!professional) {
        history.push(NAV_DEFAULT);
        return (<></>);
    }

    function buildPageContent() {
        return (
            <Grid>
                <Cell desktopSize={3} tabletSize={4} phoneSize={4}>
                    <Grid>
                        <Cell size={12}>
                            <ProfileCard getLabel={getLabel} {...professional}/>
                        </Cell>
                        <Cell size={12}>
                            <OrganizationCard getLabel={getLabel} {...currentOrganization}/>
                        </Cell>
                    </Grid>
                </Cell>
                <Cell desktopSize={9} tabletSize={4} phoneSize={4}>
                    <Grid>
                        <Cell size={12}>
                            <StipulationsList getLabel={getLabel} {...professional}/>
                        </Cell>
                    </Grid>
                </Cell>
            </Grid>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default ProfessionalProfile;