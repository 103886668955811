/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as firebase from 'firebase';
import { Card } from 'react-md';
import useRoomState from './hooks/useRoomState';
import useLocalTracks from './hooks/useLocalTracks';
import useRoom from './hooks/useRoom';
import useHandleRoomDisconnectionErrors from './hooks/useHandleRoomDisconnectionErrors';
import useHandleOnDisconnect from './hooks/useHandleOnDisconnect.js';
import useHandleTrackPublicationFailed from './hooks/useHandleTrackPublicationFailed';
import useDominantSpeaker from './hooks/useDominantSpeaker';
import useMainSpeaker from './hooks/useMainSpeaker';
import VideoControls from './components/VideoControls';
import { callCloudRunFunction } from '../../utils/firestore';
import * as REDUX from '../../constants/redux';
import Room from './components/Room';
import {  NAV_MSGING } from '../../constants/navigation';

const getTokens = async (currentRoom) => {
    console.log('calling getToken');
    const cf = firebase.functions();
    
    try {
        const resRoom = await cf.httpsCallable('createRoom')({ uniqueName: currentRoom });
        console.log(resRoom);
    } catch(err) {
        console.log(err);
    }

    const response = await cf.httpsCallable('createToken')({ uniqueName: currentRoom, identity: 'Mediator' });
    
    return response.data.token;
}

const connectionOptions = {
    dominantSpeaker: true,
    maxAudioBitrate: 12000,
    bandwidthProfile: {
        video: {
            mode: 'collaboration',
            renderDimensions: {
            high: { height: 1080, width: 1920 },
            standard: { height: 90, width: 160 },
            low: { height: 90, width: 160 },
            },
        },
    },
    networkQuality: { local: 1, remote: 1 },
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }]
};

const onErrorCallback = (error) => {
    console.log(`ERROR: ${error.message}`, error);
};

function VideoConference () {
    const history = useHistory();
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.get('global').get('currentUser'));
    const props = useSelector(state => state.get('global').get('videoConference'));

    const { currentRoom, messageCount } = props;
    const [roomName, setRoomName] = useState('');
    const { localTracks } = useLocalTracks();

    const { room, connect } = useRoom(localTracks, onErrorCallback, connectionOptions);
    const roomState = useRoomState(room);
    dispatch({ type: REDUX.ROOM, payload: room });

    const onError = (err) => { 
        updateRoomState('disconnected');
        dispatch({ type: REDUX.NEW_UNIQUE_CHANNEL_NAME, payload: {id: currentRoom, category: 'getHelp'} });
        history.push(NAV_MSGING);
        console.log(err)
    };

    const onDisconnect = (err) => {
        console.log(err);
        updateRoomState('disconnected');
        dispatch({ type: REDUX.NEW_UNIQUE_CHANNEL_NAME, payload: {id: currentRoom, category: 'getHelp'} });
        history.push(NAV_MSGING);
    };

    useHandleRoomDisconnectionErrors(room, onError);
    useHandleTrackPublicationFailed(room, onError);
    useHandleOnDisconnect(room, onDisconnect);
    useDominantSpeaker();
    useMainSpeaker();

    useEffect(() => {
        if (currentRoom !== roomName) {
            setRoomName(currentRoom);
            getTokens(currentRoom).then((token) => {
                connect(token);
            });
        }

    }, [currentRoom]);

    useEffect(() => {
        if (roomState) {
            updateRoomState(roomState);
        }
    }, [roomState]);

    const updateRoomState = (state) => {
        const json = { chatId: currentRoom, index: messageCount, msgAttributes: { status: state } };
        console.log(json);

        return callCloudRunFunction(userProfile, 'updateChatAttributes', json).then(() => {
            console.log('status updated in chat');
        }).catch((err) => {
            console.log(err);
        });
    }

    const buildVideo = () => {
        const style = {
           position: 'absolute',
           top: '50%',
           right: '25%'
        }

        return (<h1 style={style}>Intializing and Creating a Secure Channel...</h1>);
    }

    const cardStyle= { marginTop: '80px', padding: '10px' };

    return (
        <>
            <Card style={cardStyle}>
                {roomState === 'disconnected' ? buildVideo() : <Room room={room}/>}
                {roomState === 'disconnected' ? null : <VideoControls room={room} localTracks={localTracks} />}
            </Card>
        </>
    );
}

export default VideoConference;
