/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Button, DialogContainer, Toolbar } from 'react-md';
import AgreementForm from './FormAgreement';

export default class AgreementDialog extends PureComponent {
    parseAgreement = (values) => {
        const agreementValues = {};
        const findKeys = ['agreementType', 'agreement'];
        findKeys.forEach(x => agreementValues[x] = '');

        values.map((value, key) => {
            const name = findKeys.find(x => key === x);
            return agreementValues[name] = value.trim();
        });

        return agreementValues;
    };

    handleSubmit = (e) => {
        const agreement = this.parseAgreement(e);
        this.props.addAgreement(agreement);
    };

    render() {
        const { visible, onHide, title, message } = this.props;

        const initialAgreementData = {
            agreementType: title,
            agreement: message,
        };

        return (
            <DialogContainer
                id="add-Orgs-dialog"
                aria-labelledby="add-org-dialog-title"
                visible={visible}
                onHide={onHide}
                fullPage
            >
                <CSSTransitionGroup
                    id="add-org-form"
                    className="md-text-container md-toolbar--relative"
                    transitionName="md-cross-fade"
                    transitionEnterTimeout={300}
                    transitionLeave={false}
                >
                    <Toolbar
                        nav={<Button icon onClick={onHide}>close</Button>}
                        title="Create an Agreement" titleId="add-org-dialog-title" fixed colored
                    />
                    <AgreementForm onSubmit={this.handleSubmit} key={0} index={0} {...initialAgreementData}/>
                </CSSTransitionGroup>
            </DialogContainer>
        );
    }
}
