import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import usePageFramework from "../../hooks/usePageFramework"
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import ParentingPlanDisplayCard from './ParentingPlanDisplayCard';
import EventDisplayCard from './EventDisplayCard';
import HolidayDisplayCard from './HolidayDisplayCard';
import { sortDataItems } from '../../utils/sortFilter';
import { FILTER_DATE } from '../../constants';
import { SCHEDULE_CATEGORY_HOLIDAY, SCHEDULE_CATEGORY_EVENT, SCHEDULE_CATEGORY_PARENTING_PLAN } from '../../constants/schedule';
import { Cell } from 'react-md';

function ProScheduleDisplay(props) {
    const { clientId, selectSchedule, editSchedule, deleteSchedule } = props;
    const {
        selector,
    } = usePageFramework();

    const [ schedulePlans, setSchedulePlans ] = useState([]);
    const [ scheduleEvents, setScheduleEvents ] = useState([]);
    const [ scheduleHolidays, setScheduleHolidays ] = useState([]);
    const [ clientSchedule, setClientSchedule ] = useState([]);
    const [ children, setChildren ] = useState([]);

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        const db = firebase.firestore();
        var schedulePlanStream = null;
        var scheduleEventStream = null;
        var holidayStream = null;

        if (!clientId) {
            return;
        }

        db.collection('Children').where('participants', 'array-contains', clientId).get().then((childrenDocs) => {
            const children = childrenDocs.docs.map((docRef) => {
                const child = docRef.data();

                child.id = docRef.id;
                child.uid = docRef.id;

                return child;
            });

            setChildren(children);
        }).catch((err) => {
            console.log(err);
        })

        schedulePlanStream = db.collection('ScheduleParentingPlans').where('participants', 'array-contains', clientId).onSnapshot((snapshot) => {
            const parentingPlans = snapshot.docs.map((doc) => {
                const parentingPlan = doc.data();
                parentingPlan.id = doc.id;
                parentingPlan.createdOn = parentingPlan.createdOn ? parentingPlan.createdOn.toDate() : null;
                parentingPlan.updatedOn = parentingPlan.updatedOn ? parentingPlan.updatedOn.toDate() : null;
                parentingPlan.startDate = parentingPlan.startDate ? parentingPlan.startDate.toDate() : null;
                parentingPlan.endDate = parentingPlan.endDate ? parentingPlan.endDate.toDate() : null;
                parentingPlan.itemType = SCHEDULE_CATEGORY_PARENTING_PLAN;

                return parentingPlan;
            });

            setSchedulePlans(parentingPlans);
        });

        scheduleEventStream = db.collection('ScheduleEvents').where('participants', 'array-contains', clientId).onSnapshot((snapshot) => {
            const events = snapshot.docs.map((doc) => {
                const event = doc.data();
                event.id = doc.id;
                event.createdOn = event.createdOn ? event.createdOn.toDate() : null;
                event.updatedOn = event.updatedOn ? event.updatedOn.toDate() : null;
                event.startDateTime = event.startDateTime ? event.startDateTime.toDate() : null;
                event.endDateTime = event.endDateTime ? event.endDateTime.toDate() : null;
                event.itemType = SCHEDULE_CATEGORY_EVENT;

                return event;
            });

            setScheduleEvents(events);
        });

        holidayStream = db.collection('Holidays').where('participants', 'array-contains', clientId).onSnapshot((snapshot) => {
            const holidays = snapshot.docs.map((doc) => {
                const holiday = doc.data();
                holiday.id = doc.id;
                holiday.createdOn = holiday.createdOn ? holiday.createdOn.toDate() : null;
                holiday.updatedOn = holiday.updatedOn ? holiday.updatedOn.toDate() : null;
                holiday.itemType = SCHEDULE_CATEGORY_HOLIDAY;

                return holiday;
            });

            setScheduleHolidays(holidays);
        });

        return () => {
            if (schedulePlanStream) {
                schedulePlanStream();
            }

            if (scheduleEventStream) {
                scheduleEventStream();
            }

            if (holidayStream) {
                holidayStream();
            }
        }
    }, []);

    // Merge SchedulePlans and ScheduleEvents together and sort to most recently created
    useEffect(() => {
        const sortedSchedule = sortDataItems([...schedulePlans, ...scheduleEvents, ...scheduleHolidays], { type: FILTER_DATE, value: 'createdOn' }, false);
        setClientSchedule(sortedSchedule);
        finishedLoading();
    }, [ schedulePlans, scheduleEvents, scheduleHolidays ]);

    // Each type of schedule is displayed differently
    const getDisplayCard = (scheduleItem) => {
        switch (scheduleItem.itemType) {
            case SCHEDULE_CATEGORY_PARENTING_PLAN:
                return ParentingPlanDisplayCard;
            case SCHEDULE_CATEGORY_EVENT:
                return EventDisplayCard;
            case SCHEDULE_CATEGORY_HOLIDAY:
                return HolidayDisplayCard;
            default:
                return () => (<></>);
        }
    }

    const onScheduleAction = (scheduleAction, scheduleId) => {
        const foundSchedule = clientSchedule.find(x => x.id === scheduleId);

        if (scheduleAction && foundSchedule) {
            scheduleAction(foundSchedule);
        }
    }

    const buildScheduleItem = (scheduleItem) => {
        const DisplayCard = getDisplayCard(scheduleItem);

        const displayCardActions = {
            canView: selectSchedule ? true : false,
            canUpdate: editSchedule ? true : false,
            canDelete: deleteSchedule ? true : false,
            viewSchedule: (scheduleId) => onScheduleAction(selectSchedule, scheduleId),
            updateSchedule: (scheduleId) => onScheduleAction(editSchedule, scheduleId),
            deleteSchedule: (scheduleId) => onScheduleAction(deleteSchedule, scheduleId),
        };

        return (
            <Cell size={12}>
                <DisplayCard {...scheduleItem} loadedChildren={children} {...displayCardActions}/>
            </Cell>
        );
    }

    function buildPageContent() {
        return (
            <>
                { clientSchedule.map(buildScheduleItem) }
            </>
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    );
}

export default ProScheduleDisplay;