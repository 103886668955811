import React, { useState, useEffect } from 'react';
import usePageFramework from "../../hooks/usePageFramework";
import { selectCurrentUser, selectOrganizationId, selectDefaultLabels } from "../../selectors/global";
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import UpdateAdvancedLabelsForm from './UpdateAdvancedLabelsForm';
import { ListItem, Card, Cell, Button, List } from 'react-md';
import TwoColumnLayout from '../../pageLayouts/TwoColumnLayout';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants/notifications';
import { callCloudRunFunction } from '../../utils/firestore';
import GeneralConfirmationForm from '../../components/Forms/GeneralConfirmationForm';
import usePopupFormBuilder from '../../hooks/usePopupFormBuilder';

function SystemLabels() {
    const {
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const defaultLabels = selector(selectDefaultLabels());
    const [ focusedItemKey, setFocusedItemKey ] = useState(null);
    const [ pendingSystemLabels, setPendingSystemLabels ] = useState(null);
    const foundLabelGroup = focusedItemKey && defaultLabels && defaultLabels.find(x => focusedItemKey === x.key);
    
    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        finishedLoading();
    }, []);

    const updateSystemLabelsPopup = {
        FormComponent: GeneralConfirmationForm,
        onSubmit: () => saveForm(pendingSystemLabels),
        onHide: () => setPendingSystemLabels(null),
        popupData: { title: `Type \'${'Confirm'}\' to update the default labels.`, formId: 'update-system-labels-form' },
        formData: { formId: 'update-system-labels-form', initialValues: { values: 'Confirm' } },
    };

    const {
        showPopup: showUpdateSystemLabelsPopup,
        hidePopup: hideUpdateSystemLabelsPopup,
        renderPopupForm: renderUpdateSystemLabelsPopupForm,
    } = usePopupFormBuilder(updateSystemLabelsPopup);

    const initiateConfirmation = (labelData) => {
        setPendingSystemLabels(labelData);
        showUpdateSystemLabelsPopup();
    }

    const saveForm = (labelData) => {
        if (!labelData) {
            showToastNotification('An error has occurred', NOTIFICATION_ERROR);
            return;
        }

        const labelMetadata = {
            organizationId: 'Default',
        };

        const labelDocument = Object.assign({}, labelData, labelMetadata);
        
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'System Branding',
            action: 'update system labels',
            labelKey: labelData.labelKey,
            metadata: {...labelData},
        };

        showToastNotification('Saving labels...', NOTIFICATION_INFO);
        
        callCloudRunFunction(currentUser, 'updateOrganizationLabels', labelDocument).then(() => {
            showToastNotification('Labels have been sucessfully saved!', NOTIFICATION_SUCCESS);
            callCloudRunFunction(currentUser, 'logProUserAction', log);
            setFocusedItemKey(null);
        }).catch((err) => {
            console.log(err);
            showToastNotification('An error has occurred', NOTIFICATION_ERROR);
        });
    }

    const buildLabelGroups = () => {
        if (!defaultLabels) {
            return ( <p> No available labels to change </p>)
        };

        return (
            <List>
                { defaultLabels.map(x => <ListItem primaryText={x.page} onClick={() => setFocusedItemKey(x.key)}/>) }
            </List>
        );
    }

    const buildFormDisplay = () => {
        if (!foundLabelGroup) {
            return (<p>Select a feature on the left to change its branding</p>);
        }

        const formProperties = { formId: foundLabelGroup.key, title: foundLabelGroup.description, FormComponent: UpdateAdvancedLabelsForm };

        return (<Card><formProperties.FormComponent id={formProperties.formId} key={formProperties.formId} onSubmit={initiateConfirmation} {...formProperties} /></Card>)
    }

    const returnToListButton = () => {
        return (
            <Cell size={12}>
                <Button className="full-width-field" flat swapTheming primary onClick={() => setFocusedItemKey(null)}>
                    Return to list
                </Button>
            </Cell>
        );
    }

    const buildTabletMobileSection = () => {
        return !foundLabelGroup
            ? buildLabelGroups()
            : (
                <>
                    { returnToListButton() }
                    { buildFormDisplay() }
                </>
            )
    }

    function buildPageContent() {
        return (
            <>
                <b className="inactive-text"> You are currently changing default system labels. Updates to labels here will reflect across the entire Professional Tool. </b>
                <TwoColumnLayout
                    buildLeftColumn={buildLabelGroups}
                    buildRightColumn={buildFormDisplay}
                    buildTabletMobile={buildTabletMobileSection}
                />
            </>
        );
    }

    return (
        <>
            { renderPageContent() }
            { renderUpdateSystemLabelsPopupForm() }
        </>
    );
}

export default SystemLabels;