import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase'
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { Card, List, CardTitle, CardText, Subheader } from 'react-md';
import DetailsDialog from '../DetailsDialog';
import AgreementDetailContent from '../DetailsDialog/AgreementDetailContent';
import StipulationListItem from './StipulationListItem';

function StipulationsList(props) {
    const { uid, displayName, getLabel } = props;

    const [ dataItems, setDataItems ] = useState([]);
    const [ focusedAgreementId, setFocusedAgreementId ] = useState(null);

    const {
        finishedLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        var stream = null;
        const db = firebase.firestore();

        if (!uid) {
            return;
        }

        stream = db.collection('Agreements').where('createdByUid', '==', uid).onSnapshot((snapshot) => {
            const dataItems = snapshot.docs.map((doc) => {
                const agreementItem = doc.data();
                agreementItem.id = doc.ref.id;
                agreementItem.createdOn = agreementItem.createdOn ? agreementItem.createdOn.toDate() : null;
                agreementItem.updatedOn = agreementItem.updatedOn ? agreementItem.updatedOn.toDate() : null;
                return agreementItem;
            });

            setDataItems(dataItems);
            finishedLoading();
        });

        return () => {
            if (stream) {
                stream();
            }
        }
    }, []);

    const foundAgreement = dataItems && dataItems.find(x => x.id === focusedAgreementId) || {};

    if (!uid) {
        return (<></>);
    }

    function buildPageContent() {
        return dataItems.length === 0
            ? <Subheader primaryText={getLabel('emptyAgreementResultsHeader')}/>
            : <> { dataItems.map(item => <StipulationListItem setFocusedAgreementId={setFocusedAgreementId} {...item}/>) } </>
    }

    return (
        <>
            <Card>
                <CardTitle
                    title={getLabel('agreementHeader')}
                    subtitle={getLabel('agreementSubheader')}
                />
                <CardText>
                    <List>
                        { renderPageContent() }
                    </List>
                </CardText>
            </Card>
            <DetailsDialog content={AgreementDetailContent} onHide={() => setFocusedAgreementId(null)} visible={focusedAgreementId !== null} data={foundAgreement}/>
        </>
    );
}

export default StipulationsList;