import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { Cell, Button } from 'react-md';
import { required, phoneValidate } from '../../components/RequestFields/renderToolboxField';
import renderTextField from '../../components/RequestFields/renderTextField';

class InviteGuestForm extends PureComponent {
    render() {
        const { submitting, pristine, handleSubmit } = this.props;

        const fieldStyle = { width: '100%'};
        const formStyle = { paddingLeft: '25px', paddingRight: '25px', marginTop: '80px'}

        return (
            <form onSubmit={handleSubmit} style={formStyle} className="md-grid">
                <Cell size={12}>
                <Field
                    id={'sms'}
                    name={'sms'}
                    label="Enter the guest's 10 digit sms number. Only one guest should be invited in the session."
                    placeholder=""
                    type="text"
                    style={fieldStyle}
                    component={renderTextField}
                    validate={[required, phoneValidate]}
                />
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <Button className="full-width-field" disabled={pristine || submitting} type="submit" flat primary swapTheming>Submit</Button>
                </Cell>
            </form>
        )
    }
}

export default reduxForm({
    form: 'InviteGuestForm',
    enableReinitialize: true,
})(InviteGuestForm);