import React from 'react';
import { Card, CardTitle, CardText, Grid, Divider, CardActions, Cell } from 'react-md';
import moment from 'moment';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../Buttons/ThemedButton';

function ClientCard(props) {
    const { onViewClick, onCaseNumberClicked = () => {}, client } = props;

    const titleStyle = { display: 'block ', padding: '16px 8px 8px' };
    const contentStyle = { padding: '8px' };

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();
    
    return (
        <Card>
            <CardTitle
                style={titleStyle}
                title={<div className='single-line-overflow'>{ `${client.firstName} ${client.lastName}` }</div>}
                subtitle={`A client of your organization`}
            />
            <CardText style={contentStyle}>
                <Grid>
                    <Cell desktopSize={12} tabletSize={4} phoneSize={4}>
                    <p><b>{ `${getDataLabel('caseNumberData')}:` }</b> <a href={'#'} onClick={() => onCaseNumberClicked(client.caseNumber)}>{ `${client.caseNumber}` }</a></p>
                    </Cell>
                    <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                        <b>{ `${getDataLabel('createdData')}:` }</b>
                        <p>{ `${moment(client.createdOn).format("MMM Do YYYY h:mm:ss a")}` }</p>
                    </Cell>
                </Grid>
            </CardText>
            <Divider/>
            <CardActions>
                <ThemedButton flat primary onClick={() => onViewClick(client.uid || client.id)}> View Details </ThemedButton>
            </CardActions>
        </Card>
    );
}

export default ClientCard;