import usePageFramework from "./usePageFramework";
import { selectTenantTheme, selectDefaultTheme } from "../selectors/global";
import { getThemeProperty } from "../utils/customBranding";
import { THEME_PRIMARY, THEME_PRIMARY_TEXT, THEME_SECONDARY, THEME_SECONDARY_TEXT, THEME_SECONDARY_ACTIVE_TEXT, THEME_BUTTON } from "../constants/themeKeys";
import Color from "color";

/*
    Future upgrade?
    
    Rather than using keys/hooks for each ThemedComponent
    (ThemedButton, ThemedTabs, ThemedToolbar, navigation components; UserCard, NavItemLink, Layout)

    Consider a server side trigger to generate and point the user to a CSS file for theme color override

*/

function useCustomTheme() {
    const {
        selector,
    } = usePageFramework();

    const tenantTheme = selector(selectTenantTheme());
    const defaultTheme = selector(selectDefaultTheme());
    const transparentColor = '#FFFFFF00';
    const white = '#FFFFFF';

    // getTenantTheme for getting theme data only from tenant data
    const getTenantTheme  = (key) => tenantTheme && tenantTheme[key] || null;
    const getDefaultTheme  = (key) => defaultTheme && defaultTheme[key] || null;
    const getTheme = (key) => getThemeProperty(tenantTheme, defaultTheme, key);

    const getToolbarTheme = () => { return { backgroundColor: getTheme(THEME_PRIMARY), color: getTheme(THEME_PRIMARY_TEXT) } };
    const getNavMenuTheme = (isActive, isHidden, transparentBackground) => { return { backgroundColor: transparentBackground ? transparentColor : getTheme(THEME_SECONDARY), color: isHidden ? getTheme(THEME_SECONDARY) : isActive ? getTheme(THEME_SECONDARY_ACTIVE_TEXT) : getTheme(THEME_SECONDARY_TEXT) } };
    const getNavNoBackgroundTheme = (isActive, isHidden) => { return { color: isHidden ? getTheme(THEME_SECONDARY) : isActive ? getTheme(THEME_SECONDARY_ACTIVE_TEXT) : getTheme(THEME_SECONDARY_TEXT) } };
    const getNavOnlyBackgroundTheme = (isActive, isHidden) => { return { backgroundColor: isHidden ? getTheme(THEME_SECONDARY) : isActive ? getTheme(THEME_SECONDARY_ACTIVE_TEXT) : getTheme(THEME_SECONDARY_TEXT) } };

    const getButtonTheme = (swapTheming, isHovered, color, backgroundColor) => {

        const themeForegroundColor = color ? color : swapTheming ? white : getTheme(THEME_BUTTON);
        const themeBackgroundColor = backgroundColor ? backgroundColor : swapTheming ? getTheme(THEME_BUTTON) : transparentColor;

        return { 
            color: isHovered ? Color(themeForegroundColor).darken(0.1).hex() : themeForegroundColor,
            backgroundColor: isHovered ? Color(themeBackgroundColor).darken(0.1).hex() : themeBackgroundColor,
        };
    }


    return {
        getTenantTheme,
        getDefaultTheme,
        getTheme,
        getToolbarTheme,
        getNavMenuTheme,
        getNavNoBackgroundTheme,
        getNavOnlyBackgroundTheme,
        getButtonTheme,
    };
}

export default useCustomTheme;