import React from 'react';
import { CardTitle, Card, CardText, Grid, Button, Divider, CardActions } from 'react-md';
import { buildHalfCellDataDisplay } from '../CellDataDisplay';
import { convertToDisplayDate } from '../../utils/dateTime';
import ThemedButton from '../Buttons/ThemedButton';

function HolidayDisplayCard(props) {
    const { id, category, holiday, createdOn, createdBy, startTime,
        startsBefore, endTime, endsOn, repeats, children, loadedChildren,
        canView, canUpdate, canDelete, viewSchedule, updateSchedule, deleteSchedule
    } = props;

    const foundChildren = children && loadedChildren ? children.map((x) => loadedChildren.find(child => child.uid === x) || null).filter(x => x !== null) : [];

    const buildCardActions = () => {
        if (!canView && !canUpdate && !canDelete) {
            return (<></>);
        }

        return (
            <>
                <Divider/>
                <CardActions>
                    <ThemedButton flat disabled={/*!canView */ true} onClick={() => viewSchedule(id)}> View on calendar </ThemedButton>
                    <ThemedButton flat disabled={!canUpdate || true} onClick={() => updateSchedule(id)}> Edit </ThemedButton>
                    <ThemedButton flat disabled={!canDelete} onClick={() => deleteSchedule(id)}> Delete </ThemedButton>
                </CardActions>
            </>
        );
    }

    return (
        <Card>
            <CardTitle
                title={category}
                subtitle={holiday}
                expander
                defaultExpanded={false}
            />
            <CardText expandable>
                <Grid>
                    { buildHalfCellDataDisplay('Created On:', convertToDisplayDate(createdOn)) }
                    { buildHalfCellDataDisplay('Created By:', createdBy) }
                    { buildHalfCellDataDisplay('Holiday:', holiday) }
                    { buildHalfCellDataDisplay('Start Time:', startTime) }
                    { buildHalfCellDataDisplay('Starts Day(s) Before:', startsBefore) }
                    { buildHalfCellDataDisplay('End Time:', endTime) }
                    { buildHalfCellDataDisplay('Ends Day(s) After:', endsOn) }
                    { buildHalfCellDataDisplay('Repeats:', repeats) }
                    { buildHalfCellDataDisplay('Children:', foundChildren.map(x => `${x.firstName} ${x.lastName}`).join(', ')) }
                </Grid>
            </CardText>
            { buildCardActions() }
        </Card>
    );
}

export default HolidayDisplayCard;