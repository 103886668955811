import * as REDUX from '../constants/redux';

export const buildPayloadObj = (type, payload, options = {}) => {
    return { type, payload, ...options }
}

export const setisAuthenticated = (payload) => buildPayloadObj(REDUX.IS_AUTHENTICATED, payload);
export const setAllClientViewRoles = (payload) => buildPayloadObj(REDUX.ALL_CLIENT_VIEW_ROLES, payload);
export const setAllOrganizationRoles = (payload) => buildPayloadObj(REDUX.ALL_ORGANIZATION_ROLES, payload);
export const setAllManagementRoles = (payload) => buildPayloadObj(REDUX.ALL_MANAGEMENT_ROLES, payload);
export const setClientProfile = (payload) => buildPayloadObj(REDUX.CLIENT_PROFILE, payload);
export const setCurrentUser = (payload) => buildPayloadObj(REDUX.CURRENT_USER, payload);
export const setCurrentUserRoles = (payload) => buildPayloadObj(REDUX.CURRENT_USER_ROLES, payload);
export const setCurrentUserGroups = (payload) => buildPayloadObj(REDUX.CURRENT_USER_GROUPS, payload);
export const setNewUserData = (payload) => buildPayloadObj(REDUX.NEW_CURRENT_USER_DATA, payload);
export const setIsApproved = (payload) => buildPayloadObj(REDUX.IS_APPROVED, payload);
export const setIsAuthInfo = (payload) => buildPayloadObj(REDUX.IS_AUTH_INFO, payload);
export const setUniqueChannelName = (payload) => buildPayloadObj(REDUX.NEW_UNIQUE_CHANNEL_NAME, payload);
export const setToolbarButtons = (payload) => buildPayloadObj(REDUX.TOOLBAR_BUTTONS, payload);
export const setToolbarTitle = (payload) => buildPayloadObj(REDUX.TOOLBAR_TITLE, payload);
export const setVideo = (payload) => buildPayloadObj(REDUX.VIDEO_CONFERENCE, payload);

export const emptyToolbarTitle = () => setToolbarTitle('');
export const emptyToolbarButtons = () => setToolbarButtons([]);

// Branding
export const setDefaultTheme = (payload) => buildPayloadObj(REDUX.DEFAULT_THEME, payload);
export const setDefaultLabels = (payload) => buildPayloadObj(REDUX.DEFAULT_LABELS, payload);

export const setTenantTheme = (payload) => buildPayloadObj(REDUX.TENANT_THEME, payload);
export const setTenantLabels = (payload) => buildPayloadObj(REDUX.TENANT_LABELS, payload);

export const setDefaultAssets = (payload) => buildPayloadObj(REDUX.DEFAULT_ASSETS, payload);
export const setTenantAssets = (payload) => buildPayloadObj(REDUX.TENANT_ASSETS, payload);

// Set data to component key
export const setPropertyToComponent = (payload, component, property) => buildPayloadObj(REDUX.PROPERTY_COMPONENT, payload, { component, property });
export const setDataItemsToComponent = (payload, component) => setPropertyToComponent(payload, component, 'items');
export const setFilterToComponent = (payload, component) => setPropertyToComponent(payload, component, 'filter');
export const setSortToComponent = (payload, component) => setPropertyToComponent(payload, component, 'sort');