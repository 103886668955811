
import React from 'react'
import { Grid, Cell } from 'react-md';
import useFormValidation from '../../hooks/useFormValidation';
import { validate } from './CreateClientInviteValidate';
import TextFormField from '../../components/FormFields/TextFormField';
import { normalizePhone } from '../../components/RequestFields/normalizePhone';
import SelectionFormField from '../../components/FormFields/SelectionFormField';
import { parseGroupControls } from '../../utils/orgGroups';
import { useCustomDataLabels } from '../../hooks/useCustomLabels';
import ThemedButton from '../../components/Buttons/ThemedButton';
import { EMPTY_CLIENT_OBJECT } from '../../constants/clients';
import ClientInfoFormSection from './ClientInfoFormSection';

function CreateClientInviteForm(props) {
    const { onSubmit, onHide, isUsingGroups, selectableOrgGroups } = props;

    const INITIAL_STATE = {
        client1: EMPTY_CLIENT_OBJECT,
        client2: null,
        isUsingGroups: isUsingGroups || false,
        caseNumber: '',
        orgGroup: '',
    };

    const orgGroupControls = parseGroupControls(selectableOrgGroups);

    const {
        getLabel: getDataLabel,
    } = useCustomDataLabels();

    const {
        handleSubmit,
        handleChange,
        changeValues,
        handleBlur,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, createClientInvite, validate);

    function createClientInvite(values) {
        const clientInvite = parseSubmissionToInvite(values);
        onSubmit(clientInvite);
    }

    function parseClientSubmission(key) {
        const clientValues = values[key];

        if (!clientValues) return null;

        const clientData = {
            firstName: clientValues.firstName.trim(),
            lastName: clientValues.lastName.trim(),
            sms: normalizePhone(clientValues.sms.trim()),
            email: clientValues.email.trim(),
        };

        return clientData;
    }

    function parseSubmissionToInvite(values) {
        const clientKeys = [ 'client1', 'client2' ];

        const clientInvite = {
            clients: clientKeys.map((clientKey) => parseClientSubmission(clientKey)).filter(x => x),
            caseNumber: values.caseNumber.trim(),
            orgGroup: values.orgGroup.trim(),
        };

        if (!values.isUsingGroups) {
            delete clientInvite.orgGroup;
        }

        if (!values.caseNumber) {
            delete clientInvite.caseNumber;
        }

        return clientInvite;
    }

    const buildClientSection = (key) => {
        return (
            <ClientInfoFormSection
                values={values}
                errors={errors}
                clientKey={key}
                getDataLabel={getDataLabel}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />
        );
    }

    const buildClient2HeaderSection = () => {
        return !values.client2
            ? 
                (<>
                    <Cell size={12}>
                        <ThemedButton id={'client-2-add-action'} flat iconChildren='add' onClick={() => handleChange('client2', EMPTY_CLIENT_OBJECT)}>
                            Add coParent as client 2
                        </ThemedButton>
                    </Cell>
                </>)
            :
                (<>
                    <Cell desktopSize={6} tabletSize={4} phoneSize={2}><h2>Client 2</h2></Cell>
                    <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                        <ThemedButton id={'client-2-add-action'} flat iconChildren='remove' onClick={() => handleChange('client2', null)}>
                            Remove client 2
                        </ThemedButton>
                    </Cell>
                </>);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}><h2>Client 1</h2></Cell>
                { buildClientSection('client1') }
                { buildClient2HeaderSection() }
                { buildClientSection('client2') }
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}><h2>Information</h2></Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <TextFormField
                        name="caseNumber"
                        label={'Case Number'}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Cell>
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    {
                        isUsingGroups && <SelectionFormField
                                name="orgGroup"
                                label={getDataLabel('groupData')}
                                values={values}
                                errors={errors}
                                menuItems={orgGroupControls}
                                itemLabel="label"
                                itemValue="value"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    }
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <ThemedButton className="full-width-field" disabled={isSubmitting} type="submit" flat primary swapTheming>Submit</ThemedButton>
                </Cell>
            </Grid>
        </form>
    )
}

export default CreateClientInviteForm;