/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { required } from '../../components/RequestFields/renderToolboxField';
import renderTextField from '../../components/RequestFields/renderTextField';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Cell, Button, DialogContainer, Toolbar } from 'react-md';

class EmailLinkDialog extends PureComponent {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.emailLink(e);
    };

    render() {
        const { submitting, pristine, visible, onHide, fields: { password, email } } = this.props;
        const headerStyle = {marginTop: '70px', marginBottom: '20px'};

        return (
            <DialogContainer
                id="add-Orgs-dialog"
                aria-labelledby="add-coParent-dialog-title"
                visible={visible}
                onHide={onHide}
                fullPage
            >
                <CSSTransitionGroup
                    id="add-coParent-form"
                    component="form"
                    onSubmit={this.handleSubmit}
                    className="md-text-container md-toolbar--relative"
                    transitionName="md-cross-fade"
                    transitionEnterTimeout={300}
                    transitionLeave={false}
                >
                    <Toolbar
                        nav={<Button icon onClick={onHide}>close</Button>}
                        title="Sign-in or Register via an Email Link" titleId="add-coParent-dialog-title" fixed colored
                    />

                    <Field
                        style={headerStyle}
                        id={'email'}
                        name={'email'}
                        label="Email Address"
                        placeholder=""
                        className="md-cell-12"
                        required
                        validate={[required]}
                        component={renderTextField}
                    />
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <Button className="full-width-field" disabled={pristine || submitting} type="submit" flat primary swapTheming>Submit</Button>
                </Cell>
                </CSSTransitionGroup>
            </DialogContainer>
        );
    }
};

export default reduxForm({
    form: 'EmailLinkDialog',
    fields: ['email', 'password'],
    enableReinitialize: true,
})(EmailLinkDialog);


