import React from 'react';
import  { Card, Media, CardTitle, CardActions, CircularProgress } from 'react-md'
import { PATH_CARDS } from '../../constants';
import ThemedButton from '../Buttons/ThemedButton';
import Fade from 'react-reveal/Fade';
import useMediaLoader from '../../hooks/useMediaLoader';

const ManagementCardFromUpload = (props) => {
    const { cardImage, cardTitle, cardSubtitle, buttonText, onClick, disabled } = props;

    const cardStyle = { height: '100%' };

    const {
        imageHasLoaded,
        imageSrc,
    } = useMediaLoader({ id: cardImage, suppressNotification: true });

    return (
        <Card id={`management-card-${cardTitle || ''}`} style={cardStyle}>
            {
                !imageHasLoaded
                    ? <CircularProgress centered/>
                : <Fade>
                    <Media>
                        <img src={imageSrc} alt="org"/>
                    </Media>
                </Fade>
            }
            <CardTitle title={cardTitle || ''} subtitle={cardSubtitle}/>
            <CardActions>
                <ThemedButton flat className="card-buttons" disabled={disabled} onClick={onClick || (() => {})}>{ buttonText || 'Manage' }</ThemedButton>
            </CardActions>
        </Card>
    );
}

const ManagementCard = (props) => {
    const { cardImage, cardTitle, cardSubtitle, buttonText, onClick, disabled } = props;

    const cardStyle = { height: '100%' };

    return (
        <Card id={`management-card-${cardTitle || ''}`} style={cardStyle}>
            <Media>
                <img src={`${process.env.PUBLIC_URL}${PATH_CARDS}${cardImage || ''}`} alt="org"/>
            </Media>
            <CardTitle title={cardTitle || ''} subtitle={cardSubtitle}/>
            <CardActions>
                <ThemedButton flat className="card-buttons" disabled={disabled} onClick={onClick || (() => {})}>{ buttonText || 'Manage' }</ThemedButton>
            </CardActions>
        </Card>
    );
}

export default ManagementCard;

export {
    ManagementCardFromUpload
};