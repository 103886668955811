import React, { useState } from 'react';
import { DialogContainer, Button } from 'react-md';
import ThemedButton from '../components/Buttons/ThemedButton';

const onSubmitDefault = (data) => {
    // console.log('Submitting Form:');
    // console.log(data);
}

const onHideDefault = () => {
    // console.log('Hiding Form');
}

const DefaultComponent = () => <> </>;

function usePopupFormBuilder({FormComponent = DefaultComponent, onSubmit = onSubmitDefault, onHide = onHideDefault, popupData = {}, formData = {}}) {
    const { title = 'Popup Form', formId } = popupData;
    const [ visible, setVisible ] = useState(false);

    const showPopup = () => {
        setVisible(true);
    }

    const hidePopup = () => {
        setVisible(false);
        onHide();
    }

    const submitForm = () => {
        if (document.forms[formId]) {
            document.forms[formId].dispatchEvent(new Event('submit'));
        }
    }

    const onSubmitDialog = (data) => {
        onSubmit(data);
        hidePopup();
    }

    const renderPopupForm = () => {
        const actions = [
            <ThemedButton flat onClick={hidePopup}>Cancel</ThemedButton>,
            <ThemedButton flat swapTheming onClick={submitForm}>Confirm</ThemedButton>,
        ];

        return (
            <DialogContainer
                id="popup-form"
                aria-labelledby="popup-form"
                visible={visible}
                onHide={hidePopup}
                title={title}
                actions={actions}
            >
                <FormComponent
                    onSubmit={onSubmitDialog}
                    onHide={hidePopup}
                    {...formData}
                />
            </DialogContainer>
        );
    }

    return {
        showPopup,
        hidePopup,
        renderPopupForm,
    }
}

export default usePopupFormBuilder;