import React from 'react'
import * as firebase from 'firebase';
import { selectCurrentUser, selectCurrentOrganization } from '../../selectors/global';
import { Card, CardTitle, Avatar, MenuButton } from 'react-md';
import { USER_MENU_PROFILE, USER_MENU_LOGOUT, USER_MENU_SETTINGS } from '../../constants';
import usePageFramework from '../../hooks/usePageFramework';
import { NAV_MY_PROFILE, NAV_SETTINGS } from '../../constants/navigation';
import { getInitials } from '../../utils/strings';
import { callCloudRunFunction } from '../../utils/firestore';
import useCustomTheme from '../../hooks/useCustomTheme';

function UserCard(props) {
    const { enabledMenu = true, style = {} } = props;

    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const currentOrganization = selector(selectCurrentOrganization());

    const {
        getNavMenuTheme,
        getNavNoBackgroundTheme,
    } = useCustomTheme();


    const navigationMenuTheme = getNavMenuTheme();

    const cardStyle = Object.assign({}, navigationMenuTheme, style);
    const textStyle = Object.assign({}, getNavNoBackgroundTheme(), style, { overflow: 'hidden', textOverflow: 'ellipsis' });
    const menuStyle = Object.assign({}, getNavNoBackgroundTheme());
    const logoutTextStyle = { color: 'red' };
    const avatarStyle = { borderStyle: 'solid', borderWidth: '1px', borderColor: 'black' };

    const menuItems = [
        { primaryText: USER_MENU_PROFILE, onClick: () => history.push(NAV_MY_PROFILE) },
        // { primaryText: USER_MENU_SETTINGS, onClick: () => history.push(NAV_SETTINGS) },
        { primaryText: USER_MENU_LOGOUT, primaryTextStyle: logoutTextStyle, onClick: signOutUser },
    ];

    function signOutUser() {
        const log = {
            proId: currentUser.uid,
            organizationId: currentOrganization.id,
            page: 'Menu',
            action: 'sign out',
        };

        callCloudRunFunction(currentUser, 'logProUserAction', log)
        firebase.auth().signOut();
    }

    const buildUserAvatar = () => {
        return (
            <Avatar style={avatarStyle} src={currentUser && currentUser.photoUrl} role="presentation">{getInitials(currentUser && currentUser.displayName || ' ')}</Avatar>
        );
    }

    const buildUserMenu = () => {
        return (
            <MenuButton
                disabled={!enabledMenu}
                menuClassName='md-cell--right'
                style={menuStyle}
                id="user-menu"
                icon
                menuItems={menuItems}
                anchor={{
                    x: MenuButton.HorizontalAnchors.CENTER,
                    y: MenuButton.VerticalAnchors.CENTER,
                }}
            >
                more_vert
            </MenuButton>
        );
    }

    const buildUserDisplayName = () => {
        return (
            <div style={textStyle}>
                { currentUser ? currentUser.displayName : ' ' }
            </div>
        )
    }

    const buildUserDisplayEmail = () => {
        return (
            <div style={textStyle}>
                 {currentUser && currentUser.email}
            </div>
        );
    }

    return (
        <Card style={{ boxShadow: "none" }}>
            <CardTitle
                avatar={buildUserAvatar()}
                title={buildUserDisplayName()}
                subtitle={buildUserDisplayEmail()}
                children={buildUserMenu()}
                style={cardStyle}
            />
        </Card>
    );
}

export default UserCard;