import React, { useState, useEffect } from 'react';
import FormParentingPlan from '../Messaging/FormParentingPlan';
import { bufferExchangeTimes, flattenExchangeTimes } from '../../components/ParentingPlan/SchedulePattern';
import usePageFramework from '../../hooks/usePageFramework';
import { selectCurrentUser } from '../../selectors/global';
import { callCloudRunFunction } from '../../utils/firestore';

function UpdateParentingPlanForm(props) {
    const { onSubmit, onHide, initialValues } = props;
    const { category, children, cp1, cp2, description, endDate, exchangeTimes, members, participants, pattern, scheduleName, startDate } = initialValues;
    const {
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const [ loadedChildren, setLoadedChildren ] = useState(null);

    const bufferedExchangeTimes = pattern && exchangeTimes ? bufferExchangeTimes(pattern, exchangeTimes) : null;

    const cp1Search = members.find(x => x.uid === cp1);
    const cp2Search = members.find(x => x.uid === cp2);

    const formData = {
        pattern: pattern,
        exchangeTimes: bufferedExchangeTimes,
        children: children ? children.join(',') : null,
        startDate: startDate,
        endDate: endDate,
        p1Name: `${cp1Search.firstName} ${cp1Search.lastName}`,
        p2Name: `${cp2Search.firstName} ${cp2Search.lastName}`,
        p1Id: cp1Search.uid,
        p2Id: cp2Search.uid,
        desc: description,
        title: scheduleName,
        scheduleType: category,
    };

    useEffect(() => {
        if (!cp1Search && !cp2Search) {
            return;
        }

        const cpIds = {
            cp1Uid: cp1Search ? cp1Search.uid : null,
            cp2Uid: cp2Search ? cp2Search.uid : null || null,
        };

        callCloudRunFunction(currentUser, 'getChildrenForPros', cpIds).then((childrenData) => {
            if (childrenData) {
                const clientChildren = Object.keys(childrenData).map((id) => {
                    return {
                        id: id,
                        childName: `${childrenData[id].firstName} ${childrenData[id].lastName}`.trim(),
                    };
                });

                setLoadedChildren(clientChildren);
            }
        });
    }, [ cp1Search, cp2Search ]);

    function updateParentingPlan(values) {
        const parentingPlan = parseSubmissionToParentingPlan(values);
        // onSubmit(values);
        onSubmit(parentingPlan);
    }

    function parseSubmissionToParentingPlan(values) {
        // Parameter values is of type map since FormParentingPlan component uses redux forms
        const parentingPlanValues = {};
        const findKeys = ['pattern', 'exchangeTimes', 'startDate', 'endDate', 'scheduleType', 'title', 'desc', 'p1Name', 'p2Name', 'p1Id', 'p2Id', 'children' ];
        findKeys.forEach(x => parentingPlanValues[x] = '');
        
        values.map((value, key) => {
            const name = findKeys.find(x => key.startsWith(x));
            return parentingPlanValues[name] = value;
        });
        
        parentingPlanValues['children'] = parentingPlanValues['children'].split(',');
        parentingPlanValues['exchangeTimes'] = flattenExchangeTimes(parentingPlanValues['exchangeTimes']);

        return parentingPlanValues;
    }

    return (
        <FormParentingPlan
            onSubmit={updateParentingPlan}
            availableChildren={loadedChildren}
            {...formData}
        />
    );
}

export default UpdateParentingPlanForm;