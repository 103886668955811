import React from 'react';
import { SelectionControlGroup } from 'react-md';

export const requiredBox = (value) => value ? undefined : "Must select a role";

const errorStyle = {
    color: '#f44336',
    fontSize: '12px',
    marginLeft: '12px'
};

export const renderSelectionControl = ({ input, meta: { touched, error, invalid }, ...custom }) => {
    return (
    <div>
        <SelectionControlGroup
            {...input}
            {...custom}
        />
        { touched && (<div style={errorStyle}>{error}</div>) }
    </div>
)};