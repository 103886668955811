import React, { Component } from 'react';
import * as firebase from 'firebase';
import { SVGIcon, Button, Cell, Grid } from 'react-md';
import EmailLinkDialog from './EmailLinkDialog';
import MediaQuery from 'react-responsive';
import InformationDialog from '../../components/InformationDialog';
import { TERMS_OF_SERVICE, PRIVACY_POLICY } from '../../constants';
import { openInNewWindow } from '../../utils';

const INITIAL_STATE = {
    loginDialogVisible: false,
    noticeDialogVisible: false,
    notificationMessage: '',
};

class SignIn extends Component {
    constructor() {
        super();
        this.state = INITIAL_STATE;
        this.googleSignIn = this.googleSignIn.bind(this);
        this.emailLinkSignIn = this.emailLinkSignIn.bind(this);
    }

    googleSignIn(evt) {
        const provider = new firebase.auth.GoogleAuthProvider();

        firebase.auth().signInWithRedirect(provider).then((result) => {
            const user = result.user;
            // console.log("here is the google user that is returned ", user);
        });

    }

    parseForm = e => [].reduce.call(e.target.elements, (form, el) => {
        const { name, type, value } = el;

        if (!name) {
            return form;
        }

        form[name] = type === 'number' ? Number(value) : value;
        return form;
    }, {});

    hideLoginDialog = () => {
        this.setState({ loginDialogVisible: false });
    };

    hideNoticeDialog = () => {
        this.setState({ noticeDialogVisible: false });
    };

    emailLink = (e) => {
        const emailInfo = this.parseForm(e);

        this.setState({ loginDialogVisible: false });

        var actionCodeSettings = {
            url: process.env.REACT_APP_CLIENT_FIREBASE_EMAIL_SIGN_UP_URL ||' https://coparenter.pro',
            handleCodeInApp: true
        };

        const email = emailInfo.email;

        const notificationMessage = `We have sent a login link to you at ${email}. Please go to that email account and click the link we sent you to login to the coParenter ProTool.`;

        firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings).then(() => {
            window.localStorage.setItem('emailForSignIn', email);
            this.setState({ notificationMessage: notificationMessage, noticeDialogVisible: true });
        }).catch(function (error) {
            console.log(error);
        });
    }

    emailLinkSignIn(evt) {
        this.setState({ loginDialogVisible: true });
    }

    renderLoginButtons = (style) => {
        const EmailIcon = props => (
            <SVGIcon {...props}>
                <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                <path d="M0 0h24v24H0z" fill="none" />
            </SVGIcon>
        );

        return (
            <Grid>
                <Cell size={12}>
                    <Button className={`loginBtn${style}`} raised primary iconEl={<EmailIcon />} onClick={this.googleSignIn}>Sign-in With Google</Button>
                </Cell>
                <Cell size={12}>
                    <Button className={`loginBtn${style}`} raised secondary iconEl={<EmailIcon />} onClick={this.emailLinkSignIn}>Sign-in With Email</Button>
                </Cell>
            </Grid>
        );
    }

    render() {
        // window.document.body.style.background = '#1A237E';
        const headingStyle = { fontSize: '10pt', marginLeft: '75px', marginBottom: '0' };
        const footnoteStyle = { fontSize: '10pt', marginBottom: '0', color: '#000000' };

        const mobileHeadingStyle = { fontSize: '10pt', marginBottom: '0' };
        const mobileFootnoteStyle = { fontSize: '10pt', marginBottom: '0', color: '#000000' };

        const mbolileTopPadding = { paddingTop: "20px" }
        const { loginDialogVisible, noticeDialogVisible, notificationMessage } = this.state;
        const borderStyle = { background: '#1A237E', width: '100%', height: '100px' };
        const messageStyle = { color: 'red' };

        const signInRender = (
            <>
                <MediaQuery query="(min-device-width: 1224px)">
                    <div className="landingSignIn">
                        <h2 style={headingStyle}>Sign-in / Register</h2>
                        { this.renderLoginButtons('') }
                    </div>
                </MediaQuery>
                <MediaQuery query="(max-device-width: 1224px)">
                    <div className="landingSignInMobile">
                        <h2 style={mobileHeadingStyle}>Sign-in / Register</h2>
                        { this.renderLoginButtons('Mobile') }
                    </div>
                </MediaQuery>
            </>
        );

        return (
            <div>
                <MediaQuery query="(min-device-width: 1224px)">
                    <div className="console_viewport layout-column flex-100">
                        <div className="landing-page">
                            <div className="landing-page-header">
                                <img src="/HyphenusLogoDark.png" alt="Hyphenus Logo"  width="179" height="38" className="login-landing-logo" />
                                <div className="landing-padding">
                                    <h1 className="landing-welcome">Welcome to the ProTool</h1>
                                    <div className="landing-subtitle">
                                        {/* coParenter is a coParenting Management and Mediation platform that helps parents take a child-centric approach to separation, divorce and coParenting. */}
                                    </div>
                                    {signInRender}
                                    <h2 style={footnoteStyle} className="landing-subtitle">
                                        For existing users, please use the same login option you used when you first registered on the ProTool.
                                    </h2>
                                    <h2 className="landing-links" onClick={() => openInNewWindow(TERMS_OF_SERVICE)}>Terms of Service</h2>
                                    <h2 style={{marginLeft: '100px'}} className="landing-links" onClick={() => openInNewWindow(PRIVACY_POLICY)}>Privacy Policy</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={borderStyle}></div>
                </MediaQuery>

                <MediaQuery query="(max-device-width: 1224px)">
                    <div style={mbolileTopPadding} className="text-center">
                            <Cell size={12}>
                                <img src="/HyphenusLogoDark.png" width="179" height="38" alt="Hyphenus Logo" />
                            </Cell>
                            <Cell size={12}>
                                <img className="loginGraphic" src="/mobileAlternates/loginpage_mobile.png" height="300px" width="300px" alt="coParenterLogo" />
                            </Cell>
                            <Cell size={12}>
                                <h2>Welcome to the ProTool</h2>
                            </Cell>
                            <Cell size={12}>
                                <div className="text-center landing-subtitle-mobile">
                                    {/* coParenter is a coParenting Management and Mediation platform that helps parents take a child-centric approach to separation, divorce and coParenting. */}
                                </div>
                            </Cell>
                            { signInRender }
                            <Cell size={12}>
                                <h2 style={mobileFootnoteStyle} className="text-center landing-subtitle-mobile">
                                    For existing users, please use the same login option you used when you first registered on the ProTool.
                                </h2>
                            </Cell>
                            <Grid>
                                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                                    <h2 className="landing-links-mobile" onClick={() => openInNewWindow(TERMS_OF_SERVICE)}>Terms of Service</h2>
                                </Cell>
                                <Cell desktopSize={6} tabletSize={4} phoneSize={2}>
                                    <h2 className="landing-links-mobile" onClick={() => openInNewWindow(PRIVACY_POLICY)}>Privacy Policy</h2>
                                </Cell>
                            </Grid>
                    </div>
                </MediaQuery>
                <EmailLinkDialog emailLink={this.emailLink} onHide={this.hideLoginDialog} visible={loginDialogVisible} />
                <InformationDialog text={notificationMessage} onHide={this.hideNoticeDialog} visible={noticeDialogVisible} />
            </div>
        );
    }
}

export default SignIn;
