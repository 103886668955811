import React from 'react';
import moment from 'moment';
import { ListItem } from 'react-md';

function StipulationListItem(props) {
    const { title, createdOn, id, setFocusedAgreementId} = props;
    
    return (
        <ListItem
            primaryText={title}
            secondaryText={moment(createdOn).calendar()}
            onClick={() => setFocusedAgreementId(id)}
        />
    );
}

export default StipulationListItem;