import React, { PureComponent } from 'react';
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect';
import ProCalendar from '../ProCalendar/';

class CalendarDashboard extends PureComponent {
    render() {
        const { client } = this.props;
        return ( <ProCalendar clientId={client.id} />);
    }
}

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps, null)(CalendarDashboard);