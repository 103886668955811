import React, { useEffect, useState } from 'react';
import usePageFramework from '../../hooks/usePageFramework';
import useLoadingPageFramework from '../../hooks/useLoadingPageFramework';
import { selectCurrentUser, selectOrganizationId, selectDefaultLabels, selectIsSuperAdmin, selectCurrentOrganization } from '../../selectors/global';
import { List, ListItem, Card, Cell, Button } from 'react-md';
import TwoColumnLayout from '../../pageLayouts/TwoColumnLayout';
import UpdateLabelsForm from './UpdateLabelsForm';
import { showToastNotification } from '../../components/Notifications/ToastNotification';
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../constants/notifications';
import { callCloudRunFunction } from '../../utils/firestore';
import { getAccessibleLabelGroups } from '../../utils/permissions';
import ThemedButton from '../../components/Buttons/ThemedButton';

function OrganizationLabels() {
    const {
        history,
        dispatch,
        selector,
    } = usePageFramework();

    const currentUser = selector(selectCurrentUser());
    const organizationId = selector(selectOrganizationId());
    const defaultLabels = selector(selectDefaultLabels());
    const [ focusedItemKey , setFocusedItemKey ] = useState(null);
    const foundLabelGroup = focusedItemKey && defaultLabels && defaultLabels.find(x => focusedItemKey === x.key);

    const isSuperAdmin = selector(selectIsSuperAdmin());
    const organization = selector(selectCurrentOrganization());

    const accessibleLabels = getAccessibleLabelGroups(defaultLabels, organization);

    const {
        finishedLoading,
        errorLoading,
        renderPageContent,
    } = useLoadingPageFramework({ buildPageContent: buildPageContent });

    useEffect(() => {
        finishedLoading();
    }, []);

    const updateLabels = (labelData) => {
        const labelMetadata = {
            organizationId: organizationId,
        };
        
        const labelDocument = Object.assign({}, labelData, labelMetadata);

        // log saving form
        const log = {
            proId: currentUser.uid,
            organizationId: organizationId,
            page: 'Organization Branding',
            action: 'update organization labels',
            labelKey: labelData.labelKey,
            metadata: {...labelData},
        };

        showToastNotification('Saving labels...', NOTIFICATION_INFO);
        
        callCloudRunFunction(currentUser, 'updateOrganizationLabels', labelDocument).then(() => {
            showToastNotification('Labels have been sucessfully saved!', NOTIFICATION_SUCCESS);
            setFocusedItemKey(null);
            callCloudRunFunction(currentUser, 'logProUserAction', log);
        }).catch((err) => {
            console.log(err);
            showToastNotification('An error has occurred', NOTIFICATION_ERROR);
        });
    }

    const buildLabelGroups = () => {
        if (!accessibleLabels) {
            return ( <p> No available labels to change </p>)
        };

        return (
            <List>
                { accessibleLabels.map(x => <ListItem primaryText={x.page} onClick={() => setFocusedItemKey(x.key)}/>) }
            </List>
        );
    }
    
    const buildFormDisplay = () => {
        if (!foundLabelGroup) {
            return (<p>Select a feature on the left to change its labels</p>);
        }

        const formProperties = { formId: foundLabelGroup.key, title: foundLabelGroup.description, FormComponent: UpdateLabelsForm };

        return (<Card><formProperties.FormComponent id={formProperties.formId} key={formProperties.formId} onSubmit={updateLabels} {...formProperties} /></Card>)
    }

    const returnToListButton = () => {
        return (
            <Cell size={12}>
                <ThemedButton className="full-width-field" flat swapTheming primary onClick={() => setFocusedItemKey(null)}>
                    Return to list
                </ThemedButton>
            </Cell>
        );
    }

    const buildTabletMobileSection = () => {
        return !foundLabelGroup
            ? buildLabelGroups()
            : (
                <>
                    { returnToListButton() }
                    { buildFormDisplay() }
                </>
            )
    }

    function buildPageContent() {
        return (
            <TwoColumnLayout
                buildLeftColumn={buildLabelGroups}
                buildRightColumn={buildFormDisplay}
                buildTabletMobile={buildTabletMobileSection}
            />
        );
    }

    return (
        <>
            { renderPageContent() }
        </>
    )
}

export default OrganizationLabels;