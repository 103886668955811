import React from 'react';

import EndCallButton from './EndCallButton';
import ToggleAudioButton from './ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton';
import useIsUserActive from '../../hooks/useIsUserActive';
import useRoomState from '../../hooks/useRoomState';

export default function Controls({ room, localTracks }) {
    const roomState = useRoomState(room);
    const isReconnecting = roomState === 'reconnecting';
    const isUserActive = useIsUserActive();
    const showControls = isUserActive || roomState === 'disconnected';

    const container = {
        display: 'flex',
        position: 'absolute',
        bottom: '20px',
        right: '30%',
        zIndex: 1,
        opacity: 1,
        visibility: 'visible',
    };

    return !showControls ? null : (
        <div style={container}>
            <ToggleAudioButton room={room} disabled={isReconnecting} localTracks={localTracks} />
            <ToggleVideoButton room={room} disabled={isReconnecting} localTracks={localTracks} />
            {roomState !== 'disconnected' && (
                <>
                    <EndCallButton room={room} localTracks={localTracks} />
                </>
            )}
        </div>
    );
}
