import React, { PureComponent } from 'react'
import { SchedulePattern, isVisit, isExchange, isCP1, isCP2 } from '../../components/ParentingPlan/SchedulePattern';
import { Cell, Button, FontIcon, Grid } from 'react-md';
import { Field, reduxForm } from 'redux-form/immutable';
import { required } from '../../components/RequestFields/renderToolboxField';
import renderSelectField from '../../components/RequestFields/renderSelectField'
import renderDatePicker from '../../components/RequestFields/renderDatePicker';
import renderTextField from '../../components/RequestFields/renderTextField';
import { PARENTING_PLANS, SCHEDULE_TYPES } from '../../constants/lookupInfo';
import { PARENT_TWO_DAY, PARENT_ONE_DAY, DEFAULT_DROP_PICKUP_TIME } from '../../constants/schedule';
import ModifyScheduleDialog from '../../components/ParentingPlan/ModifyScheduleDialog';
import ScheduleLegend from '../../components/ParentingPlan/ScheduleLegend';
import { renderSelectionControl } from '../../components/RequestFields/renderSelectionControlGroup';
import moment from 'moment';
import { isNullOrUndefined } from 'util';
import _ from 'underscore';

const validate = (values) => {
    const errors = {};
    if (values.has('startDate') && values.has('endDate')) {
        const startDate = moment(values.get('startDate'));
        const endDate = moment(values.get('endDate'));
        
        if (startDate.isAfter(endDate)) {
            errors.startDate = 'Start Date is after End Date';
            errors.endDate = 'End Date is before Start Date';
        }
    }

    return errors;
}

class FormParentingPlan extends PureComponent {
    constructor(props) {
        super(props)

        const { children, pattern, exchangeTimes, startDate, endDate, p1Name, p2Name, p1Id, p2Id, desc, title, scheduleType  } = this.props;

        this.state = {
            pattern: pattern,
            exchangeTimes: exchangeTimes,
            showPlan: pattern && exchangeTimes,
            showModifyScheduleDialog: false,
            defaultDropPickupTime: DEFAULT_DROP_PICKUP_TIME,
            p1Name: p1Name,
            p2Name: p2Name,
        }

        this.shiftLeft = this.onShiftLeft.bind(this);
        this.shiftRight = this.onShiftRight.bind(this);
        this.swapCoParent = this.onSwapCoParent.bind(this);
        this.parentingPlanChange = this.onParentingPlanChange.bind(this, 'planType');
        this.patternTapped = this.onPatternTapped.bind(this);
        this.hideModifyScheduleDialog = this.onHideModifyScheduleDialog.bind(this);
        this.confirmModifyScheduleDialog = this.onConfirmModifyScheduleDialog.bind(this);

        this.props.change('pattern', pattern);
        this.props.change('exchangeTimes', exchangeTimes);
        this.props.change('children', children);
        this.props.change('startDate', startDate);
        this.props.change('endDate', endDate);
        this.props.change('p1Name', p1Name);
        this.props.change('p2Name', p2Name);
        this.props.change('p1Id', p1Id);
        this.props.change('p2Id', p2Id);
        this.props.change('desc', desc ? desc : '');
        this.props.change('title', title ? title : '');
        this.props.change('scheduleType', scheduleType ? scheduleType : SCHEDULE_TYPES[0].name);
    }

    onShiftLeft = () => {
        const { pattern, exchangeTimes } = this.state;

        if (!pattern || !exchangeTimes) return;

        const shiftedPattern = pattern.slice();
        const shiftedExchangeTimes = exchangeTimes.slice();
        const item = shiftedPattern.shift();
        const exchangeItem = shiftedExchangeTimes.shift();
        
        shiftedPattern.push(item);
        shiftedExchangeTimes.push(exchangeItem);

        this.setState({
            pattern: shiftedPattern,
            exchangeTimes: shiftedExchangeTimes,
        });

        this.props.change('pattern', shiftedPattern);
        this.props.change('exchangeTimes', shiftedExchangeTimes);
    }

    onShiftRight = () => {
        const { pattern, exchangeTimes } = this.state;

        if (!pattern || !exchangeTimes) return;

        const shiftedPattern = pattern.slice();
        const shiftedExchangeTimes = exchangeTimes.slice();
        const item = shiftedPattern.pop();
        const exchangeItem = shiftedExchangeTimes.pop();

        shiftedPattern.splice(0, 0, item);
        shiftedExchangeTimes.splice(0, 0, exchangeItem);

        this.setState({
            pattern: shiftedPattern,
            exchangeTimes: shiftedExchangeTimes,
        });

        this.props.change('pattern', shiftedPattern);
        this.props.change('exchangeTimes', shiftedExchangeTimes);
    }

    onSwapCoParent = () => {
        const { pattern } = this.state;

        if (!pattern) return;
        
        const swappedPattern = pattern.map((item) => {
            if (isCP1(item)) {
                return `${PARENT_TWO_DAY}${item.slice(2)}`;
            } else if (isCP2(item)) {
                return `${PARENT_ONE_DAY}${item.slice(2)}`;
            } else {
                return item;
            }
        });

        this.setState({ pattern: swappedPattern });
        this.props.change('pattern', swappedPattern);
    }

    onPatternTapped = (index) => {
        this.setState({ showModifyScheduleDialog: true, dialogIndex: index });
    }

    onHideModifyScheduleDialog = () => {
        this.setState({ showModifyScheduleDialog: false,  dialogIndex: null });
    }

    onConfirmModifyScheduleDialog = (newPattern, newExchangeTimes) => {
        this.setState({  pattern: newPattern, exchangeTimes: newExchangeTimes });
        this.props.change('pattern', newPattern);
        this.props.change('exchangeTimes', newExchangeTimes);
        this.hideModifyScheduleDialog();
    }

    onParentingPlanChange = (event, newValue, id, name) => {
        const planTemplate = PARENTING_PLANS.find((plan) => plan.id === id);
        const { plan, pattern, desc } = planTemplate;
        const { defaultDropPickupTime } = this.state;
        
        const exchangeTimes = pattern.map((item) => {
            if (isVisit(item)) {
                return `${defaultDropPickupTime},${defaultDropPickupTime}`;
            } else if (isExchange(item)) {
                return `${defaultDropPickupTime}`;
            } else {
                return null;
            }
        });

        this.setState({
            title: plan,
            pattern: pattern,
            exchangeTimes: exchangeTimes,
            desc: desc,
            showPlan: true,
        });

        this.props.change('title', plan);
        this.props.change('desc', desc);
        this.props.change('pattern', pattern);
        this.props.change('exchangeTimes', exchangeTimes);
    }

    buildCoParents = () => {
        const { p1Name, p2Name } = this.state;

        return (
            <ul id="pattern-interface-coparents" className="flex-container-cal schedule-legend-row">
                <ScheduleLegend id="coparent-one-name" text={p1Name} icon="lens" iconClassName="legend-p1"/>
                <Button id="swap-button" flat onClick={this.swapCoParent}>
                    <FontIcon id="swap=icon">
                        swap_horiz
                    </FontIcon>
                </Button>
                <ScheduleLegend id="coparent-two-name" text={p2Name} icon="lens" iconClassName="legend-p2"/>
            </ul>
        );
    }

    buildShiftButtons = () => {
        return (
            <Grid id="pattern-interface-shift">
                <Cell desktopSize={3} tabletSize={2} phoneSize={1}>
                    <Button id="left-shift-button" className="btn-fill-width" flat iconChildren="chevron_left" onClick={this.shiftLeft}>Shift Left</Button>
                </Cell>
                <Cell desktopSize={3} tabletSize={2} phoneSize={1} desktopOffset={6} tabletOffset={4} phoneOffset={2}>
                    <Button id="right-shift-button" className="btn-fill-width" flat iconChildren="chevron_right" iconBefore={false} onClick={this.shiftRight}>Shift Right</Button>
                </Cell>
            </Grid>
        );
    }

    buildTitle = () => {
        const { title } = this.state;

        return (
            <Field
                id={'title'}
                name={'title'}
                label="Parenting Plan Title"
                placeholder={title}
                rows={2}
                maxRows={4}
                component={renderTextField}
                required
                validate={[required]}
            />
        );
    }

    buildPatternDisplay = () => {
        const { pattern, exchangeTimes } = this.state;

        return (
            <SchedulePattern id="pattern-interface-display" pattern={pattern} exchangeTimes={exchangeTimes} onTapBubble={this.patternTapped} />
        );
    }

    buildDescription = () => {
        const { desc } = this.state;

        return (
            <Field
                id={'desc'}
                name={'desc'}
                label="Description"
                placeholder={desc}
                rows={4}
                maxRows={6}
                component={renderTextField}
                required
                validate={[required]}
            />
        );
    }

    buildPatternInterface = () => {
        return (
            <div id="pattern-interface">
                {this.buildTitle()}
                {this.buildCoParents()}
                {this.buildShiftButtons()}
                {this.buildPatternDisplay()}
                {this.buildDescription()}
            </div>
        );
    }

    buildScheduleTemplateActions = () => {
        const { submitting, pristine, handleSubmit, availableChildren, pattern, exchangeTimes } = this.props;
        const fieldStyle = { width: '100%' };
        const childrenControls = availableChildren && availableChildren.map((child) => ({label: child.childName, value: child.id}));

        return (
            <form onSubmit={handleSubmit} className="md-grid">
                <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                    <Field
                        id={`planType`}
                        name={`planType`}
                        label="Parenting Plan Templates"
                        menuItems={PARENTING_PLANS}
                        itemLabel="plan"
                        itemValue="id"
                        style={fieldStyle}
                        required={pattern && exchangeTimes ? false : true}
                        validate={pattern && exchangeTimes ? [] : [required]}
                        component={renderSelectField}
                        onChange={this.parentingPlanChange}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    
                    <Field
                    id="children"
                    name="children"
                    label="Children"
                    component={renderSelectionControl}
                    type="checkbox"
                    required={true}
                    validate={[required]}
                    controls={childrenControls || []}
                    />
                    {
                        !childrenControls
                            ? <p className="md-text--error">Loading children...</p>
                            : _.isEmpty(childrenControls)
                                ? <p className="md-text--error">No children found</p>
                                : null
                    }
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <Field
                        id="scheduleType"
                        name="scheduleType"
                        label="Schedule Type"
                        component={renderSelectField}
                        menuItems={SCHEDULE_TYPES}
                        itemLabel="name"
                        itemValue="name"
                        className='full-width-field'
                        required
                        validate={[required]}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <Field
                        id={`startDate`}
                        name={`startDate`}
                        label="Start Plan on"
                        required
                        validate={[required]}
                        component={renderDatePicker}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={4} phoneSize={4}>
                    <Field
                        id={`endDate`}
                        name={`endDate`}
                        label="End Plan on"
                        required
                        validate={[required]}
                        component={renderDatePicker}
                    />
                </Cell>
                <Cell desktopSize={6} tabletSize={8} phoneSize={4}>
                    <Button className="full-width-field" disabled={pristine || submitting || isNullOrUndefined(childrenControls)} type="submit" flat primary swapTheming>Submit</Button>
                </Cell>
            </form>
        );
    }

    render() {
        const { p1Name, p2Name } = this.props;
        const { showPlan, showModifyScheduleDialog, dialogIndex, pattern, exchangeTimes, defaultDropPickupTime} = this.state;
        const formStyle = { paddingLeft: '25px', paddingRight: '25px', marginTop: '100px'};
        
        const modifyScheduleDialogData = {
            index: dialogIndex,
            p1Name: p1Name,
            p2Name: p2Name,
            pattern: pattern,
            exchangeTimes: exchangeTimes,
            defaultTime: defaultDropPickupTime,
        };

        return (
            <div id="schedule-form" style={formStyle}>
                { showPlan && this.buildPatternInterface() }
                { this.buildScheduleTemplateActions() }
                { showModifyScheduleDialog && <ModifyScheduleDialog onHide={this.hideModifyScheduleDialog} onConfirm={this.confirmModifyScheduleDialog} visible={showModifyScheduleDialog} {...modifyScheduleDialogData}/> }
            </div>
        );
    }
}

export default reduxForm({
    form: 'FormParentingPlan',
    validate,
    fields: [
        'planType',
        'exchangeTimes',
        'startDate',
        'endDate',
        'scheduleType',
        'p1Id',
        'p2Id',
        'p1Name',
        'p2IName',
        'children',
        'pattern',
        'title',
        'desc'
    ],
    enableReinitialize: true,
})(FormParentingPlan);