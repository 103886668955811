import React from 'react';
import { DatePicker } from 'react-md';

const renderDatePicker = ({ input, meta: { touched, invalid, error }, ...props }) => (
    <DatePicker
        {...input}
        {...props}
        error={touched && invalid}
        errorText={error}
    />
);

export default renderDatePicker;