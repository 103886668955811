import * as REDUX from '../constants/redux';
import { buildPayloadObj, setTenantTheme, setTenantLabels, setTenantAssets } from './global';
import * as firebase from 'firebase';
import { selectOrganizationId } from '../selectors/global';

// See https://github.com/reduxjs/redux-thunk
// For writing Thunk middleware

let tenantThemeStream = null;
let tenantLabelStream = null;
let tenantAssetStream = null;

export const setOrganizationId = (payload) => {
    return (dispatch, getState) => {
        const prevOrgId = selectOrganizationId()(getState());

        if (prevOrgId !== payload) {
            const db = firebase.firestore();
    
            // Clear previous streams
            if (tenantThemeStream) {
                tenantThemeStream();
                tenantThemeStream = null;
            }

            if (tenantLabelStream) {
                tenantLabelStream();
                tenantLabelStream = null;
            }
            
            if (tenantAssetStream) {
                tenantAssetStream();
                tenantAssetStream = null;
            }

            // Set new stream based on new id
            if (payload !== null && payload !== undefined && payload !== false) {
                tenantThemeStream = db.collection('ProTenantThemes').doc(payload).onSnapshot((snapshot) => {
                    const tenantTheme = snapshot.data();
                    dispatch(setTenantTheme(tenantTheme));
                });

                tenantLabelStream = db.collection('ProTenantThemes').doc(payload).collection('Labels').onSnapshot((snapshot) => {
                    const tenantLabels = snapshot.docs.map(x => x.data());
                    dispatch(setTenantLabels(tenantLabels));
                });

                tenantAssetStream = db.collection('ProTenantThemes').doc(payload).collection('Assets').onSnapshot((snapshot) => {
                    const tenantAssets = snapshot.docs.map(x => x.data());
                    dispatch(setTenantAssets(tenantAssets));
                });
            }
        }

        dispatch(buildPayloadObj(REDUX.ORG_ID, payload));
    };
};