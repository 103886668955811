import React, { Component } from 'react';
import moment from 'moment';
import { Card, CardTitle, CardText, Grid, Cell } from 'react-md';
import AgreementActions from './AgreementActions';
import { SchedulePattern, bufferExchangeTimes } from '../ParentingPlan/SchedulePattern';
import ScheduleLegend from '../ParentingPlan/ScheduleLegend';
import { AGREEMENT_STYLES } from '../../constants/lookupInfo';

class ParentingPlanInteractionCard extends Component {
    labelLine(label, value) {
        return (<div className="flex-label"><b>{label}:</b> {value}</div>);
    }

    displayCoParentLegend() {
        const { messageAttributes } = this.props;
        return (
            <ul className="flex-container-cal">
                <ScheduleLegend text={messageAttributes.p1Name || "coParent 1"} icon="lens" iconClassName="legend-p1"/>
                <ScheduleLegend text={messageAttributes.p2Name || "coParent 2"} icon="lens" iconClassName="legend-p2"/>
            </ul>
        );
    }

    displaySchedulePattern() {
        const { messageAttributes } = this.props;
        return (
            <SchedulePattern
                pattern={messageAttributes.pattern || []}
                exchangeTimes={bufferExchangeTimes(messageAttributes.pattern, messageAttributes.exchangeTimes)}
            />
        );
    }

    displayPlanDetails() {
        const { messageAttributes } = this.props;
        const children = messageAttributes.childrenNames ? this.labelLine('Children', messageAttributes.childrenNames.join(', ')) : null;
        const startsOn = messageAttributes.startDay ? this.labelLine('Start Date', moment(messageAttributes.startDay).format('LL')) : null;
        const endsOn = messageAttributes.endDay ? this.labelLine('End Date', moment(messageAttributes.endDay).format('LL')) : null;
        const scheduleType = messageAttributes.scheduleType ? this.labelLine('Schedule Type', messageAttributes.scheduleType) : null;
        const scheduleDescription = messageAttributes.message ? this.labelLine('Schedule Description', messageAttributes.message) : null;

        return (
            <>
                { children }
                { startsOn }
                { endsOn }
                { scheduleType }
                { scheduleDescription }
            </>
        );
    }

    displayResponses() {
        const { messageAttributes } = this.props;
        const messageResponse = messageAttributes.response.split('|');
        const agreementValue = messageAttributes.agreementValue;

        return (
            messageResponse.map((item, index) => {
                const textColor = item.toLowerCase().includes(` ${agreementValue}`) ? 'chat-card-green-text' : 'chat-card-red-text';
                return (
                    <div className={`responseContainer agreementResponseContainer ${textColor}`} key={`response${index}`}>
                        {item}
                    </div>
                );
            })
        );
    }

    render() {
        const {
            messageAttributes,
            /*isAnyDisagree,*/ isAllUserResponded, isAllAgree,
            onDeleteInteraction, onOverrideToAgree, onReset, onModify,
        } = this.props;

        const waitingCoParentResponseMessage = <p className="text-center">Waiting for coParent Response</p>;
        const wrapperTemplate = isAllAgree === null ? AGREEMENT_STYLES.blue : isAllAgree === true ? AGREEMENT_STYLES.green : AGREEMENT_STYLES.red;

        return (
            <Card className="chatCardWrapper">
                <CardTitle className={`${wrapperTemplate} chatCardTitle`} title={messageAttributes.title} />
                <CardText className="chatCardText">
                    <Grid>
                        <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                            { this.displayCoParentLegend() }
                        </Cell>
                        <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                            { this.displaySchedulePattern() }
                        </Cell>
                        <Cell desktopSize={12} tabletSize={8} phoneSize={4}>
                            { this.displayPlanDetails() }
                        </Cell>
                    </Grid>
                    { messageAttributes.responses && messageAttributes.responses.length > 0 && <div className="row agreementResponseLine">{this.displayResponses()}</div> }
                    { !isAllUserResponded && <div className="chatCardWaitingResponseText">{waitingCoParentResponseMessage}</div> }
                    { !isAllAgree && <AgreementActions onCancelAction={onDeleteInteraction} onAgreeOverride={onOverrideToAgree} onReset={onReset} onModify={onModify}/> }
                </CardText>
            </Card>
        );
    }
}

export default ParentingPlanInteractionCard;
