import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataTable, TableHeader, TableBody, TableRow, TableColumn, TablePagination } from 'react-md';
import { FILTER_NONE } from '../../constants';
import { sortDataItems, getEmptyColumnValue } from '../../utils/sortFilter';
import { selectSortFromComponent } from '../../selectors/global';
import { setSortToComponent } from '../../actions/global';
import { isNullOrUndefined } from 'util';

class DataSheet extends PureComponent {
    constructor(props) {
        super(props);

        const { columns } = this.props;
        
        this.paginationClick = this.onPaginationClick.bind(this);
        this.headerClick = this.handleSorting.bind(this);

        this.state = {
            rowsPerPage: 10,
            start: 0,
            sortColumn: columns.find(x => x.isPrimaryColumn || false),
            sortAscending: columns.find(x => x.isPrimaryColumn) && columns.find(x => x.isPrimaryColumn).initialAsc || false,
            currentPage: 1,
        };
    }

    componentDidMount() {
        const { dataSort } = this.props;

        if (dataSort) {
            this.setState(dataSort);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { dataSort: prevDataSort } = prevProps;
        const { dataSort, columns, componentName } = this.props;

        // Handle new incoming data sorting props. If there is no sorting data, default to primary column.
        if (!isNullOrUndefined(dataSort)) {
            if (isNullOrUndefined(prevDataSort)) {
                this.setState(dataSort);
                return;
            }

            // Check if there are prop differences, set sorting state if props have changed
            if (this.sortingObjHasChanged(prevDataSort, dataSort)) {
                this.setState(dataSort);
                return;
            }

        } else if (!isNullOrUndefined(prevDataSort)) {
            this.setState({
                sortColumn: columns.find(x => x.isPrimaryColumn) || false,
                sortAscending: false,
            });
            return;
        }
        
        // If there are any sorting changes, save the sorting into redux. Reset paging rows if there are chagnes
        if (this.sortingObjHasChanged(prevState, this.state)) {
            const stateCopy = Object.assign({}, this.state);
            this.props.setDataSort(stateCopy, componentName);
        }
    }

    sortingObjHasChanged = (prevSortObj, nextSortObj) => {
        const { rowsPerPage: prevRowsPerPage, start: prevStart, sortColumn: prevSortColumn, sortAscending: prevSortAscending } = prevSortObj;
        const { rowsPerPage: nextRowsPerPage, start: nextStart, sortColumn: nextSortColumn, sortAscending: nextSortAscending } = nextSortObj;

        const initialSorting = !prevSortColumn && !!nextSortColumn;
        const diffRowsPerPage = prevRowsPerPage !== nextRowsPerPage;
        const diffPaginationStart = prevStart !== nextStart;
        const diffSortingColumn = prevSortColumn && nextSortColumn && prevSortColumn.value !== nextSortColumn.value;
        const diffSortingDirection = prevSortAscending !== nextSortAscending;

        return initialSorting || diffRowsPerPage || diffPaginationStart || diffSortingColumn || diffSortingDirection;
    }

    onPaginationClick = (start, rowsPerPage, currentPage) => {
        this.setState({
            rowsPerPage: rowsPerPage,
            start: start,
            currentPage: currentPage,
        });
    }

    handleSorting = (newSortingColumn) => {
        const { sortColumn, sortAscending } = this.state;

        this.setState({
            sortColumn: newSortingColumn,
            sortAscending: sortColumn && sortColumn.value === newSortingColumn.value ? !sortAscending : false,
        });
    }

    buildTableHeaders = () => {
        const { columns } = this.props;

        return (
            <TableHeader>
                <TableRow key={'data-table-header'}>
                    { columns.map(this.buildHeader) }
                </TableRow>
            </TableHeader>
        );
    }

    buildHeader = (column, index) => {
        const { sortAscending } = this.state;
        const isSortedColumn = this.isSortingOn(column);

        return column.type === FILTER_NONE
            ? (<TableColumn key={index}>{ column.label }</TableColumn>)
            : (
                <TableColumn
                    key={index}
                    role="button"
                    style={{color: '#07a7cf', fontWeight: isSortedColumn ? 'bold' : 'normal', cursor: 'pointer' }}
                    sorted={ isSortedColumn ? sortAscending : null }
                    onClick={() => this.headerClick(column)}
                >
                <div style={{textDecoration: 'underline'}}>
                        {column.label}
                    </div>
                </TableColumn>
            );
    }

    isSortingOn = (column) => {
        const { sortColumn } = this.state;
        return sortColumn && sortColumn.value === column.value;
    }

    buildTableBody = () => {
        const { items } = this.props;
        const { start, rowsPerPage, sortColumn, sortAscending } = this.state;

        const sortedItems = sortDataItems(items, sortColumn, sortAscending);
        const slicedItems = sortedItems.slice(start, start + rowsPerPage);

        return (
            <TableBody>
                { slicedItems.map(this.buildTableBodyRow) }
            </TableBody>
        );
    }

    buildTableBodyRow = (item, index) => {
        const { columns } = this.props;

        return (
            <TableRow key={`${index}-${item.id}`}>
                { columns.map((column, colIndex) => this.buildCellContent(column, item, index, colIndex)) }
            </TableRow>
        );
    }

    buildCellContent = (column, item, index, colIndex) => {
        return (
            <TableColumn key={`${index}-${colIndex}`}>
                {
                    column.staticText
                        ? column.fn(column.label, item.id, column.value, item)
                        : item.hasOwnProperty(column.value) && item[column.value] !== '' && item[column.value] !== undefined && item[column.value] !== null ? column.fn(item[column.value], item.id) : getEmptyColumnValue(column.type)
                }
            </TableColumn>
        );
    }

    buildTablePagination = () => {
        const { items } = this.props;
        const { rowsPerPage, currentPage } = this.state;

        return (
            <TablePagination
                rows={items.length}
                defaultRowsPerPage={10}
                rowsPerPage={rowsPerPage}
                rowsPerPageLabel={'Rows per page'}
                simplifiedMenu
                onPagination={this.paginationClick}
                page={currentPage}
            />
        );
    }

    render() {
        const { items, emptyCard } = this.props;

        if (!items) {
            return emptyCard ? emptyCard() : null;
        }

        return (
            <DataTable plain>
                { this.buildTableHeaders() }
                { this.buildTableBody() }
                { this.buildTablePagination() }
            </DataTable>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDataSort: (sorter, componentName) => dispatch(setSortToComponent(sorter, componentName))
    };
};

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    dataSort: selectSortFromComponent(ownProps.componentName),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataSheet);