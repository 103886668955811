/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Button, DialogContainer, Toolbar } from 'react-md';
import ParentingPlanForm from './FormParentingPlan';
import { flattenExchangeTimes, bufferExchangeTimes } from '../../components/ParentingPlan/SchedulePattern';

export default class ParentingPlanDialog extends PureComponent {
    parseParentingPlan = (values) => {
        const parentingPlanValues = {};
        const findKeys = ['pattern', 'exchangeTimes', 'startDate', 'endDate', 'scheduleType', 'title', 'desc', 'p1Name', 'p2Name', 'p1Id', 'p2Id', 'children' ];
        findKeys.forEach(x => parentingPlanValues[x] = '');
        
        values.map((value, key) => {
            const name = findKeys.find(x => key.startsWith(x));
            return parentingPlanValues[name] = value;
        });
        
        parentingPlanValues['children'] = parentingPlanValues['children'].split(',');
        parentingPlanValues['exchangeTimes'] = flattenExchangeTimes(parentingPlanValues['exchangeTimes']);

        return parentingPlanValues;
    };

    handleSubmit = (e) => {
        const parentingPlan = this.parseParentingPlan(e);
        this.props.addParentingPlan(parentingPlan);
    };
    
    render() {
        const { visible, onHide, members, availableChildren, pattern, exchangeTimes, children, startDay, endDay, p1Name, p2Name, p1Id, p2Id, message, title, scheduleType } = this.props;
        const coParents = members ? members.filter(x => !x.isProfessional) : [{id: 0, friendlyName: 'coParent 1', isPrimary: true}, {id: null, friendlyName: 'coParent 2', isPrimary: false}];
        const bufferedExchangeTimes = pattern && exchangeTimes ? bufferExchangeTimes(pattern, exchangeTimes) : null;

        const cp1Search = coParents.find(x => x.isPrimary) ? coParents.find(x => x.isPrimary) : {id: 0, friendlyName: 'coParent 1'};
        const cp2Search = coParents.find(x => x !== cp1Search) ? coParents.find(x => x!== cp1Search) : {id: null, friendlyName: 'coParent 2'};

        const initialParentingPlanData = {
            pattern: pattern,
            exchangeTimes: bufferedExchangeTimes,
            children: children ? children.join(',') : null,
            startDate: startDay,
            endDate: endDay,
            p1Name: p1Name || p1Id ? p1Name : cp1Search.friendlyName,
            p2Name: p2Name || p2Id ? p2Name : cp2Search.friendlyName,
            p1Id: p1Name || p1Id ? p1Id : cp1Search.id,
            p2Id: p2Name || p2Id ? p2Id : cp2Search.id,
            desc: message,
            title: title,
            scheduleType: scheduleType,
        };

        return (
            <DialogContainer
                id="add-Orgs-dialog"
                aria-labelledby="add-org-dialog-title"
                visible={visible}
                onHide={onHide}
                fullPage
            >
                <CSSTransitionGroup
                    id="add-org-form"
                    className="md-text-container md-toolbar--relative"
                    transitionName="md-cross-fade"
                    transitionEnterTimeout={300}
                    transitionLeave={false}
                >
                    <Toolbar
                        nav={<Button icon onClick={onHide}>close</Button>}
                        title="Create a Parenting Plan" titleId="add-org-dialog-title" fixed colored
                    />
                    <ParentingPlanForm
                        onSubmit={this.handleSubmit}
                        key={0}
                        coParents={coParents}
                        availableChildren={availableChildren}
                        {...initialParentingPlanData}
                    />
                </CSSTransitionGroup>
            </DialogContainer>
        );
    }
}
